import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { IVRCustomField } from '@/types/vrCustomField';

export const completeStepValidation = (customFields: IVRCustomField[]) => {
  const customFieldsSchema = customFields
    .reduce((acc, customField) => ({
      ...acc,
      [customField.name]: customField.required ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.string(),
    }), {});

  return Yup.object().shape({
    company: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    email: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    phoneNumber: Yup.string()
      .required(DEFAULT_ERROR_MESSAGES.REQUIRED)
      .test('is-valid-phone', DEFAULT_ERROR_MESSAGES.PHONE, (value) => {
        return !value || Yup.string().phone('US', false).isValidSync(value);
      }),
    ...customFieldsSchema,
  })
}