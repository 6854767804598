import React, { useRef } from 'react';

import { IGooglePlace } from '@/types/googlePlace';
import { IFieldProps } from '@/types/field';

import { TextField, ITextFieldProps } from '@/components/designSystem/Form/TextField';

import { useGoogleAddressFields } from './useGoogleAddressFields';

interface IProps extends ITextFieldProps {
  setFieldsFromGooglePlace: (place: IGooglePlace) => void,
}

export const GoogleAddressField = ({ setFieldsFromGooglePlace, ...inputProps }: IProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useGoogleAddressFields({ inputRef, setFieldsFromGooglePlace });

  return (
    <TextField innerRef={inputRef} {...inputProps} />
  )
}
