import styled from "styled-components";
import { SignAgreementButton } from "@/components/SignAgreementButton";

export const StyledContainer = styled.div`
  width: 60vw;
  max-width: 60rem;
`;

export const StyledAgreementContainer = styled.div`
  border: 1px solid rgba(22, 22, 22, 0.16);
  box-sizing: border-box;
  /* Subtle shadow */
  box-shadow: ${({ theme }) => theme.color.shadow.shadowSmall};
  border-radius: 12px;
  height: 700px;
  overflow-y: scroll;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  white-space: pre-line;
`;

interface IButton {
  children: string,
}

export const StyledSignButton = styled(SignAgreementButton).attrs({ primary: true })<IButton>`
  margin-top: 1.5rem;
  width: 100%;
`;

export const StyledCheckboxWrapper = styled.div`
  margin-bottom: 1rem;
`