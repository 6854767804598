import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { Formik } from 'formik';

import { noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ChangesRequestedValuesContext } from '../../context';
import { vendorsStepValidation } from './validation';
import { VendorsStepForm } from './form';
import { IChangesRequestedValuesAction } from '../../types';
import { UPDATE_VENDORS } from '../../reducer';
import { IVendorsValues } from './types';
import { mapChangesRequestedValuesToVendorReferences } from './functions';
import gql from 'graphql-tag';

export const CHANGE_REQUEST_VENDORS = gql`
mutation changeRequestVendorReferences($changeRequestId: ID!, $vendorReferences: VendorReferences!) {
  change_request_vendor_references(changeRequestId: $changeRequestId, vendorReferences: $vendorReferences) {
    customer {
      id
    }
  }
}
`;

interface IProps {
  dispatch: Dispatch<IChangesRequestedValuesAction>,
  providerCompanyId: string,
  totalReferencesNumber: number,
  requiredReferencesNumber: number,
  nextStepPath: string| null,
}

export const VendorsStep = ({ dispatch, nextStepPath }: IProps) => {
  const history = useHistory();
  const { search } = useLocation();

  const changesRequestedValues = useContext(ChangesRequestedValuesContext);
  const changeRequest = changesRequestedValues?.changeRequest;

  const firstUpdate = useRef(true);

  const [changeRequestVendors] = useMutation(CHANGE_REQUEST_VENDORS);

  const submitStep = () => {

    if (!changesRequestedValues) {
      return noop;
    }

    const vendorReferences = mapChangesRequestedValuesToVendorReferences(
      changesRequestedValues.vendors.requiredVendors,
      changesRequestedValues.vendors.optionalVendors
    );

    return changeRequestVendors({
      variables: {
        changeRequestId: changeRequest?.id,
        vendorReferences,
      }
    })
      .then(() => {
        history.push({ pathname: `${changesRequestedValues.basePath}/${nextStepPath}`, search });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep();
  }, [changesRequestedValues]);

  const dispatchValues = (values: IVendorsValues) => {
    dispatch({ type: UPDATE_VENDORS, payload: values })
  }

  if (!changesRequestedValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={changesRequestedValues.vendors}
      validationSchema={
        vendorsStepValidation(
          'mock',
          // changesRequestedValues.basicInfo.email.split('@')[1],
          changesRequestedValues.companyProfile.website,
        )
      }
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <VendorsStepForm />
    </Formik>
  )
};
