import React from 'react';

import { BodyText } from '@/components/designSystem/Typography';

import { ReactComponent as LockIcon } from '@/assets/illustrations/lock.svg';

import { StyledBulletContainer, StyledIconContainer } from './styled';

interface IProps {
  children: string,
}

export const Bullet = ({ children }: IProps) => (
  <StyledBulletContainer>
    <StyledIconContainer><LockIcon /></StyledIconContainer>
    <BodyText>{children}</BodyText>
  </StyledBulletContainer>
);
