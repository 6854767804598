import { FormikValues } from "formik";

const trimObjectValues = (obj: any) => Object.keys(obj).forEach(key => {
  if (typeof obj[key] === 'object' && obj[key] !== null) {
    trimObjectValues(obj[key])
  }
  if (
    typeof obj[key] === 'string'
    // Prevent property assign error in case object property is not writable
    && Object.getOwnPropertyDescriptor(obj, key)?.writable) {
    obj[key] = obj[key].trim();
  }
})

export const handleSubmitWrapper = (data: FormikValues, callback: (argument: any, ...other: any) => void, ...other: any) => {
  trimObjectValues(data)
  return callback(data, ...other)
}
