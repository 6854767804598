import styled from 'styled-components';

import { get } from 'lodash/fp';

import { FlexColumn, FlexRowItemsCenter } from '../designSystem/Layout';

export const StyledCalloutContainer = styled(FlexRowItemsCenter)`
  padding: 1rem;
  border-radius: 8px;
  background-color: ${({ theme, color }) => get(`color.${color}`, theme)};
`;

export const StyledCalloutContent = styled(FlexColumn)`
  width: 100%;
`;

export const StyledIconContainer = styled.div`
  margin-right: 1rem;
  max-height: 48px;
  max-width: 48px;

  & > * {
    max-width: 48px;
    max-height: 48px;
  }
`;
