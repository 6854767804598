import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { noop } from 'lodash/fp';

import {
  StyledSpinner,
  IconContainer,
  StyledButton,
  IButtonStyles,
  RightIconContainer,
} from './styled';

export interface IButtonProps extends IButtonStyles {
  children?: JSX.Element | JSX.Element[] | string | React.ReactNode,
  LeftIcon?: any,
  RightIcon?: any,
  path?: string,
  href?: string,
  target?: string,
  rel?: string,
  disabled?: boolean,
  fullHeight?: boolean,
  onClick?: (e: MouseEvent) => void,
  onDisabledClick?: () => void,
  afterPath?: () => void,
  loading?: boolean,
  type?: string,
  mt?: string,
}

export const Button = (props: IButtonProps) => {
  const {
    children,
    LeftIcon,
    RightIcon,
    path,
    href,
    target,
    rel,
    disabled = false,
    onClick,
    onDisabledClick = noop,
    afterPath = noop,
    loading = false,
    ...otherProps
  } = props;

  const history = useHistory();
  const { search } = useLocation();
  const linkTo = (pathname: string) => () => {
    history.push({ pathname, search });
    afterPath();
  };

  // const action = path ? linkTo(path) : onClick;

  const action = href
    ? () => {
        const a = document.createElement('a');
        a.href = href;
        if (target) a.target = target;
        if (rel) a.rel = rel;
        a.click();
      }
    : path
      ? linkTo(path)
      : onClick;

  return (
    <StyledButton
      type='button'
      {...otherProps}
      onClick={action}
      disabled={disabled || loading}
      isDisabled={disabled}
    >
      {LeftIcon && <IconContainer><LeftIcon /></IconContainer>}
      {
        loading ? <StyledSpinner size={20} /> : children
      }
      {RightIcon && <RightIconContainer><RightIcon /></RightIconContainer>}
    </StyledButton>
  );
};
