import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { get } from 'lodash/fp';

import { useRedirect } from '@/providers/Redirect/context';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { BodyText } from '@/components/designSystem/Typography';

import { FlexColumnItemsCenter } from '../components/designSystem/Layout';

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const Container = styled(FlexColumnItemsCenter)`
  justify-content: center;
  text-align: center;
  padding: 2rem;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

export const IncompleteCreditApplicationModal = () => {
  const { toSeekerSignup } = useRedirect();
  const currentCompanyData = useContext(CurrentCompanyContext);

  const [isOpen, setIsOpen] = useState(false);

  const incompleteSeekerOnboard = get('currentCompany.incompleteCreditApplicationCustomers[0]', currentCompanyData);

  const seekerOnboardIncomplete = !!incompleteSeekerOnboard;

  const relatedCustomerId = get('id', incompleteSeekerOnboard);
  const relatedProviderId = get('providerCompany.id', incompleteSeekerOnboard);
  const relatedProviderName = get('providerCompany.name', incompleteSeekerOnboard);

  useEffect(() => {
    setIsOpen(seekerOnboardIncomplete);
  }, [seekerOnboardIncomplete]);

  return (
    <StyledModal
      title='Continue your Application'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      blockClose
    >
      <Container>
        <BodyText>It looks like you have a Credit Application in progress for {relatedProviderName}</BodyText>
        <BodyText>Would you like to complete it now?</BodyText>
        <StyledButton
          wide
          primary
          onClick={() => toSeekerSignup({ companyId: relatedProviderId }, `?cid=${relatedCustomerId}`)}
        >
          Finish Application
        </StyledButton>
        <StyledButton wide secondary onClick={() => setIsOpen(false)}>Not Now</StyledButton>
      </Container>
    </StyledModal>
  );
};
