import styled from 'styled-components';

import { H1, Small } from '@/components/designSystem/Typography';
import { FlexColumnCenterItemsCenter, FlexRowSpaceBetweenItemsCenter } from '../designSystem/Layout';
import device from '@/styles/device';

interface ILogoProps {
  opacity?: number,
}

interface IStepProps {
  isActive: boolean,
  complete: boolean,
  activeColor?: string
}

interface INodeTextColorProps {
  isActive: boolean,
  theme: any,
  activeColor?: string,
}

export const StyledOnboardHeaderWrapper = styled.header`
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledOnboardHeader = styled(FlexRowSpaceBetweenItemsCenter)`
  background-color: ${(props) => props.theme.color.site.primary};
  width: 100%;

  @media ${device.laptopL} {
    flex-direction: column;
    padding-top: 2rem;
  };
`;

export const StyledLogoContainer = styled.div<ILogoProps>`
  margin-left: 2rem;
  height: 100%;
  width: 100%;
  max-height: 75px;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > svg {
    height: 100%;
    max-height: 75px;
    max-width: 20rem;
  }

  opacity: ${({ opacity = 1 }) => opacity};

  @media ${device.laptopL} {
    margin-left: 0rem;
    display: ${({ opacity }) => opacity === 0 ? 'none' : 'flex'};
    justify-content: center;
  };
`;

const nodeTextColor = ({ isActive, theme, activeColor }: INodeTextColorProps) => (isActive ? (activeColor ?? theme.color.brand) : theme.color.text.stepper);

export const StepNodeContainer = styled.li<IStepProps>`
  width: 5rem;
  position: relative;

  &:last-child {
    width: 2rem;
  }

  &:first-child > div {
    &:before {
      content: none;
    }
  }

  &:last-child > div {
    &:after {
      content: none;
    }
  }

  @media ${device.tablet} {
    width: 4rem;
  };
`;

export const StepTitle = styled(Small)<IStepProps>`
  color: ${nodeTextColor};
  text-align: center;
  position: absolute;
  width: 3.5rem;
  left: -1rem;
  margin-top: 0.5rem;
`;

export const StepBadge = styled(FlexColumnCenterItemsCenter)<IStepProps>`
  width: 1.5rem;
  height: 1.5rem;
  background: ${({ complete, theme, activeColor }) => (complete ? (activeColor ?? theme.color.brand) : theme.color.site.background)};
  border: 2px solid ${({ isActive, complete, theme, activeColor }) => ((isActive || complete) ? (activeColor ?? theme.color.brand) : theme.color.text.stepper)};
  color: ${({ isActive, complete, theme, activeColor }) => {
    if (isActive) return (activeColor ?? theme.color.brand);
    if (complete) return theme.color.white;
    return theme.color.text.stepper;
  }};
  border-radius: 100%;
  font-size: 12px;
  position: relative;

  &:before {
    height: 2px;
    width: 150%;
    content: '';
    background: ${({ isActive, complete, theme, activeColor }) => ((isActive || complete) ? (activeColor ?? theme.color.brand) : theme.color.text.stepper)};
    position: absolute;
    top: 10px;
    left: -150%;
    z-index: 0;
  }

  &:after {
    height: 2px;
    width: 150%;
    content: '';
    background: ${({ complete, theme, activeColor }) => ((complete) ? (activeColor ?? theme.color.brand) : theme.color.text.stepper)};
    position: absolute;
    top: 10px;
    left: 100%;
    z-index: 0;
  }
`;

export const StyledStepCounterContainer = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
`;

export const StyledTitle = styled(H1)`
  color: ${({ theme }) => theme.color.brand};
`