import React from 'react';

import { get } from 'lodash/fp';

import { IAutomaticAccountInterface } from '@/types/automaticAccount';

import { Label } from '@/components/Label/Label';

import { Account } from './Account';

import {
  InstitutionTitleContainer,
  InstitutionTitle,
  InstitutionContainer,
} from './styled';

interface IProps {
  accounts: IAutomaticAccountInterface[],
}

export const FinicityInstitution = ({ accounts }: IProps) => {
  const institutionName = get('[0].lastAccount.institution.name', accounts);

  return (
    <InstitutionContainer>
      <InstitutionTitleContainer>
        <InstitutionTitle>{institutionName}</InstitutionTitle>
        <Label type='success'>Automatic ✨</Label>
      </InstitutionTitleContainer>
      {accounts.map((account) => {
        const name = get('lastAccount.name', account);
        const accountNumber = get('lastAccount.accountDisplayNumber', account);
        return <Account key={account.id} name={name} accountNumber={accountNumber} />;
      })}
    </InstitutionContainer>
  );
};
