import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { UPSERT_MANUAL_ACCOUNT_NOTE } from '@/graphql/mutations/upsertManualAccountNote';

import { Formik, Form } from 'formik';

import { get, find } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { IManualAccount } from '@/types/manualAccount';

import { AutoSave } from '@/components/designSystem/Form/AutoSave';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';

import {
  NoteContainer,
} from './styled';

interface IValues {
  note: string,
}

interface IFunction {
  setSubmitting: (arg: boolean) => void,
}

interface IProps {
  account: IManualAccount,
  accountNotes: string[],
  customerId?: string,
  refetch: () => void,
}

export const Note = ({
  account, accountNotes, customerId, refetch,
}: IProps) => {
  const accountId = get('id', account);
  const accountNote = find(['account.id', accountId], accountNotes);
  const note = get('note', accountNote);

  const [upsertManualAccountNote] = useMutation(UPSERT_MANUAL_ACCOUNT_NOTE);

  const fields = { note };

  const autoSaveNote = (values: IValues, { setSubmitting }: IFunction) => {
    const noteValue = get('note', values);

    const variables = {
      accountId,
      customerId,
      note: noteValue,
    };

    return upsertManualAccountNote({ variables })
      .then((response) => {
        const newUpdatedAt = get('data.upsertManualAccountNote.updatedAt', response);
        setSubmitting(false);
        refetch();
        return newUpdatedAt;
      });
  };

  return (
    <NoteContainer color='greyLight'>
      <Formik initialValues={fields} onSubmit={(values, options) => handleSubmitWrapper(values, autoSaveNote, options)}>
        <Form>
          <TextAreaField
            name='note'
            label='Note'
            placeholder='Enter any notes about their account here...'
            minRows={5}
            maxRows={20}
          />
          <AutoSave debounceMs={1000} />
        </Form>
      </Formik>
    </NoteContainer>
  );
};
