import styled from 'styled-components';

export const ApplicationContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "main";
  height: 100vh;
  background-color: ${(props) => props.theme.color.site.background};
`;

export const Main = styled.main`
  grid-area: main;
  border-radius: 1.25rem;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: ${({ theme }) => theme.color.shadow.shadowLight};
  min-height: 100vh;
  height: fit-content;
  padding: 2rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 100px minmax(0, 1fr) 100px;
  grid-template-areas:
    "left center right";
`;

export const StyledStepCounterContainer = styled.div`
  margin: 3rem 0 3rem 3rem;
`;
