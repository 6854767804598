import styled from 'styled-components';
import Modal from 'styled-react-modal';
import { Form } from 'formik';

import { BodyText } from '@/components/designSystem/Typography';
import { FlexColumn, FlexRowCenter, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

interface IFunctionProps {
  width?: string,
  height?: string,
  maxWidth?: string,
  maxHeight?: string,
  overflow?: string,
}

const customWidth: any = ({ width }: IFunctionProps) => (width || '100%');
const customHeight: any = ({ height }: IFunctionProps) => (height || 'fit-content');
const customMaxWidth: any = ({ maxWidth }: IFunctionProps) => (maxWidth || '520px');
const customMaxHeight: any = ({ maxHeight }: IFunctionProps) => (maxHeight ? `min(90vh, ${maxHeight})` : 'min(90vh, 668px)');
const customOverflow: any = ({ overflow }: IFunctionProps) => (overflow || 'scroll');

export const ModalContainer = Modal.styled`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }: any) => theme.color.white};
  opacity: ${({ opacity }: any) => opacity};
  transition: opacity ease 500ms;
  border-radius: 1rem;
  max-width: ${customMaxWidth};
  max-height: ${customMaxHeight};
  width: ${customWidth};
  height: ${customHeight};
`;

export const HeaderContainer = styled(FlexRowSpaceBetweenItemsCenter)`
  padding: 2rem 3.5rem;
  position: relative;
`;

export const ContentContainer = styled(FlexColumn)<any>`
  padding: 1.5rem 3.5rem;
  overflow-y: ${customOverflow};
  flex-grow: 1;
`;

export const FooterContainer = styled(FlexColumn)`
  padding: 1.5rem 3.5rem;
  border-top: ${({ theme }) => theme.color.border.light};
`;

export const IconContainer = styled.div`
  margin-left: 1rem;
  cursor: pointer;

  & > path {
    fill: ${({ theme }) => theme.color.text.secondary}
  };
`;

export const HelpText = styled(BodyText)`
  padding: 1rem;
  text-align: center;
`;

export const HelpTextLeft = styled(BodyText)`
  text-align: left;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 34rem;
`;

export const StyledStepsContainer = styled(FlexRowCenter)`
  position: absolute;
  top: 2rem;
  left: 50%;
  height: 2.5rem;
  transform: translateX(-50%);
  gap: 1rem;
`;
