import { useState, useEffect } from 'react';

import { DocumentNode, useLazyQuery, useMutation } from '@apollo/react-hooks';

import { noop } from 'lodash/fp';

import { IValues } from './types';

export const useCreditReportModal = (
  isOpen: boolean,
  query: DocumentNode,
  mutation: DocumentNode,
  onCompleted: () => void = noop
) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState<IValues | undefined>();

  useEffect(() => {
    if (!isOpen) {
      setShowSearch(false);
    }
  }, [isOpen]);

  const [getResults, { data, loading: loadingResults }] = useLazyQuery(query, { fetchPolicy: 'network-only' });
  const [generateReport, {loading: loadingReport }] = useMutation(
    mutation,
    {
      onCompleted
    },
  );
  const loading = loadingResults || loadingReport;

  const handleSubmit = async ({ name, city, province, country }: IValues) => {
    await getResults({
      variables: {
        searchParams: {
          name, city, province, country,
        },
      },
    });

    setShowSearch(true);
    setSearchInput({ name, city, province, country });
  };

  return {
    showSearch,
    setShowSearch,
    searchInput,
    data,
    generateReport,
    loading,
    handleSubmit
  }
};
