import React from 'react';

import { BodyText, StyledLink } from '@/components/designSystem/Typography';

import { ReactComponent as LeftChevron } from '@/assets/chevron_left.svg';

import styled from 'styled-components';
import { isFunction } from 'lodash/fp';

const StyledBackNotLink = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  
  & > svg > path {
    fill: ${({ theme }) => theme.color.primary};
  }
`;

const StyledBackLink = styled(StyledLink)`
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledText = styled(BodyText)`
  color: ${({ theme }) => theme.color.primary};
`;

interface IContainerProps {
  title?: string,
  link?: string,
  onClick?: () => void,
}

export const BackButton = ({
  title = 'Back', link, onClick,
}: IContainerProps) => {
  if (isFunction(onClick)) {
    return <StyledBackNotLink onClick={onClick}><LeftChevron /><StyledText>{title}</StyledText></StyledBackNotLink>
  }
  if (link) {
    return <StyledBackLink to={link}><LeftChevron /><StyledText>{title}</StyledText></StyledBackLink>
  }
  return <><LeftChevron /><StyledText>{title}</StyledText></>;
};
