import styled from 'styled-components';

import { BodyText, H3 } from '@/components/designSystem/Typography';
import { FlexColumnCenter, FlexRow } from '@/components/designSystem/Layout';

export const StyledOptionContainer = styled(FlexRow)`
  padding-left: 1rem;
  min-height: 6rem;
  width: 40rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.secondary};
  border-radius: 5px;

  &:hover {
    box-shadow: ${({ theme }) => theme.color.shadow.shadowSmallInset};
  }
`;

export const StyledOptionTextContainer = styled(FlexColumnCenter)`
  padding: 1rem;
`;

export const StyledOptionTextTitle = styled(H3)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: 600;
`;

export const StyledOptionTextDescription = styled(BodyText)`
  color: ${({ theme }) => theme.color.primary};
`;
