import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Formik } from 'formik';

import { getOr, noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ISettings } from '@/types/settings';
import { ICustomField } from '@/types/customField';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ChangesRequestedValuesContext } from '../../context';
import { companyContactsStepValidation } from './validation';
import { CompanyContactsStepForm } from './form';
import { IChangesRequestedValues, IChangesRequestedValuesAction } from '../../types';
import { UPDATE_COMPANY_CONTACTS } from '../../reducer';
import { useAdditionalPersonalInformationFields } from './useAdditionalPersonalInformationFields';
import { ICompanyContactsValues } from './types';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { useMutation } from '@apollo/react-hooks';
import { mapChangesRequestedValuesToStakeholders } from './functions';
import gql from 'graphql-tag';

export const CHANGE_REQUEST_COMPANY_CONTACTS = gql`
  mutation changeRequestCompanyContact($changeRequestId: ID!, $stakeholders: Stakeholders!) {
    change_request_company_contact(changeRequestId: $changeRequestId, stakeholders: $stakeholders) {
      customer { id }
    }
  }
`;

interface IProps {
  dispatch: Dispatch<IChangesRequestedValuesAction>,
  providerCompanySettings: ISettings,
  customContactFields: ICustomField[],
  nextStepPath: string | null,
}

export const CompanyContactsStep = ({
  dispatch,
  providerCompanySettings,
  customContactFields,
  nextStepPath,
}: IProps) => {

  const history = useHistory();
  const { search } = useLocation();
  const changesRequestedValues = useContext(ChangesRequestedValuesContext);
  const changeRequest = changesRequestedValues?.changeRequest;
  const chosenCompanyType = getOr(null, 'companyProfile.type', changesRequestedValues);
  const enableOwnershipStake = getOr(true, 'enableOwnershipStake', providerCompanySettings);
  const requireOwnershipStake = getOr(true, 'requireOwnershipStake', providerCompanySettings);

  const additionalPersonalInformationFields = useAdditionalPersonalInformationFields({
    providerCompanySettings,
    chosenCompanyType,
  });

  const firstUpdate = useRef(true);

  const [changeRequestCompanyInfo] = useMutation(CHANGE_REQUEST_COMPANY_CONTACTS);

  const submitStep = () => {

    if (!changesRequestedValues) {
      return noop;
    }

    const companyContacts = mapChangesRequestedValuesToStakeholders(changesRequestedValues);

    return changeRequestCompanyInfo({
      variables: {
        changeRequestId: changeRequest?.id,
        stakeholders: companyContacts,
      }
    })
      .then(() => {
        history.push({ pathname: `${changesRequestedValues.basePath}/${nextStepPath}`, search });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep();
  }, [changesRequestedValues]);

  const dispatchValues = (values: ICompanyContactsValues) => {
    dispatch({ type: UPDATE_COMPANY_CONTACTS, payload: values })
  }

  if (!changesRequestedValues || !providerCompanySettings || !additionalPersonalInformationFields) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={changesRequestedValues.companyContacts}
      validationSchema={companyContactsStepValidation(requireOwnershipStake, additionalPersonalInformationFields, customContactFields)}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <CompanyContactsStepForm
        enableOwnershipStake={enableOwnershipStake}
        additionalPersonalInformationFields={additionalPersonalInformationFields}
        customContactFields={customContactFields}
      />
    </Formik>
  )
};
