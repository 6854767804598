import React, { Dispatch, SetStateAction } from 'react';

import { formatPhone } from '@/utils/format';

import { UserInfo } from '@/components/UserInfo';
import { StyledModal } from '@/modals/StyledModal';
import { IconLabeledComponent } from '@/components/_oldComponents/IconLabeledComponent';
import { H4, StyledExternalLink } from '@/components/designSystem/Typography';

import { ReactComponent as PhoneIcon } from '@/assets/phone.svg';
import { ReactComponent as PhoneExtensionIcon } from '@/assets/extension-number.svg';
import { ReactComponent as EmailIcon } from '@/assets/email.svg';

import {
  ContactInfoContainer,
  ContactDetailsContainer,
} from './styled';
import { Button } from '@/components/designSystem/buttons';

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  name: string,
  position: string,
  email: string,
  extension?: string,
  phoneNumber: string,
}

const ContactInfoModal = ({
  isOpen, setIsOpen, name, position, email, phoneNumber, extension
}: IProps) => (
  <StyledModal
    title='Contact Info'
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    blockClose
  >
    <ContactInfoContainer>
      <UserInfo name={name} detail={position} />
      <ContactDetailsContainer>
        <H4 bold>Contact Details</H4>
        <IconLabeledComponent Icon={EmailIcon}>
          <StyledExternalLink href={`mailto:${email}`}>{email}</StyledExternalLink>
        </IconLabeledComponent>
        <IconLabeledComponent Icon={PhoneIcon}>{formatPhone(phoneNumber)}</IconLabeledComponent>
        {extension && extension !== '' && <IconLabeledComponent Icon={PhoneExtensionIcon}>{extension}</IconLabeledComponent>}
      </ContactDetailsContainer>
      <Button wide primary onClick={() => setIsOpen(false)}>Done</Button>
    </ContactInfoContainer>
  </StyledModal>
);

export default ContactInfoModal;
