import React, { useState } from 'react';
import { ErrorMessage, useField } from 'formik';

import { FileUpload, IFile } from '@/components/FileUpload/FileUpload';
import { Row } from '@/components/designSystem/Form/styled';
import { StyledDescriptionText, StyledNoteText, StyledErrorText, StyledFieldContainer } from './styled';
import { FieldLabel } from './FieldLabel';

interface IProps {
  name: string,
  label?: string,
  required?: boolean,
  description?: string,
  notes?: string,
  onlyOne?: boolean | null,
  prompt?: string,
}

export const FileUploadField = ({
  required,
  name = '',
  label,
  description,
  notes,
  onlyOne,
  prompt,
}: IProps) => {
  const [field, meta, helpers] = useField(name);

  const showError = !!(meta.touched && meta.error);

  const { setValue } = helpers;

  const [visibleFiles, setVisibleFiles] = useState(field.value);

  const setFileFieldState = (files: IFile[]) => {
    setVisibleFiles(files);
    const uploadedFiles = files.filter((x) => x.signedUrl);
    setValue(uploadedFiles);
  };

  return (
    <StyledFieldContainer>
      <Row>
        {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
        {notes ? <StyledNoteText>{notes}</StyledNoteText> : false}
      </Row>
      <FileUpload
        allFiles={visibleFiles}
        setAllFiles={setFileFieldState}
        onlyOne={onlyOne}
        error={showError}
        prompt={prompt}
      />
      {description ? <StyledDescriptionText>{description}</StyledDescriptionText> : false}
      <StyledErrorText>
        { (showError && name) ? <ErrorMessage name={name} /> : false }
      </StyledErrorText>
    </StyledFieldContainer>
  )
};
