import React, { Dispatch, SetStateAction } from 'react';

import { FormikValues } from 'formik';

import { StyledModal } from '@/modals/StyledModal';
import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { Callout } from '@/components/Callout/Callout';

import { StyledPopupContainer, StyledActionContainer } from '../styled';
import { CREDIT_APPLICANT_STATUSES } from '@/constants';

interface IProps {
  isOpen: boolean,
  currentStatus: string,
  selectedStatus: string,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onConfirm: (formValues: FormikValues) => void
}

const displayStatus = (value: string) => {
  if (value === CREDIT_APPLICANT_STATUSES.APPROVED) {
    return 'approve'
  } else if (value === CREDIT_APPLICANT_STATUSES.DENIED) {
    return 'deny'
  }
  return ''
}

export const ConfirmationModal = ({ setIsOpen, isOpen, onConfirm, currentStatus, selectedStatus }: IProps) => (
  <StyledModal
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    blockClose
  >
    {(
      currentStatus === CREDIT_APPLICANT_STATUSES.IN_PROGRESS
      && (selectedStatus === CREDIT_APPLICANT_STATUSES.APPROVED
        || selectedStatus === CREDIT_APPLICANT_STATUSES.DENIED
      )) ? (
        <Callout color='label.warning'>
          <BodyText>Are you sure you want to {displayStatus(selectedStatus)} this partially completed application?</BodyText>
        </Callout>
      ) : false}
    <StyledPopupContainer>
      <BodyText>This comment will be shared with customer. Are you sure you want to continue?</BodyText>
    </StyledPopupContainer>
    <StyledActionContainer>
      <Button secondary onClick={() => setIsOpen(false)}>Cancel</Button>
      <Button onClick={onConfirm}>Ok</Button>
    </StyledActionContainer>
  </StyledModal>
);
