import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Formik } from 'formik';

import { getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { useAuth0 } from '@/providers/Auth0/context';

import { CPOnboardCompletedModal } from '@/modals/CPOnboardCompletedModal';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { OnboardValuesContext } from '../../context';
import { companyContactsStepValidation } from './validation';
import { CompanyContactsStepForm } from './form';
import { IOnboardValuesAction } from '../../types';
import { UPDATE_COMPANY_CONTACTS } from '../../reducer';
import { ICompanyContactsValues } from './types';
import { submitStep } from './functions';

interface IProps {
  dispatch: Dispatch<IOnboardValuesAction>,
}

export const CompanyContactsStep = ({
  dispatch,
}: IProps) => {
  const { signUpCreditProvider, loading: authloading } = useAuth0();

  const { pathname, search } = useLocation();
  const onboardValues = useContext(OnboardValuesContext);
  const firstUpdate = useRef(true);

  const [showCompletedModal, setShowCompletedModal] = useState(false);

  const basicInfo = getOr(null, 'basicInfo', onboardValues);

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep({
      onboardValues,
      pathname,
      search,
      signUpCreditProvider,
      setShowCompletedModal,
    });
  }, [onboardValues])

  const dispatchValues = (values: ICompanyContactsValues) => {
    dispatch({ type: UPDATE_COMPANY_CONTACTS, payload: values });
  }

  if (!onboardValues || !basicInfo || authloading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Formik
        initialValues={onboardValues.companyContacts}
        validationSchema={companyContactsStepValidation}
        onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
      >
        <CompanyContactsStepForm
          basicInfo={basicInfo}
        />
      </Formik>
      <CPOnboardCompletedModal
        isOpen={showCompletedModal}
        setIsOpen={setShowCompletedModal}
      />
    </>
  )
};
