import React from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { AdditionalQuestions } from './AdditionalQuestions';
import { CUSTOM_FIELD_TYPES } from '@/constants';

interface IProps {
  customerData: ICustomer,
  refetchCustomerData: () => void,
  canSeeEditControl: boolean,
}

export const AdditionalQuestionSection = ({ customerData, refetchCustomerData, canSeeEditControl }: IProps) => {

  const customFieldAnswers = get('customFieldAnswers', customerData);

  const additionalQuestionsAnswers = customFieldAnswers?.filter(
    (customFieldAnswer) => (customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION && customFieldAnswer.text)
  ) || [];

  return (
    <>
      {(additionalQuestionsAnswers.length) ? (
        <AdditionalQuestions customerId={customerData.id} additionalQuestionsAnswers={additionalQuestionsAnswers} refetch={refetchCustomerData} />
      ) : false }
    </>
  )};
