import React, { useState } from 'react';

import { Formik } from 'formik';

import { getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ICustomer } from '@/types/customer';
import { IProviderSettings } from '@/types/providerSettings';
import { NO_WEBSITE_VALUE } from '@/constants';

import { BodyText, H2 } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';

import { ReactComponent as BusinessTypeIcon } from '@/assets/business_type.svg';
import { ReactComponent as EstablishedIcon } from '@/assets/established.svg';

import { SectionContainer } from '../../../SectionContainer';
import { StyledGrayBox } from '../../../styled';
import {
  StyledCompanyInfoInformationColumn,
  StyledDescription,
  StyledIconLabel,
  StyledExternalLink
} from '../styled';
import { useInformationForm } from './useInformationForm';
import {
  buildAddressLine,
  formatBusinessVerificationMatchNumberToIcon,
} from '../functions';
import { EditInformationForm } from './EditInformationForm';
import { MutationFunction } from '@apollo/react-hooks';

interface IProps {
  customerData: ICustomer,
  refetch: () => void,
  providerUpdateCompany: MutationFunction,
  canSeeEditControl: boolean,
  providerSettings: IProviderSettings,
}

export const InformationSection = ({
  customerData, providerUpdateCompany, providerSettings, refetch, canSeeEditControl
}: IProps) => {
  const [isEditActive, setEditActive] = useState(false);

  const { initialValues, validation, handleSubmit } = useInformationForm(
    customerData, setEditActive, providerUpdateCompany, refetch
  );

  const billingAddress = customerData.latestApplication.billingAddress.address_line_1 ? customerData.latestApplication.billingAddress : null;
  const shippingAddress = customerData.latestApplication.shippingAddress.address_line_1 ? customerData.latestApplication.shippingAddress : null;
  const fullAddress = getOr(buildAddressLine(initialValues), 'seekerCompany.fullAddress', customerData);
  const businessVerificationComparison = getOr(
    {
      name: -1,
      address: -1,
      established: -1,
    },
    'seekerCompany.openCorporatesComparison',
    customerData
  );

  return (
    <SectionContainer
      title='Information'
      isEditEnabled={!isEditActive}
      canSeeEditControl={false} //{canSeeEditControl} //we are hiding edit from CPs
      editSection={() => setEditActive(true)}
      onCloseClick={() => setEditActive(false)}
    >
      <StyledGrayBox>
        {isEditActive && canSeeEditControl ? (
          <Formik initialValues={initialValues} onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)} validationSchema={validation} validateOnChange>
            <EditInformationForm setEditActive={setEditActive} providerSettings={providerSettings}/>
          </Formik>
        ) : (
          <>
            <Flex gap='0.5rem' align='center'>
              <H2 bold>{initialValues.name}</H2>
              {formatBusinessVerificationMatchNumberToIcon(businessVerificationComparison.name)}
            </Flex>
            <Flex justify='space-between'>
              <StyledCompanyInfoInformationColumn flexBasis='60'>
                <Flex gap='0.5rem' align='center'>
                  <BodyText>{fullAddress}</BodyText>
                  {formatBusinessVerificationMatchNumberToIcon(businessVerificationComparison.address)}
                </Flex>
                {billingAddress ? <BodyText>Billing Address: {buildAddressLine(billingAddress)}</BodyText> : false}
                {shippingAddress ? <BodyText>Shipping Address: {buildAddressLine(shippingAddress)}</BodyText> : false}
                {(initialValues.website && initialValues.website !== NO_WEBSITE_VALUE)
                  ? <StyledExternalLink
                    href={(!/^https?:\/\//i.test(initialValues.website)) ? `https://${initialValues.website}` : initialValues.website}
                    target='_blank'>
                    {initialValues.website}
                  </StyledExternalLink>
                  : false }
                <BodyText>{initialValues.phoneNumber}</BodyText>
              </StyledCompanyInfoInformationColumn>
              <StyledCompanyInfoInformationColumn flexBasis='40'>
                <StyledIconLabel>
                  <BusinessTypeIcon />
                  <BodyText>{initialValues.type}</BodyText>
                </StyledIconLabel>
                { +initialValues.established ? (
                  <StyledIconLabel>
                    <EstablishedIcon />
                    <Flex gap='0.5rem' align='center'>
                      <BodyText>Established {initialValues.established}</BodyText>
                      {formatBusinessVerificationMatchNumberToIcon(businessVerificationComparison.established)}
                    </Flex>
                  </StyledIconLabel>
                ) : false }
              </StyledCompanyInfoInformationColumn>
            </Flex>
            <StyledDescription>{initialValues.description}</StyledDescription>
          </>
        )}
      </StyledGrayBox>
    </SectionContainer>
  )
};
