import React, { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';

import { useQuery } from '@apollo/react-hooks';
import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';

import { get } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { IUserCompanyRole } from '@/types/companyUser';

import { PrivateRoute } from '@/routes/Routes';

import { CurrentUserContext } from '@/providers/CurrentUser';

import { TabbedNavContainer } from '@/containers/TabbedNavContainer';

import { GeneralSettings } from '@/views/settings/GeneralSettings';
import { IntegrationSettings } from '@/views/settings/IntegrationSettings';
import { BillingSettings } from '@/views/settings/BillingSettings';

export const SettingsApp = () => {
  const [companyId] = useLocalStorage('company-id');

  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY)

  const currentUserData = useContext(CurrentUserContext);

  const parentCompanyId = get('parentCompany.id', parentCompany)
  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData)
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId), userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId))
  const isAdmin = get('isAdmin', currentUserCompanyRole[0])

  let navigationOptions = [
    { title: 'General', path: '/settings/general', Component: GeneralSettings },
  ];

  const forAdmin = [
    { title: 'Plan & Billing', path: '/settings/billing', Component: BillingSettings },
    { title: 'Integrations', path: '/settings/integrations', Component: IntegrationSettings },
  ]

  if (isAdmin) {
    navigationOptions = [...navigationOptions, ...forAdmin];
  }

  return (
    <TabbedNavContainer title='Settings' options={navigationOptions}>
      <Switch>
        <Redirect exact from='/settings/' to='/settings/general' />
        {
          navigationOptions.map(({ path, Component }) => <PrivateRoute path={path} component={Component} key={path} />)
        }
      </Switch>
    </TabbedNavContainer>
  );
};