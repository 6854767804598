import React, { Dispatch, SetStateAction, useContext } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { ADD_MANUAL_INSTITUTION } from '@/graphql/mutations/addManualInstitution';

import { VisitorContext } from '@/providers/VisitorContext'

import { FieldArray } from 'formik';
import Yup, { DEFAULT_ERROR_MESSAGES, isValidRoutingNumber, isValidAccountNumber } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

import { AccountsSection } from './AccountsSection';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';

const manualAccountValidation = Yup.object({
  account_type: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  account_number: isValidAccountNumber(),
  routing_number: isValidRoutingNumber(),
});

const validation = Yup.object().shape({
  contactEmail: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  contactName: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  contactPhone: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
  contactTitle: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  institutionAddress: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  institutionName: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  accounts: Yup.array().of(manualAccountValidation),

});

const manualAccountFields = {
  account_type: '',
  account_number: '',
  routing_number: '',
};

const fields = {
  contactEmail: '',
  contactName: '',
  contactPhone: '',
  contactTitle: '',
  institutionAddress: '',
  institutionName: '',
  accounts: [manualAccountFields],
};

interface IAccount {
  account_type: string;
  account_number: string;
  routing_number: string;
}

interface IFields {
  contactEmail: string,
  contactName: string,
  contactPhone: string,
  contactTitle: string,
  institutionAddress: string,
  institutionName: string,
  accounts: IAccount[],
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

export const AddManualInstitutionModal = ({
  isOpen, setIsOpen, onSuccess,
}: IProps) => {
  const [addManualInstitution, { loading }] = useMutation(ADD_MANUAL_INSTITUTION);
  const visitorData = useContext(VisitorContext);

  const handleSubmit = async ({
    contactEmail,
    contactName,
    contactPhone,
    contactTitle,
    institutionAddress,
    institutionName,
    accounts,
  }: IFields) => {
    const manualInstitutionAttrs = {
      contactEmail,
      contactName,
      contactPhone,
      contactTitle,
      institutionAddress,
      institutionName,
      accounts,
    };
    addManualInstitution({ variables: { manualInstitutionAttrs, visitorData } })
      .then((response) => {
        setIsOpen(false);
        onSuccess && onSuccess(response);
      });
  };

  return (
    <StyledModalForm
      title='Manually Add Bank'
      submitButtonText='Add'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      maxWidth='768px'
      maxHeight='800px' // TODO make large
      blockClose={true}
    >
      <TextField required type='text' name='institutionName' label='Bank Name' placeholder='Bank of America' />
      <TextField required type='text' name='institutionAddress' label='Bank Address' placeholder='123 example st, New York, NY, USA, 10001' />
      <TextField required type='text' name='contactName' label='Bank Contact Name' placeholder='John Smith' />
      <TextField required type='text' name='contactEmail' label='Bank Contact Email' placeholder='john@example.org' />
      <PhoneNumberField required name='contactPhone' label='Bank Contact Phone' placeholder='Phone number' />
      <TextField required type='text' name='contactTitle' label='Bank Contact Title' placeholder='Branch Manager' />
      <FieldArray name='accounts'>
        {(arrayHelpers) => <AccountsSection arrayHelpers={arrayHelpers as any} />}
      </FieldArray>
    </StyledModalForm>
  );
};
