import React from 'react';
import styled from 'styled-components';

interface AvatarContainerProps {
  bgColor: string;
  displayStyle: string;
}

const AvatarContainer = styled.div<AvatarContainerProps>`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
  display: ${(props) => props.displayStyle};
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  color: white;
  font-weight: bold;
`;

interface InitialAvatarProps {
  name: string;
  innerContent?: boolean;
}

const initialColorMap: { [key: string]: string } = {
  A: '#f39c12',
  B: '#2ecc71',
  C: '#e74c3c',
  D: '#8e44ad',
  E: '#3498db',
  F: '#e67e22',
  G: '#1abc9c',
  H: '#9b59b6',
  I: '#2c3e50',
  J: '#34495e',
  K: '#c0392b',
  L: '#16a085',
  M: '#27ae60',
  N: '#2980b9',
  O: '#d35400',
  P: '#7f8c8d',
  Q: '#95a5a6',
  R: '#f1c40f',
  S: '#e67e22',
  T: '#d35400',
  U: '#c0392b',
  V: '#8e44ad',
  W: '#2ecc71',
  X: '#f39c12',
  Y: '#9b59b6',
  Z: '#2980b9',
};

export const InitialAvatar = ({ name, innerContent } : InitialAvatarProps) => {
  const getInitials = (name : string) => {
    if (!name) return '';
    const nameArray = name.trim().split(' ');
    if (nameArray.length === 1) {
      return nameArray[0][0].toUpperCase();
    }

    const firstInitial = nameArray[0][0];
    const lastInitial = nameArray[nameArray.length - 1][0];

    return `${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;
  };

  const bgColor = initialColorMap[getInitials(name)[0]] || '#bdc3c7';
  const displayStyle = innerContent? 'inline-flex' : 'flex';

  return (
    <AvatarContainer bgColor={bgColor} displayStyle={displayStyle}>
      {getInitials(name)}
    </AvatarContainer>
  );
};

