import gql from 'graphql-tag';

export const INVITE_CUSTOMER = gql`
  mutation InviteCustomer(
    $companyName: String!,
    $name: String!,
    $email: String!,
    $phoneNumber: String,
    $autoBav: Boolean,
    $enablePersonalGuarantee: Boolean,
    $requirePersonalGuarantee: Boolean,
    $requireBankInfo: Boolean,
    $customMessage: String!,
    $customerId: String
  ) {
    invite_customer(
      company_name: $companyName,
      name: $name,
      email: $email,
      phone_number: $phoneNumber,
      auto_bav: $autoBav,
      enable_personal_guarantee: $enablePersonalGuarantee,
      require_personal_guarantee: $requirePersonalGuarantee,
      require_bank_info: $requireBankInfo,
      custom_message: $customMessage,
      customer_id: $customerId
    ) {
      id
    }
  }
`;

export const BULK_UPLOAD_CUSTOMERS = gql`
  mutation BulkUploadCustomers($customers: [ReferralInput!]!) {
    bulk_upload_customers(customers: $customers)
  }
`;
