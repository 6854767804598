import React from 'react';

import { get } from 'lodash/fp';

import { ICurrentCompany } from '@/types/currentCompany';

import { BorderedContainer } from '@/containers/BorderedContainer';

import { H4 } from '@/components/designSystem/Typography';
import { LabeledComponent } from '@/components/LabeledComponent';
import { Flex } from '@/components/designSystem/Layout';

import { StyledDnbLogo, StyledPageTitleContainer, StyledRow } from './styled';
import { INTEGRATIONS } from './constants';
import { EditCredsButton } from './EditCredsButton';

interface IDnbSettingsProps {
  refetch: () => void,
  currentCompany: ICurrentCompany,
}

export const DnbSettings = ({ currentCompany, refetch }: IDnbSettingsProps) => {
  const dnbApiKey = get('settings.dnbApiKey', currentCompany);
  const dnbApiSecret = get('settings.dnbApiSecret', currentCompany);

  return (
    <>
      <StyledPageTitleContainer>
        <StyledDnbLogo />
        <EditCredsButton type={INTEGRATIONS.DNB} refetch={refetch}/>
      </StyledPageTitleContainer>
      <BorderedContainer>
        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Consumer/API Key'>
            <StyledRow>
              <H4 bold>{dnbApiKey ? '**********' : 'None'}</H4>
            </StyledRow>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Consumer/API Secret'>
            <H4 bold>{dnbApiSecret ? '**********' : 'None'}</H4>
          </LabeledComponent>
        </Flex>
      </BorderedContainer>
    </>
  );
};