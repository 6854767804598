import gql from 'graphql-tag';

export const CREATE_COMPANY_DOCUMENT = gql`
  mutation CreateCompanyDocument($signedFileUploadData: SignedFileUploadInput!, $visitorData: VisitorData) {
    createCompanyDocument(signed_file_upload_data: $signedFileUploadData, visitorData: $visitorData) {
      storedFile {
        id
        path
        author {
          id
          email
        }
        originalFilename
        signedUrl
      }
    }
  }
`;
