import { Dispatch, SetStateAction } from 'react';

import { noop } from 'lodash/fp';
import { formatPhoneNumberForRequest } from '@/utils/format';
import { stripStepFromPath } from '@/utils/stripStepFromPath';

import { IAddressComponent, IGooglePlace } from '@/types/googlePlace';

import { ICPOnboardOptions } from '@/providers/Auth0/context';

import { IOnboardValues } from '../../types';

const mapGooglePlaceToValues = (addressComponents: IAddressComponent[]) => ({
  state: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_1'))?.long_name ||
    addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_2'))?.long_name || '',
  city: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('locality'))?.long_name ||
    addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_town'))?.long_name || '',
  postalCode: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_code'))?.long_name || '',
  country: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('country'))?.short_name || null,
  streetNumber: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('street_number'))?.long_name || '',
  street: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('route'))?.long_name || '',
});

export const setFieldsFromGooglePlace = (place: IGooglePlace, parentKey: string, setFieldValue: (field: string, value: string | null) => void) => {
  if (place.address_components) {
    const addressValues = mapGooglePlaceToValues(place.address_components);
    setFieldValue(`${parentKey}.address.postal_code`, addressValues.postalCode);
    setFieldValue(
      `${parentKey}.address.address_line_1`,
      `${addressValues.streetNumber}${addressValues.street && ' '}${addressValues.street}`
    );
    setFieldValue(`${parentKey}.address.city`, addressValues.city)
    setFieldValue(`${parentKey}.address.state`, addressValues.state);
    setFieldValue(`${parentKey}.address.country`, addressValues.country);
  }
};

const mapOnboardValuesToStakeholders = (onboardValues: IOnboardValues) => {

  const {
    basicInfo,
    companyContacts
  } = onboardValues;

  return [
    ...(companyContacts.isCurrentUserOwner
      ? []
      : [{
        name: basicInfo.name,
        email: basicInfo.email,
        phoneNumber: formatPhoneNumberForRequest(basicInfo.phoneNumber),
        position: basicInfo.position,
        isOwner: false,
      }]
    ),
    ...companyContacts.owners.map((owner, index) => ({
      ...owner,
      phoneNumber: formatPhoneNumberForRequest(owner.phoneNumber),
      ownershipPercentage: +owner.ownershipPercentage,
      isOwner: true,
    })),
    ...(companyContacts.additionalContacts
      ? companyContacts.additionalContacts.map((additionalContact) => ({
        ...additionalContact,
        phoneNumber: formatPhoneNumberForRequest(additionalContact.phoneNumber),
      }))
      : []
    ),
  ]
}

export const mapOnboardValuesToOnboardData = (onboardValues: IOnboardValues, referralPath: string) => {
  const {
    basicInfo,
    companyProfile,
  } = onboardValues;

  return {
    currentUser: {
      name: basicInfo.name,
      email: basicInfo.email,
      phoneNumber: formatPhoneNumberForRequest(basicInfo.phoneNumber),
      position: basicInfo.position,
      referralPath,
    },
    company: {
      name: companyProfile.name,
      type: companyProfile.type,
      description: companyProfile.description,
      established: +companyProfile.established,
      phoneNumber: formatPhoneNumberForRequest(companyProfile.phoneNumber),
      address: JSON.stringify(companyProfile.address),
      website: companyProfile.website,
      otherNames: companyProfile.otherNames,
    },
    stakeholders: mapOnboardValuesToStakeholders(onboardValues)
  }
};

interface ISubmitStepArgs {
  onboardValues: IOnboardValues | null,
  pathname: string,
  search: string,
  signUpCreditProvider: (
    email: string, password: string, onboardVariables: ICPOnboardOptions
  ) => Promise<unknown>,
  setShowCompletedModal: Dispatch<SetStateAction<boolean>>,
}

export const submitStep = ({
  onboardValues,
  pathname,
  search,
  signUpCreditProvider,
  setShowCompletedModal,
}: ISubmitStepArgs) => {

  if (!onboardValues) {
    return noop;
  }

  const onboardVariables = {
    onboardData: mapOnboardValuesToOnboardData(
      onboardValues,
      `${stripStepFromPath(pathname)}${search}`
    ),
    redirectTo: '/'
  }

  const { basicInfo: { email, password } } = onboardValues;

  return signUpCreditProvider(email, password, onboardVariables).then(() => {
    setShowCompletedModal(true);
  });
}
