import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { UPDATE_AGREEMENT_TEXT } from '@/graphql/mutations/updateAgreementText';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { H3, StyledLinkButtonUnderline, } from '@/components/designSystem/Typography';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

import { FlexColumnItemsCenter } from '../components/designSystem/Layout';

const validation = Yup.object().shape({
  agreementText: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

const StyledTextAreaField = styled(TextAreaField)`
  height: 100%;
  & > textarea {
    height: 100%;
  }
`;

const Container = styled(FlexColumnItemsCenter)`
  justify-content: space-between;
  padding: 20px;
  & > * {
    margin-top: 50px;
  }
`;

interface IFields {
  agreementText: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
  agreementText: string,
}

export const UpdateAgreementModal = ({
  isOpen, setIsOpen, onSuccess, agreementText,
}: IProps) => {
  const [updateAgreementText, { loading }] = useMutation(UPDATE_AGREEMENT_TEXT);
  const [showCustomerSupport, setShowCustomerSupport] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const fields = {
    agreementText,
  };

  // eslint-disable-next-line no-shadow
  const handleSubmit = async ({ agreementText }: IFields) => {
    const variables = {
      agreementText,
    };

    updateAgreementText({ variables })
      .then((response) => {
        showToast({
          title: 'Agreement Successfully Update!',
          description: 'Going forward this updated agreement will be used.',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
        setDisabled(false);
      })
      .catch((error) => {
        showToast({
          title: 'Error!',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
        setShowCustomerSupport(true)
        setDisabled(true);
      });
  };

  const onClose = () => {
    setShowCustomerSupport(false);
    setDisabled(false);
  };

  return (
    <StyledModalForm
      title='Edit Credit Terms and Conditions of Sale'
      submitButtonText='Save'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      afterClose={onClose}
      isDisabled={isDisabled}
      setIsOpen={setIsOpen}
      loading={loading}
      width='80vw'
      maxWidth='1200px'
      height='80vh'
      maxHeight='800px'
      blockClose={true}
    >
      {!showCustomerSupport ?
        <StyledTextAreaField minRows={500} maxRows={500} required name='agreementText' />
        :
        <>
          <Container>
            <H3>We detected that your T&Cs have some styling elements that may cause some formatting issues. </H3>
            <H3>Please contact your sales rep or &nbsp;
              <StyledLinkButtonUnderline onClick={(e) => {window.open('mailto:support@nectarinecredit.com','_blank',);e.preventDefault();}}>
                support@nectarinecredit.com
              </StyledLinkButtonUnderline> &nbsp;
            for us to fix the formatting for you.
            </H3>
          </Container>
        </>
      }
    </StyledModalForm>
  );
};
