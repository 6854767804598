import gql from 'graphql-tag';

export const GET_FINICITY_CUSTOMER = gql`
  query {
    getFinicityCustomer {
      id
      finicityAccounts {
        id
      }
    }
  }
`;
