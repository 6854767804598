import React from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { H3, BodyText } from '@/components/designSystem/Typography';

import { StyledCallout } from './styled';

interface IAdditionalRefRequiredMessageProps {
  customer: ICustomer,
  number: number,
}

export const AdditionalRefRequiredMessage = ({ number, customer }: IAdditionalRefRequiredMessageProps) => {
  const name = get('seekerCompany.name', customer);
  const isManualCustomer = get('isManualCustomer', customer);

  return (
    !isManualCustomer?
      <StyledCallout color='pastelRed'>
        <H3 bold>Additional Vendor References Required</H3>
        <BodyText>{name} needs to add {number} more reference{number > 1 ? 's' : ''} </BodyText>
      </StyledCallout>
      : null
  );
};