import React, { useState } from 'react';
import { E164Number } from 'libphonenumber-js/types';

import { useFormikContext } from 'formik';

import { BodyText, H3 } from '@/components/designSystem/Typography';
import { PhoneNumberInput } from '@/components/designSystem/Form/PhoneNumberInput';
import { TextField } from '@/components/designSystem/Form/TextField';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { TextInput } from '@/components/designSystem/Form/TextInput';

import { IAddContactCustomFieldValues } from '.';
import {
  StyledPreviewFieldContainer,
  StyledForm,
  StyledFormInnerContainer,
  StyledLeftFormContainer,
  StyledContactFormRow
} from '../styled';
import { FooterContainer,  } from '../../StyledModal/styled';
import { CustomFieldPreviewContainer } from '../CustomFieldPreviewContainer';
import { CustomContactSection } from '@/views/onboard/CreditApplicantOnboard/steps/CompanyContactsStep/CustomContactSection';
import { AddCustomFieldBackButton } from '../BackButton';
import { Button } from '@/components/designSystem/buttons';

export const AddContactCustomFieldForm = () => {
  const { values } = useFormikContext<IAddContactCustomFieldValues>();

  return (
    <StyledForm>
      <StyledFormInnerContainer>
        <StyledLeftFormContainer>
          <AddCustomFieldBackButton />
          <TextField required type='text' name='label' label='Label' />
          <CheckboxField name='contact.nameRequired'><BodyText>Make Name field required</BodyText></CheckboxField>
          <CheckboxField name='contact.phoneRequired'><BodyText>Make Phone field required</BodyText></CheckboxField>
          <CheckboxField name='contact.emailRequired'><BodyText>Make Email field required</BodyText></CheckboxField>
          <CheckboxField name='contact.positionRequired'><BodyText>Make Position field required</BodyText></CheckboxField>
        </StyledLeftFormContainer>
        <CustomFieldPreviewContainer>
          <H3>Credit Applicant will see:</H3>
          <StyledPreviewFieldContainer>
            <H3 bold>{values.label || 'Example Label'}</H3>
            <CustomContactSection
              parentKey='example'
              label={values.label}
              settings={{
                nameRequired: values.contact.nameRequired,
                extensionRequired: true,
                emailRequired: values.contact.emailRequired,
                phoneRequired: values.contact.phoneRequired,
                positionRequired: values.contact.positionRequired,
                showEmail: true,
                showExtension: true,
                showName: true,
                showPhone: true}
              } />
          </StyledPreviewFieldContainer>
        </CustomFieldPreviewContainer>
      </StyledFormInnerContainer>
      <FooterContainer>
        <Button wide primary type='submit'>Add Custom Field</Button>
      </FooterContainer>
    </StyledForm>
  )
};
