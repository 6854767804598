import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { ICustomField } from '@/types/customField';
import { ISettings } from '@/types/settings';
import { getOr } from 'lodash/fp';

import { IAdditionalPersonalInformationFields } from './types';

const getConditionalStringValidation = (isRequired: boolean) => isRequired ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.string().optional();

const baseUserValidation = {
  name: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  email: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  position: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
};

const addressValidation = (isRequired: boolean) => Yup.object({
  address_line_1: getConditionalStringValidation(isRequired),
  address_line_2: Yup.string().optional(),
  city: getConditionalStringValidation(isRequired),
  state: getConditionalStringValidation(isRequired),
  postal_code: getConditionalStringValidation(isRequired),
  country: getConditionalStringValidation(isRequired),
}).shape({});

const ownershipPercentageValidation = Yup.number().min(0, 'Cannot be less than 0%').max(100, 'Cannot be more than 100%');

const ssnSinNumberValidation = Yup.string().matches(/^\d{3}-?(?:\d{2}-?\d{4}|\d{3}-?\d{3})$/, DEFAULT_ERROR_MESSAGES.SSN);

const ownerValidation = (
  enableOwnershipStake: boolean,
  requireOwnershipStake: boolean,
  additionalPersonalInformationFields: IAdditionalPersonalInformationFields
) => {
  const {
    ownerAddressRequired,
    ownerSsnSinNumberRequired,
    ownerDriverLicenseNoRequired,
    ownerDriverLicenseStateRequired,
    ownerDateOfBirthRequired
  } = additionalPersonalInformationFields;

  return Yup.object({
    ...baseUserValidation,
    ownershipPercentage: enableOwnershipStake && requireOwnershipStake ? ownershipPercentageValidation.required(DEFAULT_ERROR_MESSAGES.REQUIRED) : ownershipPercentageValidation,
    address: addressValidation(ownerAddressRequired),
    ssnSinNumber: ownerSsnSinNumberRequired ? ssnSinNumberValidation.required(DEFAULT_ERROR_MESSAGES.REQUIRED) : ssnSinNumberValidation.optional(),
    driverLicenseNo: getConditionalStringValidation(ownerDriverLicenseNoRequired),
    driverLicenseState: getConditionalStringValidation(ownerDriverLicenseStateRequired),
    dob: getConditionalStringValidation(ownerDateOfBirthRequired),
  }).shape({})
};

const customContactsValidation = (customContactFields: ICustomField[]) => {
  const shape = customContactFields.reduce((acc, customContactField) => {

    const {
      showName,
      showEmail,
      showPhone,
      showExtension,
      nameRequired,
      emailRequired,
      phoneRequired,
      positionRequired,
      extensionRequired
    } = customContactField.contact;

    return {
      ...acc,
      [customContactField.id]: Yup.object({
        position: positionRequired
          ? Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED)
          : Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).optional(),
        ...(showName && {
          name: nameRequired
            ? Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED)
            : Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).optional()
        }),
        ...(showEmail && {
          email: emailRequired
            ? Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED)
            : Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().optional()
        }),
        ...(showPhone && {
          phoneNumber: phoneRequired
            ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE)
            : Yup.string()
              .test('is-valid-phone', DEFAULT_ERROR_MESSAGES.PHONE, (value) => {
                return !value || Yup.string().phone('US', false).isValidSync(value);
              })
        }),
        ...(showExtension && {
          extension: extensionRequired
            ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED)
            : Yup.string().optional()
        }),
      })
    }
  }, {})

  return Yup.object().shape(shape);
}

export const companyContactsStepValidation = (
  companySettings: ISettings,
  additionalPersonalInformationFields: IAdditionalPersonalInformationFields,
  customContactFields: ICustomField[],
) => {
  const enableOwnershipStake = getOr(true, 'enableOwnershipStake', companySettings);
  const requireOwnershipStake = getOr(true, 'requireOwnershipStake', companySettings);
  const enableOwner = getOr(true, 'enableOwner', companySettings);
  const requireOwner = getOr(true, 'requireOwner', companySettings);

  return Yup.object().shape({
    owners: Yup.array()
      .of(ownerValidation(enableOwnershipStake, requireOwnershipStake, additionalPersonalInformationFields))
      .ensure()
      .strip(!(enableOwner && requireOwner)),
    customContacts: customContactFields.length ? customContactsValidation(customContactFields) : Yup.object().shape({}),
  })
}
