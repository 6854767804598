import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FieldArray, useFormikContext } from 'formik';

import { useAutoscrollToError } from '@/views/onboard/useAutoscrollToError';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { FlexColumn, FlexRowEnd } from '@/components/designSystem/Layout';
import { BodyText, H2 } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { ReactComponent as Plus } from '@/assets/plus.svg';
import { OnboardValuesContext } from '../../../../context';
import { IFinancialHealthValues } from '../../types';
import { StyledCheckboxWrapper, StyledForm, StyledNextButton, StyledTitleContainer } from '../../../../styled';
import { StyledCalloutAction, StyledCalloutContainer } from '../../styled';
import { initialManualBankFields } from '../../initialValues';
import { ManualBankSection } from './ManualBankSection';
import { useNoBankInfo } from './useNoBankInfo';

interface IProps {
  requireBankInfo: boolean,
  requestBankStatements: boolean,
  automaticBankVerificationEnabled: boolean,
}

export const ManualBankView = ({ requireBankInfo, requestBankStatements, automaticBankVerificationEnabled } : IProps) => {
  const history = useHistory();
  const { search } = useLocation();

  const onboardValues = useContext(OnboardValuesContext);

  const { values, setFieldValue, errors, isSubmitting } = useFormikContext<IFinancialHealthValues>();

  useAutoscrollToError(errors, isSubmitting)

  useEffect(() => {
    setFieldValue('isManualBank', true)
  }, []);

  const {
    noBankInfo,
    noBankInfoClickHandle
  } = useNoBankInfo({
    values,
    setFieldValue,
  });

  if (!onboardValues) {
    return <LoadingSpinner />
  }

  const goToConnectBankView = () => {
    history.push({ pathname: `${onboardValues.basePath}/bank`, search });
  };

  return (
    <StyledForm>
      {automaticBankVerificationEnabled
        ? (
          <StyledCalloutContainer>
            <StyledCalloutAction color='tagGreen'>
              <FlexColumn>
                <H2 bold>Want To Skip This Step?</H2>
                <BodyText>We can fill this page for you. Just click the button to easily verify your account.</BodyText>
              </FlexColumn>
              <Button primary bold onClick={goToConnectBankView}>Easy bank verification</Button>
            </StyledCalloutAction>
          </StyledCalloutContainer>
        ) : false}
      <StyledTitleContainer>
        <H2 bold>Manually Enter Bank Info</H2>
        <BodyText>Help us verify your bank account by providing the following information</BodyText>
      </StyledTitleContainer>
      {!requireBankInfo ? (
        <StyledCheckboxWrapper>
          <Checkbox checked={noBankInfo} onChange={noBankInfoClickHandle}>
            <BodyText bold>I don't have bank information available</BodyText>
          </Checkbox>
        </StyledCheckboxWrapper>
      ) : false}
      <FieldArray
        name='manualBanks'
        render={(arrayHelpers) => (
          <>
            {
              values.manualBanks ? values.manualBanks.map((_owner, index) => (
                <ManualBankSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  parentKey={`manualBanks[${index}]`}
                  onRemoveClick={() => arrayHelpers.remove(index)}
                  requestBankStatements={requestBankStatements}
                  disabled={noBankInfo}
                />
              )) : false
            }
            { !noBankInfo
              ? (<Button
                secondary
                LeftIcon={Plus}
                onClick={() => arrayHelpers.push(initialManualBankFields(requestBankStatements))}
              >
              Add Bank
              </Button>)
              : false }
          </>
        )}
      />
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Next</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )}
;
