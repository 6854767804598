import React, { Dispatch, SetStateAction, useState } from 'react';

import { H2 } from '@/components/designSystem/Typography';
import { ReactComponent as CloseIcon } from '@/assets/x_close.svg';

import {
  HeaderContainer,
  ModalContainer,
  IconContainer,
} from './styled';
import { FlexRowItemsCenter } from '@/components/designSystem/Layout';
import { LoadingSpinner } from '@/components/LoadingSpinner';


export interface IStyledModalProps {
  isOpen: boolean,
  children: React.ReactNode,
  title?: string,
  width?: string,
  height?: string,
  maxHeight?: string,
  maxWidth?: string,
  setIsOpen?: Dispatch<SetStateAction<boolean>>,
  afterClose?: () => void,
  headerAction?: JSX.Element | null,
  renderHeaderCenter?: JSX.Element | null,
  disableClose?: boolean,
  blockClose?: boolean,
  isDisabled?: boolean,
  isLoading?: boolean,
}

export const StyledModal = ({
  isOpen,
  children,
  title,
  width,
  height,
  maxHeight,
  maxWidth,
  setIsOpen = () => null,
  afterClose,
  headerAction,
  renderHeaderCenter,
  disableClose = false,
  blockClose = false,
  isLoading = false,
}: IStyledModalProps) => {
  const [opacity, setOpacity] = useState(0);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onMouseDown = (e: MouseEvent) => {
    if (e.target === e.currentTarget && !blockClose) {
      toggleModal();
    }
  };

  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1);
    }, 10);
  };

  const beforeClose = () => new Promise((resolve) => {
    setOpacity(0);
    setTimeout(resolve, 200);
  });

  return (
    <ModalContainer
      isOpen={isOpen}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      afterClose={afterClose}
      onEscapeKeydown={onMouseDown}
      opacity={opacity}
      backgroundProps={{ opacity, onMouseDown }}
      width={width}
      height={height}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
    >
      {isLoading && <LoadingSpinner />}
      <HeaderContainer>
        <H2>{title}</H2>
        {renderHeaderCenter}
        <FlexRowItemsCenter>
          <>
            {headerAction}
            {!disableClose && <IconContainer><CloseIcon onClick={() => setIsOpen(false)} /></IconContainer>}
          </>
        </FlexRowItemsCenter>

      </HeaderContainer>
      {children}
    </ModalContainer>
  );
};
