import React from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { SignApplicationAction } from './SignApplicationAction';
import { SubmitApplicationAction } from './SubmitApplicationAction';
import { Flex } from '@/components/designSystem/Layout';

interface ISubmitApplicationProps {
    customer: ICustomer,
    refetch: () => void,
    buttonText: string,
  }
  
export const SubmitApplication = ({ customer, refetch, buttonText }: ISubmitApplicationProps) => {
  const signedStatus = get('latestApplication.activeAgreement.status', customer);
  const needsNewSignature = signedStatus !== 'signed';
  
  return (
    <Flex direction='column'>
      {
        needsNewSignature
          ? <SignApplicationAction customer={customer} buttonText={buttonText} refetch={refetch} />
          : <SubmitApplicationAction customer={customer} buttonText={buttonText} refetch={refetch} />
      }
    </Flex>
  );
};
