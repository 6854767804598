import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { get } from 'lodash/fp';

import { BodyText, Caption, H4 } from '@/components/designSystem/Typography';

import {
  ColumnHeaderConnectorContainer,
  DragAreaContainer,
  ColumnDragLabel,
  StyledRightArrow,
  ConnectorTextContainer,
} from './styled';

interface IProps {
  title: string,
  value: string,
  index: number,
  droppableId: string,
  resultExample: string
}

export const ColumnHeaderConnector = (props: IProps) => {
  const {
    title, value, index, droppableId, resultExample,
  } = props;
  const exampleValue = get(value, resultExample);

  return (
    <ColumnHeaderConnectorContainer>
      <Droppable droppableId={droppableId} direction='horizontal'>
        {(provided, snapshot) => (
          <DragAreaContainer
            ref={provided.innerRef}
            isDragging={snapshot.isDraggingOver}
          >
            {value ? (
              <Draggable key={value} draggableId={value} index={index}>
                {(provided, snapshot) => (
                  <ColumnDragLabel
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={
                      snapshot.isDragging && !snapshot.isDropAnimating
                    }
                  >
                    {value}
                  </ColumnDragLabel>
                )}
              </Draggable>
            ) : (
              <BodyText>Drag a Column here to connect</BodyText>
            )}
          </DragAreaContainer>
        )}
      </Droppable>
      <StyledRightArrow />

      <ConnectorTextContainer>
        <H4 bold>{title}</H4>

        {exampleValue ? (
          <Caption>{exampleValue}</Caption>
        ) : (
          <Caption color='negative'>* Required</Caption>
        )}
      </ConnectorTextContainer>
    </ColumnHeaderConnectorContainer>
  );
};
