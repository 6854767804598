import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { formatPhoneNumber } from 'react-phone-number-input';

import { getOr, join, flow, filter, startCase, camelCase } from 'lodash/fp';
import { CREDIT_APPLICANT_STATUSES, CUSTOM_FIELD_TYPES } from '@/constants';

const toTitleCase = flow(camelCase, startCase);

export const formatPhone = (value: string, countryCode: CountryCode = 'US') => {
  const phoneNumber = parsePhoneNumber(`${value}`, countryCode);
  if (!phoneNumber) return value;
  return phoneNumber.formatInternational();
};

export const formatAccountType = toTitleCase;

export const mapStatusLabel = (status: string) => {
  const lowercaseStatus = status.toLowerCase();

  const labels = {
    [CREDIT_APPLICANT_STATUSES.INVITED]: 'Invited',
    [CREDIT_APPLICANT_STATUSES.CREATED]: 'Created',
    [CREDIT_APPLICANT_STATUSES.IN_PROGRESS]: 'In Progress',
    [CREDIT_APPLICANT_STATUSES.PENDING_APPROVAL]: 'Pending Approval',
    [CREDIT_APPLICANT_STATUSES.CHANGES_REQUESTED]: 'Changes Requested',
    [CREDIT_APPLICANT_STATUSES.APPROVED]: 'Approved',
    [CREDIT_APPLICANT_STATUSES.DENIED]: 'Denied',
    [CREDIT_APPLICANT_STATUSES.AGREEMENT_PROCESSING]: 'Agreement Processing',
    [CREDIT_APPLICANT_STATUSES.AGREEMENT_VIEWED]: 'Agreement Viewed',
  };

  return getOr(status, lowercaseStatus, labels);
}

export const getStatusType = (status: string) => {
  if (status === CREDIT_APPLICANT_STATUSES.APPROVED) {
    return 'success'
  }
  if (status === CREDIT_APPLICANT_STATUSES.DENIED) {
    return 'danger'
  }
  if (status === CREDIT_APPLICANT_STATUSES.PENDING_APPROVAL) {
    return 'info'
  }
  return 'warning'
}

interface IFormatAddressArgs {
  address_line_1: string,
  address_line_2: string,
  city: string,
  state: string,
  country: string,
  postal_code: string,
}

export const formatAddress = ({
  address_line_1,
  address_line_2,
  city,
  state,
  country,
  postal_code,
}: IFormatAddressArgs) => {
  const addressData = [
    address_line_1,
    address_line_2,
    city,
    state,
    country,
    postal_code,
  ];

  return flow(
    filter(Boolean),
    join(' '),
  )(addressData);
};

export const formatPhoneNumberForRequest = (phone: string) => {
  if (!phone) return phone;
  try {
    const parsedNumber = parsePhoneNumber(phone);
    if (parsedNumber?.countryCallingCode === '1') {
      return formatPhoneNumber(phone);
    }
  } catch(e) {
    console.log(e)
  }
  return phone;
}

export const formatCustomFieldType = (type: string) => {
  if (type === CUSTOM_FIELD_TYPES.FILE_UPLOAD) {
    return 'Upload';
  }
  if (type === CUSTOM_FIELD_TYPES.MULTIPLE_CHOICE) {
    return 'Multiple Choice';
  }
  if (type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION) {
    return 'Additional question';
  }
  return type.charAt(0).toUpperCase() + type.slice(1);
};