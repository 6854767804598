import gql from 'graphql-tag';

export const ONBOARD_CREDIT_SEEKER_INPUT = gql`
  mutation OnboardCreditSeeker(
    $onboardData: OnboardCreditProviderInput!
    $customFieldAnswers: [CustomFieldAnswerInput]
    $referralCode: String
    $providerCompanyId: ID
    $redirectTo: String!
    $visitorData: VisitorData
  ) {
    onboardCreditSeeker(
      onboardData: $onboardData,
      customFieldAnswers: $customFieldAnswers,
      referralCode: $referralCode,
      providerCompanyId: $providerCompanyId,
      redirectTo: $redirectTo,
      visitorData: $visitorData
    ) {
      customer { id }
      seekerCompany {
        id
      }
    }
  }
`;
