import { Dispatch, SetStateAction } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { CREATE_VENDOR_REFERENCE } from '@/graphql/mutations/createVendorReference';
import { UPDATE_VENDOR_REFERENCE } from '@/graphql/mutations/updateVendorReference';

import { get } from 'lodash/fp';

import { IVendor } from '@/types/vendor';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { UPDATE, CREATE } from '../constants';

export interface IValues {
  customerSince: string,
  lastPaymentOn: string,
  lastPaymentAmount: string,
  creditBalancePastDue: string,
  creditLimit: string,
  creditBalanceHigh: string,
  creditTerms: string,
  averageDaysToPay: string,
  comments: string,
  referenceDocuments: [],
  websiteAddress: string,
  accountNumber: string,
  address: string,
  currentBalance: number,
}

interface IArgs {
  reference: IVendor,
  submitFrom: string | null,
  setSubmitFrom: Dispatch<SetStateAction<string | null>>,
  refetch: () => void,
  setEditActive: Dispatch<SetStateAction<boolean>>
}

export const useFields = ({ reference, submitFrom, setSubmitFrom, refetch, setEditActive }: IArgs) => {
  const referenceId = get('id', reference);
  const inviteId = get(`sourceCompany.inviteId`, reference);

  const fields = {
    customerSince: get('customerSince', reference),
    lastPaymentOn: get('lastPaymentOn', reference),
    lastPaymentAmount: get('lastPaymentAmount', reference),
    creditBalancePastDue: get('creditBalancePastDue', reference),
    creditLimit: get('creditLimit', reference),
    creditBalanceHigh: get('creditBalanceHigh', reference),
    creditTerms: get('creditTerms', reference),
    averageDaysToPay: get('averageDaysToPay', reference),
    comments: get('comments', reference),
    referenceDocuments: get('referenceDocuments', reference),
    websiteAddress: get('websiteAddress', reference),
    accountNumber: get('accountNumber', reference),
    address: get('address', reference),
    currentBalance: get('currentBalance', reference),
  }

  const [updateReference] = useMutation(UPDATE_VENDOR_REFERENCE);
  const [createReference] = useMutation(CREATE_VENDOR_REFERENCE);

  const handleSubmit = (values: IValues) => {
    const {
      customerSince,
      lastPaymentOn,
      lastPaymentAmount,
      creditBalancePastDue,
      creditLimit,
      creditBalanceHigh,
      creditTerms,
      averageDaysToPay,
      comments,
      referenceDocuments,
      websiteAddress,
      accountNumber,
      address,
      currentBalance,
    } = values;

    const partialVariables = {
      vendorReferenceData: {
        customerSince: customerSince ? new Date(customerSince).toISOString() : null,
        lastPaymentOn: lastPaymentOn ? new Date(lastPaymentOn).toISOString() : null,
        lastPaymentAmount,
        creditBalancePastDue,
        creditLimit,
        creditBalanceHigh,
        creditTerms,
        averageDaysToPay,
        comments,
        referenceDocuments,
        websiteAddress,
        accountNumber,
        address,
        currentBalance,
      },
    };

    if (submitFrom === UPDATE) {
      const variables = { ...partialVariables, referenceId }
      updateReference({ variables })
        .then(() => {
          showToast({
            title: 'Saved',
            description: 'Vendor Reference have been successfully updated',
            type: toast.TYPE.SUCCESS,
          });
          if (refetch) {
            refetch();
          }
          setEditActive(false);
        });
    }
    if (submitFrom === CREATE) {
      const variables = { vendorReferenceData: { ...partialVariables.vendorReferenceData, inviteId } }
      createReference({ variables })
        .then(() => {
          showToast({
            title: 'Submited',
            description: 'Vendor Reference have been successfully submitted',
            type: toast.TYPE.SUCCESS,
          });
          refetch();
          setEditActive(false);
        });
    }
    setSubmitFrom(null);
  }

  return {
    fields,
    handleSubmit,
  };
}
