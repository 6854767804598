import React, { useEffect, useCallback } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { GET_APIDECK_COMPANY } from '@/graphql/queries/getApiDeckCompany';

import { useFormikContext } from 'formik';

import { get, debounce } from 'lodash/fp';

import { IFieldProps } from '@/types/field';

import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

interface IProps extends IFieldProps {
  enableApideck: boolean,
  companyId: string,
  setLoading: (loading: boolean) => void
}

export const CustomerIdField = ({ enableApideck, setLoading }: IProps) => {
  const { values, setFieldValue } = useFormikContext();
  const customer = get('customerId', values);

  const [getApiDeckCompany, {data, loading}] = useLazyQuery(GET_APIDECK_COMPANY);
  const companyName = get('getApiDeckCompany.name', data);
  const companyEmail = get('getApiDeckCompany.email', data);
  const companyPhoneNumber = get('getApiDeckCompany.phoneNumber', data);
  const getApiDeckCompanyWithArgs = (customerId: string) => getApiDeckCompany({ variables: { customerId } })
  const debouncedGetApiDeckCompany = useCallback(debounce(1000, getApiDeckCompanyWithArgs), []);

  useEffect(() => {
    if (customer) {
      debouncedGetApiDeckCompany(customer)
    }
  }, [customer]);

  useEffect(() => {
    setFieldValue('companyName', companyName);
    setFieldValue('email', companyEmail);
    setFieldValue('phoneNumber', companyPhoneNumber); // TODO fix: PhoneNumberField is not being set by this.
  }, [data]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <>
      {enableApideck &&
      <Row>
        <TextField type='text' name='customerId' label='Customer ID' placeholder='123' />
      </Row>
      }
    </>
  );
}
