import React, { useState, useEffect } from 'react';

import { useMutation} from '@apollo/react-hooks';
import { UPDATE_USER_ROLE } from '@/graphql/mutations/updateUserRole';
import { RESEND_INVITATION } from '@/graphql/mutations/resendInvitation';

import { Formik } from 'formik';

import { get, getOr } from 'lodash/fp';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { ICompanyUser, IUserCompanyRole, IUser } from '@/types/companyUser';
import { ICurrentCompany } from '@/types/currentCompany';

import { UserInfo } from '@/components/UserInfo';
import { Label } from '@/components/Label/Label';
import { Flex } from '@/components/designSystem/Layout';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { UserForm } from './UserForm';
import { Button } from '@/components/designSystem/buttons';

interface IUserProps {
  user: IUser,
  currentUser: ICompanyUser,
  company: ICurrentCompany,
  refetchCompany: () => void,
}

interface IValues {
  role: string,
  isAdmin: boolean,
  creditLimit: number,
}

export const User = ({ user, currentUser, company, refetchCompany }: IUserProps) => {
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE);
  const [resendInvitation] = useMutation(RESEND_INVITATION);

  const companyName = get('companyName', user)
  const name = get('name', user);
  const userId = get('id', user);
  const email = get('email', user);
  const status = get('status', user);
  const isPending: () => boolean = () => { return status === "pending"; };
  const userRoles = getOr([], 'userCompanyRoles', user);
  const listUserCompanyRole = userRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === company.id)
  const listUserRole = getOr('analyst', 'role.name', listUserCompanyRole[0])
  const isAdmin = getOr(false, 'isAdmin', listUserCompanyRole[0])
  const creditLimit = getOr('', 'creditLimit', listUserCompanyRole[0])

  const autoSaveSettings = (settings: IValues, { setSubmitting }: { setSubmitting: (arg: boolean) => void }) => {
    const variables = {
      roleUpdate: {
        role_name: settings.role,
        user_id: userId,
        is_admin: settings.isAdmin,
        credit_limit: settings.creditLimit,
      },
    };

    return updateUserRole({ variables })
      .then(() => {
        showToast({
          title: 'User Company role Successfully Changes!',
          description: 'User company role has been updated.',
          type: toast.TYPE.SUCCESS,
        });
        setSubmitting(false);
        refetchCompany();
      })
      .catch((error) => {
        showToast({
          title: 'Error',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialSettings = {role: listUserRole, isAdmin: isAdmin, creditLimit: creditLimit}

  const onResendInvitation = (referralId: string) => {
    const variables = { referralId: referralId }
    return resendInvitation({variables})
  }

  return (
    <Flex justify='space-between' align='center'>
      <UserInfo name={name} detail={email} companyName={companyName} />
      { (!isPending() && currentUser.id !== userId) ? (
        <Formik
          initialValues={initialSettings}
          onSubmit={autoSaveSettings}
        >
          <UserForm user={user} company={company} />
        </Formik>
      ) : false}
      {isPending() && <Flex direction='column' gap='0.5rem' align='end'>
        <Label type='success'>Pending</Label>
        {user.invitationSentAt && <span>Last invitation sent at: {user.invitationSentAt}</span>}
        <Button onClick={() => onResendInvitation(user.referralId)}>Resend Invitation</Button>
      </Flex>}
    </Flex>
  );
};
