import { get, getOr } from 'lodash/fp';
import { mapCustomFieldsToFormInitialValues } from '@/utils/customFields';

import { ICustomField } from '@/types/customField';

import { CUSTOM_FIELD_TYPES } from '@/constants';
import { ICustomer } from '@/types/customer';

export const otherInfoInitialValues = (customerData: ICustomer) => {
  const providerCompany = getOr(null, 'providerCompany', customerData);
  const customFieldAnswers = getOr([], 'customFieldAnswers', customerData);
  const application = get('latestApplication', customerData);

  const customFields = getOr([], 'customFields', providerCompany);
  const customFieldsForOtherInfoStep: ICustomField[] = customFields.filter(
    customField => (customField.type !== CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION) && (customField.type !== CUSTOM_FIELD_TYPES.CONTACT));

  return {
    creditLimit: application.creditLimit || '',
    creditTerms: application.creditTerms || '',
    einFederalTaxNumber: application.einFederalTaxNumber || '',
    customFields: mapCustomFieldsToFormInitialValues(customFieldsForOtherInfoStep, customFieldAnswers),
  }
}
