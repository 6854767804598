import { get } from 'lodash/fp';

import { IAutomaticAccountInterface } from '@/types/automaticAccount';

export const useAutomaticAccountFields = (account: IAutomaticAccountInterface) => {
  const institutionName = get('lastAccount.institution.name', account);
  const institutionAddress = get('lastAccount.institution.address.country', account);
  const accountNumber = get('lastAccount.accountDisplayNumber', account);
  const currency = get('lastAccount.currency', account);
  const accountType = get('lastAccount.type', account);

  return {
    institutionName,
    institutionAddress,
    accountNumber,
    currency,
    accountType,
  };
}