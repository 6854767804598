import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { get } from 'lodash/fp';
import { isEmpty } from 'lodash';
import { ICustomer } from '@/types/customer';

import { MutationFunctionOptions, useMutation } from '@apollo/react-hooks';
import { UPDATE_CUSTOMER_SETTINGS } from '@/graphql/mutations/updateCustomerSettings';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Button } from '@/components/designSystem/buttons';
import { BodyText, Caption } from '@/components/designSystem/Typography';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { Flex } from '@/components/designSystem/Layout';
import { EnableRequiredOptions } from '@/components/EnableRequiredOptions';

import { StyledSectionsOptionsGrid, StyledFormButtonContainer } from '../styled';

const KEYS = {
  enabled: 'enable_personal_guarantee',
  required: 'require_personal_guarantee',
}

interface IFields {
  status: string,
  providerComment: string,
}

interface IProps {
  customerData: ICustomer,
  customerId: string,
  loading: boolean,
  canSeeEditControl: boolean,
  selectedStatus: string,
  setSelectedStatus: Dispatch<SetStateAction<string>>
}

export const ActionsForm = ({ customerData, loading, customerId, selectedStatus, canSeeEditControl, setSelectedStatus }: IProps) => {
  const { values, validateForm, touched, dirty } = useFormikContext();
  const { status } = values as IFields;
  const enabled = !isEmpty(touched) || dirty;

  const enablePersonalGuarantee = get('settings.enablePersonalGuarantee', customerData);
  const requirePersonalGuarantee = get('settings.requirePersonalGuarantee', customerData);
  const [updateCustomerSettings] = useMutation(UPDATE_CUSTOMER_SETTINGS);

  const handlePersonalGuaranteeChange = (state: any) => handleUpdateCompanySettings({variables: {customerId, settings: state}});

  const handleUpdateCompanySettings = (variables: MutationFunctionOptions) => updateCustomerSettings(variables)
    .then(() => {
      showToast({
        title: 'Settings Successfully Update!',
        description: 'Settings Successfully Update',
        type: toast.TYPE.SUCCESS,
      });
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Settings were not updated',
        type: toast.TYPE.ERROR,
      });
    });

  useEffect(() => {
    setSelectedStatus(status);
    setTimeout(() => validateForm(), 25); // gross hack to ensure we disable the form button when validation changes
  }, [status]);

  return (
    <Form style={{ gap: '1rem' }}>
      <Flex direction='column' gap='0.5rem'>
        <BodyText>Please, choose application sections which require changes</BodyText>
        <StyledSectionsOptionsGrid>
          <CheckboxField name='changesRequestedSections.company_info'>
            <Caption>Company Information</Caption>
          </CheckboxField>
          <CheckboxField name='changesRequestedSections.company_contacts'>
            <Caption>Company Contact Information</Caption>
          </CheckboxField>
          <CheckboxField name='changesRequestedSections.bank_info'>
            <Caption>Bank/Financial Information</Caption>
          </CheckboxField>
          <CheckboxField name='changesRequestedSections.vendors'>
            <Caption>Vendor Information</Caption>
          </CheckboxField>
          <CheckboxField name='changesRequestedSections.customizable'>
            <Caption>Other Information</Caption>
          </CheckboxField>
          <EnableRequiredOptions
            label='Personal Guarantee'
            keys={KEYS}
            canSeeEditControl={canSeeEditControl}
            enabled={enablePersonalGuarantee}
            required={requirePersonalGuarantee}
            handleChange={handlePersonalGuaranteeChange}
          />
        </StyledSectionsOptionsGrid>
      </Flex>

      <TextAreaField
        required
        name='providerComment'
        label='Comment For Applicant'
        placeholder='Can you please update your company address, add another more current Vendor Reference etc.?'
      />
      <StyledFormButtonContainer>
        <Button primary disabled={!enabled} loading={loading} type='submit' wide>Save</Button>
      </StyledFormButtonContainer>
    </Form>
  )
};
