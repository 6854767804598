import gql from 'graphql-tag';

export const UPDATE_COMPANY_CONTACTS = gql`
  mutation UpdateCompanyContacts($companyContact: [CompanyContact!]) {
    updateCompanyContacts(companyContact: $companyContact) {
      name,
      email,
      phoneNumber,
      position,
      extension,
      ownershipPercentage,
      updatedAt
    }
  }
`;