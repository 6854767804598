import gql from 'graphql-tag';

export const ANSWER_CUSTOM_FIELDS = gql`
  mutation AnswerCustomFields(
    $customFieldAnswers: [CustomFieldAnswers!]!
    $customerId: ID!
    $applicationAttrs: ApplicationInput!
    $visitorData: VisitorData
  ) {
    answerCustomFields(
      customFieldAnswers: $customFieldAnswers,
      customerId: $customerId,
      applicationAttrs: $applicationAttrs,
      visitorData: $visitorData,
    ) {
      step
    }
  }
`;

export const ANSWER_CUSTOM_FIELDS_BY_CP = gql`
  mutation AnswerCustomFieldsByCp(
    $customFieldAnswers: [CustomFieldAnswers!]!
    $customerId: ID!
    $applicationAttrs: ApplicationInput!
  ) {
    answerCustomFieldsByCp(
      customFieldAnswers: $customFieldAnswers,
      customerId: $customerId,
      applicationAttrs: $applicationAttrs
    )
  }
`;