import React from 'react';

import {
  FormSkeletonContainer,
  TitleSkeleton,
  SubTitleSkeleton,
  LabelSkeleton,
  InputSkeleton,
} from './styled';

export const FormSkeleton = () => (
  <FormSkeletonContainer>
    <TitleSkeleton />
    <SubTitleSkeleton />
    <LabelSkeleton />
    <InputSkeleton />
    <LabelSkeleton />
    <InputSkeleton />
    <LabelSkeleton />
    <InputSkeleton />
  </FormSkeletonContainer>
);
