import React, { RefObject } from 'react';
import DataGrid from "devextreme-react/data-grid";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs/dist/exceljs';
import saveAs from 'file-saver';

import { Button } from '@/components/designSystem/buttons';

interface IExportApplicationsProps {
  gridRef: RefObject<DataGrid<unknown, unknown>>,
}

export const ExportApplications = ({ gridRef }: IExportApplicationsProps) => {
  const exportApplications = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridRef.current?.instance as any,
      worksheet
    }).then(function() {
      workbook.csv.writeBuffer().then(function(buffer: any) {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Report.csv");
      });
    });
  }

  return (
    <Button secondary onClick={exportApplications}>Export Applications</Button>
  )
};
