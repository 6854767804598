import React from 'react';

import { get } from 'lodash/fp';

import { formatAccountType } from '@/utils/format';
import { formatDateToTimeAgo } from '@/utils/date';

import ToggleWell from '@/components/_oldComponents/ToggleWell';
import { Stats } from '@/components/_oldComponents/Stats';
import { Caption } from '@/components/designSystem/Typography';
import { Note } from './Note';

import {
  StatsHeader,
} from './styled';
import { IManualAccount } from '@/types/manualAccount';

interface IProps {
  account: IManualAccount,
  accountNotes: string[],
  customerId?: string,
  refetch: () => void,
}

export const ManualAccount = ({
  account, accountNotes, customerId, refetch,
}: IProps) => {
  const accountNumber = get('accountNumber', account);
  const routingNumber = get('routingNumber', account);
  const insertedAt = get('insertedAt', account);
  const type = formatAccountType(get('accountType', account));

  return (
    <ToggleWell title={`${type} ${accountNumber}`}>
      <StatsHeader bold mt='0'>Account Stats</StatsHeader>
      <Stats.Container>
        <Stats.Stat label='Account Type'>
          {type}
        </Stats.Stat>
        <Stats.Stat label='Routing/Transit Number'>
          {routingNumber}
        </Stats.Stat>
        <Stats.Stat label='Account Number'>
          {accountNumber}
        </Stats.Stat>
      </Stats.Container>
      <Note account={account} accountNotes={accountNotes} customerId={customerId} refetch={refetch} />
      <Caption color='secondary'>Added {formatDateToTimeAgo(insertedAt)}</Caption>
    </ToggleWell>
  );
};
