import React, { Dispatch, SetStateAction } from 'react';

import { StyledModal } from '../StyledModal';
import { Steps } from './Steps';

interface IProps {
  isOpen: boolean,
  children: React.ReactNode,
  title?: string,
  width?: string,
  height?: string,
  maxHeight?: string,
  maxWidth?: string,
  setIsOpen?: Dispatch<SetStateAction<boolean>>,
  afterClose?: () => void,
  blockClose?: boolean,
  steps?: string[];
  activeStep?: number;
}

export const StepperModal = ({
  isOpen,
  children,
  title,
  width = '80vw',
  maxWidth = '1200px',
  maxHeight,
  height = '800px',
  setIsOpen = () => null,
  afterClose,
  blockClose = false,
  steps,
  activeStep,
}: IProps) => (
  <StyledModal
    title={title}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    width={width}
    maxWidth={maxWidth}
    height={height}
    maxHeight={maxHeight}
    blockClose={blockClose}
    afterClose={afterClose}
    renderHeaderCenter={(steps && activeStep) ? <Steps steps={steps} activeStep={activeStep} /> : null}
  >
    {children}
  </StyledModal>
);
