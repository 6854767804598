import gql from 'graphql-tag';

export const GENERATE_EQUIFAX_REPORT = gql`
  mutation GenerateEquifaxReport($customerId: ID!, $bin: String!, $reportType: String!, $reportDescription: String!) {
    generateEquifaxReport(customerId: $customerId, bin: $bin, reportType: $reportType, reportDescription: $reportDescription) {
      id
    }
  }
`;

export const IS_EQUIFAX_CREDENTIALS_STILL_VALID = gql`
  mutation IsEquifaxCredentialsStillValid {
    isEquifaxCredentialsStillValid
  }
`;
