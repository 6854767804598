import { createContext } from 'react';

import { ICurrentCompany } from '@/types/currentCompany';

interface ICurrentCompanyContext {
  currentCompany: ICurrentCompany,
  currentCompanyLoading: boolean,
  refetchCurrentCompany: () => void,
}

export const CurrentCompanyContext = createContext<ICurrentCompanyContext | null>(null);