import React, {useState} from 'react';
import { useMutation } from '@apollo/react-hooks';
import { INCREASE_REQUIRED_REFERENCES } from '@/graphql/mutations/changeNumberOfReferences';
import { DECREASE_REQUIRED_REFERENCES } from '@/graphql/mutations/changeNumberOfReferences';
import { DECREASE_TOTAL_REFERENCES } from '@/graphql/mutations/changeNumberOfReferences';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';
import { H4 } from '@/components/designSystem/Typography';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { PhoneNumberInput } from '@/components/designSystem/Form/PhoneNumberInput';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

import { StyledClose } from './styled';

interface IProps {
  name: string,
  company: string,
  email: string,
  phone: string,
  index: number,
  required: boolean,
  refetch: () => void,
}

export const VendorFields = ({
  name, company, email, phone, index, required, refetch,
}: IProps) => {

  const [isRequired, setRequired] = useState(required);
  const [increaseReqVendorRefs] = useMutation(INCREASE_REQUIRED_REFERENCES);
  const [decreaseReqVendorRefs] = useMutation(DECREASE_REQUIRED_REFERENCES);
  // const onClickCheckbox = () => updateCompanySettings({ variables }).then(refetch);

  const onClickCheckbox = () => {
    if(!isRequired) {
      increaseReqVendorRefs()
        .then(refetch)
        .catch((error) => {
          showToast({
            title: 'Error',
            description: extractGQLErrorMessage(error),
            type: toast.TYPE.ERROR,
          });
        })
    } else {
      decreaseReqVendorRefs()
        .then(refetch)
        .catch((error) => {
          showToast({
            title: 'Error',
            description: extractGQLErrorMessage(error),
            type: toast.TYPE.ERROR,
          });
        })
    }
    setRequired(!isRequired)
  };

  const [decreaseTotalVendorRefs] = useMutation(DECREASE_TOTAL_REFERENCES);
  const onClick = () => {
    if(!isRequired) {
      decreaseTotalVendorRefs()
        .then(refetch)
        .catch((error) => {
          showToast({
            title: 'Error',
            description: extractGQLErrorMessage(error),
            type: toast.TYPE.ERROR,
          });
        });
    } else {
      decreaseTotalVendorRefs()
        .then(refetch)
        .catch((error) => {
          showToast({
            title: 'Error',
            description: extractGQLErrorMessage(error),
            type: toast.TYPE.ERROR,
          });
        });
      decreaseReqVendorRefs()
        .then(refetch)
        .catch((error) => {
          showToast({
            title: 'Error',
            description: extractGQLErrorMessage(error),
            type: toast.TYPE.ERROR,
          });
        })
    }
  }
  return (
    <Row>
      {/* All these TextField names are fake. We need for formik fields.
        If you need to enable these text fields at some point, you will need to fix names */}
      <TextField type='text' name='vendor.company' disabled label='Company Name' placeholder={company} />
      <TextField type='text' name='vendor.name' disabled label='Full Name' placeholder={name} />
      <PhoneNumberInput disabled label='Phone Number' placeholder={phone} />
      <TextField type='text' name='vendor.email' disabled label='Email Address' placeholder={email} />
      <Checkbox
        checked={isRequired}
        onChange={() => onClickCheckbox()}
        disabled ={required}
      >
        <H4 bold>Required</H4>
      </Checkbox>
      {index > 1 ? <StyledClose onClick={onClick} /> : <div />}
    </Row>
  );
};
