import React from 'react';

import { H1 } from '@/components/designSystem/Typography';

import { ReactComponent as Plus } from '@/assets/plus.svg';
import { ReactComponent as Edit } from '@/assets/edit_primary.svg';

import { StyledEmptyStateContainer, StyledUndrawTerms, StyledLeftIconButton } from './styled';

interface IProps {
  buttonsVisible?: boolean,
  handleInviteCustomer: () => void,
}

export const EmptyState = ({ buttonsVisible, handleInviteCustomer }: IProps) => (
  <StyledEmptyStateContainer>
    <H1 bold>Welcome to Nectarine Credit</H1>
    <div><StyledUndrawTerms /></div>
    {buttonsVisible ? (
      <>
        <StyledLeftIconButton secondary wide LeftIcon={Edit} path='/dashboard/application/info'>
          1. Review and Customize Your Credit Application
          <div />
        </StyledLeftIconButton>
        <StyledLeftIconButton primary wide LeftIcon={Plus} onClick={handleInviteCustomer}>
          2. Invite Your First Customer
          <div />
        </StyledLeftIconButton>
      </>
    ) : false}
  </StyledEmptyStateContainer>
);
