import React from 'react';

import { format } from 'date-fns';
import { formatCurrency } from '@/utils/money';

import { VR_STATUS } from '@/constants';

import { StatusLabel } from "@/components/Label/StatusLabel";

import { GreenCheckIcon, RedXIcon, YellowWarningIcon } from '../icons';

interface IProps {
  value: string,
}

export const StatusCell = ({ value }: IProps) => <StatusLabel value={value} />;

export const IconCell = ({ value }: IProps) => {
  switch (value) {
  case VR_STATUS.SUCCESS:
    return <GreenCheckIcon />;

  case VR_STATUS.FAILURE:
    return <RedXIcon />;

  default:
    return <YellowWarningIcon />;
  }
};

interface IDollarCellProps {
  value: number,
}

export const DollarCell = ({ value }: IDollarCellProps) => (value || value === 0 ? formatCurrency(value, false) : '');

export const DateCell = ({ value }: IProps) => value ? format(new Date(value), 'MMM d, y') : '';