import { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { IS_EXPERIAN_CREDENTIALS_STILL_VALID } from '@/graphql/mutations/generateExperianReport';

import { getOr } from 'lodash/fp';

export const useExperian = () => {
  const [showExperianCredentialsModal, setShowExperianCredentialsModal] = useState(false);
  const [showExperianSearchModal, setShowExperianSearchModal] = useState(false);
  const [getExperianCredentialsStillValid, { loading: exLoading }] = useMutation(IS_EXPERIAN_CREDENTIALS_STILL_VALID);

  const onExperianClick = () => {
    getExperianCredentialsStillValid()
      .then((data) => {
        const isExperianCredentialsStillValid = getOr(false, 'data.isExperianCredentialsStillValid', data);
        if (isExperianCredentialsStillValid) {
          setShowExperianSearchModal(true);
        } else {
          setShowExperianCredentialsModal(true);
        }
      });
  };

  return {
    showExperianCredentialsModal,
    setShowExperianCredentialsModal,
    showExperianSearchModal,
    setShowExperianSearchModal,
    exLoading,
    onExperianClick,
  }
}