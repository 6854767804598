const initialAddressFields = {
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
}

export const companyProfileInitialValues ={
  name: '',
  otherNames: '',
  type: '',
  address: initialAddressFields,
  website: '',
  established: '',
  phoneNumber: '',
  description: '',
};
