import React, { Dispatch, SetStateAction } from 'react';

import { GET_EQUIFAX_RESULTS } from '@/graphql/queries/getEquifaxResults';
import { GENERATE_EQUIFAX_REPORT } from '@/graphql/mutations/generateEquifaxReport';

import { Formik } from 'formik';

import { getOr } from 'lodash/fp';

import { ICompany } from "@/types/company";
import { ICustomer } from '@/types/customer';

import { ContentContainer } from '@/modals/StyledModal/styled';
import { H4 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import {
  StyledForm,
  StyledContainer,
  StyledNoResultsText,
} from '@/modals/CreditReportModal/styled';
import { useCreditReportModal } from '@/modals/CreditReportModal/useCreditReportModal';
import { CreditReportModalContainer } from '@/modals/CreditReportModal/CreditReportModalContainer';

import { SearchResultItem } from '../SearchResultItem';
import { SearchForm } from '../SearchForm';
import { searchValidation, getValidCountry, getValidProvince } from '../common';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { ReportModalFooter } from '@/views/CustomerPage/tabs/BureauReport/modals/ReportModalFooter';

interface IProps {
  isOpen: boolean,
  customer?: ICustomer,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: () => void,
}

export const EquifaxCreditReportModal = ({
  isOpen,
  setIsOpen,
  customer,
  onSuccess,
}: IProps) => {
  const {
    showSearch,
    setShowSearch,
    searchInput,
    data,
    loading,
    handleSubmit
  } = useCreditReportModal(
    isOpen,
    GET_EQUIFAX_RESULTS,
    GENERATE_EQUIFAX_REPORT,
    () => {
      setIsOpen(false);
      onSuccess();
    }
  )

  const defaultCountry = getValidCountry(getOr('US', 'seekerCompany.address.country', customer));
  const defaultProvince = getValidProvince(getOr('', 'seekerCompany.address.state', customer), defaultCountry);

  const searchFields = {
    name: getOr('', 'seekerCompany.name', customer),
    city: getOr('', 'seekerCompany.address.city', customer),
    province: defaultProvince,
    country: defaultCountry,
  };

  const companies = getOr([], 'getEquifaxResults.results', data);

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />
    }
    if (companies.length === 0) {
      return (
        <>
          <StyledNoResultsText>No results found for the following input:</StyledNoResultsText>
          <pre>{JSON.stringify(searchInput, null, 5).replace(/[{}]/g, '')}</pre>
          <H4>Please go back and refine your search.</H4>
        </>
      )
    }
    return companies.map((company: ICompany, index: number) => {
      const { address } = company;
      const displayAddress = `${address.street}, ${address.city}, ${address.state}, ${address.postal_code}`;
      return (
        <SearchResultItem
          key={company.id}
          isFirst={index === 0}
          address={displayAddress}
        />
      );
    })
  };

  return (
    <CreditReportModalContainer
      title={showSearch ? '2. Select Matching Company' : '1. Company Search'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Formik
        onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
        initialValues={searchFields}
        validationSchema={searchValidation}
        validateOnChange
        enableReinitialize
      >
        <StyledForm>
          <ContentContainer maxHeight='80vh'>
            {
              showSearch
                ? (
                  <StyledContainer>
                    {renderContent()}
                  </StyledContainer>
                )
                : <SearchForm />
            }
          </ContentContainer>
          <ReportModalFooter
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            loading={loading}
          />
        </StyledForm>
      </Formik>
    </CreditReportModalContainer>
  );
};
