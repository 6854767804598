import { IAddressComponent, IGooglePlace } from '@/types/googlePlace';
import { formatPhoneNumberForRequest } from '@/utils/format';
import { IChangesRequestedValues } from '../../types';

const mapGooglePlaceToValues = (addressComponents: IAddressComponent[]) => ({
  state: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_1'))?.long_name ||
      addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_2'))?.long_name || '',
  city: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('locality'))?.long_name ||
      addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_town'))?.long_name || '',
  postalCode: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_code'))?.long_name || '',
  country: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('country'))?.short_name || null,
  streetNumber: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('street_number'))?.long_name || '',
  street: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('route'))?.long_name || '',
});

export  const setFieldsFromGooglePlace = (place: IGooglePlace, parentKey: string, setFieldValue: (field: string, value: string | null) => void) => {
  if (place.address_components) {
    const addressValues = mapGooglePlaceToValues(place.address_components);
    setFieldValue(`${parentKey}.postal_code`, addressValues.postalCode);
    setFieldValue(
      `${parentKey}.address_line_1`,
      `${addressValues.streetNumber}${addressValues.streetNumber && ' '}${addressValues.street}`
    );
    setFieldValue(`${parentKey}.city`, addressValues.city)
    setFieldValue(`${parentKey}.state`, addressValues.state);
    setFieldValue(`${parentKey}.country`, addressValues.country);
  }
};

export const mapChangesRequestedValuesToCompanyInfoVariables = (changesRequestedValues: IChangesRequestedValues) => {
  const {
    companyProfile,
  } = changesRequestedValues;

  return {
    name: companyProfile.name,
    type: companyProfile.type,
    description: companyProfile.description,
    established: +companyProfile.established,
    phoneNumber: formatPhoneNumberForRequest(companyProfile.phoneNumber),
    address: JSON.stringify(companyProfile.address),
    website: companyProfile.website,
    otherNames: companyProfile.otherNames,
  }
};