import { get, getOr } from "lodash/fp";

import { ICustomField } from "@/types/customField";
import { IReferralData } from "@/types/referralData";

import { CUSTOM_FIELD_TYPES } from "@/constants";
import { IStakeholder } from "@/types/stakeholder";
import { formatPhone } from "@/utils/format";

const initialContactFields = {
  name: '',
  email: '',
  position: '',
  phoneNumber: '',
}

export const initialAdditionalContactFields = {
  ...initialContactFields,
  extension: '',
}

export const initialOwnerFields = {
  ...initialContactFields,
  ownershipPercentage: '',
  address: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  },
  ssnSinNumber: '',
  driverLicenseNo: '',
  driverLicenseState: '',
  dob: '',
}


// OWNERS

const getInitialOwners = (seekerOwners: IStakeholder[]) => seekerOwners.map((owner) => ({
  // ...(owner.id && { id: owner.id }),
  name: owner.name,
  email: owner.email,
  position: owner.position,
  phoneNumber: formatPhone(owner.phoneNumber),
  ownershipPercentage: owner.ownershipPercentage,
  address: {
    address_line_1: owner.address?.address_line_1 ?? '',
    address_line_2: owner.address?.address_line_2 ?? '',
    city: owner.address?.city ?? '',
    state: owner.address?.state ?? '',
    postal_code: owner.address?.postal_code ?? '',
    country: owner.address?.country ?? '',
  },
  ssnSinNumber: owner.ssnSinNumber ?? '',
  driverLicenseNo: owner.driverLicenseNo ?? '',
  driverLicenseState: owner.driverLicenseState ?? '',
  dob: owner.dob ?? '',
}));

// CUSTOM CONTACTS

const getInitialCustomContactFields = (customContactField: ICustomField, seekerCustomContacts: IStakeholder[]) => {

  const {
    contact: {
      showEmail,
      showExtension,
      showName,
      showPhone
    },
    id,
    label
  } = customContactField;

  const customContactData = seekerCustomContacts.find((contact) => contact.customFieldId === id);

  return customContactData
    ? {
      name: customContactData.name ?? '',
      email: customContactData.email ?? '',
      phoneNumber: customContactData.phoneNumber ? formatPhone(customContactData.phoneNumber) :  '',
      extension: customContactData.extension ?? '',
      position: customContactData.position ?? '',
    } : {
      ...(showName && { name: '' }),
      ...(showEmail && { email: '' }),
      ...(showPhone && { phoneNumber: '' }),
      ...(showExtension && { extension: '' }),
      ...( { position: '' } ),
      label: customContactField.label,
    }
}

const getInitialCustomContacts = (
  customContactFields: ICustomField[],
  seekerCustomContacts: IStakeholder[]
) => customContactFields.reduce((acc, customContactField) => ({
  ...acc,
  [customContactField.id]: getInitialCustomContactFields(customContactField, seekerCustomContacts)
}), {})

// ADDITIONAL CONTACTS

const getInitialAdditionalContacts = (additionalContacts: IStakeholder[]) => additionalContacts.map((contact) => ({
  ...(contact.id && { id: contact.id }),
  name: contact.name ?? '',
  email: contact.email ?? '',
  position: contact.position ?? '',
  phoneNumber: contact.phoneNumber ? formatPhone(contact.phoneNumber) :  '',
  extension: contact.extension ?? '',
}));

export const companyContactsInitialValues = (refData: IReferralData) => {
  const providerCompany = getOr(null, 'getCompanyById', refData);
  const customFields = getOr([], 'customFields', providerCompany);
  const customContactFields: ICustomField[] = customFields
    .filter(customField => customField.type === CUSTOM_FIELD_TYPES.CONTACT);
  const currentUserEmail = get('currentUser.email', refData);

  const allSeekerContacts: IStakeholder[] = getOr([], 'getCustomerAsSeeker.seekerCompany.stakeholders', refData);
  const seekerOwners = allSeekerContacts.filter((contact) => contact.isOwner);
  const seekerCustomContacts: IStakeholder[] = allSeekerContacts.filter((contact) => contact.customFieldId);
  const additionalContacts: IStakeholder[] = allSeekerContacts.filter((contact) => !contact.isOwner && !contact.customFieldId)

  return {
    owners: seekerOwners.length ? getInitialOwners(seekerOwners) : [initialOwnerFields],
    customContacts: getInitialCustomContacts(customContactFields, seekerCustomContacts),
    ...(additionalContacts.length && { additionalContacts: getInitialAdditionalContacts(additionalContacts) }),
    isCurrentUserOwner: seekerOwners.length ? seekerOwners[0].email === currentUserEmail : false,
  }
};
