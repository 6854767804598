import React, { useState } from 'react';
import styled from 'styled-components';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Button, IButtonProps } from '@/components/designSystem/buttons';
import { SignAgreementModal } from '@/modals/SignAgreementModal';

import gql from 'graphql-tag';

export const TEST_MULTI_SIGN = gql`
  mutation testMultiSign($customerId: ID!) {
    testMultiSign(customerId: $customerId) {
      embeddedUrl
    }
  }
`;

interface IProps extends IButtonProps {
  url: string,
  onSuccess: () => void,
}

const SignAgreementButton = ({ url, onSuccess, ...rest }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [embeddedUrl, setEmbeddedUrl] = useState<string | undefined>();

  const initEversign = async () => {
    if (!url) {
      showToast({
        title: 'Something went wrong',
        description: 'We could not generate the agreement at this time. Please try again later.',
        type: toast.TYPE.ERROR,
      });
      return;
    }
    setIsOpen(true);
    setEmbeddedUrl(url);
  };

  return (
    <>
      <Button onClick={() => initEversign()} {...rest} />
      <SignAgreementModal isOpen={isOpen} setIsOpen={setIsOpen} embeddedUrl={embeddedUrl} onSuccess={onSuccess} />
    </>
  );
};

const StyledSignButton = styled(SignAgreementButton).attrs({ primary: true })`
  margin-top: 1.5rem;
  width: 100%;
`;

const EversignTestPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const SInput = styled.input`
  background-color: "grey";
  border: "1px solid black";
  width: "100%";
`;

export const EversignTestPage = () => {
  const [url, setUrl] = useState('');
  const onAgreementSigned = () => console.log('signed');

  return (
    <EversignTestPageContainer>
      <SInput type='text' value={url} onChange={(e) => setUrl(e.target.value)} />
      <StyledSignButton onSuccess={onAgreementSigned} url={url}>Sign Agreement</StyledSignButton>
    </EversignTestPageContainer>
  );
};
