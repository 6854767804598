import React from 'react';
import { useFormikContext } from 'formik';

import { getOr } from 'lodash/fp';

import { Row } from '@/components/designSystem/Form/styled';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { AccountSection, IManualAccountFields } from './AccountSection';

const manualAccountFields = {
  account_type: '',
  account_number: '',
  routing_number: '',
};

interface IProps {
  arrayHelpers: IManualAccountFields[],
}

export const AccountsSection = ({ arrayHelpers }: IProps) => {
  const { values } = useFormikContext();
  return (
    <>
      {getOr([], 'accounts', values).map((_data: any, index: number) => <AccountSection key={index} index={index} arrayHelpers={arrayHelpers} parentKey={`accounts[${index}]`} />)}
      <Row>
        <div>
          <Button secondary LeftIcon={Plus} onClick={() => arrayHelpers.push(manualAccountFields)}>Add Account</Button>
        </div>
      </Row>
    </>
  );
};
