import React, { useState } from 'react';
import styled from 'styled-components';

import { get } from 'lodash/fp';

import { IContact } from '@/types/contact';

import ContactInfoModal from '@/components/_oldComponents/ContactInfoModal';
import { StyledLinkButton } from '@/components/designSystem/Typography';
import { Flex } from '../designSystem/Layout';

const TextButton = styled(StyledLinkButton)`
  text-decoration: underline;
  white-space: nowrap;
`;

interface IProps {
  contact: IContact,
}

export const ContactLink = ({ contact }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const name = get('name', contact);
  const position = get('position', contact);
  const email = get('email', contact);
  const phoneNumber = get('phoneNumber', contact);

  return (
    <Flex justify='space-between' align='center'>
      <TextButton small onClick={() => setIsOpen(true)}>Contact Info</TextButton>
      <ContactInfoModal
        name={name}
        position={position}
        email={email}
        phoneNumber={phoneNumber}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Flex>
  );
};
