import React, { Dispatch, SetStateAction } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { UPDATE_CREDITSAFE_SETTINGS } from '@/graphql/mutations/updateCreditsafeSettings';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';
import { Callout } from '@/components/Callout/Callout';
import { BodyText } from '@/components/designSystem/Typography';

const validation = Yup.object().shape({
  username: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  password: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

interface IFields {
  username: string,
  password: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess?: (response: FetchResult) => void,
  username?: string,
}

export const CreditSafeCredentialsModal = ({
  isOpen, setIsOpen, onSuccess, username,
}: IProps) => {
  const [updateCreditsafeSettings, { loading }] = useMutation(UPDATE_CREDITSAFE_SETTINGS);

  // eslint-disable-next-line no-shadow
  const handleSubmit = async ({ username, password }: IFields) => {
    const variables = {
      settings: {
        credit_safe_username: username,
        credit_safe_password: password,
      },
    };

    updateCreditsafeSettings({ variables })
      .then((response) => {
        showToast({
          title: 'Credentials Successfully Update!',
          description: 'You are now able to request Creditsafe Credit Reports for your customers',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      })
      .catch(() => {
        showToast({
          title: 'Credentials Invalid!',
          description: 'Your provided Creditsafe credentials are not valid.',
          type: toast.TYPE.ERROR,
        });
        setIsOpen(false);
      });
  };

  const fields = {
    username,
    password: '',
  };

  return (
    <StyledModalForm
      title='Login to Creditsafe'
      submitButtonText='Confirm'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <Callout color='pastelRed'>
        <BodyText>Please ask your Creditsafe representative for API credentials, as these credentials are different from your typical Creditsafe login.</BodyText>
      </Callout>
      <TextField required type='text' name='username' label='Username' placeholder='you@company.com' />
      <TextField required type='password' name='password' label='Password' placeholder='*****' />
    </StyledModalForm>
  );
};
