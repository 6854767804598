import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Formik, FormikValues } from 'formik';

import { get, getOr, orderBy, noop } from 'lodash/fp';

import { IFieldProps } from '@/types/field';
import { ICustomer } from '@/types/customer';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Field } from '@/components/designSystem/Form/Field';
import { H3, H4 } from '@/components/designSystem/Typography';
import { CreditSafeCredentialsModal } from '@/modals/CreditSafeCredentialsModal';
import { EquifaxCredentialsModal } from '@/modals/EquifaxCredentialsModal';
import { ExperianCredentialsModal } from '@/modals/ExperianCredentialsModal';
import { DnbCredentialsModal } from '@/modals/DnbCredentialsModal';
import { CLIENT_HIDE_DNB, CLIENT_HIDE_EXPERIAN, CLIENT_HIDE_EQUIFAX } from '@/app.config';
import { IDocument, DocumentRow } from '@/components/Document';
import { IUploadedFile } from '@/types/file';

import { CreditSafeCreditReportModal } from './modals/CreditSafeCreditReportModal';
import { EquifaxCreditReportModal } from './modals/EquifaxCreditReportModal';
import { ExperianCreditReportModal } from './modals/ExperianCreditReportModal';
import { DnbCreditReportModal } from './modals/DnbCreditReportModal';
import { CreditReportUpload } from './CreditReportUpload';
import { DELETE_CREDIT_REPORT } from '@/graphql/mutations/deleteCreditReport';

import CreditSafeLogo from '@/assets/CreditSafeLogo.png';
import DnBLogo from '@/assets/DnBLogo.png';
import ExperianLogo from '@/assets/ExperianLogo.png';

import {
  RequestSection,
  CreditRequestPillContainer,
  StyledCSLogo,
  StyledDnBLogo,
  StyledEquifaxLogo,
  StyledExperianLogo,
  PreviousReportDisplayItems,
  CreditRequestPill,
  StyledBureauButton,
} from './styled';
import { StyledTabPanelContainer } from '../../styled';
import { useCreditSafe } from './useCreditSafe';
import { useDnb } from './useDnb';
import { useEquifax } from './useEquifax';
import { useExperian } from './useExperian';
import { FlexRow } from '@/components/designSystem/Layout';

export interface IFile {
  fileId?: string,
  name: string,
  path?: string,
  // signedUrl set as optional, because onDrop method has an error: "Property 'signedUrl' is missing in type 'File' but required in type 'IFile'"
  signedUrl?: string,
  lastModified?: number,
  lastModifiedDate?: Date,
  size?: number,
  type: string,
  webkitRelativePath?: string,
}

interface ICreditReportUploadField {
  name: string,
  refetch: () => void,
  customerId: string,
  prompt: string
  onlyOne: boolean
}

interface IProps {
  customerData?: ICustomer,
  customerId: string,
  refetchCustomerData: () => void,
  canSeeEditControl: boolean,
}

export const BureauReport = ({ customerData, customerId, refetchCustomerData, canSeeEditControl }: IProps) => {

  const [visibleFiles, setVisibleFiles] = useState<IFile[]>([]);

  const setFileFieldState = (files: IFile[]) => {
    setVisibleFiles(files);
  };

  const {
    showCreditSafeCredentialsModal,
    setShowCreditSafeCredentialsModal,
    showCreditSafeSearchModal,
    setShowCreditSafeSearchModal,
    csLoading,
    onCreditSafeClick,
  } = useCreditSafe();

  const {
    showDnbCredentialsModal,
    setShowDnbCredentialsModal,
    showDnbSearchModal,
    setShowDnbSearchModal,
    dnbLoading,
    onDnbClick,
  } = useDnb();

  const {
    showEquifaxCredentialsModal,
    setShowEquifaxCredentialsModal,
    showEquifaxSearchModal,
    setShowEquifaxSearchModal,
    eqLoading,
    onEquifaxClick,
  } = useEquifax();

  const {
    showExperianCredentialsModal,
    setShowExperianCredentialsModal,
    showExperianSearchModal,
    setShowExperianSearchModal,
    exLoading,
    onExperianClick,
  } = useExperian();

  const tmpCreditReports = getOr([], 'creditReports', customerData);
  const creditReports = orderBy(['storedFile.insertedAt'], ['desc'])(tmpCreditReports);

  const REPORTS = [
    {
      title: 'Creditsafe',
      LogoComponent: StyledCSLogo,
      isLoading: csLoading,
      onClick: onCreditSafeClick,
      logoCondition: true,
    },
    {
      title: 'D&B',
      LogoComponent: StyledDnBLogo,
      isLoading: dnbLoading,
      onClick: onDnbClick,
      logoCondition: CLIENT_HIDE_DNB !== 'true',
    },
    {
      title: 'Equifax',
      LogoComponent: StyledEquifaxLogo,
      isLoading: eqLoading,
      onClick: onEquifaxClick,
      logoCondition: CLIENT_HIDE_EQUIFAX !== 'true',
    },
    {
      title: 'Experian',
      LogoComponent: StyledExperianLogo,
      isLoading: exLoading,
      onClick: onExperianClick,
      logoCondition: CLIENT_HIDE_EXPERIAN !== 'true',
    }
  ]

  const transformReportType = (type: string) => {
    const map: Record<string, any> = {
      'credit_safe': {title: 'Credisafe', logo: CreditSafeLogo},
      'equifax': {title: 'Equifax', logo: null},
      'experian': {title: 'Experian', logo: ExperianLogo},
      'dnb': {title: 'D&B', logo: DnBLogo},
    };
    const defaultTitle = 'Custom Credit';
    return map[type] || {title: `${defaultTitle} Report`, logo: null};
  }

  const documents: IDocument[] = creditReports
    .map((creditReport) => {
      const file: IUploadedFile = get('storedFile', creditReport);
      const transformed: any = transformReportType(get('reportSource', creditReport));
      const content: string = get('originalFilename', file) as string;
      return {
        title: transformed.title,
        content: content,
        file: file,
        thumbnail: transformed.logo
      }
    });

  const [deleteCreditReportMutation] = useMutation(DELETE_CREDIT_REPORT);
  const deleteCreditReport = (file: IUploadedFile) => {
    deleteCreditReportMutation({ variables: { file_id: file.id } })
      .then(() => {
        showToast({
          title: 'Credit Report Deleted',
          description: 'Your credit report has been successfully deleted',
          type: toast.TYPE.SUCCESS,
        });
        refetchCustomerData();
      });
  };

  return (
    <StyledTabPanelContainer>
      <RequestSection>
        <H3 bold>Get a Credit Report</H3>
        <CreditRequestPillContainer>
          {
            REPORTS.map((report) => (
              <CreditRequestPill key={report.title}>
                { (report.logoCondition) && <report.LogoComponent isLoading={report.isLoading} onClick={report.onClick} />}
                <StyledBureauButton primary loading={report.isLoading} onClick={report.onClick} >Get {report.title} Report</StyledBureauButton>
              </CreditRequestPill>
            ))
          }
        </CreditRequestPillContainer>
        <PreviousReportDisplayItems>
          <H3 bold>Reports</H3>
          {creditReports.length === 0 ? <H4>No reports.</H4> : null }
          {
            documents.map((document) => <DocumentRow
              key={document.file.id}
              document={document}
              refetch={refetchCustomerData}
              onDelete={() => deleteCreditReport(document.file)}
            />)
          }
        </PreviousReportDisplayItems>
        <FlexRow>
          {canSeeEditControl && (
            <CreditReportUpload
              customerId={customerId}
              prompt='Upload a Report'
              onlyOne
              refetch={refetchCustomerData}
              allFiles={visibleFiles}
              setAllFiles={setFileFieldState}
            />
          )}
        </FlexRow>
      </RequestSection>
      <CreditSafeCreditReportModal
        customer={customerData}
        isOpen={showCreditSafeSearchModal}
        setIsOpen={setShowCreditSafeSearchModal}
        onSuccess={() => refetchCustomerData()}
      />
      <CreditSafeCredentialsModal
        isOpen={showCreditSafeCredentialsModal}
        setIsOpen={setShowCreditSafeCredentialsModal}
        username=''
      />
      <EquifaxCreditReportModal
        customer={customerData}
        isOpen={showEquifaxSearchModal}
        setIsOpen={setShowEquifaxSearchModal}
        onSuccess={() => refetchCustomerData()}
      />
      <EquifaxCredentialsModal
        isOpen={showEquifaxCredentialsModal}
        setIsOpen={setShowEquifaxCredentialsModal}
      />
      <ExperianCreditReportModal
        customer={customerData}
        isOpen={showExperianSearchModal}
        setIsOpen={setShowExperianSearchModal}
        onSuccess={() => refetchCustomerData()}
      />
      <ExperianCredentialsModal
        isOpen={showExperianCredentialsModal}
        setIsOpen={setShowExperianCredentialsModal}
        username=''
      />
      <DnbCreditReportModal
        customer={customerData}
        isOpen={showDnbSearchModal}
        setIsOpen={setShowDnbSearchModal}
        onSuccess={() => refetchCustomerData()}
      />
      <DnbCredentialsModal
        isOpen={showDnbCredentialsModal}
        setIsOpen={setShowDnbCredentialsModal}
      />
    </StyledTabPanelContainer>
  );
};
