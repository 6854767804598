import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro-spa';

import { useMutation } from '@apollo/react-hooks';

import { get } from 'lodash/fp';

import { IPublicCompany } from '@/types/publicCompany';

import { VisitorContext } from '@/providers/VisitorContext';

import { BodyText, H2 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';

import { StyledContainer, StyledSignButton, StyledCheckboxWrapper } from './styled';
import { Agreement } from './Agreement';
import { StyledTitleContainer } from '../../styled';
import { ChangesRequestedValuesContext } from '../../context';
import { COMPLETE_PATH } from '../../constants';
import { CHANGE_REQUEST_COMPLETE } from '@/graphql/mutations/changeRequestComplete';

export interface IProps {
  providerCompany: IPublicCompany,
}

export const AgreementStep = ({ providerCompany }: IProps) => {
  const visitorData: ExtendedGetResult | null = useContext(VisitorContext);
  const history = useHistory();
  const changesRequestedValues = useContext(ChangesRequestedValuesContext);
  const changeRequest = changesRequestedValues?.changeRequest;
  const customerId = changesRequestedValues?.customer?.id!;

  const [completeChangesRequested] = useMutation(CHANGE_REQUEST_COMPLETE);

  const [authorizedToSignCheck, setAuthorizedToSignCheck] = useState(false);
  const isButtonDisabled = !authorizedToSignCheck;

  const agreementText = get('agreementText', providerCompany);
  const providerCompanyName = get('name', providerCompany);

  const onAgreementSigned = () => {
    history.push({ pathname: COMPLETE_PATH });

    completeChangesRequested({ variables: {
      changeRequestId: changeRequest?.id,
      visitorData }}
    ).then(() => {
        history.push({ pathname: COMPLETE_PATH });
      });
  }

  if (!changesRequestedValues || !changeRequest) {
    return <LoadingSpinner />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <H2 bold>{providerCompanyName} Credit Agreement</H2>
      </StyledTitleContainer>
      <Agreement agreementText={agreementText} />
      <StyledCheckboxWrapper>
        <Checkbox checked={authorizedToSignCheck} onChange={() => setAuthorizedToSignCheck((prev) => !prev)}>
          <BodyText bold>
            I acknowledge that my electronic signature is legally binding and I am authorized to apply for credit and enter into this agreement on behalf of my company.
            I hereby authorize the bank, vendors and other sources named herein to release the data and information requested for the purpose of obtaining and/or reviewing my company's credit.
            I agree to the Terms and Conditions set forth in this agreement.
          </BodyText>
        </Checkbox>
      </StyledCheckboxWrapper>
      <StyledSignButton customerId={customerId} onSuccess={onAgreementSigned} disabled={isButtonDisabled}>Sign Agreement</StyledSignButton>
    </StyledContainer>
  )};
