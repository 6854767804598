import React, { Dispatch, SetStateAction } from 'react';

import { StyledModal } from '@/modals/StyledModal';

interface IProps {
  title: string,
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  disableClose?: boolean,
  children: React.ReactNode,
}

export const CreditReportModalContainer = ({
  title,
  isOpen,
  setIsOpen,
  disableClose = false,
  children,
}: IProps) => (
  <StyledModal
    title={title}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    maxWidth='768px'
    maxHeight='80vh'
    disableClose={disableClose}
    blockClose
  >
    {children}
  </StyledModal>
);
