import React, { Dispatch, SetStateAction } from 'react';

import { formatCustomFieldType } from '@/utils/format';

import { IContactSettings } from '@/types/customField';

import { CUSTOM_FIELD_TYPES, CUSTOM_TOGGLE_FIELD_TYPES, CUSTOM_TOGGLE_FIELD_TYPES_OPTIONS } from '@/constants';

import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { BodyText, H4 } from '@/components/designSystem/Typography';
import { Flex, FlexRow, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

import {
  StyledTypeTitle,
  StyledAsterisk,
  StyledTypeDescription,
  StyledFieldWithoutMargin,
  CheckboxBody,
  ToggleSelectField,
  SelectedToggle,
  NotSelectedToggle,
} from './styled';
import { ContactTypeForm } from './ContactTypeForm';
import { DropdownTypeForm } from './DropdownTypeForm';
import { IUpdateCustomFieldFormValues } from './UpdateCustomFieldModal';
import { useFormikContext } from 'formik';

interface IProps {
  type: string,
  contact: IContactSettings,
  contactNameRequired: boolean,
  setContactNameRequired: Dispatch<SetStateAction<boolean>>,
}

export const UpdateCustomFieldModalForm = ({ type, contact, contactNameRequired, setContactNameRequired }: IProps) => {
  const { values } = useFormikContext<IUpdateCustomFieldFormValues>()

  return (
    <>
      <StyledFieldWithoutMargin required type='text' name='label' label='Label' placeholder='Field Title' />
      <FlexRow><StyledTypeTitle>Type<StyledAsterisk>*</StyledAsterisk></StyledTypeTitle></FlexRow>
      <StyledTypeDescription color='secondary'>
        <H4 color='secondary' bold>{type && formatCustomFieldType(type)}</H4>
        (Once a field is created you cannot change its type. To edit the type, you must delete this field and add a new field.)
      </StyledTypeDescription>
      {type === CUSTOM_FIELD_TYPES.FILE_UPLOAD && (
        <CheckboxField name='allowMultipleUploads'>
          <CheckboxBody>Allow multiple files</CheckboxBody>
        </CheckboxField>
      )}
      {type === CUSTOM_FIELD_TYPES.CONTACT && (
        <ContactTypeForm
          nameChecked={contactNameRequired}
          setNameChecked={setContactNameRequired}
        />
      )}
      {type === CUSTOM_FIELD_TYPES.TOGGLE && (
        <Flex direction='row' justify='space-between' gap='0.5rem'>
          <Flex direction='column' border='solid 0.02em #ECECEC' padding='10px'>
            <BodyText bold>If Yes Selected, show:</BodyText>
            <ToggleSelectField name='yesToggle.type' options={CUSTOM_TOGGLE_FIELD_TYPES_OPTIONS} />
            {values.yesToggle.type === CUSTOM_TOGGLE_FIELD_TYPES.FILE_UPLOAD && (
              <CheckboxField name='yesToggle.allowMultipleUploads'>
                <BodyText>Allow multiple files</BodyText>
              </CheckboxField>
            )}
            <TextAreaField name='yesToggle.description' label='Description' />
          </Flex>
          <Flex direction='column' border='solid 0.02em #ECECEC' padding='10px'>
            <BodyText bold>If No Selected, show:</BodyText>
            <ToggleSelectField name='noToggle.type' options={CUSTOM_TOGGLE_FIELD_TYPES_OPTIONS} />
            {values.noToggle.type === CUSTOM_TOGGLE_FIELD_TYPES.FILE_UPLOAD && (
              <CheckboxField name='noToggle.allowMultipleUploads'>
                <BodyText>Allow multiple files</BodyText>
              </CheckboxField>
            )}
            <TextAreaField name='noToggle.description' label='Description' />
          </Flex>
        </Flex>
      )}
      {[CUSTOM_FIELD_TYPES.DROPDOWN, CUSTOM_FIELD_TYPES.MULTIPLE_CHOICE, CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION].includes(type) ? (
        <DropdownTypeForm additionalQuestionType={type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION} />
      ) : false}
      {type !== CUSTOM_FIELD_TYPES.CONTACT && (
        <CheckboxField name='required'>
          <BodyText>Make this field required</BodyText>
        </CheckboxField>
      )}
    </>
  );
};
