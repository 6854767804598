import React from 'react';
import { useLocation } from 'react-router';

import { IBrand, IPublicCompany } from '@/types/publicCompany';

import { BackButton } from '@/components/designSystem/buttons/BackButton';

import { ReactComponent as PoweredByLogo } from '@/assets/powered_by.svg';

import { StepHeader } from '../StepHeader';
import {
  StyledFormContainer,
  StyledLeftBlock,
  StyledMain,
  StyledRightBlock,
  StyledPoweredByContainer,
  // StyledProviderInfoContainer,
  // StyledStepCounterContainer,
} from './styled';
import { StyledCalloutContainer, StyledStepCounterContainer } from '../styled';
import { STEPS } from './constants';
import { useQuery } from '@apollo/react-hooks';
import { GET_COMPANY_BY_ID } from '@/graphql/queries/getCompanyById';
import { get } from 'lodash/fp';
import { OnboardTime } from '@/components/Onboard/OnboardTime';
import { Callout } from '@/components/Callout/Callout';
import { StepCounter } from '@/components/Onboard/StepCounter';

interface IProps {
  children: React.ReactNode,
  overriddenLogo: JSX.Element | null,
  brand: IBrand | undefined
  index: number,
  prevPath: string | null,
  providerCompanyName?: string,
  providerCompanyEmail?: string,
  providerCompanyPhone?: string,
  minutes?: number,
}

export const StepWrapper = ({
  overriddenLogo,
  brand,
  children,
  index,
  prevPath,
  providerCompanyName,
  providerCompanyEmail,
  providerCompanyPhone,
  minutes,
}: IProps) => {
  const { search } = useLocation();

  return (
    <>
      <StepHeader
        overriddenLogo={overriddenLogo}
        steps={(
          <StyledStepCounterContainer steps={Object.values(STEPS)}>
            <StepCounter steps={Object.values(STEPS)} activeStep={index} />
          </StyledStepCounterContainer>
        )}
      >
        { minutes ? (
          <OnboardTime minutes={minutes} />
        ) : false }
        {(providerCompanyName && providerCompanyEmail) ? (
          <StyledCalloutContainer>
            <Callout color='label.default'>
              If you have any questions, please contact {providerCompanyName} at {providerCompanyEmail}.
            </Callout>
          </StyledCalloutContainer>
        ) : false }
      </StepHeader>
      <StyledMain>
        <StyledLeftBlock>
          {prevPath ? (
            <BackButton link={`${prevPath}${search}`} />
          ) : false }
        </StyledLeftBlock>
        <StyledFormContainer>
          {children}
        </StyledFormContainer>
        <StyledRightBlock>
          {(brand) ? (
            <StyledPoweredByContainer href='https://nectarinecredit.com' target='_blank'>
              <PoweredByLogo />
            </StyledPoweredByContainer>
          ) : false }
        </StyledRightBlock>
      </StyledMain>
    </>
  )}
;
