import React from 'react';
import styled from 'styled-components';

import { SubHeading } from '@/components/designSystem/Typography';
import { FlexRowItemsCenter } from '@/components/designSystem/Layout';
import { ReactComponent as ClockIcon } from '@/assets/clock.svg';

const StyledContainer = styled(FlexRowItemsCenter)`
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const StyledText = styled(SubHeading)`
  color: ${({ theme }) => theme.color.brand};
`;

const StyledIcon = styled(ClockIcon)`
  fill: ${({ theme }) => theme.color.brand};
`;

interface IProps {
  minutes: number;
}

export const OnboardTime = ({ minutes }: IProps) => (
  <StyledContainer><StyledIcon /><StyledText>Takes {minutes} minutes</StyledText></StyledContainer>
);
