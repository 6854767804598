import React from 'react';

import { EditButton } from '@/components/designSystem/buttons/EditButton';

import { ReactComponent as CloseIcon } from '@/assets/x_close.svg';

import { StyledTitleRow, StyledTitle, StyledCloseIconWrapper } from './styled';

interface IProps {
  title: string,
  editSection?: () => void,
  onCloseClick?: () => void,
  isEditEnabled?: boolean,
  canSeeEditControl?: boolean,
  children: React.ReactNode,
}

export const SectionContainer = ({ title, editSection, onCloseClick, isEditEnabled = false, children, canSeeEditControl = true }: IProps) => {

  const renderTopLeft = () => {
    if (canSeeEditControl) {
      if (isEditEnabled) {
        return (<EditButton onClick={editSection} />);
      }
      if (onCloseClick) {
        return (
          <StyledCloseIconWrapper>
            <CloseIcon onClick={onCloseClick} />
          </StyledCloseIconWrapper>
        );
      }
    }
    return false;
  }

  return (
    <>
      <StyledTitleRow>
        <StyledTitle bold>{title}</StyledTitle>
        {renderTopLeft()}
      </StyledTitleRow>
      {children}
    </>
  )};