import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { H2, BodyText } from '@/components/designSystem/Typography';

import { ReactComponent as CheckIcon } from '@/assets/check.svg';

import { StyledBankConnectedViewContainer, StyledCheckImageContainer, StyledBankConnectedViewNextButtonWrapper } from '../styled';
import {
  StyledNextButton,
} from '../../../styled';

export const BankConnectedView = () => {
  const history = useHistory();
  const { search } = useLocation();

  const handleNextClick = () => {
    history.push({ pathname: 'vendors', search });
  }

  return (
    <StyledBankConnectedViewContainer>
      <StyledCheckImageContainer><CheckIcon /></StyledCheckImageContainer>
      <H2 bold>Bank Account Verification Complete</H2>
      <BodyText>Click "Next" below to finish your credit application.</BodyText>
      <StyledBankConnectedViewNextButtonWrapper>
        <StyledNextButton primary onClick={handleNextClick}>Next</StyledNextButton>
      </StyledBankConnectedViewNextButtonWrapper>
    </StyledBankConnectedViewContainer>
  )};