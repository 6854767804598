import { reject, every, get, map, mapValues } from 'lodash/fp';

/**
 * Checks if an object has any set value
 *
 * Examples
 *   objectValuesNotSet({})
 *     => false
 *
 *   objectValuesNotSet({a: "", b: ""})
 *     => false
 *
 *    objectHasSetValue({a: "!", b: ""})
 *     => true
 * @param {Object} obj
 */
export const objectHasSetValue = (obj: any) => Object.values(obj).some((v) => !(v === null || v === undefined || v === ''));

export const allValuesEmpty = every((v) => !v);
export const removeEmptyObjects = reject(allValuesEmpty);

export const getWithBackup = (pk: any, sk: any, data: any) => get(pk, data) || get(sk, data);

// TODO Fix lodash functions. Probably avoid using convert like this

export const mapValuesWithKey = (mapValues as any).convert({ cap: false });

export const mapWithKey = (map as any).convert({ cap: false });
