import React from 'react';

import { get, getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { EmptyState } from '@/components/EmptyState';

import { FinicitySection } from './FinicitySection';
import { ManualSection } from './ManualSection';

interface IProps {
  customer?: ICustomer,
  refetch: () => void,
}

export const FinancialHealthSection = ({ customer, refetch }: IProps) => {
  const customerId = get('id', customer);
  const finicityAccounts = getOr([], 'seekerCompany.finicityAccountProjections', customer);
  const manualInstitutions = getOr([], 'seekerCompany.manualInstitutions', customer);
  const manualAccountNotes = getOr([], 'manualAccountNotes', customer);

  const noData = finicityAccounts.length === 0
    && manualInstitutions.length === 0
    && manualAccountNotes.length === 0;

  return (
    <TwoColumnContainer.MainItem title='Financial Health'>
      {noData ? <EmptyState>No Banking Information provided yet</EmptyState> : null}
      {finicityAccounts.length > 0 ? <FinicitySection accounts={finicityAccounts} /> : null}
      {manualInstitutions.length > 0 ? <ManualSection customerId={customerId} institutions={manualInstitutions} accountNotes={manualAccountNotes} refetch={refetch} /> : null}
    </TwoColumnContainer.MainItem>
  );
};
