import React, { Dispatch, SetStateAction, useEffect } from 'react';

import styled from 'styled-components';
import { StyledModal } from '@/modals/StyledModal';

const EversignContainer = styled.div`
  width: 100%;
  height: 100%;
`;

interface IProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: () => void,
  embeddedUrl?: string,
}

export const EversignWidget = ({ setIsOpen, onSuccess, embeddedUrl }: IProps) => {
  useEffect(() => {
    if (!embeddedUrl) return;
    window.eversign.open({
      url: embeddedUrl,
      containerID: 'eversign-container',
      width: 520,
      height: 668,
      events: {
        loaded() {
          console.log('loaded Callback');
        },
        signed() {
          console.log('signed Callback');
          setIsOpen(false);
          onSuccess();
        },
        declined() {
          console.log('declined Callback');
        },
        error() {
          console.log('error Callback');
        },
      },
    });
  }, [embeddedUrl]);

  return (
    <>
      <EversignContainer id='eversign-container' />
    </>
  );
};
