import React from 'react';

import { PopperMenuContainer } from '@/components/PopperMenu/container';

import { PopperMenuItems } from './items';

interface IMenuItem {
  name: string,
  icon?: React.ReactNode,
  onClick?: (args: any) => void,
}

export interface IPopperMenuProps {
  items: IMenuItem[],
  placement?: string,
  offset?: number[],
}

export const PopperMenu = ({ items, placement, offset }: IPopperMenuProps) => (
  <PopperMenuContainer content={<PopperMenuItems items={items} />} placement={placement} offset={offset} />
)
