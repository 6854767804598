import React from 'react';

import { ISettings } from '@/types/settings';
import { FieldArray, useFormikContext } from 'formik';
import { ICustomField } from '@/types/customField';
import { BodyText, H2, H3, H4 } from '@/components/designSystem/Typography';
import { FlexRowEnd } from '@/components/designSystem/Layout';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { Button } from '@/components/designSystem/buttons';
import { getOr } from 'lodash/fp';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { useCurrentUserOwner } from './useCurrentUserOwner';
import { OwnerSection } from './OwnerSection';
import { initialOwnerFields, initialAdditionalContactFields } from './initialValues';
import { IBasicInfoValues } from '../BasicInfoStep/types';
import { CustomContactSection } from './CustomContactSection';
import { AdditionalContactSection } from './AdditionalContactSection';
import { IAdditionalPersonalInformationFields, ICompanyContactsValues } from './types';
import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm,
  StyledSubtitleContainer,
  StyledCheckboxWrapper,
} from '../../styled';
import { useAutoscrollToError } from '../../../useAutoscrollToError';

interface IProps {
  basicInfo: IBasicInfoValues,
  companySettings: ISettings,
  additionalPersonalInformationFields: IAdditionalPersonalInformationFields,
  customContactFields: ICustomField[],
}

export const CompanyContactsStepForm = ({
  basicInfo,
  companySettings,
  additionalPersonalInformationFields,
  customContactFields,
}: IProps) => {
  const { values, setFieldValue, setFieldTouched, errors, isSubmitting } = useFormikContext<ICompanyContactsValues>();

  const enableOwnershipStake = getOr(true, 'enableOwnershipStake', companySettings);
  const enableOwner = getOr(true, 'enableOwner', companySettings);
  useAutoscrollToError(errors, isSubmitting);

  const {
    currentUserOwner,
    currentUserOwnerClickHandle
  } = useCurrentUserOwner({
    basicInfo,
    values,
    setFieldValue,
    setFieldTouched,
  });

  return (
    <StyledForm>
      <StyledTitleContainer>
        <H2 bold>Company Contacts</H2>
      </StyledTitleContainer>
      { enableOwner && 
        <>
          <StyledSubtitleContainer>
            <H3 bold>Owners</H3>
            <H4>Enter the majority owners of the company</H4>
          </StyledSubtitleContainer>
          <StyledCheckboxWrapper>
            <Checkbox checked={currentUserOwner} onChange={currentUserOwnerClickHandle}>
              <BodyText bold>I am currently an owner of the company</BodyText>
            </Checkbox>
          </StyledCheckboxWrapper>
          <FieldArray
            name='owners'
            render={(arrayHelpers) => (
              <>
                {
                  values.owners.map((_owner, index) => (
                    <OwnerSection
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      index={index}
                      parentKey={`owners[${index}]`}
                      onRemoveClick={() => arrayHelpers.remove(index)}
                      settings={companySettings}
                      additionalPersonalInformationFields={additionalPersonalInformationFields}
                      enableOwnershipStake={enableOwnershipStake}
                    />
                  ))
                }
                <Button
                  secondary LeftIcon={Plus}
                  onClick={() => arrayHelpers.push(initialOwnerFields)}>Add Additional Owner
                </Button>
              </>
            )}
          />
        </>
      }
      <StyledSubtitleContainer>
        <H3 bold>Other Company Contacts</H3>
        <H4>Enter other key company contacts here.</H4>
      </StyledSubtitleContainer>
      {
        customContactFields.map((customContactField) => (
          <CustomContactSection
            key={customContactField.id}
            parentKey={`customContacts.${customContactField.id}`}
            label={customContactField.label}
            settings={customContactField.contact}
          />
        ))
      }
      <FieldArray
        name='additionalContacts'
        render={(arrayHelpers) => (
          <>
            {values.additionalContacts
              ? values.additionalContacts.map((_additionalContact, index) => (
                <AdditionalContactSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  parentKey={`additionalContacts[${index}]`}
                  onRemoveClick={() => arrayHelpers.remove(index)}
                />
              )) : false
            }
            <Button secondary LeftIcon={Plus} onClick={() => arrayHelpers.push(initialAdditionalContactFields)}>Add Additional Contact</Button>
          </>
        )}
      />
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Next</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )
};
