import styled from 'styled-components';

import { H3 } from '@/components/designSystem/Typography';

import device from '@/styles/device';
import { FlexRowItemsCenter } from '@/components/designSystem/Layout';

export const FinicityAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InstitutionTitleContainer = styled(FlexRowItemsCenter)`
  margin-bottom: 1.5rem;
`;

export const InstitutionTitle = styled(H3).attrs({ bold: true })`
  margin-right: 0.5rem;
`;

export const InstitutionContainer = styled.div`
  & > * {
    margin-bottom: 1rem;
  }
`;

export const FinancialActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-top: 0;
    margin-right: 1rem;
  }

  @media ${device.tablet} {
    flex-direction: column;

    & > * {
      width: 100%;
      margin-top: 1rem;
    }

    & > :first-child {
      margin-top: 0;
    }
  };
`;
