import React, { useState } from 'react';

import { get } from 'lodash/fp';

import { IContact } from '@/types/contact';

import { H4 } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import ContactInfoModal from '@/components/_oldComponents/ContactInfoModal';
import { UserInfo } from '@/components/UserInfo';
import { FlexRowSpaceBetweenItemsCenter } from '../designSystem/Layout';

interface IProps {
  contact: IContact,
}

export const Contact = ({ contact }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const name = get('name', contact);
  const position = get('position', contact);
  const email = get('email', contact);
  const phoneNumber = get('phoneNumber', contact);
  const extension = get('extension', contact);

  return (
    <FlexRowSpaceBetweenItemsCenter>
      <UserInfo name={name} detail={position} />
      <Button small onClick={() => setIsOpen(true)}><H4 bold>View Contact Info</H4></Button>
      <ContactInfoModal
        name={name}
        position={position}
        email={email}
        phoneNumber={phoneNumber}
        extension={extension}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </FlexRowSpaceBetweenItemsCenter>
  );
};
