import React, { useReducer, useState } from 'react';
import { useLocation } from 'react-router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { get, getOr } from 'lodash/fp';

import { useCPTheme } from '../useCPTheme';

import { getSteps } from './getSteps';
import { IChangeRequest } from './types';
import { changesRequestedValuesReducer } from './reducer';
import { ChangesRequestedValuesContext } from './context';
import { companyProfileInitialValues } from './steps/CompanyProfileStep/initialValues';
import { StepWrapper } from './StepWrapper';
import { companyContactsInitialValues } from './steps/CompanyContactsStep/initialValues';
import { vendorsInitialValues } from './steps/VendorsStep/initialValues';
import { otherInfoInitialValues } from './steps/OtherInfoStep/initialValues';
import { financialHealthInitialValues } from './steps/FinancialHealthStep/initialValues';

const getInitialChangesRequestedValues = (
  changeRequest: IChangeRequest,
  basePath: string
) => {
  const customerData = get('customer', changeRequest);
  return {
    companyProfile: companyProfileInitialValues(customerData),
    companyContacts: companyContactsInitialValues(customerData),
    financialHealth: financialHealthInitialValues(customerData),
    vendors: vendorsInitialValues(customerData),
    otherInfo: otherInfoInitialValues(customerData),
    changeRequest,
    customer: customerData,
    basePath,
  }
}

interface IProps {
  changeRequest: IChangeRequest,
  basePath: string,
  customerId: string | null,
}

export interface IStep {
  path: string,
  label: string,
  Component: (props: any) => JSX.Element,
  stepProps: any,
}

export const ChangesRequestedContainer = ({ changeRequest, basePath }: IProps) => {
  const { search } = useLocation();

  const [fullApplication, setFullApplication] = useState(false);

  const customerData = get('customer', changeRequest);
  const changesRequestedStepsWithoutDispatch: IStep[] = getSteps({
    changeRequest,
    fullApplication,
  });

  const startPath = `${basePath}/${changesRequestedStepsWithoutDispatch[0].path}`

  // CP options

  const providerCompany = getOr(null, 'providerCompany', customerData);
  const providerCompanyName = get('name', providerCompany);
  const providerComment = get('latestApplication.providerComment', customerData);

  // Theme

  const { overriddenTheme, logoUrl, brand } = useCPTheme(providerCompany);

  const overriddenLogo = logoUrl
    ? <img src={logoUrl} alt='logo' />
    : null;

  //

  const [changesRequestedValues, dispatch] = useReducer(
    changesRequestedValuesReducer,
    getInitialChangesRequestedValues(
      changeRequest,
      basePath
    )
  );

  const changesRequestedSteps: IStep[] = changesRequestedStepsWithoutDispatch
    .map((step) => ({
      ...step,
      stepProps: {
        ...step.stepProps,
        dispatch
      }
    }));

  return (
    <ThemeProvider theme={overriddenTheme}>
      <div>
        <Switch>
          <ChangesRequestedValuesContext.Provider value={
            changesRequestedValues
          }>
            {changesRequestedSteps.map(({
              path, Component, stepProps
            } : IStep, index) => (
              <Route
                key={path}
                path={`${basePath}/${path}`}
                render={() =>
                  <StepWrapper
                    prevPath={index ? `${basePath}/${changesRequestedSteps[index - 1].path}` : null}
                    index={index}
                    overriddenLogo={overriddenLogo}
                    brand={brand}
                    providerCompanyName={providerCompanyName}
                    providerComment={providerComment}
                    steps={changesRequestedSteps}
                    fullApplication={fullApplication}
                    setFullApplication={setFullApplication}
                  >
                    <Component {...stepProps} />
                  </StepWrapper>
                }
              />
            ))}
            <Redirect from={basePath} to={`${startPath}${search}`} />
          </ChangesRequestedValuesContext.Provider>
        </Switch>
      </div>
    </ThemeProvider>
  )
}
