import { createContext } from 'react';

import { ICompanyUser } from '@/types/companyUser';

interface ICurrentUserContext {
  currentUser: ICompanyUser,
  currentUserLoading: boolean,
  refetchCurrentUser: () => void,
}

export const CurrentUserContext = createContext<ICurrentUserContext | null>(null);