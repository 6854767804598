import React, { useContext } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { ANSWER_CUSTOM_FIELDS } from '@/graphql/mutations/answerCustomFields';

import { Formik, Form } from 'formik';

import { get, getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { mapFormValuesToFieldAnswers } from '@/utils/customFields';

import { ICustomer } from '@/types/customer';
import { ICustomField, ICustomFieldValue } from '@/types/customField';

import { VisitorContext } from '@/providers/VisitorContext';

import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Button } from '@/components/designSystem/buttons';
import { CustomField } from '@/components/CustomField/CustomField';
import { TextField } from '@/components/designSystem/Form/TextField';

import { supportingInfoInitialValues } from './initialValues';
import { supportingInfoValidation } from './validation';
import { CUSTOM_FIELD_TYPES } from '@/constants';

interface IValues {
  einFederalTaxNumber: string,
  customFields: ICustomFieldValue,
}

interface ISupportingInfoSectionProps {
  customer: ICustomer,
  refetch: () => void,
}

export const SupportingInfoSection = ({ customer, refetch }: ISupportingInfoSectionProps) => {
  const visitorData = useContext(VisitorContext);

  const customFieldAnswers = getOr([], 'customFieldAnswers', customer);
  const creditLimit = getOr('', 'latestApplication.creditLimit', customer);
  const einFederalTaxNumber = getOr('', 'latestApplication.einFederalTaxNumber', customer);
  const requireEinFederalTaxNumber = getOr(false, 'providerCompany.settings.requireEinFederalTaxNumber', customer);
  const creditTerms = getOr('', 'latestApplication.creditTerms', customer);
  const customFields: ICustomField[] = getOr([], 'providerCompany.customFields', customer);
  const customFieldsForSupportingInfoSection: ICustomField[] = customFields
    .filter(customField => (customField.type !== CUSTOM_FIELD_TYPES.CONTACT));

  const [answerCustomFields, { loading }] = useMutation(ANSWER_CUSTOM_FIELDS);

  if (customFieldsForSupportingInfoSection.length === 0) return null;

  const updateCustomFields = async (formValues: IValues) => {
    const customerId = get('id', customer);
    const customFieldAnswersValue = mapFormValuesToFieldAnswers(
      formValues.customFields,
      customFieldsForSupportingInfoSection
    )

    const response = await answerCustomFields({
      variables: {
        customerId,
        visitorData,
        customFieldAnswers: customFieldAnswersValue,
        applicationAttrs: {
          creditLimit,
          creditTerms,
          einFederalTaxNumber: formValues.einFederalTaxNumber,
        },
      },
    });

    const success = get('data.answerCustomFields', response);

    if (success) {
      showToast({
        title: 'Saved',
        description: 'The changes to your profile were successfully saved',
        type: toast.TYPE.SUCCESS,
      });
      refetch();
    }
  };

  return (
    <TwoColumnContainer.MainItem title='Supporting Information'>
      <Formik
        validateOnChange
        initialValues={supportingInfoInitialValues(customFieldsForSupportingInfoSection, customFieldAnswers, einFederalTaxNumber)}
        validationSchema={supportingInfoValidation(customFieldsForSupportingInfoSection, requireEinFederalTaxNumber)}
        onSubmit={(values) => handleSubmitWrapper(values, updateCustomFields)}
        enableReinitialize
      >
        <Form>
          <TextField
            type='text'
            required={requireEinFederalTaxNumber}
            name='einFederalTaxNumber'
            label='EIN / Federal Tax Number'
            placeholder='Enter EIN / Federal Tax ID Number'
          />
          {customFieldsForSupportingInfoSection.map((customField) => (
            <CustomField
              key={customField.id}
              name={`customFields.${customField.id}`}
              {...customField}
              options={customField.options}
            />
          ))}
          <Button wide primary loading={loading} type='submit'>Save Supporting Information</Button>
        </Form>
      </Formik>
    </TwoColumnContainer.MainItem>
  );
};
