import React, { useState } from 'react';

import { Button } from '@/components/designSystem/buttons';

import { StyledPreviewContainer, StyledPreviewButtonWrapper } from './styled';

interface IProps {
  children: React.ReactNode,
}

export const CustomFieldPreviewContainer = ({ children }: IProps) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  return (
    <StyledPreviewContainer>
      {isPreviewVisible ? children : (
        <StyledPreviewButtonWrapper>
          <Button primary onClick={() => setPreviewVisible(true)}>Show Preview</Button>
        </StyledPreviewButtonWrapper>)}
    </StyledPreviewContainer>
  )
};
