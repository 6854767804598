import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_INTERNAL_BANK_DOCUMENT } from '@/graphql/mutations/uploadInternalBankDocument';

import { get } from 'lodash/fp';
import { formatAccountType } from '@/utils/format';

import { IManualBank } from '@/types/manualBank';
import { IManualAccount } from '@/types/manualAccount';

import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';
import { BodyText } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { ToggleBlock } from '@/components/ToggleBlock';
import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';
import { IFile } from '@/components/FileUpload/FileUpload';
import { RedXIcon } from '@/components/icons';

import {
  StyledToggleBankHeader,
  TooltipContainer,
  TooltipText,
  CaptionWithIcon,
} from '../../styled';
import { ManualAccountUpdateView } from './ManualAccountUpdateView';
import { ManualAccountInfoView } from './ManualAccountInfoView';

interface IInternalBankDocumentValues {
  manualAccountId: string,
  bankDocument: IFile[],
  notes: string
}

interface IProps {
  account: IManualAccount,
  accountNotes: string[],
  institution: IManualBank,
  institutionName: string,
  customerId: string,
  refetch: () => void,
}

export const ManualAccount = ({ account, institution, institutionName, refetch }: IProps) => {
  const [isEditActive, setEditActive] = useState(false);
  const [isInternalBankDocumentModal, setInternalBankDocumentModal] = useState(false);

  const [uploadInternalBankDocument] = useMutation(UPLOAD_INTERNAL_BANK_DOCUMENT);

  const accountName = get('firstname', account) + get('lastname', account);
  const accountNumber = get('accountNumber', account);
  const accountType = formatAccountType(get('accountType', account));
  const routingNumber = get('routingNumber', account);
  const internalBankDocuments = get('internalBankDocuments', account);

  const verificationStatus = get('verificationStatus', account);
  const verificationMessage = get('verificationMessage', account);
  const isBusinessAccount = get('isBusinessAccount', account);

  const onUploadBankDocument = async (values: IInternalBankDocumentValues) => {
    const variables = {
      manualAccountId: values.manualAccountId,
      internalBankDocumentAttrs: [{
        bankDocumentId: values.bankDocument[0].fileId,
        notes: values.notes
      }]
    };

    uploadInternalBankDocument({variables})
      .then(() => setInternalBankDocumentModal(false))
      .then(() => refetch());
  };

  const Header = (
    <StyledToggleBankHeader>
      <BodyText bold>{institutionName}</BodyText>
      <BodyText bold>{accountType} {accountNumber}
        <br/><BodyText>Manual ({ isBusinessAccount ? "Business Account" : "Personal Account"})</BodyText>
      </BodyText>
      <TooltipContainer>
        {
          verificationStatus != null && !verificationStatus.localeCompare('DECLINE')?
            <CaptionWithIcon><RedXIcon/> Bank info mismatch</CaptionWithIcon> : null
        }
        <TooltipText>{verificationMessage}</TooltipText>
      </TooltipContainer>
    </StyledToggleBankHeader>
  );

  return (
    <ToggleBlock header={Header}>
      {isEditActive ? (
        <ManualAccountUpdateView institution={institution} setEditActive={setEditActive} />
      ) : (
        <ManualAccountInfoView
          name={accountName}
          accountNumber={accountNumber}
          accountType={accountType}
          routingNumber={routingNumber}
          internalBankDocuments={internalBankDocuments}
          institution={institution}
          setInternalBankDocumentModal={setInternalBankDocumentModal}
          refetch={refetch}
        />
      )}
      <StyledModalForm
        title='Upload Internal Bank Document'
        isOpen={isInternalBankDocumentModal}
        setIsOpen={setInternalBankDocumentModal}
        initialValues={{manualAccountId: account.id, bankDocument: [], notes: ''}}
        onSubmit={onUploadBankDocument}
        submitButtonText='Submit'
        blockClose={false}
      >
        <FileUploadField name='bankDocument' onlyOne={true} />
        <TextField type='text' label='Notes' name='notes' />
      </StyledModalForm>
    </ToggleBlock>
  );
};
