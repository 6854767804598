import React from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { SEND_VERIFICATION_EMAIL } from '@/graphql/mutations/sendVerificationEmail';
import { IS_EMAIL_VERIFIED } from '@/graphql/queries/isEmailVerified';
import { UPDATE_EMAIL_NOTIFICATION_SETTINGS } from '@/graphql/mutations/updateEmailNotificationsSettings';

import { Formik, Form } from 'formik';
import Yup from '@/utils/yup';

import { get, getOr } from 'lodash/fp';

import { IEmailNotificationSetting } from '@/types/emailNotificationsSettings'
import { ICompanyUser, IUserCompanyRole } from '@/types/companyUser';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BorderedContainer } from '@/containers/BorderedContainer';
import {
  H2, H3, H4, StyledLink, Small, BodyText,
} from '@/components/designSystem/Typography';
import { LabeledComponent } from '@/components/LabeledComponent';
import { Label } from '@/components/Label/Label';
import { Flex } from '@/components/designSystem/Layout';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { AutoSave } from '@/components/designSystem/Form/AutoSave';

import { NameEditButton } from './NameEditButton';
import { EmailEditButton } from './EmailEditButton';
import { ChangePasswordButton } from './ChangePasswordButton';
import { StyledContainer, PageTitleContainer, Row, StyledEmailNotificationCheckboxWrapper } from './styled';

interface IPersonalSettingsProps {
  refetch: () => void,
  currentUser: ICompanyUser,
  emailNotificationsSettings: IEmailNotificationSetting[],
  currentUserCompanyRole: IUserCompanyRole
}

const roleToName = (name: string) => {
  switch (name) {
  case 'analyst':
    return 'Credit Analyst';
  case 'read_only':
    return 'Read Only';
  case 'manager':
    return 'Credit Manager';
  case 'controller':
    return 'Controller';
  case 'credit_applicant':
    return 'Credit Applicant';
  default:
    return 'Credit Manager';
  }
}

const emailSettingsValidation = Yup.object().shape({
  applicationSubmitted: Yup.boolean(),
  vendorReferenceSubmitted: Yup.boolean(),
  applicationApprovalOrDenial: Yup.boolean(),
  applicationChangesRequested: Yup.boolean(),
});

export const PersonalSettings = ({ currentUser, refetch, currentUserCompanyRole, emailNotificationsSettings }: IPersonalSettingsProps) => {
  const name = get('name', currentUser);
  const email = get('email', currentUser);

  const { data } = useQuery(IS_EMAIL_VERIFIED, { fetchPolicy: 'network-only' });
  const [sendVerificationEmail] = useMutation(SEND_VERIFICATION_EMAIL);

  const emailVerified = getOr(true, 'isEmailVerified', data);
  const role = getOr("analyst", 'role.name', currentUserCompanyRole)
  const isCreditLimitApply = get('role.isCreditLimitApply', currentUserCompanyRole)
  const creditLimit = get('creditLimit', currentUserCompanyRole)
  const isAdmin = get('isAdmin', currentUserCompanyRole)

  const [updateEmailNotificationSettings] = useMutation(UPDATE_EMAIL_NOTIFICATION_SETTINGS);

  const initialSettings = emailNotificationsSettings[0]

  const autoSaveSettings = (settings: IEmailNotificationSetting, { setSubmitting }: { setSubmitting: (arg: boolean) => void }) => {
    const variables = {
      applicationSubmitted: settings.applicationSubmitted,
      vendorReferenceSubmitted: settings.vendorReferenceSubmitted,
      applicationApprovalOrDenial: settings.applicationApprovalOrDenial,
      applicationChangesRequested: settings.applicationChangesRequested,
    };

    return updateEmailNotificationSettings({ variables })
      .then(() => {
        setSubmitting(false);
        showToast({
          title: 'Information Successfully Updated!',
          description: 'Email notification settings updated successfully.',
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Information wasn\'t updated',
          type: toast.TYPE.ERROR,
        });
      })
  };

  const resendVerificationEmail = () => {
    sendVerificationEmail({ variables: { redirectTo: window.location.origin } })
      .then(() => {
        showToast({
          title: 'Email Sent',
          description: 'Check your inbox to finish the verification process',
          type: toast.TYPE.SUCCESS,
        });
      });
  };

  return (
    <>
      <PageTitleContainer><H2 bold>Your Settings</H2></PageTitleContainer>
      <BorderedContainer>
        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Full Name'>
            <H4 bold>{name}</H4>
          </LabeledComponent>
          <NameEditButton refetch={refetch} name={name} />
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Email'>
            <Row>
              <H4 bold>{email}</H4>
              {!emailVerified && <Label type='warning'>Not Verified</Label>}
            </Row>
            <>
              {!emailVerified ? <Small><StyledLink to='#' onClick={resendVerificationEmail}>Resend Verification Email</StyledLink></Small> : null}
            </>
          </LabeledComponent>
          <EmailEditButton refetch={refetch} email={email} />
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Password'>
            <H4 bold>**********</H4>
          </LabeledComponent>
          <ChangePasswordButton email={email} />
        </Flex>
      </BorderedContainer>
      <PageTitleContainer><H3 bold>Your Role</H3></PageTitleContainer>
      <BorderedContainer>
        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Role'>
            <H4 bold>{roleToName(role)}</H4>
          </LabeledComponent>
        </Flex>
        <Flex justify='space-between' align='center'>
          <LabeledComponent label='User Admin'>
            <H4 bold>{isAdmin ? 'Yes' : 'No'}</H4>
          </LabeledComponent>
        </Flex>
        {isCreditLimitApply && <Flex justify='space-between' align='center'>
          <LabeledComponent label='Credit Limit'>
            <H4 bold>{creditLimit}</H4>
          </LabeledComponent>
        </Flex>}
      </BorderedContainer>
      <StyledContainer>
        <Formik
          initialValues={initialSettings}
          validationSchema={emailSettingsValidation}
          onSubmit={autoSaveSettings}
        >
          <Form>
            <PageTitleContainer><H3 bold>Email Notification Settings</H3></PageTitleContainer>
            <BorderedContainer>
              <Flex align='center'>
                <StyledEmailNotificationCheckboxWrapper>
                  <CheckboxField name='applicationSubmitted' />
                </StyledEmailNotificationCheckboxWrapper>
                <Flex direction='column'>
                  <H4 bold>Credit Application Submission</H4>
                  <BodyText color='secondary'>Receive emails when credit applicant submits and signs credit application</BodyText>
                </Flex>
              </Flex>

              <Flex align='center'>
                <StyledEmailNotificationCheckboxWrapper>
                  <CheckboxField name='vendorReferenceSubmitted' />
                </StyledEmailNotificationCheckboxWrapper>
                <Flex direction='column'>
                  <H4 bold>Vendor Reference Submission</H4>
                  <BodyText color='secondary'>Receive emails when new vendor references are submitted for a credit application</BodyText>
                </Flex>
              </Flex>

              <Flex align='center'>
                <StyledEmailNotificationCheckboxWrapper>
                  <CheckboxField name='applicationApprovalOrDenial' />
                </StyledEmailNotificationCheckboxWrapper>
                <Flex direction='column'>
                  <H4 bold>Credit Application Approval or Denial</H4>
                  <BodyText color='secondary'>Receive emails when credit applications have been approved or denied by your company</BodyText>
                </Flex>
              </Flex>

              <Flex align='center'>
                <StyledEmailNotificationCheckboxWrapper>
                  <CheckboxField name='applicationChangesRequested' />
                </StyledEmailNotificationCheckboxWrapper>
                <Flex direction='column'>
                  <H4 bold>Changes Requested</H4>
                  <BodyText color='secondary'>Receive emails when your company requests changes on a credit application</BodyText>
                </Flex>
              </Flex>
              <AutoSave debounceMs={100} />
            </BorderedContainer>
          </Form>
        </Formik>
      </StyledContainer>
    </>
  );
};
