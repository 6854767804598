import React from 'react';
import { useState } from 'react';

import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { BodyText } from '@/components/designSystem/Typography';

import { FlexRow } from '../designSystem/Layout';
import { StyledCheckboxWrapper } from './styled';

interface IKeys {
  enabled: string,
  required: string,
}

export interface IHandleEnableRequiredOptionsChangeArgs {
  id?: string,
  key: string,
  enabled: boolean,
  required: boolean,
}

interface IProps {
  id?: string,
  keys: IKeys,
  label?: string,
  enabled: boolean,
  canSeeEditControl?: boolean | true
  required: boolean,
  handleChange: (args: any) => void,
}

interface IEnableOptionProps {
  id?: string,
  label?: string,
  enabled: boolean,
  canSeeEditControl?: boolean | true
  handleChange: (args: any) => void,
}

export const EnableOptions = ({
  label,
  enabled,
  handleChange,
  canSeeEditControl = true
}: IEnableOptionProps) => {
  return (
  <>
    {label ? <div>{label}</div> : false}
    <FlexRow>
      <StyledCheckboxWrapper>
        <Checkbox
          disabled={!canSeeEditControl}
          checked={enabled}
          onChange={handleChange}
        >
          <BodyText>Enable</BodyText>
        </Checkbox>
      </StyledCheckboxWrapper>
    </FlexRow>
  </>
  )
}

export const EnableRequiredOptions = ({
  id,
  keys,
  label,
  required,
  enabled,
  handleChange,
  canSeeEditControl = true
}: IProps) => {
  const [state, setState] = useState({enabled: enabled, required: required});

  return (
    <>
      {label ? <div>{label}</div> : false}
      <FlexRow>
        <StyledCheckboxWrapper>
          <Checkbox
            disabled={!canSeeEditControl}
            checked={state.enabled}
            onChange={() => {
              let newState = state;
              if(state.enabled == true && state.required == true) {
                newState = {...state, required: false, enabled: false} 
                setState(newState)
              } else {
                newState = {...state, enabled: !state.enabled}
                setState(newState)
              }
              handleChange({[keys.enabled]: newState.enabled, [keys.required]: newState.required});
            }}
          >
            <BodyText>Enable</BodyText>
          </Checkbox>
        </StyledCheckboxWrapper>
        <StyledCheckboxWrapper>
          <Checkbox
            disabled={!enabled && !canSeeEditControl}
            checked={state.required}
            onChange={() => {
              let newState = state;
              if(state.enabled == false && state.required == false) {
                newState = {...state, required: true, enabled: true}
                setState(newState);
              } else {
                newState = {...state, required: !state.required}
                setState(newState);
              }
              handleChange({[keys.enabled]: newState.enabled, [keys.required]: newState.required});
            }}
          >
            <BodyText>Required</BodyText>
          </Checkbox>
        </StyledCheckboxWrapper>
      </FlexRow>
    </>
  )
};
