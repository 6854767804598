import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { get } from 'lodash/fp';

import { IReferralData } from '@/types/referralData';

import { EmailVerification } from '@/views/onboard/CreditApplicantOnboard/EmailVerification';

import { referenceInitialValues } from './steps/ReferenceStep/initialValues';
import { completeInitialValues } from './steps/CompleteStep/initialValues';
import { StepWrapper } from './StepWrapper';
import { useTheme } from './useTheme';
import { VendorOnboardValuesContext } from './context';
import { StyledContainer, StyledCSLogoSM, StyledIndicatorSM, StyledRewardBoxContainer, StyledRewardBoxitem, StyledRewardCaption } from './styled';
import { DEFAULT_STEP } from './constants';
import { onboardValuesReducer } from './reducer';
import { ReferenceStep } from './steps/ReferenceStep';
import { CompleteStep } from './steps/CompleteStep';
import { IVRCustomField } from '@/types/vrCustomField';
import { VrCompletedModal } from '@/modals/VrCompletedModal';
import { UserOptOutModal } from '@/modals/UserOptOutModal'
import { OPT_OUT_USER } from '@/graphql/mutations/optOutUser';
import { useMutation } from '@apollo/react-hooks';

const getInitialOnboardValues = (
  basePath: string,
  referralCode: string,
  refData: IReferralData,
  referenceStepCustomFields: IVRCustomField[],
  completeStepCustomFields: IVRCustomField[],
) => ({
  reference: referenceInitialValues(referenceStepCustomFields),
  complete: completeInitialValues(refData, completeStepCustomFields),

  basePath,
  referralCode,
})

interface IProps {
  refData: IReferralData,
  basePath: string,
  referralCode: string,
  optOut: string | null,
}

export const VendorOnboardContainer = ({ refData, basePath, referralCode, optOut }: IProps) => {
  const { search } = useLocation();

  const startPath = `${basePath}/${DEFAULT_STEP}`

  const vrCompleted = get('getReferralData.complete', refData);

  const CPCompany = get('getReferralData.forCompany', refData);
  const CPCompanyName = get('name', CPCompany);
  const CACompanyName = get('getReferralData.fromCompany.name', refData);

  const vrRewardReportURL = get('getReferralData.reward_report.signedURL', refData);

  const enableVrCreditSafeIncentive = get('settings.enableVrCreditSafeIncentive', CPCompany);

  const currentUser = get('currentUser', refData);
  const currentCompany = get('currentCompany', refData);

  const vrCustomFields = get('vrCustomFields', CPCompany).filter((field: IVRCustomField) => field.enabled);
  const referenceStepCustomFields = vrCustomFields.filter((field: IVRCustomField) => field.step === 1);
  const completeStepCustomFields = vrCustomFields.filter((field: IVRCustomField) => field.step === 2);

  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [showOptOutModal, setShowOptOutModal] = useState(false);

  const [optOutUser] = useMutation(OPT_OUT_USER, {
    variables: { referral_code: referralCode },
  });

  useEffect(() => {
    if (vrCompleted) {
      setShowCompletedModal(true);
    }
  }, [currentUser, currentCompany]);

  useEffect(() => {
    if (optOut) {
      optOutUser().then((res) => {
        console.log(res)
      });

      setShowOptOutModal(true);
    }
  }, [currentUser, currentCompany, optOutUser]);

  // Theme

  const { overriddenTheme, logoUrl, brand } = useTheme(CPCompany);

  const overriddenLogo = logoUrl
    ? <img src={logoUrl} alt='logo' />
    : null;

  //

  const [onboardValues, dispatch] = useReducer(
    onboardValuesReducer,
    getInitialOnboardValues(
      basePath,
      referralCode,
      refData,
      referenceStepCustomFields,
      completeStepCustomFields,
    )
  );

  const steps = [
    {
      path: 'reference',
      Component: ReferenceStep,
      stepProps: {
        CACompanyName,
        customFields: referenceStepCustomFields
      }
    },
    {
      path: 'complete',
      Component: CompleteStep,
      stepProps: {
        customFields: completeStepCustomFields,
        setShowCompletedModal: setShowCompletedModal,
      }
    },
  ];

  return (
    <ThemeProvider theme={overriddenTheme}>
      <StyledContainer>
        <Switch>
          <VendorOnboardValuesContext.Provider value={
            onboardValues
          }>
            {steps.map(({
              path, Component, stepProps
              // Fix any
            } : any, index) => (
              <Route
                key={path}
                path={`${basePath}/${path}`}
                render={() =>
                  <StepWrapper
                    prevPath={index ? `${basePath}/${steps[index - 1].path}` : null}
                    index={index}
                    overriddenLogo={overriddenLogo}
                    brand={brand}
                  >
                    <Component dispatch={dispatch} {...stepProps} />
                    {enableVrCreditSafeIncentive && (
                      <StyledRewardBoxContainer>
                        <StyledRewardBoxitem>
                          <StyledIndicatorSM />
                          <StyledRewardCaption>
                            Complete the vendor reference and get a free commercial credit report from
                          </StyledRewardCaption>
                          <StyledCSLogoSM />
                        </StyledRewardBoxitem>
                      </StyledRewardBoxContainer>
                    )}
                    <VrCompletedModal
                      isOpen={showCompletedModal}
                      setIsOpen={setShowCompletedModal}
                      CPCompanyName={CPCompanyName}
                      CACompanyName={CACompanyName}
                      referralCode={referralCode}
                      vrRewardReportURL={vrRewardReportURL}
                      enableVrCreditSafeIncentive={enableVrCreditSafeIncentive}
                    />
                    <UserOptOutModal
                      isOpen={showOptOutModal}
                      setIsOpen={setShowOptOutModal}
                    />
                  </StepWrapper>
                }
              />
            ))}
            <Route path={`${basePath}/emailverification`} component={EmailVerification} />
            <Redirect from={basePath} to={`${startPath}${search}`} />
          </VendorOnboardValuesContext.Provider>
        </Switch>
      </StyledContainer>
    </ThemeProvider>
  )
}