import React, { useState } from 'react';

import { ICustomer } from '@/types/customer';

import { Button } from '@/components/designSystem/buttons';
import { InviteVendorModal } from '@/modals/InviteVendorModal';

import { ReactComponent as Plus } from '@/assets/plus.svg';

interface IAddVendorReferenceButtonProps {
    customer: ICustomer,
    refetch: () => void,
  }

export const AddVendorReferenceButton = ({ customer, refetch }: IAddVendorReferenceButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} LeftIcon={Plus} primary>Add Vendor Reference</Button>
      <InviteVendorModal isOpen={isOpen} setIsOpen={setIsOpen} customer={customer} onSuccess={refetch} />
    </>
  );
};