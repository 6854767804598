import React from 'react';
import styled from 'styled-components';

import { useRedirect } from '@/providers/Redirect/context';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { BodyText } from '@/components/designSystem/Typography';
import { FlexColumnItemsCenter } from '../components/designSystem/Layout';
import { deleteFromStorage } from '@rehooks/local-storage';

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const Container = styled(FlexColumnItemsCenter)`
  justify-content: center;
  text-align: center;
  padding: 2rem;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

interface IProps {
  isOpen: boolean,
  companyId: string,
  customerId: string,
}

export const ApplicationContinueModal = ({ isOpen, companyId, customerId }: IProps) => {
  const { toLogin, toSeekerSignup } = useRedirect();

  const startNewApplication = () => {
    deleteFromStorage('token');
    deleteFromStorage('company-id');
    deleteFromStorage('customer-id');
    toSeekerSignup({ companyId }, '')
  }

  return (
    <StyledModal
      title='Continue Your Application'
      isOpen={isOpen}
      blockClose
    >
      <Container>
        <BodyText>It looks like this application has already been started!</BodyText>
        <BodyText>To continue please login with the original email and password you provided.</BodyText>
        <StyledButton
          wide
          primary
          onClick={() => toLogin(`/signup/seeker/${companyId}/?cid=${customerId}`)}
        >
          Login
        </StyledButton>
        <StyledButton
          wide
          secondary
          onClick={startNewApplication}
        >
          Start New Application
        </StyledButton>
      </Container>
    </StyledModal>
  );
};
