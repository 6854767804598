import React from 'react';
import { ModalProvider, BaseModalBackground } from 'styled-react-modal';
import styled from 'styled-components';

interface IFadingBackgroundProps {
    opacity: string,
}

const FadingBackground = styled(BaseModalBackground)<IFadingBackgroundProps>`
  opacity: ${({ opacity }) => opacity};
  transition: opacity ease 200ms;
`;

interface IProps {
    children: React.ReactNode,
}

export const CustomModalProvider = ({ children }: IProps) => (
  <ModalProvider backgroundComponent={FadingBackground}>
    {children}
  </ModalProvider>
);
