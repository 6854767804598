import gql from 'graphql-tag';

export const GENERATE_CREDIT_SAFE_REPORT_FOR_REWARD = gql`
  mutation GenerateCreditSafeReportForReward($creditSafeId: String!, $reportDescription: String!, $referralCode: String!) {
    generateCreditSafeReportForReward(creditSafeId: $creditSafeId, reportDescription: $reportDescription, referralCode: $referralCode) {
      id
      originalFilename
      description
      signedUrl
    }
  }
`;