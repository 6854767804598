import React from 'react';

import { useFormikContext } from 'formik';

import { Button } from '@/components/designSystem/buttons';
import { BodyText, H2 } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { IAddYesNoToggleCustomFieldValues } from '.';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';
import { RadioButton } from '@/components/designSystem/Form/RadioButton';
import { YesNoToggleField } from '@/components/designSystem/Form/YesNoToggleField';
import { Flex } from '@/components/designSystem/Layout';

import {
  StyledPreviewFieldContainer,
  StyledForm,
  StyledFormInnerContainer,
  StyledLeftFormContainer
} from '../styled';
import { FooterContainer } from '../../StyledModal/styled';
import { CustomFieldPreviewContainer } from '../CustomFieldPreviewContainer';
import { AddCustomFieldBackButton } from '../BackButton';

export const AddYesNoToggleCustomFieldForm = () => {
  const { values, setFieldValue } = useFormikContext<IAddYesNoToggleCustomFieldValues>();

  return (
    <StyledForm>
      <StyledFormInnerContainer>
        <StyledLeftFormContainer>
          <AddCustomFieldBackButton />
          <TextField required type='text' name='label' label='Label' />
          <CheckboxField name='required'><BodyText>Make this field required</BodyText></CheckboxField>
          <Flex direction="row" gap="0.5rem">
            <Flex direction="column">
              <FieldLabel required>If Yes selected, show:</FieldLabel>
              <RadioButton
                name='yesToggleTypeText'
                checked={values.yesToggle.type === 'text'}
                onClick={() => setFieldValue('yesToggle.type', 'text')}
                label='Text Field'
              />
              <RadioButton
                name='yesToggleTypeFileUpload'
                checked={values.yesToggle.type === 'file_upload'}
                onClick={() => setFieldValue('yesToggle.type', 'file_upload')}
                label='File Upload Field'
              />
              <RadioButton
                name='yesToggleTypeNull'
                checked={values.yesToggle.type === null}
                onClick={() => setFieldValue('yesToggle.type', null)}
                label='None'
              />
              {values.yesToggle.type === 'file_upload' ? (
                <CheckboxField name='yesToggle.allowMultipleUploads'><BodyText>Allow multiple files</BodyText></CheckboxField>
              ) : false }
              <TextAreaField name='yesToggle.description' label='Description' />
            </Flex>

            <Flex direction="column">
              <FieldLabel required>If No selected, show:</FieldLabel>
              <RadioButton
                name='noToggleTypeText'
                checked={values.noToggle.type === 'text'}
                onClick={() => setFieldValue('noToggle.type', 'text')}
                label='Text Field'
              />
              <RadioButton
                name='noToggleTypeFileUpload'
                checked={values.noToggle.type === 'file_upload'}
                onClick={() => setFieldValue('noToggle.type', 'file_upload')}
                label='File Upload Field'
              />
              <RadioButton
                name='noToggleTypeNull'
                checked={values.noToggle.type === null}
                onClick={() => setFieldValue('noToggle.type', null)}
                label='None'
              />
              {values.noToggle.type === 'file_upload' ? (
                <CheckboxField name='noToggle.allowMultipleUploads'><BodyText>Allow multiple files</BodyText></CheckboxField>
              ) : false }
              <TextAreaField name='noToggle.description' label='Description' />
            </Flex>
          </Flex>
        </StyledLeftFormContainer>
        <CustomFieldPreviewContainer>
          <H2>Credit Applicant will see:</H2>
          <StyledPreviewFieldContainer>
            <YesNoToggleField
              name='example'
              label={values.label || 'Example Label'}
              description={values.description}
              required={values.required}
              yesToggle={values.yesToggle}
              noToggle={values.noToggle}
            />
          </StyledPreviewFieldContainer>
        </CustomFieldPreviewContainer>
      </StyledFormInnerContainer>

      <FooterContainer>
        <Button wide primary type='submit'>Add Custom Field</Button>
      </FooterContainer>
    </StyledForm>
  )
};
