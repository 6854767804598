import React from 'react';

import { H3, BodyText } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';

import { SectionContainer, SectionTitleContainer } from './styled';

interface IProps {
  children: React.ReactNode,
  title: string,
  actions?: React.ReactNode,
  description?: string,
}

export const Section = ({ children, title, description, actions }: IProps) => (
  <SectionContainer>
    <SectionTitleContainer>
      <Flex direction="row" justify="space-between">
        <Flex direction="column">
          <H3 bold>{title}</H3>
          {description && <BodyText>{description}</BodyText>}
        </Flex>
        {actions && 
          <Flex direction="column">
            {actions}
          </Flex>
        }
      </Flex>
    </SectionTitleContainer>
    {children}
  </SectionContainer>
);
