import gql from 'graphql-tag';

export const IS_EMAIL_VERIFIED = gql`
  query IsEmailVerified {
    isEmailVerified

    currentUser {
      id
      name
      email
      emailVerified
      userCompanyRole {
        isAdmin
        creditLimit
        company {
          id
        }
        role {
          name
          isCreditLimitApply
          permissions {
            inviteCustomer
            readOnly
            inviteUser
            changeTemplate
          }
        }
      }
    }

    currentCompany {
      id
      name
    }
  }
`;
