import React from 'react';

import { get } from 'lodash/fp';

import { ToggleBlock } from '@/components/ToggleBlock';
import { BodyText } from '@/components/designSystem/Typography';

import { StyledAutoFitColumnsRow, StyledTwoColumnsRow } from '../../../styled';
import { StyledAccountInfoContainer, StyledToggleBankHeader } from '../styled';
import { IAutomaticAccount } from '@/types/automaticAccount';
import { LabeledValue } from '../../../LabeledValue';
import { useAutomaticAccountOnlyFields } from '../useAutomaticAccountOnlyFields';
import { formatAccountType } from '@/utils/format';

interface IProps {
  account: IAutomaticAccount,
  institutionName: string,
}

export const AutomaticAccountOnly = ({ account, institutionName }: IProps) => {
  const name = get('name', account);

  const fields = useAutomaticAccountOnlyFields(account);

  const Header = (
    <StyledToggleBankHeader>
      <BodyText bold>{institutionName}</BodyText>
      <BodyText bold>{name}</BodyText>
        Automatic
    </StyledToggleBankHeader>
  )

  return (
    <ToggleBlock header={Header}>
      <StyledAccountInfoContainer>
        <StyledTwoColumnsRow>
          <LabeledValue label='Bank name' value={fields.institutionName || '-'} />
          <LabeledValue label='Bank address' value={fields.institutionAddress || '-'} />
        </StyledTwoColumnsRow>
        <StyledAutoFitColumnsRow>
          <LabeledValue label='Account number' value={fields.accountNumber || '-'} />
          <LabeledValue label='Routing / Transit' value={'-'} />
          <LabeledValue label='Account type' value={formatAccountType(fields.accountType) || '-'} />
          <LabeledValue label='Currency' value={fields.currency || '-'} />
          <LabeledValue label='Status' value='Verified' />
        </StyledAutoFitColumnsRow>
      </StyledAccountInfoContainer>
    </ToggleBlock>
  );
};
