import React, { useContext } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

import { useQuery } from '@apollo/react-hooks';
import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';

import { get } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { IUserCompanyRole } from '@/types/companyUser';

import { CurrentUserContext } from '@/providers/CurrentUser';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { PersonalSettings } from './PersonalSettings';
import { UsersSection } from './UsersSection';
import { PageContainer } from './styled';

const canInviteUsers = (isAdmin: boolean, inviteUser: boolean) => isAdmin || inviteUser;

export const GeneralSettings = () => {
  const [companyId] = useLocalStorage('company-id');

  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY);

  const currentUserData = useContext(CurrentUserContext);

  const parentCompanyId = get('parentCompany.id', parentCompany);
  const parentCompanyInfo = get('parentCompany', parentCompany);

  const currentUser = get('currentUser', currentUserData);
  const currentUserLoading = get('currentUserLoading', currentUserData);
  const refetchCurrentUser = get('refetchCurrentUser', currentUserData);

  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData);
  const emailNotificationsSettings = get('currentUser.emailNotificationsSettings', currentUserData);
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId), userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId))
  const isAdmin = get('isAdmin', currentUserCompanyRole[0])
  const inviteUser = get('role.permissions.inviteUser', currentUserCompanyRole[0]) || false;

  const canInviteUser: boolean = canInviteUsers(isAdmin, inviteUser);

  const currentCompanyData = useContext(CurrentCompanyContext);

  if (currentUserLoading || !currentCompanyData || currentCompanyData.currentCompanyLoading) {
    return <LoadingSpinner />;
  }

  const currentCompany = get('currentCompany', currentCompanyData);
  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);

  if (!currentUser || !refetchCurrentUser) {
    return <LoadingSpinner />
  }

  return (
    <PageContainer>
      <PersonalSettings currentUser={currentUser} refetch={refetchCurrentUser} currentUserCompanyRole={currentUserCompanyRole[0]} emailNotificationsSettings={emailNotificationsSettings}/>
      {canInviteUser && <UsersSection currentUser={currentUser} company={currentCompany} parentCompany={parentCompanyInfo} refetchCompany={refetchCurrentCompany} />}
    </PageContainer>
  );
};
