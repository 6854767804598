import styled from 'styled-components';

import { Form } from 'formik';

import { Flex } from '@/components/designSystem/Layout';

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  & > * {
    margin-bottom: 1.5rem;
  }
`;

export const StyledFieldsSectionContainer = styled(Flex).attrs({ direction: 'column' })`
  width: 100%;
`;