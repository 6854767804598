import React from 'react';

import { BackButton } from '@/components/designSystem/buttons/BackButton';

import {
  PageContainer,
  Header,
  MainColumn,
  SecondaryColumn,
  MainItemContainer,
  SecondaryItemContainer,
  SecondaryInfoContainer,
  MainItemTitle,
  SecondaryItemTitle,
  TitleWithStatus,
} from './styled';

interface IMainItemProps {
  title: string,
  className?: string,
  children: React.ReactNode,
}

const MainItem = ({ title, className, children }: IMainItemProps) => (
  <MainItemContainer className={className}>
    <MainItemTitle>{title}</MainItemTitle>
    {children}
  </MainItemContainer>
);

interface ISecondaryItemProps {
  title: JSX.Element | string,
  className?: string,
  children?: React.ReactNode,
}

const SecondaryItem = ({ title, className, children }: ISecondaryItemProps) => (
  <SecondaryItemContainer className={className}>
    <SecondaryItemTitle>{title}</SecondaryItemTitle>
    <SecondaryInfoContainer>
      {children}
    </SecondaryInfoContainer>
  </SecondaryItemContainer>
);

interface IContainerProps {
  title: string,
  backTitle: string,
  backTo: string,
  children: React.ReactNode,
  status?: string,
}

const Container = ({
  title, backTitle, backTo, children, status,
}: IContainerProps) => (
  <PageContainer>
    <Header>
      {backTo && <BackButton title={backTitle} link={backTo} />}
      <TitleWithStatus>{title} {status}</TitleWithStatus>
    </Header>
    {children}
  </PageContainer>
);

export default {
  Container,
  MainColumn,
  SecondaryColumn,

  MainItem,
  MainItemContainer,
  MainItemTitle,

  SecondaryItem,
  SecondaryItemContainer,
  SecondaryItemTitle,
  SecondaryInfoContainer,
};
