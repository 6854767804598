import { CLIENT_HIDE_DNB, CLIENT_HIDE_EXPERIAN, CLIENT_HIDE_EQUIFAX } from '@/app.config';
import React, { useContext } from 'react';

import { get } from 'lodash/fp';

import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ApiDeckSettings } from './ApiDeckSettings';
import { StyledPageContainer } from './styled';
import { ExperianSettings } from './ExperianSettings';
import { DnbSettings } from './DnbSettings';
import { EquifaxSettings } from './EquifaxSettings';
import { CreditSafeSettings } from './CreditSafeSettings';

export const IntegrationSettings = () => {
  const currentCompanyData = useContext(CurrentCompanyContext);

  if (!currentCompanyData) {
    return <LoadingSpinner />;
  }

  const currentCompany = get('currentCompany', currentCompanyData);
  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);

  return (
    <StyledPageContainer>
      <ApiDeckSettings companyId={currentCompany.id} />
      <CreditSafeSettings currentCompany={currentCompany} refetch={refetchCurrentCompany} />
      { (CLIENT_HIDE_DNB !== 'true') ? <DnbSettings currentCompany={currentCompany} refetch={refetchCurrentCompany} /> : null}
      { (CLIENT_HIDE_EXPERIAN !== 'true') ? <ExperianSettings currentCompany={currentCompany} refetch={refetchCurrentCompany} /> : null}
      { (CLIENT_HIDE_EQUIFAX !== 'true') ? <EquifaxSettings currentCompany={currentCompany} refetch={refetchCurrentCompany} /> : null}
    </StyledPageContainer>
  );
};
