import React, { useState } from 'react';
import { FormikValues } from 'formik';
import {
  useLocation, Route, Redirect, Switch,
} from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import { get, findIndex, noop } from 'lodash/fp';

import { IStep } from '@/types/step';

import { EmailVerifiedRoute } from '@/routes/Routes';

import { StepContainer } from '@/containers/StepContainer';
import { OnboardLogo } from '@/components/Onboard/OnboardLogo';
import { StepCounter } from '@/components/Onboard/StepCounter';
import { StyledOnboardHeaderWrapper, StyledOnboardHeader } from '@/components/Onboard/styled';

import { OnboardTime } from '@/components/Onboard/OnboardTime';
import { ApplicationContainer, Main, StyledStepCounterContainer } from './styled';
import { ONBOARDING_PATH } from '@/constants';

interface IProps {
  steps: IStep[],
  basePath: string,
  fields: FormikValues,
  onSubmit: (formValues: FormikValues) => void,
  loading?: boolean,
  logoOverride?: JSX.Element | null,
  customBrand?: string,
  startPath?: string,
  persistFormId?: string,
  onFormChange?: (values: FormikValues, steps: IStep[]) => void,
  customerId?: string,
}

// Only SeekerSignature flow and UserOnboard use this container. Need to refator.

export const OnboardContainer = ({
  steps,
  basePath,
  fields,
  onSubmit,
  loading,
  logoOverride,
  customBrand,
  startPath,
  persistFormId = 'onboard',
  onFormChange = noop,
  customerId,
}: IProps) => {
  const [disableNext, setDisableNext] = useState(false);

  const activeSteps = steps.filter(Boolean);
  const startStepPath = startPath || get('[0].path', activeSteps);

  const { pathname, search } = useLocation();
  const currentStepIndex = findIndex({ path: pathname }, activeSteps);
  const activeStepIndex = currentStepIndex || 0;

  const prevPath = get(`[${activeStepIndex - 1}].path`, activeSteps);
  const nextPath = get(`[${activeStepIndex + 1}].path`, activeSteps);
  const validation = get(`[${activeStepIndex}].validation`, activeSteps);
  const skippable = get(`[${activeStepIndex}].skippable`, activeSteps);

  const onNext = get(`[${activeStepIndex}].onNext`, activeSteps);
  const onBack = get(`[${activeStepIndex}].onBack`, activeSteps);

  const stepContainerProps = {
    prevPath,
    nextPath,
    onNext,
    onSubmit,
    onBack,
    disableNext,
    setDisableNext,
    validation,
    skippable,
    fields,
    loading,
    persistFormId,
    customBrand,
    steps,
    onFormChange,
  };

  const renderOnboardTime = () => {
    if (basePath.includes(ONBOARDING_PATH.VENDOR)) {
      return <OnboardTime minutes={2} />
    }
    if (basePath.includes(ONBOARDING_PATH.APPLICANT)) {
      return <OnboardTime minutes={5} />
    }
    return false;
  }

  return (
    <ApplicationContainer>
      <StyledOnboardHeaderWrapper>
        <StyledOnboardHeader>
          <OnboardLogo overriddenLogo={logoOverride} />
          <StyledStepCounterContainer>
            <StepCounter steps={activeSteps.map((step) => step.title)} activeStep={activeStepIndex} />
          </StyledStepCounterContainer>
          <OnboardLogo overriddenLogo={logoOverride} opacity={0} />
        </StyledOnboardHeader>
        {renderOnboardTime()}
      </StyledOnboardHeaderWrapper>
      <Main>
        <ScrollToTop>
          <StepContainer {...stepContainerProps}>
            <Switch>
              {
                activeSteps.map(({
                  path, Component, stepProps = {}, checkEmailVerified = false, memo = false, memoDeps,
                }) => {
                // TODO Fix any
                  const RouteComponent: any = checkEmailVerified
                    ? EmailVerifiedRoute
                    : Route;

                  const AnyComponent = Component as any

                  if (memo) {
                    const deps = memoDeps ? [...memoDeps] : null
                    const RouteComponentMemo = React.useMemo( () => <RouteComponent
                      key={path}
                      path={path}
                      setRouteBlocked={setDisableNext}
                      customerId={customerId}
                      component={(props: any) => <AnyComponent {...props} {...stepProps} stepContainerProps={stepContainerProps} />}
                    />, deps || [] );

                    return RouteComponentMemo;
                  }

                  return (
                    <RouteComponent
                      key={path}
                      path={path}
                      setRouteBlocked={setDisableNext}
                      customerId={customerId}
                      component={(props: any) => <AnyComponent {...props} {...stepProps} stepContainerProps={stepContainerProps} />}
                    />
                  );
                })
              }
              <Redirect from={basePath} to={{ pathname: startStepPath, search }} />
            </Switch>
          </StepContainer>
        </ScrollToTop>
      </Main>
    </ApplicationContainer>
  );
};
