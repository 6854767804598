import styled from 'styled-components';

import { SelectField } from '@/components/designSystem/Form/SelectField';
import { Field } from '@/components/designSystem/Form/Field';
import { BodyText } from '@/components/designSystem/Typography';
import { FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

export const CheckboxBody = styled(BodyText)`
  margin-bottom: 15px;
`;

export const StyledFieldWithoutMargin = styled(Field)`
  margin-bottom: 0px;
`;

export const StyledSelectField = styled(SelectField)`
  padding-bottom: 0px;
  height: 75px;
  margin-bottom: 5px;
`;

export const ToggleSelectField = styled(SelectField)`
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 5px;
  height: 60px;
`;

export const StyledCustomFieldContainer = styled.div`
  border-width: 0.02em;
  border-style: solid;
  border-color: #ECECEC;
  border-radius: 4px;
  font-size: 15px;
  margin-top: 10px;
`;

export const StyledContactTypeFormContainer = styled.div`
  margin-bottom: 5px;
`;

interface IStyledCustomFieldRow {
  first?: boolean;
}

export const StyledCustomFieldRow = styled(FlexRowSpaceBetweenItemsCenter)<IStyledCustomFieldRow>`
  border-top: ${({ first }) => first ? 'none' : 'solid 0.02em #ECECEC'};
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  width: 100%;
`

export const StyledCustomFieldCheckboxes = styled.div`
  /* flex-shrink: 1; */
`

export const BodyShow = styled(BodyText)`
  margin-right: 15px;
  font-size: 14px;
  margin-left: 0px;
`;

export const BodyRequired = styled(BodyText)`
  font-size: 14px;
`;

export const SelectedToggle = styled.div`
  border: solid 0.02em #ECECEC;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 10px;
  height: 150px;
  flex: 1;
`;

export const NotSelectedToggle = styled.div`
  border: solid 0.02em #ECECEC;
  flex: 1;
  margin-bottom: 5px;
  padding: 10px;
  height: 150px;
`;

export const StyledOptionFieldContainer = styled(FlexRowSpaceBetweenItemsCenter)`
  gap: 1rem;
  cursor: pointer;
`;

// Update Custom Field Modal

export const StyledTypeTitle = styled.div`
  color: #161616;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
`;

export const StyledAsterisk = styled.span`
  color: #E95433;
  font-weight: normal;
  font-size: 15px;
  line-height: 32px;
`;

export const StyledTypeDescription = styled(BodyText)`
  font-size: 12px;
  margin-bottom: 20px;
`;