import React from 'react';
import styled from 'styled-components';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { BodyText } from '@/components/designSystem/Typography';
import { FlexColumnItemsCenter } from '../components/designSystem/Layout';

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const Container = styled(FlexColumnItemsCenter)`
  justify-content: center;
  text-align: center;
  padding: 2rem;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

interface IProps {
  isOpen: boolean,
  onClick: () => void,
}

export const LogoutCreditProviderModal = ({ isOpen, onClick }: IProps) => (
  <StyledModal
    title='Do you want to logout as a Credit Provider?'
    isOpen={isOpen}
    blockClose
  >
    <Container>
      <BodyText>
        We detected that you are logged in as a Credit Provider.
      </BodyText>
      <BodyText>
        In order to complete, test or view the full application as Credit Applicant, you will need to log out as a Credit Provider.
      </BodyText>
      <StyledButton
        wide
        warning
        onClick={onClick}
      >
        Logout to Continue as Credit Applicant
      </StyledButton>
    </Container>
  </StyledModal>
);
