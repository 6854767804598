import React from 'react';

import { Formik, Form } from 'formik';

import { noop } from 'lodash';

import { IStepContainerProps } from '@/types/stepContainer';

import { ValidateOnChange, showErrors } from '@/components/designSystem/Form/functions';
import { BackButton } from '@/components/designSystem/buttons/BackButton';

import { ReactComponent as PoweredBy } from '@/assets/powered_by.svg';

import { NextButton } from './NextButton';
import {
  Content,
  LeftSide,
  RightSide,
  PoweredByContainer,
  StepFooterContainer,
} from './styled';

export const StepContainer = (stepContainerProps: IStepContainerProps) => {
  const {
    children,
    prevPath,
    nextPath,
    onNext,
    onSubmit,
    disableNext,
    validation,
    fields,
    steps,
    customBrand,
    loading = false,
    onFormChange = noop,
  } = stepContainerProps;

  return (
    <>
      <LeftSide>
        {prevPath && <BackButton link={prevPath} />}
        {!!customBrand && (
          <PoweredByContainer href='https://nectarinecredit.com' target='_blank'>
            <PoweredBy />
          </PoweredByContainer>
        )}
      </LeftSide>
      <Content>
        <Formik
          // enableReinitialize
          validateOnChange
          initialValues={fields}
          validationSchema={validation}
          onSubmit={onSubmit}
        >
          {(formProps) => {
            const {
              isValid, errors, setFieldTouched, values,
            } = formProps;
            if (errors) {
              console.log('Current Form Errors', errors);
            }
            onFormChange(values, steps);

            const nextDisabled = !isValid || disableNext;
            return (
              <Form>
                {children}
                <StepFooterContainer>
                  <NextButton
                    loading={loading}
                    disabled={nextDisabled}
                    onNext={onNext}
                    onSubmit={onSubmit}
                    formProps={formProps}
                    stepContainerProps={stepContainerProps}
                    path={nextPath}
                    onDisabledClick={() => {
                      showErrors({ errors, setFieldTouched });
                    }}
                  >
                    Next
                  </NextButton>
                </StepFooterContainer>
                <ValidateOnChange propToWatch={[validation]} />
              </Form>
            );
          }}
        </Formik>
      </Content>
      <RightSide />
    </>
  );
};
