import gql from 'graphql-tag';

export const UPDATE_EMAIL_NOTIFICATION_SETTINGS = gql`
  mutation UpdateEmailNotificationsSettings(
    $applicationSubmitted: Boolean,
    $vendorReferenceSubmitted: Boolean,
    $applicationApprovalOrDenial: Boolean,
    $applicationChangesRequested: Boolean
    ) {
    updateEmailNotificationsSettings(
      applicationSubmitted: $applicationSubmitted,
      vendorReferenceSubmitted: $vendorReferenceSubmitted,
      applicationApprovalOrDenial: $applicationApprovalOrDenial,
      applicationChangesRequested: $applicationChangesRequested
    ) {
      id
    }
  }
`;
