import React, { useRef } from 'react';
import DataGrid from "devextreme-react/data-grid";

import { useQuery } from '@apollo/react-hooks';
import { GET_CREDIT_CUSTOMER_OF } from '@/graphql/queries/getCreditCustomerOf';

import { get, getOr } from 'lodash/fp';

import { useRedirect } from '@/providers/Redirect/context';

import { BodyText, H1 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ExportApplications } from './ExportApplications';
import { useApplicationsData } from './useApplicationsData';
import { COLUMNS } from './constants';
import {
  StyledButtonsContainer,
  StyledContainer,
  StyledCustomersInfo,
  StyledTableInfo,
  StyledTitleWrapper
} from './styled';
import { DashboardGrid } from '@/components/DashboardGrid';
import { CREDIT_APPLICANT_STATUSES } from '@/constants';

export const ApplicantDashboard = () => {
  const { loading, data: getCreditCustomerOfData } = useQuery(GET_CREDIT_CUSTOMER_OF);

  const dataGridRef = useRef<DataGrid<unknown, unknown>>(null);

  const applications = getOr([], 'currentCompany.customerOf', getCreditCustomerOfData);
  const totalApplications = applications.length;
  const applicationsInProgress = applications.filter((c: any) => get('latestApplication.status', c) === CREDIT_APPLICANT_STATUSES.IN_PROGRESS).length;
  const pendingApplications = applications.filter((c: any) => get('latestApplication.status', c) === CREDIT_APPLICANT_STATUSES.PENDING_APPROVAL).length;

  const { toApplicationPage } = useRedirect();

  const applicationsData = useApplicationsData(applications);

  const onRowClick = ({ data }: any) => toApplicationPage(data.id);

  return (
    <StyledContainer>
      <StyledTitleWrapper>
        <H1 bold>My Applications</H1>
      </StyledTitleWrapper>
      <StyledTableInfo>
        {!loading && (
          <StyledCustomersInfo>
            <div>
              <BodyText bold>{totalApplications}</BodyText> <BodyText color='secondary'>Applications</BodyText>
            </div>
            <div>
              <BodyText bold>{applicationsInProgress}</BodyText> <BodyText color='secondary'>In progress</BodyText>
            </div>
            <div>
              <BodyText bold>{pendingApplications}</BodyText> <BodyText color='secondary'>Pending approval</BodyText>
            </div>
          </StyledCustomersInfo>
        )}
      </StyledTableInfo>
      { loading ? (
        <LoadingSpinner />
      ) : (
        <DashboardGrid
          dataSource={applicationsData}
          columns={COLUMNS}
          onRowClick={onRowClick}
          dataGridRef={dataGridRef}
        />
      )}
      <StyledButtonsContainer>
        <ExportApplications gridRef={dataGridRef} />
      </StyledButtonsContainer>
    </StyledContainer>
  )
};
