import { get } from 'lodash/fp';

import { IAutomaticAccount } from '@/types/automaticAccount';

export const useAutomaticAccountOnlyFields = (account: IAutomaticAccount) => {
  const institutionName = get('institution.name', account);
  const institutionAddress = get('institution.address.country', account);
  const accountNumber = get('accountDisplayNumber', account);
  const currency = get('currency', account);
  const accountType = get('type', account);

  return {
    institutionName,
    institutionAddress,
    accountNumber,
    currency,
    accountType,
  };
}
