import React from 'react';

import { StepNodeContainer, StepBadge } from './styled';

interface IProps {
  index: number,
  isActive?: boolean,
  isComplete?: boolean,
  color: string,
}

export const StepNode = ({
  index, isActive, isComplete, color,
}: IProps) => {
  const badgeIcon = isComplete ? '✔' : (index + 1);

  return (
    <StepNodeContainer isActive={isActive} complete={isComplete} color={color}>
      <StepBadge bold isActive={isActive} complete={isComplete} color={color}>
        {badgeIcon}
      </StepBadge>
    </StepNodeContainer>
  );
};
