import styled, { css, DefaultTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { get } from 'lodash/fp';

interface IProps {
  bold?: boolean,
  small?: boolean,
  color?: string,
  theme: DefaultTheme,
}

const textColor = ({ color = 'primary', theme }: IProps) => get(`color.text.${color}`, theme);

const textWeight = ({ bold }: IProps) => (bold ? 'bold' : 'normal');

const Text = styled.span<IProps>`
  color: ${textColor};
  font-weight: ${textWeight};
`;

export const H1 = styled(Text)`
  font-size: 32px;
  line-height: 48px;
`;

export const H2 = styled(Text)`
  font-size: 24px;
  line-height: 32px;
`;

export const H3 = styled(Text)<IProps>`
  font-size: 18px;
  line-height: 24px;
`;

export const H4 = styled(Text)<IProps>`
  font-size: 15px;
  line-height: 32px;
`;

export const SubHeading = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const BodyText = styled(Text)`
  font-size: 15px;
  line-height: 24px;
`;

export const Caption = styled(Text)`
  font-size: 13px;
  line-height: 16px;
`;

export const Small = styled(Text)`
  font-size: 12px;
  line-height: 14px;
`;

export const Tiny = styled(Text)`
  font-size: 8px;
  line-height: 12px;
`;

const linkColor = ({ color = 'primary', theme }: IProps) => get(`color.${color}`, theme);

interface IProps {
  bold?: boolean,
}

const linkStyle = css<IProps>`
  color: ${linkColor};
  font-weight: ${textWeight};
  text-decoration: none;
  cursor: pointer;
`;

export const StyledLinkButton = styled.span<IProps>`${linkStyle}`;
export const StyledExternalLink = styled.a<IProps>`${linkStyle}`;
export const StyledLink = styled(NavLink)<IProps>`
  ${linkStyle};

  &.bold-link {
    font-weight: bold;
  }
`;

export const StyledLinkButtonUnderline = styled(StyledLinkButton)`
  text-decoration: underline;
`;

export const StyledExternalLinkUnderline = styled(StyledExternalLink)`
  text-decoration: underline;
`;
