import styled from 'styled-components';

export const FinicityContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0 0 1rem 1rem;

  & > iframe {
    height: 100%;
    width: 100%;
    position: relative !important;
    border-radius: 0 0 1rem 1rem;
  }
`;
