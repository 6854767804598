import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { FORGOT_PASSWORD } from '@/graphql/mutations/forgotPassword';

import { Formik } from 'formik';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { forgotPasswordInitialValues } from './initialValues';
import { forgotPasswordValidation } from './validation';
import { ForgotPasswordForm } from './form';
import { StyledContainer } from '../styled';
import { IForgotPasswordValues } from './types';

export const ForgotPasswordPage = () => {
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

  const handleSubmit = async ({ email }: IForgotPasswordValues) => {
    forgotPassword({ variables: { email } })
      .then(() => {
        showToast({
          title: 'Password Reset Email Sent',
          description: 'Check your inbox to finish changing your email!',
          type: toast.TYPE.SUCCESS,
        });
      }).catch((error) => {
        console.error('Password Reset Error', { error });
        showToast({
          title: 'Oh No! Something Went Wrong!',
          description: 'We couldn\'t find that email in our system.',
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <StyledContainer>
      <Formik
        onSubmit={handleSubmit}
        initialValues={forgotPasswordInitialValues}
        validationSchema={forgotPasswordValidation}
        validateOnChange
      >
        <ForgotPasswordForm isLoading={loading} />
      </Formik>
    </StyledContainer>
  );
};
