import React, { useState, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import useOnClickOutside from 'use-onclickoutside';

import { debounce } from 'lodash/fp';

import { StyledColorPickerContainer, StyledColorButton, StyledColorPickerOverlapContainer } from '../styled';

interface IProps {
  value: string,
  onChange: (value: string) => void,
}

export const ColorPicker = ({ value, onChange }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const debouncedSetOpen = debounce(100, setIsOpen);
  const close = () => debouncedSetOpen(false);
  const toggle = () => debouncedSetOpen(!isOpen);

  const colorPickerRef = useRef(null);
  useOnClickOutside(colorPickerRef, close);

  return (
    <StyledColorPickerContainer>
      <StyledColorButton type='button' color={value} onClick={toggle} />
      {isOpen && (
        <StyledColorPickerOverlapContainer ref={colorPickerRef}>
          <HexColorPicker
            color={value}
            onChange={onChange}
          />
        </StyledColorPickerOverlapContainer>
      )}
    </StyledColorPickerContainer>
  );
};
