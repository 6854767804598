import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';

import { getOr } from 'lodash/fp';

import { ISettings } from '@/types/settings';

import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { Row } from '@/components/designSystem/Form/styled';
import { H4 } from '@/components/designSystem/Typography';

import { Section } from './Section';
import { StyledCheckboxWrapper } from './styled';

interface IProps {
  initialSettings: ISettings,
  refetch: () => void,
}

export const OtherInfoSection = ({ initialSettings, refetch }: IProps) => {
  const requireEinFederalTaxNumber = getOr(false, 'requireEinFederalTaxNumber', initialSettings);

  const variables = {
    settings: {
      require_ein_federal_tax_number: !requireEinFederalTaxNumber,
    },
  };

  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);
  const onClick = () => updateCompanySettings({ variables }).then(refetch);

  return (
    <Section
      title='EIN / Federal Tax Number'
      description='Make EIN / Federal Tax Number mandatory or optional.'
    >
      <Row>
        <StyledCheckboxWrapper>
          <Checkbox
            checked={requireEinFederalTaxNumber}
            onChange={() => onClick()}
          >
            <H4 bold>Make required</H4>
          </Checkbox>
        </StyledCheckboxWrapper>
      </Row>
    </Section>
  );
};