import React, { useState } from 'react';

import { ConvertToManualCustomerModal } from '@/modals/ConvertToManualCustomerModal';
import { Button } from '@/components/designSystem/buttons';

import { StyledButtonWrapper } from './styled';

interface IProps {
  customerId: string,
  refetch: () => void,
}

export const ConvertToManual = ({ customerId, refetch}: IProps) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledButtonWrapper>
      <Button onClick={() => setIsOpen(true)} wide secondary>Convert To Manual Customer</Button>
      <ConvertToManualCustomerModal isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} customerId={customerId}/>
    </StyledButtonWrapper>
  );
};
