import React, { Dispatch, SetStateAction } from 'react';

import { EditButton } from '@/components/designSystem/buttons/EditButton';

import { Card } from './Card';
import { StyledEditableCardContainer, StyledEditableCardInnerContainer } from './styled';

interface IProps {
  children: React.ReactNode,
  isEditActive: boolean,
  setEditActive: Dispatch<SetStateAction<boolean>>,
}

export const EditableCard = ({ children, isEditActive, setEditActive }: IProps) => (
  <StyledEditableCardContainer>
    {isEditActive ? false : (
      <EditButton onClick={() => setEditActive((prev) => !prev)} />
    )}
    <StyledEditableCardInnerContainer>
      <Card>
        { children }
      </Card>
    </StyledEditableCardInnerContainer>
  </StyledEditableCardContainer>
);
