import React, { Dispatch, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Formik } from 'formik';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { OnboardValuesContext } from '../../context';
import { basicInfoStepValidation } from './validation';
import { BasicInfoStepForm } from './form';
import { IOnboardValuesAction } from '../../types';
import { UPDATE_BASIC_INFO } from '../../reducer';
import { IBasicInfoValues } from './types';

export interface IBasicInfoStepProps {
  dispatch: Dispatch<IOnboardValuesAction>,
}

export const BasicInfoStep = ({ dispatch }: IBasicInfoStepProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const onboardValues = useContext(OnboardValuesContext);

  const dispatchValues = (values: IBasicInfoValues) => {
    if (!onboardValues) {
      return;
    }

    dispatch({ type: UPDATE_BASIC_INFO, payload: values })
    history.push({ pathname: `${onboardValues.basePath}/companyProfile`, search });
  }

  if (!onboardValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={onboardValues.basicInfo}
      validationSchema={basicInfoStepValidation}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <BasicInfoStepForm />
    </Formik>
  )
};
