import React, { Dispatch, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Formik } from 'formik';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ISettings } from '@/types/settings';
import { ICustomField } from '@/types/customField';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { OnboardValuesContext } from '../../context';
import { companyProfileStepValidation } from './validation';
import { UPDATE_COMPANY_PROFILE } from '../../reducer';
import { IOnboardValuesAction } from '../../types';
import { CompanyProfileStepForm } from './form';
import { ICompanyProfileValues } from './types';

export interface ICompanyProfileStepProps {
  dispatch: Dispatch<IOnboardValuesAction>,
}

export const CompanyProfileStep = ({ dispatch }: ICompanyProfileStepProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const onboardValues = useContext(OnboardValuesContext);

  const dispatchValues = (values: ICompanyProfileValues) => {
    if (!onboardValues) {
      return;
    }

    dispatch({ type: UPDATE_COMPANY_PROFILE, payload: values })
    history.push({ pathname: `${onboardValues.basePath}/companycontacts`, search });
  }

  if (!onboardValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={onboardValues.companyProfile}
      validationSchema={companyProfileStepValidation}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <CompanyProfileStepForm />
    </Formik>
  )};
