// ChangesRequested/useNoBankInfo

import { getOr } from 'lodash/fp';

import { initialManualBankFields } from '../../initialValues';
import { IFinancialHealthValues, IManualBankValues } from '../../types';

interface IArguments {
  values: IFinancialHealthValues,
  setFieldValue: (field: string, value: IManualBankValues[] | boolean) => void,
}

export const useNoBankInfo = ({ values, setFieldValue }: IArguments) => {
  const noBankInfo = getOr(false, 'noBankInfo', values);

  const noBankInfoClickHandle = () => {
    if (noBankInfo) {
      setFieldValue('noBankInfo', false);
    } else {
      setFieldValue('noBankInfo', true);
      setFieldValue('manualBanks', [initialManualBankFields(false)]);
    }
  };

  return {
    noBankInfo,
    noBankInfoClickHandle
  };
};
