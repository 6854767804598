import React from 'react';

import { StyledCalloutContainer, StyledCalloutContent, StyledIconContainer } from './styled';

interface IProps {
  children: React.ReactNode,
  color?: string,
  Icon?: React.ReactNode,
}

export const Callout = ({
  children, color = 'label.warning', Icon,
}: IProps) => (
  <StyledCalloutContainer color={color}>
    {Icon && <StyledIconContainer>{Icon}</StyledIconContainer>}
    <StyledCalloutContent className='calloutContent'>
      {children}
    </StyledCalloutContent>
  </StyledCalloutContainer>
);