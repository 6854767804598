import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { UPDATE_REFERRAL } from '@/graphql/mutations/updateReferral';

import Yup from '@/utils/yup';

import { get, getOr } from 'lodash/fp';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { IReferral } from '@/types/referral';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';
import { Column } from '@/components/designSystem/Form/styled';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { StyledLink, BodyText } from '@/components/designSystem/Typography';
import { FlexColumn } from '@/components/designSystem/Layout';

const StyledCheckboxContainer = styled(FlexColumn)`
  width: 100%;
  padding-bottom: 1rem;
`;

const customerValidation = Yup.object().shape({
  customMessage: Yup.string(),
});

interface IFields {
  customMessage: string,
  autoBav: boolean,
  requirePersonalGuarantee: boolean,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
  referral: IReferral,
}

export const SendPendingInviteModal = ({
  referral, isOpen, setIsOpen, onSuccess,
}: IProps) => {
  const [updateReferral, { loading }] = useMutation(UPDATE_REFERRAL);

  const referralId = get('id', referral);
  const toCompanyName = get('toCompanyName', referral);
  const toName = get('toName', referral);
  const autoBav = getOr(false, 'autoBav', referral);
  const requirePersonalGuarantee = getOr(false, 'requirePersonalGuarantee', referral);

  const customerFields = {
    customMessage: '',
    autoBav,
    requirePersonalGuarantee,
  };

  const handleSubmit = async (formValues: IFields) => {
    const variables = {
      referralId,
      referralAttrs: {
        customMessage: formValues.customMessage,
        autoBav: formValues.autoBav,
        requirePersonalGuarantee: formValues.requirePersonalGuarantee,
        blockSend: false,
      },
    };

    updateReferral({ variables })
      .then((response) => {
        showToast({
          title: 'Customer Invited',
          description: `Sent Invite to ${toName} from ${toCompanyName}`,
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      });
  };

  return (
    <StyledModalForm
      title={`Send Invite to ${toCompanyName}`}
      helpText={<>Send {toName} your credit application. If you need to edit your credit application, <StyledLink className='bold-link' to='/dashboard/application'>click here.</StyledLink></>}
      submitButtonText='Send Invite'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={customerFields}
      validationSchema={customerValidation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <TextAreaField
        name='customMessage'
        label='Custom Message'
        placeholder="Hey, we'd love to extend you a line of credit, please fill out your Nectarine Credit Profile to get started!"
      />
      <Column>
        <StyledCheckboxContainer>
          <FieldLabel name='bankVerification'>Automatic Bank Verification</FieldLabel>
          <CheckboxField name='autoBav'>
            <BodyText>Enable Automatic Bank Verification</BodyText>
          </CheckboxField>
        </StyledCheckboxContainer>
        <StyledCheckboxContainer>
          <FieldLabel name='requirePersonalGuarantee'>Personal Guarantee</FieldLabel>
          <CheckboxField name='requirePersonalGuarantee'>
            <BodyText>Enable Personal Guarantee</BodyText>
          </CheckboxField>
        </StyledCheckboxContainer>
      </Column>
    </StyledModalForm>
  );
};
