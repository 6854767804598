import gql from 'graphql-tag';

export const SEND_MANUAL_CUSTOMER_FOLLOW_UP = gql`
  mutation SendManualCustomerFollowUp($customerId: ID!) {
    sendManualCustomerFollowUp(customerId: $customerId)
  }
`;

export const SEND_MANUAL_REFERRAL_FOLLOW_UP = gql`
  mutation SendManualReferralFollowUp($referralId: ID!) {
    sendManualReferralFollowUp(referralId: $referralId)
  }
`;
