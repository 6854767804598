import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/react-hooks';

import { DELETE_CUSTOMER } from '@/graphql/mutations/deleteCustomer';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { ConfirmActionModal } from '@/modals/ConfirmActionModal';
import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';

import { StyledButtonWrapper } from './styled';

interface IProps {
  customerId: string,
  title: string,
}

export const DeleteCustomer = ({ customerId, title }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useHistory();
  const [deleteCustomerMutation] = useMutation(DELETE_CUSTOMER);

  const deleteCustomer = () => {
    deleteCustomerMutation({ variables: { customerId } })
      .then(() => {
        showToast({
          title: 'Customer Deleted',
          description: 'Your customer has been successfully removed',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        navigate.push('/dashboard/customers');
      });
  };

  return (
    <StyledButtonWrapper>
      <Button onClick={() => setIsOpen(true)} wide secondary>Delete Customer</Button>
      <ConfirmActionModal
        title='Delete Customer'
        buttonText='Delete'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        action={deleteCustomer}
      >
        <BodyText bold>Are you sure you want to delete {title}? This action cannot be undone.</BodyText>
      </ConfirmActionModal>
    </StyledButtonWrapper>
  );
};
