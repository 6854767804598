import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as TriangleDown } from '@/assets/triangle_down.svg';

import {
  DropdownContainer,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuItemSeparator
} from './styled';
import { Button } from '@/components/designSystem/buttons';

interface IDropdownOptionProps {
  label: string;
  separator?: boolean;
}

interface IDropdownProps {
  label?: string;
  options: IDropdownOptionProps[];
  onSelect: (option: string) => void;
  width?: string;
  color?: string;
  fontSize?: string;
  backgroundColor?: string;
  position?: 'left' | 'right';
  borderRadius?: string;
  zIndex?: number;
  upward?: boolean;
  leftIcon?: any;
  btnDisabled?: boolean;
}

const Dropdown: React.FC<IDropdownProps> = ({
  label,
  options,
  onSelect,
  width,
  color,
  fontSize,
  backgroundColor,
  position,
  borderRadius,
  zIndex,
  upward = false,
  leftIcon,
  btnDisabled = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (option: string) => {
    onSelect(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <Button
        primary
        onClick={toggleDropdown}
        RightIcon={TriangleDown}
        LeftIcon={leftIcon}
        disabled={btnDisabled}
        wide
      >
        <label>{label || 'Select'}</label>
      </Button>
      <DropdownMenu
        isOpen={isOpen}
        width={width}
        color={color}
        fontSize={fontSize}
        backgroundColor={backgroundColor}
        position={position}
        borderRadius={borderRadius}
        zIndex={zIndex}
        upward={upward}
      >
        {options.map((option, index) => (
          <React.Fragment key={index}>
            <DropdownMenuItem onClick={() => handleOptionClick(option.label)}>
              {option.label}
            </DropdownMenuItem>
            {option.separator && <DropdownMenuItemSeparator />}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default Dropdown;
