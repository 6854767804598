import gql from 'graphql-tag';

export const UPSERT_MANUAL_VENDOR_REFERRAL = gql`
  mutation UpsertManualVendorReferral(
    $customerId: ID!
    $vendorReference: VendorReferenceReferralManualInput!
    $blockSend: Boolean
    $referralId: ID
  ) {
    upsertManualVendorReferral(
      customerId: $customerId,
      vendor_reference: $vendorReference,
      block_send: $blockSend,
      referralId: $referralId
    ) {
      id
    }
  }
`;
