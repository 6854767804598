import React from 'react';

import { FieldArray, useFormikContext } from 'formik';

import { BodyText, H2, H3, H4 } from '@/components/designSystem/Typography';
import { FlexRowEnd } from '@/components/designSystem/Layout';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { useCurrentUserOwner } from './useCurrentUserOwner';
import { OwnerSection } from './OwnerSection';
import { initialOwnerFields, initialAdditionalContactFields } from './initialValues';
import { IBasicInfoValues } from '../BasicInfoStep/types';
import { AdditionalContactSection } from './AdditionalContactSection';
import { ICompanyContactsValues } from './types';
import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm,
  StyledSubtitleContainer,
  StyledCheckboxWrapper
} from '../../styled';
import { useAutoscrollToError } from '../../../useAutoscrollToError';

interface IProps {
  basicInfo: IBasicInfoValues,
}

export const CompanyContactsStepForm = ({
  basicInfo,
}: IProps) => {
  const { values, setFieldValue, setFieldTouched, errors, isSubmitting } = useFormikContext<ICompanyContactsValues>();

  useAutoscrollToError(errors, isSubmitting);

  const {
    currentUserOwner,
    currentUserOwnerClickHandle
  } = useCurrentUserOwner({
    basicInfo,
    setFieldValue,
    setFieldTouched,
  });

  return (
    <StyledForm>
      <StyledTitleContainer>
        <H2 bold>Company Contacts</H2>
      </StyledTitleContainer>
      <StyledSubtitleContainer>
        <H3 bold>Owners</H3>
        <H4>Enter the majority owners of the company</H4>
      </StyledSubtitleContainer>
      <StyledCheckboxWrapper>
        <Checkbox checked={currentUserOwner} onChange={currentUserOwnerClickHandle}>
          <BodyText bold>I am currently an owner of the company</BodyText>
        </Checkbox>
      </StyledCheckboxWrapper>
      <FieldArray
        name='owners'
        render={(arrayHelpers) => (
          <>
            {
              values.owners.map((_owner, index) => (
                <OwnerSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  parentKey={`owners[${index}]`}
                  onRemoveClick={() => arrayHelpers.remove(index)}
                />
              ))
            }
            <Button secondary LeftIcon={Plus} onClick={() => arrayHelpers.push(initialOwnerFields)}>Add Additional Owner</Button>
          </>
        )}
      />
      <StyledSubtitleContainer>
        <H3 bold>Other Company Contacts</H3>
        <H4>Enter other key company contacts here.</H4>
      </StyledSubtitleContainer>
      <FieldArray
        name='additionalContacts'
        render={(arrayHelpers) => (
          <>
            {values.additionalContacts
              ? values.additionalContacts.map((_additionalContact, index) => (
                <AdditionalContactSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  parentKey={`additionalContacts[${index}]`}
                  onRemoveClick={() => arrayHelpers.remove(index)}
                />
              )) : false
            }
            <Button secondary LeftIcon={Plus} onClick={() => arrayHelpers.push(initialAdditionalContactFields)}>Add Additional Contact</Button>
          </>
        )}
      />
      <StyledSubtitleContainer>
        <H3 bold>Service Locations</H3>
      </StyledSubtitleContainer>
      <FieldArray
        name='serviceLocations'
        render={(arrayHelpers) => (
          <>
            <h1>Test</h1>
          </>
        )}
      />
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Submit</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )
};
