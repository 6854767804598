import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { BackButton } from '@/components/designSystem/buttons/BackButton';

import { StepHeader } from '../StepHeader';
import {
  StyledFormContainer,
  StyledLeftBlock,
  StyledMain,
  StyledRightBlock,
  // StyledStepCounterContainer,
} from './styled';
import { StyledStepCounterContainer } from '../styled';
import { STEPS } from './constants';
import { StepCounter } from '@/components/Onboard/StepCounter';

interface IProps {
  children: React.ReactNode,
  overriddenLogo?: JSX.Element | null,
  index: number,
  prevPath: string | null,
}

export const StepWrapper = ({ overriddenLogo, children, index, prevPath }: IProps) => {
  const { search } = useLocation();

  return (
    <>
      <StepHeader 
      overriddenLogo={overriddenLogo} 
      steps={(
        <StyledStepCounterContainer steps={Object.values(STEPS)}>
          <StepCounter steps={Object.values(STEPS)} activeStep={index} />
        </StyledStepCounterContainer>
      )}
      />
      <StyledMain>
        <StyledLeftBlock>
          {prevPath ? (
            <BackButton link={`${prevPath}${search}`} />
          ) : false }
        </StyledLeftBlock>
        <StyledFormContainer>
          {children}
        </StyledFormContainer>
        <StyledRightBlock />
      </StyledMain>
    </>
  )}
;
