import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { BankConnectedView } from './BankConnectedView';
import { ConnectBankView } from './ConnectBankView';

interface IProps {
  isFinicityConnected: boolean,
  requireBankInfo: boolean,
  refetchFinicityCustomerData: () => void,
  automaticBankVerificationEnabled: boolean,
  basePath: string,
}

export const FinancialHealthStepView = ({
  isFinicityConnected,
  requireBankInfo,
  refetchFinicityCustomerData,
  automaticBankVerificationEnabled,
  basePath,
}: IProps) => {
  const { search } = useLocation();

  if (!automaticBankVerificationEnabled) {
    return <Redirect from={`${basePath}/bank`} to={{ pathname: `${basePath}/bank/manual`, search }} />
  }

  if (isFinicityConnected) {
    return <BankConnectedView />
  }

  return (
    <ConnectBankView
      requireBankInfo={requireBankInfo}
      refetchFinicityCustomerData={refetchFinicityCustomerData}
    />
  )
}