import React from 'react';

interface IAttachmentDetail {
  name: string,
  contentType: string,
}

interface IAttachmentProps {
  attachmentDetail: IAttachmentDetail,
  index: number,
}

export const AttachmentDetails = ({ attachmentDetail }: IAttachmentProps) => (
  <>
    {attachmentDetail.name} ({attachmentDetail.contentType})
  </>
);