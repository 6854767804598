import React from 'react';
import { Formik } from 'formik';

import { get, getOr, noop } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { BorderedContainer } from '@/containers/BorderedContainer';
import { H4, BodyText, Caption } from '@/components/designSystem/Typography';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';

import { CheckboxesContainer, SignButton, SignButtonContainer } from '../styled';
import { SignedMessage } from './messages';

interface ISignApplicationActionProps {
  customer: ICustomer,
  refetch: () => void,
  buttonText: string,
}

export const SignApplicationAction = ({ customer, buttonText, refetch }: ISignApplicationActionProps) => {
  const signedStatus = get('latestApplication.activeAgreement.status', customer);
  const signed = signedStatus === 'signed' || signedStatus === 'processing';

  return (
    <Formik
      onSubmit={noop}
      validateOnChange
      initialValues={{
        authorizedToSignCheck: false,
        authorizedBankCheck: false,
        agreeTosCheck: false,
      }}
    >
      {({ values }) => {
        const authorizedToSignCheck = getOr(signed, 'authorizedToSignCheck', values);
        // const authorizedBankCheck = getOr(signed, 'authorizedBankCheck', values);
        // const agreeTosCheck = getOr(signed, 'agreeTosCheck', values);
        const canSign = !signed && authorizedToSignCheck //&& authorizedBankCheck && agreeTosCheck;

        return (
          <>
            {signed && <SignedMessage />}

            <CheckboxesContainer>
              <CheckboxField disabled={signed} name='authorizedToSignCheck'>
                <BodyText bold>
                  I acknowledge that my electronic signature is legally binding and I am authorized to apply for credit and enter into this agreement on behalf of my company.
                  I hereby authorize the bank, vendors and other sources named herein to release the data and information requested for the purpose of obtaining and/or reviewing my company's credit.
                  I agree to the Terms and Conditions set forth in this agreement.
                </BodyText>
              </CheckboxField>
              {/* <CheckboxField disabled={signed} name='authorizedBankCheck'>
                <BodyText bold>
                  {`I hereby authorize the bank, vendors and other sources named herein to release the data and information requested `}
                  {`for the purpose of obtaining and/or reviewing my company's credit and/or for other services offered by Nectarine Credit`}
                </BodyText>
              </CheckboxField>
              <CheckboxField disabled={signed} name='agreeTosCheck'>
                <BodyText bold>I agree to the Terms and Conditions set forth in this credit agreement</BodyText>
              </CheckboxField> */}
            </CheckboxesContainer>
            <BorderedContainer>
              <SignButtonContainer>
                <H4 bold>Signature</H4>
                <SignButton
                  customerId={customer.id}
                  primary
                  onSuccess={() => refetch()}
                  disabled={!canSign}
                >
                  Sign and {buttonText}
                </SignButton>
                <Caption color='secondary'>Signature is required</Caption>
              </SignButtonContainer>
            </BorderedContainer>
          </>
        );
      }}
    </Formik>
  );
};
