import { get, getOr } from 'lodash/fp';
import { IVendor } from '@/types/vendor';

export const useVRContact = (reference: IVendor) => {
  const vrContact = reference.sourceCompany?.stakeholders?.[0] || reference.sourceUser;
  const vrCompany = reference.sourceCompany || reference.sourceUser;

  return {
    vrCompanyName: get('name', vrCompany),
    vrContactName: get('name', vrContact),
    vrContactPosition: get('position', vrContact),
    vrContactEmail: get('email', vrContact),
    vrContactPhoneNumber: get('phoneNumber', vrContact),
    showNonBusinessEmailDomainWarning: get('showNonBusinessEmailDomainWarning', vrContact),
    vrPending: getOr(null, 'isPending', reference),
    vrInviteId: getOr(null, 'inviteId', reference.sourceCompany)
  };
}