import styled from 'styled-components';

import { FlexColumnGap } from '@/components/designSystem/Layout';
import { StyledExternalLinkUnderline } from '@/components/designSystem/Typography';
import { Form } from 'formik';

export const StyledContactInfo = styled(FlexColumnGap)`
  margin-top: 0.5rem;
`;

export const StyledShowAllContactsWrapper = styled.div`
  grid-column: span 3;
  text-align: center;
`;

export const StyledContactEmail = styled(StyledExternalLinkUnderline)`
  max-width: calc(100% - 3rem);
  word-wrap: break-word; 
`

export const StyledDivider = styled.div`
  align-self: center;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.blackTrans16};
`;

export const StyledContactsForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
