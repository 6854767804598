import React from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { SubmitApplication } from './SubmitApplication';
import { DeniedMessage, PendingApprovalMessage } from './messages';

interface IApplicationActionProps {
    customer: ICustomer,
    refetch: () => void,
  }
  
export const ApplicationAction = ({ customer, refetch }: IApplicationActionProps) => {
  const status = get('latestApplication.status', customer);
  
  switch (status) {
  case 'created':
    return <SubmitApplication customer={customer} refetch={refetch} buttonText='Submit' />;
  
  case 'pending_approval':
    return <PendingApprovalMessage customer={customer} />;
  
  case 'approved':
    return null;
  
  case 'changes_requested':
    return <SubmitApplication customer={customer} refetch={refetch} buttonText='Resubmit' />;
  
  case 'denied':
    return <DeniedMessage customer={customer} />;
  
  default:
    return null;
  }
};