import { formatPhoneNumberForRequest } from "@/utils/format";

import { IContactValues } from "./types";
import { IStakeholder } from "@/types/stakeholder";

import { IPartStakeholder } from "@/views/CustomerPage/types";

const SYMBOLS_BEFORE_NUMBER = 11;

export const mapFormValuesToVariables = (values: IContactValues, stakeholders: IStakeholder[]) => {
  // Get every stakeholder ID
  let companyContacts: IPartStakeholder[] = stakeholders.map((stakeholder) => ({ id: stakeholder.id }));

  // Modify values object to stakeholders array
  Object.entries(values).forEach((value) => {
    const index = +value[0][SYMBOLS_BEFORE_NUMBER];
    companyContacts = [
      ...companyContacts.slice(0, index),
      {
        ...companyContacts[index],
        [value[0].split('-')[1]]: value[0].split('-')[1] === 'phoneNumber' ? formatPhoneNumberForRequest(value[1] as any) : value[1],
      },
      ...companyContacts.slice(index + 1)
    ]
  })

  return companyContacts.map((contact) => {
    const { addressLine1, addressLine2, city, country, postalCode, state } = contact;

    const address = {
      addressLine1,
      addressLine2,
      city,
      country,
      postalCode,
      state
    }
    delete contact.addressLine1
    delete contact.addressLine2
    delete contact.city
    delete contact.postalCode
    delete contact.state
    delete contact.country

    return {
      ...contact,
      address: JSON.stringify(address),
      dob: contact.dob ? new Date(contact.dob).toISOString() : null
    };
  });
};
