import React, { Dispatch, SetStateAction } from 'react';

import { FooterContainer } from '@/modals/StyledModal/styled';
import { Button } from '@/components/designSystem/buttons';

interface IProps {
  showSearch: boolean,
  setShowSearch: Dispatch<SetStateAction<boolean>>,
  loading: boolean,
}

export const ReportModalFooter = ({
  showSearch,
  setShowSearch,
  loading,
}: IProps) => (
  <FooterContainer>
    {
      showSearch
        ? (
          <Button
            wide
            onClick={(e: MouseEvent) => {
              setShowSearch(false);
              e.preventDefault();
            }}
          >
              Back
          </Button>
        )
        : <Button wide primary loading={loading} type='submit'>Search</Button>
    }
  </FooterContainer>
);
