import React from 'react';

import { useField, ErrorMessage } from 'formik';

import { FieldLabel } from './FieldLabel';
import { StyledDescriptionText, StyledErrorText, StyledFieldContainer, StyledNumberFormatInput } from './styled';

interface INumberFormatInputValue {
  formattedValue: string,
  value: string,
  floatValue: number,
}

interface IProps {
  name?: string,
  label?: string,
  required?: boolean,
  description?: string,
  disabled?: boolean,
}

export const CurrencyField = ({
  required,
  name = '',
  label,
  description,
  disabled,
}: IProps) => {
  const [field, meta, helpers] = useField(name);

  const showError = !!(meta.touched && meta.error);

  const { setValue, setTouched } = helpers;

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <StyledNumberFormatInput
        value={field.value}
        onValueChange={(value: INumberFormatInputValue) => setValue(value.floatValue ?? null)}
        onBlur={() => setTouched(true)}
        prefix='$'
        isNumericString
        thousandSeparator
        decimalScale={2}
        placeholder='$'
        error={showError}
        disabled={disabled}
      />
      {description && <StyledDescriptionText>{description}</StyledDescriptionText>}
      {(showError && name) &&
        <StyledErrorText>
          { (showError && name) ? <ErrorMessage name={name} /> : false }
        </StyledErrorText>
      }
    </StyledFieldContainer>
  )
};
