import styled from 'styled-components';

import { FlexRow, FlexRowItemsEnd } from '@/components/designSystem/Layout';
import { H3 } from '@/components/designSystem/Typography';
import device from '@/styles/device';

export const StyledContactContainer = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.color.greyLight};
  margin-bottom: 1.5rem;
`;

export const StyledCloseIconWrapper = styled.div`
  cursor: pointer;
`;

export const StyledCustomContactHeader = styled(H3)`
  margin-bottom: 0.5rem;
`;

export const StyledCustomContactContainer = styled.div`
  margin: 1rem 0rem;
`;

export const StyledCustomContactFormRow = styled(FlexRowItemsEnd)`
  gap: 1rem;
`;

export const StyledCustomContactPhoneNumberExtensionContainer = styled(FlexRow)`
  gap: 1rem;
  
  @media ${device.mobileL} {
    flex-direction: column;
    gap: 0rem;
  };
`;

export const StyledCustomContactPhoneNumberWrapper = styled.div`
  width: calc(100% - 6rem);
  max-width: 50%;
  
  @media ${device.mobileXL} {
    max-width: 100%;
  };
  
  @media ${device.mobileL} {
    width: 100%;
  };
`;

export const StyledAdditionalContactPhoneNumberExtensionContainer = styled(FlexRow)`
  gap: 1rem;
  
  @media ${device.mobileL} {
    flex-direction: column;
    gap: 0rem;
  };
`;

export const StyledAdditionalContactPhoneNumberWrapper = styled.div`
  width: calc(100% - 6rem);
  
  @media ${device.mobileL} {
    width: 100%;
  };
`;