import gql from 'graphql-tag';

export const UPDATE_BRANDING = gql`
  mutation UpdateBranding(
    $brandAttrs: UpdateBrandInput!
  ) {
    updateBrand(brandAttrs: $brandAttrs) {
      id
      logo {
        id
        path
        originalFilename
        signedUrl
      }
      primaryColor
      secondaryColor
      brandColor
      backgroundColor
    }
  }
`;
