import React from 'react';

import { get } from 'lodash/fp';

import { IManualBank } from '@/types/manualBank';

import { ManualAccount } from './ManualAccount';
import { StyledInstitutionContainer } from '../styled';

interface IProps {
  institution: IManualBank,
  accountNotes: string[],
  customerId: string,
  refetch: () => void,
}

export const ManualBank = ({
  institution, accountNotes, customerId, refetch,
}: IProps) => {
  const institutionName = get('institutionName', institution);
  const accounts = get('accounts', institution);

  return (
    <StyledInstitutionContainer>
      {accounts.map((account) => (
        <ManualAccount
          key={account.accountNumber}
          institution={institution}
          accountNotes={accountNotes}
          account={account}
          institutionName={institutionName}
          customerId={customerId}
          refetch={refetch}
        />
      ))}
    </StyledInstitutionContainer>
  );
};
