import React, { useState } from 'react';

import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { AddManualInstitutionModal } from './index';

interface IProps {
    refetch: () => void,
    institutionName?: string,
    institutionId?: string,
}

export const AddManualInstitutionButton = ({ refetch, ...rest }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} LeftIcon={Plus} secondary>Add Bank Manually</Button>
      <AddManualInstitutionModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} {...rest} />
    </>
  );
};