import React, { useState } from 'react';

import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { AddManualAccountModal } from './index';

interface IProps {
    refetch: () => void,
    institutionName: string,
    institutionId: string,
}

export const AddManualAccountButton = ({ refetch, ...rest }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} LeftIcon={Plus} wide secondary>Add Account</Button>
      <AddManualAccountModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} {...rest} />
    </>
  );
};