import styled from 'styled-components';

import { FlexColumn, FlexRowItemsCenter, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

export const StyledContainer = styled(FlexColumn)`
  & > :first-child {
    margin-bottom: 2rem;
  }
`;

export const PageContainer = styled(FlexColumn)`
  & > * {
    margin-bottom: 3rem;
  }
`;

export const PageTitleContainer = styled(FlexRowSpaceBetweenItemsCenter)`
  margin-bottom: 1.5rem;
`;

export const Row = styled(FlexRowItemsCenter)`
  & > * {
    margin-right: 0.5rem;
  }
`;

export const StyledEmailNotificationCheckboxWrapper = styled(FlexRowItemsCenter)`
  margin-top: 1rem;
  width: 3rem;
`;
