import styled from 'styled-components';
import { Form } from 'formik';

import { BodyText } from '@/components/designSystem/Typography';
import { Flex, FlexColumn, FlexColumnItemsCenter } from '@/components/designSystem/Layout';

export const StyledContainer = styled(FlexColumnItemsCenter)`
  height: calc(100% - 6rem);
`;

export const StyledFormContainer = styled.div`
  height: calc(100% - 1rem);
  width: 100%;
`;

export const StyledOptionsContainer = styled(FlexColumnItemsCenter)`
  gap: 1rem;
  width: 100%;
  height: calc(100% - 1rem);
  overflow-y: scroll;
  padding-bottom: 1rem;
  position: relative;
`;

export const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledFormInnerContainer = styled.div`
  overflow-y: auto;
  padding: 1.5rem 3.5rem;
  gap: 1.5rem;
  height: calc(100% - 5.5rem);
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const StyledLeftFormContainer = styled.div`
  flex-grow: 1;
`;

export const StyledPreviewContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.color.secondary};
  padding: 1.5rem;
`;

export const StyledPreviewButtonWrapper = styled(Flex).attrs({ align: 'center', justify: 'center' })`
  height: 100%;
`;

export const StyledBodyText = styled(BodyText)`
  margin-top: -1.5rem;
`;

export const StyledCheckboxContainer = styled(FlexColumn)`
  width: 100%;
  padding-bottom: 1rem;
`;

export const StyledPreviewFieldContainer = styled(FlexColumn)`
  margin-top: 3rem;
  justify-content: center;
`;

export const StyledContactFormRow = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat( auto-fit, minmax(10rem, 1fr) );
`;

export const StyledOptionFieldContainer = styled(Flex).attrs({ direction: 'row', justify: 'space-between', align: 'center' })`
  gap: 1rem;
  cursor: pointer;
`;

export const StyledAddOptionButtonWrapper = styled.div`
  margin-bottom: 1rem;
`;
