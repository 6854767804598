import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { IShowToastProps } from './types';
import { ToastBody } from './body';

const showToast = ({ title, description, ...options }: IShowToastProps) => (
  toast(<ToastBody title={title} description={description} />, options)
);

export {
  showToast,
  toast,
};
