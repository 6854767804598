import { omitBy, get, isNil } from 'lodash/fp';

const primary = '#047579';
const secondary = '#EBF1F4';
const negative = '#8C0A0A';
const brand = '#E95433';
const brandTrans = 'rgba(187, 97, 97, 0.1)';

const warning = '#C5A412';
const white = '#ffffff';

const greyDark = '#6E7072';
const greySkeleton = '#c4c2c1';
const greyMed = '#9E9996';
const greyLight = '#F5F5F5';

const black = '#161616';
const blackTrans50 = 'rgba(22, 22, 22, 0.5)';
const blackTrans16 = 'rgba(22, 22, 22, 0.16)';
const blackTrans8 = 'rgba(22, 22, 22, 0.08)';

const brownDark = '#A4847D';
const brownLight = '#BFABA3';

const purple = '#742781';
const beige = '#F6F2EF';

const highVizGreen = '#12C457';
const highVizRed = '#CB2E68';

const tagGreen = '#C6E1D9';
const tagYellow = '#FBEECC';
const tagRed = '#F4B6B6';
const tagBlue = '#C1E5F7';

const pastelGreen = '#E3F1DF';
const pastelYellow = '#FCF9DB';
const pastelRed = '#FBEAE5';
const pastelOrange = '#FCEBDB';
const pastelBlue = '#EBF5FA';

const baseColors = {
  primary,
  secondary,
  negative,
  beige,
  brand,
  brandTrans,
  warning,
  blackTrans50,
  blackTrans16,
  blackTrans8,
  black,
  white,
  greyDark,
  greyMed,
  greyLight,
  greySkeleton,
  highVizGreen,
  highVizRed,
  pastelGreen,
  pastelYellow,
  pastelRed,
  pastelOrange,
  pastelBlue,
  background: beige,
  brownDark,
  brownLight,
  purple,
  tagGreen,
  tagYellow,
  tagRed,
  tagBlue,
};

// TODO Fix styled-components types

const componentColors = (colors: any) => ({
  site: {
    background: get('background', colors),
  },
  text: {
    primary: get('black', colors),
    secondary: get('greyDark', colors),
    tertiary: get('greyMed', colors),
    quaternary: get('white', colors),
    stepper: get('brownDark', colors),
    brand: get('brand', colors),
    negative: get('negative', colors),
  },
  label: {
    warning: get('tagYellow', colors),
    danger: get('tagRed', colors),
    success: get('tagGreen', colors),
    info: get('tagBlue', colors),
    default: get('greyLight', colors),
  },
  border: {
    dark: `1px solid ${get('blackTrans16', colors)}`,
    light: `1px solid ${get('blackTrans8', colors)}`,
    brown: `1px solid ${get('brownLight', colors)}`,
    negative: `1px solid ${get('negative', colors)}`,
  },
  button: {
    primary: {
      background: get('primary', colors),
      text: get('white', colors),
    },
    secondary: {
      background: get('secondary', colors),
      text: get('primary', colors),
    },
    brand: {
      background: get('brand', colors),
      text: get('white', colors),
    },
    danger: {
      background: get('negative', colors),
      text: get('white', colors),
    },
    warning: {
      background: get('tagYellow', colors),
      text: get('black', colors),
    },
    disabled: {
      background: get('blackTrans8', colors),
      text: get('greyMed', colors),
    },
    default: {
      background: get('greyLight', colors),
      text: get('black', colors),
    },
  },
  shadow: {
    shadowSmall: '0px 1px 0px rgba(0, 0, 0, 0.04)',
    shadowSmallInset: 'inset 0px 0px 0px 100px rgba(0, 0, 0, 0.1)',
    shadowLight: '0px 0px 24px rgba(0, 0, 0, 0.04)',
    shadowMed: '6px 5px 24px 2px rgb(0, 0, 0, 0.1)',
    shadowLarge: '3px 4px 20px 4px rgba(0, 0, 0, 0.14)',
  },
  data: {
    border: get('blackTrans8', colors),
    positive: get('highVizGreen', colors),
    negative: get('highVizRed', colors),
  },
  profile: [
    get('pastelGreen', colors),
    get('pastelYellow', colors),
    get('pastelRed', colors),
    get('pastelOrange', colors),
    get('pastelBlue', colors),
  ],
  support: get('purple', colors),
});

const spacing = {
  site: {
    top: '2.5rem',
    right: '1rem',
  },
  sidebar: {
    left: '34px',
  },
};

const theme = (overrides = {}) => {
  const safeOverrides = omitBy(isNil, overrides);
  const colors = { ...baseColors, ...safeOverrides };

  const color = {
    ...colors,
    ...componentColors(colors),
  };

  return {
    color,
    spacing,
  };
};

export default theme;
