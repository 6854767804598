import React from 'react';

import { formatDateToTimeAgo } from '@/utils/date';

import { Small, Caption } from '@/components/designSystem/Typography';
import { FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

interface IProps {
  occuredAt: string,
  description: string,
}

export const ActivityItem = ({ occuredAt, description }: IProps) => (
  <FlexRowSpaceBetweenItemsCenter>
    <Caption>{description}</Caption>
    <Small color='secondary'>{formatDateToTimeAgo(occuredAt)}</Small>
  </FlexRowSpaceBetweenItemsCenter>
);
