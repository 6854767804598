export const AUTH0 = {
  domain: process.env.CLIENT_AUTH_0_DOMAIN,
  clientId: process.env.CLIENT_AUTH_0_CLIENT_ID,
  audience: process.env.CLIENT_AUTH_0_AUDIENCE,
  realm: 'Username-Password-Authentication',
  scope: 'openid profile email',
};

export const STRIPE = {
  publicKey: process.env.CLIENT_STRIPE_PUBLIC_KEY,
};

export const API = {
  baseUrl: process.env.CLIENT_API_BASE_URL,
};

export const SEGMENT = {
  id: process.env.CLIENT_SEGMENT_ID,
};

export const SENTRY = {
  dsn: process.env.CLIENT_SENTRY_DSN,
  environment: process.env.CLIENT_SENTRY_ENV,
};

export const OPENREPLAY = {
  key: process.env.CLIENT_OPENREPLAY_PROJECT_KEY,
};

export const CLIENT_ENV = process.env.CLIENT_ENV;
export const CLIENT_HIDE_DNB = process.env.CLIENT_HIDE_DNB;
export const CLIENT_HIDE_EXPERIAN = process.env.CLIENT_HIDE_EXPERIAN;
export const CLIENT_HIDE_EQUIFAX = process.env.CLIENT_HIDE_EQUIFAX;
export const FINGERPRINT_PUBLIC_KEY = process.env.FINGERPRINT_PUBLIC_KEY;
