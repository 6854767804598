import React, { Dispatch, SetStateAction } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { UPDATE_NAME } from '@/graphql/mutations/updateName';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

const validation = Yup.object().shape({
  name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

interface IFields {
  name: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
  name: string,
}

export const EditNameModal = ({
  isOpen, setIsOpen, onSuccess, name,
}: IProps) => {
  const [updateName, { loading }] = useMutation(UPDATE_NAME);

  const handleSubmit = async ({ name }: IFields) => {
    updateName({ variables: { name } })
      .then((response) => {
        setIsOpen(false);
        onSuccess && onSuccess(response);
      });
  };

  const fields = {
    name,
  };

  return (
    <StyledModalForm
      title='Update Name'
      submitButtonText='Confirm'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <TextField required type='text' name='name' label='Full Name' placeholder='Full Name' />
    </StyledModalForm>
  );
};
