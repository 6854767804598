import React from 'react';
import styled from 'styled-components';

import { get, getOr, flow, filter } from 'lodash/fp';
import { formatDateToTimeAgo } from '@/utils/date';

import { IVendor } from '@/types/vendor';

import ToggleWell from '@/components/_oldComponents/ToggleWell';
import { ContactLink } from '../ContactLink';
import { Caption} from '@/components/designSystem/Typography';

import { ReactComponent as CheckIcon } from '@/assets/check-circle.svg';
import { ReactComponent as WarningIcon } from '@/assets/warning.svg';
import { getWithBackup } from '@/utils/object';

const CaptionWithIcon = styled(Caption)`
  display: flex;
  align-items: center;
`;

const GreenCheckIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.color.highVizGreen};
  margin-right: .25rem;
`;

const YellowWarningIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.color.warning};
  margin-right: .25rem;
`;

interface IProps {
  vendor: IVendor,
  startOpen?: boolean,
  targetKey?: string,
}

export const VendorReferenceInfo = ({ vendor, startOpen = false, targetKey = 'sourceCompany' }: IProps) => {
  const sent = getOr(false, `${targetKey}.sent`, vendor);
  const [isSent, setIsSent] = React.useState(sent);

  let id = get(`${targetKey}.id`, vendor);
  if(!id) {
    id = vendor?.id;
  }

  let name = get(`${targetKey}.name`, vendor);
  //For unauth vendor, name will be null in company
  if(!name) {
    name = vendor.sourceUser?.company;
  }

  const updatedAt = getWithBackup('updatedAt', `${targetKey}.updatedAt`, vendor);

  // TODO Fix any
  let primaryContact = flow(
    getOr([], `${targetKey}.stakeholders`),
    filter('isPrimaryContact'),
    get('[0]'),
  )(vendor as any);

  // For unauth vendor, primaryContact will be null
  if(!primaryContact) {
    primaryContact = vendor.sourceUser;
  }

  const isComplete = !!id;

  const TitleAction = () => (
    <>&nbsp;{ primaryContact && <ContactLink contact={primaryContact} />}</>
  );

  const TitleInfo = () => {
    if (!isComplete && !isSent) {
      return (
        <CaptionWithIcon><YellowWarningIcon /> Request Pending</CaptionWithIcon>
      );
    }
    return (
      <CaptionWithIcon color='secondary'>
        {isComplete
          ? <><GreenCheckIcon /> Updated {formatDateToTimeAgo(updatedAt)}</>
          : <><YellowWarningIcon /> Reference Requested {formatDateToTimeAgo(updatedAt)}</>
        }
      </CaptionWithIcon>
    )
  };

  return (
    <ToggleWell
      title={name}
      startOpen={startOpen}
      TitleInfo={TitleInfo}
      TitleAction={TitleAction}
      blockOpen
    />
  );
};
