import React from 'react';

import { IContactSettings } from '@/types/customField';

import { H3 } from '@/components/designSystem/Typography';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { TextField } from '@/components/designSystem/Form/TextField';

import { StyledFormRow } from '@/components/designSystem/Form/styled';

import {
  StyledCustomContactContainer,
  StyledCustomContactFormRow,
  StyledCustomContactHeader,
  StyledContactContainer,
  StyledCustomContactPhoneNumberExtensionContainer,
  StyledCustomContactPhoneNumberWrapper
} from './styled';

interface IProps {
  parentKey: string,
  label: string,
  settings: IContactSettings,
}

export const CustomContactSection = ({ parentKey, label, settings }: IProps) => {
  const {
    nameRequired,
    phoneRequired,
    emailRequired,
  } = settings;

  return (
    <StyledContactContainer>
      <StyledCustomContactContainer>
        <StyledCustomContactHeader bold>{label}</StyledCustomContactHeader>
        <StyledFormRow>
          <TextField type='text' required={nameRequired} name={`${parentKey}.name`} label='Full Name' placeholder='John Doe' />
          <TextField type='text' required={emailRequired} name={`${parentKey}.email`} label='Email Address' placeholder='John@streamlink.com' />
        </StyledFormRow>
        <StyledCustomContactPhoneNumberExtensionContainer>
          <StyledCustomContactPhoneNumberWrapper>
            <PhoneNumberField required={phoneRequired} name={`${parentKey}.phoneNumber`} label='Phone Number' placeholder='Phone number' />
          </StyledCustomContactPhoneNumberWrapper>
          <TextField type='text' name={`${parentKey}.extension`} label='Extension' placeholder='123' width='5rem' />
        </StyledCustomContactPhoneNumberExtensionContainer>
      </StyledCustomContactContainer>
    </StyledContactContainer>
  )
};
