import React from 'react';

import { map } from 'lodash/fp';

import { IManualBank } from '@/types/manualBank';

import { ManualInstitution } from './ManualInstitution';

interface IProps {
  institutions: IManualBank[],
  accountNotes: string[],
  customerId?: string,
  refetch: () => void,
}

export const ManualSection = ({
  institutions, accountNotes, customerId, refetch,
}: IProps) => (
  <>
    {map((institution) => (
      <ManualInstitution institution={institution} accountNotes={accountNotes} customerId={customerId} refetch={refetch} />
    ), institutions)}
  </>
);
