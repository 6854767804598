import gql from 'graphql-tag';

export const ONBOARD_VENDOR_REFERENCE_INPUT = gql`
  mutation CompleteVendorReference(
    $onboardData: vendor!
    $vendorReferenceData: VendorReferenceInput!
    $referralCode: String!
    $redirectTo: String!
    $visitorData: VisitorData
  ) {
    completeVendorReference(
      onboardData: $onboardData,
      vendorReferenceData: $vendorReferenceData,
      referralCode: $referralCode,
      redirectTo: $redirectTo,
      visitorData: $visitorData
    ) {
      name
      email
      company
      phoneNumber
    }
  }
`;