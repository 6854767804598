import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useMutation } from '@apollo/react-hooks';

import { Formik } from 'formik';

import { noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { mapFormValuesToFieldAnswers } from '@/utils/customFields';

import { ICustomField } from '@/types/customField';
import { ISettings } from '@/types/settings';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ChangesRequestedValuesContext } from '../../context';
import { otherInfoStepValidation } from './validation';
import { OtherInfoStepForm } from './form';
import { IChangesRequestedValuesAction } from '../../types';
import { UPDATE_OTHER_INFO } from '../../reducer';
import { IOtherInfoValues } from './types';
import { mapFormValuesToApplicationAttrs } from './functions';
import gql from 'graphql-tag';

export const CHANGE_REQUEST_OTHER_INFO = gql`
mutation changeRequestOtherInfo(
  $changeRequestId: ID!,
  $application: ApplicationInput!,
  $customFieldAnswers: CustomFieldAnswers!)
{
  change_request_other_info(
    changeRequestId: $changeRequestId,
    application: $application,
    customFieldAnswers: $customFieldAnswers)
  {
    customer {
      id
    }
  }
}
`;

interface IProps {
  dispatch: Dispatch<IChangesRequestedValuesAction>,
  companySettings: ISettings,
  customFields: ICustomField[],
  creditTermsOptions: string[],
  requireEinFederalTaxNumber: boolean,
  nextStepPath: string | null,
}

export const OtherInfoStep = ({
  dispatch,
  companySettings,
  customFields,
  creditTermsOptions,
  requireEinFederalTaxNumber,
  nextStepPath,
}: IProps) => {
  const history = useHistory();
  const { search } = useLocation();

  const changesRequestedValues = useContext(ChangesRequestedValuesContext);
  const changeRequest = changesRequestedValues?.changeRequest;

  const firstUpdate = useRef(true);

  const [changeRequestOtherInfo] = useMutation(CHANGE_REQUEST_OTHER_INFO);

  const submitStep = () => {

    if (!changesRequestedValues) {
      return noop;
    }

    const customFieldAnswers = mapFormValuesToFieldAnswers(changesRequestedValues.otherInfo.customFields, customFields);
    const application = mapFormValuesToApplicationAttrs(changesRequestedValues.otherInfo);

    return changeRequestOtherInfo({
      variables: {
        changeRequestId: changeRequest?.id,
        application,
        customFieldAnswers,
      }
    })
      .then(() => {
        history.push({ pathname: `${changesRequestedValues.basePath}/${nextStepPath}`, search });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep();
  }, [changesRequestedValues]);

  const dispatchValues = (values: IOtherInfoValues) => {
    dispatch({ type: UPDATE_OTHER_INFO, payload: values })
  }

  if (!changesRequestedValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={changesRequestedValues.otherInfo}
      validationSchema={otherInfoStepValidation(companySettings, customFields, requireEinFederalTaxNumber)}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <OtherInfoStepForm
        companySettings={companySettings}
        customFields={customFields}
        creditTermsOptions={creditTermsOptions}
        requireEinFederalTaxNumber={requireEinFederalTaxNumber}
      />
    </Formik>
  )
};
