import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const history = createBrowserHistory();

// listen and notify Segment of client-side page updates
let prevPath: null | string = null;
history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    window.analytics.page();
  }
});

export default history;
