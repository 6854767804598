import gql from 'graphql-tag';

export const GENERATE_DNB_REPORT = gql`
  mutation GenerateDnbReport($customerId: ID!, $duns: String!, $reportType: String!, $reportDescription: String!) {
    generateDnbReport(customerId: $customerId, duns: $duns, reportType: $reportType, reportDescription: $reportDescription) {
      id
    }
  }
`;

export const IS_DNB_CREDENTIALS_STILL_VALID = gql`
  mutation IsDnbCredentialsStillValid {
    isDnbCredentialsStillValid
  }
`;
