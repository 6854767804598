import React from 'react';
import { Formik } from 'formik';

import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { AutoSave } from '@/components/designSystem/Form/AutoSave';
import { BodyText } from '@/components/designSystem/Typography';

import { DeleteCustomer } from './DeleteCustomer';
import { DeleteCustomerInvite } from './DeleteCustomerInvite';
import { CustomerSettingsContainer, StyledForm } from './styled';
import { PopperMenuContainer } from '@/components/PopperMenu/container';

interface IInitialValues {
  autoBav: boolean,
  requirePersonalGuarantee: boolean,
}

interface IProps {
  initialValues: IInitialValues,
  onSubmit: (settings: IInitialValues, { setSubmitting }: { setSubmitting: (arg: boolean) => void }) => void,
  lastSaved: string,
  loading: boolean,
  isCustomer: boolean,
  referralId?: string,
  customerId?: string,
}

const CustomerSettings = ({
  initialValues, onSubmit, lastSaved, loading = false, isCustomer, referralId, customerId,
}: IProps) => {
  if (loading) {
    return null;
  }

  return (
    <CustomerSettingsContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <StyledForm>
          <CheckboxField name='autoBav'>
            <BodyText>Enable Automatic Bank Verification</BodyText>
          </CheckboxField>
          <CheckboxField name='requirePersonalGuarantee'>
            <BodyText>Enable Personal Guarantee</BodyText>
          </CheckboxField>
          {isCustomer ? <DeleteCustomer customerId={customerId} /> : <DeleteCustomerInvite referralId={referralId} />}
          <AutoSave lastSaved={lastSaved} debounceMs={100} />
        </StyledForm>
      </Formik>
    </CustomerSettingsContainer>
  );
};

export const CustomerSettingsMenu = (props: IProps) => (<PopperMenuContainer content={<CustomerSettings {...props} />} />);
