import React, { useState } from 'react';

import { ImportCustomerModal } from '@/modals/ImportCustomerModal';
import { Button } from '@/components/designSystem/buttons';

interface IProps {
  refetch: () => void,
}

export const ImportCustomers = ({ refetch }: IProps) => {
  const [isImportOpen, setIsImportOpen] = useState(false);

  return (
    <>
      <Button secondary onClick={() => setIsImportOpen(true)}>Import Customers</Button>
      <ImportCustomerModal onSuccess={() => refetch()} isOpen={isImportOpen} setIsOpen={setIsImportOpen} />
    </>
  )
};
