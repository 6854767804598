import React, { Dispatch, SetStateAction } from 'react';

import { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_EXPERIAN_SETTINGS } from '@/graphql/mutations/updateExperianSettings';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';
import { Callout } from '@/components/Callout/Callout';
import { BodyText } from '@/components/designSystem/Typography';

const validation = Yup.object().shape({
  username: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  password: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  subcode: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

// https://github.com/apollographql/apollo-client/pull/3892 This should be removed with an updated graphql
const extractGQLErrorMessage = (error: Error) => {
  const { 1: errorMessage } = error.message.split('GraphQL error: ');
  return errorMessage;
};

interface IFields {
  username?: string,
  password: string,
  client_id?: string,
  client_secret?: string,
  subcode?: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess?: (response: FetchResult) => void,
  username?: string,
  subcode?: string,
}

export const ExperianCredentialsModal = ({
  isOpen, setIsOpen, onSuccess, username, subcode,
}: IProps) => {
  const [updateCompanySettings, { loading }] = useMutation(UPDATE_EXPERIAN_SETTINGS);

  // eslint-disable-next-line no-shadow
  const handleSubmit = async ({
    password, client_id, client_secret
  }: IFields) => {
    const variables = {
      settings: {
        experian_username: username,
        experian_password: password,
        experian_client_id: client_id,
        experian_client_secret: client_secret,
        experian_subcode: subcode,
      },
    };

    updateCompanySettings({ variables })
      .then((response) => {
        showToast({
          title: 'Credentials Successfully Update!',
          description: 'You are now able to request Experian Credit Reports for your customers',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        showToast({
          title: 'Unauthorized',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
        setIsOpen(false);
      });
  };

  const fields = {
    username,
    password: '',
    subcode,
  };

  return (
    <StyledModalForm
      title='Login to Experian'
      submitButtonText='Confirm'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <Callout color='pastelRed'>
        <BodyText>Please ask your Experian representative for API credentials, as these credentials are different from your typical Experian login.</BodyText>
      </Callout>
      <TextField required type='text' name='username' label='Username' placeholder='you@company.com' />
      <TextField required type='password' name='password' label='Password' placeholder='*****' />
      <TextField required type='text' name='client_id' label='Experian Client ID' placeholder='Experian Client ID' />
      <TextField required type='password' name='client_secret' label='Experian Client Secret' placeholder='*****' />
      <TextField required type='text' name='subcode' label='Subcode' placeholder='Experian subocde' />
    </StyledModalForm>
  );
};
