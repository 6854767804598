import React, { useContext, useRef, useState } from 'react';
import DataGrid from "devextreme-react/data-grid";

import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { DASHBOARD_CUSTOMERS } from '@/graphql/queries/dashboardCustomers';

import { get, getOr } from 'lodash/fp';

import { CREDIT_APPLICANT_STATUSES } from '@/constants';

import { ICustomField } from '@/types/customField';
import { ICustomer } from '@/types/customer';

import { useRedirect } from '@/providers/Redirect/context';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { BodyText, H3 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { AddCustomerModal } from '@/modals/AddCustomerModal';
import { DashboardGrid } from '@/components/DashboardGrid';
import { Button } from '@/components/designSystem/buttons';

import { ExportCustomers } from './ExportCustomers';
import { ImportCustomers } from './ImportCustomers';
import { useCustomersData } from './useCustomersData';
import { COLUMNS } from './constants';
import {
  StyledButtonsContainer,
  StyledContainer,
  StyledCustomersInfo,
  StyledNewCustomerWrapper,
  StyledAccountActivationWrapper,
  StyledTableInfo,
  StyledTitleWrapper,
  StyledEmptyStateWrapper
} from './styled';
import { EmptyState } from './EmptyState';
import { ActivationMessage } from './ActivationMessage';

export const CustomersApprovedDashboard = () => {
  const { loading, data, refetch } = useQuery(DASHBOARD_CUSTOMERS, {
    variables: {status: 'approved'},
    fetchPolicy: 'cache-and-network'
  });
  const dataGridRef = useRef<DataGrid<unknown, unknown>>(null);

  const currentCompanyData = useContext(CurrentCompanyContext);

  const currentCompanyName = get('currentCompany.name', currentCompanyData);

  const customers = getOr([], 'listCustomers', data);
  const totalCustomers = customers.length;
  const customersInProgress = customers.filter((c: ICustomer) => get('latestApplication.status', c) === CREDIT_APPLICANT_STATUSES.IN_PROGRESS).length;
  const customersPending = customers.filter((c: ICustomer) => get('latestApplication.status', c) === CREDIT_APPLICANT_STATUSES.PENDING_APPROVAL).length;

  const canAddNewCustomer = true;

  const additionalQuestions = getOr([], 'listAdditionalQuestions', data);

  const columns = COLUMNS
    .concat(additionalQuestions
      .map((additionalQuestion: ICustomField) => (
        {
          dataField: additionalQuestion.id,
          caption: additionalQuestion.label,
          allowHeaderFiltering: true,
          visible: false,
        }
      ))
    )

  const [isAddingCustomerOpen, setAddingCustomerOpen] = useState(false);

  const { toCustomerPage } = useRedirect();

  const customersData = useCustomersData(data);

  const onRowClick = ({ data }: any) => toCustomerPage(data.id);

  const renderTableInfo = () => {
    if (loading) {
      return null;
    }
    return (
      <>
        <StyledCustomersInfo>
          <div>
            <BodyText bold>{totalCustomers}</BodyText> <BodyText color='secondary'>Customers</BodyText>
          </div>
          {/* { totalCustomers ? (
            <>
              <div>
                <BodyText bold>{customersInProgress}</BodyText> <BodyText color='secondary'>In progress</BodyText>
              </div><div>
                <BodyText bold>{customersPending}</BodyText> <BodyText color='secondary'>Pending approval</BodyText>
              </div>
            </>
          ) : false} */}
        </StyledCustomersInfo>
        {canAddNewCustomer ? (
          <StyledNewCustomerWrapper>
            <Button primary onClick={() => setAddingCustomerOpen(true)}>New Customer</Button>
          </StyledNewCustomerWrapper>
        ) : (
          <StyledAccountActivationWrapper>
            <ActivationMessage />
          </StyledAccountActivationWrapper>
        )}
      </>
    )
  }

  return (
    <StyledContainer>
      <StyledTitleWrapper>
        <H3 bold>Customers &gt; Approved</H3>
      </StyledTitleWrapper>
      <StyledTableInfo>
        {renderTableInfo()}
      </StyledTableInfo>
      {(!loading && !customersData.length) && (
        <StyledEmptyStateWrapper>
          <EmptyState handleInviteCustomer={() => setAddingCustomerOpen(true)} buttonsVisible={canAddNewCustomer} />
        </StyledEmptyStateWrapper>
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <DashboardGrid
            dataSource={customersData}
            columns={columns}
            onRowClick={onRowClick}
            dataGridRef={dataGridRef}
            isToolbarAfterMargin
            toolbarRightMargin={canAddNewCustomer ? '10rem' : '34rem'}
          />
          <StyledButtonsContainer>
            <ExportCustomers gridRef={dataGridRef} />
            <ImportCustomers refetch={refetch} />
          </StyledButtonsContainer>
        </>
      )}
      <AddCustomerModal
        currentCompanyName={currentCompanyName}
        isOpen={isAddingCustomerOpen}
        setIsOpen={setAddingCustomerOpen}
        onSuccess={() => refetch()}
      />
    </StyledContainer>
  )
};
