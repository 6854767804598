import { useEffect } from "react";

import { FormikErrors } from 'formik';

// Check if a string is a whole number (for array keys)
const isNumeric = (value: string) => /^-?\d+$/.test(value);

const getErrorsKeys = (errorsObject: FormikErrors<any>, parentKey?: string): string[] => {
  const keysList = []
  for (const key of Object.keys(errorsObject)) {
    if (typeof errorsObject[key] === 'object') {
      if (isNumeric(key)) {
        keysList.push(...getErrorsKeys(errorsObject[key] as FormikErrors<any>, parentKey ? `${parentKey}[${key}]` : key));
      } else {
        keysList.push(...getErrorsKeys(errorsObject[key] as FormikErrors<any>, parentKey ? `${parentKey}.${key}` : key));
      }
    } else {
      if (errorsObject[key] !== undefined) {
        keysList.push(parentKey ? `${parentKey}.${key}` : key)
      }
    }
  }
  return keysList;
};

export const useAutoscrollToError = (errors: FormikErrors<any>, isSubmitting: boolean) => {
  useEffect(() => {
    if (isSubmitting && getErrorsKeys(errors)[0]) {
      const element = document.getElementById(getErrorsKeys(errors)[0]);
      if (element) {
        element.scrollIntoView({ block: 'center'})
      }
    }
  }, [errors, isSubmitting]);
};
