import { getOr } from "lodash/fp";
import { formatPhone } from "@/utils/format";

import { IReferralData } from "@/types/referralData";
import { IManualBank } from "@/types/manualBank";

import { BANK_ACCOUNT_CATEGORY } from "@/constants";

import { IManualBankValues } from "./types";
import { ICustomer } from "@/types/customer";

export const initialManualBankAccountFields = {
  firstnameMatched: false,
  lastnameMatched: false,
  verificationStatus: '',
  verificationMessage: '',
  accountCategory: null,
  firstname: '',
  lastname: '',
  accountType: '',
  accountNumber: '',
  routingNumber: '',
}

export const initialManualBankFields = (requestBankStatements: boolean): IManualBankValues => ({
  accounts: [initialManualBankAccountFields],
  ...(requestBankStatements && { bankStatements: [] }),
  id: '',
  bankName: '',
  bankAddress: '',
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  contactTitle: '',
});

export const financialHealthInitialValues = (customerData: ICustomer) => {
  const requestBankStatements = getOr(false, 'settings.requestBankStatements', customerData);
  const manualBanks = getOr([], 'seekerCompany.manualInstitutions', customerData);
  const requireBankInfo = getOr(false, 'settings.requireBankInfo', customerData);
  const noBankInfo = getOr(false, 'latestApplication.noBankInfo', customerData);

  return {
    isManualBank: !!manualBanks.length,
    ...(!requireBankInfo && { noBankInfo: noBankInfo ?? false }),
    manualBanks: manualBanks.length
      ? manualBanks.map((bank: IManualBank) => ({
        id: bank.id,
        bankName: bank.institutionName,
        bankAddress: bank.institutionAddress,
        contactName: bank.contactName,
        contactEmail: bank.contactEmail,
        contactPhone: formatPhone(bank.contactPhone),
        contactTitle: bank.contactTitle,
        accounts: bank.accounts.map((account) => ({
          accountCategory: account.isBusinessAccount ? BANK_ACCOUNT_CATEGORY.BUSINESS : BANK_ACCOUNT_CATEGORY.PERSONAL,
          firstname: account.firstname,
          lastname: account.lastname,
          firstnameMatched: account.firstnameMatched,
          lastnameMatched: account.lastnameMatched,
          verificationStatus: account.verificationStatus,
          verificationMessage: account.verificationMessage,
          accountType: account.accountType,
          accountNumber: account.accountNumber,
          routingNumber: account.routingNumber,
        })),
        bankStatements: bank.bankStatements.map((bankStatement) => ({
          fileId: bankStatement.id,
          path: bankStatement.originalFilename,
          name: bankStatement.originalFilename,
          signedUrl: bankStatement.signedUrl,
        }))
      }))
      : [initialManualBankFields(requestBankStatements)],
  }
};
