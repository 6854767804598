import { IOnboardValues, IOnboardValuesAction } from './types';

export const UPDATE_REFERENCE = 'UPDATE_REFERENCE';
export const UPDATE_COMPLETE = 'UPDATE_COMPLETE';

export const onboardValuesReducer = (state: IOnboardValues, action: IOnboardValuesAction) => {
  const { type, payload } = action;
  switch (type) {
  case UPDATE_REFERENCE: {
    return {
      ...state,
      reference: payload,
    };
  }
  case UPDATE_COMPLETE: {
    return {
      ...state,
      complete: payload,
    };
  }
  default:
    return state;
  }
}
