import styled from 'styled-components';

import { Field } from '@/components/designSystem/Form/Field';

export const StyledField = styled(Field)`
  width: 120px;
`;

export const StyledAdminCheckboxWrapper = styled.div`
  padding-top: 0.5rem;
`;
