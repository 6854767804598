import React, { useContext } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { CREATE_STRIPE_PORTAL_URL } from '@/graphql/mutations/createStripePortalUrl';

import { get } from 'lodash/fp';

import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { H4, StyledExternalLink } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';

import { ReactComponent as RocketIllustration } from '@/assets/illustrations/rocket.svg';
import { StyledBorderedContainer, StyledPageTitle, StyledTitleCallout } from './styled';

export const BillingSettings = () => {
  const currentCompanyData = useContext(CurrentCompanyContext)
  const currentPlanId = get('currentCompany.planInfo.stripePlanId', currentCompanyData);
  const currentPlanName = get('currentCompany.planInfo.planName', currentCompanyData);
  const currentCompanyLoading = get('loading', currentCompanyData);

  const [createStripePortalUrl, { loading: portalLoad }] = useMutation(CREATE_STRIPE_PORTAL_URL, { variables: { redirectUrl: `${window.location.origin}/settings/billing` } });
  const manageBilling = async () => {
    if (!currentPlanId) {
      window.open('mailto:support@nectarinecredit.com?subject=Plan Upgrade', '_blank')?.focus();
    } else {
      const sessionResponse = await createStripePortalUrl();
      const portalUrl = get('data.createStripePortalUrl', sessionResponse);
      window.location.href = portalUrl;
    }
  };

  return (
    <Flex direction='column'>
      <StyledPageTitle bold>Your Plan</StyledPageTitle>
      <StyledTitleCallout
        Icon={<RocketIllustration />}
        title='Want To Change Your Plan?'
      >
        To modify your Nectarine Credit Plan please get in touch with one of our representatives <StyledExternalLink bold href='mailto:support@nectarinecredit.com'> here</StyledExternalLink>
      </StyledTitleCallout>
      <StyledBorderedContainer>
        <Flex direction='column'>
          <H4 bold>{currentPlanName} Plan</H4>
        </Flex>
        <Flex direction='column'>
          <Button primary onClick={manageBilling} loading={portalLoad && currentCompanyLoading}>{currentPlanId ? 'Manage Plan' : 'Upgrade Plan'}</Button>
        </Flex>
      </StyledBorderedContainer>
    </Flex>
  );
};
