import styled from 'styled-components';

import { H3, BodyText, StyledLinkButton } from '@/components/designSystem/Typography';
import { FlexColumn, FlexRow, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

import { ReactComponent as LeftArrow } from '@/assets/arrow.svg';

export const ListContainer = styled.div`
  padding-top: 0.5rem;
  padding-left: 1rem;
`;

export const CustomerUploadFormContainer = styled(FlexColumn)`
  margin-top: 1rem;

  & > * {
    margin-bottom: 1.5rem;
  }
`;

export const ColumnHeaderDragDropContainer = styled(FlexColumn)`
  & > * {
    margin-bottom: 1.5rem;
  }
`;

interface IProps {
  isDragging: boolean,
}

export const DragAreaContainer = styled(FlexRow)<IProps>`
  padding: 1rem;
  background: ${({ theme }) => theme.color.greyLight};
  flex: 1;
  opacity: ${({ isDragging }) => (isDragging ? 0.8 : 1)};
  border: ${({ theme }) => theme.color.border.light};
  border-style: dashed;
  border-width: ${({ isDragging }) => (isDragging ? '3px' : '2px')};
  border-radius: 12px;
  flex-wrap: wrap;
`;

export const ColumnDragLabel = styled(BodyText)<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  color: ${({ theme }) => theme.color.white};
  opacity: ${({ isDragging }) => (isDragging ? 0.7 : 1)};
  background: ${({ theme }) => theme.color.primary};
  margin: 0.25rem;
`;

export const ColumnHeaderConnectorContainer = styled(FlexRowSpaceBetweenItemsCenter)`
  width: 100%;
`;

export const FlexItem = styled(FlexColumn)`
  flex: 1;
`;

export const ConnectorTextContainer = styled(FlexColumn)`
  flex: 1;
  overflow: hidden;
`;

export const StyledRightArrow = styled(LeftArrow)`
  height: 2rem;
  width: 2rem;
  flex: 1;
  transform: rotate(180deg);
`;

export const StepHeader = styled(H3)`
  margin-bottom: 1rem;
`;

export const CenteredTextButton = styled(StyledLinkButton)`
  align-self: center;
`;

export const StyledMenuOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.greyLight};
  }
`;