import { get, getOr } from 'lodash/fp';
import { getWithBackup } from '@/utils/object';
import { formatPhone } from '@/utils/format';
import { mapCustomFieldsToFormInitialValues } from '@/utils/customFields';

import { IReferralData } from '@/types/referralData';
import { ICustomField } from '@/types/customField';
import { IServiceLocation } from '@/types/serviceLocation';

import { CUSTOM_FIELD_TYPES } from '@/constants';

const initialAddressFields = {
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
}

export const initialServiceLocationFields = {
  locationName: '',
  address: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  },
  email: '',
  name: '',
  position: '',
  phoneNumber: '',
}

export const companyProfileInitialValues = (refData: IReferralData) => {

  const customFieldAnswers = getOr([], 'getCustomerAsSeeker.customFieldAnswers', refData);
  const providerCompany = getOr(null, 'getCompanyById', refData);
  const customFields = getOr([], 'customFields', providerCompany);
  const additionalQuestions: ICustomField[] = customFields.filter(customField => customField.type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION);
  const shippingAddress = get('getCustomerAsSeeker.latestApplication.shippingAddress', refData);
  const billingAddress = get('getCustomerAsSeeker.latestApplication.billingAddress', refData);
  const serviceLocations: IServiceLocation[] = getOr([], 'getCustomerAsSeeker.serviceLocations', refData);

  return {
    name: getWithBackup('currentCompany.name', 'getReferralData.toCompanyName', refData) || '',
    otherNames: get('currentCompany.otherNames', refData) || '',
    type: get('currentCompany.type', refData) || '',
    address: get('currentCompany.address', refData) || initialAddressFields,
    shippingAddress: shippingAddress ? {
      address_line_1: shippingAddress.address_line_1 ?? '',
      address_line_2: shippingAddress.address_line_2 ?? '',
      city: shippingAddress.city ?? '',
      state: shippingAddress.state ?? '',
      postal_code: shippingAddress.postal_code ?? '',
      country: shippingAddress.country ?? '',
    } : initialAddressFields,
    billingAddress: billingAddress ? {
      address_line_1: billingAddress.address_line_1 ?? '',
      address_line_2: billingAddress.address_line_2 ?? '',
      city: billingAddress.city ?? '',
      state: billingAddress.state ?? '',
      postal_code: billingAddress.postal_code ?? '',
      country: billingAddress.country ?? '',
    } : initialAddressFields,
    website: get('currentCompany.website', refData) || '',
    established: get('currentCompany.established', refData) ? `${get('currentCompany.established', refData)}` : '',
    phoneNumber: get('currentCompany.phoneNumber', refData) ? formatPhone(get('currentCompany.phoneNumber', refData)) : '',
    description: get('currentCompany.description', refData) || '',
    additionalQuestions: mapCustomFieldsToFormInitialValues(additionalQuestions, customFieldAnswers),
    serviceLocations: serviceLocations.length ? serviceLocations : [initialServiceLocationFields]
  }
}
