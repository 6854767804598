import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Papa from 'papaparse';

import { get, getOr, concat, values, flow, reject, isEmpty, every, isArray } from 'lodash/fp';
import Yup from '@/utils/yup';

import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';
import { TitleCallout } from '@/components/Callout/TitleCallout';
import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ColumnHeaderDragDrop } from './ColumnHeaderDragDrop';
import { ErrorMessage } from './ErrorMessage';
import { CustomerUploadFormContainer, StepHeader, ListContainer } from './styled';
import { IRow, IHeaderMap } from './types';

interface IRowErrors {
  rowErrors: Error[],
}

const normalizeRowErrors = (rowErrors: IRowErrors) => {
  if (!isArray(rowErrors)) {
    return [];
  }

  return rowErrors.map((rowError, index) => {
    const allErrors = values(rowError);
    return { row: index + 1, message: allErrors[0] };
  });
};

interface IProps {
  setRows: (rows: IRow[]) => void,
  currentRows: IRow[],
  headerMapState: IHeaderMap,
  setHeader: (mapToUpdate: any) => void,
  headersNotLinked: string,
  validation: Yup.ObjectSchema<any>,
  clearHeaderMapState: () => void,
}

export const CustomerUploadForm = (props: IProps) => {
  const {
    setRows, currentRows, headerMapState, setHeader, headersNotLinked, validation, clearHeaderMapState,
  } = props;

  const {
    values: formValues, errors: formErrors, setFieldValue, setTouched, validateForm,
  } = useFormikContext();

  useEffect(() => {
    validateForm();
  }, [validation]);

  const signedUrl = get('csvFile[0].signedUrl', formValues);

  const [result, setResult] = useState(null);
  const validationErrors = flow(
    get('rows'),
    normalizeRowErrors,
  )(formErrors);

  const headers = getOr([], 'meta.fields', result);
  const errors = getOr([], 'errors', result);
  const rows = flow(
    getOr([], 'data'),
    reject(flow(
      values,
      every(isEmpty),
    )),
  )(result);

  const allErrors = headersNotLinked ? errors : concat(validationErrors, errors);

  useEffect(() => {
    if (rows.length !== currentRows.length) {
      setRows(rows); // TODO refactor this, a bit funky, should just use formik
      setTouched({ rows: true });
      setFieldValue('rows', rows);
    }
  }, [rows, headerMapState]);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (signedUrl) {
      setProcessing(true);
      setResult(null);
      clearHeaderMapState();
      Papa.parse(signedUrl, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (results) => {
          setProcessing(false);
          setResult(results as any);
        },
      });
    } else {
      setResult(null);
    }
  }, [signedUrl]);

  return (
    <CustomerUploadFormContainer>
      <StepHeader bold> Step 1: Upload Customer .CSV</StepHeader>
      <TitleCallout title='Bulk Customer Import'><>
        To upload multiple customers at once select a .csv file with the
        following information:
      </>
      <ListContainer>
        <li>Name</li>
        <li>Company Name</li>
        <li>Email</li>
        <li>Phone Number</li>
      </ListContainer>
      </TitleCallout>
      <FileUploadField
        name='csvFile'
        label='CSV File'
        description='Select a CSV of your customers'
        prompt='Upload .CSV'
        onlyOne
      />
      {processing && <LoadingSpinner size={25} />}
      {result && headers.length > 0 && (
        <ColumnHeaderDragDrop
          columns={headers}
          result={result}
          setHeader={setHeader}
          headerMapState={headerMapState}
        />
      )}
      {allErrors.length > 0 && <ErrorMessage errors={allErrors} />}
    </CustomerUploadFormContainer>
  );
};
