import React, { useContext } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';

import { get, getOr } from 'lodash/fp';

import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { TextField } from '@/components/designSystem/Form/TextField';

import { Section } from './Section';
import { CreditTermFields } from './CreditTermFields';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { EnableRequiredOptions } from '@/components/EnableRequiredOptions';
import { Flex, FlexColumn } from '@/components/designSystem/Layout';

const CreditLimit = () => {
  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);
  const handleUpdateCompanySettings = (variables: any) => updateCompanySettings({variables: {settings: variables}})
    .then(() => {
      showToast({
        title: 'Success!',
        description: 'Company settings Updated Successfully.',
        type: toast.TYPE.SUCCESS,
      });
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Company settings Update Failed.',
        type: toast.TYPE.ERROR,
      });
    });
  // This TextField name is fake. We need it for formik fields.
  // If you need to enable this text field at some point, you will need to fix name
  return (
    <FlexColumn style={{width: "100%"}}>
      <TextField type='text' name='creditLimit' disabled label='Credit Limit' placeholder='$23,456' />
      <EnableRequiredOptions id="credit_limit_enable_required"
        keys={{enabled: "enable_credit_limit", required: "require_credit_limit"}}
        enabled={true}
        required={true}
        handleChange={handleUpdateCompanySettings}
      />
    </FlexColumn>)
};

export const CreditTermsSection = () => {
  const currentCompanyData = useContext(CurrentCompanyContext);

  if (!currentCompanyData) {
    return <LoadingSpinner />
  }

  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);

  const creditTermsOptions = getOr([], 'currentCompany.creditTerms', currentCompanyData);

  return (
    <Section
      title='Credit Terms And Conditions'
      description='Customers can specify their prefered credit terms and conditions.'
    >
      <Flex justify="space-between" gap="1.5rem">
        <CreditLimit />
        <CreditTermFields
          creditTermsOptions={creditTermsOptions}
          refetch={refetchCurrentCompany}
        />
      </Flex>
    </Section>
  );
};
