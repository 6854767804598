import React, { FormEvent } from 'react';

import { FieldLabel } from './FieldLabel';
import {
  StyledFieldContainer,
  StyledControlledTextInput,
  StyledErrorText,
  StyledDescriptionText,
} from './styled';

export interface ITextFieldProps {
  name: string,
  label?: string,
  value: string,
  handleChange: (e: FormEvent<HTMLInputElement>) => void,
  placeholder?: string,
  description?: string,
  required?: boolean,
  disabled?: boolean,
  error?: string,
}

export const TextInput = ({
  required = false, disabled = false, name, label, value, handleChange, placeholder = '', description, error
}: ITextFieldProps) => (
  <StyledFieldContainer>
    {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
    <StyledControlledTextInput
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      error={!!error}
    />
    {description && <StyledDescriptionText>{description}</StyledDescriptionText>}
    <StyledErrorText>{error}</StyledErrorText>
  </StyledFieldContainer>
);
