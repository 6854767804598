import React from 'react';

import { useFormikContext } from 'formik';

import { Button } from '@/components/designSystem/buttons';
import { BodyText, H3 } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';

import { IAddTextCustomFieldValues } from '.';
import {
  StyledPreviewFieldContainer,
  StyledForm,
  StyledFormInnerContainer,
  StyledLeftFormContainer
} from '../styled';
import { FooterContainer } from '../../StyledModal/styled';
import { CustomFieldPreviewContainer } from '../CustomFieldPreviewContainer';
import { AddCustomFieldBackButton } from '../BackButton';

export const AddFileUploadCustomFieldForm = () => {
  const { values } = useFormikContext<IAddTextCustomFieldValues>()

  return (
    <StyledForm>
      <StyledFormInnerContainer>
        <StyledLeftFormContainer>
          <AddCustomFieldBackButton />
          <TextField required type='text' name='label' label='Label' />
          <CheckboxField name='allowMultipleUploads'><BodyText>Allow multiple files</BodyText></CheckboxField>
          <TextAreaField name='description' label='Description' />
          <CheckboxField name='required'><BodyText>Make this field required</BodyText></CheckboxField>
        </StyledLeftFormContainer>
        <CustomFieldPreviewContainer>
          <H3>Credit Applicant will see:</H3>
          <StyledPreviewFieldContainer>
            <FileUploadField
              required={values.required}
              name='example'
              label={values.label || 'Example Label'}
              description={values.description}
              onlyOne={!values.allowMultipleUploads}
            />
          </StyledPreviewFieldContainer>
        </CustomFieldPreviewContainer>
      </StyledFormInnerContainer>
      <FooterContainer>
        <Button wide primary type='submit'>Add Custom Field</Button>
      </FooterContainer>
    </StyledForm>
  )
};
