import gql from 'graphql-tag';

export const GET_EXPERIAN_RESULTS = gql`
  query getExperianResults($searchParams: ExperianSearchInput!) {
    getExperianResults(searchParams: $searchParams) {
      results {
        address {
          city
          zip
          state
          street
        }
        bin
        business_name
        reliability_code
        number_of_tradelines
      }
    }
  }
`;
