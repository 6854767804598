import React, { useState } from 'react';

import { getOr } from 'lodash/fp';

import { IUser, ICompanyUser } from '@/types/companyUser';
import { ICurrentCompany } from '@/types/currentCompany';

import { InviteUserModal } from '@/modals/InviteUserModal';
import { BorderedContainer } from '@/containers/BorderedContainer';
import { H2 } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { User } from './User';
import { PageTitleContainer } from '../styled';

export interface IPendingUser {
  name: string,
  email: string,
  pending: boolean,
}

interface IProps {
  refetchCompany: () => void,
  company: ICurrentCompany,
  currentUser: ICompanyUser,
  parentCompany: ICurrentCompany
}

export const UsersSection
 = ({ currentUser, company, refetchCompany }: IProps) => {
   const [isOpen, setIsOpen] = useState(false);

   const users: IUser[] = getOr([], 'listUsers', company);
   const usersEmails = users.map((user: IUser) => user.email)

   return (
     <>
       <PageTitleContainer>
         <H2 bold>Users</H2>
         <Button onClick={() => setIsOpen(true)} LeftIcon={Plus} primary>Invite User</Button>
       </PageTitleContainer>
       <InviteUserModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetchCompany} existingUsersEmails={usersEmails} />

       <BorderedContainer>
         {
           users.map((user: IUser) => (
             <User
               currentUser={currentUser}
               company={company}
               user={user}
               key={user.id || user.referralId}
               refetchCompany={refetchCompany}
             />
           ))
         }
       </BorderedContainer>
     </>
   );
 };
