import React, { useEffect } from 'react';
import { useField } from 'formik';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
// import Highlight from '@tiptap/extension-highlight';
// import Typography from '@tiptap/extension-typography';
// import Underline from '@tiptap/extension-underline';
// import CharacterCount from '@tiptap/extension-character-count';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
// import Youtube from '@tiptap/extension-youtube';
// import TextAlign from '@tiptap/extension-text-align';
// import Focus from '@tiptap/extension-focus';
// import TaskItem from '@tiptap/extension-task-item';
// import TaskList from '@tiptap/extension-task-list';
// import Superscript from '@tiptap/extension-superscript';
// import Subscript from '@tiptap/extension-subscript';
// import Placeholder from '@tiptap/extension-placeholder';

import { ToolBar } from './ToolBar';
import { StyledEditorWrapper, StyledEditorContent } from './styled';

interface TiptapProps {
  name: string;
  // placeholder?: string;
  // content: string | undefined;
  // onChange: (content: string) => void;
}

const Tiptap: React.FC<TiptapProps> = ({
  name,
  // placeholder,
  // content,
  // onChange,
}) => {
  const [field, , helpers] = useField(name);
  // const limit = 5000;
  
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        codeBlock: false,
      }),
      // Subscript,
      // Superscript,
      // Highlight,
      // Typography,
      // Underline,
      Link.configure({
        openOnClick: false,
      }),
      // TaskList,
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      // TaskItem.configure({
      //   nested: true,
      // }),
      // CharacterCount.configure({
      //   limit,
      // }),
      // TextAlign.configure({
      //   defaultAlignment: 'left',
      //   types: ['heading', 'paragraph'],
      // }),
      // Youtube.configure({
      //   controls: false,
      // }),
      // Placeholder.configure({
      //   placeholder: placeholder,
      // }),
    ],
    content: field.value,
    onUpdate: ({ editor }) => {
      helpers.setValue(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && field.value !== editor.getHTML()) {
      editor.commands.setContent(field.value);
    }
  }, [field.value, editor]);

  return (
    <StyledEditorWrapper>
      {editor && <ToolBar editor={editor} />}
      <StyledEditorContent editor={editor} />
    </StyledEditorWrapper>
  );
};

export default Tiptap;
