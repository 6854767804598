import { useMutation } from '@apollo/react-hooks';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { ADD_PROVIDER_COMMENT } from '@/graphql/mutations/addProviderComment';
import Yup from '@/utils/yup';

interface IValues {
  comment: string,
}

export const useCommentsForm = (
  applicationId: string,
  currentUserId: string,
  refetch: () => void,
) => {

  const fields = {
    comment: "",
  }

  const [providerAddInternalComment, { loading }] = useMutation(ADD_PROVIDER_COMMENT);

  const handleSubmit = async (values: IValues, { resetForm }: any) => {
    providerAddInternalComment({ variables: {
      comment: values.comment,
      applicationId: applicationId,
      userId: currentUserId,
    } })
      .then(() => {
        showToast({
          title: 'Information Successfully Updated!',
          description: 'Comment has been added successfully.',
          type: toast.TYPE.SUCCESS,
        });
        refetch();
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Information wasn\'t updated',
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        resetForm();
      });
  };

  const validation = Yup.object().shape({
    comment: Yup.string(),
  });

  return {
    fields,
    validation,
    handleSubmit,
    loading
  };
}