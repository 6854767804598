import { getOr } from "lodash/fp";

import { IReferralData } from "@/types/referralData";
import { IVRCustomField } from "@/types/vrCustomField";
import { formatPhone } from "@/utils/format";

export const completeInitialValues = (refData: IReferralData, customFields: IVRCustomField[]) => {
  const vendorCompanyName = getOr('', 'getReferralData.toCompanyName', refData);
  const vendorName = getOr('', 'getReferralData.toName', refData);
  const vendorEmail = getOr('', 'getReferralData.toEmail', refData);
  const vendorPhoneNumber = formatPhone(getOr('', 'getReferralData.toPhoneNumber', refData));

  const customFieldsValues = customFields
    .map((customField) => customField.name)
    .reduce((acc, customFieldName) => ({
      ...acc,
      [customFieldName]: ''
    }), {});

  return {
    company: vendorCompanyName,
    name: vendorName,
    email: vendorEmail,
    phoneNumber: vendorPhoneNumber || '',
    ...customFieldsValues,
  }
};
