import { formatPhoneNumberForRequest } from "@/utils/format";

import { IVendorValues } from "./types";

export const mapChangesRequestedValuesToVendorReferences = (
  requiredVendors: IVendorValues[],
  optionalVendors: IVendorValues[]
) => requiredVendors.concat(optionalVendors)
  .map((vendor) => ({
    ...vendor,
    phoneNumber: formatPhoneNumberForRequest(vendor.phoneNumber)
  }))
  .filter((vendor) => !Object.values(vendor).some(x => x === ''));
