import { IconCell, StatusCell, DollarCell, DateCell } from "@/components/DashboardGrid/cells";

export const COLUMNS = [
  {
    dataField: 'company',
    caption: 'Company',
    allowHiding: false,
    allowHeaderFiltering: false,
    minWidth: 160,
    width: '20%',
  },
  {
    dataField: 'status',
    caption: 'Status',
    cellRender: StatusCell,
    minWidth: 175,
  },
  {
    dataField: 'creditLimit',
    caption: 'Credit Limit',
    cellRender: DollarCell,
    allowHeaderFiltering: false,
    alignment: 'left',
    minWidth: 80,
  },
  {
    dataField: 'creditTerms',
    caption: 'Credit Terms',
    allowHeaderFiltering: false,
    minWidth: 100,
  },
  {
    dataField: 'assignedTo',
    caption: 'Assigned To',
    allowHeaderFiltering: true,
    minWidth: 100,
  },
  {
    dataField: 'lastUpdatedBy',
    caption: 'Last Updated By',
    allowHeaderFiltering: false,
    minWidth: 110,
  },
  {
    dataField: 'lastUpdatedOn',
    caption: 'Last Updated On',
    dataType: 'date',
    cellRender: DateCell,
    allowHeaderFiltering: false,
    minWidth: 110,
  },
  {
    dataField: 'dateApproved',
    caption: 'Approved On',
    dataType: 'date',
    cellRender: DateCell,
    allowHeaderFiltering: false,
    minWidth: 130,
  },
  // {
  //   dataField: 'vendorReference',
  //   caption: 'Vendor Reference',
  //   cellRender: IconCell,
  //   allowHeaderFiltering: false,
  //   minWidth: 90,
  // },
  // {
  //   dataField: 'bankReference',
  //   caption: 'Bank reference',
  //   cellRender: IconCell,
  //   allowHeaderFiltering: false,
  //   minWidth: 90,
  // }
];
