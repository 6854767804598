import { RefObject, useState } from 'react';

export const useScroll = (containerRef: RefObject<HTMLDivElement>) => {
  const [isScrollArrowUp, setScrollArrowUp] = useState(false);

  const handleScroll = () => {
    if (!containerRef.current) return;
    if (containerRef.current.scrollTop + containerRef.current.offsetHeight >= containerRef.current.scrollHeight) {
      setScrollArrowUp(true);
    } else {
      setScrollArrowUp(false);
    }
  };

  const handleScrollArrowClick = () => {
    if (isScrollArrowUp) {
      containerRef.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    } else {
      containerRef.current?.scrollTo({
        top: containerRef.current?.offsetHeight,
        behavior: "smooth",
      })
    }
  };

  return {
    isScrollArrowUp,
    handleScroll,
    handleScrollArrowClick,
  }
};
