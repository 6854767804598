import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { GET_OWN_CUSTOM_FIELDS } from '@/graphql/queries/getOwnCustomFields';

import { getOr } from 'lodash/fp';

import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { AddCustomFieldModal } from '@/modals/AddCustomField';

import { CustomFieldItem } from './CustomFieldItem';
import { StyledContainer, StyledCustomFieldsContainer } from './styled';
import { ICustomField } from '@/types/customField';

export const CreditApplicationCustomizableFields = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data, refetch } = useQuery(GET_OWN_CUSTOM_FIELDS, {variables: {kind: "review"}});
  console.log(refetch, 'refetch');

  const customFields = getOr([], 'listCustomFields', data);

  return (
    <StyledContainer>
      <BodyText>
        Below are some customizable fields you can use to request more information from your customers. We've included three default examples you could use.
      </BodyText>
      <StyledCustomFieldsContainer>
        {
          customFields.map((customField: ICustomField) => (
            <CustomFieldItem key={customField.id} parentKey='customFields' {...customField} refetch={refetch} />
          ))
        }
      </StyledCustomFieldsContainer>
      <Button primary onClick={() => setIsOpen(true)}>Add Field</Button>
      <AddCustomFieldModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} kind="REVIEW" />
    </StyledContainer>
  );
};
