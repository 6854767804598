import { Dispatch, SetStateAction } from 'react';
import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';
import { useMutation } from '@apollo/react-hooks';
import { CONVERT_TO_MANUAL } from '@/graphql/mutations/convertToManual';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { useHistory } from 'react-router-dom';

interface IFile {
  fileId: string
  path: string
  signedUrl: string
  name: string
  type: string
}

export interface IManualCustomerFormValues {
  signatureRequiredCreditTerms: boolean,
  signatureRequiredPersonalGuarantee: boolean,
  customMessage: string,
  file: IFile[]
  aware: boolean,
}

export const useForm = (
  customerId: string,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  refetch: () => void,
) => {

  const [convertToManual] = useMutation(CONVERT_TO_MANUAL);
  const history = useHistory();

  const customerValidation = Yup.object().shape({
    signatureRequiredCreditTerms: Yup.boolean(),
    signatureRequiredPersonalGuarantee: Yup.boolean(),
    customMessage: Yup.string(),
    file: Yup.array(),
    aware: Yup.boolean().oneOf([true], DEFAULT_ERROR_MESSAGES.REQUIRED),
  });

  const customerFields = {
    signatureRequiredCreditTerms: false,
    signatureRequiredPersonalGuarantee: false,
    customMessage: '',
    file: [],
    aware: false,
  };

  const handleSubmit = async ({
    signatureRequiredCreditTerms, signatureRequiredPersonalGuarantee, customMessage, file
  }: IManualCustomerFormValues) => {
    const manualCustomer = {
      signatureRequiredCreditTerms,
      signatureRequiredPersonalGuarantee,
      customMessage,
    };
    const file_ids = file.map(eachfile => (eachfile.fileId));
    convertToManual({ variables: { manualCustomerInput: manualCustomer, uploadedFileIds: file_ids, customerId: customerId} })
      .then(() => {//response
        setIsOpen(false);
        showToast({
          title: 'Converted to Manual.',
          description: `Customer converted to a manual customer.`,
          type: toast.TYPE.SUCCESS,
        });
        refetch()
        history.push('/dashboard/customers/'+customerId)//response.data.addManualCustomer
      })
      .catch((err) => console.log(err));
  };

  return {
    customerValidation,
    customerFields,
    handleSubmit,
  }
}