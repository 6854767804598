import { Dispatch, useEffect, useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { IS_EMAIL_VERIFIED } from "@/graphql/queries/isEmailVerified";

import { UPDATE_EMAIL_VERIFIED } from "../../reducer";
import { IOnboardValues, IOnboardValuesAction } from "../../types";

export const useEmailVerified = (onboardValues: IOnboardValues | null, dispatch: Dispatch<IOnboardValuesAction>) => {
  const [isEmailVerified, setEmailVerified] = useState(false)

  const {
    data: emailVerifiedData,
    refetch: refetchEmailVerification
  } = useQuery(
    IS_EMAIL_VERIFIED,
    { skip: onboardValues?.emailVerified }
  );

  useEffect(() => {
    if (emailVerifiedData) {
      if (emailVerifiedData.isEmailVerified) {
        setEmailVerified(true);
        dispatch({ type: UPDATE_EMAIL_VERIFIED, payload: true });
      }
    }
  }, [emailVerifiedData]);

  useEffect(() => {
    if (onboardValues?.emailVerified) {
      setEmailVerified(true);
    }
  }, [onboardValues?.emailVerified]);

  return {
    emailVerifiedData,
    isEmailVerified,
    refetchEmailVerification
  }
};
