import React, { useState } from 'react';

import { noop } from 'lodash/fp';

import { UpgradeModal } from '@/modals/UpgradeModal';

import { UpgradeContext } from './context';

let openUpgrade = noop;

interface IProps {
  children: React.ReactNode,
}

const UpgradeProvider = ({
  children,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  openUpgrade = open;

  return (
    <UpgradeContext.Provider
      value={{
        open,
        close,
      }}
    >
      {children}
      <UpgradeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </UpgradeContext.Provider>
  );
};

export {
  UpgradeProvider,
  openUpgrade,
};
