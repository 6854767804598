import { get } from "lodash/fp";

import { IApplication } from '@/types/application';

export const getActionsInitialValues = (application: IApplication) => {
  const status = get('status', application);
  const providerComment = get('providerComment', application) || '';

  return {
    status,
    providerComment
  }
};
