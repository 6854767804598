import React, { useContext } from 'react';

import { noop } from 'lodash/fp';

export interface IUpgradeContextValue {
    open: () => void,
    close: () => void,
  }

const defaultValue = {
  open: noop,
  close: noop,
}

export const UpgradeContext = React.createContext<IUpgradeContextValue>(defaultValue);
export const useUpgrade = () => useContext(UpgradeContext);