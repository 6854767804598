import React from 'react';

import { MutationFunctionOptions, useMutation } from '@apollo/react-hooks';
import { UPDATE_VR_CUSTOM_FIELDS } from '@/graphql/mutations/updateVRCustomFields';

import { IVRCustomField } from '@/types/vrCustomField';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { EnableRequiredOptions, IHandleEnableRequiredOptionsChangeArgs } from '@/components/EnableRequiredOptions';

import { StyledCustomFieldRow } from './styled';

const KEYS = {
  enabled: 'enabled',
  required: 'required',
}

interface IProps {
  customFields: IVRCustomField[],
  refetch: () => void,
}

export const VendorReferenceCustomFields = ({ customFields, refetch }: IProps) => {
  const [updateVRCustomFields] = useMutation(UPDATE_VR_CUSTOM_FIELDS);

  const handleChange = (id: string, state: any) => {
    const updatedField = {
      id,
      ...state,
    };
    handleUpdateVRCustomFields({ variables: { vrCustomFields: updatedField } });
  };

  const handleUpdateVRCustomFields = (variables: MutationFunctionOptions) => {
    updateVRCustomFields(variables)
    .then(() => {
      showToast({
        title: 'Information Successfully Updated!',
        description: 'Vendor Reference Custom Field updated successfully.',
        type: toast.TYPE.SUCCESS,
      });
      refetch();
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Information wasn\'t updated',
        type: toast.TYPE.ERROR,
      });
    })
  }


  return (
    <div>
      {customFields.map((customField: IVRCustomField, index: number) => (
        <StyledCustomFieldRow key={customField.id} first={index === 0}>
          <EnableRequiredOptions
            key={customField.id}
            id={customField.id}
            keys={KEYS}
            label={customField.label}
            enabled={customField.enabled}
            required={customField.required}
            handleChange={(state) => handleChange(customField.id, state)}
          />
        </StyledCustomFieldRow>
      ))}
    </div>
  )};
