import React from 'react';

import { ReactComponent as NectarineLogo } from '@/assets/logo_full.svg';

import { StyledLogoContainer } from './styled';

interface IProps {
  overriddenLogo: React.ReactNode | null,
  opacity?: number,
}

export const OnboardLogo = ({ overriddenLogo, opacity }: IProps) => (
  <StyledLogoContainer opacity={opacity}>
    {overriddenLogo || <NectarineLogo />}
  </StyledLogoContainer>
);
