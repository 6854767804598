import React from 'react';
import get from 'lodash/fp/get';
import { useParams } from 'react-router';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ChangesRequestedContainer } from './container';
import { useQuery } from '@apollo/react-hooks';
import { ONBOARDING_PATH } from '@/constants';
import { GET_CHANGE_REQUEST } from './getChangeRequest';

interface IParams { changeRequestId: string }

export const ChangesRequested = () => {
  const { changeRequestId } = useParams<IParams>();

  const { data } = useQuery(
    GET_CHANGE_REQUEST,
    { variables: { changeRequestId }, fetchPolicy: 'no-cache' }
  );
  const changeRequestData = get('getChangeRequest', data);
  const customer = get('customer', changeRequestData);
  const customerId = get('id', customer);
  const basePath = `${ONBOARDING_PATH.CHANGES_REQUESTED}/${changeRequestId}`;

  if (!changeRequestData) {
    return <LoadingSpinner />;
  }

  return (
    <ChangesRequestedContainer
      changeRequest={changeRequestData}
      basePath={basePath}
      customerId={customerId} />
  )
};
