import { RouteComponentProps } from 'react-router-dom';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { writeStorage } from '@rehooks/local-storage';

import { MutationFunction } from '@apollo/react-hooks';

import { get, noop } from 'lodash/fp';
import { formatPhoneNumberForRequest } from '@/utils/format';
import { stripStepFromPath } from '@/utils/stripStepFromPath';

import { IAddressComponent, IGooglePlace } from '@/types/googlePlace';
import { IRefDataCurrentCompany } from '@/types/referralData';
import { ICompanyUser } from '@/types/companyUser';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { ICAOnboardOptions } from '@/providers/Auth0/context';

import { IOnboardValues } from '../../types';
import { IOnboardCreditApplicantVariables } from './types';
import { IServiceLocation } from '@/types/serviceLocation';

const mapGooglePlaceToValues = (addressComponents: IAddressComponent[]) => ({
  state: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_1'))?.long_name ||
    addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_2'))?.long_name || '',
  city: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('locality'))?.long_name ||
    addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_town'))?.long_name || '',
  postalCode: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_code'))?.long_name || '',
  country: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('country'))?.short_name || null,
  streetNumber: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('street_number'))?.long_name || '',
  street: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('route'))?.long_name || '',
});

export const setFieldsFromGooglePlace = (place: IGooglePlace, parentKey: string, setFieldValue: (field: string, value: string | null) => void) => {
  if (place.address_components) {
    const addressValues = mapGooglePlaceToValues(place.address_components);
    setFieldValue(`${parentKey}.address.postal_code`, addressValues.postalCode);
    setFieldValue(
      `${parentKey}.address.address_line_1`,
      `${addressValues.streetNumber}${addressValues.street && ' '}${addressValues.street}`
    );
    setFieldValue(`${parentKey}.address.city`, addressValues.city)
    setFieldValue(`${parentKey}.address.state`, addressValues.state);
    setFieldValue(`${parentKey}.address.country`, addressValues.country);
  }
};

const mapOnboardValuesToStakeholders = (onboardValues: IOnboardValues) => {

  const {
    basicInfo,
    companyContacts
  } = onboardValues;

  return [
    ...(companyContacts.isCurrentUserOwner
      ? []
      : [{
        name: basicInfo.name,
        email: basicInfo.email,
        phoneNumber: formatPhoneNumberForRequest(basicInfo.phoneNumber),
        position: basicInfo.position,
        isOwner: false,
      }]
    ),
    ...companyContacts.owners.map((owner) => ({
      ...owner,
      ...(owner.address && { address: JSON.stringify(owner.address) }),
      ...(owner.dob && { dob: new Date(owner.dob).toISOString() }),
      phoneNumber: formatPhoneNumberForRequest(owner.phoneNumber),
      ownershipPercentage: +owner.ownershipPercentage,
      isOwner: true,
    })),
    ...(companyContacts.additionalContacts
      ? companyContacts.additionalContacts
      // Remove empty contacts
        .filter((contact) => !Object.values(contact).every(x => x === ''))
        .map((additionalContact) => ({
          name: additionalContact.name,
          email: additionalContact.email,
          extension: additionalContact.extension,
          phoneNumber: formatPhoneNumberForRequest(additionalContact.phoneNumber),
          position: additionalContact.position,
        }))
      : []
    ),
    ...(Object.keys(companyContacts.customContacts).length
      ? Object.keys(companyContacts.customContacts).map((customContactFieldId) => ({
        ...companyContacts.customContacts[customContactFieldId],
        customFieldId: customContactFieldId,
        phoneNumber: formatPhoneNumberForRequest(companyContacts.customContacts[customContactFieldId].phoneNumber ?? '')
      }))
      : []
    )
  ]
}

const transformServiceLocationAddress = (serviceLocations: IServiceLocation[]) => {
  return serviceLocations.map((serviceLocation: IServiceLocation) => {
    return {
      locationName: serviceLocation.locationName,
      address: JSON.stringify(serviceLocation.address),
      email: serviceLocation.email,
      phoneNumber: serviceLocation.phoneNumber,
      name: serviceLocation.name,
      position: serviceLocation.position
    }
  })
}

export const mapOnboardValuesToOnboardData = (onboardValues: IOnboardValues, referralPath: string) => {
  const {
    basicInfo,
    companyProfile,
  } = onboardValues;

  return {
    onboardData: {
      currentUser: {
        name: basicInfo.name,
        email: basicInfo.email,
        phoneNumber: formatPhoneNumberForRequest(basicInfo.phoneNumber),
        position: basicInfo.position,
        referralPath,
      },
      company: {
        name: companyProfile.name,
        type: companyProfile.type,
        description: companyProfile.description,
        established: +companyProfile.established,
        phoneNumber: formatPhoneNumberForRequest(companyProfile.phoneNumber),
        address: JSON.stringify(companyProfile.address),
        website: companyProfile.website,
        otherNames: companyProfile.otherNames,
      },
      shipping_address: JSON.stringify(companyProfile.shippingAddress),
      billing_address: JSON.stringify(companyProfile.billingAddress),
      stakeholders: mapOnboardValuesToStakeholders(onboardValues),
      serviceLocations: transformServiceLocationAddress(companyProfile.serviceLocations || []),
    },
    customFieldAnswers: [
      ...(Object.keys(companyProfile.additionalQuestions).length
        ? Object.keys(companyProfile.additionalQuestions).map((additionalQuestionFieldId) => ({
          text: companyProfile.additionalQuestions[additionalQuestionFieldId],
          customFieldId: additionalQuestionFieldId,
        }))
        : []
      )
    ]
  }
};

interface IOnboardCreditApplicantWithNoCompanyArgs {
  history: RouteComponentProps['history'],
  onboardValues: IOnboardValues | null,
  onboardVariables: IOnboardCreditApplicantVariables,
  visitorData: ExtendedGetResult | null,
  providerCompanyId: string,
  referralCode: string | null,
  onboardCreditSeekerMutation: MutationFunction,
}

const onboardCreditApplicantWithNoCompany = async ({
  history,
  onboardValues,
  onboardVariables,
  visitorData,
  referralCode,
  providerCompanyId,
  onboardCreditSeekerMutation
}: IOnboardCreditApplicantWithNoCompanyArgs) => {
  if (!onboardValues) {
    return;
  }

  onboardCreditSeekerMutation({
    variables: {
      onboardData: onboardVariables.onboardData,
      customFieldAnswers: onboardVariables.customFieldAnswers,
      visitorData,
      referralCode,
      providerCompanyId,
      redirectTo: `${onboardValues.basePath}/bank`,
    },
  })
    .then((onboardResponse) => {
      const seekerCompanyId = get('data.onboardCreditSeeker.seekerCompany.id', onboardResponse);
      const newCustomerId = get('data.onboardCreditSeeker.customer.id', onboardResponse);
      writeStorage('company-id', seekerCompanyId);
      writeStorage('customer-id', newCustomerId);

      history.push({ pathname: `${onboardValues.basePath}/bank`, search: `?cid=${newCustomerId}` });
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Something went wrong',
        type: toast.TYPE.ERROR,
      });
    });
}

interface ISubmitStepArgs {
  onboardValues: IOnboardValues | null,
  history: RouteComponentProps['history'],
  pathname: string,
  search: string,
  currentCompany: IRefDataCurrentCompany | null,
  currentUser: ICompanyUser,
  visitorData: ExtendedGetResult | null,
  providerCompanyId: string,
  referralCode: string | null,
  signUpOrLoginCreditApplicant: (
    name: string, email: string, password: string, onboardOptions: ICAOnboardOptions
  ) => Promise<unknown>,
  onboardCreditApplicantMutation: MutationFunction,
  updateOnboardMutation: MutationFunction,
}

export const submitStep = ({
  onboardValues,
  history,
  pathname,
  search,
  currentUser,
  currentCompany,
  visitorData,
  referralCode,
  providerCompanyId,
  signUpOrLoginCreditApplicant,
  onboardCreditApplicantMutation,
  updateOnboardMutation,
}: ISubmitStepArgs) => {

  if (!onboardValues) {
    return noop;
  }

  const { basicInfo: { name, email, password } } = onboardValues;

  const onboardVariables = mapOnboardValuesToOnboardData(
    onboardValues,
    `${stripStepFromPath(pathname)}${search}`
  );

  if (currentUser && currentCompany) {
    // TODO handle the case of the missing customer id
    return updateOnboardMutation(
      { variables: { onboardData : onboardVariables.onboardData, visitorData } }
    )
      .then(
        () => history.push({ pathname: `${onboardValues.basePath}/bank` })
      );
  } else if (currentUser && !currentCompany) {
    console.error(`Unexpected State, logged in as user id: ${currentUser.id}, with no company`);
    return onboardCreditApplicantWithNoCompany({
      history,
      onboardValues,
      onboardVariables,
      visitorData,
      referralCode,
      providerCompanyId,
      onboardCreditSeekerMutation: onboardCreditApplicantMutation,
    });
  } else {
    return signUpOrLoginCreditApplicant(
      name,
      email,
      password,
      { onboardVariables, onboardValues, visitorData, referralCode, providerCompanyId }
    ).then((onboardResponse) => {
      const seekerCompanyId = get('onboardCreditSeeker.seekerCompany.id', onboardResponse);
      const newCustomerId = get('onboardCreditSeeker.customer.id', onboardResponse);
      writeStorage('company-id', seekerCompanyId);
      writeStorage('customer-id', newCustomerId);

      history.push({ pathname: `${onboardValues.basePath}/bank`, search: `?cid=${newCustomerId}` });
    })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }
}
