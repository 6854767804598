import gql from 'graphql-tag';

export const UPSERT_VENDOR_REFERENCES = gql`
  mutation UpsertVendorReferences(
    $customerId: ID!
    $vendorReferences: [VendorReferenceReferralInput!]!
    $blockSend: Boolean
    $deleteExisting: Boolean
    $forCompanyId: ID!
    $visitorData: VisitorData
  ) {
    upsert_vendor_references(
      customerId: $customerId,
      vendor_references: $vendorReferences,
      block_send: $blockSend,
      delete_existing: $deleteExisting,
      forCompanyId: $forCompanyId,
      visitorData: $visitorData) {
      customer { id }
      step
    }
  }
`;
