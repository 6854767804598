import React from 'react';

import {
  StepNodeContainer,
  StepBadge,
  StepTitle,
} from './styled';

interface IProps {
    title: string,
    index: number,
    activeStep: number,
    activeColor?: string,
}

export const StepNode = ({ title, index, activeStep, activeColor }: IProps) => {
  const isComplete = index < activeStep;
  const isActive = index === activeStep;

  const badgeIcon = isComplete ? '✔' : (index + 1);

  return (
    <StepNodeContainer isActive={isActive} complete={isComplete}>
      <StepBadge isActive={isActive} complete={isComplete} activeColor={activeColor}>
        {badgeIcon}
      </StepBadge>
      <StepTitle isActive={isActive} complete={isComplete} activeColor={activeColor}>
        {title}
      </StepTitle>
    </StepNodeContainer>
  );
};
