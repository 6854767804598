import React from 'react';

import { SelectField } from '@/components/designSystem/Form/SelectField';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

import { MANUAL_ACCOUNT_TYPES } from '@/constants';

import { StyledClose } from './styled';

export interface IManualAccountFields {
    account_type: string,
    account_number: string,
    routing_number: string,
  }

interface IProps {
  parentKey: string,
  index: number,
  arrayHelpers: any, // Need to figure out arrayHelpers.remove(index)
}

export const AccountSection = ({ parentKey, index, arrayHelpers }: IProps) => {
  // ??
  const removeSelf = () => arrayHelpers.remove(index);

  return (
    <div>
      <Row>
        <SelectField required name={`${parentKey}.account_type`} label='Account Type' options={MANUAL_ACCOUNT_TYPES} />
        <TextField required type='text' name={`${parentKey}.routing_number`} label='Routing/Transit Number' placeholder='e.g. 123456789' />
        <TextField required type='text' name={`${parentKey}.account_number`} label='Account Number' placeholder='e.g. 000123456789' />
        {index > 0 && <StyledClose onClick={removeSelf} />}
      </Row>
    </div>
  );
};
