import React from 'react';

import {
  PageGrid,
  ContentContainer,
  MarketingSectionContainer,
  StyledLogo,
  InnerContentContainer,
} from './styled';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

interface IProps {
  children: React.ReactNode,
}

const GET_CURRENT_COMPANY = gql`
  query GetCompanyById($companyId: ID!) {
    getCompanyById(companyId: $companyId) {
      id
      brand {
        primaryColor
        secondaryColor
        logo {
          path
        }
      }
    }
  }
`;

const MarketingSection = () => {
  const { data } = useQuery(GET_CURRENT_COMPANY, {
    variables: {companyId: '047a25a7-14ca-4604-9793-fe7bf47e64ca'}
  });
  return <MarketingSectionContainer />
}

const Content = ({ children }: IProps) => (
  <ContentContainer>
    <InnerContentContainer>
      <StyledLogo />
      {children}
    </InnerContentContainer>
  </ContentContainer>
);

export const LoginContainer = ({ children }: IProps) => (
  <PageGrid>
    <Content>
      {children}
    </Content>
    <MarketingSection />
  </PageGrid>
);
