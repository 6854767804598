import React from 'react';

import { FieldArray, useFormikContext } from 'formik';

import { ICustomField } from '@/types/customField';

import { H2, H3, H4 } from '@/components/designSystem/Typography';
import { FlexRowEnd } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { OwnerSection } from './OwnerSection';
import { initialOwnerFields, initialAdditionalContactFields } from './initialValues';
import { CustomContactSection } from './CustomContactSection';
import { AdditionalContactSection } from './AdditionalContactSection';
import { IAdditionalPersonalInformationFields, ICompanyContactsValues } from './types';
import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm,
  StyledSubtitleContainer,
} from '../../styled';
import { useAutoscrollToError } from '../../../useAutoscrollToError';

interface IProps {
  enableOwnershipStake: boolean,
  additionalPersonalInformationFields: IAdditionalPersonalInformationFields,
  customContactFields: ICustomField[],
}

export const CompanyContactsStepForm = ({
  enableOwnershipStake,
  additionalPersonalInformationFields,
  customContactFields,
}: IProps) => {
  const { values, errors, isSubmitting } = useFormikContext<ICompanyContactsValues>();

  useAutoscrollToError(errors, isSubmitting);

  return (
    <StyledForm>
      <StyledTitleContainer>
        <H2 bold>Company Contacts</H2>
      </StyledTitleContainer>
      <StyledSubtitleContainer>
        <H3 bold>Owners</H3>
        <H4>Enter the majority owners of the company</H4>
      </StyledSubtitleContainer>
      <FieldArray
        name='owners'
        render={(arrayHelpers) => (
          <>
            {
              values.owners.map((_owner, index) => (
                <OwnerSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  parentKey={`owners[${index}]`}
                  onRemoveClick={() => arrayHelpers.remove(index)}
                  additionalPersonalInformationFields={additionalPersonalInformationFields}
                  enableOwnershipStake={enableOwnershipStake}
                />
              ))
            }
            <Button secondary LeftIcon={Plus} onClick={() => arrayHelpers.push(initialOwnerFields)}>Add Additional Owner</Button>
          </>
        )}
      />
      <StyledSubtitleContainer>
        <H3 bold>Other Company Contacts</H3>
        <H4>Enter other key company contacts here.</H4>
      </StyledSubtitleContainer>
      {
        customContactFields.map((customContactField) => (
          <CustomContactSection
            key={customContactField.id}
            parentKey={`customContacts.${customContactField.id}`}
            label={customContactField.label}
            settings={customContactField.contact}
          />
        ))
      }
      <FieldArray
        name='additionalContacts'
        render={(arrayHelpers) => (
          <>
            {values.additionalContacts
              ? values.additionalContacts.map((_additionalContact, index) => (
                <AdditionalContactSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  parentKey={`additionalContacts[${index}]`}
                  onRemoveClick={() => arrayHelpers.remove(index)}
                />
              )) : false
            }
            <Button secondary LeftIcon={Plus} onClick={() => arrayHelpers.push(initialAdditionalContactFields)}>Add Additional Contact</Button>
          </>
        )}
      />
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Next</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )
};
