import React from 'react';
import { useLocation } from 'react-router-dom';

import { Item, ChildItem } from './Item';

import { StyledNavLink, StyledNavChildLink } from './styled';

interface IProps {
  title: string,
  path?: string,
  toggleMenu?: () => void,
}

export const NavigationItem = ({ title, path, toggleMenu }: IProps) => {
  const { pathname } = useLocation();
  const isActive = path ? pathname.includes(path) : false;

  if (path) {
    return (
      <StyledNavLink active={`${isActive}` as any} to={path} onClick={toggleMenu}>
        <Item title={title} isActive={isActive} />
      </StyledNavLink>
    );
  }
  return null;
};

export const ChildNavigationItem = ({ title, path, toggleMenu }: IProps) => {
  const { pathname } = useLocation();
  const isActive = path ? pathname === path : false;

  if (path) {
    return (
      <StyledNavChildLink active={`${isActive}` as any} to={path} onClick={toggleMenu}>
        <ChildItem title={title} isActive={isActive} />
      </StyledNavChildLink>
    );
  }
  return null;
};
