import React, { Dispatch, SetStateAction } from 'react';

import { StyledModal } from '@/modals/StyledModal';

import { FinicityWidget } from './FinicityWidget';

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: any) => void,
}

export const ConnectBankModal = ({ isOpen, setIsOpen, onSuccess }: IProps) => (
  <StyledModal
    title='Connect your bank'
    // Do we need this?
    // submitButtonText='Send'
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    width='375px'
    height='820px'
    maxHeight='98vh'
    blockClose
  >
    {isOpen ? <FinicityWidget setIsOpen={setIsOpen} onSuccess={onSuccess} /> : null}
  </StyledModal>
);
