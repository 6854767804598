import React from 'react';

import { NavItem } from './NavItem';
import { SettingsNavContainer } from './styled';

export interface IOption {
    path: string,
    title: string,
}

interface IProps {
    options: IOption[],
  }

export const SettingsNav = ({ options }: IProps) => (
  <SettingsNavContainer>
    {
      options.map(({ path, title }) => <NavItem key={title} path={path}>{title}</NavItem>)
    }
  </SettingsNavContainer>
);
