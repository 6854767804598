import React, { Dispatch, SetStateAction } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { DELETE_INTERNAL_BANK_DOCUMENT } from '@/graphql/mutations/deleteInternalBankDocument';

import { get } from 'lodash/fp';

import { IManualBank, IBankStatement, IInternalBankDocument } from '@/types/manualBank';

import { Row } from '@/components/designSystem/Form/styled';
import { StyledExternalLink, H4 } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';
import { PopperMenu } from '@/components/PopperMenu';

import { useManualAccountFields } from './useManualAccountFields';
import { LabeledValue, LabeledValueAsChildren } from '../../../../LabeledValue';
import { StyledButtonsContainer, StyledAutoFitColumnsRow, StyledTwoColumnsRow } from '../../../../styled';
import { StyledBankStatementsContainer, StyledAccountInfoContainer } from '../../styled';
import { BankStatement } from '../../BankStatement';
import { StyledInternalBankDocumentContainer, StyledJustifiedColumn } from './styled';

import { ReactComponent as Filedownload } from '@/assets/filedownload.svg';
import { ReactComponent as Trash } from '@/assets/trash_gray.svg';

import { WARNINGS } from '@/constants';
import { WarningPopupIcon } from '@/components/WarningPopupIcon';

interface IProps {
  name: string,
  accountNumber: string,
  accountType: string,
  routingNumber: string,
  internalBankDocuments: IInternalBankDocument[],
  institution: IManualBank,
  setInternalBankDocumentModal: Dispatch<SetStateAction<boolean>>,
  refetch: () => void,
}

export const ManualAccountInfoView = ({
  name,
  accountNumber,
  accountType,
  routingNumber,
  internalBankDocuments,
  institution,
  setInternalBankDocumentModal,
  refetch
}: IProps) => {
  const [deleteInternalBankDocument] = useMutation(DELETE_INTERNAL_BANK_DOCUMENT);

  const fields = useManualAccountFields(institution);
  const bankStatements = get('bankStatements', institution);

  const onDeleteBankDocument = async(internalBankDocumentId: string) => {
    deleteInternalBankDocument({variables: {internalBankDocumentId}});
  };

  return (
    <StyledAccountInfoContainer>
      <StyledTwoColumnsRow>
        <LabeledValue label='Bank name' value={fields.institutionName || '-'} />
        <LabeledValue label='Bank address' value={fields.institutionAddress || '-'} />
      </StyledTwoColumnsRow>
      <StyledAutoFitColumnsRow>
        <LabeledValue label='Account Owner/representative Name' value={name || '-'} />
        <LabeledValue label='Account number' value={accountNumber || '-'} />
        <LabeledValue label='Routing / Transit' value={routingNumber || '-'} />
        <LabeledValue label='Account type' value={accountType || '-'} />
        <LabeledValue label='Currency' value={'-'} />
      </StyledAutoFitColumnsRow>
      <StyledAutoFitColumnsRow>
        <LabeledValue label='Bank Contact name' value={fields.contactName || '-'} />
        <LabeledValueAsChildren label='Bank Contact email'>
          <div>{fields.contactEmail  || '-'}</div>
          {fields.showNonBusinessEmailDomainWarning && (
            <WarningPopupIcon description={WARNINGS.DOMAIN} />
          )}
        </LabeledValueAsChildren>
        <LabeledValue label='Bank Contact phone' value={fields.contactPhone || '-'} />
        <LabeledValue label='Bank Contact title' value={fields.contactTitle || '-'} />
      </StyledAutoFitColumnsRow>
      <Row>
        <LabeledValue label='Comments' value={'-'} />
      </Row>
      <H4 bold>Recent Bank Statements</H4>
      <StyledBankStatementsContainer>
        {bankStatements.map((bankStatement: IBankStatement) => <BankStatement key={bankStatement.id} bankStatement={bankStatement} />)}
      </StyledBankStatementsContainer>
      <H4 bold>Internal Bank Document</H4>
      <StyledInternalBankDocumentContainer>
        {internalBankDocuments.map((internalBankDocument: IInternalBankDocument) => (
          <StyledJustifiedColumn key={internalBankDocument.id} >
            <Flex direction='column'>
              <StyledExternalLink href={internalBankDocument.storedFile.signedUrl} target='_blank'>
                {internalBankDocument.storedFile.originalFilename}
              </StyledExternalLink>
            </Flex>
            <div>{internalBankDocument.notes || '-'}</div>
            <PopperMenu placement='right' items={[
              {
                name: 'Download',
                icon: <Filedownload width='16' height='16' fill='grey' />,
                onClick: (e: Event) => {
                  e.preventDefault();
                  window.open(internalBankDocument.storedFile.signedUrl,'_blank',);
                }
              },
              {
                name: 'Delete',
                icon: <Trash width='16' height='16' />,
                onClick: () => {
                  onDeleteBankDocument(internalBankDocument.id);
                  refetch();
                },
              }
            ]} />
          </StyledJustifiedColumn>
        ))}
      </StyledInternalBankDocumentContainer>
      <StyledButtonsContainer>
        <Button onClick={() => setInternalBankDocumentModal(true)}>Upload</Button>
      </StyledButtonsContainer>
    </StyledAccountInfoContainer>
  );
};
