import React from 'react';

import { IManualBank } from '@/types/manualBank';

import { ManualBank } from './ManualBank';

interface IProps {
  institutions: IManualBank[],
  accountNotes: string[],
  customerId: string,
  refetch: () => void,
}

export const ManualSection = ({
  institutions, accountNotes, customerId, refetch,
}: IProps) => (
  <>
    {institutions.map((institution) => (
      <ManualBank
        key={institution.institutionName}
        institution={institution}
        accountNotes={accountNotes}
        customerId={customerId}
        refetch={refetch}
      />
    ))}
  </>
);
