import styled from 'styled-components';

import { StyledAutoFitColumnsRow } from '../../../../styled';

export const StyledJustifiedColumn = styled(StyledAutoFitColumnsRow)`
  background: ${({ theme }) => theme.color.greyLight};
  border-radius: 6px;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledInternalBankDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
