import { ISettings } from "@/types/settings";

import { NUMBER_OF_COMPANY_TYPES } from "@/constants";

interface IArgs {
  providerCompanySettings: ISettings,
  chosenCompanyType: string,
}

export const useAdditionalPersonalInformationFields = ({ providerCompanySettings, chosenCompanyType }: IArgs) => {
  const enableAddressForLength = providerCompanySettings?.enableAddressFor?.split(',').length;
  const enableSsnSinNumberForLength = providerCompanySettings?.enableSsnSinNumberFor?.split(',').length;
  const enableDriverLicenseNoForLength = providerCompanySettings?.enableDriverLicenseNoFor?.split(',').length;
  const enableDriverLicenseStateForLength = providerCompanySettings?.enableDriverLicenseStateFor?.split(',').length;
  const enableDateOfBirthForLength = providerCompanySettings?.enableDateOfBirthFor?.split(',').length;

  const ownerAddressEnabled = providerCompanySettings?.enableAddress
      && (providerCompanySettings?.enableAddressFor?.includes(chosenCompanyType)
        || enableAddressForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerAddressRequired = ownerAddressEnabled && providerCompanySettings?.requireAddress;
  const ownerSsnSinNumberEnabled = providerCompanySettings?.enableSsnSinNumber
      && (providerCompanySettings?.enableSsnSinNumberFor?.includes(chosenCompanyType)
        || enableSsnSinNumberForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerSsnSinNumberRequired = ownerSsnSinNumberEnabled && providerCompanySettings?.requireSsnSinNumber;
  const ownerDriverLicenseNoEnabled = providerCompanySettings?.enableDriverLicenseNo
      && (providerCompanySettings?.enableDriverLicenseNoFor?.includes(chosenCompanyType)
        || enableDriverLicenseNoForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerDriverLicenseNoRequired = ownerDriverLicenseNoEnabled && providerCompanySettings?.requireDriverLicenseNo;
  const ownerDriverLicenseStateEnabled = providerCompanySettings?.enableDriverLicenseState
      && (providerCompanySettings?.enableDriverLicenseStateFor?.includes(chosenCompanyType)
        || enableDriverLicenseStateForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerDriverLicenseStateRequired = ownerDriverLicenseStateEnabled && providerCompanySettings?.requireDriverLicenseState;
  const ownerDateOfBirthEnabled = providerCompanySettings?.enableDateOfBirth
      && (providerCompanySettings?.enableDateOfBirthFor?.includes(chosenCompanyType)
        || enableDateOfBirthForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerDateOfBirthRequired = ownerDateOfBirthEnabled && providerCompanySettings?.requireDateOfBirth;

  return {
    ownerAddressEnabled,
    ownerAddressRequired,
    ownerSsnSinNumberEnabled,
    ownerSsnSinNumberRequired,
    ownerDriverLicenseNoEnabled,
    ownerDriverLicenseNoRequired,
    ownerDriverLicenseStateEnabled,
    ownerDriverLicenseStateRequired,
    ownerDateOfBirthEnabled,
    ownerDateOfBirthRequired,
  };
}