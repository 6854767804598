import { DependencyList, useEffect } from 'react';
import * as Formik from 'formik';
import { FormikErrors, FormikValues } from 'formik';

import flat from 'flat';
import { flow, mapKeys } from 'lodash/fp';
import { IBaseFile } from '@/types/file';

interface IValidateOnChange {
  propToWatch: DependencyList,
}

interface IShowErrors {
  errors: FormikErrors<FormikValues>,
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}

export const ValidateOnChange = ({ propToWatch }: IValidateOnChange) => {
  const formik = Formik.useFormikContext();
  const { validateForm } = formik;
  useEffect(() => {
    // using setTimeout is hack to account for formik not properly handling
    // initialValues changing asynchronously
    // https://github.com/formium/formik/issues/2841
    setTimeout(() => validateForm(), 50);
  }, propToWatch);

  return null;
};

export  const showErrors = ({ errors, setFieldTouched }: IShowErrors) => {
  flow(
    flat,
    mapKeys((key) => {
      setFieldTouched(`${key}`, true);
    }),
  )(errors);
};

export  const convertFileToFileUpload = (file: IBaseFile) => ({
  fileId: file.id,
  signedUrl: file.signedUrl,
  name: file.originalFilename,
});