import React from 'react';

import { IBankruptcyCase } from '@/types/bankruptcyCase';

import { StyledMultipleResultsCaseContainer, StyledMultipleResultsContainer } from '../styled';
import { Bankruptcy } from './Bankruptcy';

interface IProps {
  bankruptcyResults: IBankruptcyCase[],
}

export const BankruptciesMultipleResultsView = ({ bankruptcyResults }: IProps) => (
  <StyledMultipleResultsContainer>
    {bankruptcyResults.map((bankruptcyResult) => (
      <StyledMultipleResultsCaseContainer key={bankruptcyResult.caseNo}>
        <Bankruptcy bankruptcyResult={bankruptcyResult} />
      </StyledMultipleResultsCaseContainer>
    ))}
  </StyledMultipleResultsContainer>
);
