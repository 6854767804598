import styled from 'styled-components';

import { FlexColumn, FlexRow } from '@/components/designSystem/Layout';
import { Caption, SubHeading } from '@/components/designSystem/Typography';
import { Form } from 'formik';

// ADDITIONAL QUESTIONS

export const StyledAdditionalQuestionInfoContainer = styled(FlexColumn)`
  gap: 1rem;
  width: 100%;
`;

// COMMENTS

export const StyledCommentsForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 2rem;
`

export const StyledCommentInfo = styled(FlexRow)`
  gap: 1rem;
`

export const StyledCommentDate = styled(Caption)`
  color: ${({ theme }) => theme.color.greyMed};
`;
