import styled from 'styled-components';

import { FlexColumnItemsCenter, FlexRowSpaceBetween } from '@/components/designSystem/Layout';
import { StyledLinkButton } from '@/components/designSystem/Typography';

export const StyledDisabledLinkButton = styled(StyledLinkButton)`
  color: grey;
  cursor: default;
`;

export const StyledModalContainer = styled(FlexColumnItemsCenter)`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

export const StyledControls = styled(FlexRowSpaceBetween)`
  flex: 1 0;
`;

export const StyledSubject = styled.div`
  font-size: 12px;
  color: #6E7072;
`;

export const StyledSubjectDetails = styled.div`
  text-align: center;
`;

export const StyledSubjectTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

export const StyledSentAtDetails = styled.div`
  font-size: 13px;
`;

export const StyledEmailDetails = styled.div`
  text-align: left;
  background-color: #F5F5F5;
  padding: 20px;
  margin-top: 15px;
`;

export const StyledEmailText = styled.div`
  margin-top: 20px;
  text-align: left;
`;

export const StyledHr = styled.hr`
  border: none;
  border-top: 1px dotted #081836;
  color: #fff;
  background-color: #fff;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
`;

export const StyledAttachmentHeading = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const StyledDownloadContainer = styled(FlexColumnItemsCenter)`
  gap: 0.5rem;
`;
