import React from 'react';

import { get } from 'lodash/fp';

import { ICurrentCompany } from '@/types/currentCompany';

import { BorderedContainer } from '@/containers/BorderedContainer';

import { H4 } from '@/components/designSystem/Typography';
import { LabeledComponent } from '@/components/LabeledComponent';
import { Flex } from '@/components/designSystem/Layout';

import { StyledExperianLogo, StyledPageTitleContainer, StyledRow } from './styled';
import { EditCredsButton } from './EditCredsButton';
import { INTEGRATIONS } from './constants';

interface IProps {
    refetch: () => void,
    currentCompany: ICurrentCompany,
  }

export const ExperianSettings = ({ currentCompany, refetch }: IProps) => {
  const experianUsername = get('settings.experianUsername', currentCompany);
  const experianClientId = get('settings.experianClientId', currentCompany);
  const experianSubcode = get('settings.experianSubcode', currentCompany);

  return (
    <>
      <StyledPageTitleContainer>
        <StyledExperianLogo />
        <EditCredsButton
          type={INTEGRATIONS.EXPERIAN}
          refetch={refetch}
          username={experianUsername}
          subcode={experianSubcode}
        />
      </StyledPageTitleContainer>
      <BorderedContainer>
        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Username'>
            <StyledRow>
              <H4 bold>{experianUsername || 'None'}</H4>
            </StyledRow>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Password'>
            <H4 bold>{experianUsername ? '**********' : 'None'}</H4>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Experian Client ID'>
            <StyledRow>
              <H4 bold>{experianClientId ? '**********' : 'None'}</H4>
            </StyledRow>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Experian Client Secret'>
            <H4 bold>{experianClientId ? '**********' : 'None'}</H4>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='SubCode'>
            <StyledRow>
              <H4 bold>{experianSubcode || 'None'}</H4>
            </StyledRow>
          </LabeledComponent>
        </Flex>

      </BorderedContainer>
    </>
  );
};