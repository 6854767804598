import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { GET_CURRENT_USER } from '@/graphql/queries/getCurrentUser';
import { IS_EMAIL_VERIFIED } from '@/graphql/queries/isEmailVerified';

import { getOr, get, debounce } from 'lodash/fp';
import { setTrackerUserId } from '@/utils/openreplay';

import { useAuth0 } from '@/providers/Auth0/Auth0';

import { EmailVerification } from '@/views/onboard/CreditApplicantOnboard/EmailVerification';
import { LoadingSpinner } from '@/components/LoadingSpinner';

declare global {
  interface Window {
    analytics: any;
    Beacon: any;
    finicityConnect: any;
    eversign: any;
    google: any;
  }
}

const setAnalytics = debounce(500, (userId, analyticsData) => {
  setTrackerUserId(userId);
  window.analytics.identify(userId, analyticsData);
  if (window.Beacon) {
    window.Beacon('identify', {
      name: get('name', analyticsData),
      email: get('email', analyticsData),
      currentCompany: get('currentCompany.name', analyticsData),
    });
  }
});

interface IProps extends RouteComponentProps {
  component: FunctionComponent,
  path: string,
  exact?: boolean,
  returnToPath?: boolean
}

interface IEmailVerifiedProps extends RouteComponentProps {
  component: FunctionComponent,
  path: string,
  exact?: boolean,
  setRouteBlocked: Dispatch<SetStateAction<boolean>>,
  customerId?: string,
}

export const PrivateRoute = withRouter(({
  component: Component, returnToPath, path, location, ...rest
}: IProps) => {
  const { loading, data } = useQuery(GET_CURRENT_USER, { fetchPolicy: 'network-only' });
  const { clearSession } = useAuth0();
  const userId = getOr(false, 'currentUser.id', data);

  if (loading) {
    return <LoadingSpinner />;
  }

  const render = (props: any) => {
    if (!userId) {
      clearSession();
    } else {
      const { currentUser, ...other } = data;
      const analyticsData = { ...currentUser, ...other };
      setAnalytics(userId, analyticsData);
    }
    return (userId ? <Component {...props} /> : <Redirect to={returnToPath ? `/login?redirect=${location.pathname}` : '/login'}/>);
  };

  return <Route path={path} render={render} {...rest} />;
});

export const EmailVerifiedRoute = withRouter(({
  component: Component,
  path,
  setRouteBlocked,
  customerId,
  ...rest
}: IEmailVerifiedProps) => {
  const { loading, data, refetch } = useQuery(IS_EMAIL_VERIFIED, { fetchPolicy: 'network-only' });
  const emailVerified = getOr(false, 'isEmailVerified', data);
  const userId = getOr(false, 'currentUser.id', data);
  const userEmail = getOr(false, 'currentUser.email', data);

  if (loading) {
    return <LoadingSpinner />;
  }

  const render = (props: any) => {
    if (userId) {
      const { currentUser, ...other } = data;
      const analyticsData = { ...currentUser, ...other };
      setAnalytics(userId, analyticsData);
    }

    return emailVerified
      ? <Component {...props} />
      : <EmailVerification checkUser={debounce(1000, refetch)} customerId={customerId} userEmail={userEmail}/>;
  };

  return <Route path={path} render={render} {...rest} />;
});
