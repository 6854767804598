import styled, { css } from 'styled-components';

import device from '@/styles/device';

import { Flex } from '@/components/designSystem/Layout';

interface IStyledStepCounterContainerProps {
  steps: any[];
}

export const StyledOnboardHeaderWrapper = styled.header`
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledOnboardHeader = styled(Flex).attrs({ justify: 'space-between', align: 'center' })`
  background-color: ${(props) => props.theme.color.site.primary};
  width: 100%;

  @media ${device.laptopL} {
    flex-direction: column;
    padding-top: 2rem;
  };
`;

export const StyledStepCounterContainer = styled.div<IStyledStepCounterContainerProps>`
  display: flex;
  justify-content: center;
  width: 40rem;
  margin: 3rem 0rem;

  @media ${device.tablet} {
    width: 30rem;
  };

  @media ${device.mobileL} {
    width: 20rem;
    height: 7rem;
    padding: 0rem 1.25rem;

    ${(props) => {
      const stepsArray = props.steps;
      return stepsArray.length > 4
        ? css`
            justify-content: start;
            overflow-x: scroll;
          `
        : css`
            justify-content: center;
          `;
    }}
  };
`;

export const StyledCalloutContainer = styled(Flex).attrs({ direction: 'column', gap: '1rem' })`
  width: 50%;
  margin-bottom: 1rem;

  @media ${device.tablet} {
    width: 95%;
  };

  @media ${device.mobileS} {
    font-size: 14px;
  };
`
