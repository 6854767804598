import { useMemo } from 'react';

export const useApplicationsData = (data: any) => {
  const generateApplicationsData = () => [
    ...data.map((application: any) => ({
      id: application.id,
      company: application.providerCompany.name,
      status: application.latestApplication?.seekerDisplayStatus,
      creditLimit: application.latestApplication?.creditLimit || '',
      creditTerms: application.latestApplication?.creditTerms || '',
      dateApproved: application.latestApplication?.dateApproved || '',
    })),
  ];

  const applicationsData = useMemo(() => generateApplicationsData(), [generateApplicationsData]);

  return applicationsData;
};