import styled from 'styled-components';

import { BorderedContainer } from '@/containers/BorderedContainer';
import { ReactComponent as TriangleDown } from '@/assets/triangle_down.svg';

export const ToggleWellContainer = styled(BorderedContainer)`
  & > :first-child {
    border-bottom: none;
  }

  overflow-x: hidden;
`;

export const ToggleWellHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  `;

export const ToggleTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: 0.5rem;
  }
`;

interface IProps {
  open: boolean,
}

export const ToggleIcon = styled(TriangleDown)<IProps>`
  color: ${({ theme }) => theme.color.black};
  transform: ${({ open }) => (open ? '' : 'rotate(-90deg)')};
  margin-right: 1rem;
`;
