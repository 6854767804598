import React from 'react';

import { formatCustomFieldType } from '@/utils/format';

import { ICustomField } from '@/types/customField';

import { H4 } from '@/components/designSystem/Typography';

import { EditAction } from './EditAction';
import { DeleteAction } from './DeleteAction';
import {
  StyledCustomFieldContainer,
  StyledBorderedContainer,
  StyledFieldDetails,
  StyledActionContainer,
  StyledBodyText,
  StyledRequiredText
} from './styled';

export interface ICustomFieldProps extends ICustomField {
  refetch: () => void,
  parentKey?: string,
}

export const CustomFieldItem = (props: ICustomFieldProps) => {
  const {
    id, type, label, required,
  } = props;

  return (
    <StyledCustomFieldContainer>
      <StyledBorderedContainer>
        <StyledFieldDetails key={id}>
          <H4 bold>{label}</H4>
          <StyledBodyText>
            ({formatCustomFieldType(type)}) {required && <StyledRequiredText>REQUIRED</StyledRequiredText>}
          </StyledBodyText>
        </StyledFieldDetails>
        <StyledActionContainer>
          <EditAction {...props} />
          <DeleteAction {...props} />
        </StyledActionContainer>
      </StyledBorderedContainer>
    </StyledCustomFieldContainer>
  );
};
