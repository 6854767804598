import React from 'react';

import { StyledRadioButtonContainer, StyledRadioButtonInput, StyledRadioButtonText } from './styled';

interface IProps {
  label?: string,
  checked?: boolean,
  name: string,
  group?: string,
  onClick?: () => void,
  error?: boolean,
}

export const RadioButton = ({
  label,
  checked,
  name,
  onClick,
  error = false,
}: IProps) => (
  <StyledRadioButtonContainer>
    <StyledRadioButtonInput
      checked={checked}
      onClick={onClick}
      id={name}
    />
    <StyledRadioButtonText error={error} htmlFor={name}>{label}</StyledRadioButtonText>
  </StyledRadioButtonContainer>
);
