import React, { useState } from 'react';
import styled from 'styled-components';

import { sortBy, take, map, flow, reverse } from 'lodash/fp';

import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { Button } from '@/components/designSystem/buttons';
import { Small, StyledLinkButton } from '@/components/designSystem/Typography';

import { ActivityItem } from './ActivityItem';

const CenteredTextButton = styled(StyledLinkButton)`
  align-self: center;
`;

interface IProps {
  activities: string[],
  enableManualSend: boolean,
  loading: boolean,
  handleManualSend: () => void,
}

export const ActivitySection = ({
  activities, enableManualSend = false, loading = false, handleManualSend,
}: IProps) => {
  const [showAll, setShowAll] = useState(false);
  const trucatedLength = 3;

  return (
    <TwoColumnContainer.SecondaryItem title={(
      <>
        Email Activity Feed
        {
          enableManualSend && (
            <Button
              primary
              xsmall
              loading={loading}
              onClick={() => handleManualSend()}
            >
              <Small color='white'>
                Send Reminder
              </Small>
            </Button>
          )
        }
      </>
    )}
    >
      {
        flow(
          sortBy(({ occuredAt }) => new Date(occuredAt)),
          map(ActivityItem),
          reverse,
          take(showAll ? activities.length : trucatedLength),
        )(activities)
      }
      {
        activities.length > trucatedLength ? (
          <CenteredTextButton onClick={() => setShowAll(!showAll)}>
            show {showAll ? 'less' : 'more'}
          </CenteredTextButton>
        ) : null
      }
    </TwoColumnContainer.SecondaryItem>
  );
};
