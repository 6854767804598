import styled from 'styled-components';
import { Form } from 'formik';

export const DeleteIcon = styled.div`
  cursor: pointer;
`;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
  border: ${(props) => props.theme.color.border.light};
  border-radius: 6px;
`;

export const CustomerSettingsContainer = styled(SettingsContainer)`
  padding: 1.5rem;
`;

export const DashboardSettingsContainer = styled(SettingsContainer)`
  padding: 0.75rem 0;
`;

export const StyledForm = styled(Form)`
  & > :first-child {
    margin-bottom: 1rem;
  }
  & > :nth-child(2) {
    margin-bottom: 1rem;
  }
`;
