import React from 'react';

import { BodyText, H2, H4, StyledExternalLink } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { FlexRowEnd } from '@/components/designSystem/Layout';
import { StyledFormRow } from '@/components/designSystem/Form/styled';

import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm
} from '../../styled';

export const BasicInfoStepForm = () => (
  <StyledForm>
    <StyledTitleContainer>
      <H2 bold>Your Information</H2>
      <H4>We need to know a bit more about you and your company</H4>
    </StyledTitleContainer>
    <TextField required type='text' name='name' label='Your Full Name' placeholder='Full Name' />
    <StyledFormRow>
      <TextField required type='text' name='email' label='Email' placeholder='you@company.com' />
      <TextField required type='password' name='password' label='Create Password' placeholder='*****' />
    </StyledFormRow>
    <StyledFormRow>
      <TextField required type='text' name='position' label='Position' placeholder='CFO' />
      <PhoneNumberField required name='phoneNumber' label='Phone' placeholder='Phone number' />
    </StyledFormRow>
    <CheckboxField name='agreeCheck'>
      <BodyText>
          I agree to Nectarine Credit's
        <StyledExternalLink href='https://www.nectarinecredit.com/terms' target='_blank'>
          {` Terms and Conditions`}
        </StyledExternalLink>
        {` and `}
        <StyledExternalLink href='https://www.nectarinecredit.com/privacy' target='_blank'>
            Privacy Policy
        </StyledExternalLink>
      </BodyText>
    </CheckboxField>
    <FlexRowEnd>
      <StyledNextButton primary type='submit'>Next</StyledNextButton>
    </FlexRowEnd>
  </StyledForm>
);
