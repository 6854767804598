import styled, { css } from 'styled-components';
import { EditorContent } from '@tiptap/react';

import device from '@/styles/device';

export const StyledEditorContent = styled(EditorContent)`
  .tiptap {
    text-align: left;
    margin: 0.5rem;
    min-height: 3rem;
  
    &:focus {
      outline: none;
      // border: 2px solid ${({ theme }) => theme.color.primary};
    }
    
    &:focus-visible {
      outline: none;
    }
  }
`;

export const StyledEditorWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: 2px solid ${({ theme }) => theme.color.greyLight};
  box-shadow: ${({ theme }) => theme.color.shadow.shadowSmall};
  border-radius: 6px;

  &:focus-within {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

export const StyledToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.25rem;
  border-bottom: ${({ theme }) => theme.color.border.dark};
`;

export const StyledToolDivider = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  opacity: 25%;
  height: 1.25rem;
  width: 0.125rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
`;

interface StyledToolsProps {
  isActive: boolean;
}

export const StyledTools = styled.button<StyledToolsProps>`
  display: flex;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  border-radius: 6px;
  background-color: ${props => (props.isActive ? '#000000' : 'transparent')};
  color: ${props => (props.isActive ? '#ffffff' : '#000000')};
  
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`