import styled from 'styled-components';

import { FlexRow, FlexRowItemsEnd } from '@/components/designSystem/Layout';

export const StyledContactContainer = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.color.greyLight};
  margin-bottom: 1.5rem;
`;

export const StyledCloseIconWrapper = styled.div`
  cursor: pointer;
`;

export const StyledCustomContactContainer = styled.div`
  margin: 0 1rem;
`;

export const StyledCustomContactFormRow = styled(FlexRowItemsEnd)`
  gap: 1rem;
`;

export const StyledAdditionalContactPhoneNumberExtensionContainer = styled(FlexRow)`
  gap: 1rem;
`;

export const StyledAdditionalContactPhoneNumberWrapper = styled.div`
  width: calc(100% - 6rem)
`;