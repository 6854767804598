import styled from 'styled-components';

import { BorderedContainer } from '@/containers/BorderedContainer';
import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { FlexColumn, FlexRow } from '@/components/designSystem/Layout';

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const ImageLabel = styled.label<{ selected: boolean }>`
  cursor: pointer;
  border: ${(props) => (props.selected ? '1px solid #0070f3' : '1px solid #ccc')};
  border-radius: 8px;
  padding: 5px;
  transition: border 0.3s ease;
  background: lightgrey;

  &:hover {
    border: 1px solid #0070f3;
  }
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

export const RadioImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;
