import React from 'react';

import { Callout } from '@/components/Callout/Callout';
import { BodyText } from '@/components/designSystem/Typography';

import { Section } from './Section';
import { OtherPersonFields } from './OtherPersonFields';

export const OtherPersonSection = () => (
  <Section
    title='Other Authorized Persons'
    description='Customers can add multiple authorized persons to their company.'
  >
    <OtherPersonFields name='Sara Smith' position='CEO' email='s.smith@streamlink.com' />
    <OtherPersonFields name='John Adams' position='CFO' email='j.adams@streamlink.com' />
    <OtherPersonFields name='Sam Tucker' position='Accountant' email='s.tucker@streamlink.com' />
    <Callout>
      <BodyText>Customers are able to add multiple authorized persons, when filling out this section.</BodyText>
    </Callout>
  </Section>
);
