import React from 'react';

import { StyledMenuItem, StyledMenuItems } from './styled';
import { IPopperMenuProps } from '.';

export const PopperMenuItems = ({ items }: IPopperMenuProps) => (
  <StyledMenuItems>
    <ul>{items.map((item) => <PopperMenuItem key={item.name} name={item.name} icon={item.icon} onClick={item.onClick} />)}</ul>
  </StyledMenuItems>
)

interface IPopperMenuItemProps {
  name: string,
  icon?: React.ReactNode,
  onClick?: (args: any) => void,
}

export const PopperMenuItem = ({name, icon, onClick} : IPopperMenuItemProps) => (
  <li>
    <StyledMenuItem onClick={onClick}>
      {icon && <span>{icon}</span>}
      <span>{name}</span>
    </StyledMenuItem>
  </li>
)