import React from 'react';

import { IBankStatement } from '@/types/manualBank';

import { StyledExternalLinkUnderline } from '@/components/designSystem/Typography';


interface IBankStatementProps {
  bankStatement: IBankStatement
}

export const BankStatement = ({ bankStatement }: IBankStatementProps) => (
  <StyledExternalLinkUnderline color='greyDark' href={bankStatement.signedUrl} target='_blank'>{bankStatement.originalFilename}</StyledExternalLinkUnderline>
)