import React, { useState } from 'react';

import { formatDateToLongDateString } from '@/utils/date';

import { Caption, StyledLinkButton } from '@/components/designSystem/Typography';

import { PDFDocumentModal } from './PDFDocumentModal';
import { PDFCardContainer, NewLineCaption } from './styled';
import { IUploadedFile } from '@/types/file';
import { Flex } from '../designSystem/Layout';

interface IProps {
  title: string,
  subtitle?: string,
  file: IUploadedFile,
  dateDescription: string,
}

export const PDFDocumentCard = ({
  title, subtitle, file, dateDescription,
}: IProps) => {
  const { insertedAt, id } = file;
  const [isOpen, setIsOpen] = useState(false);
  const dateText = dateDescription ? `${dateDescription} ${insertedAt ? formatDateToLongDateString(insertedAt) : ''}` : insertedAt && formatDateToLongDateString(insertedAt);

  return (
    <PDFCardContainer key={id}>
      <Flex direction='column' align='flex-start'>
        {subtitle && <NewLineCaption title={subtitle}>{subtitle}</NewLineCaption>}
        <Caption>{dateText}</Caption>
      </Flex>
      <StyledLinkButton onClick={() => setIsOpen(true)} bold>Preview</StyledLinkButton>
      <PDFDocumentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        file={file}
        title={title}
      />
    </PDFCardContainer>
  );
};
