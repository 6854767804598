import styled from "styled-components";

import { FlexColumnItemsEnd } from "../Layout";

export const StyledEditableCardContainer = styled(FlexColumnItemsEnd)`
  gap: 1rem;
`;

export const StyledEditableCardInnerContainer = styled.div`
  align-self: stretch;
`;