import React, { useState, useContext } from 'react';
import { Flex } from '@/components/designSystem/Layout';
import { H2 } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { SideTitle } from '../SideTitle';
import { RadioButton } from '@/components/designSystem/Form/RadioButton';
import { StyledTopBorder } from './styled';
import { StyledSlideActionContainer, StyledFormButtonContainer } from '../styled';
import { StyledMessageWrapper } from './styled';
import { InternalReviewForm } from './InternalReviewForm';
import { ExternalReviewForm } from './ExternalReviewForm';
import { ICustomer } from '@/types/customer';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { getOr } from 'lodash/fp';

interface IProps {
  customerId: string,
  customerData: ICustomer,
  setOpen: (isOpen: boolean) => void,
  refetchCustomerData: () => void,
  creditTermsOptions: string[]
}

export const Review = ({customerData, refetchCustomerData, customerId, setOpen, creditTermsOptions} : IProps) => {
  const [reviewSelected, setReviewSelected] = useState<string | null>(null)
  const currentCompanyData = useContext(CurrentCompanyContext);
  const enabledReview = getOr(false, 'currentCompany.planInfo.enabledReview', currentCompanyData);

  return (
    <StyledSlideActionContainer>
      <SideTitle title='Review' />
      {enabledReview ?
        <div>
          <Flex style={{padding: '1rem'}}>
            <RadioButton
              name='internal-review'
              checked={reviewSelected === 'internal'}
              onClick={() => setReviewSelected('internal')}
              label='Internal Review'
            />
            <RadioButton
              name='external-review'
              checked={reviewSelected === 'external'}
              onClick={() => setReviewSelected('external')}
              label='External Review'
            />
          </Flex>
          {reviewSelected !== null &&
            <StyledTopBorder />
          }

          {reviewSelected === 'external' &&
            <ExternalReviewForm customerData={customerData} refetchCustomerData={refetchCustomerData} customerId={customerId} setOpen={setOpen} />
          }

          {reviewSelected === 'internal' &&
            <InternalReviewForm customerId={customerId} refetchCustomerData={refetchCustomerData} setOpen={setOpen} creditTermsOptions={creditTermsOptions} />
          }
        </div>
        :
        <StyledMessageWrapper>
          <H2>
          To upgrade and add our Review Module, please schedule a call with your Nectarine Credit account executive.
          </H2>
          <Button href='https://calendly.com/alex-1257' target='_blank' primary wide>Schedule a call</Button>
        </StyledMessageWrapper>
      }
    </StyledSlideActionContainer>
  )};
