import React from 'react';
import axios from 'axios';
import { API } from '@/app.config';

import { Button } from '@/components/designSystem/buttons';
import { useLocalStorage } from '@rehooks/local-storage';

import { StyledButtonWrapper } from './styled';

interface IProps {
  applicationId?: string,
}

export const ExportData = ({ applicationId }: IProps) => {
  const [token] = useLocalStorage('token');
  const exportData = () => {
    const headers = { 'Authorization': `Bearer ${token}` };
    axios.get(`${API.baseUrl}/api/applications/${applicationId}/export`, { headers, responseType: 'blob'})
      .then((response: any) => {
        const temp = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = temp;
        link.setAttribute('download', 'application.csv');
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <StyledButtonWrapper>
      <Button onClick={() => exportData()} wide secondary>Export Data</Button>
    </StyledButtonWrapper>
  );
};
