import React, { useEffect, useState, useContext } from 'react';
import theme from '@/styles/theme';
import styled, { ThemeProvider } from 'styled-components';
import { deleteFromStorage } from '@rehooks/local-storage';
import { useQuery } from '@apollo/react-hooks';
import { GET_REFERRAL_DATA } from '@/graphql/queries/getReferralData';
import { GET_SIGNED_AGREEMENT } from '@/graphql/queries/getOrCreateAgreement';
import { useQueryParams } from '@/utils/history';
import { get} from 'lodash/fp';
import { IReferralData } from '@/types/referralData';
import { ONBOARDING_PATH } from '@/constants';
import { OnboardContainer } from '@/containers/OnboardContainer';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { SignatureLinkExpiredModal } from '@/modals/SignatureLinkExpiredModal';
import { SignatureCompletedModal } from '@/modals/SignatureCompletedModal';
import { Caption } from '@/components/designSystem/Typography';
import { AgreementSignatureStep, agreementStepValidation, agreementStepFields } from './AgreementSignatureStep';

export const StyledCaption = styled(Caption)`
  padding-top: 10px;
 font-size: 14px;
 line-height: 18px;
 font-weight: bold;
 text-align: center;
 color: ${({ color }) => color};
`;

const applyReferralData = (referralData: IReferralData) => ({
  ...agreementStepFields(referralData),
  fromCompany: get('getReferralData.fromCompany', referralData),
});

export const SeekerSignature = () => {
  const query = useQueryParams();
  const referralCode = query.get('ref') || '';
  const customerId = query.get('cid') || '';
  const userId = query.get('uid') || '';
  const [showExpiredModal, setShowExpiredModal] = useState(false);
  const [showSignedModal, setShowSignedModal] = useState(false);

  const {data: agreement } = useQuery(GET_SIGNED_AGREEMENT, {
    variables: { customerId, userId},
    onCompleted: () => {
      const status= get('GetSignedAgreement.status', agreement);
      const inserted_at= new Date(get('GetSignedAgreement.inserted_at', agreement));
      const days = Date.now().valueOf() - inserted_at.valueOf();

      if (status){setShowSignedModal(true)}
      if(days > 30){setShowExpiredModal(true)}
    },

    // this is nessesary as the query returns partial refdata when the customer is not logged in
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    deleteFromStorage('token');
    deleteFromStorage('company-id');
    deleteFromStorage('customer-id');
  }, [agreement]);

  const { loading, data: refdata } = useQuery(GET_REFERRAL_DATA, {
    variables: { referralCode },

    // this is nessesary as the query returns partial refdata when the customer is not logged in
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  const fromCompany= get('getReferralData.fromCompany', refdata);
  const agreementText = get('agreementText', fromCompany);
  const providerCompanyId = get('id', fromCompany);
  const providerCompanyName = get('name', fromCompany);
  const seekerCompany= get('getReferralData.forCompany', refdata);
  const seekerCompanyName= get('name', seekerCompany);

  if (loading) {
    return <LoadingSpinner />;
  }

  const injectedFields = applyReferralData(refdata);
  const brand = get('brand', fromCompany);

  const overridenTheme = brand
    ? theme({
      primary: get('primaryColor', brand),
      secondary: get('secondaryColor', brand),
      background: get('backgroundColor', brand),
      brand: get('brandColor', brand),
    })
    : theme();

  const logoUrl = get('logo.signedUrl', brand);
  const logoOverride = logoUrl
    ? <img src={logoUrl} alt='logo' />
    : null;

  const submitSignature = () => {
    console.log('')
  }

  const steps = [
    {
      title: 'Please Sign the Agreement',
      path: `${ONBOARDING_PATH.SEEKER_SIGNATURE}`,
      Component: AgreementSignatureStep,
      validation: agreementStepValidation,
      stepProps: { agreementText, providerCompanyName, providerCompanyId, customerId, userId, seekerCompanyName}
    }
  ];

  return (
    <>
      <ThemeProvider theme={overridenTheme}>
        <OnboardContainer
          basePath={ONBOARDING_PATH.SEEKER_SIGNATURE}
          steps={steps}
          fields={injectedFields}
          logoOverride={logoOverride}
          customBrand={brand}
          onSubmit={submitSignature}
        />
        <SignatureLinkExpiredModal
          isOpen={showExpiredModal}
          setIsOpen={setShowExpiredModal}
          fromCompany={fromCompany}
        />
        <SignatureCompletedModal
          isOpen={showSignedModal}
          setIsOpen={setShowSignedModal}
          fromCompany={fromCompany}
        />
      </ThemeProvider>
    </>
  );
};
