import styled from 'styled-components';

import { Flex } from '@/components/designSystem/Layout';
import { BodyText } from '@/components/designSystem/Typography';

export const StyledToggleHeader = styled(Flex)`
  flex-grow: 1;

  & > :first-child {
    flex-basis: 17rem;
  }
`;

export const StyledResultsNumberText = styled(BodyText)`
  color: ${({ theme }) => theme.color.greyDark};
`;

export const StyledSingleResultContainer = styled(Flex).attrs({ direction: 'column', align: 'center', gap: '2rem' })`
  background-color: ${({ theme }) => theme.color.white};
  padding: 1rem 2rem;
  margin: 0 10%;
`;

export const StyledMultipleResultsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export const StyledMultipleResultsCaseContainer = styled(Flex).attrs({ direction: 'column' })`
  background-color: ${({ theme }) => theme.color.white};
  padding: 1rem 2rem;
  gap: 0.5rem;
`;
