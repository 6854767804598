import Yup, { DEFAULT_ERROR_MESSAGES, allowedVendorEmail } from '@/utils/yup';

const requiredVendorValidation = (applicantEmailDomain: string, applicantWebsite: string) => Yup.object().shape({
  companyName: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  name: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  email: allowedVendorEmail(applicantEmailDomain, applicantWebsite, true),
  phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
});

const optionalVendorValidation = (applicantEmailDomain: string, applicantWebsite: string) => Yup.object().shape({
  companyName: Yup.string().when(['name', 'email', 'phoneNumber'], {
    is: (nameValue: string, emailValue: string, phoneNumberValue: string) => nameValue || emailValue || phoneNumberValue,
    then: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    otherwise: Yup.string(),
  }),
  name: Yup.string().when(['companyName', 'email', 'phoneNumber'], {
    is: (companyNameValue: string, emailValue: string, phoneNumberValue: string) => companyNameValue || emailValue || phoneNumberValue,
    then: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    otherwise: Yup.string(),
  }),
  email: Yup.string().when(['companyName', 'name', 'phoneNumber'], {
    is: (companyNameValue: string, nameValue: string, phoneNumberValue: string) => companyNameValue || nameValue || phoneNumberValue,
    then: allowedVendorEmail(applicantEmailDomain, applicantWebsite, true),
    otherwise: allowedVendorEmail(applicantEmailDomain, applicantWebsite),
  }),
  phoneNumber: Yup.string().when(['companyName', 'name', 'email'], {
    is: (companyNameValue: string, nameValue: string, emailValue: string) => companyNameValue || nameValue || emailValue,
    then: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
    otherwise: Yup.string().when('phoneNumber', {
      is: (value: string) => value?.length > 0,
      then: Yup.string().phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
      otherwise: Yup.string(),
    }),
  }),
},
[
  ['companyName', 'name'],
  ['companyName', 'email'],
  ['companyName', 'phoneNumber'],
  ['name', 'companyName'],
  ['name', 'email'],
  ['name', 'phoneNumber'],
  ['email', 'companyName'],
  ['email', 'name'],
  ['email', 'phoneNumber'],
  ['phoneNumber', 'companyName'],
  ['phoneNumber', 'name'],
  ['phoneNumber', 'email'],
  ['phoneNumber', 'phoneNumber'],
]
);

export const vendorsStepValidation = (
  applicantEmailDomain: string,
  applicantWebsite: string,
) => (Yup as any).object().shape({
  // TODO Yup with TS is mess. Investigate.
  requiredVendors: Yup.array()
    .of(requiredVendorValidation(applicantEmailDomain, applicantWebsite)),
  optionalVendors: Yup.array()
    .of(optionalVendorValidation(applicantEmailDomain, applicantWebsite))
})
  .uniqueVendor('You cannot ask the same vendor twice.', 'email')
  .uniqueVendor('You cannot ask the same vendor twice.', 'phoneNumber');
