import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

export const ApplicationContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "main";
  width: 100%;
`;

export const Header = styled.header`
  grid-area: header;
`;

export const Main = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  height: fit-content;
`;

export const SettingsNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 2.5rem;
  border-bottom: ${(props) => props.theme.color.border.light};
  border-width: 2px;
  & > * {
    margin-left: 1rem;
  }
  & > :first-child {
    margin-left: 0;
  }
`;

export const activeClassName = 'nav-item-active';

export const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  padding-bottom: 1rem;
  margin-right: 1rem;
  margin-bottom: -2px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.${activeClassName} {
    border-bottom: 4px solid ${(props) => props.theme.color.brand};
  }
`;
