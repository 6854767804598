import React, { useContext, useState } from 'react';

import { useMutation, MutationFunctionOptions } from '@apollo/react-hooks';
import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';

import { get, getOr } from 'lodash/fp';

import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BorderedContainer } from '@/containers/BorderedContainer';

import { UpdateGuaranteeModal } from '@/modals/UpdateGuaranteeModal';
import { H2, BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { UpdateAgreementModal } from '@/modals/UpdateAgreementModal';
import { AgreementText } from './AgreementText';
import { EnableRequiredOptions, IHandleEnableRequiredOptionsChangeArgs } from '@/components/EnableRequiredOptions';
import { Flex } from '@/components/designSystem/Layout';
import { StyledContainer } from './styled';
import { LoadingSpinner } from '@/components/LoadingSpinner';

const KEYS = {
  enabled: 'enable_personal_guarantee_default',
  required: 'require_personal_guarantee_default',
}

export const CreditApplicationTermsAndConditions = () => {
  const currentCompanyData = useContext(CurrentCompanyContext);

  const [agreementIsOpen, setAgreementIsOpen] = useState(false);
  const [guaranteeIsOpen, setGuaranteeIsOpen] = useState(false);

  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);

  if (!currentCompanyData) {
    return <LoadingSpinner />
  }

  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);

  const agreementText = getOr([], 'currentCompany.agreementText', currentCompanyData);
  const personalGuaranteeText = getOr([], 'currentCompany.personalGuaranteeText', currentCompanyData);
  const companySettings = get('currentCompany.settings', currentCompanyData);

  const handleChange = (state: any) => handleUpdateCompanySettings({variables: {settings: state}});

  const handleUpdateCompanySettings = (variables: MutationFunctionOptions) => updateCompanySettings(variables)
    .then(() => {
      showToast({
        title: 'Information Successfully Updated!',
        description: 'Personal Guarantee options updated successfully.',
        type: toast.TYPE.SUCCESS,
      });
      refetchCurrentCompany();
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Information wasn\'t updated',
        type: toast.TYPE.ERROR,
      });
    })

  return (
    <StyledContainer>
      <BodyText>Manage the credit terms for your customers. You can use our pre-written terms or edit the terms below.</BodyText>
      <BorderedContainer disableDivider>
        <Flex justify='space-between' align='center'>
          <H2 bold>Credit Terms and Conditions of Sale</H2>
          <Button onClick={() => setAgreementIsOpen(true)}>Edit</Button>
        </Flex>
        <AgreementText>{agreementText}</AgreementText>
      </BorderedContainer>

      <BorderedContainer disableDivider>
        <Flex justify='space-between' align='center'>
          <H2 bold>Personal Guarantee</H2>
          <Button onClick={() => setGuaranteeIsOpen(true)}>Edit</Button>
        </Flex>
        <AgreementText>{personalGuaranteeText}</AgreementText>
      </BorderedContainer>
      {companySettings ? (
        <EnableRequiredOptions
          label='Personal Guarantee By Default'
          keys={KEYS}
          enabled={companySettings.enablePersonalGuaranteeDefault}
          required={companySettings.requirePersonalGuaranteeDefault}
          handleChange={handleChange}
        />) : false }

      <UpdateGuaranteeModal isOpen={guaranteeIsOpen} setIsOpen={setGuaranteeIsOpen} onSuccess={refetchCurrentCompany} personalGuaranteeText={personalGuaranteeText} />
      <UpdateAgreementModal isOpen={agreementIsOpen} setIsOpen={setAgreementIsOpen} onSuccess={refetchCurrentCompany} agreementText={agreementText} />
    </StyledContainer>
  );
};
