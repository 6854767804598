import React, { useState } from 'react';

import { IVendor } from '@/types/vendor';
import { IEmailThread } from '@/types/emailThread';

import { PopperMenuContainer } from '@/components/PopperMenu/container';
import { VendorReferenceModal } from '@/modals/VendorReferenceModal';

import { ReactComponent as View } from '@/assets/view.svg';

import { StyledMenuOption, StyledOptionIcon, StyledSettingsContainer } from '../../styled';

interface IProps {
  emailThreads: Array<IEmailThread>,
  vendor: IVendor,
  placement?: string,
  refetch: () => void,
  offset?: number[],
}

const ViewEmailThread = ({ vendor, emailThreads, refetch}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <StyledOptionIcon onClick={() => setIsOpen(true)}><View width='16' height='16' fill='grey' />&nbsp;View</StyledOptionIcon>
      <VendorReferenceModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        vendor={vendor}
        refetch={refetch}
        emailThreads={emailThreads}
      />
    </>
  );
};

const EmailThreadActions = ({ vendor, emailThreads, refetch}: IProps) => (
  <StyledSettingsContainer>
    <StyledMenuOption>
      <ViewEmailThread vendor={vendor} emailThreads={emailThreads} refetch={refetch}/>
    </StyledMenuOption>
  </StyledSettingsContainer>
);

export const EmailThread = (props: IProps) => (
  <PopperMenuContainer content={<EmailThreadActions placement='bottom-start' offset={[-150, 10]} {...props} />} />
);
