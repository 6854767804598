import styled from 'styled-components';

interface IProps {
  borderType?: string,
  disableDivider?: boolean,
}

export const BorderedContainer = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: ${({ theme, borderType }) => (borderType === 'attention' ? theme.color.border.negative : theme.color.border.light)};
  border-radius: 12px;

  & > * {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: ${({ theme, disableDivider }) => (disableDivider ? 'none' : theme.color.border.light)};
  }

  & > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;
