import styled from 'styled-components';

import { H2, H3, StyledLink } from '@/components/designSystem/Typography';
import { ReactComponent as LeftChevron } from '@/assets/chevron_left.svg';

// Grid components

export const PageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(400px, 3fr) minmax(300px, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header header"
    "main secondary";
  min-height: 100vh;
  width: 100%;
  column-gap: 5.25rem;
`;

export const Header = styled.div`
  grid-area: header;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
`;

export const MainColumn = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 3rem;
  }
`;

export const SecondaryColumn = styled.div`
  grid-area: secondary;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 2rem;
    border-bottom: ${(props) => props.theme.color.border.light};
  }

  & > :last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

// Section Containers

export const MainItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 1rem;
  }
  & > :last-child {
    margin-bottom: 0;
  }`;

export const SecondaryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SecondaryInfoContainer = styled.div`
display: flex;
flex-direction: column;
& > * {
  margin-bottom: 2rem;
}
`;

// Typography

export const MainItemTitle = styled(H2).attrs({ bold: true })`
  margin-bottom: 1.5rem;
`;

export const SecondaryItemTitle = styled(H3).attrs({ bold: true })`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

// Other Components

export const TitleWithStatus = styled.div`
  display: flex;
  font-size: 32px;
`;
