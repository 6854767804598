import React, { useState } from 'react';

import {
  ToggleBlockContainer,
  ToggleBlockHeader,
  ToggleBlockContent,
  ToggleIcon,
} from './styled';

interface IProps {
  header: React.ReactNode,
  children: React.ReactNode,
  isAvailableToOpen?: boolean,
}

export const ToggleBlock = ({
  header, children, isAvailableToOpen = true,
}: IProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <ToggleBlockContainer>
      <ToggleBlockHeader onClick={() => isAvailableToOpen ? setOpen(!isOpen) : null}>
        {header}
        {isAvailableToOpen
          ? <ToggleIcon className={isOpen ? 'is-open' : ''}/>
          : null}
      </ToggleBlockHeader>
      {isOpen ? (
        <ToggleBlockContent>
          {children}
        </ToggleBlockContent>
      ) : false}
    </ToggleBlockContainer>
  );
};
