import React, { useState, useCallback, useEffect } from 'react';

import * as Formik from 'formik';

import { debounce, noop, isEqual } from 'lodash/fp';
import { formatDateToTimeAgo } from '@/utils/date';

import { Caption } from '@/components/designSystem/Typography';

interface IProps {
  debounceMs: number,
  lastSaved?: string | null,
  showText?: boolean | true
}

export const AutoSave = ({ debounceMs = 1000, lastSaved = null, showText = true }: IProps) => {
  const {
    submitForm, values, initialValues, isSubmitting,
  } = Formik.useFormikContext();
  const [lastValues, setLastValues] = useState(initialValues);
  const [updatedAt, setUpdatedAt] = useState<Date | string | null>(lastSaved);

  const debouncedSubmit = useCallback(
    debounce(debounceMs, () => submitForm().then((newUpdatedAt: any) => setUpdatedAt(newUpdatedAt || new Date()))),
    [submitForm, debounceMs],
  );

  useEffect(() => {
    const dirty = !isEqual(values, lastValues);
    if (dirty) {
      debouncedSubmit();
    }

    setLastValues(values);

    return noop;
  }, [debouncedSubmit, values]);

  if (isSubmitting && showText) {
    return (
      <Caption color='secondary'>
      Saving...
      </Caption>
    );
  }

  if (updatedAt && showText) {
    return (
      <Caption color='secondary'>
      Last saved {formatDateToTimeAgo(updatedAt)}
      </Caption>
    );
  }
  return null;
};
