import styled from 'styled-components';

import { TitleCallout } from '@/components/Callout/TitleCallout';
import { BorderedContainer } from '@/containers/BorderedContainer';
import {
  FlexColumn,
  FlexRowCenterItemsCenter,
  FlexRowItemsCenter,
  FlexRowSpaceBetweenItemsCenter
} from '@/components/designSystem/Layout';

import { ReactComponent as Close } from '@/assets/x_circle.svg';
import { BodyText } from '@/components/designSystem/Typography';

export const StyledClose = styled(Close)`
  align-self: center;
  min-width: 20px;
  color: ${({ theme }) => theme.color.greyDark};
  &:hover {
    color: ${({ theme }) => theme.color.negative};
  }
`;

export const StyledTitleCallout = styled(TitleCallout)`
  margin-bottom: 2.5rem;
`;

export const Container = styled(FlexColumn)`
  & > :first-child {
    margin-bottom: 2.5rem;
  }
`;

export const SectionContainer = styled(FlexColumn)`
  padding-bottom: 1rem;
  margin-bottom: 3.5rem;
  border-bottom: ${({ theme }) => theme.color.border.light};
`;

export const SectionTitleContainer = styled(FlexColumn)`
  border-bottom: ${({ theme }) => theme.color.border.light};
  padding: 1rem 0;
  margin-bottom: 1.5rem;
`;

export const RowWrapper = styled(FlexRowCenterItemsCenter)`
  flex-basis: 18rem;
`;

export const StyledCheckboxWrapper = styled(FlexRowItemsCenter)`
  margin-right: 2rem;
`;

interface IStyledEnableRequiredRow {
  first?: boolean;
}

export const StyledEnableRequiredRow = styled(FlexRowSpaceBetweenItemsCenter) <IStyledEnableRequiredRow>`
  justify-content: normal;
  border-top: ${({ first }) => first ? 'none' : 'solid 0.02em #ECECEC'};
  margin-top: ${({ first }) => first ? '2rem' : '0.5rem'};
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 60%;
  min-width: 50rem;

  & > :first-child {
    width: 12rem;
  }
`;

export const StyledOptionsContainer = styled(BorderedContainer)`
  & > * {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  & > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  margin-bottom: 1.25rem;
`;

export const StyledOptionsContainerDescription = styled(BodyText)`
  margin-top: 1.75rem;
`;