import gql from 'graphql-tag';

export const PROVIDER_UPDATE_APPLICATION = gql`
  mutation ProviderUpdateApplication(
    $applicationId: ID!
    $applicationAttrs: ApplicationInput!
  ) {
    providerUpdateApplication(applicationId: $applicationId, applicationAttrs: $applicationAttrs) {
      id
    }
  }
`;
