import React, { Dispatch, SetStateAction, useState } from 'react';

import { getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IStakeholder } from '@/types/stakeholder';

import { EmptyState } from '@/components/EmptyState';
import { StyledLinkButtonUnderline } from '@/components/designSystem/Typography';
import { CardsContainer } from '@/components/designSystem/cards/CardsContainer';

import { Contact } from './Contact';
import { StyledShowAllContactsWrapper } from './styled';
import { ContactsSectionUpdateView } from './ContactsSectionUpdateView';

interface IProps {
  customerData: ICustomer,
  isEditActive: boolean
  setEditActive: Dispatch<SetStateAction<boolean>>,
  refetch: () => void,
}

export const ContactsSectionContent = ({ customerData, isEditActive, setEditActive, refetch }: IProps) => {
  const [allContactsShowed, setAllContactsShowed] = useState(false);
  const stakeholders: IStakeholder[] = getOr([], 'seekerCompany.stakeholders', customerData);

  if (!stakeholders.length) {
    return (
      <EmptyState>No Company Contacts provided yet</EmptyState>
    )
  }

  if (isEditActive) {
    return (
      <ContactsSectionUpdateView stakeholders={stakeholders} customerData={customerData} refetch={refetch} setEditActive={setEditActive} />
    )
  }

  return (
    <CardsContainer>
      <Contact key={stakeholders[0].id} contact={stakeholders[0]} />
      {stakeholders.slice(1, 3).map((stakeholder: IStakeholder) => <Contact key={stakeholder.id} contact={stakeholder} />)}
      {stakeholders.length > 3 && (
        allContactsShowed ? (
          stakeholders.slice(3).map((stakeholder: IStakeholder) => <Contact key={stakeholder.id} contact={stakeholder} />)
        ) : (
          <StyledShowAllContactsWrapper>
            <StyledLinkButtonUnderline onClick={() => setAllContactsShowed(true)}>Show all contacts</StyledLinkButtonUnderline>
          </StyledShowAllContactsWrapper>
        )
      )}
    </CardsContainer>
  )
};
