import React from 'react';

import { get } from 'lodash/fp';

import { Label } from '@/components/Label/Label';

import { FinicityAccount } from './FinicityAccount';

import {
  InstitutionTitleContainer,
  InstitutionTitle,
  FinicityInstitutionContainer,
} from './styled';
import { IAutomaticAccountInterface } from '@/types/automaticAccount';

interface IProps {
  accounts: IAutomaticAccountInterface[],
}

export const FinicityInstitution = ({ accounts }: IProps) => {
  const institutionName = get('[0].lastAccount.institution.name', accounts);

  return (
    <FinicityInstitutionContainer>
      <InstitutionTitleContainer>
        <InstitutionTitle>{institutionName}</InstitutionTitle>
        <Label type='success'>Automatic ✨</Label>
      </InstitutionTitleContainer>
      {accounts.map((account) => <FinicityAccount key={account.id} account={account} />)}
    </FinicityInstitutionContainer>
  );
};
