import React from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { H3, BodyText } from '@/components/designSystem/Typography';
import { Callout } from '@/components/Callout/Callout';

interface IProps {
    customer: ICustomer,
  }

export const DeniedMessage = ({ customer }: IProps) => {
  const providerName = get('providerCompany.name', customer);

  return (
    <Callout color='pastelRed'>
      <H3 bold>Credit Request Denied</H3>
      <BodyText>If you wish to petition please contact {providerName}</BodyText>
    </Callout>
  );
};

export const AcceptMessage = ({ customer }: IProps) => {
  const providerName = get('providerCompany.name', customer);

  return (
    <Callout color='pastelRed'>
      <H3 bold>Credit Request Approved</H3>
      <BodyText>If you wish to petition please contact {providerName}</BodyText>
    </Callout>
  );
};

export const PendingApprovalMessage = ({ customer }: IProps) => {
  const providerName = get('providerCompany.name', customer);

  return (
    <Callout color='pastelBlue'>
      <H3 bold>{providerName} is reviewing your application</H3>
      <BodyText>You will receive an email when this applications status changes</BodyText>
    </Callout>
  );
};

export const SignedMessage = () => (
  <Callout color='pastelGreen'>
    <BodyText>The agreement has been successfully signed, your document will appear here shortly.</BodyText>
  </Callout>
);
