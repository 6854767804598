import { useState } from 'react';

import { IBasicInfoValues } from '../BasicInfoStep/types';
import { initialOwnerFields } from './initialValues';

interface IArguments {
  basicInfo: IBasicInfoValues,
  setFieldValue: (field: string, value: string | boolean) => void,
  setFieldTouched: (field: string, isTouched?: boolean | undefined) => void,
}

export const useCurrentUserOwner = ({ basicInfo, setFieldValue, setFieldTouched }: IArguments) => {
  const [currentUserOwner, setCurrentUserOwner] = useState(false);

  const currentUserOwnerClickHandle = () => {
    if (currentUserOwner) {
      setFieldValue('isCurrentUserOwner', false);
      setFieldValue('owners[0].name', initialOwnerFields.name);
      setFieldValue('owners[0].email', initialOwnerFields.email);
      setFieldValue('owners[0].position', initialOwnerFields.position)
      setFieldValue('owners[0].phoneNumber', initialOwnerFields.phoneNumber);
      setFieldValue('owners[0].ownershipPercentage', initialOwnerFields.ownershipPercentage);
    } else {
      setFieldValue('isCurrentUserOwner', true);
      setFieldValue('owners[0].name', basicInfo.name);
      setFieldValue('owners[0].email', basicInfo.email);
      setFieldValue('owners[0].position', basicInfo.position)
      setFieldValue('owners[0].phoneNumber', basicInfo.phoneNumber);
      setFieldValue('owners[0].ownershipPercentage', '0');
    }
    setTimeout(() => setFieldTouched('owners[0].name', true));
    setTimeout(() => setFieldTouched('owners[0].email', true));
    setTimeout(() => setFieldTouched('owners[0].position', true));
    setTimeout(() => setFieldTouched('owners[0].phoneNumber', true));
    setTimeout(() => setFieldTouched('owners[0].ownershipPercentage', true));
    setCurrentUserOwner((prev) => !prev)
  };

  return {
    currentUserOwner,
    currentUserOwnerClickHandle
  };
};
