import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { FlexColumn, FlexRowEnd } from '../components/designSystem/Layout';

const Container = styled(FlexColumn)`
  padding: 2rem;
`;

const StyledButton = styled(Button)`
  width: 7.5rem;
`;

const ActionContainer = styled(FlexRowEnd)`
  padding: 1.5rem;
  /* border-top: ${({ theme }) => theme.color.border.light}; */

  & > * {
    margin-right: 1rem;
  }

  & > :last-child{
    margin-right: 0;
  }
`;

interface IProps {
  isOpen: boolean,
  title: string,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  buttonText: string,
  action: () => void,
  children: React.ReactNode,
  loading?: boolean,
  onSuccess?: () => void,
  disableBrand?: boolean,
}

export const ConfirmActionModal = (props: IProps) => {
  const {
    setIsOpen, buttonText, action, children, loading, disableBrand
  } = props;
  return (
    <StyledModal {...props}>
      <Container>
        {children}
      </Container>
      <ActionContainer>
        <StyledButton onClick={() => setIsOpen(false)}>Cancel</StyledButton>
        {!disableBrand?
          <StyledButton loading={loading} onClick={action}>{buttonText}</StyledButton>
          :
          <StyledButton loading={loading} onClick={action}>{buttonText}</StyledButton>
        }
      </ActionContainer>
    </StyledModal>
  );
};
