import React from 'react';

import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

interface IProps {
  name: string,
  position: string,
  email: string,
}

export const OtherPersonFields = ({ name, position, email }: IProps) => (
  <Row>
    {/* All these TextField names are fake. We need for formik fields.
        If you need to enable these text fields at some point, you will need to fix names */}
    <TextField type='text' name='otherPerson.name' disabled label='Full Name' placeholder={name} />
    <TextField type='text' name='otherPerson.title' disabled label='Title' placeholder={position} />
    <TextField type='text' name='otherPerson.email' disabled label='Email Address' placeholder={email} />
  </Row>
);
