import React, { useState, useContext } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { ANSWER_CUSTOM_FIELDS_BY_CP } from '@/graphql/mutations/answerCustomFields';

import { Formik, Form } from 'formik';

import { get, getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { mapFormValuesToFieldAnswers } from '@/utils/customFields';

import { ICustomer } from '@/types/customer';
import { ICustomField, ICustomFieldValue } from '@/types/customField';

import { VisitorContext } from '@/providers/VisitorContext';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { supportingInfoInitialValues } from './initialValues';
import { supportingInfoValidation } from './validation';
import { CUSTOM_FIELD_TYPES } from '@/constants';
import { StyledButtonsContainer } from '../../../styled';
import { CustomField, CustomFieldAnswers } from '@/components/CustomField/CustomField';
import { Button } from '@/components/designSystem/buttons';
import { TextField } from '@/components/designSystem/Form/TextField';
//New
import { MutationFunction } from '@apollo/react-hooks';
import { SectionContainer } from '../../../SectionContainer';
import { StyledGrayBox } from '../../../styled';
import { BodyText } from '@/components/designSystem/Typography';


interface IValues {
  einFederalTaxNumber: string,
  customFields: ICustomFieldValue,
}

interface ISupportingInfoSectionProps {
  customer: ICustomer,
  refetch: () => void,
  providerUpdateCompany: MutationFunction,
  canSeeEditControl: boolean,
}

export const SupportingInfoSection = ({ customer, providerUpdateCompany, refetch, canSeeEditControl }: ISupportingInfoSectionProps) => {
  const [isEditActive, setEditActive] = useState(false);

  const visitorData = useContext(VisitorContext);
  const customFieldAnswers = getOr([], 'customFieldAnswers', customer);
  const einFederalTaxNumber = getOr('', 'latestApplication.einFederalTaxNumber', customer);
  const requireEinFederalTaxNumber = getOr(false, 'providerCompany.settings.requireEinFederalTaxNumber', customer);
  const customFields: ICustomField[] = getOr([], 'providerCompany.customFields', customer);
  const customFieldsForSupportingInfoSection: ICustomField[] = customFields
    .filter(customField => (customField.type !== CUSTOM_FIELD_TYPES.CONTACT && customField.type !== CUSTOM_FIELD_TYPES.FILE_UPLOAD));

  const [answerCustomFields, { loading }] = useMutation(ANSWER_CUSTOM_FIELDS_BY_CP);

  if (customFieldsForSupportingInfoSection.length === 0) return null;

  const updateCustomFields = async (formValues: IValues) => {
    const customerId = get('id', customer);
    const customFieldAnswersValue = mapFormValuesToFieldAnswers(
      formValues.customFields,
      customFieldsForSupportingInfoSection
    )
    const response = await answerCustomFields({
      variables: {
        customerId: customerId,
        visitorData: visitorData,
        customFieldAnswers: customFieldAnswersValue,
        applicationAttrs: {
          einFederalTaxNumber: formValues.einFederalTaxNumber,
        },
      },
    });

    const success = get('data.answerCustomFieldsByCp', response);
    if (success) {
      showToast({
        title: 'Saved',
        description: 'The changes to this application were successfully saved',
        type: toast.TYPE.SUCCESS,
      });
      setEditActive(false);
      refetch();
    }
  };

  return (
    <SectionContainer
      title='Supporting Information'
      isEditEnabled={!isEditActive}
      canSeeEditControl={canSeeEditControl}
      editSection={() => setEditActive(true)}
      onCloseClick={() => setEditActive(false)}
    >
      <StyledGrayBox>
        {isEditActive && canSeeEditControl ? (
          <Formik
            validateOnChange
            initialValues={supportingInfoInitialValues(customFieldsForSupportingInfoSection, customFieldAnswers, einFederalTaxNumber)}
            validationSchema={supportingInfoValidation(customFieldsForSupportingInfoSection, requireEinFederalTaxNumber)}
            onSubmit={(values) => handleSubmitWrapper(values, updateCustomFields)}
            enableReinitialize
          >
            <Form>
              <TextField
                type='text'
                required={requireEinFederalTaxNumber}
                name='einFederalTaxNumber'
                label='EIN / Federal Tax Number'
                placeholder='Enter EIN / Federal Tax ID Number'
              />
              {customFieldsForSupportingInfoSection.map((customField) => (
                <CustomField
                  key={customField.id}
                  name={`customFields.${customField.id}`}
                  {...customField}
                  options={customField.options}
                />
              ))}
              <StyledButtonsContainer>
                <Button onClick={() => setEditActive(false)} secondary>Cancel</Button>
                <Button type='submit' primary>Save</Button>
              </StyledButtonsContainer>
            </Form>
          </Formik>
        ) : (
          <>
            <BodyText>EIN / Federal Tax Number: {einFederalTaxNumber? einFederalTaxNumber : 'Not Provided'}<br/></BodyText>
            {customFieldsForSupportingInfoSection.map((customField) => (
              <CustomFieldAnswers
                key={customField.id}
                name={`customFields.${customField.id}`}
                {...customField}
                options={customField.options}
                customFieldAnswers={customFieldAnswers}
              />
            ))}
          </>
        )}
      </StyledGrayBox>
    </SectionContainer>
  );
};
