import React, { useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { StyledModal } from '@/modals/StyledModal';
import { H3, Caption, } from '@/components/designSystem/Typography';
import { FlexColumnItemsCenter } from '../components/designSystem/Layout';

const Container = styled(FlexColumnItemsCenter)`
  justify-content: space-between;
  padding: 20px;
  text-align: left;
`;


export const StyledCaption = styled(Caption)`
 font-size: 18px;
 line-height: 28px;
 width:100%;
 text-align: left;
 padding-top: 20px;
`;

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  providerCompanyName: string,
}

export const SignatureThankYouModal = ({isOpen, setIsOpen, providerCompanyName}: IProps) => {

  useEffect(() => {
    console.log('')
  }, []);
  return (
    <>
      <StyledModal
        title={'Thank You!'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disableClose
        blockClose
      >
        <Container>
          <H3>
            Thank you for submitting your credit application to {providerCompanyName}. 
            We will review your application and get back to you as soon as possible.
          </H3>
          <StyledCaption>
            You can safely close this browser.
          </StyledCaption>
        </Container>
      </StyledModal>
    </>
  );
};
