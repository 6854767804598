import styled from 'styled-components';
import device from '@/styles/device';

import get from 'lodash/fp/get';

import { Button } from '@/components/designSystem/buttons';
import { FlexColumn, FlexColumnCenterItemsCenter, FlexRow, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

import { ReactComponent as XIcon } from '@/assets/x_circle.svg';

// CONNECT BANK VIEW

export const StyledContainer = styled(FlexColumnCenterItemsCenter)`
  width: 50vw;
  max-width: 600px;
`;

export const StyledHeaderImageContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledBulletsContainer = styled(FlexColumn)`
  margin-bottom: 1.5rem;
`;

interface ITextButtonProps {
  bold?: boolean,
}

export const StyledTextButton = styled.button<ITextButtonProps>`
  margin-top: 1.5rem;
  font-size: 15px;
  line-height: 32px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.text.secondary};
  background-color: none;
  background: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

// BULLET

export const StyledBulletContainer = styled(FlexRow)`
  padding-top: 1.5rem;
`;

export const StyledIconContainer = styled(FlexColumnCenterItemsCenter)`
  & > svg {
    color: ${({ theme }) => theme.color.highVizGreen};
    margin-right: 1.25rem;
    height: 1.5rem;
  }
`;

// BANK CONNECTED VIEW

export const StyledBankConnectedViewContainer = styled(StyledContainer)`
  gap: 2rem;
`;

export const StyledCheckImageContainer = styled(FlexColumnCenterItemsCenter)`
  width: 80px;
  height: 80px;
  margin-bottom: 1.5rem;
  background: ${({ theme }) => theme.color.brand};;
  border-radius: 100px;

  & > svg {
    height: 28px;
    width: 39px;
    color: ${({ theme }) => theme.color.white};
  };
`;

export const StyledBankConnectedViewNextButtonWrapper = styled.div`
  margin-top: 3rem;
  align-self: flex-end;
`

// MANUAL BANK

export const StyledCalloutContainer = styled.div`
  margin: 2rem 0;
`;

export const StyledCalloutAction = styled(FlexRowSpaceBetweenItemsCenter)`
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: ${({ theme, color }) => get(`color.${color}`, theme)};
  gap: 1rem;

  @media ${device.laptop} {
    flex-direction: row;
  }

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.mobileXL} {
    flex-direction: column;
  }
`;

export const StyledClose = styled(XIcon)`
  align-self: center;
  width: 8rem;
  color: ${({ theme }) => theme.color.greyDark};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.negative};
  }
`;

export const StyledManualBankContainer = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.color.greyLight};
  margin-bottom: 1.5rem;
`;

export const StyledCloseIconWrapper = styled.div`
  cursor: pointer;
`;

export const StyledUploadBankStatementsContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledFlexButton = styled(Button)`
  flex-shrink: 1;
`;

export const StyledTitleContainer = styled(FlexColumn)`
  margin-bottom: 1.5rem;
`;
