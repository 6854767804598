import gql from 'graphql-tag';

export const GET_OR_CREATE_AGREEMENT = gql`
  query GetOrCreateAgreement($customerId: ID!) {
    getOrCreateAgreement(customerId: $customerId) {
      embeddedUrl
    }
  }
`;

export const GET_AGREEMENT_FOR_MANUAL_CUSTOMER = gql`
  query GetAgreementForManualCustomer($customerId: ID!, $userId: ID!, $name: String, $email: String) {
    getAgreementForManualCustomer(customerId: $customerId, user_id: $userId, name: $name, email: $email) {
      embeddedUrl
    }
  }
`;

export const GET_SIGNED_AGREEMENT = gql`
  query GetSignedAgreement($customerId: ID!, $userId: ID!) {
    GetSignedAgreement(customerId: $customerId, userId: $userId){
      status
      inserted_at
    }
  }
`;