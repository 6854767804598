import React from 'react';
import styled from 'styled-components';

import { getOr } from 'lodash/fp';
import { formatCurrency } from '@/utils/money';

import { H2 } from '@/components/designSystem/Typography';
import { StatusLabel } from '@/components/Label/StatusLabel';
import { LabeledComponent } from '@/components/LabeledComponent';
import { FlexRowSpaceEvenly } from '@/components/designSystem/Layout';

import device from '@/styles/device';
import { IApplication } from '@/types/application';

const AgreementInfoContainer = styled(FlexRowSpaceEvenly)`
  flex: 1;
  align-items: flex-end;
  padding: 1rem;

  @media ${device.laptopL} {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem;

    & > * {
      margin-bottom: 1.5rem;
    }

    & > :last-child {
    margin-bottom: 0;
  }
  };
`;

interface IProps {
  application: IApplication,
  seeker?: boolean,
}

export const ApplicationInfo = ({ application, seeker = false }: IProps) => {
  const creditLimit = getOr('--', 'creditLimit', application);
  const creditTerms = getOr('--', 'creditTerms', application) || '--';
  const status = getOr('--', seeker ? 'seekerDisplayStatus' : 'providerDisplayStatus', application);
  const isPending = status === 'pending_approval';

  return (
    <AgreementInfoContainer>
      <LabeledComponent bottom label={isPending ? 'Requested CreditLimit' : 'CreditLimit'}>
        <H2>{creditLimit && typeof creditLimit === 'number' ? formatCurrency(creditLimit) : '--'}</H2>
      </LabeledComponent>
      <LabeledComponent bottom label={isPending ? 'Requested Terms' : 'Terms'}><H2>{creditTerms}</H2></LabeledComponent>
      <LabeledComponent bottom label='Status'><H2><StatusLabel value={status} /></H2></LabeledComponent>
    </AgreementInfoContainer>
  );
};
