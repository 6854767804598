import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

const baseUserValidation = {
  name: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  email: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  position: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
};

const ownershipPercentageValidation = Yup.number().min(0, 'Cannot be less than 0%').max(100, 'Cannot be more than 100%');

const ownerValidation = Yup.object({
  ...baseUserValidation,
  ownershipPercentage: ownershipPercentageValidation.required(DEFAULT_ERROR_MESSAGES.REQUIRED),
}).shape({});

export const companyContactsStepValidation = Yup.object().shape({
  owners: Yup.array().of(ownerValidation),
})
