import React, { useState, useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { StyledModal } from '@/modals/StyledModal';
import { H3, Caption, } from '@/components/designSystem/Typography';
import { FlexColumnItemsCenter } from '../components/designSystem/Layout';
import { ICompany } from "@/types/company";

const Container = styled(FlexColumnItemsCenter)`
  justify-content: space-between;
  padding: 20px;
`;


export const StyledCaption = styled(Caption)`
 font-size: 18px;
 line-height: 28px;
 text-align: center;
 color: ${({ color }) => color};
 padding-bottom: 10px;
`;

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  fromCompany: ICompany,
}

export const SignatureCompletedModal = ({isOpen, setIsOpen, fromCompany}: IProps) => {

  useEffect(() => {
    console.log('')
  }, []);
  return (
    <>
      <StyledModal
        title={'This agreement is already signed.'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disableClose
        blockClose
      >
        <Container>
          <H3>
            The agreement link is not active anymore. Please contact {fromCompany.name} for assistance.
          </H3>
        </Container>
      </StyledModal>
    </>
  );
};
