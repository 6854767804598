import React from 'react';

import { H4 } from '@/components/designSystem/Typography';
import { FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { StyledCloseIconWrapper, StyledContactContainer } from './styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledFormRow } from '@/components/designSystem/Form/styled';

import { ReactComponent as CloseIcon } from '@/assets/x_close.svg';

interface IProps {
  parentKey: string,
  disabled?: boolean,
  onRemoveClick?: () => void,
  index?: number,
}

export const OwnerSection = ({ parentKey, disabled, onRemoveClick, index = 0 }: IProps) => (
  <StyledContactContainer>
    <FlexRowSpaceBetweenItemsCenter>
      <H4 bold>Owner #{index + 1}</H4>
      {(onRemoveClick && Number(index) > 0) && (
        <StyledCloseIconWrapper>
          <CloseIcon onClick={onRemoveClick} />
        </StyledCloseIconWrapper>
      )}
    </FlexRowSpaceBetweenItemsCenter>
    <StyledFormRow>
      <TextField required type='text' disabled={disabled} name={`${parentKey}.name`} label='Owner Name/Corporation Name' placeholder='John Doe' />
      <TextField required type='text' disabled={disabled} name={`${parentKey}.position`} label='Title' placeholder='CFO' />
    </StyledFormRow>
    <StyledFormRow>
      <TextField required type='number' name={`${parentKey}.ownershipPercentage`} label='Ownership Stake' placeholder='XX %' />
      <PhoneNumberField required disabled={disabled} name={`${parentKey}.phoneNumber`} label='Phone Number' placeholder='Phone number' />
      <TextField required type='email' disabled={disabled} name={`${parentKey}.email`} label='Email Address' placeholder='John@streamlink.com' />
    </StyledFormRow>
  </StyledContactContainer>
);