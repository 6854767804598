import styled from 'styled-components';

import { GrayBox } from '@/components/designSystem/GrayBox';
import { ReactComponent as ToggleChevron } from '@/assets/toggle_chevron.svg';

export const ToggleBlockContainer = styled.div`
  margin-bottom: 1rem;
`;

export const ToggleBlockHeader = styled(GrayBox)`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const ToggleBlockContent = styled.div`
  background-color: ${({ theme }) => theme.color.greyLight};
  padding: 1rem;
`;

interface IProps {
  isOpen: boolean,
}

export const ToggleIcon = styled(ToggleChevron)<IProps>`
  margin-right: 1rem;

  &.is-open {
    transform: rotate(90deg);
  };
`;
