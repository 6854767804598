import { get } from 'lodash/fp';

import { IManualBank } from '@/types/manualBank';

export const useManualAccountFields = (institution: IManualBank) => {
  const institutionName = get('institutionName', institution);
  const institutionAddress = get('institutionAddress', institution);
  const contactName = get('contactName', institution);
  const contactTitle = get('contactTitle', institution);
  const contactEmail = get('contactEmail', institution);
  const contactPhone = get('contactPhone', institution);
  const showNonBusinessEmailDomainWarning = get('showNonBusinessEmailDomainWarning', institution);

  return {
    institutionName,
    institutionAddress,
    contactName,
    contactTitle,
    contactEmail,
    contactPhone,
    showNonBusinessEmailDomainWarning
  };
}
