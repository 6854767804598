import styled from 'styled-components';

import { FlexColumn, FlexRowItemsCenter, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

import CreditSafeLogo from '@/assets/CreditSafeLogo.png';
import EquifaxLogo from '@/assets/EquifaxLogo.png';
import ExperianLogo from '@/assets/ExperianLogo.png';
import DnBLogo from '@/assets/DnBLogo.png';
import SalesforceLogo from '@/assets/SalesforceLogo.png';

export const StyledPageContainer = styled(FlexColumn)`
  width: 100%;
  max-width: 800px;

  & > * {
    margin-bottom: 3rem;
  }
`;

export const StyledPageTitleContainer = styled(FlexRowSpaceBetweenItemsCenter)`
  margin-bottom: 1.5rem;
`;

export const StyledRow = styled(FlexRowItemsCenter)`
  & > * {
    margin-right: 0.5rem;
  }
`;

export const StyledCSLogo = styled.img.attrs({ src: CreditSafeLogo })`
  height: 50px;
`;

export const StyledExperianLogo = styled.img.attrs({ src: ExperianLogo })`
  height: 50px;
`;

export const StyledDnbLogo = styled.img.attrs({ src: DnBLogo })`
  height: 50px;
`;

export const StyledSalesforceLogo = styled.img.attrs({ src: SalesforceLogo })`
  height: 90px;
  margin-left: -14px;
`;

export const StyledEquifaxLogo = styled.img.attrs({ src: EquifaxLogo })`
  height: 50px;
`;