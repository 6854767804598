import React from 'react';

import { get, getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { EmptyState } from '@/components/EmptyState';

import { FinicitySection } from './FinicitySection';
import { ManualSection } from './ManualSection';
import { FinancialActions } from './FinancialActions';

interface IProps {
  customer: ICustomer,
  refetch: () => void,
}

export const SeekerFinanicalHealthSection = ({ customer, refetch }: IProps) => {
  const customerId = get('id', customer);
  const finicityAccounts = getOr([], 'seekerCompany.finicityAccountProjections', customer);
  const manualInstitutions = getOr([], 'seekerCompany.manualInstitutions', customer);
  const autoBav = getOr(false, 'settings.autoBav', customer);

  const status = get('latestApplication.status', customer);
  const isFinalized = status === 'approved' || status === 'denied';

  const noData = finicityAccounts.length === 0
    && manualInstitutions.length === 0;

  return (
    <TwoColumnContainer.MainItem title='Financial Health'>
      {noData && <EmptyState>No Banking Information provided yet</EmptyState>}
      {finicityAccounts.length > 0 && (
        <FinicitySection
          accounts={finicityAccounts}
          // We don't have these props in component
          // customer={customer}
          // refetch={refetch}
        />
      )}
      {manualInstitutions.length > 0 && <ManualSection institutions={manualInstitutions} refetch={refetch} isFinalized={isFinalized} />}
      <FinancialActions isFinalized={isFinalized} refetch={refetch} />
    </TwoColumnContainer.MainItem>
  );
};
