import React from 'react';

import { groupBy, map } from 'lodash/fp';

import { FinicityInstitution } from './FinicityInstitution';
import { IAutomaticAccountInterface } from '@/types/automaticAccount';

interface IProps {
  accounts: IAutomaticAccountInterface[],
}

export const FinicitySection = ({ accounts }: IProps) => {
  const institutions = groupBy('lastAccount.institution.id', accounts);

  return (
    <>
      {map((groupedAccounts) => <FinicityInstitution accounts={groupedAccounts} />, institutions)}
    </>
  );
};
