import React from 'react';

import { IVendor } from '@/types/vendor';

import { EmptyState } from '@/components/EmptyState';
import { VendorReferenceInfo } from '@/components/_oldComponents/VendorReferenceInfo';

interface IProps {
  receivedReferences: IVendor[],
}

export const VendorReferencesList = ({ receivedReferences }: IProps) => {
  if (!receivedReferences.length) {
    return <EmptyState>No Vendor References provided yet</EmptyState>;
  }
  return <>{receivedReferences.map((vendor) => <VendorReferenceInfo vendor={vendor} key={vendor.id || vendor.sourceCompany.inviteId} />)}</>;
};
