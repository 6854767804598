import gql from 'graphql-tag';

export const UPSERT_MANUAL_ACCOUNT_NOTE = gql`
  mutation UpsertManualAccountNote($accountId: ID!, $customerId: ID!, $note: String!) {
    upsertManualAccountNote(accountId: $accountId, customerId: $customerId, note: $note) {
      id
      note
      insertedAt
      updatedAt
      account {
        id
      }
    }
  }
`;
