import React from 'react';

import { get } from 'lodash/fp';

import { ICurrentCompany } from '@/types/currentCompany';

import { BorderedContainer } from '@/containers/BorderedContainer';

import { H4 } from '@/components/designSystem/Typography';
import { LabeledComponent } from '@/components/LabeledComponent';
import { Flex } from '@/components/designSystem/Layout';

import { StyledCSLogo, StyledPageTitleContainer, StyledRow } from './styled';
import { EditCredsButton } from './EditCredsButton';
import { INTEGRATIONS } from './constants';

interface IProps {
  refetch: () => void,
  currentCompany: ICurrentCompany,
}

export const CreditSafeSettings = ({ currentCompany, refetch }: IProps) => {
  const creditSafeUsername = get('settings.creditSafeUsername', currentCompany);

  return (
    <>
      <StyledPageTitleContainer>
        <StyledCSLogo />
        <EditCredsButton type={INTEGRATIONS.CREDIT_SAFE} refetch={refetch} username={creditSafeUsername} />
      </StyledPageTitleContainer>
      <BorderedContainer>
        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Username'>
            <StyledRow>
              <H4 bold>{creditSafeUsername || 'None'}</H4>
            </StyledRow>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Password'>
            <H4 bold>{creditSafeUsername ? '**********' : 'None'}</H4>
          </LabeledComponent>
        </Flex>
      </BorderedContainer>
    </>
  );
};