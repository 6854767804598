import gql from 'graphql-tag';

export const ONBOARD_UPDATE_INPUT = gql`
  mutation OnboardUpdate(
    $onboardData: OnboardCreditProviderInput!
    $visitorData: VisitorData
  ) {
    onboardUpdate(onboardData: $onboardData, visitorData: $visitorData)
  }
`;
