import React from 'react';

import { useFormikContext } from 'formik';

import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';
import { BodyText } from '@/components/designSystem/Typography';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { EnableRequiredOptions, IHandleEnableRequiredOptionsChangeArgs } from '@/components/EnableRequiredOptions';
import { StyledCheckboxContainer } from '../styled';

const KEYS = {
  enabled: 'enablePersonalGuarantee',
  required: 'requirePersonalGuarantee',
}

interface IAddCreditCustomerValues {
  customerId: string,
  companyName: string,
  name: string,
  email: string,
  phoneNumber: string,
  autoBav: boolean,
  enablePersonalGuarantee: boolean,
  requirePersonalGuarantee: boolean,
  requireBankInfo: boolean,
  customMessage: string,
}

export const CreditCustomerForm = () => {
  const { values, setFieldValue } = useFormikContext<IAddCreditCustomerValues>();

  const handlePersonalGuaranteeChange = ({ key, enabled, required }: IHandleEnableRequiredOptionsChangeArgs) => {
    if (key === KEYS.enabled && enabled) {
      setFieldValue(KEYS.enabled, false);
      setFieldValue(KEYS.required, false);
    } else if (key === KEYS.required && !enabled) {
      setFieldValue(KEYS.enabled, true);
      setFieldValue(KEYS.required, true);
    } else {
      setFieldValue(key, key === KEYS.enabled ? !enabled : !required);
    }
  }

  return (
    <>
      <Row>
        <TextField required type='text' name='companyName' label='Company Legal Name' placeholder='e.g., Excel Manufactoring LTD' />
        <TextField required type='text' name='name' label='Full Name' placeholder='Full Name' />
      </Row>
      <Row>
        <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
        <TextField required type='text' name='phoneNumber' label='phoneNumber' placeholder='Phone number' />
        {/* TODO fix: PhoneNumberField is not being set by setFieldValue in CustomerIdField. */}
        {/* <PhoneNumberField required name='phoneNumber' label='Phone Number' placeholder='Phone number' /> */}
      </Row>
      <Row>
        <StyledCheckboxContainer>
          <FieldLabel name='bankVerification'>Bank Information & Verification</FieldLabel>
          <CheckboxField name='autoBav'>
            <BodyText>Enable Automatic Bank Verification</BodyText>
          </CheckboxField>
          <CheckboxField name='requireBankInfo'>
            <BodyText>Require Bank Information</BodyText>
          </CheckboxField>
        </StyledCheckboxContainer>

        <StyledCheckboxContainer>
          <FieldLabel name='requirePersonalGuarantee'>Personal Guarantee</FieldLabel>
          <EnableRequiredOptions
            keys={KEYS}
            enabled={values.enablePersonalGuarantee}
            required={values.requirePersonalGuarantee}
            handleChange={handlePersonalGuaranteeChange}
          />
        </StyledCheckboxContainer>
      </Row>
      <Row>
        <TextAreaField
          required
          name='customMessage'
          label='Custom Message'
          placeholder='Please complete this credit application.'
        />
      </Row>
    </>
  )
}
