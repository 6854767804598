import React from 'react';

import { StyledOuterWrapper, StyledInnerWrapper } from './styled';

import { ReactComponent as ScrollArrowIcon } from '@/assets/arrow_scroll.svg';

interface IProps {
  isScrollArrowUp: boolean,
  handleScrollArrowClick: () => void,
  rightPosition?: string,
}

export const ScrollArrow = ({ isScrollArrowUp, handleScrollArrowClick, rightPosition }: IProps) => (
  <StyledOuterWrapper onClick={handleScrollArrowClick}>
    <StyledInnerWrapper isArrowUp={isScrollArrowUp} onClick={handleScrollArrowClick} rightPosition={rightPosition}>
      <ScrollArrowIcon width='32px' height='32px'/>
    </StyledInnerWrapper>
  </StyledOuterWrapper>
);
