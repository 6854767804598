/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { GET_FINICITY_CONNECT_URL } from '@/graphql/queries/getFinicityConnectUrl';

import { get } from 'lodash/fp';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { FinicityContainer } from './styled';

interface IProps {
    setIsOpen: Dispatch<SetStateAction<boolean>>,
    onSuccess: (event: any) => void,
}

const TIMEOUT_CODE = 1440;

export const FinicityWidget = ({ setIsOpen, onSuccess }: IProps) => {
  const { loading, data } = useQuery(GET_FINICITY_CONNECT_URL, { fetchPolicy: 'no-cache' });
  const connectUrl = get('getFinicityCustomer.connectUrl', data);

  useEffect(() => {
    if (!connectUrl || !window.finicityConnect) return;

    window.finicityConnect.launch(connectUrl, {
      selector: '#finicity-container',
      success: (event: any) => {
        console.log('Yay! User went through Connect', event);
        onSuccess(event);
        setIsOpen(false);
      },
      cancel: (event: any) => {
        console.log('The user cancelled the iframe', event);
        setIsOpen(false);
      },
      error: (event: any) => {
        console.error('Some runtime error was generated during insideConnect ', event);
      },
      loaded: () => {
        console.log('This gets called only once after the iframe has finished loading ');
      },
      route: (event: any) => {
        console.log('This is called as the user navigates through Connect ', event);
      },
      user: (event: any) => {
        console.log('This is called as the user interacts with Connect ', event);
        if (event.data.code === TIMEOUT_CODE) {
          setIsOpen(false);
        }
      },
    });

    // eslint-disable-next-line consistent-return
    return () => window.finicityConnect.destroy();
  }, [connectUrl]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <FinicityContainer id='finicity-container' />
    </>
  );
};
