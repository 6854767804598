import React, { useState } from 'react';

import { Button } from '@/components/designSystem/buttons';
import { EditNameModal } from '@/modals/EditNameModal';

interface IProps {
  refetch: () => void,
  name: string,
}

export const NameEditButton = ({ refetch, name }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Edit</Button>
      <EditNameModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} name={name} />
    </>
  );
};
