import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { CheckItem } from './CheckItem';
import { FlexColumn, FlexRowSpaceEvenly } from '@/components/designSystem/Layout';

const Container = styled(FlexColumn)`
  padding: 2rem;
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const ActionContainer = styled(FlexRowSpaceEvenly)`
  padding: 1.5rem;
  border-top: ${({ theme }) => theme.color.border.light};

  & > * {
    margin-right: 1rem;
  }

  & > :last-child{
    margin-right: 0;
  }
`;

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
}

export const UpgradeModal = ({ setIsOpen, isOpen }: IProps) => (
  <StyledModal
    title='Upgrade to a Premium Plan to'
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    blockClose
  >
    <Container>
      <CheckItem>Add More Customizable Fields</CheckItem>
      <CheckItem>Request Additional Vendor References</CheckItem>
      <CheckItem>Invite More Users</CheckItem>
      <CheckItem>Monitor More Customers Per Month</CheckItem>
      <CheckItem>Send More Credit Applications Per Month</CheckItem>
    </Container>
    <ActionContainer>
      <StyledButton primary afterPath={() => setIsOpen(false)} path='/settings/billing'>Upgrade To Premium</StyledButton>
    </ActionContainer>
  </StyledModal>
);
