import React, { useState } from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { SectionContainer } from '../../SectionContainer';
import { StyledTabPanelContainer } from '../../styled';
import { AddManualBankInformation } from './AddManualBankInformation';
import { BanksList } from './BanksList';
import { StyledManualCustomerViewContainer, StyledButton } from './styled';

interface IProps {
  customerData?: ICustomer,
  refetchCustomerData: () => void,
}

export const Bank = ({ customerData, refetchCustomerData }: IProps) => {
  const customerId = get('id', customerData);

  const [isAddManualBankActive, setAddManualBankActive] = useState(false);

  if (!customerData || !customerId) {
    return <LoadingSpinner />
  }

  return (
    <StyledTabPanelContainer>
      <SectionContainer title='Bank verification'>
        <BanksList customerData={customerData} refetch={refetchCustomerData} />
        <StyledManualCustomerViewContainer>
          {isAddManualBankActive ? (
            <AddManualBankInformation customerData={customerData} setAddManualBankActive={setAddManualBankActive} refetch={refetchCustomerData}/>
          ) : (
            <StyledButton
              secondary
              small
              onClick={() => setAddManualBankActive(true)}
            >
              Add Bank Information
            </StyledButton>
          )}
        </StyledManualCustomerViewContainer>
      </SectionContainer>
    </StyledTabPanelContainer>
  )
};