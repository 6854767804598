import gql from 'graphql-tag';

export const GET_GIVEN_REFERENCES = gql`
  query GetGivenReferences {
    currentCompany {
      id
      givenReferences {
        id
        customerSince
        lastPaymentOn
        lastPaymentAmount
        averageDaysToPay
        creditTerms
        creditLimit
        creditBalanceHigh
        creditBalancePastDue
        comments
        updatedAt
        targetCompany {
          id
          name
          stakeholders {
            id
            name
            email
            position
            extension
            phoneNumber
            customFieldId
            isPrimaryContact
          }
        }
      }
    }
  }
`;
