import React from 'react';

import { SelectField } from '@/components/designSystem/Form/SelectField';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

import { COMPANY_TYPES } from '@/constants';

import { Section } from './Section';
import { PhoneNumberInput } from '@/components/designSystem/Form/PhoneNumberInput';

export const CompanyDetailsSection = () => (
  <Section title='Company Details'>
    <Row>
      {/* All these TextField names are fake. We need for formik fields.
        If you need to enable these text fields at some point, you will need to fix names */}
      <TextField type='text' name='company.name' disabled label='Company Name' placeholder='Enter Company Name' />
      <PhoneNumberInput disabled label='Phone Number' placeholder='Phone number' />
      <TextField type='text' name='company.website' disabled label='Website' placeholder='E.g yourcompany.com' />
    </Row>
    <Row>
      <SelectField disabled name='company.type' defaultValue='LLC' label='Company Type' options={COMPANY_TYPES} />
      <TextField type='text' name='company.year' disabled label='Year Established' placeholder='2003' />
    </Row>
    <Row>
      <TextField type='text' name='company.other' disabled label='Tell Us About Your Business? ' placeholder='Are you a retailer, distributor, manufacturer and in which industry?' />
    </Row>
  </Section>
);
