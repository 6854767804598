import React from 'react';
import styled from 'styled-components';
import { Pulse } from 'styled-spinkit';
import { FlexColumnItemsCenter } from './designSystem/Layout';

const LoadingContainer = styled(FlexColumnItemsCenter)`
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledSpinner = styled(Pulse)`
  background-color: ${({ theme }) => theme.color.brand};
`;

interface IProps {
  size?: number,
}

export const LoadingSpinner = ({ size = 100 }: IProps) => (
  <LoadingContainer>
    <StyledSpinner size={size} />
  </LoadingContainer>
);
