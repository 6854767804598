import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { IWrappedToastContainerProps } from './types';

const WrappedToastContainer = ({ className, ...rest }: IWrappedToastContainerProps) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

export const StyledToastContainer = styled(WrappedToastContainer).attrs({
    // custom props
  })`
    .Toastify__toast-container {
      background: 'red';
    }
    .Toastify__toast {
      padding: 1rem;
      border-radius: 8px;
    }
    .Toastify__toast--info {
      background-color: ${({ theme }) => theme.color.label.info}
    }
    .Toastify__toast--error {
      background-color: ${({ theme }) => theme.color.label.danger}
    }
    .Toastify__toast--warning {
      background-color: ${({ theme }) => theme.color.label.warning}
    }
    .Toastify__toast--success {
      background-color: ${({ theme }) => theme.color.label.success}
    }
    .Toastify__close-button {
      color: ${({ theme }) => theme.color.text.primary}
    }
    .Toastify__toast-body {}
    .Toastify__progress-bar {}
  `;
  
  export const ToastBodyContainer = styled.div`
    display: flex;
    flex-direction:column;
  `;