import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { getOr } from 'lodash/fp';

import { H3 } from '@/components/designSystem/Typography';

const MonogramAvatarContainer = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color};
  text-transform: capitalize;
  margin-right: 1rem;
`;

interface IProps {
  name?: string,
}

export const MonogramAvatar = ({ name }: IProps) => {
  const theme = useContext(ThemeContext as any);
  const profileColors = getOr([], 'color.profile', theme);

  const firstLetter = getOr('?', '[0]', name);
  const alphabetPos = firstLetter.toLowerCase().charCodeAt() - 97;
  const numberColors = profileColors.length;
  const colorIndex = alphabetPos % numberColors;

  const color = profileColors[colorIndex];

  return (
    <MonogramAvatarContainer color={color}>
      <H3 bold>{firstLetter}</H3>
    </MonogramAvatarContainer>
  );
};
