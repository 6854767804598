import React, { useContext } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { INCREASE_TOTAL_REFERENCES } from '@/graphql/mutations/changeNumberOfReferences';

import { getOr, range, flow, map, get } from 'lodash/fp';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { Section } from './Section';
import { VendorFields } from './VendorFields';
import { LoadingSpinner } from '@/components/LoadingSpinner';

export const VendorSection = () => {
  const currentCompanyData = useContext(CurrentCompanyContext);

  if (!currentCompanyData) {
    return <LoadingSpinner />
  }

  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);

  const [increaseTotalVendorRefs, { loading }] = useMutation(INCREASE_TOTAL_REFERENCES);

  const onClick = () => increaseTotalVendorRefs()
    .then(refetchCurrentCompany)
    .catch((error) => {
      showToast({
        title: 'Error',
        description: extractGQLErrorMessage(error),
        type: toast.TYPE.ERROR,
      });
    });

  const numRequiredReferences = getOr(0, 'currentCompany.numRequiredReferences', currentCompanyData);
  const numTotalReferences = getOr(0, 'currentCompany.numTotalReferences', currentCompanyData);
  const canAddVendor = getOr(false, 'currentCompany.planInfo.canAddVendor', currentCompanyData);

  return (
    <Section
      title='Vendor And Trade References'
      description={`Customers are required to add at least ${numRequiredReferences} current vendor and trade references.`}
    >
      {
        flow(
          range(0),
          map((index) => (
            <VendorFields
              key={index}
              index={index}
              company='Google'
              name='Sam Smith'
              phone='(XXX) XXX-XXXX'
              email='s.smith@google.com'
              required={true}
              refetch={refetchCurrentCompany}
            />
          )),
        )(numRequiredReferences as any)
      }
      {
        flow(
          range(numRequiredReferences),
          map((index) => (
            <VendorFields
              key={index}
              index={index}
              company='Google'
              name='Sam Smith'
              phone='(XXX) XXX-XXXX'
              email='s.smith@google.com'
              required={false}
              refetch={refetchCurrentCompany}
            />
          )),
        )(numTotalReferences as any)
      }
      {canAddVendor && <Button primary LeftIcon={Plus} loading={loading} onClick={onClick}>Add Vendor Reference</Button>}

    </Section>
  );
};
