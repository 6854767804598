import { IOnboardValues, IOnboardValuesAction } from './types';

export const UPDATE_ONBOARD_VALUES = 'UPDATE_ONBOARD_VALUES';
export const UPDATE_BASIC_INFO = 'UPDATE_BASIC_INFO';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const UPDATE_COMPANY_CONTACTS = 'UPDATE_COMPANY_CONTACTS';
export const UPDATE_FINANCIAL_HEALTH = 'UPDATE_FINANCIAL_HEALTH';
export const UPDATE_VENDORS = 'UPDATE_VENDORS';
export const UPDATE_OTHER_INFO = 'UPDATE_OTHER_INFO';
export const UPDATE_EMAIL_VERIFIED = 'UPDATE_EMAIL_VERIFIED';

export const onboardValuesReducer = (state: IOnboardValues, action: IOnboardValuesAction) => {
  const { type, payload } = action;
  switch (type) {
  case UPDATE_ONBOARD_VALUES: {
    return {
      ...payload,
    };
  }
  case UPDATE_BASIC_INFO: {
    return {
      ...state,
      basicInfo: payload,
    };
  }
  case UPDATE_COMPANY_PROFILE: {
    return {
      ...state,
      companyProfile: payload,
    };
  }
  case UPDATE_COMPANY_CONTACTS: {
    return {
      ...state,
      companyContacts: payload,
    };
  }
  case UPDATE_FINANCIAL_HEALTH: {
    return {
      ...state,
      financialHealth: payload,
    };
  }
  case UPDATE_VENDORS: {
    return {
      ...state,
      vendors: payload,
    };
  }
  case UPDATE_OTHER_INFO: {
    return {
      ...state,
      otherInfo: payload,
    };
  }
  case UPDATE_EMAIL_VERIFIED: {
    return {
      ...state,
      emailVerified: payload,
    };
  }
  default:
    return state;
  }
}
