import gql from 'graphql-tag';

export const UPDATE_CREDITSAFE_SETTINGS = gql`
  mutation UpdateCreditsafeSettings($settings: CompanySettings!) {
    updateCreditsafeSettings(settings: $settings) {
      updatedAt
    }
  }
`;


