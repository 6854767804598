import { IVendor } from "@/types/vendor";
import { getOr } from "lodash/fp";

export const addVendorInitialValues = (reference?: IVendor) => {
  const vrInvite = getOr(null, 'sourceCompany.stakeholders[0]', reference)
  const companyName = getOr('', 'sourceCompany.name', reference)
  const name = getOr('', 'name', vrInvite)
  const phoneNumber = getOr('', 'phoneNumber', vrInvite)
  const email = getOr('', 'email', vrInvite)

  return {
    companyName: companyName ?? '',
    name: name ?? '',
    phoneNumber: phoneNumber ?? '',
    email: email ?? '',
  }
};
