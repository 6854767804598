export const STEPS = {
  basicInfo: 'Basic Info',
  companyProfile: 'Company Profile',
  stakeholder: 'Company Contacts',
  bank: 'Financial Health',
  vendors: 'Vendors',
  others: 'Other Info',
  agreement: 'Agreement',
};

export const DEFAULT_STEP = 'orientation';

export const COMPLETE_PATH = '/dashboard/requests';
