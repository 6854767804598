import styled from 'styled-components';
import { Form } from 'formik';

import { FlexColumn } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "main";
  height: 100vh;
  background-color: ${(props) => props.theme.color.site.background};
`;

export const StyledStepCounterContainer = styled.div`
  margin: 3rem 0 3rem 3rem;
`;

export const StyledMain = styled.main`
  grid-area: main;
  border-radius: 1.25rem;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: ${({ theme }) => theme.color.shadow.shadowLight};
  min-height: 100vh;
  height: fit-content;
  padding: 2rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 100px minmax(0, 1fr) 100px;
  grid-template-areas:
    "left center right";
`;

export const StyledLeftBlock = styled(FlexColumn)`
  grid-area: left;
  height: 100%;
`

export const StyledRightBlock = styled.div`
  grid-area: right;
`

export const StyledFormContainer = styled(FlexColumn)`
  grid-area: center;
  align-items: center;
`;

// STEP

export const StyledTitleContainer = styled(FlexColumn)`
  margin-bottom: 1.5rem;
`;

export const StyledSubtitleContainer = styled(FlexColumn)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export const StyledForm = styled(Form)`
  width: 60vw;
  max-width: 60rem;
`;

export const StyledCheckboxWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const StyledNextButton = styled(Button)`
  margin-top: 1rem;
  width: 13rem;
`;