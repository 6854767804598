import { get } from "lodash/fp";
import { getWithBackup } from "@/utils/object";
import { formatPhone } from "@/utils/format";

import { IReferralData } from "@/types/referralData"

export const basicInfoInitialValues = (refData: IReferralData) => {
  const currentUserEmail = get('currentUser.email', refData);

  return {
    name: getWithBackup('currentUser.name', 'getReferralData.toName', refData) || '',
    email: currentUserEmail || getWithBackup('currentUser.email', 'getReferralData.toEmail', refData) || '',
    password: '',
    position: getWithBackup('currentCompany.currentUserStakeholder.position', 'getReferralData.toPosition', refData) || '',
    phoneNumber: formatPhone(getWithBackup('currentCompany.currentUserStakeholder.phoneNumber', 'getReferralData.toPhoneNumber', refData)) || '',
    agreeCheck: false,
  }
};
