import React from 'react';
import { useFormikContext } from 'formik';

import { getOr } from 'lodash/fp';

import { SelectField } from '@/components/designSystem/Form/SelectField';
import { TextField } from '@/components/designSystem/Form/TextField';

import { DNB_COUNTRIES, PROVINCES_OR_STATES } from '@/constants';

export const DnbSearchForm = () => {
  const { values } = useFormikContext();
  const country = getOr('US', 'country', values);
  const provinceList = (PROVINCES_OR_STATES as any)[country];

  return (
    <>
      <TextField required type='text' name='name' label='Company Name' placeholder='Full Name' />
      <TextField required type='text' name='address_line_1' label='Address Line 1' placeholder='123 xyz st.' />
      <SelectField name='country' label='Country' options={DNB_COUNTRIES} />
      {provinceList.length > 0 && <SelectField required name='province' label='State / Province' options={provinceList} />}
      <TextField required type='text' name='city' label='City' placeholder='London' />
      <TextField required type='text' name='postal_code' label='Zip / Postal Code' placeholder='90283' />
      <TextField required type='text' name='website' label='Website' placeholder='www.company.com' />
    </>
  );
};
