import { Dispatch, SetStateAction } from 'react';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro-spa';

import { noop } from 'lodash/fp';
import { formatPhoneNumberForRequest } from '@/utils/format';
import { stripStepFromPath } from '@/utils/stripStepFromPath';

import { IOnboardValues } from './types';
import { MutationFunctionOptions } from '@apollo/react-hooks';
import { IAddressComponent, IGooglePlace } from '@/types/googlePlace';
import { IReferenceValues } from './steps/ReferenceStep/types';

const mapGooglePlaceToAddressString = (place: IGooglePlace) => {
  const streetNumber = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('street_number'))?.long_name || '';
  const street = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('route'))?.long_name || '';
  const city = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('locality'))?.long_name || '';
  const state = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_1'))?.long_name || '';
  const country = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('country'))?.long_name || '';
  const streetNumberStreetString = `${streetNumber}${streetNumber && street ? ' ' : ''}${street}`
  return [streetNumberStreetString, state, city, country].filter((element) => element !== '').join(', ');
};

export const setFieldsFromGooglePlace = (place: IGooglePlace, key: string, setFieldValue: (field: string, value: string | null) => void) => {
  const address = mapGooglePlaceToAddressString(place);
  setFieldValue(key, address);
};

const mapOnboardValuesToVendorReferenceData = (onboardValuesReference: IReferenceValues) => {
  const { agreeCheck, ...vendorReferenceData } = onboardValuesReference;
  return vendorReferenceData;
}

interface ISubmitStepArgs {
  onboardValues: IOnboardValues | null,
  pathname: string,
  search: string,
  onboardVendor: (options: MutationFunctionOptions) => Promise<unknown>,
  setShowCompletedModal: Dispatch<SetStateAction<boolean>>,
  visitorData: ExtendedGetResult | null,
}

export const submitStep = ({
  onboardValues,
  pathname,
  search,
  onboardVendor,
  setShowCompletedModal,
  visitorData,
}: ISubmitStepArgs) => {

  if (!onboardValues) {
    return noop;
  }

  const onboardVariables = {
    onboardData: {
      ...onboardValues.complete,
      referralPath: `${stripStepFromPath(pathname)}${search}`,
    },
    vendorReferenceData: mapOnboardValuesToVendorReferenceData(onboardValues.reference),
    referralCode: onboardValues.referralCode,
    redirectTo: '/',
    visitorData
  }

  return onboardVendor({
    variables: onboardVariables,
  }).then(() => {
    setShowCompletedModal(true);
  });
}
