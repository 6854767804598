import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BodyText, H2, H3, H4 } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';
import { get } from 'lodash/fp';
import { StyledNextButton } from '../../styled';
import { OnboardValuesContext } from '../../context';
import {
  StyledClipboardIcon,
  StyledClockIcon,
  StyledContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledRightMessageWrapper,
  StyledShieldIcon,
  StyledLinkButton
} from './styled';

//Reruting user Login
import { Formik } from 'formik';
import { useAuth0 } from '@/providers/Auth0/Auth0';
import { loginValidation, forgotPasswordValidation} from './validation';
import { loginInitialValues, forgotPasswordInitialValues } from './initialValues';
import { ILoginValues, IForgotPasswordValues } from './types';
import { ReturningLoginForm, ReturningForgotPasswordForm } from './form';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CONTENT_BY_PROVIDER_ID } from '@/graphql/queries/getContent';
import { FORGOT_PASSWORD } from '@/graphql/mutations/forgotPassword';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';

export interface IProps {
  companyName: string,
  providerCompanyId: string,
}

export const OrientationStep = ({ companyName, providerCompanyId }: IProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const onboardValues = useContext(OnboardValuesContext);

  const { data: leftdata } = useQuery(GET_CONTENT_BY_PROVIDER_ID, { variables: { name: 'OrientationLeft', providerCompanyId: providerCompanyId } });
  const { data: rightdata } = useQuery(GET_CONTENT_BY_PROVIDER_ID, { variables: { name: 'OrientationRight', providerCompanyId: providerCompanyId } });

  const lefttitle = get('getContentByProviderId.title', leftdata)
  const leftbody = get('getContentByProviderId.body', leftdata)
  const righttitle = get('getContentByProviderId.title', rightdata)


  const startOnboarding = () => {
    if (!onboardValues) {
      return;
    }
    history.push({ pathname: `${onboardValues.basePath}/basicInfo`, search });
  }

  const { loginWithCompany, loading } = useAuth0();
  const handleLogin = (values: ILoginValues) => {
    const { email, password } = values;
    loginWithCompany(email, password);
  }

  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const handleForgotPassword = async ({ email }: IForgotPasswordValues) => {
    forgotPassword({ variables: { email } })
      .then(() => {
        showToast({
          title: 'Password Reset Email Sent',
          description: 'Check your inbox to finish changing your email!',
          type: toast.TYPE.SUCCESS,
        });
      }).catch((error) => {
        console.error('Password Reset Error', { error });
        showToast({
          title: 'Oh No! Something Went Wrong!',
          description: 'We couldn\'t find that email in our system.',
          type: toast.TYPE.ERROR,
        });
      });
  };

  const [isLogin, setIsLogin] = useState<boolean>(false)
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false)

  const forgot = () => {
    setIsForgotPassword(true);
    setIsLogin(false);
  };

  const forgotBack = () => {
    setIsForgotPassword(false);
    setIsLogin(true);
  };

  return (
    <>
      <StyledContainer>
        <StyledLeftContainer>
          {(!isLogin && !isForgotPassword) &&
            <>
              <H2 bold>{lefttitle? lefttitle : `${companyName} uses Nectarine Credit for credit applications `}</H2>
              <BodyText>
                {leftbody? <div dangerouslySetInnerHTML={{__html: leftbody}} /> : `Please have a business owner or officer complete and digitally sign the application. 
                You will need your business information, vendor and bank references handy.`}
              </BodyText>
              <Flex>
                <StyledNextButton primary onClick={startOnboarding}>Start</StyledNextButton>
                <StyledLinkButton type='button' onClick={() => setIsLogin(true)}>
                  Returning User?
                </StyledLinkButton>
              </Flex>
            </>
          }

          {(isLogin && !isForgotPassword) &&
            <Formik
              initialValues={loginInitialValues}
              validationSchema={loginValidation}
              onSubmit={handleLogin}
            >
              <ReturningLoginForm isLoading={loading} back={() => setIsLogin(false)} forgot={() => forgot()} />
            </Formik>
          }

          {(!isLogin && isForgotPassword) &&
            <Formik
              initialValues={forgotPasswordInitialValues}
              validationSchema={forgotPasswordValidation}
              onSubmit={handleForgotPassword}
            >
              <ReturningForgotPasswordForm back={() => forgotBack()} />
            </Formik>
          }
        </StyledLeftContainer>
        <StyledRightContainer>
          <StyledRightMessageWrapper>
            <H3 bold>{righttitle? righttitle : 'Submit Your Credit Application'}</H3>
            <Flex direction='column' gap='0.5rem'>
              <Flex gap='0.5rem' align='center'><StyledClockIcon /><H3 bold>QUICK<H4> — Takes only 5 minutes</H4></H3></Flex>
              <Flex gap='0.5rem' align='center'><StyledClipboardIcon /><H3 bold>EFFICIENT<H4> — Streamlined</H4></H3></Flex>
              <Flex gap='0.5rem' align='center'><StyledShieldIcon /><H3 bold>SECURE<H4> — Process data safely</H4></H3></Flex>
            </Flex>
          </StyledRightMessageWrapper>
        </StyledRightContainer>
      </StyledContainer>
    </>
  )};