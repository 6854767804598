/* eslint-disable react/no-array-index-key */
import React from 'react';

import { INavOptions } from '@/types/navOptions';

import { NavigationGroup } from './NavigationGroup';

interface IProps {
  options: INavOptions,
  toggleMenu?: () => void,
}

export const NavigationContent = ({ options, toggleMenu }: IProps) => (
  <>
    {options.map((groupOptions, index) => (
      <NavigationGroup key={index} options={groupOptions} index={index} toggleMenu={toggleMenu} />
    ))}
  </>
);
