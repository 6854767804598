import React from 'react';
import { useParams } from 'react-router';
import { useLocalStorage } from '@rehooks/local-storage';
import { validate as uuidValidate } from 'uuid';

import { useQueryParams } from '@/utils/history';

import { useAuth0 } from '@/providers/Auth0/context';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { LogoutCreditProviderModal } from '@/modals/LogoutCreditProviderModal';
import { ApplicationContinueModal } from '@/modals/ApplicationContinueModal';

import { ONBOARDING_PATH } from '@/constants';

import { CreditApplicantOnboardContainer } from './container';
import { useCompanyPersonnelData } from './useCompanyPersonnelData';
import { StepHeader } from '../StepHeader';
import { useReferral } from './useReferral';
import { STEPS } from './constants';
import { StepCounter } from '@/components/Onboard/StepCounter';
import { StyledStepCounterContainer } from './styled';

interface IParams {
  companyId: string,
}

export const CreditApplicantOnboard = () => {
  const query = useQueryParams();
  const { loading: authLoading } = useAuth0();

  // customer id
  const [customerIdFromStorage] = useLocalStorage('customer-id');
  const customerIdParam = query.get('cid') ?? customerIdFromStorage;

  // company id
  const { companyId: companyIdParam } = useParams<IParams>();
  const sanitizedCompanyId = uuidValidate(companyIdParam) ? companyIdParam : null;

  // Before referral data request we need to know if the user is company personnel and suggest him to logout as CP.
  const {
    isCompanyPersonnelData,
    isCompanyPersonnelError,
    isCompanyPersonnel,
    isLogoutModalActive,
    cleanLocalStorage,
  } = useCompanyPersonnelData(sanitizedCompanyId, authLoading);

  // Get Referral Data
  const referralCode = query.get('ref');

  const {
    refData,
    customerId,
    loginRequired,
  } = useReferral(
    referralCode,
    sanitizedCompanyId,
    customerIdParam,
    isCompanyPersonnelData,
    isCompanyPersonnelError,
    isCompanyPersonnel,
    authLoading,
  );

  const basePath = companyIdParam ? `${ONBOARDING_PATH.APPLICANT}/${companyIdParam}` : ONBOARDING_PATH.APPLICANT;

  if (isLogoutModalActive) {
    return (
      <>
        <StepHeader 
          steps={(
            <StyledStepCounterContainer>
              <StepCounter steps={Object.values(STEPS)} activeStep={0} />
            </StyledStepCounterContainer>
          )}
        />
        <LogoutCreditProviderModal isOpen onClick={cleanLocalStorage} />
      </>
    )
  }

  if (!refData || authLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <CreditApplicantOnboardContainer referralCode={referralCode} refData={refData} basePath={basePath} customerId={customerId} />
      <ApplicationContinueModal isOpen={loginRequired} companyId={sanitizedCompanyId ?? ''} customerId={customerId} />
    </>
  )
};
