import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { GET_OWN_CUSTOM_FIELDS } from '@/graphql/queries/getOwnCustomFields';

import { getOr } from 'lodash/fp';

import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';

import Dynamics365 from '@/assets/reviewIntegration/dynamics-365.png';
import Epicor from '@/assets/reviewIntegration/epicor.png';
import ERPNext from '@/assets/reviewIntegration/erpnext.png';
import NetSuite from '@/assets/reviewIntegration/netSuite.png';
import Odoo from '@/assets/reviewIntegration/odoo.png';
import Quickbooks from '@/assets/reviewIntegration/quickbooks.png';
import Salesforce from '@/assets/reviewIntegration/salesforce.png';
import SAP from '@/assets/reviewIntegration/SAP.png';
import SAPHana from '@/assets/reviewIntegration/sap-s4hana.png';
import Syspro from '@/assets/reviewIntegration/syspro.png';
import TaiSoftware from '@/assets/reviewIntegration/TaiSoftware.png';

import { StyledContainer, ImageLabel, HiddenRadio, RadioImage } from './styled';

const options = [
  { name: 'Dynamics365', src: Dynamics365 },
  { name: 'Epicor', src: Epicor },
  { name: 'ERPNext', src: ERPNext },
  { name: 'NetSuite', src: NetSuite },
  { name: 'Odoo', src: Odoo },
  { name: 'Quickbooks', src: Quickbooks },
  { name: 'Salesforce', src: Salesforce },
  { name: 'SAP', src: SAP },
  { name: 'SAPHana', src: SAPHana },
  { name: 'Syspro', src: Syspro },
  { name: 'TaiSoftware', src: TaiSoftware },
];

export const ReviewIntegration = () => {
  const [selected, setSelected] = useState<string>('');

  const handleChange = (value: string) => {
    setSelected(value);
  };

  return (
    <StyledContainer>
      {options.map((option) => (
        <ImageLabel key={option.name} selected={selected === option.name}>
          <HiddenRadio
            name="erp-selection"
            value={option.name}
            checked={selected === option.name}
            onChange={() => handleChange(option.name)}
          />
          <RadioImage src={option.src} alt={option.name} onClick={() => handleChange(option.name)} />
        </ImageLabel>
      ))}
    </StyledContainer>
  );
};
