import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';

import { useMutation } from '@apollo/react-hooks';
import { ONBOARD_CREDIT_SEEKER_INPUT } from '@/graphql/mutations/onboardCreditSeeker';
import { ONBOARD_UPDATE_INPUT } from '@/graphql/mutations/onboardUpdate';

import { Formik } from 'formik';

import { getOr, noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ISettings } from '@/types/settings';
import { ICustomField } from '@/types/customField';
import { IRefDataCurrentCompany } from '@/types/referralData';
import { ICompanyUser } from '@/types/companyUser';

import { VisitorContext } from '@/providers/VisitorContext';
import { useAuth0 } from '@/providers/Auth0/context';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { OnboardValuesContext } from '../../context';
import { companyContactsStepValidation } from './validation';
import { CompanyContactsStepForm } from './form';
import { IOnboardValuesAction } from '../../types';
import { UPDATE_COMPANY_CONTACTS } from '../../reducer';
import { useAdditionalPersonalInformationFields } from './useAdditionalPersonalInformationFields';
import { ICompanyContactsValues } from './types';
import { submitStep } from './functions';

interface IProps {
  dispatch: Dispatch<IOnboardValuesAction>,
  companySettings: ISettings,
  customContactFields: ICustomField[],
  currentCompany: IRefDataCurrentCompany | null,
  currentUser: ICompanyUser,
  providerCompanyId: string,
  referralCode: string | null,
}

export const CompanyContactsStep = ({
  dispatch,
  companySettings,
  customContactFields,
  currentCompany,
  currentUser,
  providerCompanyId,
  referralCode
}: IProps) => {
  const visitorData: ExtendedGetResult | null = useContext(VisitorContext);

  const { signUpOrLoginCreditApplicant } = useAuth0();

  const history = useHistory();
  const { pathname, search } = useLocation();
  const onboardValues = useContext(OnboardValuesContext);
  const firstUpdate = useRef(true);

  const basicInfo = getOr(null, 'basicInfo', onboardValues);
  const chosenCompanyType = getOr(null, 'companyProfile.type', onboardValues);

  const additionalPersonalInformationFields = useAdditionalPersonalInformationFields({
    companySettings,
    chosenCompanyType,
  });

  const [onboardCreditApplicantMutation] = useMutation(ONBOARD_CREDIT_SEEKER_INPUT);
  const [updateOnboardMutation] = useMutation(ONBOARD_UPDATE_INPUT);

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep({
      onboardValues,
      history,
      pathname,
      search,
      currentUser,
      currentCompany,
      visitorData,
      referralCode,
      providerCompanyId,
      signUpOrLoginCreditApplicant,
      onboardCreditApplicantMutation,
      updateOnboardMutation,
    });
  }, [onboardValues])

  const dispatchValues = (values: ICompanyContactsValues) => {
    dispatch({ type: UPDATE_COMPANY_CONTACTS, payload: values });
  }

  if (!onboardValues || !companySettings || !basicInfo || !additionalPersonalInformationFields) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={onboardValues.companyContacts}
      validationSchema={companyContactsStepValidation(companySettings, additionalPersonalInformationFields, customContactFields)}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <CompanyContactsStepForm
        basicInfo={basicInfo}
        companySettings={companySettings}
        additionalPersonalInformationFields={additionalPersonalInformationFields}
        customContactFields={customContactFields}
      />
    </Formik>
  )
};
