import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { PROVIDER_UPDATE_APPLICATION } from '@/graphql/mutations/providerUpdateApplication';

import { Formik } from 'formik';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { includes } from 'lodash';
import { get } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { IApplication } from '@/types/application';
import { ICustomer } from '@/types/customer';

import { CREDIT_APPLICANT_STATUSES } from '@/constants';

import { BodyText } from '@/components/designSystem/Typography';

import { SideTitle } from '../SideTitle';
import { actionsFormValidation } from './validation';
import { ActionsForm } from './ActionsForm';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '@/components/designSystem/buttons';
import { StyledSideButton, StyledApprovedContainer, StyledSlideActionContainer, StyledFormButtonContainer } from '../styled';
import { getActionsInitialValues } from './initialValues';

interface IFields {
  creditLimit: string,
  creditTerms: string,
  status: string,
  seekerDenialAlertEmail: boolean,
  seekerApprovalAlertEmail: boolean,
  providerComment: string,
}

interface IProps {
  customerData: ICustomer,
  application: IApplication,
  seekerDenialAlert: boolean,
  seekerApprovalAlert: boolean,
  creditTermsOptions: string[],
  refetchCustomerData: () => void,
}

export const UpdateCredit = ({
  customerData,
  application,
  creditTermsOptions,
  seekerDenialAlert,
  seekerApprovalAlert,
  refetchCustomerData,
}: IProps) => {
  const [updateApplication, { loading }] = useMutation(PROVIDER_UPDATE_APPLICATION);

  const applicationId = get('id', application);
  const applicationStatus = get('status', application);
  const [selectedStatus, setSelectedStatus] = useState(applicationStatus);
  const requireLimits = selectedStatus === CREDIT_APPLICANT_STATUSES.APPROVED;

  const initialValues = getActionsInitialValues(application, seekerDenialAlert, seekerApprovalAlert);
  const validation = actionsFormValidation(requireLimits);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isCreditOptionsUpdate, setCreditOptionsUpdate] = useState(false);

  const onSubmit = async ({
    creditLimit, creditTerms, status, providerComment, seekerDenialAlertEmail, seekerApprovalAlertEmail
  }: IFields) => {
    const isDenied = status === CREDIT_APPLICANT_STATUSES.DENIED;
    const variables = {
      applicationId,
      applicationAttrs: {
        creditLimit: isDenied ? null : parseInt(creditLimit, 10),
        creditTerms: isDenied ? null : creditTerms,
        status,
        providerComment,
        seekerDenialAlertEmail,
        seekerApprovalAlertEmail,
      },
    };

    updateApplication({ variables })
      .then(() => {
        refetchCustomerData();
      })
      .catch((error) => {
        showToast({
          title: 'Error!',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      });

    if (isCreditOptionsUpdate) {
      setCreditOptionsUpdate(false);
    }
  };

  const submitOrConfirm = (values: any) => {
    const toConfirm = values.providerComment
      && ((includes([CREDIT_APPLICANT_STATUSES.APPROVED], values.status) && values.seekerApprovalAlertEmail)
        || (includes([CREDIT_APPLICANT_STATUSES.DENIED], values.status) && values.seekerDenialAlertEmail));
    if (toConfirm) {
      setConfirmationModalOpen(true);
    } else {
      onSubmit(values);
    }
  };

  const onCancel = () => {
    if (isCreditOptionsUpdate) {
      setCreditOptionsUpdate(false);
    }
  }

  return (
    <StyledSlideActionContainer>
      <SideTitle title='Update Credit' />
      {(applicationStatus !== CREDIT_APPLICANT_STATUSES.APPROVED || isCreditOptionsUpdate) ?
        <Formik
          onSubmit={(values) => handleSubmitWrapper(values, submitOrConfirm)}
          initialValues={initialValues}
          validationSchema={validation}
          validateOnChange
          enableReinitialize
        >
          {({ values }) => (
            <>
              <ActionsForm
                customerData={customerData}
                loading={loading}
                creditTermsOptions={creditTermsOptions}
                requireLimits={requireLimits}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                creditUpdated={isCreditOptionsUpdate}
                cancel={onCancel}
              />
              <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                setIsOpen={setConfirmationModalOpen}
                currentStatus={applicationStatus}
                selectedStatus={selectedStatus}
                onConfirm={() => {
                  onSubmit(values);
                  setConfirmationModalOpen(false);
                }}
              />
            </>
          )}
        </Formik>
        :
        <StyledApprovedContainer>
          <BodyText bold>Status:</BodyText> <BodyText style={{ textTransform: 'capitalize' }}>{applicationStatus}</BodyText>
          <BodyText bold>Credit Terms:</BodyText> <BodyText>{application.creditTerms}</BodyText>
          <BodyText bold>Credit Limit:</BodyText> <BodyText>${application.creditLimit}</BodyText>
          { application.requestedCreditLimit &&
            <>
              <BodyText bold>Requested Credit Limit:</BodyText> <BodyText>${application.requestedCreditLimit}</BodyText>
            </>
          }
          <BodyText bold>Date approved:</BodyText> <BodyText>{application.dateApproved}</BodyText>
          { application.providerComment &&
            <>
              <BodyText bold>Comment:</BodyText> <BodyText>{application.providerComment}</BodyText>
            </>
          }
          <StyledFormButtonContainer>
            <Button onClick={() => setCreditOptionsUpdate(true)} primary wide>Update Credit</Button>
          </StyledFormButtonContainer>
        </StyledApprovedContainer>
      }
    </StyledSlideActionContainer>
  )};
