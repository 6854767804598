import { useState, FormEvent } from 'react';

import { getOr } from 'lodash/fp';

import { NO_WEBSITE_VALUE } from '@/constants';

import { ICompanyProfileValues } from './types';

interface IArguments {
  values: ICompanyProfileValues,
  setFieldValue: (field: string, value: string) => void,
}

export const useWebsite = ({ values, setFieldValue }: IArguments) => {
  const websiteValue = getOr('', 'website', values);

  const [tempWebsite, setTempWebsite] = useState(values.website);

  const handleWebsiteChange = (e: FormEvent<HTMLInputElement>) => {
    setTempWebsite(e.currentTarget.value.toLowerCase())
    setFieldValue('website', e.currentTarget.value.toLowerCase());
  }

  const [noWebsite, setNoWebsite] = useState(websiteValue === NO_WEBSITE_VALUE);

  const handleNoWebsiteClick = () => {
    setFieldValue('website', noWebsite ? tempWebsite : 'n/a')
    setNoWebsite((prev) => !prev)
  }

  return {
    noWebsite,
    handleNoWebsiteClick,
    handleWebsiteChange
  }
};
