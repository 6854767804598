import gql from 'graphql-tag';

export const GENERATE_EXPERIAN_REPORT = gql`
  mutation GenerateExperianReport($customerId: ID!, $bin: String!, $reportType: String!, $reportDescription: String!) {
    generateExperianReport(customerId: $customerId, bin: $bin, reportType: $reportType, reportDescription: $reportDescription) {
      id
    }
  }
`;

export const IS_EXPERIAN_CREDENTIALS_STILL_VALID = gql`
  mutation IsExperianCredentialsStillValid {
    isExperianCredentialsStillValid
  }
`;
