import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { IStakeholder } from '@/types/stakeholder';
import { ICustomField } from '@/types/customField';

const getValidationShape = (
  stakeholders: IStakeholder[],
  providerCustomContactFields: ICustomField[],
) => stakeholders.reduce((acc, stakeholder, index) => {
  const { isOwner, isPrimaryContact, customFieldId } = stakeholder;
  const isOwnerOrPrimary = isOwner ?? isPrimaryContact;
  const customFieldContactInformation = customFieldId
    ? providerCustomContactFields.find((customField) => customField.id === customFieldId)?.contact
    : null;

  const ownerInfoValidation = {
    [`stakeholder${index}-ssnSinNumber`]: Yup.string().matches(/^\d{3}-?(?:\d{2}-?\d{4}|\d{3}-?\d{3})$/, DEFAULT_ERROR_MESSAGES.SSN).nullable(),
    [`stakeholder${index}-driverLicenseNo`]: Yup.string().nullable(),
    [`stakeholder${index}-driverLicenseState`]: Yup.string().nullable(),
    [`stakeholder${index}-dob`]: Yup.string().nullable(),
    [`stakeholder${index}-addressLine1`]: Yup.string().nullable(),
    [`stakeholder${index}-addressLine2`]: Yup.string().nullable(),
    [`stakeholder${index}-state`]: Yup.string().nullable(),
    [`stakeholder${index}-postalCode`]: Yup.string().nullable(),
    [`stakeholder${index}-city`]: Yup.string().nullable(),
    [`stakeholder${index}-country`]: Yup.string().nullable(),
  }

  return {
    ...acc,
    [`stakeholder${index}-position`]: isOwnerOrPrimary ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.string(),
    [`stakeholder${index}-name`]: (isOwnerOrPrimary || customFieldContactInformation?.nameRequired)
      ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED)
      : Yup.string(),
    [`stakeholder${index}-phoneNumber`]: (isOwnerOrPrimary || customFieldContactInformation?.phoneRequired)
      ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE)
      : Yup.string()
        .test('is-valid-phone', DEFAULT_ERROR_MESSAGES.PHONE, (value) => {
          return !value || Yup.string().phone('US', false).isValidSync(value);
        }),
    [`stakeholder${index}-extension`]: Yup.string().nullable(),
    ...(isOwner && ownerInfoValidation)
  }}, {})

export const contactsValidation = (
  stakeholders: IStakeholder[],
  providerCustomContactFields: ICustomField[],
) => Yup.object().shape(getValidationShape(stakeholders, providerCustomContactFields));