import React from 'react';
import styled from 'styled-components';

import { BodyText } from '@/components/designSystem/Typography';

import { GreenCheckIcon } from '@/components/icons';

const CheckItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

const StyledGreenCheck = styled(GreenCheckIcon)`
  margin-right: 1rem;
`;

interface IProps {
  children: React.ReactNode,
}

export const CheckItem = ({ children }: IProps) => (
  <CheckItemContainer>
    <StyledGreenCheck />
    <BodyText>{children}</BodyText>
  </CheckItemContainer>
);
