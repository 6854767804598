import { concat, getOr, orderBy } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IPendingVendorInvite } from '@/types/pendingVendorInvite';

const convertReferralToVendor = ({
  id,
  toCompanyName,
  toName,
  toEmail,
  toPosition,
  toPhoneNumber,
  sent,
  bounced,
  showNonBusinessEmailDomainWarning,
  updatedAt,
  optOut,
  optOutAt,
}: IPendingVendorInvite) => ({
  isPending: true,
  sourceCompany: {
    name: toCompanyName,
    sent,
    bounced: bounced,
    inviteId: parseInt(id, 10),
    stakeholders: [{
      name: toName,
      email: toEmail,
      phoneNumber: toPhoneNumber,
      position: toPosition,
      showNonBusinessEmailDomainWarning: showNonBusinessEmailDomainWarning,
    }],
    updatedAt,
    optOut,
    optOutAt,
  },
});

export const getAllReferences = (customer: ICustomer) => {
  const receivedReferences = getOr([], 'seekerCompany.receivedReferences', customer);
  const sortedReceivedReferences = orderBy('sourceUser.company', 'asc', receivedReferences);
  const pendingVendorInvites = getOr([], 'seekerCompany.pendingVendorInvites', customer)
  const pendingReferences = pendingVendorInvites.map(convertReferralToVendor);
  const sortedPendingReferences = orderBy('name', 'asc', pendingReferences);

  return concat(sortedReceivedReferences)(sortedPendingReferences);
};
