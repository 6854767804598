import React from 'react';

import { useFormikContext } from 'formik';

import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

import { GoogleAddressField } from '@/components/GoogleAddressField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { SORTED_COUNTRIES } from '@/constants';
import { IAddressComponent, IGooglePlace } from '@/types/googlePlace';

export const AddressFields = () => {
  const { setFieldValue } = useFormikContext();

  const mapGooglePlaceToValues = (addressComponents: IAddressComponent[]) => ({
    state: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_1'))?.long_name ||
          addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_2'))?.long_name || '',
    city: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('locality'))?.long_name ||
          addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_town'))?.long_name || '',
    postalCode: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_code'))?.long_name || '',
    country: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('country'))?.short_name || null,
    streetNumber: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('street_number'))?.long_name || '',
    street: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('route'))?.long_name || '',
  });

  const setFieldsFromGooglePlace = (place: IGooglePlace) => {
    if (place.address_components) {
      const addressValues = mapGooglePlaceToValues(place.address_components);
      setFieldValue('company.address.postal_code', addressValues.postalCode);
      setFieldValue(
        'company.address.address_line_1',
        `${addressValues.streetNumber}${addressValues.streetNumber && ' '}${addressValues.street}`
      );
      setFieldValue('company.address.city', addressValues.city)
      setFieldValue('company.address.state', addressValues.state);
      setFieldValue('company.address.country', addressValues.country);
    }
  }

  return (
    <>
      <GoogleAddressField
        required
        type='text'
        name='company.address.address_line_1'
        label='Company Address'
        placeholder='123 XYZ st. '
        setFieldsFromGooglePlace={setFieldsFromGooglePlace}
      />
      <TextField type='text' name='company.address.address_line_2' label='Address Line 2' placeholder='E.g. Apt 1' />
      <Row>
        <TextField required type='text' name='company.address.city' label='City' placeholder='New York' />
        <TextField required type='text' name='company.address.state' label='State / Province' placeholder='New York' />
      </Row>
      <Row>
        <TextField required type='text' name='company.address.postal_code' label='Zip / Postal Code' placeholder='90283' />
        <SelectField required name='company.address.country' label='Country' options={SORTED_COUNTRIES} />
      </Row>
    </>
  )}
