import React, { useContext } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';

import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';
import { GET_CONTENT } from '@/graphql/queries/getContent';
import { Formik } from 'formik';
import Yup from '@/utils/yup';

import { get } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { IUserCompanyRole } from '@/types/companyUser';

import { CurrentUserContext } from '@/providers/CurrentUser';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BorderedContainer } from '@/containers/BorderedContainer';

import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { AutoSave } from '@/components/designSystem/Form/AutoSave';
import { H3, H4, BodyText } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';

import { OrientationSettingsForm } from './OrientationSettingsForm';
import { TagSettingsForm } from './TagSettingsForm';
import { VendorReferenceCustomFields } from './VendorReferenceCustomFields';
import { StyledContainer, StyledForm } from './styled';

import { ReactComponent as Link } from '@/assets/link.svg';

const settingsValidation = Yup.object().shape({
  autoBavDefault: Yup.boolean(),
  autoSendVrInvite: Yup.boolean(),
  seekerApprovalAlert: Yup.boolean(),
  seekerDenialAlert: Yup.boolean(),
  enableVrCreditSafeIncentive: Yup.boolean(),
});

interface IValues {
  autoBavDefault: boolean,
  autoSendVrInvite: boolean,
  requestBankStatements: boolean,
  seekerApprovalAlert: boolean,
  seekerDenialAlert: boolean,
  enableVrCreditSafeIncentive: boolean,
  requireBankInfo: boolean
}

export const CreditApplicationSettings = () => {
  const [companyId] = useLocalStorage('company-id');

  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY);

  const { data: leftdata, refetch: leftDataRefetch, loading: leftLoading } = useQuery(GET_CONTENT, { variables: { name: 'OrientationLeft' } });
  const { data: rightdata, refetch: rightDataRefetch, loading: rightLoading } = useQuery(GET_CONTENT, { variables: { name: 'OrientationRight' } });

  const lefttitle = get('getContent.title', leftdata)
  const leftbody = get('getContent.body', leftdata)
  const righttitle = get('getContent.title', rightdata)

  const currentUserData = useContext(CurrentUserContext);
  const currentCompanyData = useContext(CurrentCompanyContext);

  if (!currentCompanyData) {
    return <LoadingSpinner />
  }

  const currentCompanyLoading = get('loading', currentCompanyData);

  const parentCompanyId = get('parentCompany.id', parentCompany);
  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData)
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId),
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId)
  );
  const changeTemplate = get('role.permissions.changeTemplate', currentUserCompanyRole[0]) || false;

  const initialSettings = get('currentCompany.settings', currentCompanyData);
  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);
  const vrCustomFields = get('currentCompany.vrCustomFields', currentCompanyData);
  const genericSeekerOnboardUrl = get('currentCompany.genericSeekerOnboardUrl', currentCompanyData);
  const lastSaved = get('currentCompany.settings.updatedAt', currentCompanyData);

  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);

  if (currentCompanyLoading) {
    return <LoadingSpinner />;
  }

  const autoSaveSettings = (settings: IValues, { setSubmitting }: { setSubmitting: (arg: boolean) => void }) => {
    const variables = {
      settings: {
        auto_bav_default: settings.autoBavDefault,
        auto_send_vr_invite: settings.autoSendVrInvite,
        requestBankStatements: settings.requestBankStatements,
        seekerApprovalAlert: settings.seekerApprovalAlert,
        seekerDenialAlert: settings.seekerDenialAlert,
        enableVrCreditSafeIncentive: settings.enableVrCreditSafeIncentive,
        requireBankInfo: settings.requireBankInfo
      },
    };

    return updateCompanySettings({ variables })
      .then((response) => {
        const newUpdatedAt = get('data.upsertManualAccountNote.updatedAt', response);
        setSubmitting(false);
        showToast({
          title: 'Update Saved.',
          description: 'The setting update is saved.',
          type: toast.TYPE.SUCCESS,
        });
        if (typeof refetchCurrentCompany === 'function') {
          refetchCurrentCompany();
        }
        return newUpdatedAt;
      });
  };

  const copyOnboardingLink = () => {
    navigator.clipboard.writeText(genericSeekerOnboardUrl);
    showToast({
      title: 'Copied',
      description: 'The link to your Credit Application has been copied to your clipboard',
      type: toast.TYPE.SUCCESS,
    });
  };

  const refetchAll = async () => {
    await leftDataRefetch();
    await rightDataRefetch();
  };

  const contentLoading = leftLoading || rightLoading

  if (contentLoading) {
    return <LoadingSpinner />;
  }

  return (
    <StyledContainer>
      <BorderedContainer disableDivider>
        <Flex justify='space-between' align='center'>
          <H3 bold>Credit Application Settings</H3>
        </Flex>
        <div>
          <Button primary LeftIcon={Link} onClick={copyOnboardingLink}>Copy Credit Application Link</Button>
        </div>
      </BorderedContainer>
      <OrientationSettingsForm leftTitle={lefttitle} leftBody={leftbody} rightTitle={righttitle} refetchAll={refetchAll} />
      <Formik
        initialValues={initialSettings}
        validationSchema={settingsValidation}
        onSubmit={autoSaveSettings}
      >
        <StyledForm>
          {changeTemplate && <BorderedContainer disableDivider>
            <Flex justify='space-between' align='center'>
              <H3 bold>Applicant Settings</H3>
            </Flex>
            <Flex justify='space-between' align='center'>
              <H4 bold>General Applicant Settings</H4>
            </Flex>
            <CheckboxField name='seekerApprovalAlert'>
              <BodyText>Automatically Send Credit Applicant Application Approval Email</BodyText>
            </CheckboxField>
            <CheckboxField name='seekerDenialAlert'>
              <BodyText>Automatically Send Credit Applicant Application Denial Email</BodyText>
            </CheckboxField>
            <Flex justify='space-between' align='center'>
              <H4 bold>Bank Information & Verification Settings</H4>
            </Flex>
            <CheckboxField name='autoBavDefault'>
              <BodyText>Enable Automatic Bank Verification by Default</BodyText>
            </CheckboxField>
            <CheckboxField name='requestBankStatements'>
              <BodyText>Request Bank Statements</BodyText>
            </CheckboxField>
            <CheckboxField name='requireBankInfo'>
              <BodyText>Require Bank Information</BodyText>
            </CheckboxField>
          </BorderedContainer>}
          {changeTemplate && <BorderedContainer disableDivider>
            <Flex justify='space-between' align='center'>
              <H3 bold>Vendor Reference Settings</H3>
            </Flex>
            <Flex justify='space-between' align='center'>
              <H4 bold>General Vendor Settings</H4>
            </Flex>
            <CheckboxField name='autoSendVrInvite'>
              <BodyText>Automatically Send Vendor Reference Forms</BodyText>
            </CheckboxField>
            <CheckboxField name='enableVrCreditSafeIncentive'>
              <BodyText>Enable Creditsafe Incentive for Vendor Reference</BodyText>
            </CheckboxField>
            <Flex justify='space-between' align='center'>
              <H4 bold>Vendor Reference Custom Fields</H4>
            </Flex>
            <VendorReferenceCustomFields customFields={vrCustomFields} refetch={refetchCurrentCompany} />
          </BorderedContainer>}
          <AutoSave lastSaved={lastSaved} debounceMs={100} />
        </StyledForm>
      </Formik>
      {/* <TagSettingsForm /> */}
    </StyledContainer>
  );
};
