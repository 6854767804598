import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { useMutation } from '@apollo/react-hooks';
import { CREATE_SIGNED_UPLOAD_URL } from '@/graphql/mutations/createSignedUploadUrl';
import { CREATE_APPLICATION_DOCUMENT } from '@/graphql/mutations/createApplicationDocument';

import { get, flow, noop, unionBy, pick } from 'lodash/fp';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { Flex, FlexColumnItemsStretch } from '@/components/designSystem/Layout';

import { ReactComponent as Page } from '@/assets/page.svg';
import { handleUploadProgress } from '@/utils/handleUploadProgress';

const UploadButton = styled(Button)`
  padding: .75rem .5rem;  
`;

const StyledPage = styled(Page)`
  color: ${(props) => props.theme.color.greyDark};
`;

const FileToUploadContainer = styled(FlexColumnItemsStretch)`
  width: 100%;
`;

const FileContainer = styled(BodyText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  text-decoration: none;
  padding: 1rem;
  background: ${(props) => props.theme.color.greyLight};
  border-radius: 6px;
  border: ${(props) => props.theme.color.border.light};

  & > :first-child {
    margin-right: 1rem;
  }
`;

const Filename = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.text.primary};
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1rem;
`;

export interface IFile {
  fileId?: string,
  name: string,
  path?: string,
  // signedUrl set as optional, because onDrop method has an error: "Property 'signedUrl' is missing in type 'File' but required in type 'IFile'"
  signedUrl?: string,
  lastModified?: number,
  lastModifiedDate?: Date,
  size?: number,
  type: string,
  webkitRelativePath?: string,
}

interface IFileToUploadProps {
  file: IFile,
  onUpload: (file: IFile, signedUrl: string, id: string) => void,
  onUploadFail: (file: IFile) => void,
  applicationId?: string,
  refetch: () => void,
}

const FileToUpload = ({ file, onUpload, onUploadFail, applicationId, refetch }: IFileToUploadProps) => {
  const [progress, setProgress] = useState(0);

  const [getSignedUrlMutation] = useMutation(CREATE_SIGNED_UPLOAD_URL);
  const [fileUploadCompleteMutation] = useMutation(CREATE_APPLICATION_DOCUMENT);

  useEffect(() => {
    const startFileUpload = async () => {
      const signedUrlResponse = await getSignedUrlMutation({ variables: { filename: file.name } });
      const signedUrlData = get('data.createSignedUploadUrl', signedUrlResponse);
      const awsUrl = get('uploadUrl', signedUrlData);
      const headers = { 'Content-Type': file.type };

      const onUploadProgress = (progressEvent: ProgressEvent) => handleUploadProgress(progressEvent, setProgress);

      const uploadResponse = await axios.put(awsUrl, file, { headers, onUploadProgress });

      if (uploadResponse.status !== 200 || !uploadResponse.config.data.size) {
        showToast({
          title: 'Error',
          description: 'Failed to upload the file',
          type: toast.TYPE.ERROR,
        });
        return onUploadFail(file);
      }

      const storedFileAttrs = pick(['id', 'originalFilename', 'path'], signedUrlData);
      const fileCompleteResponse = await fileUploadCompleteMutation({ variables: { applicationId, storedFileAttrs } });
      const signedUrl = get('data.createInternalDocument.signedUrl', fileCompleteResponse);
      const id = get('data.createInternalDocument.id', fileCompleteResponse);
      onUpload(file, signedUrl, id);
      refetch();
    };
    if (!file.signedUrl) {
      startFileUpload();
    }
  }, [file]);

  return (
    <FileToUploadContainer>
      <FileContainer>
        {file.signedUrl ? <StyledPage /> : <BodyText bold color='tertiary'>{progress}%</BodyText>}
        <Filename href={file.signedUrl} target='_blank'>{file.name}</Filename>
      </FileContainer>
    </FileToUploadContainer>
  );
};

interface IInternalDocumentUpload {
  disabled?: boolean,
  allFiles: IFile[],
  prompt?: string,
  setAllFiles?: (files: IFile[]) => void,
  onlyOne?: boolean,
  refetch: () => void,
  applicationId?: string
}

export const InternalDocumentUpload = (props: IInternalDocumentUpload) => {
  const {
    allFiles, prompt = 'Upload Document', setAllFiles = noop, onlyOne = false, refetch, applicationId
  } = props;

  const buttonText = `${prompt}${onlyOne ? '' : '(s)'}`;

  const onDrop = flow(
    unionBy('name', allFiles),
    setAllFiles,
  );

  const onUploadFail = useCallback((currentFile: IFile) => {
    setAllFiles(allFiles.filter((f: IFile) => f.name !== currentFile.name));
  }, []);

  const onUpload = (currentFile: IFile, signedUrl: string, id: string) => {
    // eslint-disable-next-line no-param-reassign
    currentFile.signedUrl = signedUrl;

    // eslint-disable-next-line no-param-reassign
    currentFile.fileId = id;

    const newFiles = onlyOne
      ? [currentFile]
      : unionBy('name', [currentFile], allFiles);

    setAllFiles(newFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });


  return (
    <Flex direction='column' align='flex-end' {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <UploadButton
        primary
        onClick={open}
      >
        {buttonText}
      </UploadButton>
      {
        (allFiles || []).map((file) => <FileToUpload key={file.fileId} file={file} onUpload={onUpload} onUploadFail={onUploadFail} applicationId={applicationId} refetch={refetch}/>)
      }
    </Flex>
  );
};
