import styled from 'styled-components';

import { ReactComponent as Logo } from '@/assets/logo_full.svg';
import device from '@/styles/device';

export const PageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(0, 1fr);
  grid-template-areas: "main marketing";
  height: fit-content;
  min-height: 100vh;
  background-color: ${(props) => props.theme.color.site.background};

  @media ${device.mobileXL} {
    grid-template-columns: 1fr;
    grid-template-areas: "main";
  };
`;

export const ContentContainer = styled.div`
  grid-area: main;
  padding: 3rem 2rem 1rem 2rem;
  background-color: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const MarketingSectionContainer = styled.div`
  grid-area: marketing;
  height: 100%;
  background-color: ${(props) => props.theme.color.brand};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledLogo = styled(Logo)`
  align-self: flex-start;
  width: 150px;
  height: auto;
  padding-bottom: 20vh;
`;

export const InnerContentContainer = styled.div`
  max-width: 750px;
  width: 100%;
`;
