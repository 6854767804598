import React, { useState, Ref } from 'react';
import styled from 'styled-components';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Formik, FormikValues } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { SectionContainer } from '../../SectionContainer';
import { FlexColumn, FlexRow } from '@/components/designSystem/Layout';
import { IDocument, DocumentRow } from '@/components/Document';
import { InternalDocumentUpload } from './InternalDocumentUpload';
import { Field } from '@/components/designSystem/Form/Field';
import { IUploadedFile } from '@/types/file';
import { DELETE_APPLICATION_DOCUMENT } from '@/graphql/mutations/deleteApplicationDocument';

export interface ILabelValue {
  label: string,
  value: string,
}

interface IField {
  value?: string,
}

export interface IFieldProps {
  label?: string,
  name?: string,
  required?: boolean,
  description?: string,
  className?: string,
  ErrorAction?: any,
  type?: string,
  placeholder?: string,
  disabled?: boolean,
  component?: React.ReactNode,
  onlyOne?: boolean,
  options?: ILabelValue[],
  onChange?: any,
  defaultValue?: ILabelValue,
  creatable?: boolean,
  prompt?: string,
  field?: IField,
  autoFocus?: boolean,
  applicationId?: string,
  refetch?: () => void,
  onKeyDown?: (e: KeyboardEvent) => void,
  onPaste?: (e: KeyboardEvent) => void | boolean,
  maxDate?: Date,
  innerRef?: Ref<HTMLInputElement | null>,
  onFocus?: () => void,
  onBlur?: () => void,
}

export interface IFile {
  fileId?: string,
  name: string,
  path?: string,
  // signedUrl set as optional, because onDrop method has an error: "Property 'signedUrl' is missing in type 'File' but required in type 'IFile'"
  signedUrl?: string,
  lastModified?: number,
  lastModifiedDate?: Date,
  size?: number,
  type: string,
  webkitRelativePath?: string,
}

interface IInternalDocumentsProps {
  documents: IDocument[],
  applicationId: string,
  refetch: () => void
}

const StyledFlexRow = styled(FlexRow)`
  margin-top: 1rem;
`;

const StyledFlexColumn = styled(FlexColumn)`
  gap: 1rem;
`;

export const InternalDocuments = ({documents, applicationId, refetch}: IInternalDocumentsProps) => {
  const [deleteApplicationDocumentMutation] = useMutation(DELETE_APPLICATION_DOCUMENT);
  const InternalDocumentUploadField = (props: IFieldProps) => (
    <Field {...props}>
      {({ field }: FormikValues) => {
        const [visibleFiles, setVisibleFiles] = useState(field.value);
        const setFileFieldState = (files: IFile[]) => {
          setVisibleFiles(files);
        };
        return (
          <InternalDocumentUpload {...props} allFiles={visibleFiles} setAllFiles={setFileFieldState} refetch={refetch} />);
      }}
    </Field>
  );

  const deleteApplicationDocument = (applicationId: string, file: IUploadedFile) => {
    deleteApplicationDocumentMutation({ variables: { applicationId, fileId: file.id } })
      .then(() => {
        showToast({
          title: 'Credit Report Deleted',
          description: 'Your credit report has been successfully deleted',
          type: toast.TYPE.SUCCESS,
        });
        refetch();
      });
  };

  return (
    <SectionContainer title='Internal Document'>
      <StyledFlexColumn>
        {documents.map((document: IDocument) => (
          <DocumentRow
            key={document.file.id}
            document={document}
            refetch={refetch}
            onDelete={(file) => deleteApplicationDocument(applicationId, file)}
          />
        ))}
      </StyledFlexColumn>
      <StyledFlexRow>
        <Formik initialValues={{}} onSubmit={() => undefined}>
          <InternalDocumentUploadField name='applicationDocument' applicationId={applicationId} onlyOne />
        </Formik>
      </StyledFlexRow>
    </SectionContainer>
  )
}
