import { get, getOr } from 'lodash/fp';
import { mapCustomFieldsToFormInitialValues } from '@/utils/customFields';

import { IReferralData } from '@/types/referralData';
import { ICustomField } from '@/types/customField';

import { CUSTOM_FIELD_TYPES } from '@/constants';

export const otherInfoInitialValues = (refData: IReferralData) => {

  const providerCompany = getOr(null, 'getCompanyById', refData);
  const customFieldAnswers = getOr([], 'getCustomerAsSeeker.customFieldAnswers', refData);

  const customFields = getOr([], 'customFields', providerCompany);
  const customFieldsForOtherInfoStep: ICustomField[] = customFields.filter(
    customField => (customField.type !== CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION) && (customField.type !== CUSTOM_FIELD_TYPES.CONTACT));

  return {
    creditLimit: '',
    creditTerms: '',
    einFederalTaxNumber: '',
    customFields: mapCustomFieldsToFormInitialValues(customFieldsForOtherInfoStep, customFieldAnswers),
  }
}
