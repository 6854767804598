import styled from 'styled-components';

import { BodyText, Caption } from '@/components/designSystem/Typography';
import { FlexColumnItemsCenter, FlexRowSpaceBetweenItemsCenter } from '../designSystem/Layout';

export const PDFCardContainer = styled(FlexRowSpaceBetweenItemsCenter)`
  background: ${({ theme }) => theme.color.greyLight};
  padding: 1rem;
  border-radius: 12px;
`;

export const ModalContainer = styled(FlexColumnItemsCenter)`
  padding: 2rem 4rem 2rem 2rem;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

export const NewLineCaption = styled(Caption)`
  max-width: 90%;
  white-space: pre;
`;

export const StyledModalText = styled(BodyText)`
  align-content: center;
  padding: 1.5rem;
`;

