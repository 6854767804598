import gql from 'graphql-tag';

export const INCREASE_REQUIRED_REFERENCES = gql`
  mutation IncreaseRequiredReferences {
    increaseRequiredReferences {
      id
    }
  }
`;

export const DECREASE_REQUIRED_REFERENCES = gql`
  mutation DecreaseRequiredReferences {
    decreaseRequiredReferences {
      id
    }
  }
`;

export const INCREASE_TOTAL_REFERENCES = gql`
  mutation IncreaseTotalReferences {
    increaseTotalReferences {
      id
    }
  }
`;

export const DECREASE_TOTAL_REFERENCES = gql`
  mutation DecreaseTotalReferences {
    decreaseTotalReferences {
      id
    }
  }
`;