import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { IVRCustomField } from '@/types/vrCustomField';

export const referenceStepValidation = (customFields: IVRCustomField[]) => {
  const customFieldsSchema = customFields.reduce((acc, customField) => {
    let schema = Yup.mixed();

    if (customField.type === 'number') {
      schema = customField.required
        ? Yup.number().nullable().required(DEFAULT_ERROR_MESSAGES.REQUIRED)
        : Yup.number().nullable();
    } else if (customField.type === 'text') {
      schema = customField.required
        ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED)
        : Yup.string();
    }

    return {
      ...acc,
      [customField.name]: schema,
    };
  }, {});

  return Yup.object().shape({
    customerSince: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    creditTerms: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    creditLimit: Yup.number().nullable().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    creditBalancePastDue: Yup.number().nullable().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    lastPaymentAmount: Yup.number().nullable().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    lastPaymentOn: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    creditBalanceHigh: Yup.number().nullable().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    averageDaysToPay: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    ...customFieldsSchema,
    comments: Yup.string(),
    agreeCheck: Yup.boolean().oneOf([true], 'Field must be checked').required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  })
}
