import { ISettings } from "@/types/settings";

import { NUMBER_OF_COMPANY_TYPES } from "@/constants";

interface IArgs {
    companySettings: ISettings,
    chosenCompanyType: string,
}

export const useAdditionalPersonalInformationFields = ({ companySettings, chosenCompanyType }: IArgs) => {
  const enableAddressForLength = companySettings?.enableAddressFor?.split(',').length;
  const enableSsnSinNumberForLength = companySettings?.enableSsnSinNumberFor?.split(',').length;
  const enableDriverLicenseNoForLength = companySettings?.enableDriverLicenseNoFor?.split(',').length;
  const enableDriverLicenseStateForLength = companySettings?.enableDriverLicenseStateFor?.split(',').length;
  const enableDateOfBirthForLength = companySettings?.enableDateOfBirthFor?.split(',').length;

  const ownerAddressEnabled = companySettings?.enableAddress
      && (companySettings?.enableAddressFor?.includes(chosenCompanyType)
        || enableAddressForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerAddressRequired = ownerAddressEnabled && companySettings?.requireAddress;
  const ownerSsnSinNumberEnabled = companySettings?.enableSsnSinNumber
      && (companySettings?.enableSsnSinNumberFor?.includes(chosenCompanyType)
        || enableSsnSinNumberForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerSsnSinNumberRequired = ownerSsnSinNumberEnabled && companySettings?.requireSsnSinNumber;
  const ownerDriverLicenseNoEnabled = companySettings?.enableDriverLicenseNo
      && (companySettings?.enableDriverLicenseNoFor?.includes(chosenCompanyType)
        || enableDriverLicenseNoForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerDriverLicenseNoRequired = ownerDriverLicenseNoEnabled && companySettings?.requireDriverLicenseNo;
  const ownerDriverLicenseStateEnabled = companySettings?.enableDriverLicenseState
      && (companySettings?.enableDriverLicenseStateFor?.includes(chosenCompanyType)
        || enableDriverLicenseStateForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerDriverLicenseStateRequired = ownerDriverLicenseStateEnabled && companySettings?.requireDriverLicenseState;
  const ownerDateOfBirthEnabled = companySettings?.enableDateOfBirth
      && (companySettings?.enableDateOfBirthFor?.includes(chosenCompanyType)
        || enableDateOfBirthForLength === NUMBER_OF_COMPANY_TYPES);
  const ownerDateOfBirthRequired = ownerDateOfBirthEnabled && companySettings?.requireDateOfBirth;

  return {
    ownerAddressEnabled,
    ownerAddressRequired,
    ownerSsnSinNumberEnabled,
    ownerSsnSinNumberRequired,
    ownerDriverLicenseNoEnabled,
    ownerDriverLicenseNoRequired,
    ownerDriverLicenseStateEnabled,
    ownerDriverLicenseStateRequired,
    ownerDateOfBirthEnabled,
    ownerDateOfBirthRequired,
  };
}