import React from 'react';

import { getStatusType, mapStatusLabel } from '@/utils/format';

import { SubHeading } from '@/components/designSystem/Typography';

import { Label } from './Label';

interface IProps {
  value: string,
  className?: string,
}

export const StatusLabel = ({ value, className }: IProps) => {
  const type = getStatusType(value);

  if (value) {
    return (<Label className={className} type={type}><SubHeading>{mapStatusLabel(value)}</SubHeading></Label>);
  }
  return null;
};