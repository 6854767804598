import styled from 'styled-components';

import { ReactComponent as Close } from '@/assets/x_circle.svg';

export const StyledClose = styled(Close)`
  align-self: center;
  max-width: 20px;
  color: ${({ theme }) => theme.color.greyDark};
  &:hover {
    color: ${({ theme }) => theme.color.negative};
  }
`;
