import gql from 'graphql-tag';

export const OPT_OUT_USER = gql`
  mutation OptOutUser(
    $referral_code: String!,
  ) {
    opt_out_user(
      referral_code: $referral_code
    ) {
      id
    }
  }
`;
