import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';

import { VisitorContext } from '@/providers/VisitorContext'

import { get } from 'lodash/fp';

import { CREATE_SIGNED_UPLOAD_URL } from '@/graphql/mutations/createSignedUploadUrl';
import { CREATE_COMPANY_DOCUMENT } from '@/graphql/mutations/createCompanyDocument';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BodyText } from '@/components/designSystem/Typography';

import {
  FileToUploadContainer,
  FileContainer,
  Filename,
  StyledPage,
  StyledClose,
} from './styled';
import { handleUploadProgress } from '@/utils/handleUploadProgress';
import { IFile, IStoredFile } from './FileUpload';

interface IProps {
  file: IFile,
  onUpload: (file: IFile, storedFile: IStoredFile) => void,
  onUploadFail: (file: IFile) => void,
  removeFile: (file: IFile) => void,
}

export const FileToUpload = ({ file, onUpload, onUploadFail, removeFile }: IProps) => {
  const [progress, setProgress] = useState(0);

  const visitorData = useContext(VisitorContext);

  const [getSignedUrlMutation] = useMutation(CREATE_SIGNED_UPLOAD_URL);
  const [fileUploadCompleteMutation] = useMutation(CREATE_COMPANY_DOCUMENT);

  useEffect(() => {
    const startFileUpload = async () => {
      const signedUrlResponse = await getSignedUrlMutation({ variables: { filename: file.name } });
      const signedUrlData = get('data.createSignedUploadUrl', signedUrlResponse);
      const awsUrl = get('uploadUrl', signedUrlData);
      const headers = { 'Content-Type': file.type };

      const onUploadProgress = (progressEvent: ProgressEvent) => handleUploadProgress(progressEvent, setProgress);

      const uploadResponse = await axios.put(awsUrl, file, { headers, onUploadProgress });

      if (uploadResponse.status !== 200 || !uploadResponse.config.data.size) {
        showToast({
          title: 'Error',
          description: 'Failed to upload the file',
          type: toast.TYPE.ERROR,
        });
        return onUploadFail(file);
      }

      const signedFileUploadData = {
        id: get('id', signedUrlData),
        originalFilename: get('originalFilename', signedUrlData),
        path: get('path', signedUrlData),
      };
      const fileCompleteResponse = await fileUploadCompleteMutation({ variables: { signedFileUploadData, visitorData } });

      const storedFile = get('data.createCompanyDocument.storedFile', fileCompleteResponse);
      onUpload(file, storedFile);
    };
    if (!file.signedUrl) {
      startFileUpload();
    }
  }, [file]);

  return (
    <FileToUploadContainer>
      <FileContainer>
        {file.signedUrl ? <StyledPage /> : <BodyText bold color='tertiary'>{progress}%</BodyText>}
        <Filename href={file.signedUrl} target='_blank'>{
        // file.originalFilename ?
        // file.originalFilename :
          file.name
        }</Filename>
        {file.signedUrl && (
          <StyledClose onClick={(e: MouseEvent) => {
            e.stopPropagation();
            // if (file.id) {
            removeFile(file);
            // }
          }}
          />
        )}
      </FileContainer>
    </FileToUploadContainer>
  );
};
