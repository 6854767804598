import React, { useContext } from 'react';

import { BackButton } from '@/components/designSystem/buttons/BackButton';

import { SetActiveViewContext } from '.';

export const AddCustomFieldBackButton = () => {
  const returnToFirstStep = useContext(SetActiveViewContext);

  return (
    <BackButton onClick={returnToFirstStep} />
  )
};
