import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { E164Number } from 'libphonenumber-js';

import { FieldLabel } from './FieldLabel';
import { StyledErrorText, StyledFieldContainer, StyledPhoneInput } from './styled';
import { noop } from 'lodash/fp';

interface IProps {
  name?: string,
  label: string,
  value?: any // Fix for String type issue,
  handleChange?: (value?: E164Number | undefined) => void,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  error?: string,
}

// Controlled input. Don't use inside Formik.

export const PhoneNumberInput = ({
  required,
  name = '',
  label,
  value = '',
  handleChange = noop,
  placeholder,
  disabled,
  error
}: IProps) => (
  <StyledFieldContainer>
    {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
    <PhoneInput
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      inputComponent={StyledPhoneInput}
      defaultCountry='US'
      disabled={disabled}
    />
    <StyledErrorText>{error}</StyledErrorText>
  </StyledFieldContainer>
);
