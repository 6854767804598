import styled from 'styled-components';

import { BodyText } from '@/components/designSystem/Typography';

export const StyledSingleResultTable = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;

  & > * {
    border-right: 2px solid ${({ theme }) => theme.color.greyMed};
    padding: 1rem;
  }
`;

export const StyledSingleResultTableIconCell = styled.div`
  border-right: none;
`

export const StyledSingleResultTableIconCellTitle = styled(BodyText).attrs({ bold: true })`
  border-right: none;
`

export const StyledText = styled(BodyText)`
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  display: flex;
  align-items: center;
`;
