import React, { Dispatch, SetStateAction, useState } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { BULK_UPLOAD_CUSTOMERS } from '@/graphql/mutations/inviteCustomer';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { get, merge, map, flow, includes } from 'lodash/fp';
import { mapValuesWithKey } from '@/utils/object';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

import { CustomerUploadForm } from './CustomerUploadForm';
import { IRow, IHeaderMap } from './types';

interface IFormValues {
  rows: IRow[],
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

export const ImportCustomerModal = ({ isOpen, setIsOpen, onSuccess }: IProps) => {
  const [bulkUploadCustomers, { loading }] = useMutation(BULK_UPLOAD_CUSTOMERS);

  const [rows, setRows] = useState<IRow[]>([]);

  // Column to Header mapping

  const headerMap = {
    customerNameHeader: {
      title: 'Customer Name',
      value: null,
    },
    customerEmailHeader: {
      title: 'Customer Email',
      value: null,
    },
    customerPhoneNumberHeader: {
      title: 'Customer Phone Number',
      value: null,
    },
    customerCompanyNameHeader: {
      title: 'Customer Company Name',
      value: null,
    },
  };

  const [headerMapState, setHeaderMapState] = useState<IHeaderMap>(headerMap);
  const headersNotLinked = flow(
    map(get('value')),
    includes(null),
  )(headerMapState);

  const setHeader = (mapToUpdate: any) => {
    const mapWithTitles = mapValuesWithKey((value: string, key: number) => {
      const { title } = (headerMapState as any)[key];
      return {
        title,
        value,
      };
    }, mapToUpdate);
    const newState = merge(headerMapState, mapWithTitles);
    setHeaderMapState(newState);
  };

  const clearHeaderMapState = () => setHeaderMapState(headerMap);

  // Formik fields

  const rowValidatior = Yup.object({
    [get('customerNameHeader.value', headerMapState)]: Yup.string().required(
      'Customer name is required',
    ),
    [get('customerEmailHeader.value', headerMapState)]: Yup.string()
      .email('Customer email must be a valid email')
      .required('Customer email is required'),
    [get('customerPhoneNumberHeader.value', headerMapState)]: Yup.string()
      .phone('Customer phone number must be a valid phone number')
      .required('Customer phone number is required'),
    [get('customerCompanyNameHeader.value', headerMapState)]:
      Yup.string().required('Company name is required'),
  });

  const fields = {
    csvFile: '',
    rows: [],
  };

  const validation = Yup.object().shape({
    csvFile: Yup.array().min(1, DEFAULT_ERROR_MESSAGES.REQUIRED),
    rows: Yup.array().of(rowValidatior).min(1, DEFAULT_ERROR_MESSAGES.REQUIRED),
  });

  const handleSubmit = (formValues: IFormValues) => {
    const { rows } = formValues;

    const nameColumn = get('customerNameHeader.value', headerMapState);
    const emailColumn = get('customerEmailHeader.value', headerMapState);
    const phoneNumberColumn = get('customerPhoneNumberHeader.value', headerMapState);
    const companyNameColumn = get('customerCompanyNameHeader.value', headerMapState);

    const customers = rows.map((row: IRow) => ({
      name: get(nameColumn, row),
      email: get(emailColumn, row),
      phoneNumber: get(phoneNumberColumn, row),
      companyName: get(companyNameColumn, row),
    }));

    return bulkUploadCustomers({ variables: { customers } })
      .then((response) => {
        showToast({
          title: 'Customers',
          description: `Uploaded ${customers.length} customer(s)`,
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      });
  };

  return (
    <StyledModalForm
      title='Import Customers'
      submitButtonText={
        rows.length > 0 ? `Upload ${rows.length} Customer(s)` : 'Upload'
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      initialValues={fields}
      validationSchema={validation}
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      maxWidth='900px'
      maxHeight='90vh'
      loading={loading}
      blockClose={true}
    >
      <CustomerUploadForm
        currentRows={rows}
        setRows={setRows}
        headerMapState={headerMapState}
        setHeader={setHeader}
        clearHeaderMapState={clearHeaderMapState}
        headersNotLinked={headersNotLinked}
        validation={validation}
      />
    </StyledModalForm>
  );
};
