import { IOnboardValues, IOnboardValuesAction } from './types';

export const UPDATE_BASIC_INFO = 'UPDATE_BASIC_INFO';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const UPDATE_COMPANY_CONTACTS = 'UPDATE_COMPANY_CONTACTS';

export const onboardValuesReducer = (state: IOnboardValues, action: IOnboardValuesAction) => {
  const { type, payload } = action;
  switch (type) {
  case UPDATE_BASIC_INFO: {
    return {
      ...state,
      basicInfo: payload,
    };
  }
  case UPDATE_COMPANY_PROFILE: {
    return {
      ...state,
      companyProfile: payload,
    };
  }
  case UPDATE_COMPANY_CONTACTS: {
    return {
      ...state,
      companyContacts: payload,
    };
  }
  default:
    return state;
  }
}
