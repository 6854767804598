import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableLocation } from 'react-beautiful-dnd';

import { get, merge as values } from 'lodash/fp';

import { BodyText } from '@/components/designSystem/Typography';

import { ColumnHeaderConnector } from './ColumnHeaderConnector';
import {
  ColumnHeaderDragDropContainer,
  ColumnHeaderConnectorContainer,
  DragAreaContainer,
  StepHeader,
  ColumnDragLabel,
  FlexItem,
} from './styled';
import { IHeaderMap } from './types';

interface IProps {
  columns: string[],
  result: DropResult,
  headerMapState: IHeaderMap,
  setHeader: (arg: any) => void,
}

export const ColumnHeaderDragDrop = ({
  columns, result, headerMapState, setHeader,
}: IProps) => {
  const resultExample = get('data[0]', result);

  const headerValues: any = values(headerMapState);
  const unusedColumns = columns.filter(
    (column) => !headerValues.map(get('value')).includes(column),
  );

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    // Drag to self
    if (destination.droppableId === source.droppableId) {
      return;
    }

    // Drag header to columns
    if (destination.droppableId === 'columns') {
      setHeader({ [source.droppableId]: null });
      return;
    }

    // Drag columns to header
    if (source.droppableId === 'columns') {
      const columnIndex = source.index;
      const columnValue = unusedColumns[columnIndex];

      setHeader({ [destination.droppableId]: columnValue });

      return;
    }

    // Drag header to header
    const { value: sourceValue } = get(source.droppableId, headerMapState);

    setHeader({
      [source.droppableId]: null,
      [destination.droppableId]: sourceValue,
    });
  };

  return (
    <ColumnHeaderDragDropContainer>
      <StepHeader bold> Step 2: Map Columns to Customer Fields</StepHeader>
      <BodyText bold color='secondary'>
        All Columns
      </BodyText>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='columns' direction='horizontal'>
          {(provided, snapshot) => (
            <DragAreaContainer
              ref={provided.innerRef}
              isDragging={snapshot.isDraggingOver}
            >
              {unusedColumns.map((column, index) => (
                <Draggable key={column} draggableId={column} index={index}>
                  {(provided, snapshot) => (
                    <ColumnDragLabel
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={
                        snapshot.isDragging && !snapshot.isDropAnimating
                      }
                    >
                      {column}
                    </ColumnDragLabel>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </DragAreaContainer>
          )}
        </Droppable>
        <ColumnHeaderConnectorContainer>
          <FlexItem>
            <BodyText bold color='secondary'>
              CSV Column
            </BodyText>
          </FlexItem>
          <FlexItem />
          <FlexItem>
            <BodyText bold color='secondary'>
              Nectarine Credit Field
            </BodyText>
          </FlexItem>
        </ColumnHeaderConnectorContainer>
        {Object.keys(headerMapState).map((key, index) => {
          const { title, value } = (headerMapState as any)[key];
          return (
            <ColumnHeaderConnector
              key={key}
              droppableId={key}
              title={title}
              value={value}
              index={index}
              resultExample={resultExample}
            />
          );
        })}
      </DragDropContext>
    </ColumnHeaderDragDropContainer>
  );
};
