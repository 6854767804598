import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      email
      emailVerified
      emailNotificationsSettings {
        applicationSubmitted
        vendorReferenceSubmitted
        applicationApprovalOrDenial
        applicationChangesRequested
      }
      userCompanyRole {
        isAdmin
        creditLimit
        company {
          id
        }
        role {
          name
          isCreditLimitApply
          permissions {
            inviteCustomer
            readOnly
            inviteUser
            changeTemplate
          }
        }
      }
    }
  }
`;
