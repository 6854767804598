import styled from 'styled-components';
import { Tab, TabList } from 'react-tabs';

import { Form } from 'formik';

import { GrayBox } from '@/components/designSystem/GrayBox';
import { Button } from '@/components/designSystem/buttons';
import { H3, H4, StyledLinkButtonUnderline } from '@/components/designSystem/Typography';
import {
  FlexColumn,
  FlexRow,
  FlexRowEnd,
  FlexRowItemsCenter,
  FlexRowSpaceBetween,
  FlexColumnGap,
  Flex,
} from '@/components/designSystem/Layout';

import { GreenCheckIcon } from '@/components/icons';

const COLUMN_GAP = '4rem';

export const StyledContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  // grid-template-areas: 
  // "main_info sidebar"
  // "tabs sidebar";
  // grid-template-columns: calc(100% - 300px - ${COLUMN_GAP}) 300px;
  // grid-column-gap: ${COLUMN_GAP};
  grid-template-areas: 
  "main_info"
  "tabs";
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: 10rem auto;
`;

// MAIN INFO

export const StyledMainInfoContainer = styled(FlexColumn)`
  grid-area: main_info;
  margin-bottom: 2rem;
  gap: 1rem;
`;

export const StyledMainInfoValuesContainer = styled(FlexRowItemsCenter)`
  & > * {
    padding-left: 3rem;
  }
`;

// ACTIONS

// Before we remove dropdown

export const StyledPopupContainer = styled(FlexColumn)`
  padding: 2rem 1.5rem 0 1.5rem;
`;

export const StyledActionContainer = styled(FlexRowEnd)`
  padding: 1.5rem;

  & > * {
    margin-right: 1rem;
  }

  & > :last-child{
    margin-right: 0;
  }
`;

export const StyledApprovedContainer = styled.div`
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 2rem;
`;

export const StyledSectionsOptionsGrid = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

// SIDE BLOCK

export const StyledSideContainer = styled(FlexColumn)`
  grid-area: sidebar;
  height: 100%;

  & > div {
    margin-bottom: 2rem;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledSideTitleBlock = styled(FlexRowItemsCenter)`
  & > :first-child {
    margin-right: 1rem;
  }
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.primary};
`;

export const StyledSideTitle = styled(H3)`
  white-space: nowrap;
  color: ${({ theme }) => theme.color.white};
`

export const StyledSideTitleLine = styled(FlexRow)`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.greyMed};
`;

export const StyledSideButton = styled(Button)`
  width: 50%;
`;

// HISTORY

export const StyledHistoryItemContainer = styled(FlexRowSpaceBetween)`
  gap: 0.5rem;

  & > :last-child {
    min-width: 25%;
  }
`;

export const StyledHistoryItemCaptions = styled(FlexColumn)`
  gap: 0.2rem;
  margin-bottom: 0.4rem;
`;

// ACTIVITIES

export const StyledEmailActivityFeedContainer = styled(FlexColumnGap)`
  flex-grow: 1;
`;

export const StyledActivitiesList = styled(FlexColumnGap)`
  margin-top: 1rem;
`;

export const StyledShowAllButton = styled(StyledLinkButtonUnderline)`
  margin-bottom: 1rem;
`;

// SHARED

export const StyledTitleRow = styled(FlexRowSpaceBetween)`
  align-items: baseline;
`;

export const StyledTitle = styled(H3)`
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const StyledGrayBox = styled(GrayBox)`
  padding: 1.5rem;
`;

export const StyledButtonsWrapper = styled(FlexRowEnd)`
  gap: 1rem;
`;

export const StyledButtonsContainer = styled(StyledButtonsWrapper)`
  margin-top: .5rem;
`;

export const StyledToggleReferenceContentHeaderContainer = styled(Flex).attrs({ justify: 'space-between' })`
  padding-left: 1.5rem;
  padding-bottom: .5rem;
  margin-top: -1rem;
`;

export const StyledToggleReferenceHeader = styled(FlexRow)`
  flex-grow: 1;

  & > :first-child {
    flex-basis: 17rem;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const StyledButton = styled(Button)`
  height: 3.5rem;
`;

export const StyledRow = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 1rem;
  grid-gap: 1rem;
`;

export const StyledAutoFitColumnsRow = styled(StyledRow)`
  grid-template-columns: repeat( auto-fit, minmax(10rem, 1fr) );
`;

export const StyledTwoColumnsRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr;
`;

// TABS

export const StyledTabList = styled(TabList)`
  position: relative;
  border-bottom: 1px solid #dadada;;
  padding: 0;

  & > li {
    margin-right: 0.5rem;
  }
`;

export const StyledTab = styled(Tab)`
  display: inline-block;
  border: 1px solid transparent;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 8px 12px 2px 12px;
  cursor: pointer;
  outline: none;
  font-size: 0.8rem;
  height: 2rem;
  border-radius: 5px 5px 0 0;
  border-color: #dadada;

  &.react-tabs__tab--selected {
    text-shadow: 0px 0px 1px black;
    border-bottom: none;
  }
`;

export const StyledTabPanelContainer = styled.div`
  border: 1px solid #dadada;
  border-top: none;
  padding: 1.5rem;
  border-radius: 0 0 5px 5px;
`;

export const StyledCheckIcon = styled(GreenCheckIcon)`
  margin-right: 0.5rem;
`;

export const StyledCloseIconWrapper = styled.div`
  cursor: pointer;
`;

export const StyledSlideActionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  form {
    position: relative;
    height: calc(100% - 8rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
`;

export const StyledFormButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  padding: 1rem;
  border-top: 1px solid lightgrey;
  display: flex;
  gap: 0.5rem;
  background: #ffffff;

  button {
    flex: 1 1 0%;

    label {
      width: 8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const StyledDropdownContainer = styled.div`
  position: absolute;
  right: 0px;
  top: -0.75rem;
`

