import React, { Dispatch, SetStateAction } from 'react';

import { FieldArray, Form, useFormikContext } from 'formik';

import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';
import { StyledButtonsWrapper } from '@/views/CustomerPage/styled';

import { initialManualBankFields } from './initialValues';
import { IManualBankFormValues } from './types';
import { ManualBankSection } from './ManualBankSection';

interface IProps {
  setAddManualBankActive: Dispatch<SetStateAction<boolean>>;
}

export const AddManualBankInformationForm = ({ setAddManualBankActive }: IProps) => {
  const { values } = useFormikContext<IManualBankFormValues>();

  return (
    <Form>
      <FieldArray
        name='manualBanks'
        render={(arrayHelpers) => (
          <>
            {
              values.manualBanks ? values.manualBanks.map((_owner, index) => (
                <ManualBankSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  parentKey={`manualBanks[${index}]`}
                  onRemoveClick={() => arrayHelpers.remove(index)}
                />
              )) : false
            }
            <Flex direction='row' justify='space-between'>
              <Button
                secondary
                onClick={() => arrayHelpers.push(initialManualBankFields)}
              >
              Add Bank
              </Button>
              <StyledButtonsWrapper>
                <Button
                  secondary
                  onClick={() => setAddManualBankActive(false)}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  type='submit'
                >
                  Save
                </Button>
              </StyledButtonsWrapper>
            </Flex>
          </>
        )}
      />
    </Form>)
};