import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';

import { getOr, noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ISettings } from '@/types/settings';
import { ICustomField } from '@/types/customField';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ChangesRequestedValuesContext } from '../../context';
import { companyProfileStepValidation } from './validation';
import { UPDATE_COMPANY_PROFILE } from '../../reducer';
import { IChangesRequestedValuesAction } from '../../types';
import { CompanyProfileStepForm } from './form';
import { ICompanyProfileValues } from './types';
import { useMutation } from '@apollo/react-hooks';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { mapChangesRequestedValuesToCompanyInfoVariables } from './functions';

import gql from 'graphql-tag';

export const CHANGE_REQUEST_COMPANY_INFO = gql`
  mutation ChangeRequestCompanyInfo($changeRequestId: ID!, $companyInfo: CompanyInfoInput!) {
    change_request_company_info(changeRequestId: $changeRequestId, companyInfo: $companyInfo) {
      customer {
        id
      }
    }
  }
`;

export interface ICompanyProfileStepProps {
  companySettings?: ISettings,
  additionalQuestions: ICustomField[],
  dispatch: Dispatch<IChangesRequestedValuesAction>,
  nextStepPath: string | null,
}

export const CompanyProfileStep = ({ additionalQuestions, companySettings, dispatch, nextStepPath }: ICompanyProfileStepProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const changesRequestedValues = useContext(ChangesRequestedValuesContext);
  const changeRequest = changesRequestedValues?.changeRequest;

  const firstUpdate = useRef(true);

  const [changeRequestCompanyInfo] = useMutation(CHANGE_REQUEST_COMPANY_INFO);

  const submitStep = () => {

    if (!changesRequestedValues) {
      return noop;
    }

    const companyInfo = mapChangesRequestedValuesToCompanyInfoVariables(changesRequestedValues);

    return changeRequestCompanyInfo({
      variables: {
        changeRequestId: changeRequest?.id,
        companyInfo,
      }
    })
      .then(() => {
        history.push({ pathname: `${changesRequestedValues.basePath}/${nextStepPath}`, search });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep();
  }, [changesRequestedValues]);

  const dispatchValues = (values: ICompanyProfileValues) => {
    dispatch({ type: UPDATE_COMPANY_PROFILE, payload: values })
  }

  const requireShippingAddress = getOr(false, 'requireShippingAddress', companySettings);
  const requireBillingAddress = getOr(false, 'requireBillingAddress', companySettings);

  if (!changesRequestedValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={changesRequestedValues.companyProfile}
      validationSchema={
        companyProfileStepValidation(
          { requireBillingAddress, requireShippingAddress },
          additionalQuestions
        )
      }
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <CompanyProfileStepForm companySettings={companySettings} additionalQuestions={additionalQuestions} />
    </Formik>
  )};
