import { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { IS_DNB_CREDENTIALS_STILL_VALID } from '@/graphql/mutations/generateDnbReport';

import { getOr } from 'lodash/fp';

export const useDnb = () => {
  const [showDnbCredentialsModal, setShowDnbCredentialsModal] = useState(false);
  const [showDnbSearchModal, setShowDnbSearchModal] = useState(false);
  const [getDnbCredentialsStillValid, { loading: dnbLoading }] = useMutation(IS_DNB_CREDENTIALS_STILL_VALID);

  const onDnbClick = () => {
    getDnbCredentialsStillValid()
      .then((data) => {
        const isDnbCredentialsStillValid = getOr(false, 'data.isDnbCredentialsStillValid', data);
        if (isDnbCredentialsStillValid) {
          setShowDnbSearchModal(true);
        } else {
          setShowDnbCredentialsModal(true);
        }
      });
  };

  return {
    showDnbCredentialsModal,
    setShowDnbCredentialsModal,
    showDnbSearchModal,
    setShowDnbSearchModal,
    dnbLoading,
    onDnbClick,
  }
}