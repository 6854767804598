import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'formik';
import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';
import { StyledButtonsWrapper } from '@/views/CustomerPage/styled';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledFormRow, StyledMultiRow } from '@/components/designSystem/Form/styled';

interface IProps {
  setAddVendorsActive: Dispatch<SetStateAction<boolean>>;
}

export const AddVendorForm = ({ setAddVendorsActive }: IProps) => (
  <Form>
    <StyledMultiRow>
      <StyledFormRow>
        <TextField required type='text' name='companyName' label='Company Name' placeholder='Company ABC' />
        <TextField required type='text' name='name' label='Contact Full name' placeholder='John Doe' />
      </StyledFormRow>
      <StyledFormRow>
        <PhoneNumberField name='phoneNumber' label='Phone Number' placeholder='Phone number' />
        <TextField required type='text' name='email' label='Email Address' placeholder='John@streamlink.com' />
      </StyledFormRow>
    </StyledMultiRow>
    <Flex direction='row' justify='end'>
      <StyledButtonsWrapper>
        <Button
          secondary
          onClick={() => setAddVendorsActive(false)}
        >
          Cancel
        </Button>
        <Button
          primary
          type='submit'
        >
          Save
        </Button>
      </StyledButtonsWrapper>
    </Flex>
  </Form>);