import styled from 'styled-components';
import { Form } from 'formik';

import { SignAgreementButton } from '@/components/SignAgreementButton';
import { Caption } from '@/components/designSystem/Typography';
import { FlexColumn, FlexColumnItemsStretch } from '@/components/designSystem/Layout';

export const CheckboxesContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  & > * {
    margin-bottom: 1rem;
  }
`;

export const SignButtonContainer = styled(FlexColumn)`
  & > * {
    margin-bottom: 1rem;
  }

  & > :last-item {
    margin-bottom: 0;
  }
`;

export const SignButton = styled(SignAgreementButton).attrs({ primary: true })`
  width: 100%;
`;

export const CreditSubContainer = styled(FlexColumn)`
  & > * {
    margin-bottom: 1rem;
  }
`;

export const SignedAgreementsContainer = styled(FlexColumnItemsStretch)`
  & > * {
    margin-bottom: 1rem;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const CommentDisclaimer = styled(Caption).attrs({ color: 'secondary' })`
  padding-top: 0.75rem;
`;
