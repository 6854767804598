import gql from 'graphql-tag';

export const GET_COMPANY_BY_ID = gql`
  query getCompanyById($companyId: ID) {
    getCompanyById(companyId: $companyId) {
      id
      providerInfo {
        contactEmail
        contactPhone
      }
    }
  }
`;
