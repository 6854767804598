import React from 'react';
import { useTheme } from 'styled-components';

import { StepCounter } from '@/components/Onboard/StepCounter';

import { StyledStepsContainer } from './styled';

interface IProps {
  steps: string[];
  activeStep: number;
}

export const Steps = ({ steps, activeStep }: IProps) => {
  const theme: any = useTheme();

  return (
    <StyledStepsContainer>
      <StepCounter activeStep={activeStep} steps={steps} activeColor={theme.color.primary} />
    </StyledStepsContainer>
  )
};
