import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  grid-area: center;
  padding: 1rem 7.75rem;
  flex-direction: column;
  align-items: center;
`;

export const LeftSide = styled.div`
  grid-area: left;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RightSide = styled(LeftSide)`
  grid-area: right;
`;

export const PoweredByContainer = styled.a`
  position: fixed;
  bottom: 0;
  left: 2rem;
  width: 100px;

  & > * {
    width: 100%;
  }
`;

export const StepFooterContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 1.5rem;
  margin-top: 2.5rem;
  border-top: ${({ theme }) => theme.color.border.light};
  background : ${({ theme }) => theme.color.white};
`;
