import React, { useState, useEffect } from 'react';

import { getOr } from 'lodash/fp';

import { ICompanyUser, IUserCompanyRole } from '@/types/companyUser';
import { ICurrentCompany } from '@/types/currentCompany';

import { AutoSave } from '@/components/designSystem/Form/AutoSave';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';

import { USER_ROLES } from '@/constants';

import { IPendingUser } from '.';
import {
  StyledContainer,
  StyledAdminCheckboxContainer,
  StyledFieldLabel,
  StyledAdminCheckboxWrapper,
  StyledField,
  StyledSelectField
} from './styled';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';

interface IUserProps {
  user: ICompanyUser | IPendingUser,
  company: ICurrentCompany,
}

export const UserForm = ({ user, company }: IUserProps) => {
  const [showCreditLimit, setShowCreditLimit] = useState(false);

  const userRoles = getOr([], 'userCompanyRole', user);
  const listUserCompanyRole = userRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === company.id)
  const isCreditLimitApply = getOr(true, 'role.isCreditLimitApply', listUserCompanyRole[0])

  useEffect(() => {
    setShowCreditLimit(isCreditLimitApply)
  }, [isCreditLimitApply])

  return (
    <StyledContainer>
      <StyledSelectField
        required
        name='role'
        label='Role'
        options={USER_ROLES}
      />
      <CurrencyField
        name='creditLimit'
        label='Credit Limit'
        disabled={!isCreditLimitApply && !showCreditLimit}
        required
      />
      <StyledAdminCheckboxContainer>
        <StyledFieldLabel>User Admin</StyledFieldLabel>
        <StyledAdminCheckboxWrapper>
          <CheckboxField name='isAdmin' />
        </StyledAdminCheckboxWrapper>
      </StyledAdminCheckboxContainer>
      <AutoSave debounceMs={2000} showText={false} />
    </StyledContainer>
  );
};
