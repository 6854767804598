import React, { useState } from 'react';

import { Button } from '@/components/designSystem/buttons';
import { EditEmailModal } from '@/modals/EditEmailModal';

interface IProps {
  refetch: () => void,
  email: string,
}

export const EmailEditButton = ({ refetch, email }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Edit</Button>
      <EditEmailModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} email={email} />
    </>
  );
};
