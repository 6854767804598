import React, { Dispatch, SetStateAction, useContext } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { UPSERT_VENDOR_REFERENCES } from '@/graphql/mutations/upsertVendorReferences';

import { VisitorContext } from '@/providers/VisitorContext';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { get } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ICustomer } from '@/types/customer';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

import { PhoneNumberField } from '../components/designSystem/Form/PhoneNumberField';

const vendorValidation = Yup.object({
  name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  email: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  companyName: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
});

const vendorFields = {
  companyName: '',
  name: '',
  email: '',
  phoneNumber: '',
};

interface IFields {
  name: string,
  email: string,
  companyName: string,
  phoneNumber: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
  customer: ICustomer,
}

export const InviteVendorModal = ({
  isOpen, setIsOpen, customer, onSuccess,
}: IProps) => {

  const visitorData = useContext(VisitorContext);
  const [upsertVendorReferences, { loading }] = useMutation(UPSERT_VENDOR_REFERENCES);
  const forCompanyId = get('providerCompany.id', customer);

  const handleSubmit = async ({
    name, email, companyName, phoneNumber,
  }: IFields) => {
    const variables = {
      vendorReferences: [{
        companyName,
        name,
        email,
        phoneNumber,
      }],
      blockSend: false,
      forCompanyId,
      visitorData,
      customerId: customer.id,
      deleteExisting: false
    };

    upsertVendorReferences({ variables })
      .then((response) => {
        showToast({
          title: 'Reference Requested',
          description: `Sent Request to ${name}`,
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      });
  };

  return (
    <StyledModalForm
      title='Request a Vendor Reference'
      submitButtonText='Send Request'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={vendorFields}
      validationSchema={vendorValidation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <TextField type='text' name='companyName' label='Company Name' placeholder='Company ABC' />
      <TextField type='text' name='name' label='Contact Full Name' placeholder='John Doe' />
      <PhoneNumberField required name='phoneNumber' label='Phone Number' placeholder='Phone number' />
      <TextField type='text' name='email' label='Email Address' placeholder='John@streamlink.com' />
    </StyledModalForm>
  );
};
