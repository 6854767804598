import { useEffect } from "react";
import { writeStorage } from "@rehooks/local-storage";

import { ApolloError, useQuery } from "@apollo/react-hooks";
import { GET_REFERRAL_DATA } from "@/graphql/queries/getReferralData";

import { get, getOr } from "lodash/fp";

import { IReferralData } from "@/types/referralData";

export const useReferral = (
  referralCode: string | null,
  sanitizedCompanyId: string | null,
  customerIdParam: string | null,
  isCompanyPersonnelData: any,
  isCompanyPersonnelError: ApolloError | undefined,
  isCompanyPersonnel: boolean,
  authLoading: boolean,
) => {
  const { data } = useQuery<IReferralData>(GET_REFERRAL_DATA, {
    variables: {
      referralCode,
      companyId: sanitizedCompanyId,
      customerId: customerIdParam,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    skip: (!isCompanyPersonnelData && !isCompanyPersonnelError) || isCompanyPersonnel || authLoading,
  });

  const customerId = getOr('', 'getReferralData.customer.id', data);

  const customerData = get('getCustomerAsSeeker', data);
  const loginRequired = customerIdParam && !customerData;

  useEffect(() => {
    if (customerId) {
      writeStorage('customer-id', customerId);
    }
  }, [customerId]);

  return {
    refData: data,
    customerId: customerIdParam ?? customerId,
    loginRequired: !!loginRequired,
  }
}