import styled from 'styled-components';
import { BodyText } from '@/components/designSystem/Typography';

interface IProps {
  maxHeight?: string,
}

export const AgreementText = styled(BodyText)<IProps>`
  white-space: pre-line;
  max-height: ${({ maxHeight }) => maxHeight || '400px'};
  overflow: scroll;
`;
