import React, { Dispatch, SetStateAction } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { UPDATE_EMAIL } from '@/graphql/mutations/updateEmail';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

const validation = Yup.object().shape({
  email: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

interface IFields {
  email: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
  email: string,
}

export const EditEmailModal = ({
  isOpen, setIsOpen, onSuccess, email,
}: IProps) => {
  const [updateEmail, { loading }] = useMutation(UPDATE_EMAIL);

  // eslint-disable-next-line no-shadow
  const handleSubmit = async ({ email }: IFields) => {
    updateEmail({ variables: { email } })
      .then((response) => {
        showToast({
          title: 'Email Successfully Changes!',
          description: 'Please check your inbox to for an email to verify your new address',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      });
  };

  const fields = {
    email,
  };

  return (
    <StyledModalForm
      title='Update Email'
      submitButtonText='Confirm'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
    </StyledModalForm>
  );
};
