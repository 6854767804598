import gql from 'graphql-tag';

export const PROVIDER_UPDATE_COMPANY = gql`
  mutation ProviderUpdateCompany($applicationId: ID!, $companyInfo: CompanyInfo!) {
    providerUpdateCompany(applicationId: $applicationId, companyInfo: $companyInfo) {
      name,
      otherNames,
      type,
      address {
        city,
        state,
        country,
      },
      phoneNumber,
      website,
      established,
      description,
      updatedAt
    }
  }
`;
