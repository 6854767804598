import React, { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { BankConnectedView } from './BankConnectedView';
import { ConnectBankView } from './ConnectBankView';
import { ChangesRequestedValuesContext } from '../../../context';

interface IProps {
  isFinicityConnected: boolean,
  requireBankInfo: boolean,
  refetchFinicityCustomerData: () => void,
  automaticBankVerificationEnabled: boolean,
  basePath: string,
}

export const FinancialHealthStepView = ({
  isFinicityConnected,
  requireBankInfo,
  refetchFinicityCustomerData,
  automaticBankVerificationEnabled,
  basePath,
}: IProps) => {
  const { search } = useLocation();
  const changesRequestedValues = useContext(ChangesRequestedValuesContext);

  if (!automaticBankVerificationEnabled && !isFinicityConnected || changesRequestedValues?.financialHealth.isManualBank) {
    return <Redirect from={`${basePath}/bank`} to={{ pathname: `${basePath}/bank/manual`, search }} />
  }

  return (
    <ConnectBankView
      requireBankInfo={requireBankInfo}
      refetchFinicityCustomerData={refetchFinicityCustomerData}
    />
  )
}
