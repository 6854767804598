import styled from 'styled-components';
import { Caption } from '@/components/designSystem/Typography';
import { FlexRowItemsCenter } from '../designSystem/Layout';

interface IProps {
  type?: string,
}

export const LabelContainer = styled(FlexRowItemsCenter)<IProps>`
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;

  background: ${({ type, theme }) => {
    if (type === 'warning') return theme.color.label.warning;
    if (type === 'danger') return theme.color.label.danger;
    if (type === 'success') return theme.color.label.success;
    if (type === 'info') return theme.color.label.info;
    return theme.color.label.default;
  }};
`;

export const LabelText = styled(Caption)`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
