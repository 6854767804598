import React, { Dispatch, SetStateAction, useState } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { UPDATE_CUSTOM_FIELD } from '@/graphql/mutations/updateCustomField';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { ICustomField } from '@/types/customField';
import { IForToggle } from '@/types/customField';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

import { UpdateCustomFieldModalForm } from './UpdateCustomFieldModalForm';
import { omit } from 'lodash/fp';

export interface IUpdateCustomFieldFormValues {
  label?: string,
  description: string,
  required?: boolean,
  allowMultipleUploads: boolean,
  emailRequired: boolean,
  phoneRequired: boolean,
  positionRequired: boolean,
  yesToggle: IForToggle,
  noToggle: IForToggle,
  toggleMultipleUpload: boolean,
  options: string[],
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
  customFieldProps: ICustomField,
}

export const UpdateCustomFieldModal = ({
  isOpen, setIsOpen, onSuccess, customFieldProps,
}: IProps) => {
  const [updateCustomField, { loading }] = useMutation(UPDATE_CUSTOM_FIELD);

  const {
    id: customFieldId, label, description, required, type, allowMultipleUploads, contact, yesToggle, noToggle, options
  } = customFieldProps;

  const [contactNameRequired, setContactNameRequired] = useState(contact.nameRequired);

  const validationShape = {
    label: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    description: Yup.string(),
    required: Yup.boolean()
  };

  const customFieldValidation = Yup.object().shape(validationShape);

  const customFieldFields = {
    label,
    description: description || '',
    required,
    type,
    allowMultipleUploads,
    emailRequired: contact.emailRequired,
    phoneRequired: contact.phoneRequired,
    positionRequired: contact.positionRequired,
    yesToggle: omit('__typename', yesToggle),
    noToggle: omit('__typename', noToggle),
    options,
  };

  // eslint-disable-next-line no-shadow
  const handleSubmit = async ({
    // eslint-disable-next-line no-shadow
    label,
    // eslint-disable-next-line no-shadow
    description,
    // eslint-disable-next-line no-shadow
    required,
    // eslint-disable-next-line no-shadow
    allowMultipleUploads,
    emailRequired,
    phoneRequired,
    positionRequired,
    yesToggle,
    noToggle,
    options,
  }: IUpdateCustomFieldFormValues) => {
    const variables = {
      customFieldId,
      customFieldAttrs: {
        label,
        type,
        description,
        required,
        allowMultipleUploads,
        options,
        contact: {
          showName: true,
          nameRequired: contactNameRequired,
          showEmail: true,
          emailRequired,
          showPhone: true,
          phoneRequired,
          positionRequired,
          showExtension: true,
          extensionRequired: false,
        },
        yesToggle: yesToggle,
        noToggle: noToggle,
      },
    };

    updateCustomField({ variables })
      .then((response) => {
        setIsOpen(false);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        showToast({
          title: 'Error',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <StyledModalForm
      title={`Edit "${label}" field`}
      submitButtonText='Save'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={customFieldFields}
      validationSchema={customFieldValidation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      maxHeight='800px'
      blockClose={true}
    >
      <UpdateCustomFieldModalForm
        type={type}
        contact={contact}
        contactNameRequired={contactNameRequired}
        setContactNameRequired={setContactNameRequired}
      />
    </StyledModalForm>
  );
};
