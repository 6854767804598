import React from 'react';

import { Formik } from 'formik';

import { useAuth0 } from '@/providers/Auth0/Auth0';

import { loginValidation } from './validation';
import { loginInitialValues } from './initialValues';
import { ILoginValues } from './types';
import { StyledContainer } from '../styled';
import { LoginForm } from './form';

export const LoginPage = () => {
  const { loginWithCompany, loading } = useAuth0();

  const handleSubmit = (values: ILoginValues) => {
    const { email, password } = values;
    loginWithCompany(email, password);
  }

  return (
    <StyledContainer>
      <Formik
        initialValues={loginInitialValues}
        validationSchema={loginValidation}
        onSubmit={handleSubmit}
        validateOnChange
      >
        <LoginForm isLoading={loading} />
      </Formik>
    </StyledContainer>
  );
};
