import gql from 'graphql-tag';

export const GET_DASHBOARD_OVERVIEW = gql`
  query GetDashboardOverview {
    currentCompany {
      id
      customers {
        id
      }
      pendingCustomerInvites {
        id
      }
      customerOf {
        id
      }
      givenReferences {
        id
      }
    }
  }
`;
