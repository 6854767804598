import React, { useState } from 'react';

import { UpdateCustomFieldModal } from '@/modals/CustomField_old/UpdateCustomFieldModal';

import { ReactComponent as Edit } from '@/assets/edit.svg';

import { StyledEditButton } from './styled';
import { ICustomFieldProps } from './CustomFieldItem';

export const EditAction = ({ refetch, ...customFieldProps }: ICustomFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <StyledEditButton onClick={() => setIsOpen(true)}><Edit /></StyledEditButton>
      <UpdateCustomFieldModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        customFieldProps={customFieldProps}
        onSuccess={() => refetch()}
      />
    </>
  );
};