import React from 'react';

import { getOr, get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IVendor } from '@/types/vendor';

import { CREDIT_APPLICANT_STATUSES } from '@/constants';

import { EmptyState } from '@/components/EmptyState';

import { Reference } from './Reference';
import { AdditionalRefRequiredMessage } from './AdditionalRefRequiredMessage';
import { getAllReferences } from '../functions';

interface IProps {
  customerData: ICustomer,
  refetch: () => void,
  canSeeEditControl: boolean,
}

export const ReferencesList = ({ customerData, refetch, canSeeEditControl }: IProps) => {
  const receivedReferences = customerData ? getAllReferences(customerData) : [];
  const numRequiredReferences = getOr(0, 'providerCompany.numRequiredReferences', customerData);
  const vrCustomFields = get('providerCompany.vrCustomFields', customerData);
  const applicationStatus = get('latestApplication.status', customerData);
  const additionalReferencesRequired = numRequiredReferences - receivedReferences.length;

  return (
    <>
      {
        receivedReferences.length === 0
          ? <EmptyState>No Vendor References provided yet</EmptyState>
          : receivedReferences.map((reference: IVendor) => <Reference
            key={reference.id || reference.sourceCompany.inviteId}
            reference={reference}
            customerData={customerData}
            canSeeEditControl={canSeeEditControl}
            vrCustomFields={vrCustomFields}
            isApplicationInProgress={applicationStatus === CREDIT_APPLICANT_STATUSES.IN_PROGRESS}
            refetch={refetch}
          />)
      }
      {additionalReferencesRequired > 0 && <AdditionalRefRequiredMessage number={additionalReferencesRequired} customer={customerData} />}
    </>
  )
};
