import styled, { css } from 'styled-components';
import { Form } from 'formik';

import { ReactComponent as Logo } from '@/assets/logo_full.svg';
import { FlexColumnCenterItemsCenter, FlexColumnItemsCenter, FlexColumnItemsStart, FlexRowEnd, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

interface ISkeletonProps {
  width: string,
  height: string,
}

interface INodeTextColorArgs {
  isActive?: boolean,
  theme: any,
}

interface IProps {
  complete?: boolean,
  isActive?: boolean,
  bold?: boolean,
}

interface IPreviewButtonProps {
  textColor: string,
}

export const FormSkeletonContainer = styled(FlexColumnItemsStart)`
  width: 100%;
  padding-bottom: 6px;
  margin-bottom: 12px;
  border-bottom: ${({ theme }) => theme.color.border.light};
`;

const Skeleton = styled.div<ISkeletonProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ theme }) => theme.color.greySkeleton};
  margin: 6px 0;
  border-radius: 3px;
`;

export const TitleSkeleton = styled(Skeleton).attrs({ width: '50%', height: '16px' })``;
export const SubTitleSkeleton = styled(Skeleton).attrs({ width: '100%', height: '16px' })``;
export const LabelSkeleton = styled(Skeleton).attrs({ width: '30px', height: '12px' })``;
export const InputSkeleton = styled(Skeleton).attrs({ width: '75%', height: '24px' })``;

const nodeTextColor = ({ isActive, theme }: INodeTextColorArgs) => (isActive ? theme.color.brand : theme.color.text.stepper);

export const StepNodeContainer = styled.li<IProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  position: relative;
  color: ${nodeTextColor};

  &:before {
    height: 2px;
    width: calc(100% - 1rem);
    content: '';
    background: ${({
    isActive, complete, theme, color,
  }) => ((isActive || complete) ? color : theme.color.text.stepper)};
    position: absolute;
    top: 0.55rem;
    left: calc(-50% + 0.5rem);
    z-index: 0;
  }

  &:first-child {
    &:before {
      content: none;
    }
  }
`;

export const StepBadge = styled.span<IProps>`
  font-size: 8px;
  line-height: 12px;
  width: 1rem;
  height: 1rem;
  align-self: center;
  background: ${({ complete, theme, color }) => (complete ? color : theme.color.site.white)};
  border: 2px solid ${({
    isActive, complete, theme, color,
  }) => ((isActive || complete) ? color : theme.color.text.stepper)};
  color: ${({
    isActive, complete, theme, color,
  }) => {
    if (isActive) return color;
    if (complete) return theme.color.white;
    return theme.color.text.stepper;
  }};
  border-radius: 100%;
  margin-bottom: .5em;
  z-index: 5;
`;

export const StepCounterContainer = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  min-width: 45%;
`;

export const StylePreviewContainer = styled(FlexColumnItemsCenter)`
  height: 400px;
  width: 100%;
  max-width: 600px;
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.color.shadow.shadowLarge};
  background-color: ${({ color }) => color};
`;

export const PreviewHeader = styled(FlexRowSpaceBetweenItemsCenter)`
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  height: 100%;
`;

export const PreviewBody = styled(FlexColumnCenterItemsCenter)`
  width: 100%;
  border-radius: 1.25rem 1.25rem 0.5rem 0.5rem;
  background-color: ${({ color }) => color};
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
`;

export const PreviewBodyContent = styled(FlexColumnCenterItemsCenter)`
  padding: 0 20% 20px 20%;
  width: 100%;
  box-sizing: border-box;
`;

export const PreviewButton = styled.button<IPreviewButtonProps>`
  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  font-size: 8px;
  line-height: 12px;
  padding: 6px;
  border-radius: 3px;
  margin-left: 6px;
  border: ${(props) => props.theme.color.border.light};
`;

const LogoStyle = css`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

export const StyledLogo = styled(Logo)`${LogoStyle}`;
export const PreviewLogo = styled.img`${LogoStyle}`;

export const HeaderItem = styled.div`
  width: 20%;
  height: 100%;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 7rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin-bottom: 2rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 calc((100% - 3rem)/2);
  max-width: 50%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & > :first-child {
    margin-bottom: 1rem;
  }
`;
