import React from 'react';
import styled, { keyframes } from 'styled-components';

import { StyledModal } from '@/modals/StyledModal';
import { BodyText, H3 } from '@/components/designSystem/Typography';
import { FlexColumn, FlexColumnCenterItemsCenter } from '../components/designSystem/Layout';

const StyledContainer = styled(FlexColumnCenterItemsCenter)`
  padding: -10rem 2rem 2rem 2rem;
 
  & > * {
    margin-bottom: 0.5rem;
  }
`;

const StyledListContainer = styled(FlexColumn)`
  padding: 2rem;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

const ShowItem = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const StyledListItem = styled(BodyText)`
  opacity: 0;
  animation: ${ShowItem} .5s ease forwards;

  &:nth-child(3) {
    animation-delay: 2.5s;
  }

  &:nth-child(4) {
    animation-delay: 5s;
  }
`;

interface IProps {
  isOpen: boolean,
}

export const LoadingAgreementModal = ({ isOpen }: IProps) => (
  <StyledModal
    title=''
    isOpen={isOpen}
    disableClose
    isLoading
  >
    <StyledContainer>
      <H3>We are generating the credit agreement for you to sign.</H3>
      <H3>Please refresh if you do not see the contract within 5 mins.</H3>
    </StyledContainer>
    <StyledListContainer>
      <BodyText>What you can expect next:</BodyText>
      <StyledListItem>1. We will review your application and contact your references.</StyledListItem>
      <StyledListItem>2. If we need more information, we will follow up with you.</StyledListItem>
      <StyledListItem>3. Once our review is done, you will receive a final decision email.</StyledListItem>
    </StyledListContainer>
  </StyledModal>
);
