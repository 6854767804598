import React from 'react';

import { get, getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { EmptyState } from '@/components/EmptyState';

import { AutomaticSection } from './AutomaticSection';
import { ManualSection } from './ManualSection';
import { AutomaticAccountOnly } from './AutomaticAccountOnly';

interface IProps {
  customerData?: ICustomer,
  refetch: () => void,
}

export const BanksList = ({ customerData, refetch }: IProps) => {

  const customerId = get('id', customerData);
  const automaticAccounts = getOr([], 'seekerCompany.finicityAccountProjections', customerData);
  const manualBanks = getOr([], 'seekerCompany.manualInstitutions', customerData);
  const manualAccountNotes = getOr([], 'manualAccountNotes', customerData);
  const finicityAccounts = (getOr([], 'seekerCompany.finicityCustomers[0].finicityAccounts', customerData))

  const noData = !automaticAccounts.length
    && !manualBanks.length && !finicityAccounts.length;

  if (!customerId) {
    return <LoadingSpinner />
  }

  if (noData) {
    return <EmptyState>No Banking Information provided yet</EmptyState>
  }

  return (
    <>
      {(!!automaticAccounts.length && (
        <AutomaticSection accounts={automaticAccounts} />
      ))}
      {!automaticAccounts.length && finicityAccounts.map((account: any) => (
        <AutomaticAccountOnly account={account} key={account.id} institutionName={account.institution.name} />
      ))}
      {(!!manualBanks.length && (
        <ManualSection
          customerId={customerId}
          institutions={manualBanks}
          accountNotes={manualAccountNotes}
          refetch={refetch}
        />
      ))}
    </>
  )
};
