import React, { FormEvent, Ref } from 'react';
import { useFormikContext, ErrorMessage } from 'formik';

import { get } from 'lodash/fp';

import { FieldLabel } from './FieldLabel';
import {
  StyledFieldContainer,
  StyledTextInput,
  StyledErrorText,
  StyledDescriptionText,
} from './styled';

type TextFieldType = 'text' | 'email' | 'password' | 'number';

type ErrorActionArgsType = ({
  // We can't know values type for any TextField
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  errorText: string,
})

export interface ITextFieldProps {
  type: TextFieldType,
  name: string,
  label?: string,
  description?: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  ErrorAction?: ({ values, errorText }: ErrorActionArgsType) => JSX.Element | null,
  innerRef?: Ref<HTMLInputElement | null>,
  width?: string,
  autoFocus?: boolean,
}

export const TextField = ({
  required = false,
  disabled = false,
  type,
  name,
  label,
  description,
  placeholder = '',
  ErrorAction,
  innerRef,
  width,
  autoFocus = false,
}: ITextFieldProps) => {

  const { values, errors, touched } = useFormikContext();

  const isTouched = name ? get(name, touched) : false;
  const hasFieldErrors = name ? get(name, errors) : false;
  const showError = isTouched && hasFieldErrors;

  return (
    <StyledFieldContainer width={width}>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <StyledTextInput
        id={name}
        type={type}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        error={showError}
        autoFocus={autoFocus}
        innerRef={innerRef}
      />
      {description && <StyledDescriptionText>{description}</StyledDescriptionText>}
      
      {(showError || ErrorAction) &&
        <StyledErrorText>
          { showError ? <ErrorMessage name={name} /> : false }
          {/* Suggestion to user to do something in case of error */}
          { (showError && ErrorAction) ? <ErrorAction errorText={hasFieldErrors} values={values} /> : false }
        </StyledErrorText>
      }
    </StyledFieldContainer>
  );
};
