import { IVRCustomField } from "@/types/vrCustomField";

export const referenceInitialValues = (customFields: IVRCustomField[]) => {
  const customFieldsValues = customFields.reduce((acc, customField) => ({
    ...acc,
    [customField.name]: customField.type === 'number' ? null : ''
  }), {});

  return {
    customerSince: '',
    creditTerms: '',
    creditLimit: '',
    creditBalancePastDue: '',
    lastPaymentAmount: '',
    lastPaymentOn: '',
    creditBalanceHigh: '',
    averageDaysToPay: '',
    ...customFieldsValues,
    comments: '',
    agreeCheck: true,
  }
};
