import gql from 'graphql-tag';

export const UPDATE_VR_CUSTOM_FIELDS = gql`
  mutation UpdateVRCustomFields($vrCustomFields: [VrCustomFieldInput!]) {
    updateVRCustomFields(vrCustomFields: $vrCustomFields) {
      id,
      enabled,
      required,
    }
  }
`;