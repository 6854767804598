import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

const addressRequiredValidation = Yup.object({
  address_line_1: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  address_line_2: Yup.string().nullable(),
  city: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  state: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  postal_code: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  country: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

export const companyProfileStepValidation = Yup.object().shape({
  name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  otherNames: Yup.string().nullable(),
  type: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  address: addressRequiredValidation,
  website: Yup.string().matches(
    // eslint-disable-next-line max-len
    /^(?!^www\.com$|^www\.org$|^www\.net$|^www\.co$|^www\.io$)(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}(\/)?$|^N\/A$/,
    'Enter correct url'
  ).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  established: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
  description: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED)
})
