import React, { Dispatch, SetStateAction } from 'react';

import { Formik, Form } from 'formik';

import { IManualBank } from '@/types/manualBank';

import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { Button } from '@/components/designSystem/buttons';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';

import { useManualAccountFields } from './useManualAccountFields';
import { StyledButtonsContainer, StyledAutoFitColumnsRow, StyledTwoColumnsRow } from '../../../../styled';

interface IValues {
  institutionName: string,
  institutionAddress: string,
}

interface IProps {
  institution: IManualBank,
  setEditActive: Dispatch<SetStateAction<boolean>>,
}

export const ManualAccountUpdateView = ({ institution, setEditActive }: IProps) => {
  const fields = useManualAccountFields(institution);

  const onSubmit = (values: IValues) => {
    console.log(values)
  };

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={fields} enableReinitialize>
        <Form>
          <StyledTwoColumnsRow>
            <TextField type='text' label='Bank name' name='institutionName' />
            <TextField type='text' label='Bank address' name='institutionAddress' />
          </StyledTwoColumnsRow>
          <StyledAutoFitColumnsRow>
            <TextField type='number' label='Account number' name='accountNumber' />
            <TextField type='number' label='Routing / Transit' name='routingNumber' />
            <SelectField creatable label='Account type' name='accountType' />
            <TextField type='text' label='Currency' name='currency' />
          </StyledAutoFitColumnsRow>
          <StyledAutoFitColumnsRow>
            <TextField type='text' label='Bank Contact name' name='contactName' />
            <TextField type='text' label='Bank Contact email' name='contactEmail' />
            <PhoneNumberField name='contactPhone' label='Bank Contact phone' />
            <TextField type='text' label='Bank Contact title' name='contactTitle' />
          </StyledAutoFitColumnsRow>
          <Row>
            <TextAreaField name='comments' label='Comments' placeholder='Include comments...' />
          </Row>
        </Form>
      </Formik>
      <StyledButtonsContainer>
        <Button onClick={() => setEditActive(false)} secondary>Cancel</Button>
        <Button type='submit' primary onClick={() => setEditActive(false)}>Save</Button>
      </StyledButtonsContainer>
    </>
  );
};
