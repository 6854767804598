import React from 'react';
import { useQueryParams } from '@/utils/history';
import { writeStorage } from '@rehooks/local-storage';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { ONBOARD_USER_INPUT } from '@/graphql/mutations/onboardUser';
import { GET_REFERRAL_DATA } from '@/graphql/queries/getReferralData';

import { FormikValues } from 'formik';

import { get, flow, getOr, set } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { IReferralData } from '@/types/referralData';

import { useAuth0 } from '@/providers/Auth0/Auth0';
import { useRedirect } from '@/providers/Redirect/context';

import { ONBOARDING_PATH } from '@/constants';

import { OnboardContainer } from '@/containers/OnboardContainer';
import { LoadingSpinner } from '@/components/LoadingSpinner';

import { UserInviteStep, userInviteValidation, userInviteFields } from './steps/UserInviteStep';

const steps = [
  {
    title: 'Welcome', path: `${ONBOARDING_PATH.USER}/invite`, Component: UserInviteStep, validation: userInviteValidation,
  },
];

const fields = {
  ...userInviteFields,
};

const applyReferralData = (initialFields: any, referralData: IReferralData) => {
  const {
    toName,
    toEmail,
    fromCompany,
    from,
  } = getOr({}, 'getReferralData', referralData);

  return flow(
    set('name', toName),
    set('email', toEmail),
    set('from', from),
    set('company', fromCompany),
  )(initialFields);
};

export const UserOnboard = () => {
  const { login, signup } = useAuth0();
  const { toDashboard } = useRedirect();

  const query = useQueryParams();
  const referralCode = query.get('ref');

  const { loading, data } = useQuery(GET_REFERRAL_DATA, {
    variables: { referralCode },

    // this is nessesary as the query returns partial data when the customer is not logged in
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
  const [onboardUser] = useMutation(ONBOARD_USER_INPUT);

  if (loading) {
    return <LoadingSpinner />;
  }

  const injectedFields = referralCode ? applyReferralData(fields, data) : fields;

  const signupUser = (formValues: FormikValues) => {
    const { name, email, password } = formValues;
    signup(name, email, password)
      .catch(() => login(email, password))
      .then(() => onboardUser({ variables: { name, referralCode, redirectTo: '/' } })).then((onboardResponse: any) => {
        const companyId = get('data.onboardUser.company.id', onboardResponse);
        writeStorage('company-id', companyId);
        toDashboard();
      });
  };

  return (
    <OnboardContainer
      basePath={ONBOARDING_PATH.USER}
      steps={steps}
      fields={injectedFields}
      onSubmit={(values) => handleSubmitWrapper(values, signupUser)}
    />
  );
};
