import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { API } from '@/app.config';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { openUpgrade } from '@/providers/Upgrade/Upgrade';
import { recordGraphQL } from '@/utils/openreplay';

let client: any = null;

interface IGlobalErrorMessageHandlerProps {
  message: string,
}

const globalErrorMessageHandler = ({ message }: IGlobalErrorMessageHandlerProps) => {
  switch (message) {
  case 'email_not_verified':
    showToast({
      title: 'You need to verify your email first!',
      description: 'Check your inbox for an email with a link to verify your account',
      type: toast.TYPE.ERROR,
    });
    break;

  case 'ref_complete':
    showToast({
      title: 'This invite has already been completed',
      description: 'Someone in your organziation may have already signed up.',
      type: toast.TYPE.ERROR,
    });
    break;

  case 'invalid_status_change':
    showToast({
      title: 'Status change invalid',
      description: 'You cannot change an application to that status',
      type: toast.TYPE.ERROR,
    });
    break;

  case 'upgrade_required':
    openUpgrade();
    break;

  default:
    break;
  }
};

const errorLink = onError(({ graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    console.warn('graphQLErrors', graphQLErrors);

    if (Array.isArray(graphQLErrors)) {
      graphQLErrors.map(globalErrorMessageHandler);
    }
  }
  if (networkError) {
    showToast({
      title: 'Uh Oh!',
      description: "We're sorry, something went wrong, please try again.",
      type: toast.TYPE.ERROR,
    });
    console.warn('networkError', networkError);
  }
});

const authLink = (token: string, companyId: any) => new ApolloLink((operation, forward) => {
  const headers = {
    ...(token && { authorization: `Bearer ${token}` }),
    ...(companyId && { 'company-id': companyId }),
  };

  operation.setContext({ headers });
  return forward(operation);
})

const openReplayLink = (recordGraphQL: any) => new ApolloLink((operation, forward) => forward(operation).map(result => {
  const operationDefinition = operation.query.definitions[0];
  const opKind = operationDefinition.kind === 'OperationDefinition' ? operationDefinition.operation : 'unknown?'
  return recordGraphQL(opKind, operation.operationName, operation.variables, result);
}))

const httpLink = new HttpLink({ uri: `${API.baseUrl}/graphql` });

interface ICreateApolloClientProps {
  token: string,
  companyId?: string,
  recordGraphQL?: any
}

const createApolloClient = ({ token, companyId, recordGraphQL }: ICreateApolloClientProps) => new ApolloClient({
  link: from([
    errorLink,
    ...(recordGraphQL ? [openReplayLink(recordGraphQL)] : []),
    authLink(token, companyId),
    httpLink
  ]),
  cache: new InMemoryCache(),
});

const initApolloClient = (args: any) => {
  client = createApolloClient(args);
  return client;
};

export { initApolloClient, client, createApolloClient };
