import React from 'react';

import { get, getOr, flow, filter } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { PDFDocumentCard } from '@/components/PDFDocumentCard';

import { SignedAgreementsContainer } from './styled';

interface IAgreement {
    id: string,
  }

  interface ISignedAgreementsProps {
    customer: ICustomer,
  }

export  const SignedAgreements = ({ customer }: ISignedAgreementsProps) => {
  const signedAgreements = flow(
    getOr([], 'latestApplication.agreements'),
    filter(get('signedFile.signedUrl') as any),
  )(customer as any);

  return (
    <SignedAgreementsContainer>
      {signedAgreements.map((agreement: IAgreement) => {
        const title = 'Credit Agreement';
        const file = get('signedFile', agreement);
        const dateDescription = 'Date signed';
        return (
          <PDFDocumentCard key={agreement.id} title={title} file={file} dateDescription={dateDescription} />
        );
      })}
    </SignedAgreementsContainer>
  );
};