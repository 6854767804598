import React from 'react';

import { get } from 'lodash/fp';

import { IAutomaticAccountInterface } from '@/types/automaticAccount';

import { AutomaticAccount } from './AutomaticAccount';
import { StyledInstitutionContainer } from '../styled';

interface IProps {
  accounts: IAutomaticAccountInterface[],
}

export const AutomaticBank = ({ accounts }: IProps) => {
  const institutionName = get('[0].lastAccount.institution.name', accounts);

  return (
    <StyledInstitutionContainer>
      {accounts.map((account) => (
        <AutomaticAccount account={account} key={account.id} institutionName={institutionName} />
      ))}
    </StyledInstitutionContainer>
  );
};
