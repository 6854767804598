import styled from 'styled-components';

import { H2, H3, H4 } from '@/components/designSystem/Typography';
import { Callout } from '@/components/Callout/Callout';

import { ReactComponent as CheckIcon } from '@/assets/check-circle.svg';
import { FlexRowItemsCenter } from '@/components/designSystem/Layout';

export const StyledCheckIcon = styled(CheckIcon)`
  margin-top: 0.2rem;
  color: ${({ theme }) => theme.color.data.positive};
`;

export const PaddedH3 = styled(H3)`
  padding-left: 1rem;
`;

interface IProps {
  mt?: string,
}

export const StatsHeader = styled(H4).attrs({ bold: true })<IProps>`
  margin-top: ${({ mt = '2rem' }) => mt};
`;

export const LatestBalanceHeader = styled(H2).attrs({ bold: true })`
  margin-bottom: 1rem;
`;

export const InstitutionTitleContainer = styled(FlexRowItemsCenter)`
  margin-bottom: 1.5rem;
`;

export const InstitutionTitle = styled(H3).attrs({ bold: true })`
  margin-right: 0.5rem;
`;

export const FinicityInstitutionContainer = styled.div`
  & > * {
    margin-bottom: 1rem;
  }
`;

export const NoteContainer = styled(Callout)`
  margin-bottom: 1.5rem;
`;
