import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { DELETE_CUSTOM_FIELD } from '@/graphql/mutations/deleteCustomField';

import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { BodyText } from '@/components/designSystem/Typography';
import { OldConfirmActionModal } from '@/modals/OldConfirmActionModal';

import { ReactComponent as Trash } from '@/assets/trash.svg';

import { StyledDeleteButton } from './styled';

interface IDeleteActionProps {
  id: string,
  refetch: () => void,
  label: string,
}

export const DeleteAction = ({ id, refetch, label }: IDeleteActionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteCustomField, { loading }] = useMutation(DELETE_CUSTOM_FIELD);

  const deleteField = () => {
    deleteCustomField({ variables: { customFieldId: id } })
      .then(() => {
        showToast({
          title: 'Field Deleted',
          description: 'Your custom field has been successfully removed',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        refetch();
      })
      .catch((error) => {
        showToast({
          title: 'Error',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <>
      <StyledDeleteButton onClick={() => setIsOpen(true)}><Trash /></StyledDeleteButton>
      <OldConfirmActionModal
        title={`Delete "${label}" Field`}
        buttonText='Delete'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        action={deleteField}
        loading={loading}
        // onSuccess={() => refetch()}
      >
        <BodyText bold>{`Removing a field has no effect on the answers you have received so far. 
        Customer responses to this field will still be visible on their individual credit applications. 
        However they will be exempt from future agreement documents.`}
        </BodyText>
      </OldConfirmActionModal>
    </>
  );
};
