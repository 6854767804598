import React from 'react';
import { YellowWarningIcon } from '@/components/icons';
import styled from 'styled-components';

import { ReactComponent as WarningIcon } from '@/assets/info.svg';

const StyledYellowWarning = styled.div`
  padding-left: 0.25rem;
  cursor: pointer;
`

const StyledIcon = styled(WarningIcon)`
  width: 25px;
  height: 25px;
  padding: 2px;
  fill: ${({ theme }) => theme.color.primary};
`

interface IProps {
  description: string,
}

export const WarningPopupIcon = ({ description }: IProps) => (
  <StyledYellowWarning
    title={description}
  >
    <StyledIcon />
  </StyledYellowWarning>
)