import React from 'react';

import { FieldArray, useFormikContext } from 'formik';

import { BodyText, H3 } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { Button } from '@/components/designSystem/buttons';
import { MultipleChoiceField } from '@/components/designSystem/Form/MultipleChoiceField';

import { IAddMultipleChoiceCustomFieldValues } from '.';
import {
  StyledPreviewFieldContainer,
  StyledForm,
  StyledFormInnerContainer,
  StyledLeftFormContainer,
  StyledOptionFieldContainer,
  StyledAddOptionButtonWrapper
} from '../styled';
import { FooterContainer } from '../../StyledModal/styled';
import { CustomFieldPreviewContainer } from '../CustomFieldPreviewContainer';
import { AddCustomFieldBackButton } from '../BackButton';

import { ReactComponent as Plus } from '@/assets/plus.svg';
import { ReactComponent as CloseIcon } from '@/assets/x_close.svg';

export const AddMultipleChoiceCustomFieldForm = () => {
  const { values, setFieldValue } = useFormikContext<IAddMultipleChoiceCustomFieldValues>()

  const onRemoveClick = (index: number) => {
    setFieldValue(
      'options',
      values.options.filter((s, i) => i !== index)
    )
  };

  return (
    <StyledForm>
      <StyledFormInnerContainer>
        <StyledLeftFormContainer>
          <AddCustomFieldBackButton />
          <TextField required type='text' name='label' label='Label' />
          <FieldArray
            name='options'
            render={(arrayHelpers) => (
              <>
                {values.options.map((field, index) => (
                  <StyledOptionFieldContainer key={index}>
                    <TextField required type='text' name={`options[${index}]`} label={`Option ${index + 1}`} placeholder={`Option ${index + 1}`} />
                    {(values.options.length > 2) ?  (
                      <CloseIcon
                        onClick={() => onRemoveClick(index)}
                      />) : false
                    }
                  </StyledOptionFieldContainer>
                ))}
                <StyledAddOptionButtonWrapper>
                  <Button secondary LeftIcon={Plus} onClick={() => arrayHelpers.push('')}>Add Additional Option</Button>
                </StyledAddOptionButtonWrapper>
              </>
            )}
          />
          <TextAreaField name='description' label='Description' />
          <CheckboxField name='required'><BodyText>Make this field required</BodyText></CheckboxField>
        </StyledLeftFormContainer>
        <CustomFieldPreviewContainer>
          <H3>Credit Applicant will see:</H3>
          <StyledPreviewFieldContainer>
            <MultipleChoiceField
              name='example'
              options={values.options}
              label={values.label || 'Label Example'}
              description={values.description}
              required={values.required}
            />
          </StyledPreviewFieldContainer>
        </CustomFieldPreviewContainer>
      </StyledFormInnerContainer>
      <FooterContainer>
        <Button wide primary type='submit'>Add Custom Field</Button>
      </FooterContainer>
    </StyledForm>
  )
};
