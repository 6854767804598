import React from 'react';

import { BodyText } from '@/components/designSystem/Typography';

import { Flex } from '@/components/designSystem/Layout';
import { StyledExternalLink } from '../../styled';
import { StyledMultipleResultsCaseContainer, StyledMultipleResultsContainer } from '../styled';
import { IOpenCorporate } from '@/types/openCorporate';
import { formatDateToLongDateString } from '@/utils/date';

interface IProps {
  businessVerificationResults: IOpenCorporate[],
}

export const BusinessVerificationMultipleResultsView = ({ businessVerificationResults }: IProps) => (
  <StyledMultipleResultsContainer>
    {businessVerificationResults.map((businessVerificationResult) => (
      <StyledMultipleResultsCaseContainer key={businessVerificationResult.name}>
        <Flex direction='column'>
          <BodyText bold>{businessVerificationResult.name},</BodyText>
          <BodyText bold>{businessVerificationResult.registeredAddress}</BodyText>
          <BodyText bold>{businessVerificationResult.companyType}, Established {businessVerificationResult.incorporationDate}</BodyText>
        </Flex>
        <Flex direction='column'>
          <BodyText>Status: {businessVerificationResult.currentStatus}</BodyText>
          <BodyText>Source: {businessVerificationResult.publisher}</BodyText>
          <BodyText>URL: <StyledExternalLink
            href={businessVerificationResult.publisherUrl}
            target='_blank'
          >
            {businessVerificationResult.publisherUrl}
          </StyledExternalLink></BodyText>
          <BodyText>Updated At: {formatDateToLongDateString(businessVerificationResult.lastUpdatedDatetime)}</BodyText>
        </Flex>
      </StyledMultipleResultsCaseContainer>
    ))}
  </StyledMultipleResultsContainer>
);
