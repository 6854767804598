import gql from 'graphql-tag';

export const ONBOARD_USER_INPUT = gql`
  mutation OnboardUser(
    $name: String
    $referralCode: String!
    $redirectTo: String!
  ) {
    onboardUser(referralCode: $referralCode, redirectTo: $redirectTo, name: $name) {
      company {
        id
      }
    }
  }
`;
