import React from 'react';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { ConnectBankButton } from '@/components/ConnectBankButton';
import { AddManualInstitutionButton } from '@/modals/AddManualInstitutionModal/AddManualInstitutionButton';

import { ReactComponent as Plus } from '@/assets/plus.svg';

import { FinancialActionsContainer } from './styled';

interface IProps {
  isFinalized: boolean,
  refetch: () => void,
}

export const FinancialActions = ({ isFinalized, refetch }: IProps) => (
  <FinancialActionsContainer>
    {!isFinalized && (
      <ConnectBankButton
        mt='0'
        LeftIcon={Plus}
        onSuccess={() => {
          showToast({
            title: 'Bank Info Successfully Updated',
            description: 'We\'re currently importing your account, check back here shortly and your profile will be updated',
            type: toast.TYPE.SUCCESS,
            onClose: () => refetch(),
          });
          refetch();
        }}
      >
      Automatic Bank Verification
      </ConnectBankButton>
    )}
    {!isFinalized && <AddManualInstitutionButton refetch={refetch} />}
  </FinancialActionsContainer>
);
