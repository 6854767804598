import gql from 'graphql-tag';

export const CREATE_CUSTOM_FIELD = gql`
  mutation CreateCustomField(
    $kind: CustomFieldKind = ONBOARD,
    $customFieldAttrs: CustomFieldInput!
    ) {
      createCustomField(customFieldAttrs: $customFieldAttrs, kind: $kind) {
        id
    }
  }
`;
