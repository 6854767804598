import React from 'react';

import {
  StyledOptionContainer,
  StyledOptionTextContainer,
  StyledOptionTextTitle,
  StyledOptionTextDescription,
} from './styled';

interface IProps {
  title: string,
  description: string,
  handleOptionClick: () => void,
}

export const ListOption = ({ title, description, handleOptionClick }: IProps) => (
  <StyledOptionContainer onClick={handleOptionClick}>
    <StyledOptionTextContainer>
      <StyledOptionTextTitle>{title}</StyledOptionTextTitle>
      <StyledOptionTextDescription>{description}</StyledOptionTextDescription>
    </StyledOptionTextContainer>
  </StyledOptionContainer>
);
