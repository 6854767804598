import React from 'react';
import styled from 'styled-components';
import { FormikProps, FormikValues } from 'formik';

import { isFunction, debounce } from 'lodash/fp';

import { IStepContainerProps } from '@/types/stepContainer';

import { Button, IButtonProps } from '@/components/designSystem/buttons';

const LargeButton = styled(Button)`
  width: 13rem;
`;

interface IProps extends IButtonProps {
    formProps: FormikProps<FormikValues>,
    path: string,
    stepContainerProps: IStepContainerProps,
    onNext: (
      values: FormikValues,
      stepContainerProps: IStepContainerProps,
      formProps: FormikProps<FormikValues>
    ) => void,
    onSubmit: (
      values: FormikValues,
      stepContainerProps: IStepContainerProps,
      formProps: FormikProps<FormikValues>
    ) => void,
}

export const NextButton = (props: IProps) => {
  const {
    path, formProps, stepContainerProps, onNext, onSubmit, ...rest
  } = props;
  const { values } = formProps;

  const pathBasedProps = {
    type: 'button',
    path,
  };

  const submitBasedProps = {
    type: 'button',
    onClick: debounce(200, () => onSubmit(values, stepContainerProps, formProps)),
  };

  const customOnNextBasedProps = {
    type: 'button',
    onClick: debounce(200, () => onNext(values, stepContainerProps, formProps)),
  };

  const buttonProps = isFunction(onNext)
    ? customOnNextBasedProps
    : path
      ? pathBasedProps
      : submitBasedProps;

  return (
    <LargeButton primary {...rest} {...buttonProps}>
      {path ? 'Next' : 'Submit'}
    </LargeButton>
  );
};
