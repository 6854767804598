import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { getValidationForCustomFields } from '@/utils/customFields';

import { ICustomField } from '@/types/customField';

const addressRequiredValidation = Yup.object({
  address_line_1: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  address_line_2: Yup.string().nullable(),
  city: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  state: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  postal_code: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  country: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

const addressValidation = Yup.object({
  address_line_1: Yup.string(),
  address_line_2: Yup.string().nullable(),
  city: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER),
  state: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER),
  postal_code: Yup.string(),
  country: Yup.string(),
});

const serviceLocationValidation = (required: boolean) => {
  if(required) {
    return Yup.object({
      locationName: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
      name: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
      email: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
      position: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
      phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
    }).concat(Yup.object({address: addressRequiredValidation}));
  } else {
    return Yup.object({
      locationName: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER),
      name: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER),
      email: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim(),
      position: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER),
      phoneNumber: Yup.string()
      .test('is-valid-phone', DEFAULT_ERROR_MESSAGES.PHONE, (value) => {
        return !value || Yup.string().phone('US', false).isValidSync(value);
      })
    }).concat(Yup.object({address: addressValidation}));
  }
}

interface ICompanyInfoValidationArgs {
  requireBillingAddress: boolean,
  requireShippingAddress: boolean,
  requireServiceLocations: boolean,
}

export const companyProfileStepValidation = (
  { requireBillingAddress, requireShippingAddress, requireServiceLocations }: ICompanyInfoValidationArgs,
  additionalQuestions: ICustomField[]
) => Yup.object().shape({
  name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  otherNames: Yup.string().nullable(),
  type: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  address: addressRequiredValidation,
  shippingAddress: requireShippingAddress ? addressRequiredValidation : addressValidation,
  billingAddress: requireBillingAddress ? addressRequiredValidation : addressValidation,
  website: Yup.string().matches(
    // eslint-disable-next-line max-len
    /^(?!^www\.com$|^www\.org$|^www\.net$|^www\.co$|^www\.io$)(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}(\/)?$|^n\/a$/,
    'Enter correct url'
  ).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  established: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
  description: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  additionalQuestions: Yup.object().shape(additionalQuestions ? getValidationForCustomFields(additionalQuestions) : {}).nullable(),
  serviceLocations: Yup.array().of(serviceLocationValidation(requireServiceLocations)).min(requireServiceLocations ? 1 : 0)

})
