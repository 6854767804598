import React, { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';

import { USER_ROLES } from '@/constants';

import { TextField } from '@/components/designSystem/Form/TextField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { BodyText } from '@/components/designSystem/Typography';
import { SelectField } from '@/components/designSystem/Form/SelectField';

import { StyledAdminCheckboxWrapper, StyledField } from '../styled';
import { IInviteUserFormValues } from '.';

export const InviteUserModalForm = () => {
  const { values } = useFormikContext<IInviteUserFormValues>()

  const [showCreditLimit, setShowCreditLimit] = useState(true);

  useEffect(() => {
    if (values.roleName === "read_only") {
      setShowCreditLimit(false)
    } else {
      setShowCreditLimit(true)
    }
  }, [values.roleName])

  return (
    <>
      <TextField required type='text' name='name' label='Full Name' placeholder='Full Name' />
      <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
      <SelectField
        required
        name='roleName'
        label='Role'
        options={USER_ROLES}
      />
      {showCreditLimit && <StyledField required type='number' name='creditLimit' label='Credit Limit' placeholder='Credit Limit' />}
      <StyledAdminCheckboxWrapper>
        <CheckboxField name='isAdmin'>
          <BodyText bold>User Admin</BodyText>
        </CheckboxField>
      </StyledAdminCheckboxWrapper>
      <TextAreaField name='customMessage' label='Custom Message' placeholder='Hey! Come and join us on Nectarine Credit.' />
    </>
  );
};
