import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Formik } from 'formik';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_OWN_BRANDING } from '@/graphql/queries/getOwnBranding';
import { UPDATE_BRANDING } from '@/graphql/mutations/updateBranding';

import { get, getOr, noop } from 'lodash/fp';

import { IBaseFile } from '@/types/file';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { H2 } from '@/components/designSystem/Typography';
import { convertFileToFileUpload } from '@/components/designSystem/Form/functions';
import { ColorPickerField } from '@/components/designSystem/Form/ColorPicker/ColorPickerField';
import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';
import { Button } from '@/components/designSystem/buttons';
import { LoadingSpinner } from '@/components/LoadingSpinner';

import { StylePreview } from './StylePreview';
import {
  Container,
  StyledForm,
  Column,
  ButtonContainer,
} from './styled';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

interface IFile extends IBaseFile {
  path: string,
}

interface IFileToUpload {
  fileId: string,
  name: string,
  signedUrl: string,
}

interface IValues {
  logoFile: IFileToUpload[] | null,
  brandColor: string,
  primaryColor: string,
  secondaryColor: string,
  backgroundColor: string,
}

export const CreditApplicationDesignAndBrand = () => {

  const [updateBrand] = useMutation(UPDATE_BRANDING);
  const themeContext = useContext(ThemeContext);
  const currentCompanyData = useContext(CurrentCompanyContext);

  if (!currentCompanyData) {
    return <LoadingSpinner />
  }

  const genericSeekerOnboardUrl = get('currentCompany.genericSeekerOnboardUrl', currentCompanyData);
  const logoFileRaw: IFile = get('currentCompany.brand.logo', currentCompanyData);
  const brandColor = getOr(themeContext.color.brand, 'currentCompany.brand.brandColor', currentCompanyData);
  const primaryColor = getOr(themeContext.color.primary, 'currentCompany.brand.primaryColor', currentCompanyData);
  const secondaryColor = getOr(themeContext.color.secondary, 'currentCompany.brand.secondaryColor', currentCompanyData);
  const backgroundColor = getOr(themeContext.color.site.background, 'currentCompany.brand.backgroundColor', currentCompanyData);

  const refetchCurrentCompany = get('refetchCurrentCompany', currentCompanyData);

  const logo = logoFileRaw
    ? [convertFileToFileUpload(logoFileRaw)]
    : null;

  const fields = {
    logoFile: logo,
    brandColor,
    primaryColor,
    secondaryColor,
    backgroundColor,
  };

  const saveBrand = (values: IValues) => {
    const {
      logoFile,
      brandColor: brand,
      primaryColor: primary,
      secondaryColor: secondary,
      backgroundColor: background,
    } = values;

    const logoId = get('[0].fileId', logoFile);

    const variables = {
      brandAttrs: {
        logoId,
        colors: {
          brandColor: brand,
          primaryColor: primary,
          secondaryColor: secondary,
          backgroundColor: background,
        },
      },
    };

    updateBrand({ variables })
      .then(() => {
        showToast({
          title: 'Saved',
          description: 'Your brand settings have been successfully updated',
          type: toast.TYPE.SUCCESS,
        });
        refetchCurrentCompany();
      });
  };

  return (
    <Container>
      <Formik onSubmit={noop} initialValues={fields} enableReinitialize>
        {
          ({ values }) => (
            <StyledForm>
              <Column>
                <H2>Assets</H2>
                <FileUploadField name='logoFile' label='Logo' prompt='Upload Your Logo' onlyOne />
                <H2>Colors</H2>
                <ColorPickerField required name='brandColor' label='Brand' />
                <ColorPickerField required name='primaryColor' label='Primary' />
                <ColorPickerField required name='secondaryColor' label='Secondary' />
                <ColorPickerField required name='backgroundColor' label='Background' />
                <ButtonContainer>
                  <Button primary wide onClick={() => saveBrand(values)}>Save</Button>
                  <Button secondary wide onClick={() => window.open(genericSeekerOnboardUrl, '_blank')?.focus()}>
                    Preview Credit Application
                  </Button>
                </ButtonContainer>
              </Column>
              <Column>
                <StylePreview {...values} />
              </Column>
            </StyledForm>
          )
        }
      </Formik>
    </Container>
  );
};
