import React from 'react';

import { H4, BodyText } from '@/components/designSystem/Typography';

import { IToastBodyProps } from './types';
import { ToastBodyContainer } from './container';

export const ToastBody = ({ title, description }: IToastBodyProps) => (
  <ToastBodyContainer>
    <H4 bold>{title}</H4>
    <BodyText>{description}</BodyText>
  </ToastBodyContainer>
);
