import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { StyledModal } from '@/modals/StyledModal';
import { H4 } from '@/components/designSystem/Typography';

const StyledContainer = styled.div`
  padding: 2.5rem;
  padding-top: 0;
`;

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
}

export const UserOptOutModal = ({
  isOpen, setIsOpen,
}: IProps) => (
  <>
    <StyledModal
      title='Thank you.'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      disableClose
      blockClose
    >
      <StyledContainer>
        <H4>You will no longer receive vendor reference emails from Nectarine Credit moving forward.</H4>
      </StyledContainer>
    </StyledModal>
  </>
);
