import React, { ChangeEvent } from 'react';

import { StyledDescriptionText, StyledErrorText, StyledFieldContainer, StyledMultipleChoiceFieldCheckboxesContainer } from './styled';
import { FieldLabel } from './FieldLabel';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import { get } from 'lodash/fp';
import { Checkbox } from './Checkbox';

interface IProps {
  name: string,
  label?: string,
  description?: string,
  required?: boolean,
  options?: string[] | undefined,
  selections?: string[]
}

export const MultipleChoiceField = ({ name, label, description, options, required }: IProps) => {
  const [field, meta, helpers] = useField(name);

  const showError = !!(meta.touched && meta.error);

  const { setValue } = helpers;

  const selections: string[] = field.value.selections;

  const uncheckOption = (value: string) => {
    setValue({ selections: selections.filter((selection) => selection !== value) });
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, option: string) => {
    if (e.target.checked) {
      setValue({ selections: [...field.value.selections, option] })
    } else {
      uncheckOption(option)
    }
  }

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <StyledMultipleChoiceFieldCheckboxesContainer>
        {options?.map((option, index) => (
          <Checkbox
            key={index}
            checked={!!selections?.find((selected: string) => option === selected)}
            onChange={(e) => handleChange(e, option)}
            isError={showError}
          >
            {option}
          </Checkbox>
        ))}
      </StyledMultipleChoiceFieldCheckboxesContainer>
      {description ? <StyledDescriptionText>{description}</StyledDescriptionText> : false}
      <StyledErrorText>
        { showError ? <ErrorMessage name={`${name}.selections`} /> : false }
      </StyledErrorText>
    </StyledFieldContainer >
  )
}