import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';

import { ISettings } from '@/types/settings';

import { Callout } from '@/components/Callout/Callout';
import { BodyText } from '@/components/designSystem/Typography';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { PhoneNumberInput } from '@/components/designSystem/Form/PhoneNumberInput';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';
import { AdditionalPersonalInformation } from './AdditionalPersonalInformation';
import { Section } from '../Section';
import { EnableRequiredOptions } from '@/components/EnableRequiredOptions';

interface IProps {
  initialSettings: ISettings,
  refetch: () => void,
}

export const OwnerSection = ({ initialSettings, refetch }: IProps) => {
  const enableOwner:boolean= getOr(true, 'enableOwner', initialSettings);
  const requireOwner:boolean = getOr(true, 'requireOwner', initialSettings);
  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);

  const handleOwnershipOptionChange = (state: any) => {
    updateCompanySettings({variables: {settings: state}})
    .then(refetch)
    .then(() => {
      showToast({
        title: 'Success!',
        description: 'Company settings Updated Successfully.',
        type: toast.TYPE.SUCCESS,
      });
    })
  }

  return (
    <Section
      title='Owners'
      description='If enabled, Customers will be able to enter the majority owners of the company. If both enabled and required, Customers must enter the majority owners of the company'
      actions={
        <Row>
          <EnableRequiredOptions
            id='ownership'
            label=''
            keys={{enabled: 'enable_owner', required: 'require_owner'}}
            enabled={enableOwner}
            required={requireOwner}
            handleChange={handleOwnershipOptionChange}
          />
        </Row>
      }>
      {enableOwner &&
        <>
          <Row>
            {/* All these TextField names are fake. We need for formik fields.
                If you need to enable these text fields at some point, you will need to fix names */}
            <TextField type='text' disabled name='owner.name' label='Full Name' placeholder='John Doe' />
            <TextField type='text' disabled name='owner.title' label='Title' placeholder='CFO' />
          </Row>
          <Row>
            <TextField type='number' disabled name='owner.stake' label='Ownership Stake' placeholder='XX %' />
            <PhoneNumberInput disabled label='Phone Number' placeholder='Phone number' />
            <TextField type='email' disabled name='owner.email' label='Email Address' placeholder='John@streamlink.com' />
          </Row>
          <AdditionalPersonalInformation initialSettings={initialSettings} refetch={refetch} />
          <Callout>
            <BodyText>Customers are able to add multiple owners, when filling out this section.</BodyText>
          </Callout>
        </>
      }
    </Section>
  )
};
