import React, { useEffect } from 'react';
import { deleteFromStorage } from '@rehooks/local-storage';

import { useQueryParams } from '@/utils/history';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { ONBOARDING_PATH } from '@/constants';

import { useQuery } from '@apollo/react-hooks';
import { GET_REFERRAL_DATA } from '@/graphql/queries/getReferralData';
import { VendorOnboardContainer } from './container';
// import { VendorOptOut } from './VendorOptOut';

export const VendorOnboard = () => {

  // Get Referral Data
  const query = useQueryParams();
  const referralCode = query.get('ref') || '';
  const optOut = query.get('optOut');

  useEffect(() => {
    deleteFromStorage('token');
    deleteFromStorage('company-id');
    deleteFromStorage('customer-id');
  }, []);

  const { loading: refDataLoading, data: refData } = useQuery(GET_REFERRAL_DATA, {
    variables: { referralCode },

    // this is nessesary as the query returns partial refdata when the customer is not logged in
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  if (!refData || refDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <VendorOnboardContainer referralCode={referralCode} refData={refData} basePath={ONBOARDING_PATH.VENDOR} optOut={optOut} />
  )

  // if (!optOut) {
  //   return (
  //     <VendorOnboardContainer referralCode={referralCode} refData={refData} basePath={ONBOARDING_PATH.VENDOR} />
  //   )
  // } else {
  //   return (
  //     <VendorOptOut referralCode={referralCode} refData={refData} />
  //   )
  // }
};
