import React from 'react';

import styled from 'styled-components';

import { ReactComponent as LogoFull } from '@/assets/logo_full.svg';
import { ReactComponent as LogoMark } from '@/assets/logo_mark.svg';

import device from '@/styles/device';

const TabletLogoContainer = styled.div`
  display: none;
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1.5rem;

  @media ${device.laptop} {
    display: block;
  };

  @media ${device.mobileXL} {
    display: none;
  };
`;

const MobileLogoContainer = styled.div`
  display: none;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: 0.6rem;
  left: 1rem;
  z-index: 3;

  @media ${device.mobileXL} {
    display: block;
  };
`;

const DesktopLogo = styled(LogoFull)`
  width: 125.53px;
  height: 44px;
  margin-left: 1.5rem;
  
  @media ${device.laptop} {
    display: none;
  };
`;

const MobileLogo = styled(LogoMark)`
  display: none;
  width: 100%;
  height: 100%;

  @media ${device.laptop} {
    display: flex;
  };
`;

export const Logo = () => (
  <>
    <DesktopLogo />
    <TabletLogoContainer>
      <MobileLogo />
    </TabletLogoContainer>
    <MobileLogoContainer>
      <MobileLogo />
    </MobileLogoContainer>
  </>
);
