import styled from 'styled-components';

import { Button } from '@/components/designSystem/buttons';
import { GrayBox } from '@/components/designSystem/GrayBox';

import device from '@/styles/device';
import { ReactComponent as Horizontaldots } from '@/assets/horizontaldots.svg';
import { ReactComponent as LeftChevron } from '@/assets/chevron_left.svg';
import CreditSafeLogo from '@/assets/CreditSafeLogo.png';
import ExperianLogo from '@/assets/ExperianLogo.png';
import EquifaxLogo from '@/assets/EquifaxLogo.png';
import DnBLogo from '@/assets/DnBLogo.png';
import { FlexColumn, FlexColumnItemsCenter, FlexColumnItemsStart, FlexColumnSpaceBetween, FlexRow, FlexRowItemsCenter, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';

interface ILogoProps {
  isLoading?: boolean,
}

export const StyledHorizontaldots = styled(Horizontaldots)`
  height: 35px;
  cursor: pointer;  
`;

export const StyledCSLogo = styled.img.attrs({ src: CreditSafeLogo })<ILogoProps>`
  height: 35px;
  cursor: pointer;  
`;

export const StyledEquifaxLogo = styled.img.attrs({ src: EquifaxLogo })<ILogoProps>`
  height: 35px;
  cursor: pointer;  
`;

export const StyledDnBLogo = styled.img.attrs({ src: DnBLogo })<ILogoProps>`
  height: 35px;
  cursor: pointer;  
`;

export const StyledExperianLogo = styled.img.attrs({ src: ExperianLogo })<ILogoProps>`
  height: 35px;
  cursor: pointer;
`;

export const RightChevron = styled(LeftChevron)`
  margin-right: 0.5rem;
  transform: rotate(180deg);
`;

export const UpChevron = styled(LeftChevron)`
  margin-left: 0.5rem;
  transform: rotate(90deg);
  cursor: pointer;
`;

export const DownChevron = styled(LeftChevron)`
  margin-left: 0.5rem;
  transform: rotate(270deg);
  cursor: pointer;
`;

export const StyledBureauButton = styled(Button)`
  margin-top: .5rem;
  padding: .5rem;
  width: 12rem;
`;

export const SearchStyledButton = styled(Button)`
  margin-top: 1rem;
`;

export const SearchResultItemContainer = styled(FlexRowSpaceBetweenItemsCenter)`
  width: 100%;
  margin: 0.5rem 1rem;
  padding: 0 1rem 1rem 1rem;
  border-bottom: 1px solid #ccc;
  opacity: 0.75;

  :hover {
    opacity: 1;
    cursor: pointer;
  }

  & > * {
    margin-right: 1.5rem;
  }
`;

export const Row = styled(FlexRowItemsCenter)`
  & > * {
    margin-right: 1rem;
  }
`;

export const SearchResultContent = styled(FlexColumnItemsStart)`
  & > * {
    margin-right: 0.5rem;
  }
`;

export const ReportTypeButtons = styled(FlexRowItemsCenter)`
  & > * {
    margin-right: 1rem;
  }
`;

export const RequestSection = styled(FlexColumnSpaceBetween)`
  margin-top: 1rem;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const PreviousReportDisplayItems = styled(FlexColumn)`
  margin-top: 1rem;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const CreditRequestPill = styled(FlexColumnItemsCenter)`
  & > *:not(:last-child) {
    margin-bottom: .25rem;
  }
`;

export const CreditRequestPillContainer = styled(GrayBox)`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  // padding-top: 1rem;
  // padding-bottom: 1rem;
  padding: 1rem;
  gap: 1rem;

  // & > * {
  //   margin-left: 1rem;
  // }

  @media ${device.laptopL} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
    // flex-direction: column;
    // justify-content: space-evenly;
    // align-items: center;
    // padding: 0.5rem;

    // & > * {
    //   margin-bottom: 1.5rem;
    //   margin-left: 1.5rem;
    // }

    // & > :last-child {
    //   margin-bottom: 0;
    // }
  };
`;

export const StyledCreditReportActionsOptionIcon = styled(FlexRowItemsCenter)`
  cursor: pointer;
`;

export const StyledCreditReportActionsContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.color.white};
  border: ${(props) => props.theme.color.border.light};
  border-radius: 6px;
`;

export const StyledCreditReportActionsOption = styled(FlexRowItemsCenter)`
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.greyLight};
  }
`;
