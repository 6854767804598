import React from 'react';

import Select from 'react-select';
import { InitialAvatar } from '@/components/InitialAvatar';
import { HiMiniCheck } from "react-icons/hi2";
import { useMutation } from '@apollo/react-hooks';
import { PROVIDER_ASSIGNMENT } from '@/graphql/mutations/providerAssignment';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';


import {
  StyledSelectOption,
  StyledSelectedOption,
  StyledSelectedOptionLabel
} from '../styled';

interface AssigneeProps {
  index: number,
  name: string,
  id: string,
}

interface IProps {
  data: AssigneeProps[],
  assignedTo: string,
  applicationId?: string,
  refetchCustomerData: () => void,
}

const CustomSingleValue = (props: any) => {
  return (
    <StyledSelectedOption>
      <InitialAvatar name={props.data.name} />
      <StyledSelectedOptionLabel>{props.data.name}</StyledSelectedOptionLabel>
    </StyledSelectedOption>
  );
};

const CustomOption = (props: any) => {
  return (
    <StyledSelectOption
      {...props.innerProps}
      focused={props.isFocused}
      selected={props.isSelected}
    >
      <div>
        <InitialAvatar name={props.data.name} />
        <p>{props.data.name}</p>
      </div>
      {props.isSelected && <HiMiniCheck />}
    </StyledSelectOption>
  );
};

export const AssigneeForm = ({ data, assignedTo, applicationId, refetchCustomerData}: IProps) => {
  const selectedOption = data.find((user) => user.id === assignedTo);
  const [updateApplication] = useMutation(PROVIDER_ASSIGNMENT);

  const handleChange = (selectedAssignee: any) => {
    if (selectedOption?.id == selectedAssignee.id)
      return;
    const variables = {
      applicationId: applicationId,
      assignedTo: selectedAssignee.id,
      assignmentNote: null,
    };

    updateApplication({ variables })
      .then(() => {
        showToast({
          title: 'Success!',
          description: 'Application assigned to ' + selectedAssignee.name,
          type: toast.TYPE.SUCCESS,
        });
        refetchCustomerData();
      })
      .catch((error) => {
        showToast({
          title: 'Error!',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
      <Select
        styles={{
          control: (baseStyles: any) => ({
            ...baseStyles,
            width: '12rem',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '0.5rem',
            "&:hover": {
              backgroundColor: "#f3f4f6",
            },
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          menu: (provided: any) => ({ ...provided, width: '13rem' })
        }}
        options={data}
        value={selectedOption}
        onChange={handleChange}
        getOptionLabel={(option: any) => option.name}
        getOptionValue={(option: any) => option.id}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        placeholder={`No Assignee`}
      ></Select>
  )
}