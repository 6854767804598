import { Dispatch, SetStateAction } from 'react';

import Yup, { allowedUserEmail, DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { FetchResult, useMutation } from '@apollo/react-hooks';
import { INVITE_CUSTOMER } from '@/graphql/mutations/inviteCustomer';

import { getOr } from 'lodash/fp';

import { ISettings } from '@/types/settings';
import { ICompanyUser } from '@/types/companyUser';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

interface IFields {
  customerId: string,
  companyName: string,
  name: string,
  email: string,
  phoneNumber: string,
  autoBav: boolean,
  enablePersonalGuarantee: boolean,
  requirePersonalGuarantee: boolean,
  requireBankInfo: boolean,
  customMessage: string,
}

export const useForm = (
  currentUser: ICompanyUser,
  initialSettings: ISettings,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
) => {
  const [inviteCustomer] = useMutation(INVITE_CUSTOMER);

  const customerValidation = Yup.object().shape({
    companyName: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    email: allowedUserEmail(currentUser.email),
    phoneNumber: Yup.string()
      .required(DEFAULT_ERROR_MESSAGES.REQUIRED)
      .test('is-valid-phone', DEFAULT_ERROR_MESSAGES.PHONE, (value) => {
        return !value || Yup.string().phone('US', false).isValidSync(value);
      }),
    customMessage: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  });

  const customerFields = {
    customerId: '',
    company: '',
    name: '',
    email: '',
    phoneNumber: '',
    autoBav: getOr(false, 'autoBavDefault', initialSettings),
    enablePersonalGuarantee: getOr(false, 'enablePersonalGuaranteeDefault', initialSettings),
    requirePersonalGuarantee: getOr(false, 'requirePersonalGuaranteeDefault', initialSettings),
    requireBankInfo: getOr(true, 'requireBankInfo', initialSettings),
    customMessage: '',
  };

  const handleSubmit = async ({
    companyName, name, email, phoneNumber, autoBav, enablePersonalGuarantee, requirePersonalGuarantee, requireBankInfo, customMessage, customerId
  }: IFields) => {
    const variables = {
      companyName,
      name,
      email,
      phoneNumber,
      autoBav,
      enablePersonalGuarantee,
      requirePersonalGuarantee,
      requireBankInfo,
      customerId,
      customMessage,
    };

    await inviteCustomer({ variables })
      .then((response) => {
        setIsOpen(false);
        showToast({
          title: 'Customer Invited',
          description: `Sent Invite to ${name} from ${companyName}`,
          type: toast.TYPE.SUCCESS,
        });
        onSuccess && onSuccess(response);
      })
      .catch(() => null);
  };

  return {
    customerValidation,
    customerFields,
    handleSubmit,
  }
}