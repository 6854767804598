import React from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { H3, BodyText } from '@/components/designSystem/Typography';
import { Callout } from '@/components/Callout/Callout';

import { CommentDisclaimer } from './styled';
import { includes } from 'lodash';

interface IApplicationCommentSectionProps {
    customer: ICustomer,
}

export const ApplicationCommentSection = ({ customer }: IApplicationCommentSectionProps) => {
  const providerName = get('providerCompany.name', customer);
  const status = get('latestApplication.status', customer);
  const providerComment = get('latestApplication.providerComment', customer);

  const show = includes(['changes_requested', 'approved', 'denied'], status) && providerComment;
  const showDisclaimer = status === 'changes_requested' && providerComment;

  return show && (
    <Callout color='label.warning'>
      <H3 bold>Message from {providerName}</H3>
      <BodyText>{providerComment}</BodyText>
      {showDisclaimer && (<CommentDisclaimer color='secondary'>Please make the requested changes and resubmit the Application using the button below.</CommentDisclaimer>)}
    </Callout>
  );
};
