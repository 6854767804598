import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';
import TextareaAutosize from 'react-textarea-autosize';
import * as Formik from 'formik';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Small, Caption } from '@/components/designSystem/Typography';

import device from '@/styles/device';
import { Flex, FlexColumn, FlexColumnEnd, FlexRow, FlexRowItemsCenter} from '../Layout';

export const Direction = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;

  @media ${device.tablet} {
    flex-direction: column;
  };
`;

export const Row = styled(Direction)`
  flex-direction: row;
`;

export const Column = styled(Direction)`
  flex-direction: column;
`;

export const FieldStyle = css<any>`
  padding: 1rem;
  border: ${({ theme, error }) => (error
    ? `2px solid ${theme.color.negative}`
    : theme.color.border.light)};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.color.shadow.shadowSmall};
  border-radius: 6px;
  height: 3rem;

  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

export const StyledField = styled(Formik.Field)`${FieldStyle}`;

export const FieldContainer = styled(FlexColumnEnd)`
  margin-bottom: 0.3rem;
  width: 100%;

  & > textarea {
    height: 7.5rem;
  }

  & > input:disabled {
    background-color: ${({ theme }) => theme.color.greyLight};
  }
`;

export const ErrorText = styled(Small)`
  color: ${({ theme }) => theme.color.negative};
  margin: 0.25rem;
  height: 1rem;
`;

// layout

export const StyledMultiRow = styled(Flex)`
  width: 100%;
  gap: 1rem;
  flex-direction: row;

  @media ${device.laptopL} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    gap: 0;
    flex-direction: column;
  }

  @media ${device.mobileXL} {
    gap: 0;
    flex-direction: column;
  }
`;

export const StyledFormRow = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 1rem;
  grid-template-columns: repeat( auto-fit, minmax(10rem, 1fr) );
  // margin-bottom: 1rem;

  @media ${device.mobileXL} {
    grid-gap: 0;
  }
`;

// common

interface IStyledFieldContainer {
  width?: string,
}

export const StyledFieldContainer = styled(FlexColumn)<IStyledFieldContainer>`
  margin-bottom: 1rem;
  width: ${({ width }) => width || '100%'};
`;

export const StyledErrorText = styled(Small)`
  color: ${({ theme }) => theme.color.negative};
  margin-top: 0.25rem;
  // height: 1rem;
`;

interface IStyledWithError {
  error?: boolean,
}

export const SharedFieldStyle = css<IStyledWithError>`
  padding: 1rem;
  border: ${({ theme, error }) => (error
    ? `2px solid ${theme.color.negative}`
    : theme.color.border.light)};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.color.shadow.shadowSmall};
  border-radius: 6px;
  height: 2.7rem;

  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

export const StyledDescriptionText = styled(Caption).attrs({ color: 'secondary' })`
  margin: 0.75rem 0.25rem 0rem 0.25rem;
`;

export const StyledNoteText = styled(Caption).attrs({ color: 'secondary' })`
  margin: .6rem 0.75rem 0.0rem -0.5rem;
`;

// text

export const StyledTextInput = styled(Formik.Field)<IStyledWithError>`
  ${SharedFieldStyle}
`;

export const StyledControlledTextInput = styled.input.attrs({ type: 'text' })`
  ${SharedFieldStyle}
`;

// phone

export const StyledPhoneInput = styled.input.attrs({ type: 'phone' })`
  ${SharedFieldStyle}
`;

// checkbox

export const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })<IStyledWithError>`
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  box-shadow: ${({ theme, error }) => (error
    ? `0px 0px 0px 1px ${theme.color.negative}`
    : theme.color.border.light)};
`;

// select

export const SharedSelectStyle = css<IStyledWithError>`
& .Select__value-container, .Select__indicator, .Select__indicator-separator {
  padding: 0;
  margin: 0;
}

& .Select__indicator-separator {
  display: none;
}

& .Select__control {
  ${SharedFieldStyle}
  padding: 0.3rem 1rem;
}

& .Select__control--is-disabled {
  background-color: ${({ theme }) => theme.color.greyLight};
}

& .Select__single-value--is-disabled {
  color: ${({ theme }) => theme.color.greySkeleton}
}

& .Select__indicator Select__dropdown-indicator {
  border-color: transparent transparent red;
}
`;

export const StyledSelect = styled(Select)`${SharedSelectStyle}`;

export const StyledCreatableSelect = styled(CreatableSelect)`${SharedSelectStyle}`;

// textarea

export const StyledTextArea = styled(TextareaAutosize)`
  ${SharedFieldStyle}
  width: 100%;
`;

// datepicker

export const StyledDateInput = styled.input<IStyledWithError>`
  ${SharedFieldStyle}
  width: 100%;
`;

// currency

export const StyledNumberFormatInput = styled(NumberFormat as any)`${SharedFieldStyle}`;

// radio

export const StyledRadioButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem;
  position: relative;
`;

export const StyledRadioButtonInput = styled.input.attrs({ type: 'radio' })<IStyledWithError>`
  position: absolute;
  opacity: 0;

  &:checked {
    + label {
      &:after {
        background-color: ${({ theme }) => (theme.color.primary)};
        box-shadow: inset 0 0 0 4px ${({ theme }) => (theme.color.primary)};
      }
    }
  }

  &:focus {
    + label {
      &:before {
        outline: none;
        border-color: ${({ theme }) => (theme.color.primary)};
      }
    }
  }
`;

export const StyledRadioButtonText = styled.label<IStyledWithError>`
  position: relative;

  &:before {
    content: '';
    border-radius: 100%;
    border: ${({ theme, error }) => (error
    ? `2px solid ${theme.color.negative}`
    : theme.color.border.dark)};
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    margin-right: 1rem; 
    vertical-align: top;
    cursor: pointer;
    padding: 0.2rem;
  }

  &:after {
    content: '';
    border-radius: 100%;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    cursor: pointer;
    transition: all 250ms ease;
  }
`;

// color

export const StyledColorPickerContainer = styled.div`
  position: relative;
`;

export const StyledColorPickerOverlapContainer = styled(FlexRow)`
  position: absolute;
  margin: 0.5rem;
  z-index: 2;
`;

export const StyledColorButton = styled.button`
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  border: ${(props) => props.theme.color.border.light};
  border-radius: 2px;
  background-color: ${({ color }) => color};
  z-index: 1;
`;

export const StyledColorPickerInputContainer = styled(FlexRowItemsCenter)`
  ${FieldStyle}

  width: 100%;

  &:focus-within {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

export const StyledColorPickerTextInput = styled.input`
  width: 100%;

  &:focus {
    outline: none;
    border: none;
  }
`;

// yes / no toggle

export const StyledYesNoToggleFieldWrapper = styled.div`
  margin-top: 0.5rem;
`;

// multiple choice

export const StyledMultipleChoiceFieldCheckboxesContainer = styled(FlexColumn)`
  gap: 1rem;
`;