import styled from 'styled-components';

export const StyledMessageWrapper = styled.div`
  height: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 2.5rem;
  & > * + * {
    margin-top: 1rem;
  }
`;

export const StyledButtonContainer = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 36rem;
  border-top: 1px solid #D1D5DB;
`;

export const StyledGridContainer = styled.div`
  margin-top: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`
interface GridColumnProps {
  column?: number;
}

export const StyledGridColumn = styled.div<GridColumnProps>`
  ${({ column }) => column && `grid-column: span ${column} / span ${column};`}
`

export const StyledReviewDatePickerContainer = styled.div`
  flex: 1 1 0%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  div {
    margin-bottom: 0rem;
  }
  
  svg {
    color: red;
    cursor: pointer;
  }
`

export const StyledTopBorder = styled.div`
  margin: 0.25rem 0rem;
  border-top: 1px solid lightgrey;
`
