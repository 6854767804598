import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { DELETE_CUSTOMER_INVITE } from '@/graphql/mutations/deleteCustomerInvite';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BodyText } from '@/components/designSystem/Typography';
import { OldConfirmActionModal } from '@/modals/OldConfirmActionModal';

import { ReactComponent as Trash } from '@/assets/trash_gray.svg';

import { DeleteIcon } from './styled';

interface IProps {
    referralId?: string,
}

export const DeleteCustomerInvite = ({ referralId }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useHistory();
  const [deleteCustomerInviteMutation] = useMutation(DELETE_CUSTOMER_INVITE);

  const deleteCustomerInvite = () => {
    deleteCustomerInviteMutation({ variables: { referralId } })
      .then(() => {
        showToast({
          title: 'Customer Invite Deleted',
          description: 'Your customer invite has been successfully removed',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        navigate.push('/dashboard/customers');
      });
  };

  return (
    <>
      <DeleteIcon onClick={() => setIsOpen(true)}><Trash width='24' height='24' /></DeleteIcon>
      <OldConfirmActionModal
        title='Delete Customer Invite'
        buttonText='Delete'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        action={deleteCustomerInvite}
      >
        <BodyText bold>Are you sure you want to delete this Customer Invite?</BodyText>
      </OldConfirmActionModal>
    </>
  );
};
