import React from 'react';

import { get } from 'lodash/fp';

import { ICurrentCompany } from '@/types/currentCompany';

import { BorderedContainer } from '@/containers/BorderedContainer';

import { H4 } from '@/components/designSystem/Typography';
import { LabeledComponent } from '@/components/LabeledComponent';
import { Flex } from '@/components/designSystem/Layout';

import { StyledEquifaxLogo, StyledPageTitleContainer, StyledRow } from './styled';
import { INTEGRATIONS } from './constants';
import { EditCredsButton } from './EditCredsButton';

interface IProps {
  refetch: () => void,
  currentCompany: ICurrentCompany,
}

export const EquifaxSettings = ({ currentCompany, refetch }: IProps) => {
  const equifaxCustomerCode = get('settings.equifaxCustomerCode', currentCompany);
  const equifaxCustomerNumber = get('settings.equifaxCustomerNumber', currentCompany);
  const equifaxClientId = get('settings.equifaxClientId', currentCompany);

  return (
    <>
      <StyledPageTitleContainer>
        <StyledEquifaxLogo />
        <EditCredsButton type={INTEGRATIONS.EQUIFAX} refetch={refetch} username={equifaxCustomerNumber} />
      </StyledPageTitleContainer>
      <BorderedContainer>
        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Equifax Customer Code'>
            <StyledRow>
              <H4 bold>{equifaxCustomerCode || 'None'}</H4>
            </StyledRow>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Equifax Customer Number'>
            <H4 bold>{equifaxCustomerNumber || 'None'}</H4>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Equifax Security Code'>
            <H4 bold>{equifaxCustomerCode ? '**********' : 'None'}</H4>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Equifax Client ID'>
            <StyledRow>
              <H4 bold>{equifaxClientId ? '**********' : 'None'}</H4>
            </StyledRow>
          </LabeledComponent>
        </Flex>

        <Flex justify='space-between' align='center'>
          <LabeledComponent label='Equifax Client Secret'>
            <H4 bold>{equifaxClientId ? '**********' : 'None'}</H4>
          </LabeledComponent>
        </Flex>
      </BorderedContainer>
    </>
  );
};