import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  ResponsiveContainer,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Bar,
  ComposedChart,
  Legend,
} from 'recharts';

import { get } from 'lodash/fp';
import { formatDateToMonthYearString } from '@/utils/date';
import { formatCurrency } from '@/utils/money';
import { orderByDate } from '@/utils/date';

import { ICashflow } from '@/types/automaticAccount';

interface IProps {
  cashflowHistory: ICashflow[],
  height: number,
}

export const CashflowChart = ({
  cashflowHistory,
  height,
}: IProps) => {
  const theme = useContext(ThemeContext);
  const positiveColor = get('color.data.positive', theme);
  const negativeColor = get('color.data.negative', theme);
  const avgLineColor = get('color.black', theme);

  return (
    <ResponsiveContainer width='100%' height={height}>
      <ComposedChart
        data={orderByDate(cashflowHistory, 'startDate')}
        stackOffset='sign'
        margin={{
          top: 2, right: 0, left: 25, bottom: 2,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='startDate' tickFormatter={formatDateToMonthYearString} interval='preserveStartEnd' />
        <YAxis tickFormatter={(value: number) => formatCurrency(value, false)} allowDecimals={false} />
        <Tooltip
          labelFormatter={(value) => typeof value === 'string' ? formatDateToMonthYearString(value) : value}
          formatter={(value) => typeof value === 'number' ? formatCurrency(value) : value}
        />
        <Legend />
        <ReferenceLine y={0} stroke='#000' />
        <Bar dataKey='inflow' name='Inflow' fill={positiveColor} stackId='stack' />
        <Bar dataKey='outflow' name='Outflow' fill={negativeColor} stackId='stack' />
        <Line type='monotone' name='Average Transaction Amount' dataKey='averageTransactionAmount' stroke={avgLineColor} strokeWidth={2} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
