import React from 'react';

import { FlexRowEnd } from '@/components/designSystem/Layout';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledFormRow } from '@/components/designSystem/Form/styled';

import { ReactComponent as CloseIcon } from '@/assets/x_close.svg';

import {
  StyledCloseIconWrapper,
  StyledContactContainer,
  StyledAdditionalContactPhoneNumberExtensionContainer,
  StyledAdditionalContactPhoneNumberWrapper
} from './styled';

interface IProps {
  parentKey: string,
  onRemoveClick?: () => void,
}

export const AdditionalContactSection = ({ parentKey, onRemoveClick }: IProps) => (
  <StyledContactContainer>
    <FlexRowEnd>
      <StyledCloseIconWrapper>
        <CloseIcon onClick={onRemoveClick} />
      </StyledCloseIconWrapper>
    </FlexRowEnd>
    <StyledFormRow>
      <TextField type='text' name={`${parentKey}.name`} label='Owner Name/Corporation Name' placeholder='John Doe' />
      <TextField type='text' name={`${parentKey}.position`} label='Title' placeholder='CFO' />
    </StyledFormRow>
    <StyledFormRow>
      <StyledAdditionalContactPhoneNumberExtensionContainer>
        <StyledAdditionalContactPhoneNumberWrapper>
          <PhoneNumberField name={`${parentKey}.phoneNumber`} label='Phone Number' placeholder='Phone number' />
        </StyledAdditionalContactPhoneNumberWrapper>
        <TextField type='text' name={`${parentKey}.extension`} label='Extension' placeholder='123' width='5rem' />
      </StyledAdditionalContactPhoneNumberExtensionContainer>
      <TextField type='email' name={`${parentKey}.email`} label='Email Address' placeholder='John@streamlink.com' />
    </StyledFormRow>
  </StyledContactContainer>
);
