import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { SEEKER_SUBMIT_APPLICATION } from '@/graphql/mutations/seekerSubmitApplication';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Button } from '@/components/designSystem/buttons';

interface ISubmitApplicationActionProps {
  customer: ICustomer,
  refetch: () => void,
  buttonText: string,
}
  
export const SubmitApplicationAction = ({ customer, refetch, buttonText }: ISubmitApplicationActionProps) => {
  const customerId = get('id', customer);
  const [submitApplicationMutation, { loading }] = useMutation(SEEKER_SUBMIT_APPLICATION);
  
  const submitApp = async () => {
    submitApplicationMutation({ variables: { customerId } })
      .then((response) => {
        const successId = get('data.seekerSubmitApplication.id', response);
  
        if (!successId) return;
        refetch();
      })
      .catch((e) => {
        console.error('Application Submit Error', e);
        showToast({
          title: 'Oh no',
          description: 'We we\'re not able to submit the application, please refresh and try again.',
          type: toast.TYPE.ERROR,
        });
      });
  };
  
  return (<Button wide primary loading={loading} onClick={submitApp}>{buttonText}</Button>);
};