import styled from 'styled-components';

import { FlexColumn, FlexRow, FlexRowEnd, FlexRowSpaceBetween } from '@/components/designSystem/Layout';

export const StyledContainer = styled(FlexColumn)`
  width: 100%;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
`;

export const StyledTitleWrapper = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 4rem;
`;

export const StyledButtonsContainer = styled(FlexRowEnd)`
  margin-top: 2rem;
  gap: 1rem;
`;

export const StyledTableInfo = styled(FlexRowSpaceBetween)`
  position: relative;
`;

export const StyledCustomersInfo = styled(FlexRow)`
  position: absolute;
  z-index: 1;
  left: 0;
  gap: 2rem;
`;
