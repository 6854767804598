import React, { RefObject } from 'react';

import { Formik } from 'formik';

import Yup from '@/utils/yup';
import { AnyObject } from 'yup/lib/types';

import { Button } from '@/components/designSystem/buttons';

import { StyledModal, IStyledModalProps } from '.';
import {
  FooterContainer,
  ContentContainer,
  StyledForm,
  HelpText,
} from './styled';
import { ScrollArrow } from '@/components/ScrollArrow';

interface IProps<IFields> extends IStyledModalProps {
  initialValues: IFields,
  isAdditionalTouched?: boolean,
  onSubmit: (response: IFields) => void,
  validationSchema?: Yup.ObjectSchema<AnyObject>,
  helpText?: JSX.Element | string,
  submitButtonText: string,
  loading?: boolean,
  overflow?: string,
  children: React.ReactNode,
  modalRef?: RefObject<HTMLDivElement>,
  scrollArrow?: {
    isScrollArrowUp: boolean,
    handleScroll: () => void,
    handleScrollArrowClick: () => void,
  }
}

export const StyledModalForm = <IFields extends object>(props: IProps<IFields>) => {
  const {
    initialValues,
    onSubmit,
    isDisabled,
    validationSchema,
    helpText,
    submitButtonText,
    loading = false,
    children,
    overflow,
    maxHeight,
    isAdditionalTouched,
    modalRef,
    scrollArrow
  } = props;

  return (
    <StyledModal {...props}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange enableReinitialize>
        <StyledForm>
          <ContentContainer onScroll={scrollArrow?.handleScroll} overflow={overflow} maxHeight={maxHeight} ref={modalRef}>
            {children}
            {(scrollArrow) ? (
              <ScrollArrow
                isScrollArrowUp={scrollArrow.isScrollArrowUp}
                handleScrollArrowClick={scrollArrow.handleScrollArrowClick}
                rightPosition='-45px'
              />
            ) : false}
          </ContentContainer>
          <FooterContainer>
            <Button wide primary loading={loading} type='submit'>{submitButtonText}</Button>
            {helpText && <HelpText color='secondary'>{helpText}</HelpText>}
          </FooterContainer>
        </StyledForm>
      </Formik>
    </StyledModal>
  );
};
