import gql from 'graphql-tag';

export const EMAIL_EXISTS = gql`
  query EmailExists($email: String!) {
    email_exists(email: $email)
  }
`;

export const IS_BUSINESS_EMAIL_DOMAIN = gql`
  query IsBusinessEmailDomain($email: String!) {
    isBusinessEmailDomain(email: $email)
  }
`;

