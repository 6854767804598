import React from 'react';


import { H4, BodyText } from '@/components/designSystem/Typography';
import { MonogramAvatar } from '@/components/MonogramAvatar';
import { Flex } from './designSystem/Layout';

interface IProps {
  name: string,
  detail: string,
  companyName?: string | null,
}

export const UserInfo = ({ name, detail, companyName }: IProps) => (
  <Flex align='center'>
    <MonogramAvatar name={name} />
    <Flex direction='column'>
      <H4 bold>{name}</H4>
      <BodyText color='secondary'>{detail}</BodyText>
      {companyName && <BodyText color='secondary'>{companyName}</BodyText>}
    </Flex>
  </Flex>
);
