import gql from 'graphql-tag';

export const CREATE_CREDIT_REPORT = gql`
  mutation CreateCreditReport($signedFileCreditReportUploadData: SignedFileCreditReportUploadInput!) {
    createCreditReport(signed_file_credit_report_upload_data: $signedFileCreditReportUploadData) {
      id
      path
      author {
        id
        email
      }
      originalFilename
      currentFilename
      signedUrl
    }
  }
`;