import styled from 'styled-components';

import { Form } from 'formik';

import { Flex } from '@/components/designSystem/Layout';
import { Field } from '@/components/designSystem/Form/Field';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';

export const StyledContainer = styled(Form)`
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 500px;
`;

export const StyledField = styled(Field)`
  width: 120px;
`;

export const StyledSelectField = styled(SelectField)`
  width: 190px;
`;

export const StyledAdminCheckboxContainer = styled(Flex).attrs({ direction: 'column', align: 'center' })`
  flex-basis: 12rem;
  padding-bottom: 1.5rem;
  margin-bottom: 0.3rem;
`;

export const StyledFieldLabel = styled(FieldLabel)`
  margin-bottom: 0.3rem;
`;

export const StyledAdminCheckboxWrapper = styled(Flex).attrs({ direction: 'column' })`
  height: 3rem;
  padding-top: 0.8rem;
`;
