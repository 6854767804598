import React from 'react';

import { IBankruptcyCase } from '@/types/bankruptcyCase';

import { ToggleBlock } from '@/components/ToggleBlock';

import { BankruptciesSingleResultView } from './BankruptciesSingleResultView';
import { BankruptciesMultipleResultsView } from './BankruptciesMultipleResultsView';
import { Header } from '../Header';

interface IProps {
  resultsNumber: number,
  results: IBankruptcyCase[],
}

export const Bankruptcies = ({ resultsNumber, results }: IProps) => (
  <ToggleBlock
    header={
      <Header
        title='Bankruptcies'
        resultsNumberText={
          <>
            {resultsNumber === null ? 'Not Applicable' : `${resultsNumber} result(s) found`}
          </>
        }
      />
    }
    isAvailableToOpen={!!resultsNumber}
  >
    { results.length > 1 ? (
      <BankruptciesMultipleResultsView bankruptcyResults={results} />
    ) : (
      <BankruptciesSingleResultView bankruptcyResult={results[0]} />
    )}
  </ToggleBlock>
);