import React, { useState, useEffect } from 'react';
import { ReactComponent as XClose } from '@/assets/x_close.svg';
import {
  SlideOverWrapper,
  SlideOverContainer,
  StyledCloseButton,
} from './styled';

interface ISlideoverProps {
  isOpen: boolean;
  onClose: () => void;
  width?: string;
  height?: string;
  position?: string;
  children: React.ReactNode;
}

const Slideover: React.FC<ISlideoverProps> = ({
  isOpen,
  onClose,
  width,
  height,
  position,
  children
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(isOpen);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setIsVisible(false);
      onClose();
    }, 300); // Match the animation duration
  };

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsClosing(false);
    }
  }, [isOpen]);

  return isVisible ? (
    <SlideOverWrapper
      isOpen={isOpen}
      isClosing={isClosing}
      width={width}
      height={height}
      position={position}
    >
      <SlideOverContainer>
        {children}
        <StyledCloseButton onClick={handleClose}>
          <XClose />
        </StyledCloseButton>
      </SlideOverContainer>
    </SlideOverWrapper>
  ) : null;
};

export default Slideover;
