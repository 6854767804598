import { Dispatch, SetStateAction } from 'react';

import { VIEWS } from '.';

interface IArgs {
  setActiveView: Dispatch<SetStateAction<keyof typeof VIEWS>>,
}

export const useOptions = ({ setActiveView }: IArgs) => {

  const handleOptionClick = (view: keyof typeof VIEWS) => {
    setActiveView(view);
  };

  return [
    {
      title: 'Text',
      description: "Choose this if you need to add plain text field. Eg 'Name', 'Title'.",
      handleOptionClick: () => handleOptionClick(VIEWS.TEXT),
    },
    {
      title: 'File Upload',
      description: 'Choose this if you need to add the field for adding one or more files.',
      handleOptionClick: () => handleOptionClick(VIEWS.FILE_UPLOAD),
    },
    {
      title: 'Yes / No Toggle',
      description: 'Choose this if you need a conditional field.',
      handleOptionClick: () => handleOptionClick(VIEWS.YES_NO_TOGGLE),
    },
    {
      title: 'Contact',
      description: 'Choose this if you need an additional contact field. The field will appear on step 3 of onboarding.',
      handleOptionClick: () => handleOptionClick(VIEWS.CONTACT),
    },
    {
      title: 'Currency',
      description: 'Choose this if you need a field related to currency.',
      handleOptionClick: () => handleOptionClick(VIEWS.CURRENCY),
    },
    {
      title: 'Dropdown',
      description: 'Choose this if you need a field where customer can select only one answer from a menu of choices.',
      handleOptionClick: () => handleOptionClick(VIEWS.DROPDOWN),
    },
    {
      title: 'Multiple choice',
      description: 'Choose this if you need a field where customer can select multiple answers from a menu of choices.',
      handleOptionClick: () => handleOptionClick(VIEWS.MULTIPLE_CHOICE),
    },
    {
      title: 'Additional Question',
      description: 'Choose this if you need a field where customer can select only one answer from a menu of choices. This field will appear on step 2 of onboarding.',
      handleOptionClick: () => handleOptionClick(VIEWS.ADDITIONAL_QUESTION),
    }
  ]
};
