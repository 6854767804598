import React, { useContext } from 'react';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { get, getOr } from 'lodash/fp';
import { formatCurrency } from '@/utils/money';
import { mapStatusLabel } from '@/utils/format';

import { ICustomer } from '@/types/customer';
import { ILabelValue } from '@/types/field';
import { Row } from '@/components/designSystem/Form/styled';
import { BodyText, H1, H2 } from '@/components/designSystem/Typography';
import { StatusLabel } from '@/components/Label/StatusLabel';
import { Flex } from '@/components/designSystem/Layout';
import { AssigneeForm } from './AssigneeForm';
import { TagForm } from './TagForm';
import { IUser, ICompanyUser } from '@/types/companyUser';
import { ICurrentCompany } from '@/types/currentCompany';
import { getAssigneeInitialValues } from './initialValues';

import {
  StyledMainInfoContainer,
  StyledMainInfoValuesContainer,
} from '../styled';

const MainInfoValue = ({ label, value }: ILabelValue) => (
  <Flex direction='column' justify='flex-end'>
    <H2>{value}</H2>
    <BodyText color='secondary'>{label}</BodyText>
  </Flex>
);

interface IProps {
  customerData?: ICustomer,
  refetchCustomerData: () => void,
}

interface AssigneeProps {
  index: number,
  name: string,
  id: string
}

interface TagProps {
  id: number,
  name: string,
  backgroundColor: string,
  textColor: string
}

const tags: TagProps[] = [
  { id: 1, name: 'Risk: High', backgroundColor: '#fff7ed', textColor: '#c2410c' },
  { id: 2, name: 'Risk: Medium', backgroundColor: '#fefce8', textColor: '#a16207' },
  { id: 3, name: 'Risk: Low', backgroundColor: '#f0fdf4', textColor: '#15803d' },
  { id: 4, name: 'Fraud', backgroundColor: '#fef2f2', textColor: '#b91c1c' },
]

export const MainInfo = ({ customerData, refetchCustomerData}: IProps) => {
  const application = get('latestApplication', customerData);
  const applicationId = get('id', application);
  const isManualCustomer = get('isManualCustomer', customerData);
  const creditLimit = getOr('--', 'creditLimit', application);
  const creditTerms = getOr('--', 'creditTerms', application);
  const status = getOr('', 'providerDisplayStatus', application);
  const assignedTo = getOr('', 'assignedTo.id', application);
  const isPending = status === 'pending_approval';
  const reviewedAt = get('latestApplication.reviewedAt', customerData);

  const currentCompany = useContext(CurrentCompanyContext);
  const users: IUser[] = getOr([], 'listUsers', currentCompany?.currentCompany);

  const assignees: AssigneeProps[] = users
    .filter(user => user.email && !user.email.includes('nectarinecredit.com'))
    .map((user, index) => ({
      index,
      name: user.name,
      id: user.id,
    }));

  return (
    <StyledMainInfoContainer>
      <Flex justify='space-between' align='center'>
        <div>
          <Row>
            <StatusLabel value={status} />
            {
              isManualCustomer ?
                <StatusLabel value='Manual Customer' />
                : null
            }
            {reviewedAt && <StatusLabel value={`Reviewed at: ${reviewedAt}`} />}
          </Row>
          <H1 bold>{get('seekerCompany.name', customerData)}</H1>
          <Row>
            <AssigneeForm data={assignees} assignedTo={assignedTo} applicationId={applicationId} refetchCustomerData={refetchCustomerData}/>
            {/* <TagForm data={tags} /> */}
          </Row>
        </div>
        <StyledMainInfoValuesContainer>
          <MainInfoValue
            value={typeof creditLimit === 'number' ? formatCurrency(creditLimit) : '--'}
            label={isPending ? 'Requested Credit limit' : 'Credit limit'}
          />
          <MainInfoValue
            value={creditTerms || '--'}
            label={isPending ? 'Requested Terms' : 'Terms'}
          />
        </StyledMainInfoValuesContainer>
      </Flex>
    </StyledMainInfoContainer>
  );
};
