import gql from 'graphql-tag';

export const GET_PARENT_COMPANY = gql`
  query {
    parentCompany {
      id
      name
    }
  }
`;
