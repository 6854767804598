import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { useAuth0 } from '@/providers/Auth0/Auth0';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { BodyText } from '@/components/designSystem/Typography';
import { FlexColumn, FlexRowEnd } from '../components/designSystem/Layout';

const Container = styled(FlexColumn)`
  padding: 2rem 1.5rem 0 1.5rem;
`;

const ActionContainer = styled(FlexRowEnd)`
  padding: 1.5rem;

  & > * {
    margin-right: 1rem;
  }

  & > :last-child{
    margin-right: 0;
  }
`;

const StyledButton = styled(Button)`
  padding: 0.7rem 2rem;
`;

const StyledMainButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.color.brand};
  color: white;
`;

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
}

export const LogoutModal = ({ setIsOpen, isOpen }: IProps) => {
  const { logout } = useAuth0();

  return (
    <StyledModal
      title='Confirm logout'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      blockClose
    >
      <Container>
        <BodyText>Do you want to logout of your Nectarine Credit session</BodyText>
      </Container>
      <ActionContainer>
        <StyledButton secondary onClick={() => setIsOpen(false)}>Go back</StyledButton>
        <StyledMainButton onClick={logout}>Logout</StyledMainButton>
      </ActionContainer>
    </StyledModal>
  );
};
