import React from 'react';

import { IVendor } from '@/types/vendor';
import { IEmailThread } from '@/types/emailThread';

import { Flex } from '@/components/designSystem/Layout';

import { StyledEmailCommunicationsWrapper } from '../styled';

import { EmailThread } from './EmailThread';

interface IProps {
  emailThreads: IEmailThread[]
  reference: IVendor,
  refetch: () => void,
}

export const EmailThreads = ({
  emailThreads,
  reference,
  refetch,
}: IProps) => {
  if (emailThreads && emailThreads.length) {
    return (
      <>
        <StyledEmailCommunicationsWrapper>
          <Flex justify='space-between'>Email Communications ({emailThreads.length})
            <EmailThread vendor={reference} emailThreads={emailThreads} refetch={() => refetch ? refetch() : null} />
          </Flex>
        </StyledEmailCommunicationsWrapper>
      </>
    )
  }

  return null;
}
