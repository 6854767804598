import { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { IS_CREDIT_SAFE_CREDENTIALS_STILL_VALID } from '@/graphql/mutations/generateCreditSafeReport';

import { getOr } from 'lodash/fp';

export const useCreditSafe = () => {
  const [showCreditSafeCredentialsModal, setShowCreditSafeCredentialsModal] = useState(false);
  const [showCreditSafeSearchModal, setShowCreditSafeSearchModal] = useState(false);
  const [getCreditSafeCredentialsStillValid, { loading: csLoading }] = useMutation(IS_CREDIT_SAFE_CREDENTIALS_STILL_VALID);

  const onCreditSafeClick = () => {
    getCreditSafeCredentialsStillValid()
      .then((data) => {
        const isCreditSafeCredentialsStillValid = getOr(false, 'data.isCreditSafeCredentialsStillValid', data);
        if (isCreditSafeCredentialsStillValid) {
          setShowCreditSafeSearchModal(true);
        } else {
          setShowCreditSafeCredentialsModal(true);
        }
      });
  };

  return {
    showCreditSafeCredentialsModal,
    setShowCreditSafeCredentialsModal,
    showCreditSafeSearchModal,
    setShowCreditSafeSearchModal,
    csLoading,
    onCreditSafeClick,
  }
}