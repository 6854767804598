import React, { useContext, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useLocalStorage } from '@rehooks/local-storage';

import { useQuery } from '@apollo/react-hooks';
import { GET_CUSTOMER_AS_PROVIDER } from '@/graphql/queries/getCustomer';
import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';

import { get, getOr } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { IGetCustomerAsProviderData } from '@/types/querries';
import { IUserCompanyRole } from '@/types/companyUser';

import { CurrentUserContext } from '@/providers/CurrentUser';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { FlexRowItemsCenter } from '@/components/designSystem/Layout';

import { MainInfo } from './MainInfo';
import { UpdateCredit } from './Actions/UpdateCredit';
import { RequestChanges } from './Actions/RequestChanges';
import { Review } from './Actions/Review';
import { CompanyInfo } from './tabs/CompanyInfo';
import { BureauReport } from './tabs/BureauReport';
import { References } from './tabs/References';
import { Bank } from './tabs/Bank';
import { Preferences } from './Actions/More/Preferences';
import { Activities } from './tabs/Activities';
import { StyledContainer, StyledTabList, StyledTab, StyledDropdownContainer } from './styled';
import { WarningIcon } from './WarningIcon';

import Dropdown from '@/components/Dropdown';
import Slideover from '@/modals/SlideOver';
import { includes } from 'lodash';

interface IActivity {
  data: any,
  actor: string,
  insertedAt: string,
  type: string
}

interface ITab {
  title: string | JSX.Element,
  icon?: JSX.Element,
  Component: JSX.Element,
  id: string,
}

interface IDropdownOptions {
  label: string;
  separator: boolean;
}

interface IParams {
  customerId: string,
}

export const BankVerificationStatus = React.createContext("");

export const CustomerPage = () => {
  const [companyId] = useLocalStorage('company-id');
  const { customerId } = useParams<IParams>();
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('')

  const {
    data: getCustomerAsProviderData,
    loading,
    error: getCustomerAsProviderError,
    refetch: refetchCustomerData
  } = useQuery<IGetCustomerAsProviderData>(GET_CUSTOMER_AS_PROVIDER, { variables: { customerId }, fetchPolicy: 'no-cache' });
  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY);

  const activities = getOr([], 'listActivities', getCustomerAsProviderData).map((activity: IActivity)  => {
    return { ...activity, data: JSON.parse(activity.data), actor: JSON.parse(activity.actor) }
  });

  const currentUserData = useContext(CurrentUserContext);
  const currentCompanyData = useContext(CurrentCompanyContext);

  const parentCompanyId = get('parentCompany.id', parentCompany);
  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData);
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId), userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId))
  // const isAdmin = get('isAdmin', currentUserCompanyRole[0])
  const isReadOnly = get('role.permissions.readOnly', currentUserCompanyRole[0]) || false;

  const canSeeEditControl = !isReadOnly;

  const customer = get('getCustomerAsProvider', getCustomerAsProviderData);
  const application = get('latestApplication', customer);
  const providerSettings = get('providerCompany.settings', customer)
  const seekerApprovalAlert = get('seekerApprovalAlert', providerSettings)
  const seekerDenialAlert = get('seekerDenialAlert', providerSettings)
  const applicationId = get('id', application);
  const creditTermsOptions = get('currentCompany.creditTerms', currentCompanyData);

  const isManualCustomer = get('isManualCustomer', customer);
  const numberOfManualInstitutions: number = get('seekerCompany.manualInstitutions.length', customer)
  const numberOfAutomaticAccounts: number =  get('seekerCompany.finicityAccountProjections.length', customer)
  const numberOfPendingVendorInvites: number = get('seekerCompany.pendingVendorInvites.length', customer)
  const numberOfReceivedReferences: number = get('seekerCompany.receivedReferences.length', customer)
  const numberOfVendors: number = numberOfPendingVendorInvites + numberOfReceivedReferences;

  const bankruptcies = get('getBankruptcies', getCustomerAsProviderData);

  if (getCustomerAsProviderError) {
    return (
      <Redirect to='/dashboard'/>
    )
  }

  if (!customer || loading) {
    return <LoadingSpinner />
  }
  
  const handleOpen = (option: string) => {
    setCurrentTab(option)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  interface IIsApproved {status: string}
  const isAllowedReview : (application: IIsApproved) => boolean = (application) => {
    return includes(['approved', 'external_review', 'pending_review'], application.status);
  }

  const options: IDropdownOptions[] = [
    { label: 'Update Credit', separator: false },
    { label: 'Request Changes', separator: false },
    ...(isAllowedReview(application || {status: ''}) ? [{ label: 'Review', separator: true }] : []),
    {label: 'More ...', separator: false}
  ];

  const filteredOptions: IDropdownOptions[] = isManualCustomer
    ? options.filter(option => option.label !== 'Request Changes')
    : options;

  const TABS = [
    {
      id: '1',
      title: 'Company info',
      Component: <CompanyInfo canSeeEditControl={canSeeEditControl} customerData={customer} bankruptcies={bankruptcies} refetchCustomerData={refetchCustomerData} providerSettings={providerSettings}/>
    },
    {
      id: '2',
      title: 'Bureau report',
      Component: <BureauReport canSeeEditControl={canSeeEditControl} customerData={customer} customerId={customerId} refetchCustomerData={refetchCustomerData} />
    },
    {
      id: '3',
      title: 'References',
      icon: <WarningIcon />,
      Component: <References
        canSeeEditControl={canSeeEditControl}
        customerData={customer}
        refetchCustomerData={refetchCustomerData}
      />
    },
    { id: '4', title: 'Bank info', icon: <WarningIcon />, Component: <Bank customerData={customer} refetchCustomerData={refetchCustomerData} /> },
    { id: '5', title: 'Activities',  Component: <Activities canSeeEditControl={!isReadOnly} activities={activities} customerData={customer} refetchCustomerData={refetchCustomerData} /> },
  ];

  return (
    <StyledContainer>
      <BankVerificationStatus.Provider value={customer.bankVerificationStatus}>
        <MainInfo customerData={customer} refetchCustomerData={refetchCustomerData} />
        <Tabs selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)}>
          <StyledTabList>
            {TABS.map((tab: ITab) => (
              <StyledTab key={tab.id}>
                <FlexRowItemsCenter>
                  {
                    (isManualCustomer && numberOfVendors < 1 && tab.id === '3')? tab.icon : null
                  }
                  {
                    (isManualCustomer && numberOfManualInstitutions < 1 && numberOfAutomaticAccounts < 1 && tab.id === '4')? tab.icon : null
                  }
                  {tab.title}
                </FlexRowItemsCenter>
              </StyledTab>
            ))}
            <StyledDropdownContainer>
              <Dropdown
                label="Actions"
                options={filteredOptions}
                onSelect={handleOpen}
                position='right'
              />
            </StyledDropdownContainer>
          </StyledTabList>
          {TABS.map((tab: ITab) => <TabPanel key={tab.id}>{tab.Component}</TabPanel>)}
        </Tabs>
        <Slideover
          isOpen={isOpen}
          onClose={handleClose}
          position='right'
        >
          {currentTab === 'Update Credit' &&
            <>
              {application && (
                <React.Fragment>
                  {!isReadOnly && (
                    <UpdateCredit
                      customerData={customer}
                      application={application}
                      creditTermsOptions={creditTermsOptions}
                      seekerApprovalAlert={seekerApprovalAlert}
                      seekerDenialAlert={seekerDenialAlert}
                      refetchCustomerData={refetchCustomerData}
                    />
                  )}
                </React.Fragment>
              )}
            </>
          }
          {currentTab === 'Request Changes' &&
            <>
              {application && (
                <React.Fragment>
                  {!isReadOnly && (
                    <RequestChanges
                      canSeeEditControl={!isReadOnly}
                      customerData={customer}
                      application={application}
                      creditTermsOptions={creditTermsOptions}
                      seekerApprovalAlert={seekerApprovalAlert}
                      seekerDenialAlert={seekerDenialAlert}
                      refetchCustomerData={refetchCustomerData}
                      closeDrawer={handleClose}
                      customerId={customerId}
                    />
                  )}
                </React.Fragment>
              )}
            </>
          }
          {currentTab === 'Review' &&
            <Review customerData={customer} refetchCustomerData={refetchCustomerData} customerId={customerId} setOpen={setOpen} creditTermsOptions={creditTermsOptions} />
          }
          {currentTab === 'More ...' &&
            <Preferences
              canSeeEditControl={!isReadOnly}
              customerData={customer}
              customerId={customerId}
              applicationId={applicationId}
              refetchCustomerData={refetchCustomerData}
            />
          }
        </Slideover>
      </BankVerificationStatus.Provider>
    </StyledContainer>
  );
};
