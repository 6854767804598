import React from 'react';

import { H2, BodyText, StyledLink } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';

import { StyledForm, StyledFieldsSectionContainer, StyledLinkButton, StyledButtonMargin, StyledH2Margin } from './styled';

interface ILoginProps {
  isLoading: boolean,
  back: () => void,
  forgot: () => void
}

interface IForgotProps {
  back: () => void
}

export const ReturningLoginForm = ({ isLoading, back, forgot }: ILoginProps) => (
  <StyledForm>
    <StyledH2Margin bold>Login</StyledH2Margin>
    <StyledFieldsSectionContainer>
      <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
      <TextField required type='password' name='password' label='Password' placeholder='*****' />
    </StyledFieldsSectionContainer>
    <Flex direction='column'>
      <Button wide primary type='submit' loading={isLoading}>Login</Button>
      <StyledButtonMargin wide secondary type='button' onClick={back}>Back</StyledButtonMargin>
    </Flex>
    <StyledLinkButton type="button" onClick={forgot}>Forgot Password?</StyledLinkButton>
  </StyledForm>
);

export const ReturningForgotPasswordForm = ({ back }: IForgotProps) => (
  <StyledForm>
    <StyledH2Margin bold>Forgot Password</StyledH2Margin>
    <StyledFieldsSectionContainer>
      <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
    </StyledFieldsSectionContainer>
    <Flex direction='column'>
      <Button wide primary type='submit'>Reset</Button>
      <StyledButtonMargin wide secondary type='button' onClick={back}>Back</StyledButtonMargin>
    </Flex>
  </StyledForm>
);