const initialContactFields = {
  name: '',
  email: '',
  position: '',
  phoneNumber: '',
}

export const initialAdditionalContactFields = {
  ...initialContactFields,
  extension: '',
}

export const initialOwnerFields = {
  ...initialContactFields,
  ownershipPercentage: '',
}

export const companyContactsInitialValues ={
  owners: [initialOwnerFields],
  isCurrentUserOwner: false,
};
