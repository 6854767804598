import { useEffect, useRef, MutableRefObject } from 'react';
import { useLoadScript } from "@react-google-maps/api";

import { IGooglePlace } from '@/types/googlePlace';

import { PLACES_LIBRARIES, PLACES_OPTIONS } from '@/constants';

interface IUseGoogleAddressFieldArgs {
  inputRef: MutableRefObject<HTMLInputElement | null>,
  setFieldsFromGooglePlace: (place: IGooglePlace) => void,
}

export const useGoogleAddressFields = ({ inputRef, setFieldsFromGooglePlace }: IUseGoogleAddressFieldArgs) => {
  const autoCompleteRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.CLIENT_PLACES_KEY as string,
    libraries: PLACES_LIBRARIES,
    language: 'en',
  });

  useEffect(() => {
    if (isLoaded) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        PLACES_OPTIONS,
      );
      if (autoCompleteRef.current) {
        (autoCompleteRef.current as any).addListener("place_changed", async function () {
          const place = await (autoCompleteRef.current as any).getPlace();
          setFieldsFromGooglePlace(place);
        });
      }
    }
  }, [isLoaded]);
}
