import React from 'react';

import { ColorPicker } from './ColorPicker';
import { StyledColorPickerInputContainer, StyledColorPickerTextInput } from '../styled';

interface IProps {
  value: string,
  onChange: (value: string) => void,
}

export const ColorPickerInput = ({ value, onChange }: IProps) => (
  <StyledColorPickerInputContainer>
    <ColorPicker value={value} onChange={onChange} />
    <StyledColorPickerTextInput
      type='text'
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </StyledColorPickerInputContainer>
);
