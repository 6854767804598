import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import auth0, { Auth0UserProfile, WebAuth } from 'auth0-js';

import { createApolloClient } from '@/graphql/client';
import { GET_MY_COMPANIES } from '@/graphql/queries/getMyCompanies';

import { get, getOr } from 'lodash/fp';
import { useQueryParams } from '@/utils/history';
import { useLocalStorage, deleteFromStorage, writeStorage } from '@rehooks/local-storage';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { AUTH0 } from '@/app.config';

import { useAuth0, Auth0Context, ICPOnboardOptions, ICAOnboardOptions } from './context';
import { ONBOARD_CREDIT_SEEKER_INPUT } from '@/graphql/mutations/onboardCreditSeeker';
import { ONBOARD_CREDIT_PROVIDER_INPUT } from '@/graphql/mutations/onboardCreditProvider';

interface IProps {
  children: React.ReactNode,
}

const Auth0Provider = ({
  children,
}: IProps) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const query = useQueryParams();
  const redirect = query.get('redirect');

  const auth0Client: WebAuth = new auth0.WebAuth({
    domain: AUTH0.domain || '',
    clientID: AUTH0.clientId || '',
    audience: AUTH0.audience,
  });

  const [token] = useLocalStorage('token');
  const [companyId] = useLocalStorage('company-id');

  const [user, setUser] = useState<Auth0UserProfile | null>(null);

  const clearSession = () => {
    deleteFromStorage('token');
    deleteFromStorage('company-id');
    deleteFromStorage('customer-id');

    window.Beacon && window.Beacon('logout');
    window.analytics.reset();

    setUser(null);
  };

  useEffect(() => {
    if (!token) {
      clearSession();
    } else {
      auth0Client.client.userInfo(token, (err, userInfo) => {
        if (err) {
          console.error('Login Error', err);
          return;
        }
        setUser(userInfo);
      });
    }
  }, [token]);

  const loginPromisified = (email: string, password: string) => new Promise((resolve, reject) => {
    auth0Client.client.login({
      username: email,
      password,
      realm: AUTH0.realm,
      scope: AUTH0.scope,
    }, (err, authResult) => {
      if (err) {
        console.error('Login Error', err);
        return reject(err);
      }
      return resolve(authResult);
    });
  });

  const signupPromisified = (email: string, password: string) => new Promise((resolve, reject) => {
    auth0Client.signup({
      email,
      password,
      connection: AUTH0.realm,
    }, (err, authResult) => {
      console.log(authResult)
      if (err) {
        console.error('Signup Error', err);
        return reject(err);
      }
      return resolve(authResult);
    });
  });

  const login = (email: string, password: string) => loginPromisified(email, password)
    .then((authResult: any) => {
      const { accessToken } = authResult;
      if (accessToken) {
        writeStorage('token', accessToken);
      }

      return accessToken;
    });

  const loginWithoutTokenStoring = (email: string, password: string) => loginPromisified(email, password)
    .then((authResult: any) => {
      const { accessToken } = authResult;

      return accessToken;
    });

  const signup = (name: string, email: string, password: string) => signupPromisified(email, password)
    .then((_authResult) => login(email, password));

  const signUpCreditProvider = (
    email: string,
    password: string,
    onboardVariables: ICPOnboardOptions
  ) => {
    setLoading(true);
    return signupPromisified(email, password)
      .catch((_err) => login(email, password))
      .then(() => loginWithoutTokenStoring(email, password))
      .then((accessToken) => {
        const client = createApolloClient({ token: accessToken });
        return client.mutate({
          mutation: ONBOARD_CREDIT_PROVIDER_INPUT,
          variables: onboardVariables
        });
      })
      .then(({ data }) => data)
      .finally(() => setLoading(false));
  };

  const signUpOrLoginCreditApplicant = (name: string, email: string, password: string,
    {
      onboardVariables,
      onboardValues,
      visitorData,
      referralCode,
      providerCompanyId
    } : ICAOnboardOptions) => {
    setLoading(true);
    return signup(name, email, password)
      .catch((_err) => login(email, password))
      .then((token) => {
        const client = createApolloClient({ token });
        return client.mutate({
          mutation: ONBOARD_CREDIT_SEEKER_INPUT,
          variables: {
            onboardData: onboardVariables.onboardData,
            customFieldAnswers: onboardVariables.customFieldAnswers,
            visitorData,
            referralCode,
            providerCompanyId,
            redirectTo: `${onboardValues.basePath}/bank`,
          }
        });
      })
      .then(({ data }) => data)
      .finally(() => setLoading(false));
  };

  const logout = () => {
    clearSession();
    history.push('/');
  };

  const loginWithCompany = (email: string, password: string) => {
    setLoading(true);
    clearSession();

    login(email, password)
      .then((token) => {
        const client = createApolloClient({ token });
        return client.query({ query: GET_MY_COMPANIES });
      })
      .then(({ data }) => {
        const companies = getOr([], 'myCompanies', data);
        const referralPath = get('currentUser.referralPath', data);

        if (companies.length > 1) {
          console.error('DANGEROUS STATE: User has more than one company which at this time is not allowed.');
        }

        if (companies.length < 1) {
          console.error('DANGEROUS STATE: User has no company which at this time is not allowed.');
          const goto = referralPath || redirect || '/signup';

          setTimeout(() => history.push(goto), 500);
        } else {
          const companyId = get('[0].id', companies);
          writeStorage('company-id', companyId);
          const goto = redirect || '/dashboard';
          setTimeout(() => history.push(goto), 500);
        }
      }).catch((e) => {
        // login failed
        console.error('Login Failed', e);
        clearSession();
        showToast({
          title: 'Login Unsuccessful',
          description: 'Please check your email and password and try again',
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Auth0Context.Provider
      value={{
        login,
        signup,
        user,
        token,
        logout,
        loading,
        loginWithCompany,
        signUpCreditProvider,
        signUpOrLoginCreditApplicant,
        clearSession,
        companyId,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export {
  Auth0Provider,
  useAuth0,
};
