import gql from 'graphql-tag';

export const GET_MY_COMPANIES = gql`
  query GetMyCompanies {
    currentUser {
      id
      name
      referralPath
      email
      emailVerified
      userCompanyRole {
        isAdmin
        creditLimit
        company {
          id
        }
        role {
          name
          isCreditLimitApply
          permissions {
            inviteCustomer
            readOnly
            inviteUser
            changeTemplate
          }
        }
      }
    }
    myCompanies {
      id
      name
    }
  }
`;
