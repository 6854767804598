import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { noop } from 'lodash/fp';

import { Button, IButtonProps } from '@/components/designSystem/buttons';
import { ConnectBankModal } from '@/modals/ConnectBankModal';

const StyledConnectButton = styled(Button)`
  margin-top: 1.5rem;
`;

interface IProps extends IButtonProps {
  onSuccess: () => void,
  onOpen?: () => void,
}

export const ConnectBankButton = ({ onSuccess, onOpen = noop, ...rest }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      onOpen();
    }
  }, [isOpen]);

  return (
    <>
      <StyledConnectButton primary onClick={() => setIsOpen(true)} {...rest} />
      <ConnectBankModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={onSuccess} />
    </>
  );
};
