import styled from 'styled-components';

import { H2, H3, H4, Caption } from '@/components/designSystem/Typography';
import { FlexColumn, FlexRow } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as CheckIcon } from '@/assets/check-circle.svg';
import { ReactComponent as XIcon } from '@/assets/x_circle.svg';
import { ReactComponent as WarningIcon } from '@/assets/warning.svg';

export const StyledCheckIcon = styled(CheckIcon)`
  margin-top: 0.2rem;
  color: ${({ theme }) => theme.color.data.positive};
`;

export const PaddedH3 = styled(H3)`
  padding-left: 1rem;
`;

interface IProps {
  mt?: string,
}

export const StatsHeader = styled(H4).attrs({ bold: true })<IProps>`
  margin-top: ${({ mt = '2rem' }) => mt};
`;

export const LatestBalanceHeader = styled(H2).attrs({ bold: true })`
  margin-bottom: 1rem;
`;

export const StyledInstitutionContainer = styled.div`
  & > * {
    margin-bottom: 1rem;
  }
`;

export const StyledToggleBankHeader = styled(FlexRow)`
  flex-grow: 1;

  & > :first-child {
    flex-basis: 35%;
  }

  & > :nth-child(2) {
    flex-basis: 35%;
  }
`;

export const StyledBankStatementsContainer = styled(FlexColumn)`
  gap: 0rem;
  margin-bottom: 1rem;
`;

export const CaptionWithIcon = styled(Caption)`
  display: flex;
  align-items: center;
  font-size: 12pt;
  & > :first-child {
    margin-right: .5rem;
  }
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
`;

export const TooltipText = styled.div`
  visibility: hidden;
  opacity: 0;
  background-color: #333; /* Grey background color */
  color: white;
  border-radius: 8px; /* Rounded border */
  padding: 8px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s, visibility 0.2s;
  width: 200px;

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

export const StyledManualCustomerViewContainer = styled.div`
  margin-top: 1rem;
`;

export const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: 0;
`;

export const StyledAccountInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 1.5rem;
`