import React from 'react';

import { BorderedContainer } from '@/containers/BorderedContainer';

import { H3 } from '@/components/designSystem/Typography';

import { FinicityAccountContainer } from './styled';
import { FlexRowItemsCenter } from '@/components/designSystem/Layout';

interface IProps {
  name: string,
  routingNumber?: string,
  accountNumber: string,
}

export const Account = ({ name, accountNumber, routingNumber }: IProps) => {
  const title = routingNumber
    ? `${name} (${routingNumber}-${accountNumber})`
    : `${name} (${accountNumber})`;

  return (
    <BorderedContainer>
      <FinicityAccountContainer>
        <FlexRowItemsCenter>
          <H3 bold>{title}</H3>
        </FlexRowItemsCenter>
      </FinicityAccountContainer>
    </BorderedContainer>
  );
};
