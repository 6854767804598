import React from 'react';

import { getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { BodyText } from '@/components/designSystem/Typography';
import { LabeledComponent } from '@/components/LabeledComponent';

interface IProps {
  customer?: ICustomer,
  action?: string | JSX.Element,
}

export const CompanyInfoSection = ({ customer, action }: IProps) => {
  const title = action
    ? <>{'Company Information'}{action}</>
    : 'Company Information';

  return (
    <TwoColumnContainer.SecondaryItem title={title}>
      <LabeledComponent label='Legal Company Name'>
        <BodyText>{getOr('--', 'seekerCompany.name', customer)}</BodyText>
      </LabeledComponent>
      <LabeledComponent label='Business Type'>
        <BodyText>{getOr('--', 'seekerCompany.type', customer)}</BodyText>
      </LabeledComponent>
      <LabeledComponent label='Year Established'>
        <BodyText>{getOr('--', 'seekerCompany.established', customer)}</BodyText>
      </LabeledComponent>
      <LabeledComponent label='Tell us more about your business'>
        <BodyText>{getOr('--', 'seekerCompany.description', customer)}</BodyText>
      </LabeledComponent>
    </TwoColumnContainer.SecondaryItem>
  );
};
