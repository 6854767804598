import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { FORGOT_PASSWORD } from '@/graphql/mutations/forgotPassword';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Button } from '@/components/designSystem/buttons';

interface IProps {
  email: string,
}

export const ChangePasswordButton = ({ email }: IProps) => {
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

  const onClick = () => {
    forgotPassword({ variables: { email } })
      .then(() => {
        showToast({
          title: 'Change Password Email Sent',
          description: 'Check your inbox to finish changing your email!',
          type: toast.TYPE.SUCCESS,
        });
      });
  };

  return (<Button onClick={onClick} loading={loading}>Change Your Password</Button>);
};
