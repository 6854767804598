import gql from 'graphql-tag';

export const GET_CONTENT = gql`
  query GetContent($name: String) {
    getContent(name: $name) {
      id
      title
      body
    }
  }
`;

export const GET_CONTENT_BY_PROVIDER_ID = gql`
  query GetContentByProviderId($name: String, $providerCompanyId: ID) {
    getContentByProviderId(name: $name, providerCompanyId: $providerCompanyId) {
      id
      title
      body
    }
  }
`;
