import styled from 'styled-components';
import { MultiSelect } from 'react-multi-select-component';

import { FlexRow } from '@/components/designSystem/Layout';

export const StyledMultiSelectWrapper = styled(FlexRow)`
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
`;

export const StyledMultiSelect = styled(MultiSelect)`
  width: 300px
`;
