import gql from 'graphql-tag';

export const GET_APIDECK_COMPANY = gql`
  query GetApiDeckCompany($customerId: ID!) {
    getApiDeckCompany(customerId: $customerId) {
      name
      email
      phoneNumber
    }
  }
`;
