import React from 'react';

import { ToggleBlock } from '@/components/ToggleBlock';

import { BusinessVerificationSingleResultView } from './BusinessVerificationSingleResultView';
import { BusinessVerificationMultipleResultsView } from './BusinessVerificationMultipleResultsView';
import { Header } from '../Header';
import { IOpenCorporate } from '@/types/openCorporate';
import { IOpenCorporatesComparison, ISeekerCompany } from '@/types/seekerCompany';

interface IProps {
  applicationInfo: Pick<ISeekerCompany, "name" | "streetAddressCity" | "type" | "established">,
  openCorporatesCount: number,
  results: IOpenCorporate[],
  comparison: IOpenCorporatesComparison,
}

export const BusinessVerification = ({ applicationInfo, openCorporatesCount, results, comparison }: IProps) => {

  const renderBusinessVerificationResults = () => {
    if (results.length > 1) {
      return (
        <BusinessVerificationMultipleResultsView businessVerificationResults={results} />
      )
    }
    if (results.length === 1) {
      return (
        <BusinessVerificationSingleResultView
          businessVerificationResult={results[0]}
          applicationInfo={applicationInfo}
          comparison={comparison}
        />
      )
    }
    return <></>
  }

  return (
    <ToggleBlock
      header={
        <Header
          title='Business Verification'
          resultsNumberText={
            <>
              {openCorporatesCount} result(s) found
              {openCorporatesCount > 4
                ? '. Showing top 4 results'
                : ''
              }
            </>
          }
        />
      }
      isAvailableToOpen={!!openCorporatesCount}
    >
      {renderBusinessVerificationResults()}
    </ToggleBlock>
  )};
