import React, { Dispatch, SetStateAction, useContext } from 'react';

import { FetchResult } from '@apollo/react-hooks';

import { Formik } from 'formik';

import { get } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { CurrentUserContext } from '@/providers/CurrentUser';

import { useForm } from './useForm';
import {
  StyledContainer,
  StyledFormContainer,
} from '../styled';
import { ManualCustomerForm } from './form';
import { LoadingSpinner } from '@/components/LoadingSpinner';

interface IProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

export const ManualCustomer = ({ setIsOpen, onSuccess }: IProps) => {
  const currentUserData = useContext(CurrentUserContext);

  const currentUser = get('currentUser', currentUserData);

  if (!currentUser) {
    return <LoadingSpinner />
  }

  const {
    customerValidation,
    customerFields,
    handleSubmit,
  } = useForm(currentUser, setIsOpen, onSuccess);

  return (
    <StyledContainer >
      <StyledFormContainer>
        <Formik onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
          initialValues={customerFields}
          validationSchema={customerValidation}
          validateOnChange
          enableReinitialize
        >
          <ManualCustomerForm />
        </Formik>
      </StyledFormContainer>
    </StyledContainer>
  )
}
