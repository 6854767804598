import styled from "styled-components";

import { Flex } from "../designSystem/Layout";

export const StyledOuterWrapper = styled(Flex).attrs({ justify: 'center', align: 'center' })`
  position: sticky;
  bottom: 1rem;
  width: 100%;
  height: 40px;
`;

interface IStyledScrollArrowInnerWrapperArgs {
  isArrowUp: boolean,
  rightPosition?: string,
}

export const StyledInnerWrapper = styled(Flex).attrs({ justify: 'center', align: 'center' })<IStyledScrollArrowInnerWrapperArgs>`
  position: absolute;
  right: ${({ rightPosition }) => rightPosition || '1rem'};;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  transform: ${({ isArrowUp }) => isArrowUp ? 'rotateX(180deg)' : 'none'};
`;