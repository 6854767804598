import React from 'react';

import { StyledLinkButton } from '@/components/designSystem/Typography';

import { ReactComponent as Edit } from '@/assets/edit_primary.svg';

import { StyledEditButtonWrapper } from './styled';

interface IEditButtonProps {
    onClick?: () => void,
    buttonText?: string
  }

export const EditButton = ({ onClick, buttonText }: IEditButtonProps) => (
  <StyledEditButtonWrapper onClick={onClick}>
    <Edit width={18} height={18} />
    <StyledLinkButton>{buttonText ?? "Edit"}</StyledLinkButton>
  </StyledEditButtonWrapper>
);