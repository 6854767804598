import React, { useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import useOnClickOutside from 'use-onclickoutside';

import { debounce } from 'lodash/fp';

import { ReactComponent as HorizontalDotsIcon } from '@/assets/horizontaldots.svg';

import { DropdownContainer, StyledMenuIconWrapper } from './styled';

export interface IPopperMenuProps {
  content?: JSX.Element | JSX.Element[],
  placement?: string,
  offset?: number[],
}

export const PopperMenuContainer = ({
  content, placement = 'top', offset = [-80, 10],
}: IPopperMenuProps) => {
  const [visible, setVisibility] = useState(false);
  const toggleMenu = debounce(100, () => setVisibility(!visible));
  const closeMenu = debounce(100, () => setVisibility(false));

  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  useOnClickOutside(popperRef, closeMenu);

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset,
          },
        },
      ],
    } as any,
  );

  return (
    <>
      <div ref={referenceRef} onClick={toggleMenu}>
        <StyledMenuIconWrapper>
          <HorizontalDotsIcon />
        </StyledMenuIconWrapper>
      </div>
      <div ref={popperRef} style={styles.popper} {...attributes.popper}>
        <DropdownContainer style={styles.offset} visible={visible}>
          {content}
        </DropdownContainer>
      </div>
    </>
  );
};