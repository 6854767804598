import { ICompany } from '@/types/company';

import { ROLES } from '../constants';

export const getRoles = (company: ICompany) => {
  const roles = [];
  if (company.customerOf.length) {
    roles.push(ROLES.CS);
  }
  if (company.customers.length) {
    roles.push(ROLES.CP);
  }
  if (company.givenReferences.length) {
    roles.push(ROLES.VENDOR);
  }
  if (!roles.length) {
    roles.push(ROLES.CP);
  }
  return roles;
};
