import React, { useState, Dispatch, SetStateAction, useContext } from 'react';

import { FetchResult } from '@apollo/react-hooks';

import { Formik } from 'formik';

import { get, getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { CurrentUserContext } from '@/providers/CurrentUser';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { StyledLink } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';

import { useForm } from './useForm';
import { CustomerIdField } from './CustomerIdField';
import { CreditCustomerForm } from './CreditCustomerForm';
import {
  StyledContainer,
  StyledFormContainer,
  StyledFormInnerContainer,
  StyledForm
} from '../styled';
import { FooterContainer, HelpText } from '../../StyledModal/styled';
import { Button } from '@/components/designSystem/buttons';

interface IProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

export const CreditCustomer = ({ setIsOpen, onSuccess }: IProps) => {
  const currentUserData = useContext(CurrentUserContext);
  const currentCompanyData = useContext(CurrentCompanyContext);
  const currentCompanyLoading = get('loading', currentCompanyData);

  const [isApiDeckLoading, setApiDeckLoading] = useState(false);

  const currentUser = get('currentUser', currentUserData);
  const initialSettings = get('currentCompany.settings', currentCompanyData);
  const companyId = get('currentCompany.id', currentCompanyData);
  const enableApideck =  getOr(false, 'enableApideck', initialSettings);

  if (!currentUser) {
    return <LoadingSpinner />
  }

  const {
    customerValidation,
    customerFields,
    handleSubmit,
  } = useForm(currentUser, initialSettings, setIsOpen, onSuccess);

  return (
    <StyledContainer>
      <StyledFormContainer>
        <Formik
          onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
          initialValues={customerFields}
          validationSchema={customerValidation}
          validateOnChange
          enableReinitialize
        >
          <StyledForm>
            <StyledFormInnerContainer>
              {enableApideck && <CustomerIdField enableApideck={enableApideck} companyId={companyId} setLoading={setApiDeckLoading} /> }
              {isApiDeckLoading ? <LoadingSpinner /> : <CreditCustomerForm />}
            </StyledFormInnerContainer>
            <FooterContainer>
              <Button wide primary loading={currentCompanyLoading} type='submit'>Send Invitation</Button>
              <HelpText color='secondary'><>
                  Send your customer your credit application. If you need to edit your credit application,
                <StyledLink className='bold-link' to='/dashboard/application'> click here.</StyledLink>
              </></HelpText>
            </FooterContainer>
          </StyledForm>
        </Formik>
      </StyledFormContainer>
    </StyledContainer>
  )
};
