import React from 'react';
import styled from 'styled-components';

import { useQuery } from '@apollo/react-hooks';
import { GET_GIVEN_REFERENCES } from '@/graphql/queries/getGivenReferences';

import { getOr } from 'lodash/fp';

import { IVendor } from '@/types/vendor';
import { ICompany } from '@/types/company';

import { StyledLink, H1 } from '@/components/designSystem/Typography';
import { TitleCallout } from '@/components/Callout/TitleCallout';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { VendorReferenceInfo } from '@/components/_oldComponents/VendorReferenceInfo';
import { ReactComponent as ContractIllustration } from '@/assets/illustrations/contract.svg';

const EmptyStateCallout = styled(TitleCallout)`
    margin-bottom: 2rem;
`;
const EmptyState = () => (

  <EmptyStateCallout
    title='Thanks for being a reference!'
    Icon={ContractIllustration}
  >
    Here is where you manage the customers that you’ve been a reference for. If you'd like to start managing your own customer Credit Applications
    <StyledLink className='bold-link' to='/dashboard/customers'> click here.</StyledLink> It's free to get started.
  </EmptyStateCallout>
);

const VendorReferencesDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  & > * {
    margin-bottom: 1rem;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

interface IGetGivenRefencesData {
  currentCompany: ICompany,
}

export const VendorReferencesDashboard = () => {
  const { loading, data } = useQuery<IGetGivenRefencesData>(GET_GIVEN_REFERENCES);
  const givenReferences = getOr([], 'currentCompany.givenReferences', data);

  return (
    <VendorReferencesDashboardContainer>
      <H1>Outgoing Vendor References</H1>
      <EmptyState />
      {
        loading
          ? <LoadingSpinner />
          : givenReferences.map(
            (vendor: IVendor, index: number) => (
              <VendorReferenceInfo
                vendor={vendor}
                targetKey='targetCompany'
                startOpen={index === 0}
                key={vendor.id}
              />
            ))
      }
    </VendorReferencesDashboardContainer>
  );
};
