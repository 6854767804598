import React, { useState } from 'react';

import { take, map, filter, flow } from 'lodash/fp';

import { BodyText, Small } from '@/components/designSystem/Typography';
import { Callout } from '@/components/Callout/Callout';

import { ListContainer, CenteredTextButton } from './styled';

interface IProps {
  errors: Error[],
}

export const ErrorMessage = ({ errors }: IProps) => {
  const [showAll, setShowAll] = useState(false);
  const trucatedLength = 5;

  return (
    <Callout color='pastelRed'>
      <BodyText>
        The following error(s) occured while trying to process the file:
      </BodyText>
      <ListContainer>
        {flow(
          filter(Boolean),
          map(({ row, message }: any, index: number) => {
            const messageText = row ? `Row ${row}: ${message}` : message;
            return (
              <li key={index}>
                <Small>{messageText}</Small>
              </li>
            );
          }),
          take(showAll ? errors.length : trucatedLength),
        )(errors)}
      </ListContainer>
      <>
        {errors.length > trucatedLength ? (
          <CenteredTextButton onClick={() => setShowAll(!showAll)}>
         show {showAll ? 'less' : 'more'}
          </CenteredTextButton>
        ) : null}
      </>
    </Callout>
  );
};
