import React, { useState, Dispatch, SetStateAction } from 'react';
import { StyledModal } from '@/modals/StyledModal';
import { EversignWidget } from './EversignWidget';
import { SignatureThankYouModal } from '@/modals/SignatureThankYouModal';

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: () => void,
  embeddedUrl?: string,
  providerCompanyName: string,
}

export const ManualSignAgreementModal = ({
  isOpen, setIsOpen, onSuccess, embeddedUrl, providerCompanyName
}: IProps) => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const submitted = () => {
    setShowThankYouModal(true);
  };

  return (
    <>
      <StyledModal
        title='Sign Agreement'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        blockClose
      >
        {isOpen ? (
          <EversignWidget
            setIsOpen={setIsOpen}
            onSuccess={submitted}
            embeddedUrl={embeddedUrl}
          />
        ) : null}
      </StyledModal>
      <SignatureThankYouModal
        isOpen={showThankYouModal}
        setIsOpen={setShowThankYouModal}
        providerCompanyName={providerCompanyName}
      />
    </>
  );
};
