import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

export const isValidRoutingNumber = () => Yup.string()
  .matches(/^\d*$/, DEFAULT_ERROR_MESSAGES.NUMBERS)
  .required('No routing number provided.');

export const isValidAccountNumber = () => Yup.string()
  .matches(/^\d*$/, DEFAULT_ERROR_MESSAGES.NUMBERS)
  .required('No account number provided.');

const manualAccountValidation = Yup.object({
  accountCategory: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).nullable(),
  firstname: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  lastname: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  accountType: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  accountNumber: isValidAccountNumber(),
  routingNumber: isValidRoutingNumber(), // TODO validate
});

const manualBankValidation = (currentUserEmail: string) => {
  const contactEmailValidation = Yup
    .string()
    .email(DEFAULT_ERROR_MESSAGES.EMAIL)
    .trim()
    .required(DEFAULT_ERROR_MESSAGES.REQUIRED)
    .notOneOf([currentUserEmail], 'should not include your own email')

  return Yup.object().shape({
    contactEmail: contactEmailValidation,
    contactName: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    contactPhone: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
    contactTitle: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    bankAddress: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    bankName: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    accounts: Yup.array().of(manualAccountValidation),
  })
}

export const financialHealthStepValidation = (
  currentUserEmail: string,
  requireBankInfo: boolean,
) => Yup.object().shape({
  isManualBank: Yup.boolean(),
  ...(!requireBankInfo && { noBankInfo: Yup.boolean() }),
  manualBanks: Yup.array().when(['isManualBank', 'noBankInfo'], {
    is: (isManualBank: boolean, noBankInfo: boolean) => isManualBank && !noBankInfo,
    then: Yup.array().of(manualBankValidation(currentUserEmail))
  }),
})
