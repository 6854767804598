import gql from 'graphql-tag';

export const CREATE_SIGNED_UPLOAD_URL = gql`
  mutation CreateSignedUploadUrl($filename: String!) {
    createSignedUploadUrl(filename: $filename) {
      id
      originalFilename
      path
      uploadUrl
    }
  }
`;
