import React from 'react';

import { useFormikContext } from 'formik';

import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { BodyText } from '@/components/designSystem/Typography';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';

import { IManualCustomerFormValues } from './useForm';
import { StyledDescriptionTextContainer } from './styled';

export const ConvertToManualCustomerForm = () => {
  const { values } = useFormikContext<IManualCustomerFormValues>();

  return (
    <>
      <StyledDescriptionTextContainer>
        <CheckboxField required name='aware'>
          <BodyText>I am aware that this action cannot be undone. I am sure I want to convert "prime" as a manual customer.</BodyText>
        </CheckboxField>
        <BodyText>
          You will be able to update customer information based on PDFs, Word documents, or other documentations your client has provided you.
        </BodyText>
      </StyledDescriptionTextContainer>
      <CheckboxField name='signatureRequiredCreditTerms'>
        <BodyText>Ask your client to digitally sign your Credit Terms and Conditions</BodyText>
      </CheckboxField>
      <CheckboxField name='signatureRequiredPersonalGuarantee' disabled={!values.signatureRequiredCreditTerms}>
        <BodyText>Ask your client to digitally sign your Personal Guarantee</BodyText>
      </CheckboxField>
      <TextAreaField
        label='Custom Message'
        name='customMessage'
        placeholder='Please digitally sign this agreement.'
        disabled={!values.signatureRequiredCreditTerms}
      />
      <FileUploadField name='file' label='Attach Documents' notes='These documents will be part of your contract (if signature required) and shared with your applicant. Only upload documents received from your customer.'/>
    </>
  )
}
