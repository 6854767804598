import React from 'react';
import styled from 'styled-components';

import { BodyText } from '@/components/designSystem/Typography';

const IconLabeledContainer = styled(BodyText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & > svg {
    color: ${({ theme }) => theme.color.text.tertiary};
    margin-right: 0.75rem;
    min-width: 24px;
  }
`;

interface IProps {
  Icon: any,
  children: React.ReactNode,
}

export const IconLabeledComponent = ({ Icon, children }: IProps) => (
  <IconLabeledContainer>
    <Icon />
    {children}
  </IconLabeledContainer>
);
