import React from 'react';

import { get } from 'lodash/fp';

import { StepPreview } from './StepPreview';
import { FormSkeleton } from './FormSkeleton';
import {
  StylePreviewContainer,
  PreviewHeader,
  HeaderItem,
  PreviewLogo,
  StyledLogo,
  PreviewBody,
  PreviewBodyContent,
  PreviewButton,
} from './styled';
import { Flex } from '@/components/designSystem/Layout';

interface IFile {
  fileId: string,
  name: string,
  signedUrl: string,
}

interface IProps {
  backgroundColor: string,
  primaryColor: string,
  secondaryColor: string,
  brandColor: string,
  logoFile: IFile[] | null,
}

export const StylePreview = ({
  backgroundColor,
  primaryColor,
  secondaryColor,
  brandColor,
  logoFile,
}: IProps) => {
  const fileURl = get('[0].signedUrl', logoFile);

  return (
    <StylePreviewContainer color={backgroundColor}>
      <PreviewHeader>
        <HeaderItem>
          {fileURl ? <PreviewLogo src={fileURl} /> : <StyledLogo />}
        </HeaderItem>
        <StepPreview color={brandColor} />
        <HeaderItem />
      </PreviewHeader>
      <PreviewBody>
        <PreviewBodyContent>
          <FormSkeleton />
          <Flex justify='end' align='center'>
            <PreviewButton color={secondaryColor} textColor={primaryColor}>
              Back
            </PreviewButton>
            <PreviewButton color={primaryColor} textColor='white'>
              Next
            </PreviewButton>
          </Flex>
        </PreviewBodyContent>
      </PreviewBody>
    </StylePreviewContainer>
  );
};
