import styled from 'styled-components';

import { BodyText } from '@/components/designSystem/Typography';

import { ReactComponent as Page } from '@/assets/page.svg';
import { ReactComponent as Close } from '@/assets/x_close.svg';
import { FlexColumnItemsCenter, FlexColumnItemsStretch } from '../designSystem/Layout';

export const StyledPage = styled(Page)`
  color: ${(props) => props.theme.color.greyDark};
`;

export const StyledClose = styled(Close)`
  margin-left: auto;
  color: ${(props) => props.theme.color.greyDark};
  opacity: 0.5;
  min-width: 1rem;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

export const FileToUploadContainer = styled(FlexColumnItemsStretch)`
  width: 100%;
`;

export const FileContainer = styled(BodyText)`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;

  text-decoration: none;
  padding: 1rem;
  background: ${(props) => props.theme.color.greyLight};
  border-radius: 6px;
  border: ${(props) => props.theme.color.border.light};

  & > :first-child {
    margin-right: 1rem;
  }
`;

export const Filename = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.text.primary};
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1rem;
`;

export const FileUploadContainer = styled(FlexColumnItemsCenter)`
  & > * {
    margin-bottom: 1rem;
  }
`;