import React, { ReactElement, SVGProps } from 'react';
import { StyledTools } from './styled';

export type ToolsProps = {
  icon?: (props: SVGProps<SVGElement>) => ReactElement;
  divider?: boolean;
  title?: string;
  action?: React.MouseEventHandler<HTMLButtonElement>;
  isActive?: () => boolean;
  disabled?: boolean;
}

export const Tools: React.FC<ToolsProps> = ({
  icon: Icon,
  title,
  action,
  isActive,
  disabled,
}) => (
  <StyledTools
    isActive={!!(isActive && isActive())}
    type='button'
    onClick={action}
    title={title}
    disabled={disabled}
  >
    {Icon && <Icon />}
  </StyledTools>
);
