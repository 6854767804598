import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro-spa';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_FINICITY_CUSTOMER } from '@/graphql/queries/getFinicityCustomer';
import { UPSERT_MANUAL_INSTITUTIONS } from '@/graphql/mutations/destructiveUpsertManualInstitutions';

import { Formik } from 'formik';

import { get, getOr, noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ISettings } from '@/types/settings';

import { VisitorContext } from '@/providers/VisitorContext';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { IChangesRequestedValuesAction } from '../../types';
import { FinancialHealthStepView } from './views';
import { IFinancialHealthValues } from './types';
import { ChangesRequestedValuesContext } from '../../context';
import { financialHealthStepValidation } from './validation';
import { ManualBankView } from './views/ManualBankView';
import { UPDATE_FINANCIAL_HEALTH } from '../../reducer';
import { mapChangesRequestedValuesToManualInstitutionsVariables } from './functions';
import gql from 'graphql-tag';

export const CHANGE_REQUEST_MANUAL_INSTITUTIONS = gql`
mutation changeRequestManualInstitutions($changeRequestId: ID!, $manualInstitutions: ManualInstitutions!, $noBankInfo: Boolean) {
  change_request_manual_institutions(changeRequestId: $changeRequestId, manualInstitutions: $manualInstitutions, noBankInfo: $noBankInfo) {
    customer {
      id
    }
  }
}
`;

interface IProps {
  dispatch: Dispatch<IChangesRequestedValuesAction>,
  companySettings: ISettings,
  automaticBankVerificationEnabled: boolean,
  requireBankInfo: boolean,
  nextStepPath: string | null,
}

export const FinancialHealthStep = ({ dispatch, companySettings, automaticBankVerificationEnabled, requireBankInfo, nextStepPath }: IProps) => {
  const visitorData: ExtendedGetResult | null = useContext(VisitorContext);
  const history = useHistory();
  const { search } = useLocation();
  const firstUpdate = useRef(true);

  const changesRequestedValues = useContext(ChangesRequestedValuesContext);
  const changeRequest = changesRequestedValues?.changeRequest;

  const {
    data: getFinicityCustomerData, refetch: refetchFinicityCustomerData, loading: getFinicityCustomerLoading
  } = useQuery(GET_FINICITY_CUSTOMER);

  const [changeRequestManualInstitutions] = useMutation(CHANGE_REQUEST_MANUAL_INSTITUTIONS);

  const currentUserEmail = get('basicInfo.email', changesRequestedValues);

  const finicityAccounts = getOr([], 'getFinicityCustomer.finicityAccounts', getFinicityCustomerData);
  const isFinicityConnected = finicityAccounts.length;

  const requestBankStatements = get('requestBankStatements', companySettings);

  const submitStep = () => {
    const noBankInfo = changesRequestedValues?.financialHealth.noBankInfo;
    if (!changesRequestedValues?.financialHealth.manualBanks) {
      return noop;
    }

    const manualInstitutions =
      mapChangesRequestedValuesToManualInstitutionsVariables(changesRequestedValues.financialHealth.manualBanks);

    return changeRequestManualInstitutions({
      variables: {
        changeRequestId: changeRequest?.id,
        manualInstitutions,
        noBankInfo
      }
    })
      .then(() => {
        history.push({ pathname: `${changesRequestedValues.basePath}/${nextStepPath}`, search });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  };

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep();
  }, [changesRequestedValues]);

  const dispatchManualBanksValues = (values: IFinancialHealthValues) => {
    dispatch({ type: UPDATE_FINANCIAL_HEALTH, payload: values });
  }

  if (!changesRequestedValues || getFinicityCustomerLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={changesRequestedValues.financialHealth}
      validationSchema={financialHealthStepValidation(currentUserEmail, requireBankInfo)}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchManualBanksValues)}
    >
      <Switch>
        <Route exact path={`${changesRequestedValues.basePath}/bank`}
          render={() => (
            <FinancialHealthStepView
              isFinicityConnected={isFinicityConnected}
              requireBankInfo={requireBankInfo}
              refetchFinicityCustomerData={refetchFinicityCustomerData}
              automaticBankVerificationEnabled={automaticBankVerificationEnabled}
              basePath={changesRequestedValues.basePath}
            />
          )}
        />
        <Route path={`${changesRequestedValues.basePath}/bank/manual`}
          render={() => (
            <ManualBankView
              requireBankInfo={requireBankInfo}
              requestBankStatements={requestBankStatements}
              automaticBankVerificationEnabled={automaticBankVerificationEnabled}
            />
          )}
        />
      </Switch>
    </Formik>
  )
};
