import gql from 'graphql-tag';

export const GET_EQUIFAX_RESULTS = gql`
  query getEquifaxResults($searchParams: EquifaxSearchInput!) {
    getEquifaxResults(searchParams: $searchParams) {
      results {
        address {
          city
          zip
          state
          street
        }
        bin
        business_name
        reliability_code
        number_of_tradelines
      }
    }
  }
`;
