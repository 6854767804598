import { useEffect, useState } from 'react';

import { getOr } from 'lodash/fp';

import { ISettings } from '@/types/settings';

import { ICompanyProfileValues } from './types';

interface IArguments {
  companySettings?: ISettings,
  values: ICompanyProfileValues,
  setFieldValue: (field: string, value: string) => void,
  setFieldTouched: (field: string, isTouched?: boolean | undefined) => void,
  isSubmitting: boolean,
}

export const useShippingBillingAddress = ({ companySettings, values, setFieldValue, setFieldTouched, isSubmitting }: IArguments) => {
  const [sameShippingAddress, setSameShippingAddress] = useState(false);
  const [sameBillingAddress, setSameBillingAddress] = useState(false);
  const enableShippingAddress = getOr(false, 'enableShippingAddress', companySettings);
  const enableBillingAddress = getOr(false, 'enableBillingAddress', companySettings);
  const requireShippingAddress = getOr(false, 'requireShippingAddress', companySettings);
  const requireBillingAddress = getOr(false, 'requireBillingAddress', companySettings);

  // After form submition with all empty fields shipping and billing address fields don't become touched.
  // Maybe the formik issue. This is the fix.
  useEffect(() => {
    if (isSubmitting) {
      if (enableShippingAddress) {
        setFieldTouched(`shippingAddress.postal_code`);
        setFieldTouched(`shippingAddress.address_line_1`);
        setFieldTouched(`shippingAddress.city`);
        setFieldTouched(`shippingAddress.state`);
        setFieldTouched(`shippingAddress.country`);
      }
      if (enableBillingAddress) {
        setFieldTouched(`billingAddress.postal_code`);
        setFieldTouched(`billingAddress.address_line_1`);
        setFieldTouched(`billingAddress.city`);
        setFieldTouched(`billingAddress.state`);
        setFieldTouched(`billingAddress.country`);
      }
    }
  }, [isSubmitting]);

  const fillFieldsWithAddressValues = (parentKey: 'shippingAddress' | 'billingAddress') => {
    setFieldValue(`${parentKey}.postal_code`, values.address.postal_code);
    setFieldValue(`${parentKey}.address_line_1`, values.address.address_line_1);
    setFieldValue(`${parentKey}.city`, values.address.city)
    setFieldValue(`${parentKey}.state`, values.address.state);
    setFieldValue(`${parentKey}.country`, values.address.country);
    setTimeout(() => setFieldTouched(`${parentKey}.postal_code`, true));
    setTimeout(() => setFieldTouched(`${parentKey}.address_line_1`, true));
    setTimeout(() => setFieldTouched(`${parentKey}.city`, true));
    setTimeout(() => setFieldTouched(`${parentKey}.state`, true));
    setTimeout(() => setFieldTouched(`${parentKey}.country`, true));
  };

  const sameShippingAddressClickHandle = () => {
    if (!sameShippingAddress) {
      fillFieldsWithAddressValues('shippingAddress');
    }
    setSameShippingAddress((prev) => !prev);
  };

  const sameBillingAddressClickHandle = () => {
    if (!sameBillingAddress) {
      fillFieldsWithAddressValues('billingAddress');
    }
    setSameBillingAddress((prev) => !prev)
  };

  useEffect(() => {
    if (sameShippingAddress) {
      fillFieldsWithAddressValues('shippingAddress');
    }
    if (sameBillingAddress) {
      fillFieldsWithAddressValues('billingAddress');
    }
  }, [values.address]);

  return {
    enableShippingAddress,
    requireShippingAddress,
    enableBillingAddress,
    requireBillingAddress,
    sameShippingAddress,
    sameBillingAddress,
    sameShippingAddressClickHandle,
    sameBillingAddressClickHandle,
  }
}