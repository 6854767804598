import React from 'react';
import styled from 'styled-components';

import { SubHeading } from './designSystem/Typography';

interface ILabeledComponentProps {
  bottom?: boolean,
}

const LabeledComponentContainer = styled.div<ILabeledComponentProps>`
  display: flex;
  flex-direction: ${({ bottom }) => (bottom ? 'column-reverse' : 'column')};
  align-items: ${({ bottom }) => (bottom ? 'center' : 'flex-start')};
  flex: 1;
`;

const LabeledComponentLabel = styled(SubHeading).attrs({ color: 'secondary' })<ILabeledComponentProps>`
  margin-top: ${({ bottom }) => (bottom ? '0.5rem' : '0')};
`;

interface IProps {
  label: string,
  className?: string,
  children?: React.ReactNode,
  bottom?: boolean,
}

export const LabeledComponent = ({
  label, children, className, bottom,
}: IProps) => (
  <LabeledComponentContainer className={className} bottom={bottom}>
    <LabeledComponentLabel bottom={bottom}>{label}</LabeledComponentLabel>
    {children}
  </LabeledComponentContainer>
);
