import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { FlexColumn, FlexRowSpaceEvenly } from '../components/designSystem/Layout';

const Container = styled(FlexColumn)`
  padding: 2rem;
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const ActionContainer = styled(FlexRowSpaceEvenly)`
  padding: 1.5rem;
  border-top: ${({ theme }) => theme.color.border.light};

  & > * {
    margin-right: 1rem;
  }

  & > :last-child{
    margin-right: 0;
  }
`;

interface IProps {
  isOpen: boolean,
  title: string,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  buttonText: string,
  action: () => void,
  children: React.ReactNode,
  loading?: boolean,
  onSuccess?: () => void,
}

export const OldConfirmActionModal = (props: IProps) => {
  const {
    setIsOpen, buttonText, action, children, loading,
  } = props;

  const handleConfirm = () => {
    action()
    setIsOpen(false);
  }

  return (
    <StyledModal {...props}>
      <Container>
        {children}
      </Container>
      <ActionContainer>
        <StyledButton onClick={() => setIsOpen(false)}>Cancel</StyledButton>
        <StyledButton primary loading={loading} onClick={handleConfirm}>{buttonText}</StyledButton>
      </ActionContainer>
    </StyledModal>
  );
};
