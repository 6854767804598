import React, { useState } from 'react';

import { IBankruptcyCase } from '@/types/bankruptcyCase';

import { BodyText, StyledLinkButton } from '@/components/designSystem/Typography';

interface IProps {
  bankruptcyResult: IBankruptcyCase,
}

export const Bankruptcy = ({ bankruptcyResult }: IProps) => {
  const [showMoreDocketVisible, setShowMoreDocketVisible] = useState(bankruptcyResult.docket && bankruptcyResult.docket.length > 200);

  const showMoreHandle = () => {
    setShowMoreDocketVisible(false);
  }

  const renderDocket = () => {
    if (!bankruptcyResult.docket) {
      return 'N/A';
    }
    if (!showMoreDocketVisible) {
      return bankruptcyResult.docket
    }
    return (
      <>
        {bankruptcyResult.docket.substring(0, 200) + '... '}
        <StyledLinkButton onClick={showMoreHandle}>Show more</StyledLinkButton>
      </>
    )
  }

  return (
    <>
      <BodyText bold>Title: <BodyText>{bankruptcyResult.caseTitle}</BodyText></BodyText>
      <BodyText bold>Case Number: <BodyText>{bankruptcyResult.caseNo}</BodyText></BodyText>
      <BodyText bold>Court Code: <BodyText>{bankruptcyResult.courtCode}</BodyText></BodyText>
      <BodyText bold>Date filed: <BodyText>{bankruptcyResult.dateFiled}</BodyText></BodyText>
      <BodyText bold>Date of last filing: <BodyText>{bankruptcyResult.dateOfLastFiling}</BodyText></BodyText>
      <BodyText bold>Chapter: <BodyText>{bankruptcyResult.chapter}</BodyText></BodyText>
      <BodyText bold>Docket: <BodyText>
        {renderDocket()}
      </BodyText></BodyText>
    </>
  )};
