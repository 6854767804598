import React from 'react';
import { useLocation } from 'react-router-dom';

import { H4, BodyText } from '@/components/designSystem/Typography';

import { StyledNavLink, activeClassName } from './styled';

interface IProps {
    children: React.ReactNode,
    path: string,
  }

export const NavItem = ({ children, path }: IProps) => {
  const { pathname } = useLocation();
  const isActive = path === pathname;

  return (
    <StyledNavLink activeClassName={activeClassName} to={path}>
      {
        isActive
          ? <H4 bold>{children}</H4>
          : <BodyText>{children}</BodyText>
      }
    </StyledNavLink>
  );
};