import { StatusCell, DollarCell, DateCell } from "@/components/DashboardGrid/cells";

export const COLUMNS = [
  {
    dataField: 'company',
    caption: 'Company',
    allowHiding: false,
    allowHeaderFiltering: false,
    minWidth: 160,
    width: '30%',
  },
  {
    dataField: 'status',
    caption: 'Status',
    cellRender: StatusCell,
    minWidth: 160,
  },
  {
    dataField: 'creditLimit',
    caption: 'Credit Limit',
    cellRender: DollarCell,
    allowHeaderFiltering: false,
    alignment: 'left',
    minWidth: 80,
  },
  {
    dataField: 'creditTerms',
    caption: 'Credit Terms',
    allowHeaderFiltering: false,
    minWidth: 100,
  },
  {
    dataField: 'dateApproved',
    caption: 'Approved On',
    dataType: 'date',
    cellRender: DateCell,
    allowHeaderFiltering: false,
    minWidth: 130,
  },
];
