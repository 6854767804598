import { formatPhone } from '@/utils/format';

import { IStakeholder } from '@/types/stakeholder';

export const contactsInitialValues = (stakeholders: IStakeholder[]) => stakeholders.reduce((acc, stakeholder, index) => {
  const { position, name, email, phoneNumber, address, ssnSinNumber, driverLicenseNo, driverLicenseState, dob, isOwner } = stakeholder;
  const phoneExtNumber = phoneNumber?.split('/') || [];

  const contactInitialValues = {
    [`stakeholder${index}-position`]: position || '',
    [`stakeholder${index}-name`]: name || '',
    [`stakeholder${index}-email`]: email || '',
    [`stakeholder${index}-phoneNumber`]: phoneExtNumber[1] ? formatPhone(phoneExtNumber[1]) : formatPhone(phoneExtNumber[0]),
    [`stakeholder${index}-extension`]: phoneExtNumber[1] ? phoneExtNumber[0] : '',
  };

  const ownerInitialValues = {
    [`stakeholder${index}-ssnSinNumber`]: ssnSinNumber ?? '',
    [`stakeholder${index}-driverLicenseNo`]: driverLicenseNo ?? '',
    [`stakeholder${index}-driverLicenseState`]: driverLicenseState ?? '',
    [`stakeholder${index}-dob`]: dob ? new Date(dob) : dob,
    [`stakeholder${index}-addressLine1`]: address?.address_line_1 ?? '',
    [`stakeholder${index}-addressLine2`]: address?.address_line_2 ?? '',
    [`stakeholder${index}-state`]: address?.state ?? '',
    [`stakeholder${index}-postalCode`]: address?.postal_code ?? '',
    [`stakeholder${index}-city`]: address?.city ?? '',
    [`stakeholder${index}-country`]: address?.country ?? '',
  };

  return {
    ...acc,
    ...contactInitialValues,
    ...(isOwner && ownerInitialValues),
  }
}, {});
