import gql from 'graphql-tag';

export const UPDATE_CUSTOM_FIELD_ANSWER = gql`
  mutation UpdateCustomFieldAnswer(
    $customerId: ID!
    $customFieldAnswer: CustomFieldAnswerInput!
  ) {
    update_custom_field_answer(
        customerId: $customerId,
        customFieldAnswer: $customFieldAnswer,
      ) {
        id
        text
      }
  }
`;