import React from 'react';

import { IBankruptcyCase } from '@/types/bankruptcyCase';

import { StyledBankruptciesSingleResultContainer } from './styled';
import { Bankruptcy } from './Bankruptcy';

interface IProps {
  bankruptcyResult: IBankruptcyCase,
}

export const BankruptciesSingleResultView = ({ bankruptcyResult }: IProps) => (
  <StyledBankruptciesSingleResultContainer>
    <Bankruptcy bankruptcyResult={bankruptcyResult} />
  </StyledBankruptciesSingleResultContainer>
);
