import React from 'react';

import { useField, ErrorMessage } from 'formik';

import { FieldLabel } from './FieldLabel';
import { StyledErrorText, StyledFieldContainer, StyledYesNoToggleFieldWrapper } from './styled';
import { CUSTOM_TOGGLE_FIELD_TYPES } from '@/constants';
import { RadioButton } from './RadioButton';
import { FileUploadField } from './FileUploadField';
import { TextField } from './TextField';
import { IForToggle } from '@/types/customField';

interface IYesNoToggleFieldError {
  checked?: string,
  text?: string,
  files?: string,
}

interface IProps {
  name?: string,
  label?: string,
  description?: string,
  required?: boolean,
  yesToggle: IForToggle,
  noToggle: IForToggle,
}

export const YesNoToggleField = ({
  required,
  name = '',
  label,
  yesToggle,
  noToggle
}: IProps) => {
  const [field, meta, helpers] = useField(name);

  const showErrorForRadioButtons = !!(meta.touched && meta.error && (meta.error as IYesNoToggleFieldError).checked);

  const { setValue } = helpers;

  const checked = field.value.checked;

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <RadioButton
        checked={checked === true}
        onClick={() => setValue({ ...field.value, checked: true })}
        label='Yes'
        error={showErrorForRadioButtons}
        name={`${name}-yes`}
      />
      <RadioButton
        checked={checked === false}
        onClick={() => setValue({ ...field.value, checked: false })}
        label='No'
        error={showErrorForRadioButtons}
        name={`${name}-no`}
      />
      {/* Error message for radio buttons */}
      {!checked ? (
        <StyledErrorText>
          { showErrorForRadioButtons ? <ErrorMessage name={`${name}.checked`} /> : false }
        </StyledErrorText>
      ) : false }
      {checked === true && (
        <StyledYesNoToggleFieldWrapper>
          {yesToggle.type === CUSTOM_TOGGLE_FIELD_TYPES.TEXT && (
            <TextField type='text' placeholder={`Enter ${label}`} name={`${name}.text`} description={yesToggle.description} />
          )}
          {yesToggle.type === CUSTOM_TOGGLE_FIELD_TYPES.FILE_UPLOAD && (
            <FileUploadField name={`${name}.files`} description={yesToggle.description} onlyOne={!yesToggle.allowMultipleUploads} />
          )}
        </StyledYesNoToggleFieldWrapper>
      )}
      
      {checked === false && (
        <StyledYesNoToggleFieldWrapper>
          {noToggle.type === CUSTOM_TOGGLE_FIELD_TYPES.TEXT && (
            <TextField type='text' placeholder={`Enter ${label}`} name={`${name}.text`} description={noToggle.description} />
          )}
          {noToggle.type === CUSTOM_TOGGLE_FIELD_TYPES.FILE_UPLOAD && (
            <FileUploadField name={`${name}.files`} description={noToggle.description} onlyOne={!noToggle.allowMultipleUploads} />
          )}
        </StyledYesNoToggleFieldWrapper>
      )}
    </StyledFieldContainer>
  )
};
