import styled from 'styled-components';
import { Form } from 'formik';

import { H4 } from '@/components/designSystem/Typography';
import { FlexColumnItemsStart } from '@/components/designSystem/Layout';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledContainer = styled(FlexColumnItemsStart)`
  padding: 1rem;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

export const StyledNoResultsText = styled(H4)`
  margin-bottom: -1rem;
`;
