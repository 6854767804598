import styled from 'styled-components';

import { ReactComponent as CheckIcon } from '@/assets/check-circle.svg';
import { ReactComponent as XIcon } from '@/assets/x_circle.svg';
import { ReactComponent as WarningIcon } from '@/assets/warning.svg';

export const GreenCheckIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.color.highVizGreen};
`;

export const RedXIcon = styled(XIcon)`
  color: ${({ theme }) => theme.color.highVizRed};
`;

export const YellowWarningIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.color.warning};
`;
