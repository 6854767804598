import gql from 'graphql-tag';

export const GET_CREDIT_SAFE_RESULTS = gql`
  query getCreditSafeResults($searchParams: CreditSafeSearchInput!) {
    getCreditSafeResults(searchParams: $searchParams) {
      companies {
        address {
          city
          postCode
          province
          simpleValue
          street
        }
        country
        fileNo
        groupId
        id
        name
        officeType
        phoneNumbers
        regNo
        safeNo
        significantItems
        status
      }
    }
  }
`;
