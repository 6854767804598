import gql from 'graphql-tag';

export const INVITE_USER = gql`
  mutation InviteUser(
    $name: String!,
    $email: String!,
    $customMessage: String,
    $roleName: String
    $isAdmin: Boolean,
    $creditLimit: Float,
  ) {
    invite_user(
      name: $name,
      email: $email,
      custom_message: $customMessage,
      is_admin: $isAdmin,
      role_name: $roleName,
      credit_limit: $creditLimit
    ) {
      id
    }
  }
`;
