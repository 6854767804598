import React from 'react';

import { get, getOr } from 'lodash/fp';
import { formatAddress, formatPhone } from '@/utils/format';

import { ICustomer } from '@/types/customer';

import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { StyledLink } from '@/components/designSystem/Typography';
import { IconLabeledComponent } from '@/components/_oldComponents/IconLabeledComponent';

import { ReactComponent as WebIcon } from '@/assets/web.svg';
import { ReactComponent as PhoneIcon } from '@/assets/phone.svg';
import { ReactComponent as LocationIcon } from '@/assets/location.svg';

interface IProps {
  customer?: ICustomer,
}

export const ContactInfoSection = ({ customer }: IProps) => {
  const phoneNumber = getOr('--', 'seekerCompany.phoneNumber', customer);
  const website = get('seekerCompany.website', customer);
  const address = getOr('--', 'seekerCompany.address', customer);

  const urlElement = website
    ? <StyledLink to={website}>{website}</StyledLink>
    : '--';

  return (
    <TwoColumnContainer.SecondaryItem title='Contact Information'>
      <IconLabeledComponent Icon={WebIcon}>{urlElement}</IconLabeledComponent>
      <IconLabeledComponent Icon={PhoneIcon}>{formatPhone(phoneNumber, address.country)}</IconLabeledComponent>
      <IconLabeledComponent Icon={LocationIcon}>{formatAddress(address)}</IconLabeledComponent>
    </TwoColumnContainer.SecondaryItem>
  );
};
