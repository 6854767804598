import React from 'react';

import { groupBy, map } from 'lodash/fp';

import { AutomaticBank } from './AutomaticBank';
import { IAutomaticAccountInterface } from '@/types/automaticAccount';

interface IProps {
  accounts: IAutomaticAccountInterface[],
}

export const AutomaticSection = ({ accounts }: IProps) => {
  const institutions = groupBy('lastAccount.institution.id', accounts);

  return (
    <>
      {map((groupedAccounts) => <AutomaticBank key={groupedAccounts[0].id} accounts={groupedAccounts} />, institutions)}
    </>
  );
};
