import styled from 'styled-components';

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2rem 0 2rem;

  & > :first-child {
    border-bottom: ${(props) => props.theme.color.border.light};
    padding-bottom: 2rem;
  }

  & > * {
    margin-bottom: 2rem;
  }
`;

export const ContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 1rem;
  }
`;