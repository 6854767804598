import React, { Dispatch, SetStateAction } from 'react';

import { useMutation } from '@apollo/client';
import { REMIND_VENDOR } from '@/graphql/mutations/remindVendor';

import { get, getOr } from 'lodash/fp';
import { formatCurrency } from '@/utils/money';

import { IVendor } from '@/types/vendor';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Button } from '@/components/designSystem/buttons';
import { ReactComponent as EmailIcon } from '@/assets/email.svg';
import { ICustomer } from '@/types/customer';
import { useVRContact } from './useVRContact';
import { StyledReferenceInfoContainer } from '../styled';
import { StyledButtonsContainer, StyledAutoFitColumnsRow, StyledRow } from '../../../styled';
import { IValues } from './useFields';
import { LabeledValue } from '../../../LabeledValue';
import { IShownVendorCustomFields } from './Reference';
import { UploadedDocuments } from './UploadedDocuments';
import { EmailThreads } from './EmailThreads';
import { EmailActivities } from './EmailActivities';

interface IProps {
  customerData?: ICustomer,
  reference: IVendor,
  fields: IValues,
  refetch: () => void,
  canSeeEditControl: boolean,
  shownVendorCustomFields: IShownVendorCustomFields,
  isEditActive: boolean,
  setEditActive: Dispatch<SetStateAction<boolean>>,
}

export const ReferenceInfoView = ({
  customerData,
  reference,
  refetch,
  fields,
  canSeeEditControl,
  shownVendorCustomFields,
  isEditActive,
  setEditActive,
}: IProps) => {
  const inviteId = get(`sourceCompany.inviteId`, reference);
  const emailThreads = get('emailThreads', reference);

  const [remindVendor] = useMutation(REMIND_VENDOR);
  const sent = getOr(false, `sourceCompany.sent`, reference);
  const {vrCompanyName, vrContactEmail} = useVRContact(reference)
  const remindVendorClickHandle = () => remindVendor({ variables: { referralId: inviteId } })
    .then(() => {
      showToast({
        title: 'Reminder Email Sent',
        description: 'Reminder for Vendor Reference Request have been successfully Sent',
        type: toast.TYPE.SUCCESS,
      });
      refetch();
    });

  const Buttons = (
    <>
      <StyledButtonsContainer>
        <Button primary onClick={() => setEditActive(true)}>Manually Add Reference Information</Button>
      </StyledButtonsContainer>
      {sent ?
        (
          <StyledButtonsContainer>
            <Button
              secondary
              small
              LeftIcon={EmailIcon}
              onClick={remindVendorClickHandle}
            >
            Ask Customer to Remind Vendor
            </Button>
          </StyledButtonsContainer>
        )
        : null
      }
    </>
  )

  return (
    <StyledReferenceInfoContainer>
      <StyledAutoFitColumnsRow>
        <LabeledValue label='Customer Since' value={fields.customerSince || '-'} />
        <LabeledValue label='Credit Limit' value={fields.creditLimit ? formatCurrency(+fields.creditLimit) : '$'} />
        <LabeledValue label='Terms' value={fields.creditTerms || '-'} />
        <LabeledValue label='Average Days To Pay' value={fields.averageDaysToPay || '-'} />
      </StyledAutoFitColumnsRow>
      <StyledAutoFitColumnsRow>
        <LabeledValue label='Last Payment Date' value={fields.lastPaymentOn || '-'} />
        <LabeledValue label='Last Payment Amount' value={fields.lastPaymentAmount ? formatCurrency(+fields.lastPaymentAmount) : '$'} />
        <LabeledValue label='Past Due Balance' value={fields.creditBalancePastDue ? formatCurrency(+fields.creditBalancePastDue) : '$'} />
        <LabeledValue label='High Credit Balance' value={fields.creditBalanceHigh ? formatCurrency(+fields.creditBalanceHigh) : '$'} />
      </StyledAutoFitColumnsRow>
      {Object.values(shownVendorCustomFields).includes(true) ? (
        <StyledAutoFitColumnsRow>
          {shownVendorCustomFields.address ? <LabeledValue label='Address' value={fields.address || '-'} /> : false}
          {shownVendorCustomFields.website ? <LabeledValue label='Website Address' value={fields.websiteAddress || '-'} /> : false}
          {shownVendorCustomFields.accountNumber ? <LabeledValue label='Account Number' value={fields.accountNumber || '-'} /> : false}
          {shownVendorCustomFields.currentBalance
            ? (<LabeledValue label='Current Balance' value={fields.currentBalance ? formatCurrency(fields.currentBalance) : '$'} />)
            : false
          }
        </StyledAutoFitColumnsRow>
      ) : false}
      <StyledRow>
        <LabeledValue label='Notes' value={fields.comments || '-'} />
      </StyledRow>
      <UploadedDocuments reference={reference} isEditActive={isEditActive} refetch={refetch} />
      <EmailThreads emailThreads={emailThreads} reference={reference} refetch={refetch}/>
      { canSeeEditControl ? Buttons : false }
      <EmailActivities customerData={customerData} vrCompanyName={vrCompanyName} vrContactEmail={vrContactEmail} />
    </StyledReferenceInfoContainer>
  )}
