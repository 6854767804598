import React from 'react';
import styled from 'styled-components';

interface AvatarProps {
  name: string;
}

const AvatarWrapper = styled.span<{ colorClass: string; }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  height: 1.75rem;
  width: 1.75rem;
  ${(props) => props.colorClass}
`;

const InitialsText = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
`;

export function InitialAvatar({ name }: AvatarProps) {
  const getInitials = (name: string): string => {
    const names = name.split(' ');
    let initials = '';

    if (names.length === 1) {
      initials = names[0].substring(0, 2).toUpperCase();
    } else if (names.length === 2) {
      initials = names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
    } else if (names.length >= 3) {
      initials = names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
    }

    return initials;
  };

  const initials = getInitials(name);

  const getColorClass = (initials: string): string => {
    const firstChar = initials.charAt(0).toUpperCase();
    const colorClasses: { [key: string]: string } = {
      A: 'background-color: #ef4444; color: #ffffff;', // bg-red-500 text-white
      B: 'background-color: #3b82f6; color: #ffffff;', // bg-blue-500 text-white
      C: 'background-color: #10b981; color: #ffffff;', // bg-green-500 text-white
      D: 'background-color: #f59e0b; color: #000000;', // bg-yellow-500 text-black
      E: 'background-color: #8b5cf6; color: #ffffff;', // bg-purple-500 text-white
      F: 'background-color: #ec4899; color: #ffffff;', // bg-pink-500 text-white
      G: 'background-color: #6366f1; color: #ffffff;', // bg-indigo-500 text-white
      H: 'background-color: #14b8a6; color: #ffffff;', // bg-teal-500 text-white
      I: 'background-color: #f97316; color: #000000;', // bg-orange-500 text-black
      J: 'background-color: #84cc16; color: #000000;', // bg-lime-500 text-black
      K: 'background-color: #06b6d4; color: #000000;', // bg-cyan-500 text-black
      L: 'background-color: #f59e0b; color: #000000;', // bg-amber-500 text-black
      M: 'background-color: #60a5fa; color: #ffffff;', // bg-blue-400 text-white
      N: 'background-color: #7c3aed; color: #ffffff;', // bg-purple-700 text-white
      O: 'background-color: #34d399; color: #000000;', // bg-green-400 text-black
      P: 'background-color: #ea580c; color: #ffffff;', // bg-orange-600 text-white
      Q: 'background-color: #365314; color: #ffffff;', // bg-lime-800 text-white
      R: 'background-color: #b91c1c; color: #ffffff;', // bg-red-700 text-white
      S: 'background-color: #1e3a8a; color: #ffffff;', // bg-blue-700 text-white
      T: 'background-color: #047857; color: #ffffff;', // bg-green-700 text-white
      U: 'background-color: #854d0e; color: #000000;', // bg-yellow-700 text-black
      V: 'background-color: #6b21a8; color: #ffffff;', // bg-purple-700 text-white
      W: 'background-color: #be185d; color: #ffffff;', // bg-pink-700 text-white
      X: 'background-color: #4338ca; color: #ffffff;', // bg-indigo-700 text-white
      Y: 'background-color: #0f766e; color: #ffffff;', // bg-teal-700 text-white
      Z: 'background-color: #c2410c; color: #000000;', // bg-orange-700 text-black
    };

    return colorClasses[firstChar] || 'background-color: #6b7280; color: #ffffff;'; // bg-gray-500 text-white
  };

  const colorClass = getColorClass(initials);

  return (
    <AvatarWrapper colorClass={colorClass}>
      <InitialsText>{initials}</InitialsText>
    </AvatarWrapper>
  );
}
