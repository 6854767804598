import React from 'react';

import { SectionContainer } from '../../../SectionContainer';
import { BusinessVerification } from './BusinessVerification';
import { Bankruptcies } from './Bankruptcies';
import { ICustomer } from '@/types/customer';
import { get, getOr } from 'lodash/fp';
import { IBankruptciesData } from '..';

interface IProps {
  customerData: ICustomer,
  bankruptcies: IBankruptciesData,
}

export const BusinessVerificationSection = ({ customerData, bankruptcies }: IProps) => {
  const businessVerificationResults = getOr([], 'seekerCompany.openCorporates', customerData);
  const openCorporatesCount = getOr([], 'seekerCompany.openCorporatesCount', customerData);
  const businessVerificationComparison = getOr(
    {
      name: -1,
      address: -1,
      established: -1,
    },
    'seekerCompany.openCorporatesComparison',
    customerData
  );
  const name = get('seekerCompany.name', customerData);
  const streetAddressCity = get('seekerCompany.streetAddressCity', customerData);
  const type = get('seekerCompany.type', customerData);
  const established = get('seekerCompany.established', customerData);

  return (
    <SectionContainer
      title='Business and Legal Verification'
    >
      <BusinessVerification
        applicationInfo={{
          name,
          streetAddressCity,
          type,
          established
        }}
        openCorporatesCount={openCorporatesCount}
        results={businessVerificationResults}
        comparison={businessVerificationComparison}
      />
      <Bankruptcies resultsNumber={bankruptcies.count} results={bankruptcies.cases} />
    </SectionContainer>
  )};
