import { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { IS_EQUIFAX_CREDENTIALS_STILL_VALID } from '@/graphql/mutations/generateEquifaxReport';

export const useEquifax = () => {
  const [showEquifaxCredentialsModal, setShowEquifaxCredentialsModal] = useState(false);
  const [showEquifaxSearchModal, setShowEquifaxSearchModal] = useState(false);
  const [getEquifaxCredentialsStillValid, { loading: eqLoading }] = useMutation(IS_EQUIFAX_CREDENTIALS_STILL_VALID);

  const onEquifaxClick = () => {
    setShowEquifaxCredentialsModal(true);

    //ToDO: unblock when implementing full equifax integration
    // getEquifaxCredentialsStillValid()
    //   .then((data) => {
    //     const isEquifaxCredentialsStillValid = getOr(false, 'data.isEquifaxCredentialsStillValid', data);
    //     if (isEquifaxCredentialsStillValid) {
    //       setShowEquifaxSearchModal(true);
    //     } else {
    //       setShowEquifaxCredentialsModal(true);
    //     }
    //   });
  };

  return {
    showEquifaxCredentialsModal,
    setShowEquifaxCredentialsModal,
    showEquifaxSearchModal,
    setShowEquifaxSearchModal,
    eqLoading,
    onEquifaxClick,
  }
}