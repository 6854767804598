import React from 'react';

import { useFormikContext } from 'formik';

import { get } from 'lodash/fp';

import { ISettings } from '@/types/settings';
import { ICustomField } from '@/types/customField';

import { BodyText, H2, H3, H4, Small } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { GoogleAddressField } from '@/components/GoogleAddressField';
import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';
import { TextInput } from '@/components/designSystem/Form/TextInput';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { FlexRowEnd } from '@/components/designSystem/Layout';
import { StyledFormRow } from '@/components/designSystem/Form/styled';

import { ADDITIONAL_QUESTION_DEFAULT_OPTION, COMPANY_TYPES, SORTED_COUNTRIES, YEARS_OPTIONS } from '@/constants';

import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm,
  StyledSubtitleContainer,
  StyledCheckboxWrapper,
  StyledCheckboxWrapperRight,
  StyledNoWebsiteCalloutWrapper
} from '../../styled';
import { setFieldsFromGooglePlace } from './functions';
import { ICompanyProfileValues } from './types';
import { useShippingBillingAddress } from './useShippingBillingAddress';
import { useWebsite } from './useWebsite';
import { useAutoscrollToError } from '../../../useAutoscrollToError';
import { Callout } from '@/components/Callout/Callout';

interface IProps {
  companySettings?: ISettings,
  additionalQuestions: ICustomField[],
}

export const CompanyProfileStepForm = ({ companySettings, additionalQuestions }: IProps) => {
  const { values, setFieldValue, setFieldTouched, errors, touched, isSubmitting } = useFormikContext<ICompanyProfileValues>();

  useAutoscrollToError(errors, isSubmitting);

  const {
    enableShippingAddress,
    requireShippingAddress,
    enableBillingAddress,
    requireBillingAddress,
    sameShippingAddress,
    sameBillingAddress,
    sameShippingAddressClickHandle,
    sameBillingAddressClickHandle,
  } = useShippingBillingAddress({
    companySettings,
    values,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  });

  const {
    noWebsite,
    handleNoWebsiteClick,
    handleWebsiteChange
  } = useWebsite({
    values,
    setFieldValue,
  });

  return (
    <StyledForm>
      <StyledTitleContainer>
        <H2 bold>Company Profile</H2>
        <H4>We need to know a bit more about you and your company</H4>
      </StyledTitleContainer>
      <TextField required type='text' name='name' label='Legal Company Name' placeholder='E.g. Google, Alphabet, Google Inc' />
      <StyledFormRow>
        <TextField type='text' name='otherNames' label="Other Names" description='Are There Any Other Names Or Dba Your Company Goes By?' placeholder='google' />
        <SelectField creatable required name='type' label='Business Type' options={COMPANY_TYPES} />
      </StyledFormRow>
      <GoogleAddressField
        required
        type='text'
        name='address.address_line_1'
        label='Street Address'
        placeholder='123 XYZ st. '
        setFieldsFromGooglePlace={(place) => setFieldsFromGooglePlace(place, 'address', setFieldValue)}
      />
      <TextField type='text' name='address.address_line_2' label='Address Line 2' placeholder='E.g. Apt 1' />
      <StyledFormRow>
        <TextField required type='text' name='address.city' label='City' placeholder='New York' />
        <TextField required type='text' name='address.state' label='State / Province' placeholder='New York' />
      </StyledFormRow>
      <StyledFormRow>
        <TextField required type='text' name='address.postal_code' label='Zip / Postal Code' placeholder='90283' />
        <SelectField required name='address.country' label='Country' options={SORTED_COUNTRIES} />
      </StyledFormRow>
      {enableShippingAddress ? (
        <>
          <StyledSubtitleContainer>
            <H3 bold>Shipping Address</H3>
          </StyledSubtitleContainer>
          <StyledCheckboxWrapper>
            <Checkbox checked={sameShippingAddress} onChange={sameShippingAddressClickHandle}>
              <BodyText bold>Shipping address is same as our main address</BodyText>
            </Checkbox>
          </StyledCheckboxWrapper>
          <GoogleAddressField
            required={requireShippingAddress}
            disabled={sameShippingAddress}
            type='text'
            name='shippingAddress.address_line_1'
            label='Street Address'
            placeholder='123 XYZ st. '
            setFieldsFromGooglePlace={(place) => setFieldsFromGooglePlace(place, 'shippingAddress', setFieldValue)} />
          <TextField type='text' disabled={sameShippingAddress} name='shippingAddress.address_line_2' label='Address Line 2' placeholder='E.g. Apt 1' />
          <StyledFormRow>
            <TextField
              required={requireShippingAddress}
              disabled={sameShippingAddress}
              type='text'
              name='shippingAddress.city'
              label='City'
              placeholder='New York'
            />
            <TextField
              required={requireShippingAddress}
              disabled={sameShippingAddress}
              type='text'
              name='shippingAddress.state'
              label='State / Province'
              placeholder='New York'
            />
          </StyledFormRow>
          <StyledFormRow>
            <TextField
              required={requireShippingAddress}
              disabled={sameShippingAddress}
              type='text'
              name='shippingAddress.postal_code'
              label='Zip / Postal Code'
              placeholder='90283'
            />
            <SelectField
              required={requireShippingAddress}
              disabled={sameShippingAddress}
              name='shippingAddress.country'
              label='Country'
              options={SORTED_COUNTRIES}
            />
          </StyledFormRow>
        </>
      ) : false}
      {enableBillingAddress && (
        <>
          <StyledSubtitleContainer>
            <H3 bold>Billing Address</H3>
          </StyledSubtitleContainer>
          <StyledCheckboxWrapper>
            <Checkbox checked={sameBillingAddress} onChange={sameBillingAddressClickHandle}>
              <BodyText bold>Billing address is same as our main address</BodyText>
            </Checkbox>
          </StyledCheckboxWrapper>
          <GoogleAddressField
            required={requireBillingAddress}
            disabled={sameBillingAddress}
            type='text'
            name='billingAddress.address_line_1'
            label='Street Address'
            placeholder='123 XYZ st. '
            setFieldsFromGooglePlace={(place) => setFieldsFromGooglePlace(place, 'billingAddress', setFieldValue)}
          />
          <TextField type='text' disabled={sameBillingAddress} name='billingAddress.address_line_2' label='Address Line 2' placeholder='E.g. Apt 1' />
          <StyledFormRow>
            <TextField
              required={requireBillingAddress}
              disabled={sameBillingAddress}
              type='text'
              name='billingAddress.city'
              label='City'
              placeholder='New York'
            />
            <TextField
              required={requireBillingAddress}
              disabled={sameBillingAddress}
              type='text'
              name='billingAddress.state'
              label='State / Province'
              placeholder='New York'
            />
          </StyledFormRow>
          <StyledFormRow>
            <TextField
              required={requireBillingAddress}
              disabled={sameBillingAddress}
              type='text'
              name='billingAddress.postal_code'
              label='Zip / Postal Code'
              placeholder='90283'
            />
            <SelectField
              required={requireBillingAddress}
              disabled={sameBillingAddress}
              name='billingAddress.country'
              label='Country'
              options={SORTED_COUNTRIES}
            />
          </StyledFormRow>
        </>
      )}
      <FieldLabel required>Company Website</FieldLabel>
      <TextInput
        required={!noWebsite}
        disabled={noWebsite}
        name='website'
        placeholder={noWebsite ? '' : 'E.g. www.companyname.com'}
        value={values.website}
        handleChange={handleWebsiteChange}
        error={get('website', touched) ? get('website', errors) : ''}
      />
      <StyledCheckboxWrapperRight>
        <Checkbox checked={noWebsite} onChange={handleNoWebsiteClick}>
          <Small color='secondary'>Company does not have a website</Small>
        </Checkbox>
      </StyledCheckboxWrapperRight>
      { noWebsite ? (
        <StyledNoWebsiteCalloutWrapper>
          <Callout color='tagRed'>Include your website to improve your credit score.</Callout>
        </StyledNoWebsiteCalloutWrapper>
      ) : false }
      <StyledFormRow>
        <SelectField required name='established' label='Year Established' options={YEARS_OPTIONS} />
        <PhoneNumberField required name='phoneNumber' label='Main Company Phone' placeholder='Phone number' />
      </StyledFormRow>
      <TextAreaField
        required
        name='description'
        label='Tell Us About Your Business'
        placeholder='Are you a retailer, distributor, manufacturer and in which industry?'
      />
      {additionalQuestions ? additionalQuestions.map((question) => (
        <SelectField
          key={question.id}
          required={question.required}
          name={`additionalQuestions.${question.id}`}
          label={question.label}
          options={question.options?.map((option) => ({ label: option, value: option })).concat([ADDITIONAL_QUESTION_DEFAULT_OPTION])}
        />
      )) : false}
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Next</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )};
