import React from 'react';
import styled from 'styled-components';

import { useFormikContext } from 'formik';
import Yup, { isNewEmail, isValidPassword, DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { get } from 'lodash/fp';

import { H2, H4 } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { FlexColumn } from '@/components/designSystem/Layout';

const Container = styled(FlexColumn)`
  max-width: 1000px;
  width: 50vw;
`;

const TitleContainer = styled(FlexColumn)`
  margin-bottom: 1.5rem;
`;

const UserInviteStep = () => {
  const { values } = useFormikContext();
  const fromName = get('from.name', values);
  const companyName = get('company.name', values);

  return (
    <Container>
      <TitleContainer>
        <H2 bold>{fromName} has invited you to join {companyName}</H2>
        <H4>We need to know who you are to get started!</H4>
      </TitleContainer>
      <TextField required type='text' name='name' label='Full Name' placeholder='Full Name' />
      <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
      <TextField required type='password' name='password' label='Password' placeholder='*****' />
    </Container>
  );
};

const userInviteValidation = Yup.object().shape({
  name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  email: isNewEmail(),
  password: isValidPassword(),

});

const userInviteFields = {
  name: '',
  email: '',
  password: '',
};

export {
  UserInviteStep,
  userInviteValidation,
  userInviteFields,
};
