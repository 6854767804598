import { client } from '@/graphql/client';
import { EMAIL_EXISTS } from '@/graphql/queries/emailExists';
import { CUSTOMER_EMAIL_EXISTS } from '@/graphql/queries/customerEmailExists';
import * as Yup from 'yup';
import { DateSchema } from 'yup';
import 'yup-phone';

import { get } from 'lodash/fp';
import { objectHasSetValue } from '@/utils/object';

export const DEFAULT_ERROR_MESSAGES = {
  REQUIRED: 'This field is required',
  EMAIL: 'Must be a valid email address',
  PHONE: 'Must be a valid phone number',
  EMAIL_IN_USE: 'This email is already in use.',
  EXISTING_CUSTOMER: 'You already have a customer with this email.',
  FUTURE_DATE: 'Date can not be a future date.',
  ONE_LETTER: 'Must contain at least one letter',
  NUMBERS: 'Must contain only numbers',
  SSN: 'This SSN/SIN Number is invalid'
};

export const isValidRoutingNumber = () => Yup.string()
  .matches(/^\d*$/, DEFAULT_ERROR_MESSAGES.NUMBERS)
  .required('No routing number provided.');

export const isValidAccountNumber = () => Yup.string()
  .matches(/^\d*$/, DEFAULT_ERROR_MESSAGES.NUMBERS)
  .required('No account number provided.');

interface IEmailExistResponse {
  data: {
    email_exists: boolean,
  }
}

interface ICustomerEmailExistResponse {
  data: {
    customerEmailExists: boolean,
  }
}

export const isNewEmail = () => Yup.string()
  .email(DEFAULT_ERROR_MESSAGES.EMAIL)
  .trim()
  .required(DEFAULT_ERROR_MESSAGES.REQUIRED)
  .test('checkDuplEmail', 'This email is already in use.', (email) => {
    // Formik doesn't allow us to pass the abortEarly flag to yup
    // This means we have to check email validity twice
    // So we dont spam the server
    const validEmail = Yup.string().email().isValidSync(email);
    if (!validEmail || !email) {
      return true;
    }

    return client.query({
      query: EMAIL_EXISTS,
      variables: { email },
    }).then(({ data }: IEmailExistResponse) => {
      const { email_exists } = data;
      return !email_exists;
    }).catch((e: Error) => {
      console.error('Email exist error', e);
      return false;
    });
  });

export const allowedUserEmail = (currentEmail: string) => Yup.string()
  .email(DEFAULT_ERROR_MESSAGES.EMAIL)
  .trim()
  .required(DEFAULT_ERROR_MESSAGES.REQUIRED)
  .test('CheckSelfInvite', 'You can not invite yourself', (email) => {
    const validEmail = Yup.string().email().isValidSync(email);
    if (!validEmail || !email) {
      return true;
    }

    return currentEmail.toLowerCase() !== email.toLowerCase();
  })
  .test('checkDuplCustomerEmail', DEFAULT_ERROR_MESSAGES.EXISTING_CUSTOMER, (email) => {
    const validEmail = Yup.string().email().isValidSync(email);
    if (!validEmail || !email) {
      return true;
    }

    return client.query({
      query: CUSTOMER_EMAIL_EXISTS,
      variables: { email },
      fetchPolicy: 'no-cache'
    }).then(({ data }: ICustomerEmailExistResponse) => {
      const { customerEmailExists } = data;
      return !customerEmailExists;
    }).catch((e: Error) => {
      console.error('Customer Email exist error', e);
      return false;
    });
  });

export const allowedVendorEmail = (applicantEmailDomain: string, applicantWebsite: string, required?: boolean) => {
  const baseValidation = Yup.string()
    .email(DEFAULT_ERROR_MESSAGES.EMAIL)
    .trim()
    .test('notApplicantEmail', 'Invalid Email: Trade reference email must not include your own domain', (email) => {
      const emailDomain = email?.split('@')[1];
      if (emailDomain === applicantEmailDomain || emailDomain === applicantWebsite) {
        return false
      }
      return true;
    })

  if (required) {
    return baseValidation.required(DEFAULT_ERROR_MESSAGES.REQUIRED)
  }
  return baseValidation

}
  ;

// Note: any changes to the password requirements must also be changed on Auth0
export const isValidPassword = () => Yup.string()
  .required('No password provided.')
  .min(8, 'Password is too short - should be 8 chars minimum.')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
    'Password must have at least 8 characters long, 1 uppercase, 1 lowercase, 1 number and 1 special case character',
  );

Yup.addMethod<DateSchema>(Yup.object as any, 'optional', function (
  isOptional = true,
  defaultValue = undefined,
) {
  return this.transform((value) => {
    // If false is passed, skip the transform
    if (!isOptional) return value;

    // If any child property has a value, skip the transform
    if (value && objectHasSetValue(value)) {
      return value;
    }

    return defaultValue;
  })
  // Remember, since we're dealing with the `object`
  // type, we have to change the default value
    .default(defaultValue);
});

// NOT GENERAL. NEED TO REFACTOR.
Yup.addMethod(Yup.object, 'uniqueVendor', function (message, path) {
  return this.test('uniqueVendor', message, function (values: object) {
    const valuesWithParentKeys = Object.entries(values)
      .map((keyValue) => keyValue[1]
        .map((value: object) => ({ ...value, key: keyValue[0] })
        )
      );

    // NOT GENERAL. NEED TO REFACTOR.
    const requiredVendorsNumber = Object.values(values)[0].length;

    const list = [].concat(...Object.values(valuesWithParentKeys)).filter(x => x[path]);

    const mapper = (x: unknown) => get(path, x);
    const set = [...new Set(list?.map(mapper)) as any];

    const isUnique = list?.length === set.length;
    if (isUnique) {
      return true;
    }

    const parentKey = (list?.find((l, i) => mapper(l) !== set[i]) as any)?.key;

    // NOT GENERAL. NEED TO REFACTOR.
    const idx = parentKey === 'optionalVendors'
      ? list?.findIndex((l, i) => mapper(l) !== set[i]) - requiredVendorsNumber
      : list?.findIndex((l, i) => mapper(l) !== set[i]);

    return this.createError({ path: `${parentKey}[${idx}].${path}`, message });
  });
});

export default Yup;
