import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { getValidationForCustomFields } from '@/utils/customFields';

import { ICustomField } from '@/types/customField';

export const supportingInfoValidation = (
  customFields: ICustomField[],
  requireEinFederalTaxNumber: boolean,
) => Yup.object().shape({
  einFederalTaxNumber: requireEinFederalTaxNumber ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.string(),
  customFields: Yup.object().shape(getValidationForCustomFields(customFields)),
})
