import React from 'react';
import styled from 'styled-components';

import { get, getOr } from 'lodash/fp';

import { IManualBank, IBankStatement } from '@/types/manualBank';

import { BorderedContainer } from '@/containers/BorderedContainer';
import { Contact } from '@/components/_oldComponents/Contact';
import { Label } from '@/components/Label/Label';
import { H2, StyledExternalLink } from '@/components/designSystem/Typography';
import { Column } from '@/components/designSystem/Form/styled';

import { ManualAccount } from './ManualAccount';

import {
  InstitutionTitleContainer,
  InstitutionTitle,
  FinicityInstitutionContainer,
} from './styled';

interface IProps {
  institution: IManualBank,
  accountNotes: string[],
  customerId?: string,
  refetch: () => void,
}

export const ManualInstitution = ({
  institution, accountNotes, customerId, refetch,
}: IProps) => {
  const institutionName = get('institutionName', institution);
  const accounts = get('accounts', institution);
  const bankStatements = get('bankStatements', institution);
  const name = get('contactName', institution);
  const position = get('contactTitle', institution);
  const email = get('contactEmail', institution);
  const phoneNumber = get('contactPhone', institution);
  const extension = getOr('', 'extension', institution);

  const bankContact = {
    name,
    position,
    email,
    phoneNumber,
    extension,
  };

  const StyledColumn = styled(Column)`
  margin-top: 1rem;  
  gap: 0.5rem;
  `;

  return (
    <FinicityInstitutionContainer>
      <InstitutionTitleContainer>
        <InstitutionTitle>{institutionName}</InstitutionTitle>
        <Label>Manual</Label>
      </InstitutionTitleContainer>
      <BorderedContainer><Contact contact={bankContact} /></BorderedContainer>
      {accounts.map((account) => (
        <ManualAccount
          key={account.id}
          account={account}
          accountNotes={accountNotes}
          customerId={customerId}
          refetch={refetch} />
      ))}
      <H2>Recent bank statements</H2>
      <StyledColumn>
        {bankStatements.map((bankStatement: IBankStatement) => <BankStatement key={bankStatement.id} bankStatement={bankStatement} />)}
      </StyledColumn>
    </FinicityInstitutionContainer>
  );
};

interface IBankStatementProps {
  bankStatement: IBankStatement
}

const BankStatement = ({ bankStatement }: IBankStatementProps) => (
  <StyledExternalLink href={bankStatement.signedUrl} target='_blank'>{bankStatement.originalFilename}</StyledExternalLink>
)
