export const initialManualBankAccountFields = {
  firstnameMatched: false,
  lastnameMatched: false,
  verificationStatus: '',
  verificationMessage: '',
  accountCategory: null,
  firstname: '',
  lastname: '',
  accountType: '',
  accountNumber: '',
  routingNumber: '',
}

export const initialManualBankFields = {
  accounts: [initialManualBankAccountFields],
  bankName: '',
  bankAddress: '',
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  contactTitle: '',
};

export const initialValues = {
  manualBanks: [initialManualBankFields],
}