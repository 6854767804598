import React, { Dispatch, SetStateAction, useRef } from 'react';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { useForm } from './useForm';

import { ConvertToManualCustomerForm } from './form';
import { StyledModalForm } from '../StyledModal/StyledModalForm';
import { useScroll } from '../../utils/useScroll';

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  customerId: string,
  refetch: () => void,
}

export const ConvertToManualCustomerModal = ({ isOpen, setIsOpen, customerId, refetch }: IProps) => {
  const {
    customerValidation,
    customerFields,
    handleSubmit,
  } = useForm(customerId, setIsOpen, refetch);

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    isScrollArrowUp,
    handleScroll,
    handleScrollArrowClick,
  } = useScroll(containerRef);

  return (
    <StyledModalForm
      title='Convert To Manual Customer'
      submitButtonText='Convert'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={customerFields}
      validationSchema={customerValidation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      blockClose={true}
      width='80vw'
      maxWidth='1200px'
      height='800px'
      modalRef={containerRef}
      scrollArrow={{ isScrollArrowUp, handleScroll, handleScrollArrowClick }}
    >
      <ConvertToManualCustomerForm/>
    </StyledModalForm>
  )
}
