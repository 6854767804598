import gql from 'graphql-tag';

export const ONBOARD_CREDIT_PROVIDER_INPUT = gql`
  mutation OnboardCreditProvider(
    $onboardData: OnboardCreditProviderInput!
    $redirectTo: String!
  ) {
    onboard_credit_provider(onboard_data: $onboardData, redirectTo: $redirectTo) {
      id
      name
    }
  }
`;
