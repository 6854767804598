import { getOr } from "lodash/fp";
import { formatPhone } from "@/utils/format";

import { IReferralData } from "@/types/referralData";
import { IPendingVendorInvite } from "@/types/pendingVendorInvite";

export const vendorsInitialValues = (refData: IReferralData) => {
  const providerCompany = getOr(null, 'getCompanyById', refData);
  const totalReferencesNumber = getOr(2, 'numTotalReferences', providerCompany);
  const requiredReferencesNumber = getOr(2, 'numRequiredReferences', providerCompany);
  const pendingVendorInvites = getOr([], 'getCustomerAsSeeker.seekerCompany.pendingVendorInvites', refData);

  return {
    requiredVendors: pendingVendorInvites.length
      ? [...pendingVendorInvites.slice(0, requiredReferencesNumber)].map((pendingVendorInvite: IPendingVendorInvite) => ({
        id: pendingVendorInvite.id,
        companyName: pendingVendorInvite.toCompanyName,
        name: pendingVendorInvite.toName,
        phoneNumber: formatPhone(pendingVendorInvite.toPhoneNumber),
        email: pendingVendorInvite.toEmail,
      }))
      : Array(requiredReferencesNumber)
        .fill({
          companyName: '',
          name: '',
          phoneNumber: '',
          email: '',
        }),
    optionalVendors: [...pendingVendorInvites.slice(requiredReferencesNumber, totalReferencesNumber)].length
      ? [...pendingVendorInvites.slice(requiredReferencesNumber, totalReferencesNumber)].map((pendingVendorInvite: IPendingVendorInvite) => ({
        id: pendingVendorInvite.id,
        companyName: pendingVendorInvite.toCompanyName,
        name: pendingVendorInvite.toName,
        phoneNumber: formatPhone(pendingVendorInvite.toPhoneNumber),
        email: pendingVendorInvite.toEmail,
      })).concat(Array(totalReferencesNumber - requiredReferencesNumber - [...pendingVendorInvites.slice(requiredReferencesNumber, totalReferencesNumber)].length)
        .fill({
          companyName: '',
          name: '',
          phoneNumber: '',
          email: '',
        }),)
      : Array(totalReferencesNumber - requiredReferencesNumber)
        .fill({
          companyName: '',
          name: '',
          phoneNumber: '',
          email: '',
        }),
  }
};
