import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import { useMutation } from '@apollo/react-hooks';
import { SET_CREDIT_TERM_OPTIONS } from '@/graphql/mutations/setCreditTermOptions';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';
import { Button } from '@/components/designSystem/buttons';
import { Row } from '@/components/designSystem/Form/styled';

import { ReactComponent as Plus } from '@/assets/plus.svg';
import { ReactComponent as Close } from '@/assets/x_circle.svg';

export const StyledClose = styled(Close)`
  align-self: center;
  min-width: 20px;
  color: ${({ theme }) => theme.color.greyDark};
  &:hover {
    color: ${({ theme }) => theme.color.negative};
  }
`;

interface IFields {
  [key: string]: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: () => void,
  creditTermsOptions: string[],
  setСreditTermsOptionsState: Dispatch<SetStateAction<string[]>>,
}

export const SetCreditTermsOptionsModal = ({ isOpen, setIsOpen, onSuccess, creditTermsOptions, setСreditTermsOptionsState }: IProps) => {
  const [isAdditionalTouched, setIsAdditionalTouched] = useState(false);

  const [setCreditTermOptions] = useMutation(SET_CREDIT_TERM_OPTIONS);

  const removeCreditTermsOption = (index: number) => {
    setСreditTermsOptionsState((prevOptions) => [
      ...prevOptions.slice(0, index),
      ...prevOptions.slice(index + 1)
    ]);
    setIsAdditionalTouched(true);
  };

  const addCreditTermsOption = () => {
    setСreditTermsOptionsState((prevOptions) => [...prevOptions, '']);
    setIsAdditionalTouched(true);
  };

  const handleSubmit = (data: IFields) => {
    setCreditTermOptions({ variables: { creditTerms: Object.values(data) } })
      .then(() => {
        showToast({
          title: 'Credit Terms Updated',
          description: `Successfully updated Credit Terms options`,
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess();
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: `Credit Terms options were not updated`,
          type: toast.TYPE.ERROR,
        });
      });
  };

  return (
    <StyledModalForm
      title='Set Credit Terms Options'
      submitButtonText='Save'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={creditTermsOptions.reduce((acc, option, index) => ({ ...acc, [`Option-${index + 1}`]: option }), {})}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      blockClose={true}
      isAdditionalTouched={isAdditionalTouched}
    >
      {creditTermsOptions.map((field, index) => (
        <Row key={index}>
          <TextField required type='text' name={`Option-${index + 1}`} label={`Option ${index + 1}`} placeholder={`Option ${index + 1}`} />
          <StyledClose onClick={() => removeCreditTermsOption(index)}  />
        </Row>
      ))}
      <Button secondary LeftIcon={Plus} onClick={addCreditTermsOption}>Add Additional Option</Button>
    </StyledModalForm>
  )
};
