import React from 'react';

import { getOr } from 'lodash/fp';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';
import { ISettings } from '@/types/settings';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { EnableRequiredOptions } from '@/components/EnableRequiredOptions';
import { Flex } from '@/components/designSystem/Layout';
import { H4 } from '@/components/designSystem/Typography';

import { Section } from '../Section';
import { StyledEnableRequiredRow, StyledOptionsContainer, StyledOptionsContainerDescription } from '../styled';

interface IProps {
  initialSettings: ISettings,
  refetch: () => void,
}

export const CompanyAddressSection = ({ initialSettings, refetch }: IProps) => {
  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);
  const items = [
    {
      label: 'Shipping Address',
      keys: {
        enabled: 'enable_shipping_address',
        required: 'require_shipping_address',
      },
      enabled: getOr(false, 'enableShippingAddress', initialSettings),
      required: getOr(false, 'requireShippingAddress', initialSettings),
    },
    {
      label: 'Billing Address',
      keys: {
        enabled: 'enable_billing_address',
        required: 'require_billing_address',
      },
      enabled: getOr(false, 'enableBillingAddress', initialSettings),
      required: getOr(false, 'requireBillingAddress', initialSettings),
    },
  ];

  const handleUpdateCompanySettings = (variables: any) => updateCompanySettings({variables: {settings: variables}})
    .then(() => {
      showToast({
        title: 'Success!',
        description: 'Company settings Updated Successfully.',
        type: toast.TYPE.SUCCESS,
      });
      refetch();
    })
    .catch(() => {
      showToast({
        title: 'Error',
        description: 'Company settings Update Failed.',
        type: toast.TYPE.ERROR,
      });
    });

  const handleServiceLocationChange = (variables: any) => {
    updateCompanySettings({variables: {settings: variables}})
    .then(refetch)
    .then(() => {
      showToast({
        title: 'Success!',
        description: 'Company settings Updated Successfully.',
        type: toast.TYPE.SUCCESS,
      });
    })
  }

  return (
    <Section title='Company Address'>
      <Row>
        {/* All these TextField names are fake. We need for formik fields.
        If you need to enable these text fields at some point, you will need to fix names */}
        <TextField type='text' disabled name='address.street' label='Street Address' placeholder='Street Address' />
        <TextField type='text' disabled name='address.line2' label='Address Line 2' placeholder='E.g Apt 7' />
      </Row>
      <Row>
        <TextField type='text' disabled name='address.city' label='City' placeholder='New York' />
        <TextField type='text' disabled name='address.state' label='State' placeholder='New York' />
        <TextField type='text' disabled name='address.postal_code' label='Zip Code' placeholder='90210' />
      </Row>
      <StyledOptionsContainer disableDivider>
        <Flex justify='space-between' align='center'>
          <H4 bold>Additional Addresses</H4>
        </Flex>
        {items.map((item, index) => (
          <StyledEnableRequiredRow key={item.label} first={index === 0}>
            <EnableRequiredOptions
              id={item.label}
              label={item.label}
              keys={item.keys}
              enabled={item.enabled}
              required={item.required}
              handleChange={handleUpdateCompanySettings}
            />
          </StyledEnableRequiredRow>
        ))}
        <StyledOptionsContainerDescription>
        If enabled, Credit Applicant will be asked if their Shipping/Billing address is different than their Primary address. If different, they will be asked to provide the other address(es).
        </StyledOptionsContainerDescription>
      </StyledOptionsContainer>
      <StyledOptionsContainer disableDivider>
        <H4 bold>Service Locations</H4>
        <StyledEnableRequiredRow>
          <EnableRequiredOptions
            label={"Service Locations"}
            keys={{required: "require_service_locations", enabled: "enable_service_locations"}}
            enabled={initialSettings.enableServiceLocations}
            required={initialSettings.requireServiceLocations}
            handleChange={handleServiceLocationChange}
          />
        </StyledEnableRequiredRow>
      </StyledOptionsContainer>
    </Section>
  )};
