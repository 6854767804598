import React from 'react';

import { LabelContainer, LabelText } from './styled';

interface IProps {
  children: React.ReactNode,
  type?: string,
  className?: string,
}

export const Label = ({ children, type, className }: IProps) => (
  <LabelContainer type={type} className={className}>
    <LabelText>{children}</LabelText>
  </LabelContainer>
);
