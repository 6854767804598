import React, { Dispatch, SetStateAction } from 'react';

import { Checkbox } from '@/components/designSystem/Form/Checkbox';
import { BodyText } from '@/components/designSystem/Typography';

import {
  StyledCustomFieldContainer,
  StyledContactTypeFormContainer,
  BodyRequired,
  StyledCustomFieldRow,
  StyledCustomFieldCheckboxes,
} from './styled';
import { useFormikContext } from 'formik';
import { IUpdateCustomFieldFormValues } from './UpdateCustomFieldModal';

interface IProps {
  nameChecked: boolean,
  setNameChecked: Dispatch<SetStateAction<boolean>>,
}

export const ContactTypeForm = ({ nameChecked, setNameChecked }: IProps) => {
  const { values, setFieldValue } = useFormikContext<IUpdateCustomFieldFormValues>();

  return (
    <StyledContactTypeFormContainer>
      <BodyText bold>Details</BodyText>
      <StyledCustomFieldContainer>
        <StyledCustomFieldRow first>
          <div>Name</div>
          <StyledCustomFieldCheckboxes>
            <Checkbox
              checked={nameChecked}
              onChange={() => setNameChecked(!nameChecked)}
            >
              <BodyRequired>Required</BodyRequired>
            </Checkbox>
          </StyledCustomFieldCheckboxes>
        </StyledCustomFieldRow>
        <StyledCustomFieldRow>
          <div>Email</div>
          <StyledCustomFieldCheckboxes>
            <Checkbox
              checked={values.emailRequired}
              onChange={() => setFieldValue('emailRequired', !values.emailRequired)}
            >
              <BodyRequired>Required</BodyRequired>
            </Checkbox>
          </StyledCustomFieldCheckboxes>
        </StyledCustomFieldRow>
        <StyledCustomFieldRow>
          <div>Phone</div>
          <StyledCustomFieldCheckboxes>
            <Checkbox
              checked={values.phoneRequired}
              onChange={() => setFieldValue('phoneRequired', !values.phoneRequired)}
            >
              <BodyRequired>Required</BodyRequired>
            </Checkbox>
          </StyledCustomFieldCheckboxes>
        </StyledCustomFieldRow>
        <StyledCustomFieldRow>
          <div>Position</div>
          <StyledCustomFieldCheckboxes>
            <Checkbox
              checked={values.positionRequired}
              onChange={() => setFieldValue('positionRequired', !values.positionRequired)}
            >
              <BodyRequired>Required</BodyRequired>
            </Checkbox>
          </StyledCustomFieldCheckboxes>
        </StyledCustomFieldRow>
      </StyledCustomFieldContainer>
    </StyledContactTypeFormContainer>
  )};
