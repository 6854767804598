import React, { Fragment, useCallback } from 'react';
import { Tools, ToolsProps } from './Tools';
import { StyledToolBarWrapper, StyledToolDivider } from './styled';

import {
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiAlignLeft,
  RiAlignCenter,
  RiAlignRight,
  RiBold,
  RiItalic,
  RiStrikethrough,
  RiMarkPenLine,
  RiParagraph,
  RiListUnordered,
  RiListOrdered,
  RiListCheck2,
  RiCodeView,
  RiSubscript,
  RiSuperscript,
  RiDoubleQuotesL,
  RiSeparator,
  RiTextWrap,
  RiFormatClear,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiImageFill,
  RiLink,
  RiLinkUnlink,
  RiYoutubeFill
} from 'react-icons/ri';

interface ToolBarProps {
  editor: any;
}

export const ToolBar: React.FC<ToolBarProps> = ({
  editor
}) => {
  const items: ToolsProps[] = [
    // {
    //   icon: RiH1,
    //   title: 'Heading 1',
    //   action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
    //   isActive: () => editor.isActive('heading', { level: 1 }),
    // },
    // {
    //   icon: RiH2,
    //   title: 'Heading 2',
    //   action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    //   isActive: () => editor.isActive('heading', { level: 2 }),
    // },
    // {
    //   icon: RiH3,
    //   title: 'Heading 3',
    //   action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    //   isActive: () => editor.isActive('heading', { level: 3 }),
    // },
    // {
    //   icon: RiH4,
    //   title: 'Heading 4',
    //   action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
    //   isActive: () => editor.isActive('heading', { level: 4 }),
    // },
    // {
    //   icon: RiH5,
    //   title: 'Heading 5',
    //   action: () => editor.chain().focus().toggleHeading({ level: 5 }).run(),
    //   isActive: () => editor.isActive('heading', { level: 5 }),
    // },
    // {
    //   icon: RiH6,
    //   title: 'Heading 6',
    //   action: () => editor.chain().focus().toggleHeading({ level: 6 }).run(),
    //   isActive: () => editor.isActive('heading', { level: 6 }),
    // },
    // {
    //   divider: true
    // },
    // {
    //   icon: RiAlignLeft,
    //   title: 'Align Left',
    //   action: () => editor.chain().focus().setTextAlign('left').run(),
    //   isActive: () => editor.isActive({ textAlign: 'left' }),
    // },
    // {
    //   icon: RiAlignCenter,
    //   title: 'Align Center',
    //   action: () => editor.chain().focus().setTextAlign('center').run(),
    //   isActive: () => editor.isActive({ textAlign: 'center' }),
    // },
    // {
    //   icon: RiAlignRight,
    //   title: 'Align Right',
    //   action: () => editor.chain().focus().setTextAlign('right').run(),
    //   isActive: () => editor.isActive({ textAlign: 'right' }),
    // },
    // {
    //   divider: true
    // },
    {
      icon: RiBold,
      title: 'Bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      icon: RiItalic,
      title: 'Italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      icon: RiStrikethrough,
      title: 'Strike',
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strike'),
    },
    // {
    //   icon: RiMarkPenLine,
    //   title: 'Highlight',
    //   action: () => editor.chain().focus().toggleHighlight().run(),
    //   isActive: () => editor.isActive('highlight'),
    // },
    {
      divider: true
    },
    // {
    //   icon: RiParagraph,
    //   title: 'Paragraph',
    //   action: () => editor.chain().focus().setParagraph().run(),
    //   isActive: () => editor.isActive('paragraph'),
    // },
    {
      icon: RiListUnordered,
      title: 'Bullet List',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList'),
    },
    {
      icon: RiListOrdered,
      title: 'Ordered List',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive('orderedList'),
    },
    // {
    //   icon: RiListCheck2,
    //   title: 'Task List',
    //   action: () => editor.chain().focus().toggleTaskList().run(),
    //   isActive: () => editor.isActive('taskList'),
    // },
    // {
    //   icon: 'code-box-line',
    //   title: 'Code Block',
    //   action: () => editor.chain().focus().toggleCodeBlock().run(),
    //   isActive: () => editor.isActive('codeBlock'),
    // },
    // {
    //   divider: true
    // },
    // {
    //   icon: RiCodeView,
    //   title: 'Code',
    //   action: () => editor.chain().focus().toggleCode().run(),
    //   isActive: () => editor.isActive('code'),
    // },
    // {
    //   icon: RiSubscript,
    //   title: 'Subscript',
    //   action: () => editor.chain().focus().toggleSubscript().run(),
    //   isActive: () => editor.isActive('subscript'),
    // },
    // {
    //   icon: RiSuperscript,
    //   title: 'Superscript',
    //   action: () => editor.chain().focus().toggleSuperscript().run(),
    //   isActive: () => editor.isActive('superscript'),
    // },
    // {
    //   icon: RiDoubleQuotesL,
    //   title: 'Blockquote',
    //   action: () => editor.chain().focus().toggleBlockquote().run(),
    //   isActive: () => editor.isActive('blockquote'),
    // },
    // {
    //   icon: RiSeparator,
    //   title: 'Horizontal Rule',
    //   action: () => editor.chain().focus().setHorizontalRule().run(),
    // },
    // {
    //   divider: true
    // },
    // {
    //   icon: RiTextWrap,
    //   title: 'Hard Break',
    //   action: () => editor.chain().focus().setHardBreak().run(),
    // },
    // {
    //   icon: RiFormatClear,
    //   title: 'Clear Format',
    //   action: () => editor.chain().focus().clearNodes().unsetAllMarks()
    //     .run(),
    // },
    // {
    //   divider: true
    // },
    // {
    //   icon: RiArrowGoBackLine,
    //   title: 'Undo',
    //   action: () => editor.chain().focus().undo().run(),
    // },
    // {
    //   icon: RiArrowGoForwardLine,
    //   title: 'Redo',
    //   action: () => editor.chain().focus().redo().run(),
    // },
    {
      divider: true
    },
    {
      icon: RiImageFill,
      title: 'Image',
      action: () => addImage(),
    },
    {
      icon: RiLink,
      title: 'Link',
      action: () => setLink(),
    },
    {
      icon: RiLinkUnlink,
      title: 'Unlink',
      action: () => editor.chain().focus().unsetLink().run()
    },
    // {
    //   icon: RiYoutubeFill,
    //   title: 'Youtube',
    //   action: () => addYoutubeVideo(),
    // },
  ]

  const addImage = useCallback(() => {
    const url = window.prompt('URL')

    if (url) {
      editor.chain().focus().setImage({ src: url }).run()
    }
  }, [editor])

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  const addYoutubeVideo = useCallback(() => {
    const url = window.prompt('Enter YouTube URL')

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url
      })
    }
  }, [editor])

  if (!editor) {
    return null;
  }

  return (
    <StyledToolBarWrapper>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.divider ? <StyledToolDivider /> : <Tools {...item} />}
        </Fragment>
      ))}
    </StyledToolBarWrapper>
  )
}