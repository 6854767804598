import React from 'react';

import { H1 } from '@/components/designSystem/Typography';

import { IOption, SettingsNav } from './SettingsNav'
import {
  ApplicationContainer,
  Header,
  Main,
} from './styled';

interface IProps {
  children: React.ReactNode,
  options: IOption[],
  title: string,
}

export const TabbedNavContainer = ({ children, options, title }: IProps) => (
  <ApplicationContainer>
    <Header>
      <H1>{title}</H1>
      <SettingsNav options={options} />
    </Header>
    <Main>
      {children}
    </Main>
  </ApplicationContainer>
);
