import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_AGREEMENT_FOR_MANUAL_CUSTOMER } from '@/graphql/queries/getOrCreateAgreement';

import { get } from 'lodash/fp';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { Button, IButtonProps } from '@/components/designSystem/buttons';
import { ManualSignAgreementModal } from '@/modals/ManualSignAgreementModal';
import { LoadingAgreementModal } from '@/modals/LoadingAgreementModal';

interface IProps extends IButtonProps {
  providerCompanyName: string,
  customerId: string,
  userId: string,
  name: string,
  email: string,
  onSuccess: () => void,
}

export const ManualCustomerSignAgreementButton = ({ providerCompanyName, customerId, userId, name, email, onClick, onSuccess, ...rest }: IProps) => {
  const [isGenerating, setGenerating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [embeddedUrl, setEmbeddedUrl] = useState();

  const {
    loading, data, startPolling, stopPolling, refetch
  } = useQuery(
    GET_AGREEMENT_FOR_MANUAL_CUSTOMER,
    {
      onCompleted: () => {
        console.log('POLLED')
      },
      onError: () => {
        showToast({
          title: 'Something went wrong',
          description: 'We could not generate the agreement at this time. Please try again later.',
          type: toast.TYPE.ERROR,
        });
        stopPolling();
        setGenerating(false);
      },
      skip: !isGenerating,
      variables: { customerId, userId, name, email},
      fetchPolicy: 'no-cache'
    },
  );

  useEffect(() => {
    const url = get('getAgreementForManualCustomer.embeddedUrl', data);
    if (url) {
      setIsOpen(true);
      setEmbeddedUrl(url);
      stopPolling();
      setGenerating(false);
    }
  }, [data]);

  const initEversign = async () => {
    setGenerating(true);
    startPolling(1000);
  };

  const refetchAndInit = async () => {
    await refetch();
    initEversign();
  };

  return (
    <>
      <Button loading={loading} onClick={onClick ? onClick : () => refetchAndInit()}  {...rest} />
      <ManualSignAgreementModal isOpen={isOpen} setIsOpen={setIsOpen} embeddedUrl={embeddedUrl} providerCompanyName={providerCompanyName} onSuccess={onSuccess} />
      <LoadingAgreementModal
        isOpen={isGenerating}
      />
    </>
  );
};
