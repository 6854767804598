import React, { ChangeEvent } from 'react';
import { Flex } from '../Layout';
import { H4 } from '../Typography';

import { StyledCheckboxLabel, StyledCheckbox } from './styled';

interface IProps {
  children?: React.ReactNode,
  checked: boolean,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  isError?: boolean,
  disabled?: boolean,
  required?: boolean,
}

export const Checkbox = ({
  children,
  checked,
  onChange,
  isError = false,
  disabled = false,
  required = false
}: IProps) => (
  <StyledCheckboxLabel>
    <StyledCheckbox
      checked={checked}
      onChange={onChange}
      error={isError}
      disabled={disabled}
    />
    <Flex gap='1rem'>
      {required && <H4 color='brand'>*</H4>}
      {children}
    </Flex>
  </StyledCheckboxLabel>
);
