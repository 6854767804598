import gql from 'graphql-tag';

export const GET_DNB_RESULTS = gql`
  query getDnbResults($searchParams: DnbSearchInput!) {
    getDnbResults(searchParams: $searchParams) {
      transaction_id
      results {
        business_name
        duns
        address {
          address_line_1
          city
          postal_code
          state
          country
        }
      }
    }
  }
`;
