import { OPENREPLAY, CLIENT_ENV } from '@/app.config';
import Tracker from '@openreplay/tracker';
import trackerGraphQL from '@openreplay/tracker-graphql/cjs';

let tracker: Tracker;

const initTracker = () => {
  if (!OPENREPLAY.key) return;

  tracker = new Tracker({
    projectKey: OPENREPLAY.key,
    obscureTextEmails: false,
    obscureTextNumbers: false,
    obscureInputEmails: false,
    obscureInputDates: false,
    defaultInputMode: 0,
    __DISABLE_SECURE_MODE: CLIENT_ENV === "development",
  });

  return tracker.use(trackerGraphQL() as any);
}

export const recordGraphQL = initTracker();

export const startTracker = () => {
  if (!tracker) return;

  tracker.start();
};

export const setTrackerUserId = (userId: string) => {
  if (!tracker) return;

  tracker.setUserID(userId);
};
