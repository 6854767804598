import React from 'react';

import { get, getOr } from 'lodash/fp';
import { formatAccountType } from '@/utils/format';

import { IManualBank } from '@/types/manualBank';

import { BorderedContainer } from '@/containers/BorderedContainer';
import { Contact } from '@/components/_oldComponents/Contact';
import { AddManualAccountButton } from '@/modals/AddManualAccountModal/AddManualAccountButton';
import { Label } from '@/components/Label/Label';

import { Account } from './Account';
import {
  InstitutionTitleContainer,
  InstitutionTitle,
  InstitutionContainer,
} from './styled';

interface IProps {
  institution: IManualBank,
  refetch: () => void,
  isFinalized: boolean,
}

export const ManualInstitution = ({ institution, refetch, isFinalized }: IProps) => {
  const institutionId = get('id', institution);
  const institutionName = get('institutionName', institution);
  const accounts = get('accounts', institution);

  const name = get('contactName', institution);
  const position = get('contactTitle', institution);
  const email = get('contactEmail', institution);
  const phoneNumber = get('contactPhone', institution);
  const extension = getOr('', 'extension', institution);

  const bankContact = {
    name,
    position,
    email,
    phoneNumber,
    extension,
  };

  return (
    <InstitutionContainer>
      <InstitutionTitleContainer>
        <InstitutionTitle>{institutionName}</InstitutionTitle>
        <Label>Manual</Label>
      </InstitutionTitleContainer>
      <BorderedContainer><Contact contact={bankContact} /></BorderedContainer>
      {accounts.map(({ accountNumber, accountType, routingNumber }) => (
        <Account key={accountNumber} accountNumber={accountNumber} name={formatAccountType(accountType)} routingNumber={routingNumber} />
      ))}
      {!isFinalized && <AddManualAccountButton institutionName={institutionName} institutionId={institutionId} refetch={refetch} />}
    </InstitutionContainer>
  );
};
