import React from 'react';

import { OnboardLogo } from '@/components/Onboard/OnboardLogo';
import { StepCounter } from '@/components/Onboard/StepCounter';

import {
  StyledOnboardHeader,
  StyledOnboardHeaderWrapper,
} from './styled';

interface IProps {
  overriddenLogo?: JSX.Element | null,
  steps: React.ReactNode,
  children?: React.ReactNode,
}

export const StepHeader = ({
  overriddenLogo = null,
  steps,
  children,
}: IProps) => (
  <StyledOnboardHeaderWrapper>
    <StyledOnboardHeader>
      <OnboardLogo overriddenLogo={overriddenLogo} />
      { steps }
      <OnboardLogo overriddenLogo={overriddenLogo} opacity={0} />
    </StyledOnboardHeader>
    { children }
  </StyledOnboardHeaderWrapper>
);
