import React, { useState } from 'react';

import { H3 } from '@/components/designSystem/Typography';

import {
  ToggleWellContainer,
  ToggleWellHeader,
  InfoContainer,
  ToggleTitleContainer,
  ToggleIcon,
} from './styled';

interface IProps {
  className?: string,
  children?: React.ReactNode,
  title: string,
  startOpen?: boolean,
  TitleInfo?: any,
  TitleAction?: any,
  blockOpen?: boolean,
}

const ToggleWell = ({
  className, children, title, startOpen = false, TitleInfo, TitleAction, blockOpen = false,
}: IProps) => {
  const [open, setOpen] = useState(startOpen);

  return (
    <ToggleWellContainer className={className}>
      <ToggleWellHeader onClick={() => setOpen(!open)}>
        <ToggleTitleContainer>
          {!blockOpen ? (
            <ToggleIcon open={open} />
          ) : false }
          <H3 bold>{title}</H3>
          {TitleAction && <TitleAction />}
        </ToggleTitleContainer>
        {TitleInfo && <TitleInfo open={open} />}
      </ToggleWellHeader>
      {
        open && !blockOpen && (
          <InfoContainer>
            {children}
          </InfoContainer>
        )
      }
    </ToggleWellContainer>
  );
};

export default ToggleWell;
