import React from 'react';
import * as Formik from 'formik';

import { get } from 'lodash/fp';

import { IFieldProps } from '@/types/field';

import { FieldLabel } from './FieldLabel';
import {
  FieldContainer,
  StyledField,
  StyledDescriptionText,
  ErrorText,
} from './styled';

export const Field = (props: IFieldProps) => {
  const {
    label, name, required, description, className, ErrorAction, type, ...otherProps
  } = props;

  const isErrorSkipped = type === 'phone' || type === 'date' || type === 'ssn';

  const { values, errors, touched } = Formik.useFormikContext();

  const isTouched = name ? get(name, touched) : false;
  const hasFieldErrors = name ? get(name, errors) : false;
  const showError = isTouched && hasFieldErrors && !isErrorSkipped;

  return (
    <FieldContainer className={className}>
      {label && <FieldLabel name={name} required={required}>{label}</FieldLabel>}
      <StyledField id={name} type={type} {...otherProps} name={name} />
      {description && <StyledDescriptionText>{description}</StyledDescriptionText>}
      {!isErrorSkipped && (
        <ErrorText>
          { showError && name && <Formik.ErrorMessage name={name} /> }
          { showError && ErrorAction && <ErrorAction errorText={hasFieldErrors} values={values} /> }
        </ErrorText>
      )}
    </FieldContainer>
  );
};
