import React from 'react';

import { H3, BodyText } from '@/components/designSystem/Typography';
import { Callout } from '@/components/Callout/Callout';

interface IAdditionalRefRequiredMessageProps {
  number: number,
}
  
export const AdditionalRefRequiredMessage = ({ number }: IAdditionalRefRequiredMessageProps) => (
  <Callout color='pastelRed'>
    <H3 bold>Additional Vendor References Required</H3>
    <BodyText>Please add {number} more reference{number > 1 ? 's' : ''} </BodyText>
  </Callout>
);