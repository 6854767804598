import React, { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Formik } from 'formik';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { IVRCustomField } from '@/types/vrCustomField';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { VendorOnboardValuesContext } from '../../context';
import { completeStepValidation } from './validation';
import { CompleteStepForm } from './form';
import { IOnboardValuesAction } from '../../types';
import { UPDATE_COMPLETE } from '../../reducer';
import { ICompleteValues } from './types';
import { useMutation } from '@apollo/react-hooks';
import { ONBOARD_VENDOR_REFERENCE_INPUT } from '@/graphql/mutations/onboardVendorReference';
import { submitStep } from '../../functions';
import { VisitorContext } from '@/providers/VisitorContext';

export interface ICompleteStepProps {
  customFields: IVRCustomField[],
  setShowCompletedModal: Dispatch<SetStateAction<boolean>>,
  dispatch: Dispatch<IOnboardValuesAction>,
}

export const CompleteStep = ({ customFields, setShowCompletedModal, dispatch }: ICompleteStepProps) => {
  const visitorData = useContext(VisitorContext);

  const history = useHistory();
  const { pathname, search } = useLocation();

  const onboardValues = useContext(VendorOnboardValuesContext);
  const firstUpdate = useRef(true);

  const [onboardVendor, { loading: onboardLoading }] = useMutation(ONBOARD_VENDOR_REFERENCE_INPUT);

  const dispatchValues = (values: ICompleteValues) => {
    if (!onboardValues) {
      return;
    }

    dispatch({ type: UPDATE_COMPLETE, payload: values })
  }

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep({
      onboardValues,
      pathname,
      search,
      onboardVendor,
      setShowCompletedModal,
      visitorData,
    });
  }, [onboardValues])

  if (!onboardValues || onboardLoading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={onboardValues.complete}
      validationSchema={completeStepValidation(customFields)}
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <CompleteStepForm customFields={customFields} />
    </Formik>
  )
};
