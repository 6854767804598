import React, { Dispatch, SetStateAction } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { INVITE_USER } from '@/graphql/mutations/inviteUser';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

import { InviteUserModalForm } from './form';

const userValidation = (existingEmails: string[]) => Yup.object().shape({
  name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  email: Yup.string()
    .email(DEFAULT_ERROR_MESSAGES.EMAIL)
    .trim()
    .required(DEFAULT_ERROR_MESSAGES.REQUIRED)
    .test('checkExisting', 'CP user already exists for a given email', (email) => {
      if (email) {
        return !existingEmails.includes(email);
      }
      return true
    }),
  customMessage: Yup.string(),
});

const inviteUserInitialValues = {
  name: '',
  email: '',
  customMessage: '',
  roleName: 'analyst',
  isAdmin: false,
  creditLimit: 0,
};

export interface IInviteUserFormValues {
  name: string,
  email: string,
  customMessage: string,
  roleName: string,
  isAdmin: boolean,
  creditLimit: number,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
  existingUsersEmails: string[],
}

export const InviteUserModal = ({ isOpen, setIsOpen, onSuccess, existingUsersEmails }: IProps) => {
  const [inviteUser, { loading }] = useMutation(INVITE_USER);

  const handleSubmit = async ({ name, email, customMessage, isAdmin, creditLimit, roleName }: IInviteUserFormValues) => {
    const variables = {
      name,
      email,
      customMessage,
      isAdmin,
      creditLimit,
      roleName
    };

    inviteUser({ variables })
      .then((response) => {
        showToast({
          title: 'User Invited',
          description: `Sent Invite to ${name}`,
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        showToast({
          title: 'Error!',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
        setIsOpen(false);
      });
  };

  return (
    <StyledModalForm
      title='Invite User'
      submitButtonText='Send Invite'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={inviteUserInitialValues}
      validationSchema={userValidation(existingUsersEmails)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <InviteUserModalForm />
    </StyledModalForm>
  );
};
