import React, { Dispatch, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery} from '@apollo/react-hooks';
import { IS_BUSINESS_EMAIL_DOMAIN } from '@/graphql/queries/emailExists';
import { Formik } from 'formik';

import { getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ISettings } from '@/types/settings';
import { ICustomField } from '@/types/customField';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { OnboardValuesContext } from '../../context';
import { companyProfileStepValidation } from './validation';
import { UPDATE_COMPANY_PROFILE } from '../../reducer';
import { IOnboardValuesAction } from '../../types';
import { CompanyProfileStepForm } from './form';
import { ICompanyProfileValues } from './types';

export interface ICompanyProfileStepProps {
  companySettings?: ISettings,
  additionalQuestions: ICustomField[],
  dispatch: Dispatch<IOnboardValuesAction>,
}

export const CompanyProfileStep = ({ additionalQuestions, companySettings, dispatch }: ICompanyProfileStepProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const onboardValues = useContext(OnboardValuesContext);

  const { data } = useQuery(IS_BUSINESS_EMAIL_DOMAIN, { variables: { email: onboardValues?.basicInfo.email}});
  const isBusinessEmailDomain = getOr('', 'isBusinessEmailDomain', data);

  const dispatchValues = (values: ICompanyProfileValues) => {
    if (!onboardValues) {
      return;
    }

    dispatch({ type: UPDATE_COMPANY_PROFILE, payload: values })
    history.push({ pathname: `${onboardValues.basePath}/stakeholder`, search });
  }

  const requireShippingAddress = getOr(false, 'requireShippingAddress', companySettings);
  const requireBillingAddress = getOr(false, 'requireBillingAddress', companySettings);
  const requireServiceLocations = getOr(false, 'requireServiceLocations', companySettings);

  if (!onboardValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={onboardValues.companyProfile}
      validationSchema={
        companyProfileStepValidation(
          { requireBillingAddress, requireShippingAddress, requireServiceLocations },
          additionalQuestions
        )
      }
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <CompanyProfileStepForm companySettings={companySettings} additionalQuestions={additionalQuestions} canSkipWebsite={!isBusinessEmailDomain} />
    </Formik>
  )};
