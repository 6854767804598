import React, { Dispatch, SetStateAction } from 'react';

import { Formik } from 'formik';
import { initialValues } from './initialValues';
import { bankFormValidation } from './validation';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { AddManualBankInformationForm } from './form';
import { IManualBankFormValues } from './types';
import { ICustomer } from '@/types/customer';
import { useMutation } from '@apollo/react-hooks';
import { UPSERT_MANUAL_INSTITUTIONS_FOR_MANUAL_CUSTOMER } from '@/graphql/mutations/upsertManualInstitutionsForManualCustomer';
import { get } from 'lodash/fp';
import { mapBankValuesToUpsertManualInstitutionsVariables } from './functions';

interface IProps {
  customerData?: ICustomer,
  setAddManualBankActive: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

export const AddManualBankInformation = ({ customerData, setAddManualBankActive, refetch }: IProps) => {
  const customerId = get('id', customerData);
  const [upsertManualInstitutionsForManualCustomer] = useMutation(UPSERT_MANUAL_INSTITUTIONS_FOR_MANUAL_CUSTOMER);

  const handleSubmit = (data: IManualBankFormValues) => {
    const upsertManualInstitutionsVariables = mapBankValuesToUpsertManualInstitutionsVariables(data.manualBanks);

    upsertManualInstitutionsForManualCustomer({
      variables: {
        customerId: customerId,
        manualInstitutions: upsertManualInstitutionsVariables
      }
    })
      .then(() => {
        showToast({
          title: 'Bank Info Added Successfully!',
          description: 'Bank info added successfully for this customer.',
          type: toast.TYPE.SUCCESS,
        });
        setAddManualBankActive(false)
        refetch();
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={bankFormValidation}
      onSubmit={handleSubmit}
    >
      <AddManualBankInformationForm setAddManualBankActive={setAddManualBankActive} />
    </Formik>)
};