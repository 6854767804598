import React, { useContext } from 'react';

import { noop } from 'lodash/fp';

interface IToSeekerSignupArgs {
  companyId: string,
  step?: string,
}

export interface IRedirectContextValue {
    toCustomerPage: (customerId: string, search?: string) => void,
    toApplicationPage: (customerId: string, search?: string) => void,
    toSeekerSignup: (args: IToSeekerSignupArgs, search?: string) => void,
    toLogin: (redirect: string) => void,
    toDashboard: () => void,
    toCPDashboard: () => void,
    toCSDashboard: () => void,
    toVRDashboard: () => void,
  }

const defaultValue = {
  toCustomerPage: noop,
  toApplicationPage: noop,
  toSeekerSignup: noop,
  toLogin: noop,
  toDashboard: noop,
  toCPDashboard: noop,
  toCSDashboard: noop,
  toVRDashboard: noop,
}

export const RedirectContext = React.createContext<IRedirectContextValue>(defaultValue);
export const useRedirect = () => useContext(RedirectContext);