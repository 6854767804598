import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

export const addVendorFormValidation = Yup.object().shape({
  companyName: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  name: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  phoneNumber: Yup.string()
    .test('is-valid-phone', DEFAULT_ERROR_MESSAGES.PHONE, (value) => {
      return !value || Yup.string().phone('US', false).isValidSync(value);
    }),
  email: Yup.string().email(DEFAULT_ERROR_MESSAGES.EMAIL).trim().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});
