import theme from '@/styles/theme';

import { get } from 'lodash/fp';

import { IPublicCompany } from '@/types/publicCompany';

export const useCPTheme = (providerCompany: IPublicCompany | null) => {
  const brand = get('brand', providerCompany);

  const overriddenTheme = brand
    ? theme({
      primary: get('primaryColor', brand),
      secondary: get('secondaryColor', brand),
      background: get('backgroundColor', brand),
      brand: get('brandColor', brand),
    })
    : theme();

  const logoUrl = get('logo.signedUrl', brand);

  return { overriddenTheme, logoUrl, brand };
};
