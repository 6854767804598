import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro-spa';

import { useFormikContext } from 'formik';

import { noop } from 'lodash/fp';

import { VisitorContext } from '@/providers/VisitorContext';

import { ConnectBankButton } from '@/components/ConnectBankButton';
import { H2, BodyText } from '@/components/designSystem/Typography';

import { ReactComponent as BankIcon } from '@/assets/illustrations/bank.svg';

import {
  StyledContainer,
  StyledHeaderImageContainer,
  StyledTextButton,
  StyledBulletsContainer
} from '../styled';
import { Bullet } from '../Bullet';
import { IFinancialHealthValues } from '../types';
import { ChangesRequestedValuesContext } from '../../../context';

interface IProps {
  requireBankInfo: boolean,
  refetchFinicityCustomerData: () => void,
}

export const ConnectBankView = ({ requireBankInfo, refetchFinicityCustomerData }: IProps) => {
  const history = useHistory();
  const { search } = useLocation();

  const changesRequestedValues = useContext(ChangesRequestedValuesContext);

  const { values, setFieldValue, setFieldTouched, errors } = useFormikContext<IFinancialHealthValues>();

  useEffect(() => {
    setFieldValue('isManualBank', false)
  }, []);

  const handleManualBankClick = () => {
    history.push({ pathname: 'bank/manual', search });
  };

  const skipStep = () => {
    if (!changesRequestedValues) {
      return noop;
    }
    history.push({ pathname: `${changesRequestedValues.basePath}/vendors`, search });
  }

  return (
    <StyledContainer>
      <StyledHeaderImageContainer><BankIcon /></StyledHeaderImageContainer>
      <H2 bold>Automatic Bank Account Verification</H2>
      <BodyText>Instantly and securely verify your bank account to get credit approval faster.</BodyText>
      <StyledBulletsContainer>
        <Bullet>We use bank-level security employing strict encryption processes during data transmission and storage.</Bullet>
        <Bullet>Our bank integrator is partnered with more than 10,000 financial institutions.</Bullet>
        <Bullet>We never have access to your password and can never access your bank account.</Bullet>
      </StyledBulletsContainer>
      <ConnectBankButton
        wide
        onSuccess={() => refetchFinicityCustomerData()}
      >
        Verify Bank Account
      </ConnectBankButton>
      <StyledTextButton color='secondary' bold onClick={handleManualBankClick}>Manually Enter Bank Details</StyledTextButton>
      {!requireBankInfo && <StyledTextButton color='secondary' bold onClick={skipStep}>Skip Bank Verification</StyledTextButton>}
    </StyledContainer>
  )
};
