import React from 'react';

import { useField, ErrorMessage } from 'formik';

import { FieldLabel } from '../FieldLabel';
import { ColorPickerInput } from './ColorPickerInput';
import { StyledFieldContainer, StyledErrorText } from '../styled';

interface IProps {
  name: string,
  label: string,
  required?: boolean,
}

export const ColorPickerField = ({ name, label, required = false }: IProps) => {
  const [field, meta, helpers] = useField(name);

  const showError = !!(meta.touched && meta.error);

  const { setValue } = helpers;

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <ColorPickerInput value={field.value} onChange={(value) => setValue(value)} />
      <StyledErrorText>
        { (showError && name) ? <ErrorMessage name={name} /> : false }
      </StyledErrorText>
    </StyledFieldContainer>
  );
};
