import styled from 'styled-components';
import device from '@/styles/device';

import { get } from 'lodash/fp';

import { FlexColumn, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as XIcon } from '@/assets/x_circle.svg';

export const StyledManualBankContainer = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.color.greyLight};
  margin-bottom: 1.5rem;
`;

export const StyledCloseIconWrapper = styled.div`
  cursor: pointer;
`;

export const StyledCalloutAction = styled(FlexRowSpaceBetweenItemsCenter)`
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: ${({ theme, color }) => get(`color.${color}`, theme)};
  gap: 1rem;

  @media ${device.laptop} {
    flex-direction: row;
  }

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.mobileXL} {
    flex-direction: column;
  }
`;

export const StyledCalloutContainer = styled.div`
  margin: 2rem 0;
`;

export const StyledUploadBankStatementsContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledFlexButton = styled(Button)`
  flex-shrink: 1;
`;

export const StyledTitleContainer = styled(FlexColumn)`
  margin-bottom: 1.5rem;
`;

export const StyledClose = styled(XIcon)`
  align-self: center;
  width: 8rem;
  color: ${({ theme }) => theme.color.greyDark};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.negative};
  }
`;