import Yup, { isNewEmail, isValidPassword, DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

export const basicInfoStepValidation = Yup.object().shape({
  name: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).nullable(true).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  position: Yup.string().matches(/^(?=[^A-Za-z]*[A-Za-z])[ -~]*/, DEFAULT_ERROR_MESSAGES.ONE_LETTER).nullable(true).required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  email: isNewEmail(),
  password: isValidPassword(),
  phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
  agreeCheck: Yup.boolean().oneOf([true], 'Field must be checked').required(DEFAULT_ERROR_MESSAGES.REQUIRED),
})
