import styled from 'styled-components';

import { BodyText, Caption } from '@/components/designSystem/Typography';
import { Callout } from '@/components/Callout/Callout';
import { FlexColumn, FlexColumnItemsStretch, FlexRowItemsCenter, FlexRowSpaceBetween } from '@/components/designSystem/Layout';

import { ReactComponent as Page } from '@/assets/page.svg';
import { ReactComponent as Close } from '@/assets/x_close.svg';
import { ReactComponent as Upload } from '@/assets/upload.svg';

export const StyledPage = styled(Page)`
  color: ${(props) => props.theme.color.greyDark};
`;

export const StyledUpload = styled(Upload)`
  color: ${(props) => props.theme.color.greyDark};
`;

export const StyledClose = styled(Close)`
  margin-left: auto;
  color: ${(props) => props.theme.color.greyDark};
  opacity: 0.5;
  min-width: 1rem;

  :hover {
    opacity: 1;
  }
`;

export const FileToUploadContainer = styled(FlexColumnItemsStretch)`
  width: 100%;
  margin-top: 1rem;
`;

export const FileContainer = styled(BodyText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  text-decoration: none;
  padding: 1rem;
  background: ${(props) => props.theme.color.greyLight};
  border-radius: 6px;
  border: ${(props) => props.theme.color.border.light};

  & > :first-child {
    margin-right: 1rem;
  }
`;

export const Filename = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.text.primary};
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1rem;
`;

export const StyledFileField = styled.div`
  display: grid;
  grid-template-columns: 7fr 1fr 1fr;

  & > * {
    margin-right: 1rem;
    padding-right: 1rem;
  }
`;

export const StyledCallout = styled(Callout)`
  margin-top: 1rem;
`;

export const StyledFilesContainer = styled.div`
  margin-top: 1rem;

  & > * {
    margin-bottom: 1rem;
  }
`;

export const StyledDocumentsContainer = styled(FlexRowSpaceBetween)`
  width: 100%;
  position: relative;
`;

export const StyledEmailCommunicationsWrapper = styled.div`
  margin-top: 0.6rem;
`;

export const CaptionWithIcon = styled(Caption)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const StyledIconWrapper = styled.div`
  margin-right: .25rem;
`

// EmailThread

export const StyledOptionIcon = styled(FlexRowItemsCenter)`
  cursor: pointer;
`;

export const StyledSettingsContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.color.white};
  border: ${(props) => props.theme.color.border.light};
  border-radius: 6px;
`;

export const StyledMenuOption = styled(FlexRowItemsCenter)`
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.greyLight};
  };
`;

export const StyledAddVendorsForm = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.greyLight};
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledReferenceInfoContainer = styled.div`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.color.white};
`;