import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { getOr, flow, map } from 'lodash/fp';

import { CREDIT_SAFE_COUNTRIES, PROVINCES_OR_STATES } from '@/constants';
import { ILabelValue } from '@/types/field';

export const searchValidation = Yup.object().shape({
  name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  city: Yup.string(),
  province: Yup.string(),
  country: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

export const getValidCountry = (country: string) => {
  const creditSafeCountries = Object.keys(CREDIT_SAFE_COUNTRIES);
  if (creditSafeCountries.includes(country)) {
    return country;
  }
  return 'US';
};

export const getValidProvince = (province: string, country: string) => {
  const countryProvinces = getOr([], country, PROVINCES_OR_STATES);
  const creditSafeProvinceCodes = countryProvinces.map((pr: ILabelValue) => pr.value);

  if (creditSafeProvinceCodes.includes(province)) {
    return province;
  }
  return '';
};
