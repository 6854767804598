import { formatPhoneNumberForRequest } from '@/utils/format';

import { IAddressComponent, IGooglePlace } from '@/types/googlePlace';

import { IChangesRequestedValues } from '../../types';

const mapGooglePlaceToValues = (addressComponents: IAddressComponent[]) => ({
  state: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_1'))?.long_name ||
    addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_2'))?.long_name || '',
  city: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('locality'))?.long_name ||
    addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_town'))?.long_name || '',
  postalCode: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('postal_code'))?.long_name || '',
  country: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('country'))?.short_name || null,
  streetNumber: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('street_number'))?.long_name || '',
  street: addressComponents.find((addressElement: IAddressComponent) => addressElement.types.includes('route'))?.long_name || '',
});

export const setFieldsFromGooglePlace = (place: IGooglePlace, parentKey: string, setFieldValue: (field: string, value: string | null) => void) => {
  if (place.address_components) {
    const addressValues = mapGooglePlaceToValues(place.address_components);
    setFieldValue(`${parentKey}.address.postal_code`, addressValues.postalCode);
    setFieldValue(
      `${parentKey}.address.address_line_1`,
      `${addressValues.streetNumber}${addressValues.street && ' '}${addressValues.street}`
    );
    setFieldValue(`${parentKey}.address.city`, addressValues.city)
    setFieldValue(`${parentKey}.address.state`, addressValues.state);
    setFieldValue(`${parentKey}.address.country`, addressValues.country);
  }
};

export const mapChangesRequestedValuesToStakeholders = (changesRequestedValues: IChangesRequestedValues) => {

  const {
    companyContacts
  } = changesRequestedValues;

  return [
    ...companyContacts.owners.map((owner) => ({
      ...owner,
      ...(owner.address && { address: JSON.stringify(owner.address) }),
      ...(owner.dob && { dob: new Date(owner.dob).toISOString() }),
      phoneNumber: formatPhoneNumberForRequest(owner.phoneNumber),
      ownershipPercentage: +owner.ownershipPercentage,
      isOwner: true,
    })),
    ...(companyContacts.additionalContacts
      ? companyContacts.additionalContacts
      // Remove empty contacts
        .filter((contact) => !Object.values(contact).every(x => x === ''))
        .map((additionalContact) => ({
          name: additionalContact.name,
          email: additionalContact.email,
          extension: additionalContact.extension,
          phoneNumber: formatPhoneNumberForRequest(additionalContact.phoneNumber),
          position: additionalContact.position,
        }))
      : []
    ),
    ...(Object.keys(companyContacts.customContacts).length
      ? Object.keys(companyContacts.customContacts).map((customContactFieldId) => ({
        ...companyContacts.customContacts[customContactFieldId],
        customFieldId: customContactFieldId,
        phoneNumber: formatPhoneNumberForRequest(companyContacts.customContacts[customContactFieldId].phoneNumber ?? '')
      }))
      : []
    )
  ]
}
