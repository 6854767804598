import React from 'react';
import { Vault } from '@apideck/react-vault';
import '@/assets/styles.css';

import { useQuery } from '@apollo/react-hooks';
import { GET_APIDECK_TOKEN } from '@/graphql/queries/getApiDeckToken';

import { get } from 'lodash/fp';

import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';
import { StyledSalesforceLogo } from './styled';

interface IProps {
  companyId: string
}

export const ApiDeckSettings = ({companyId}: IProps) => {
  const {data} = useQuery(GET_APIDECK_TOKEN, { variables: {companyId}});
  const token = get('getApiDeckToken.sessionToken', data);

  return (
    <Flex justify='space-between' align='center'>
      <StyledSalesforceLogo />
      <Vault token={token} trigger={<Button primary small>Connect</Button>} />
    </Flex>
  );
}