import React from 'react';

import { getOr, concat, map, flow, orderBy } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IPendingVendorInvite } from '@/types/pendingVendorInvite';

import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';

import { VendorReferencesList } from './VendorReferencesList';

const convertReferralToVendor = ({
  id,
  toCompanyName,
  toName,
  toEmail,
  toPosition,
  toPhoneNumber,
  sent,
  updatedAt,
}: IPendingVendorInvite) => ({
  sourceCompany: {
    name: toCompanyName,
    sent,
    inviteId: parseInt(id, 10),
    stakeholders: [{
      name: toName,
      email: toEmail,
      phoneNumber: toPhoneNumber,
      position: toPosition
    }],
    updatedAt,
  },
});

const getAllReferences = (customer: ICustomer) => {
  const receivedReferences = getOr([], 'seekerCompany.receivedReferences', customer);
  const sortedReceivedReferences = orderBy('sourceUser.company', 'asc', receivedReferences);
  const pendingReferences = flow(
    getOr([], 'seekerCompany.pendingVendorInvites'),
    map(convertReferralToVendor),
  )(customer as any);
  const sortedPendingReferences = orderBy('name', 'asc', pendingReferences);
  return concat(sortedReceivedReferences)(sortedPendingReferences);
};

interface IProps {
  customer?: ICustomer,
  children?: any,
}

export const VendorReferencesSection = ({
  customer, children
}: IProps) => {
  const receivedReferences = customer ? getAllReferences(customer) : [];
  const numRequiredReferences = getOr(0, 'providerCompany.numRequiredReferences', customer);

  return (
    <TwoColumnContainer.MainItem title='Vendor References'>
      <VendorReferencesList receivedReferences={receivedReferences} />
      {(children && children({ receivedReferences, numRequiredReferences }))}
    </TwoColumnContainer.MainItem>
  );
};
