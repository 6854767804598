import React, { Dispatch, SetStateAction } from 'react';

import { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { ADD_MANUAL_ACCOUNT } from '@/graphql/mutations/addManualAccount';

import Yup, { DEFAULT_ERROR_MESSAGES, isValidRoutingNumber, isValidAccountNumber } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { MANUAL_ACCOUNT_TYPES } from '@/constants';

import { SelectField } from '@/components/designSystem/Form/SelectField';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';

const validation = Yup.object().shape({
  firstname: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  lastname: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  routingNumber: isValidRoutingNumber(), // TODO validate
  accountNumber: isValidAccountNumber(),
  accountType: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
  institutionName: string,
  institutionId: string,
}

interface IFields {
  isBusinessAccount: boolean,
  firstname: string,
  lastname: string,
  accountNumber: string,
  routingNumber: string,
  accountType: string,
}

export const AddManualAccountModal = ({
  isOpen, setIsOpen, onSuccess, institutionName, institutionId,
}: IProps) => {
  const [addManualAccount, { loading }] = useMutation(ADD_MANUAL_ACCOUNT);

  const handleSubmit = async ({ isBusinessAccount, firstname, lastname, accountNumber, routingNumber, accountType }: IFields) => {
    const manualAccountAttrs = {
      institutionId,
      isBusinessAccount,
      firstname,
      lastname,
      accountNumber,
      routingNumber,
      accountType,
    };
    addManualAccount({ variables: { manualAccountAttrs } })
      .then((response) => {
        setIsOpen(false);
        onSuccess && onSuccess(response);
      });
  };

  const fields = {
    isBusinessAccount: false,
    firstname: '',
    lastname: '',
    accountType: '',
    accountNumber: '',
    routingNumber: '',
  };

  return (
    <StyledModalForm
      title={`Add account to ${institutionName}`}
      submitButtonText='Add'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <TextField required type='text' name='firstname' label="Account Holder's First Name" placeholder='John' />
      <TextField required type='text' name='lastname' label="Account Holder's Last Name" placeholder='Smith' />
      <SelectField required name='accountType' label='Account Type' options={MANUAL_ACCOUNT_TYPES} />
      <TextField required type='text' name='routingNumber' label='Routing/Transit Number' placeholder='e.g. 123456789' />
      <TextField required type='text' name='accountNumber' label='Account Number' placeholder='e.g. 000123456789' />
    </StyledModalForm>
  );
};
