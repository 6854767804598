import React, { useState } from 'react';
import styled from 'styled-components';

import { noop, get } from 'lodash/fp';
import { useMutation } from '@apollo/react-hooks';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { IUploadedFile } from '@/types/file';
import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { PDFDocumentModal } from '@/components/PDFDocumentCard/PDFDocumentModal';
import { Card } from '@/components/designSystem/cards/Card';
import { Flex, FlexRowSpaceBetween } from '@/components/designSystem/Layout';
import { PopperMenu } from '@/components/PopperMenu';
import { Formik, Form } from 'formik';
import { TextField } from '@/components/designSystem/Form/TextField';
import { UPDATE_STORED_FILE_NAME } from '@/graphql/mutations/updateStoredFileName';
import { ReactComponent as Filedownload } from '@/assets/filedownload.svg';
import { ReactComponent as Trash } from '@/assets/trash_gray.svg';
import { ReactComponent as FileEdit } from '@/assets/edit_gray.svg';
import { OldConfirmActionModal } from '@/modals/OldConfirmActionModal';
import { formatDateToLongDateString } from '@/utils/date';

export const StyledPreviewButtonWrapper = styled.div`
  margin-top: 1rem;
`;

export interface IDocument {
  title: string,
  content: string,
  file: IUploadedFile,
  thumbnail?: string
}

interface IValues {
  filename?: string,
}

export const Document = ({title, content, file}: IDocument) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card>
      <BodyText>{content}</BodyText>
      <StyledPreviewButtonWrapper>
        <Button onClick={() => setIsOpen(true)} secondary>Preview</Button>
      </StyledPreviewButtonWrapper>
      <PDFDocumentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        file={file}
        title={title}
      />
    </Card>
  )
};

interface IDocumentRowProps {
  document: IDocument,
  refetch: () => void,
  onDelete: (file: IUploadedFile) => void,
  enableDelete?: boolean
}

const StyledRowContainer = styled(Flex)`
  background: ${({ theme }) => theme.color.greyLight};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  align-items: center;
`;

const StyledRow = styled(Flex).attrs({ direction: 'row', align: 'center' })`
  padding: 0 0.5rem; 
  width: 100%; 
  cursor: pointer;
  gap: 1rem;
`;

const FileField = styled(FlexRowSpaceBetween)`
  width: 100%;
  gap: 1rem;
`;

export const DocumentRow = ({document, refetch, onDelete, enableDelete = true}: IDocumentRowProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setConfirm] = useState(false);
  const [updateStoredFileNameMutation] = useMutation(UPDATE_STORED_FILE_NAME);
  const fields = { filename: document.file.currentFilename ? document.file.currentFilename : document.file.originalFilename };
  const onViewClick = () => setIsOpen(!isOpen);
  const { file, title, thumbnail } = document;

  const renameInternalDocument = (values: IValues) => {
    updateStoredFileNameMutation({ variables: { id: file.id, filename: values.filename } })
      .then(() => {
        setShowEdit(false);
        showToast({
          title: 'Document Renamed',
          description: 'Your document has been successfully renamed',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        refetch();
      });
  };
  return (
    <StyledRowContainer>
      {!showEdit ?
        (
          <StyledRow onClick={onViewClick}>
            <div style={{width: "250px", height: "auto"}}>{thumbnail && <img src={thumbnail} alt={file.originalFilename} />}</div>
            <div style={{width: "100%"}}>{file.currentFilename || file.originalFilename}</div>
            <div style={{width: "100%"}}>{get('author.name', file)}</div>
            <div style={{width: "100%"}}>{file.insertedAt && formatDateToLongDateString(file.insertedAt)}</div>
          </StyledRow>
        ) :
        (
          <div style={{width: "100%"}}>
            <Formik onSubmit={noop} initialValues={fields} enableReinitialize>
              {
                ({ values }) => (
                  <Form>
                    <FileField>
                      <TextField required name='filename' type='text' />
                      <Button secondary onClick={() => setShowEdit(false)}>Cancel</Button>
                      <Button primary onClick={() => renameInternalDocument(values) }>Save</Button>
                    </FileField>
                  </Form>
                )
              }
            </Formik>
          </div>
        )
      }
      <PopperMenu items={[
        {
          name: 'Download',
          icon: <Filedownload width='16' height='16' fill='grey' />,
          onClick: (e: Event) => {
            e.preventDefault();
            window.open(file.signedUrl,'_blank',);
          }
        },
        {
          name: 'Edit',
          icon: <FileEdit width='16' height='16' />,
          onClick: () => setShowEdit(true),
        },
        ...(enableDelete ? [{
          name: 'Delete',
          icon: <Trash width='16' height='16' />,
          onClick: () => setConfirm(true),
        }] : []),
      ]} />
      <PDFDocumentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        file={file}
        title={title || file.originalFilename || ""}
      />

      <OldConfirmActionModal
        title={`Delete Document`}
        buttonText='Delete'
        isOpen={showConfirm}
        setIsOpen={setConfirm}
        action={() => onDelete(file)}
      >
        <BodyText bold>Are you sure you want to delete this document?</BodyText>
      </OldConfirmActionModal>
    </StyledRowContainer>
  )
}
