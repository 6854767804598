import React, { Dispatch, SetStateAction, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as DownloadIcon } from '@/assets/download.svg';

import { ModalContainer, StyledModalText } from './styled';
import { getFileFormat } from '@/utils/getFileFormat';

interface IFile {
  id?: string,
  path?: string,
  originalFilename?: string,
  signedUrl: string,
  name?: string,
  type?: string,
}

export interface IPDFDocumentModalProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  file: IFile,
  title: string,
}

export const PDFDocumentModal = ({
  isOpen,
  setIsOpen,
  file,
  title,
}: IPDFDocumentModalProps) => {
  const { path, signedUrl } = file;
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: { numPages: number }) => {
    setNumPages(nextNumPages);
  };

  return (
    <StyledModal
      title={title || path}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      maxWidth='800px'
      maxHeight='90vh'
      blockClose
      headerAction={(
        <Button
          small
          onClick={(e: MouseEvent) => {
            window.open(
              signedUrl,
              '_blank',
            );
            e.preventDefault();
          }}
          LeftIcon={DownloadIcon}
        >
          Download
        </Button>
      )}
    >
      {file.originalFilename && getFileFormat(file.originalFilename) !== 'pdf' ? (
        <StyledModalText>You can not view this file online. Please Download to view.</StyledModalText>
      ) : (
        <ModalContainer>
          <Document file={signedUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} width={800} />
            ))}
          </Document>
        </ModalContainer> )
      }
    </StyledModal>
  );
};
