import React, { Dispatch, SetStateAction, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { useMutation } from '@apollo/react-hooks';
import { UPDATE_VENDOR_REFERENCE } from '@/graphql/mutations/updateVendorReference';

import { get } from 'lodash/fp';
import { getFileFormat } from '@/utils/getFileFormat';
import {
  formatDateToDateTimeString,
} from '@/utils/date';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { IAttachment } from '@/types/attachment';
import { IVendor } from '@/types/vendor';
import { IEmailThread } from '@/types/emailThread';

import { CREDIT_TERMS } from '@/constants';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { DatePickerField } from '@/components/designSystem/Form/DatePickerField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { Flex } from '@/components/designSystem/Layout';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';
import { BodyText, StyledLinkButtonUnderline } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';

import { AttachmentDetails } from './AttachmentDetails';
import {
  StyledDisabledLinkButton,
  StyledModalContainer,
  StyledControls,
  StyledSubject,
  StyledSubjectDetails,
  StyledSubjectTitle,
  StyledSentAtDetails,
  StyledEmailDetails,
  StyledEmailText,
  StyledHr,
  StyledAttachmentHeading,
  StyledDownloadContainer
} from './styled';

interface IValues {
  customerSince: string,
  lastPaymentOn: string,
  lastPaymentAmount: string,
  creditBalancePastDue: string,
  creditLimit: string,
  creditBalanceHigh: string,
  creditTerms: string,
  averageDaysToPay: string,
  comments: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  vendor: IVendor,
  attachment?: IAttachment,
  emailThreads?: Array<IEmailThread>,
  refetch: () => void,
}

const parseEmailText = (text: string) => text.split('\r\n').map((line) => <div key={line}><BodyText>{line}</BodyText></div>)

export const VendorReferenceModal = ({
  isOpen, setIsOpen, vendor, attachment, emailThreads, refetch,
}: IProps) => {
  const [numPagesState, setNumPagesState] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [emailIndex, setEmailIndex] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPagesState(numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousEmail = () => {
    setEmailIndex(emailIndex !== 0 ? emailIndex - 1 : emailIndex);
  };
  const nextEmail = () => {
    setEmailIndex(emailThreads && emailIndex !== emailThreads.length - 1 ? emailIndex + 1 : emailIndex);
  };

  const previousPage = () => pageNumber > 1 ? changePage(-1) : null;
  const nextPage = () => numPagesState && (pageNumber < numPagesState) ? changePage(1) : null;

  const [updateReference] = useMutation(UPDATE_VENDOR_REFERENCE);

  const referenceId = get('id', vendor);
  const customerSince = get('customerSince', vendor);
  const lastPaymentOn = get('lastPaymentOn', vendor);
  const averageDaysToPay = get('averageDaysToPay', vendor);
  const lastPaymentAmount = get('lastPaymentAmount', vendor);
  const creditLimit = get('creditLimit', vendor);
  const creditTerms = get('creditTerms', vendor);
  const creditBalanceHigh = get('creditBalanceHigh', vendor);
  const creditBalancePastDue = get('creditBalancePastDue', vendor);
  const comments = get('comments', vendor);

  const fields = {
    customerSince,
    lastPaymentOn,
    lastPaymentAmount,
    creditBalancePastDue,
    creditLimit,
    creditBalanceHigh,
    creditTerms,
    averageDaysToPay,
    comments,
  };

  const editReference = async (values: IValues) => {
    const {
      customerSince,
      lastPaymentOn,
      lastPaymentAmount,
      creditBalancePastDue,
      creditLimit,
      creditBalanceHigh,
      creditTerms,
      averageDaysToPay,
      comments,
    } = values;

    const variables = {
      referenceId,
      vendorReferenceData: {
        customerSince: customerSince ? new Date(customerSince).toISOString() : null,
        lastPaymentOn: lastPaymentOn ? new Date(lastPaymentOn).toISOString() : null,
        lastPaymentAmount,
        creditBalancePastDue,
        creditLimit,
        creditBalanceHigh,
        creditTerms,
        averageDaysToPay,
        comments,
      },
    };

    updateReference({ variables })
      .then(() => {
        showToast({
          title: 'Vendor Reference updated',
          description: 'Vendor Reference have been successfully updated',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        refetch();
      });
  };

  return (
    <StyledModalForm
      title='View Vendor Reference'
      submitButtonText='Save'
      onSubmit={(values) => handleSubmitWrapper(values, editReference)}
      initialValues={fields}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      width='80vw'
      maxWidth='1200px'
      maxHeight='800px'
      blockClose={true}
    >
      <Row>
        <DatePickerField name='customerSince' label='Customer Since' />
        <DatePickerField name='lastPaymentOn' label='Last Payment Date' />
        <CurrencyField name='lastPaymentAmount' label='Last Payment Amount' />
        <CurrencyField name='creditLimit' label='Credit Limit' />
      </Row>
      <Row>
        <SelectField creatable name='creditTerms' label='Terms' options={CREDIT_TERMS} />
        <CurrencyField name='creditBalancePastDue' label='Past Due Balance' />
        <TextField type='number' name='averageDaysToPay' label='Average Days To Pay' />
        <CurrencyField name='creditBalanceHigh' label='High Credit Balance' />
      </Row>
      <Row>
        <TextAreaField name='comments' label='Notes' placeholder='Include comments...' />
      </Row>
      {attachment && (
        <>
          {getFileFormat(attachment.originalFilename) === 'pdf' ? (
            <>
              <Flex justify='space-between'>
                {pageNumber <= 1
                  ? <StyledDisabledLinkButton>Previous</StyledDisabledLinkButton>
                  : <StyledLinkButtonUnderline onClick={previousPage}>Previous</StyledLinkButtonUnderline>}
                <>Page {pageNumber || (numPagesState ? 1 : '--')} of {numPagesState || '--'}</>
                {numPagesState && pageNumber >= numPagesState
                  ? <StyledDisabledLinkButton>Next</StyledDisabledLinkButton>
                  : <StyledLinkButtonUnderline onClick={nextPage}>Next</StyledLinkButtonUnderline>}

              </Flex>

              <Row>
                <StyledModalContainer>
                  <Document file={attachment.signedUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} width={800} />
                  </Document>
                </StyledModalContainer>
              </Row>
            </>
          ) : (
            <StyledDownloadContainer>
              <BodyText>We can't show the preview of this file. Download it to view.</BodyText>
              <Button primary onClick={(e: MouseEvent) => { window.open(attachment.signedUrl, '_blank'); e.preventDefault(); }}>Download</Button>
            </StyledDownloadContainer>
          )}
        </>
      )}
      {emailThreads && (
        <>
          <StyledControls>
            { emailIndex !== 0
              ? <Button onClick={previousEmail} >Previous Email</Button>
              : false }
            <StyledSubjectDetails>
              <StyledSubject>SUBJECT</StyledSubject>
              <StyledSubjectTitle>{emailThreads[emailIndex].subject}</StyledSubjectTitle>
              <StyledSentAtDetails>Sent {formatDateToDateTimeString(emailThreads[emailIndex].deliveredAt)}</StyledSentAtDetails>
            </StyledSubjectDetails>
            { emailIndex !== emailThreads.length - 1
              ? <Button onClick={nextEmail}>Next Email</Button>
              : false }
          </StyledControls>
          <StyledEmailDetails>
            <div>On {formatDateToDateTimeString(emailThreads[emailIndex].deliveredAt)} <b>{emailThreads[emailIndex].fromFull.name}</b> wrote:</div>
            <StyledEmailText>{emailThreads[emailIndex].textBody ? parseEmailText(emailThreads[emailIndex].textBody) : ''}</StyledEmailText>
            {emailThreads[emailIndex].attachments && emailThreads[emailIndex].attachments.length > 0 && (
              <>
                <StyledHr />
                <StyledAttachmentHeading>Attachment Details</StyledAttachmentHeading>
                {emailThreads[emailIndex].attachments.map(
                  (attachmentDetail, index) => <AttachmentDetails attachmentDetail={attachmentDetail} key={attachmentDetail.name} index={index} />
                )}
              </>
            )}
          </StyledEmailDetails>
        </>
      )}
    </StyledModalForm>
  );
};
