import React from 'react';

import { H2, BodyText, StyledLink } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';

import { StyledFieldsSectionContainer, StyledForm } from '../styled';

interface IProps {
  isLoading: boolean,
}

export const LoginForm = ({ isLoading }: IProps) => (
  <StyledForm>
    <H2 bold>Login</H2>
    <StyledFieldsSectionContainer>
      <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
      <TextField required type='password' name='password' label='Password' placeholder='*****' />
    </StyledFieldsSectionContainer>
    <Button wide primary type='submit' loading={isLoading}>Login</Button>
    <Flex direction='column'>
      {/* <BodyText color='secondary'>Don't have an account? <StyledLink className='bold-link' color='brand' to='/signup'>Sign up</StyledLink></BodyText> */}
      <BodyText color='secondary'><StyledLink className='bold-link' color='brand' to='/login/forgot'>Forgot Password?</StyledLink></BodyText>
    </Flex>
  </StyledForm>
);
