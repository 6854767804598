import React from 'react';

import { get, getOr } from 'lodash/fp';

import { formatCurrency } from '@/utils/money';

import { CashflowChart } from '@/components/CashflowChart';
import { ToggleBlock } from '@/components/ToggleBlock';
import { H4, BodyText } from '@/components/designSystem/Typography';

import { StyledAutoFitColumnsRow, StyledTwoColumnsRow } from '../../../styled';
import { StyledToggleBankHeader, LatestBalanceHeader, StyledAccountInfoContainer } from '../styled';
import { IAutomaticAccountInterface } from '@/types/automaticAccount';
import { LabeledValue } from '../../../LabeledValue';
import { useAutomaticAccountFields } from '../useAutomaticAccountFields';
import { Flex } from '@/components/designSystem/Layout';
import { formatAccountType } from '@/utils/format';

interface IProps {
  account: IAutomaticAccountInterface,
  institutionName: string,
}

export const AutomaticAccount = ({ account, institutionName }: IProps) => {
  const name = get('lastAccount.name', account);
  const cashflowHistory = getOr([], 'cashflowHistory', account);
  const latestBalance = get('latestBalance', account);

  const fields = useAutomaticAccountFields(account);

  const isCreditCard = fields.accountType === 'creditCard';
  const showGraph = !isCreditCard && cashflowHistory && cashflowHistory.length;

  const Header = (
    <StyledToggleBankHeader>
      <BodyText bold>{institutionName}</BodyText>
      <BodyText bold>{name}</BodyText>
        Automatic
    </StyledToggleBankHeader>
  )

  return (
    <ToggleBlock header={Header}>
      <StyledAccountInfoContainer>
        <StyledTwoColumnsRow>
          <LabeledValue label='Bank name' value={fields.institutionName || '-'} />
          <LabeledValue label='Bank address' value={fields.institutionAddress || '-'} />
        </StyledTwoColumnsRow>
        <StyledAutoFitColumnsRow>
          <LabeledValue label='Account number' value={fields.accountNumber || '-'} />
          <LabeledValue label='Routing / Transit' value={'-'} />
          <LabeledValue label='Account type' value={formatAccountType(fields.accountType) || '-'} />
          <LabeledValue label='Currency' value={fields.currency || '-'} />
          <LabeledValue label='Status' value='Verified' />
        </StyledAutoFitColumnsRow>
        <Flex direction='column'>
          <H4 bold>Cash flow report (6 month)</H4>
          <H4 bold>Account Balance</H4>
          <LatestBalanceHeader bold>{formatCurrency(latestBalance)}</LatestBalanceHeader>
        </Flex>
        {showGraph && (
          <CashflowChart
            height={400}
            cashflowHistory={cashflowHistory}
          />
        )}
      </StyledAccountInfoContainer>
    </ToggleBlock>
  );
};
