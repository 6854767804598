/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import { INavOptions } from '@/types/navOptions';

import { Logo } from './Logo';
import { NavigationContent } from './NavigationContent';
import { NavigationUserInfo } from './NavigationUserInfo';

import {
  NavContainer,
  MobileContainer,
  StyledBurgerButton,
  MobileNav,
  StyledOverlay,
  StyledDivider,
} from './styled';
import { Flex } from '../../../components/designSystem/Layout';

interface IProps {
  options: INavOptions,
}

export const Navigation = ({ options = [] }: IProps) => {

  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      <NavContainer>
        <Flex direction='column' gap='1rem'>
          <Logo />
          <NavigationContent options={options} />
        </Flex>
        <StyledDivider />
        <NavigationUserInfo />
      </NavContainer>
      <MobileContainer>
        <Logo />
        <StyledBurgerButton isMenuVisible={isMenuVisible} onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </StyledBurgerButton>
        <MobileNav isVisible={isMenuVisible}>
          <NavigationContent options={options} toggleMenu={toggleMenu} />
        </MobileNav>
        <StyledOverlay className={isMenuVisible ? 'active' : ''} />
      </MobileContainer>
    </>
  );
};
