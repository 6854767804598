import styled from 'styled-components';

import { Form } from 'formik';
import { H4 } from '@/components/designSystem/Typography';
import device from '@/styles/device';

import { Flex, FlexColumn, FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';
import { BorderedContainer } from '@/containers/BorderedContainer';

export const StyledContainer = styled(FlexColumn)`
  & > * {
    margin-bottom: 2rem;
  }
`;

export const StyledForm = styled(Form)`
  & > * {
    margin-bottom: 2rem;
  }
`;

// CUSTOM FIELDS

export const StyledVRCustomFieldsContainer = styled(BorderedContainer)`
  & > * {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  & > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

interface IStyledCustomFieldRow {
  first?: boolean;
}

export const StyledCustomFieldRow = styled(FlexRowSpaceBetweenItemsCenter)<IStyledCustomFieldRow>`
  border-top: ${({ first }) => first ? 'none' : 'solid 0.02em #ECECEC'};
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 70%;

  @media ${device.mobileXL} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0.5rem 0rem 0rem;

    & > * {
      margin-bottom: 1rem;
    }
  }
`;

export const StyledOrientationContainer = styled(Flex)`
  gap: 1rem;
  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const StyledOrientationDirection = styled(Flex)`
  flex-direction: column;
  flex-basis: 100%;
`

export const StyledOrientationH4 = styled(H4)`
  margin-bottom: 1rem;
`


export const StyledTagForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  flex: 1;
  gap: 2rem;
  padding-bottom: 7rem;
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  flex-direction: column;
`;

export const TagContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 calc((100% - 3rem)/2);
  max-width: 50%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & > :first-child {
    margin-bottom: 1rem;
  }
`;

export const StyledTag = styled.span<{ backgroundColor: string, textColor: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.125rem 0.625rem;
  border-radius: 10px;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};

  svg {
    margin-left: 0.25rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
`