import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { H3, Caption, } from '@/components/designSystem/Typography';
import { FlexColumnItemsCenter, FlexRowSpaceEvenly } from '../components/designSystem/Layout';

const Container = styled(FlexColumnItemsCenter)`
  justify-content: space-between;
  padding: 20px;
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const ActionContainer = styled(FlexRowSpaceEvenly)`
  padding: 1.5rem;
  border-top: ${({ theme }) => theme.color.border.light};

  & > * {
    margin-right: 1rem;
  }

  & > :last-child{
    margin-right: 0;
  }
`;

export const StyledCaption = styled(Caption)`
 font-size: 18px;
 line-height: 28px;
 text-align: center;
 color: ${({ color }) => color};
 padding-bottom: 10px;
`;

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
}

export const CPOnboardCompletedModal = ({isOpen, setIsOpen}: IProps) => (
  <>
    <StyledModal
      title='Thank you for signing up.'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      disableClose
      blockClose
    >
      <Container>
        <H3>Please reach out to support@nectarinecredit.com to activate your account</H3>
      </Container>
      <ActionContainer>
        <StyledButton onClick={() => window.location.assign('https://www.nectarinecredit.com/schedule-demo')}>Learn More</StyledButton>
      </ActionContainer>
    </StyledModal>
  </>
);
