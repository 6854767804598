import React from 'react';

import { StepNode } from './StepNode';

import { StepCounterContainer } from './styled';

interface IProps {
  color: string,
}

export const StepPreview = ({ color }: IProps) => (
  <StepCounterContainer>
    <StepNode index={0} isComplete color={color} />
    <StepNode index={1} isActive color={color} />
    <StepNode index={2} color={color} />
  </StepCounterContainer>
);
