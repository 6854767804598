import gql from 'graphql-tag';

export const CHANGE_REQUEST_COMPLETE = gql`
mutation changeRequestComplete($changeRequestId: ID!) {
  change_request_complete(changeRequestId: $changeRequestId) {
    changeRequest {
      id
    }
  }
}
`;
