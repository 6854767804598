import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Flex } from '@/components/designSystem/Layout';

const StyledContainer = styled(Flex).attrs({ align: 'center', justify: 'center' })`
  color: #3c3c3c;
  height: 100%;
  margin-right: 0.5rem;
`;

const ping = keyframes`
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const StyledHeartbeat = styled.span`
  position: absolute;
  width: fit-content;
  background-color: ${({ theme }) => theme.color.warning};
  border-radius: 50%;
  width: 9.5px;
  height: 9.5px;
  opacity: 0.75;
  animation: ${ping} 1s cubic-bezier(0, 0, 0.4, 1) infinite;
`;

const StyledDot = styled.span`
  position: relative;
  width: fit-content;
  background: ${({ theme }) => theme.color.warning};
  border-radius: 50%;
  width: 9.5px;
  height: 9.5px;
`;

export const WarningIcon = () => (
  <StyledContainer>
    <StyledHeartbeat />
    <StyledDot />
  </StyledContainer>
);