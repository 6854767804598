import React from 'react';

import { IManualBank } from '@/types/manualBank';

import { ManualInstitution } from './ManualInstitution';

interface IProps {
  institutions: IManualBank[],
  refetch: () => void,
  isFinalized: boolean,
}

export const ManualSection = ({ institutions, refetch, isFinalized }: IProps) => (
  <>
    {institutions.map((institution) => <ManualInstitution key={institution.id} institution={institution} refetch={refetch} isFinalized={isFinalized} />)}
  </>
);
