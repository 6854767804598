import React, { Dispatch, SetStateAction } from 'react';

import { GET_DNB_RESULTS } from '@/graphql/queries/getDnbResults';
import { GENERATE_DNB_REPORT } from '@/graphql/mutations/generateDnbReport';

import { Formik } from 'formik';

import { get, getOr } from 'lodash/fp';

import { ICompany } from "@/types/company";
import { ICustomer } from '@/types/customer';

import { ContentContainer } from '@/modals/StyledModal/styled';
import { H4 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import {
  StyledForm,
  StyledContainer,
  StyledNoResultsText,
} from '@/modals/CreditReportModal/styled';
import { useDnbCreditReportModal } from '@/modals/CreditReportModal/useDnbCreditReportModal';
import { ReportModalFooter } from '@/views/CustomerPage/tabs/BureauReport/modals/ReportModalFooter';
import { CreditReportModalContainer } from '@/modals/CreditReportModal/CreditReportModalContainer';

import { DnbSearchResultItem } from '../DnbSearchResultItem';
import { DnbSearchForm } from '../DnbSearchForm';
import { searchValidation, getValidCountry, getValidProvince } from '../common';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

interface IProps {
  isOpen: boolean,
  customer?: ICustomer,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: () => void,
}

export const DnbCreditReportModal = ({
  isOpen,
  setIsOpen,
  customer,
  onSuccess,
}: IProps) => {

  const {
    showSearch,
    setShowSearch,
    searchInput,
    data,
    loading,
    handleSubmit,
  } = useDnbCreditReportModal(
    isOpen,
    GET_DNB_RESULTS,
    GENERATE_DNB_REPORT,
    () => {
      setIsOpen(false);
      onSuccess();
    }
  )

  const defaultCountry = getValidCountry(getOr('US', 'seekerCompany.address.country', customer));
  const defaultProvince = getValidProvince(getOr('', 'seekerCompany.address.state', customer), defaultCountry);

  const searchFields = {
    name: getOr('', 'seekerCompany.name', customer),
    address_line_1: getOr('', 'seekerCompany.address.address_line_1', customer),
    city: getOr('', 'seekerCompany.address.city', customer),
    province: defaultProvince,
    country: defaultCountry,
    postal_code: getOr('', 'seekerCompany.address.postal_code', customer),
    website: getOr('', 'seekerCompany.website', customer),
  };

  const customerId = get('id', customer);

  const companies = getOr([], 'getDnbResults.results', data);

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />
    }
    if (companies.length === 0) {
      return (
        <>
          <StyledNoResultsText>No results found for the following input:</StyledNoResultsText>
          <pre>{JSON.stringify(searchInput, null, 5).replace(/[{}]/g, '')}</pre>
          <H4>Please go back and refine your search.</H4>
        </>
      )
    }
    return companies.map((company: ICompany, index: number) => {
      const { duns, business_name, address} = company;
      const displayAddress = `${address.address_line_1}, ${address.city}, ${address.state}, ${address.postal_code}`;
      return (
        <DnbSearchResultItem
          key={company.id}
          isFirst={index === 0}
          address={displayAddress}
          businessName={business_name}
          duns={duns}
          customerId={customerId}
          setIsOpen={setIsOpen}
          onSuccess={onSuccess}
        />
      );
    })
  }

  return (
    <CreditReportModalContainer
      title={showSearch ? '2. Select Matching Company' : '1. Company Search'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Formik
        onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
        initialValues={searchFields}
        validationSchema={searchValidation}
        validateOnChange
        enableReinitialize
      >
        <StyledForm>
          <ContentContainer maxHeight='80vh'>
            {
              showSearch
                ? (
                  <StyledContainer>
                    {renderContent()}
                  </StyledContainer>
                )
                : <DnbSearchForm />
            }
          </ContentContainer>
          <ReportModalFooter
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            loading={loading}
          />
        </StyledForm>
      </Formik>
    </CreditReportModalContainer>
  );
};
