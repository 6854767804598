import React from 'react';

import { useFormikContext } from 'formik';


import { ICSOnboardingValues } from '@/types/onboardingValues';

import { H4 } from '@/components/designSystem/Typography';
import { FlexRowSpaceBetweenItemsCenter } from '@/components/designSystem/Layout';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { StyledCloseIconWrapper, StyledContactContainer } from './styled';
import { GoogleAddressField } from '@/components/GoogleAddressField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { DatePickerField } from '@/components/designSystem/Form/DatePickerField';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledFormRow, StyledMultiRow } from '@/components/designSystem/Form/styled';

import { ReactComponent as CloseIcon } from '@/assets/x_close.svg';

import { SORTED_COUNTRIES } from '@/constants';

import { IAdditionalPersonalInformationFields } from './types';
import { setFieldsFromGooglePlace } from './functions';

interface IProps {
  parentKey: string,
  disabled?: boolean,
  onRemoveClick?: () => void,
  index?: number,
  additionalPersonalInformationFields: IAdditionalPersonalInformationFields,
  enableOwnershipStake: boolean,
}

export const OwnerSection = ({ parentKey, disabled, onRemoveClick, index = 0, additionalPersonalInformationFields, enableOwnershipStake }: IProps) => {
  const { setFieldValue } = useFormikContext<ICSOnboardingValues>();

  const {
    ownerAddressEnabled,
    ownerAddressRequired,
    ownerSsnSinNumberEnabled,
    ownerSsnSinNumberRequired,
    ownerDriverLicenseNoEnabled,
    ownerDriverLicenseNoRequired,
    ownerDriverLicenseStateEnabled,
    ownerDriverLicenseStateRequired,
    ownerDateOfBirthEnabled,
    ownerDateOfBirthRequired,
  } = additionalPersonalInformationFields;

  return (
    <StyledContactContainer>
      <FlexRowSpaceBetweenItemsCenter>
        <H4 bold>Owner #{index + 1}</H4>
        {(onRemoveClick && Number(index) > 0) && (
          <StyledCloseIconWrapper>
            <CloseIcon onClick={onRemoveClick} />
          </StyledCloseIconWrapper>
        )}
      </FlexRowSpaceBetweenItemsCenter>
      <StyledFormRow>
        <TextField required type='text' disabled={disabled} name={`${parentKey}.name`} label='Name' placeholder='John Doe' description='Owner Name/Corporation Name' />
        <TextField required type='text' disabled={disabled} name={`${parentKey}.position`} label='Title' placeholder='CFO' />
      </StyledFormRow>
      <StyledFormRow>
        {enableOwnershipStake && <TextField required type='number' name={`${parentKey}.ownershipPercentage`} label='Ownership Stake' placeholder='XX %' />}
        <PhoneNumberField required disabled={disabled} name={`${parentKey}.phoneNumber`} label='Phone Number' placeholder='Phone number' />
        <TextField required type='email' disabled={disabled} name={`${parentKey}.email`} label='Email Address' placeholder='John@streamlink.com' />
      </StyledFormRow>
      {
        ownerAddressEnabled ? (
          <>
            <GoogleAddressField
              required={ownerAddressRequired}
              type='text'
              name={`${parentKey}.address.address_line_1`}
              label='Home address'
              placeholder='123 XYZ st. '
              setFieldsFromGooglePlace={(place) => setFieldsFromGooglePlace(place, parentKey, setFieldValue)}
            />
            <TextField type='text' name={`${parentKey}.address.address_line_2`} label='Address Line 2' placeholder='E.g. Apt 1' />
            <StyledMultiRow>
              <StyledFormRow>
                <TextField required={ownerAddressRequired} type='text' name={`${parentKey}.address.city`} label='City' placeholder='New York' />
                <TextField required={ownerAddressRequired} type='text' name={`${parentKey}.address.state`} label='State / Province' placeholder='New York' />
              </StyledFormRow>
              <StyledFormRow>
                <TextField required={ownerAddressRequired} type='text' name={`${parentKey}.address.postal_code`} label='Zip / Postal Code' placeholder='90283' />
                <SelectField required={ownerAddressRequired} name={`${parentKey}.address.country`} label='Country' options={SORTED_COUNTRIES} />
              </StyledFormRow>
            </StyledMultiRow>
          </>
        ) : false
      }

      <StyledFormRow>
        {
          ownerSsnSinNumberEnabled ? (
            <>
              <TextField
                required={ownerSsnSinNumberRequired}
                type='text'
                name={`${parentKey}.ssnSinNumber`}
                label='SSN/SIN Number'
                placeholder=''
              />
            </>
          ) : false
        }
        {
          ownerDateOfBirthEnabled ? (
            <>
              <DatePickerField
                required={ownerDateOfBirthRequired}
                name={`${parentKey}.dob`}
                label='Date Of Birth'
              />
            </>
          ) : false
        }
      </StyledFormRow>
      <StyledFormRow>
        {
          ownerDriverLicenseNoEnabled ? (
            <>
              <TextField
                required={ownerDriverLicenseNoRequired}
                type='text'
                name={`${parentKey}.driverLicenseNo`}
                label="Driver's License Number"
                placeholder=''
              />
            </>
          ) : false
        }
        {
          ownerDriverLicenseStateEnabled ? (
            <>
              <TextField
                required={ownerDriverLicenseStateRequired}
                type='text'
                name={`${parentKey}.driverLicenseState`}
                label="State/Province"
                placeholder=''
                description="Driver's License State/Province"
              />
            </>
          ) : false
        }
      </StyledFormRow>
    </StyledContactContainer>
  );
};