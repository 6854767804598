import { Dispatch, SetStateAction } from 'react';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { get } from 'lodash/fp';
import { formatPhoneNumberForRequest, formatPhone } from '@/utils/format';

import { ICustomer } from '@/types/customer';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { MutationFunction } from '@apollo/react-hooks';

interface IValues {
  id: string,
  name: string,
  phoneNumber: string,
  website: string,
  address: {
    address_line_1: string,
    address_line_2: string,
    city: string,
    state: string,
    postal_code: string,
    country: string,
  },
  shippingAddress?: {
    address_line_1: string,
    address_line_2: string,
    city: string,
    state: string,
    postal_code: string,
    country: string,
  },
  billingAddress?: {
    address_line_1: string,
    address_line_2: string,
    city: string,
    state: string,
    postal_code: string,
    country: string,
  },
  type: string,
  established: string,
  description: string,
}

export const useInformationForm = (
  customerData: ICustomer,
  setEditActive: Dispatch<SetStateAction<boolean>>,
  providerUpdateCompany: MutationFunction,
  refetch: () => void
) => {
  const applicationId = get('latestApplication.id', customerData);
  const id = get('seekerCompany.id', customerData);
  const name = get('seekerCompany.name', customerData);
  const phoneNumber = get('seekerCompany.phoneNumber', customerData);
  const website = get('seekerCompany.website', customerData);
  const type = get('seekerCompany.type', customerData);
  const address_line_1 = get('seekerCompany.address.address_line_1', customerData);
  const address_line_2 = get('seekerCompany.address.address_line_2', customerData);
  const city = get('seekerCompany.address.city', customerData);
  const state = get('seekerCompany.address.state', customerData);
  const postal_code = get('seekerCompany.address.postal_code', customerData);
  const country = get('seekerCompany.address.country', customerData);
  //Shipping Address
  const shippingAddressLine1 = get('latestApplication.shippingAddress.address_line_1', customerData);
  const shippingAddressLine2 = get('latestApplication.shippingAddress.address_line_2', customerData);
  const shippingCity = get('latestApplication.shippingAddress.city', customerData);
  const shippingState = get('latestApplication.shippingAddress.state', customerData);
  const shippingPostalCode = get('latestApplication.shippingAddress.postal_code', customerData);
  const shippingCountry = get('latestApplication.shippingAddress.country', customerData);
  //Billing Address
  const billingAddressLine1 = get('latestApplication.billingAddress.address_line_1', customerData);
  const billingAddressLine2 = get('latestApplication.billingAddress.address_line_2', customerData);
  const billingCity = get('latestApplication.billingAddress.city', customerData);
  const billingState = get('latestApplication.billingAddress.state', customerData);
  const billingPostalCode = get('latestApplication.billingAddress.postal_code', customerData);
  const billingCountry = get('latestApplication.billingAddress.country', customerData);
  const established = `${get('seekerCompany.established', customerData)}`;
  const description = get('seekerCompany.description', customerData);

  const initialValues = {
    id,
    name,
    phoneNumber: formatPhone(phoneNumber),
    website,
    address: {
      address_line_1,
      address_line_2,
      city,
      state,
      postal_code,
      country,
    },
    ...(customerData.providerCompany.settings.enableShippingAddress && { shippingAddress: {
      address_line_1: shippingAddressLine1,
      address_line_2: shippingAddressLine2,
      city: shippingCity,
      state: shippingState,
      postal_code: shippingPostalCode,
      country: shippingCountry,
    }}),
    ...(customerData.providerCompany.settings.enableBillingAddress && { billingAddress: {
      address_line_1: billingAddressLine1,
      address_line_2: billingAddressLine2,
      city: billingCity,
      state: billingState,
      postal_code: billingPostalCode,
      country: billingCountry,
    }}),
    type,
    established,
    description,
  }

  const handleSubmit = async (values: IValues) => {
    const companyInfo = {
      id: values.id,
      name: values.name,
      type: values.type,
      established: +values.established,
      description: values.description,
      website: values.website,
      phoneNumber: formatPhoneNumberForRequest(values.phoneNumber),
      address: JSON.stringify({
        address_line_1: values.address.address_line_1,
        address_line_2: values.address.address_line_2,
        city: values.address.city,
        state: values.address.state,
        country: values.address.country,
        postal_code: values.address.postal_code,
      }),
      ...(values.shippingAddress && { shippingAddress: JSON.stringify({
        address_line_1: values.shippingAddress.address_line_1,
        address_line_2: values.shippingAddress.address_line_2,
        city: values.shippingAddress.city,
        state: values.shippingAddress.state,
        country: values.shippingAddress.country,
        postal_code: values.shippingAddress.postal_code,
      })}),
      ...(values.billingAddress && { billingAddress: JSON.stringify({
        address_line_1: values.billingAddress.address_line_1,
        address_line_2: values.billingAddress.address_line_2,
        city: values.billingAddress.city,
        state: values.billingAddress.state,
        country: values.billingAddress.country,
        postal_code: values.billingAddress.postal_code,
      })}),
    }
    console.log(companyInfo)
    providerUpdateCompany({ variables: {
      applicationId,
      companyInfo
    } })
      .then(() => {
        showToast({
          title: 'Information Successfully Updated!',
          description: 'Your customer company information is Successfully Updated.',
          type: toast.TYPE.SUCCESS,
        });
        setEditActive(false);
        refetch();
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Information wasn\'t updated',
          type: toast.TYPE.ERROR,
        });
        setEditActive(false);
      });
  };

  const addressValidation = Yup.object().shape({
    address_line_1: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    address_line_2: Yup.string().nullable(),
    city: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    state: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    postal_code: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    country: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  });

  const validation = Yup.object().shape({
    name: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    type: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    phoneNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED).phone('US', false, DEFAULT_ERROR_MESSAGES.PHONE),
    website: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    established: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    description: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    address: addressValidation,
  });

  return { initialValues, validation, handleSubmit };
}
