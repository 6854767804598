import styled from 'styled-components';
import { Pulse } from 'styled-spinkit';
import { FlexRowItemsCenter } from '../Layout';
import device from '@/styles/device';

export interface IButtonStyles {
  primary?: boolean,
  secondary?: boolean,
  danger?: boolean,
  brand?: boolean,
  warning?: boolean,
  isDisabled?: boolean,
  disabled?: boolean,
  theme?: any,
  small?: boolean,
  xsmall?: boolean,
  wide?: boolean,
  bold?: boolean,
}

const buttonStyles = ({
  primary, secondary, danger, warning, brand, disabled, theme,
}: IButtonStyles) => {
  if (disabled) return theme.color.button.disabled;
  if (primary) return theme.color.button.primary;
  if (secondary) return theme.color.button.secondary;
  if (brand) return theme.color.button.brand;
  if (danger) return theme.color.button.danger;
  if (warning) return theme.color.button.warning;
  return theme.color.button.default;
};

const buttonPadding = ({ small, xsmall }: IButtonStyles) => {
  if (small) return '0.5rem 1rem';
  if (xsmall) return '0.25rem 0.5rem';
  return '1rem';
};
const buttonTextColor = (props: IButtonStyles) => buttonStyles(props).text;
const buttonBgColor = (props: IButtonStyles) => buttonStyles(props).background;
const buttonWidth = ({ wide }: IButtonStyles) => (wide ? '100%' : 'fit-content');

export const StyledButton = styled.button<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${buttonPadding};
  width: ${buttonWidth};
  height: ${({ fullHeight }) => (fullHeight ? '3.5rem' : '2.5rem')};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background: ${buttonBgColor};
  border: ${({ theme }) => theme.color.border.light};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.color.shadow.shadowSmall};
  border-radius: 6px;
  flex: none;
  flex-grow: 0;
  color: ${buttonTextColor};
  font-weight: ${({ bold }) => bold ? '700' : '400'};

  :hover {
    box-shadow: ${({ theme, isDisabled }) => !isDisabled && theme.color.shadow.shadowSmallInset};
  }

  & > path {
    fill: ${buttonTextColor};
  };

  @media ${device.mobileM} {
    font-size: 14px;
  }
`;

export const IconContainer = styled.span`
  margin-right: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
`;

export const RightIconContainer = styled.span`
  margin-left: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
`;

export const StyledSpinner = styled(Pulse)`
  color: ${buttonTextColor};
  padding: 0;
  margin: 0;
`;

export const StyledEditButtonWrapper = styled(FlexRowItemsCenter)`
  gap: 0.3rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.primary};
  cursor: pointer;
`;