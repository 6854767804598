import React from 'react';

import { BorderedContainer } from '@/containers/BorderedContainer';
import { BodyText } from '@/components/designSystem/Typography';

interface IProps {
  children: React.ReactNode,
}

export const EmptyState = ({ children }: IProps) => (
  <BorderedContainer>
    <BodyText color='secondary'>{children}</BodyText>
  </BorderedContainer>
);
