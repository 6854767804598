import styled from 'styled-components';

import { BorderedContainer } from '@/containers/BorderedContainer';

import { H2 } from '@/components/designSystem/Typography';
import { TitleCallout } from '@/components/Callout/TitleCallout';

export const StyledTitleCallout = styled(TitleCallout)`
  margin-bottom: 2.5rem;
`;

export const StyledBorderedContainer = styled(BorderedContainer).attrs({ disableDivider: true })`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > * {
    padding-bottom: 0;
    margin-bottom: 0;
  };
`;

export const StyledPageTitle = styled(H2)`
  margin-bottom: 1.5rem;
`;