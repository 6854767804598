import styled from 'styled-components';

import { LabeledComponent } from '@/components/LabeledComponent';
import { FlexRowItemsCenter } from '../designSystem/Layout';

const Container = styled(FlexRowItemsCenter)`
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 1.5rem 0;
`;

const Stat = styled(LabeledComponent)`
  flex: 0 1 33%;
  margin-bottom: 2rem;
`;

export const Stats = {
  Container,
  Stat,
}
