import React from 'react';

import { BodyText } from '@/components/designSystem/Typography';

import { StyledResultsNumberText, StyledToggleHeader } from './styled';

interface IProps {
  title: string,
  resultsNumberText: React.ReactNode,
}

export const Header = ({ title, resultsNumberText }: IProps) => (
  <StyledToggleHeader>
    <BodyText bold>{title}</BodyText>
    <StyledResultsNumberText>{resultsNumberText}</StyledResultsNumberText>
  </StyledToggleHeader>
);
