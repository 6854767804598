import { IAddressComponent, IGooglePlace } from '@/types/googlePlace';

const mapGooglePlaceToAddressString = (place: IGooglePlace) => {
  const streetNumber = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('street_number'))?.long_name || '';
  const street = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('route'))?.long_name || '';
  const city = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('locality'))?.long_name || '';
  const state = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('administrative_area_level_1'))?.long_name || '';
  const country = place
    .address_components
    .find((addressElement: IAddressComponent) => addressElement.types.includes('country'))?.long_name || '';
  const streetNumberStreetString = `${streetNumber}${streetNumber && street ? ' ' : ''}${street}`
  return [streetNumberStreetString, state, city, country].filter((element) => element !== '').join(', ');
};

export const setFieldsFromGooglePlace = (place: IGooglePlace, parentKey: string, setFieldValue: (field: string, value: string | null) => void) => {
  const address = mapGooglePlaceToAddressString(place);
  setFieldValue(`${parentKey}.bankAddress`, address);
}