import React from 'react';
import PhoneInput from 'react-phone-number-input/input';

import { useField, ErrorMessage } from 'formik';

import { FieldLabel } from './FieldLabel';
import { StyledErrorText, StyledFieldContainer, StyledPhoneInput } from './styled';

interface IProps {
  name?: string,
  label: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
}

export const PhoneNumberField = ({
  required,
  name = '',
  label,
  placeholder,
  disabled
}: IProps) => {
  const [field, meta, helpers] = useField(name);

  const showError = !!(meta.touched && meta.error);

  const { setValue, setTouched } = helpers;

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <PhoneInput
        id={name}
        name={name}
        placeholder={placeholder}
        value={field.value}
        onChange={(value) => setValue(value ?? '')}
        onBlur={() => setTouched(true)}
        inputComponent={StyledPhoneInput}
        defaultCountry='US'
        disabled={disabled}
        error={showError}
      />
      {(showError && name) &&
        <StyledErrorText>
          { (showError && name) ? <ErrorMessage name={name} /> : false }
        </StyledErrorText>
      }
    </StyledFieldContainer>
  )
};
