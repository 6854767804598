import gql from 'graphql-tag';

export const GENERATE_CREDIT_SAFE_REPORT = gql`
  mutation GenerateCreditSafeReport($creditSafeId: String!, $reportDescription: String!, $customerId: ID!) {
    generateCreditSafeReport(creditSafeId: $creditSafeId, customerId: $customerId, reportDescription: $reportDescription) {
      id
    }
  }
`;

export const IS_CREDIT_SAFE_CREDENTIALS_STILL_VALID = gql`
  mutation IsCreditSafeCredentialsStillValid {
    isCreditSafeCredentialsStillValid
  }
`;
