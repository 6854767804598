import React, { useEffect } from 'react';
import { useFormikContext, FieldArray } from 'formik';

import { TextField } from '@/components/designSystem/Form/TextField';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as Plus } from '@/assets/plus.svg';
import { ReactComponent as CloseIcon } from '@/assets/x_close.svg';

import { StyledOptionFieldContainer } from './styled';

export interface IAddCustomFieldValues {
  options: string[],
}

interface IProps {
  createOptions?: boolean,
  additionalQuestionType?: boolean,
}

export const DropdownTypeForm = ({ createOptions, additionalQuestionType }: IProps) => {
  const { values, setFieldValue } = useFormikContext<IAddCustomFieldValues>();

  useEffect(() => {
    if (createOptions) {
      setFieldValue(
        'options',
        ['', '']
      )
    }
  }, []);

  const onRemoveClick = (index: number) => {
    setFieldValue(
      'options',
      values.options.filter((s, i) => i !== index)
    )
  };

  return (
    <FieldArray
      name='options'
      render={(arrayHelpers) => (
        <>
          {values.options.map((field, index) => (
            <StyledOptionFieldContainer key={index}>
              <TextField required type='text' name={`options[${index}]`} label={`Option ${index + 1}`} placeholder={`Option ${index + 1}`} />
              {(values.options.length > 2) ?  (
                <CloseIcon
                  onClick={() => onRemoveClick(index)}
                />) : false
              }
            </StyledOptionFieldContainer>
          ))}
          { additionalQuestionType ? (
            <StyledOptionFieldContainer>
              <TextField required type='text' name='additionalQuestionPlaceholder' disabled label={`Option ${values.options.length + 1}`} placeholder="Don't know / Not Listed" />
            </StyledOptionFieldContainer>
          ) : false }
          <Button secondary LeftIcon={Plus} onClick={() => arrayHelpers.push('')}>Add Additional Option</Button>
        </>
      )}
    />
  )};
