import React from 'react';

import { IAgreement } from '@/types/agreement';
import { ICustomFieldAnswerFile } from '@/types/customField';

import { Document } from '@/components/Document';
import { SectionContainer } from '../../SectionContainer';
import { CardsContainer } from '@/components/designSystem/cards/CardsContainer';
import { IUploadedFile } from '@/types/file';
import { formatDateToLongDateString } from '@/utils/date';

interface IProps {
  agreements: IAgreement[],
  customDocuments: ICustomFieldAnswerFile[],
}

interface IDocument {
  title: string,
  content: string,
  file: IUploadedFile
}

export const Documents = ({ agreements, customDocuments }: IProps) => {
  const signedAgreements = agreements?.filter((agreement) => agreement.signedFile?.signedUrl) || [];
  const agreementDocuments: IDocument[] = signedAgreements.map((agreement: IAgreement) => {
    const signedFile = agreement.signedFile;
    const file: IUploadedFile = {
      name: signedFile.originalFilename,
      signedUrl: signedFile.signedUrl,
      path: signedFile.path,
    };
    return {
      file: file,
      title: "Credit Agreement",
      content: `Agreement signed ${formatDateToLongDateString(signedFile.insertedAt)}`,
    }
  });
  const customFieldDocuments: IDocument[] = customDocuments.map((document: ICustomFieldAnswerFile) => {
    const file: IUploadedFile = {
      name: document.file.originalFilename,
      signedUrl: document.file.signedUrl,
      path: document.file.path,
    }
    return {
      content: `${document.customField.label} - ${file.name}`,
      title: document.customField.label,
      file: file
    };
  })

  return (
    <SectionContainer title='Documents'>
      <CardsContainer>
        {agreementDocuments.map((document: IDocument) => <Document
          key={document.file.id}
          title={document.title}
          content={document.content}
          file={document.file} />)}
        {customFieldDocuments.map((document: IDocument) => <Document
          key={document.file.id}
          title={document.title}
          content={document.content}
          file={document.file} />)}
      </CardsContainer>
    </SectionContainer>
  )
};
