import React from 'react';

import { IVRCustomField } from '@/types/vrCustomField';

import { BodyText, H2, StyledExternalLink } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { FlexRowEnd } from '@/components/designSystem/Layout';
import { StyledFormRow } from '@/components/designSystem/Form/styled';

import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm
} from '../../styled';
import { VRCustomField } from '../../VRCustomField';
import { useFormikContext } from 'formik';

export interface IProps {
  customFields: IVRCustomField[],
}

export const CompleteStepForm = ({ customFields }: IProps) => {

  const { setFieldValue } = useFormikContext();

  return (
    <StyledForm>
      <StyledTitleContainer>
        <H2>Please verify the information about you and your company</H2>
      </StyledTitleContainer>
      <StyledFormRow>
        <TextField required type='text' autoFocus name='company' label='Legal Company Name' placeholder='E.g. Google, Alphabet, Google Inc' />
        <TextField required type='text' name='name' label='Full Name' placeholder='Full Name' />
      </StyledFormRow>
      <StyledFormRow>
        <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
        <PhoneNumberField required name='phoneNumber' label='Phone' placeholder='Phone number' />
      </StyledFormRow>
      <StyledFormRow>
        {
          customFields.map((customField) => (
            <VRCustomField {...customField} key={customField.id} setFieldValue={setFieldValue} />
          ))
        }
      </StyledFormRow>
      <BodyText>
        By submitting you agree to our        
        <StyledExternalLink href='https://www.nectarinecredit.com/terms' target='_blank'>
          {` Terms of Service`}
        </StyledExternalLink>
        {` and `}
        <StyledExternalLink href='https://www.nectarinecredit.com/privacy' target='_blank'>
          Privacy Notice
        </StyledExternalLink>
      </BodyText>
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Submit</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )};
