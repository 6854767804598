import React from 'react';

import { get, getOr } from 'lodash/fp';

import { formatCurrency } from '@/utils/money';
import { formatAddress, formatAccountType } from '@/utils/format';
import { formatDateToTimeAgo } from '@/utils/date';

import { H4, Caption } from '@/components/designSystem/Typography';
import { CashflowChart } from '@/components/CashflowChart';
import ToggleWell from '@/components/_oldComponents/ToggleWell';
import { Stats } from '@/components/_oldComponents/Stats';

import {
  StyledCheckIcon,
  PaddedH3,
  StatsHeader,
  LatestBalanceHeader,
} from './styled';
import { IAutomaticAccountInterface } from '@/types/automaticAccount';

interface IProps {
  account: IAutomaticAccountInterface,
}

export const FinicityAccount = ({ account }: IProps) => {
  const name = get('lastAccount.name', account);
  const currency = get('lastAccount.currency', account);
  const type = get('lastAccount.type', account);

  const institutionName = get('lastAccount.institution.name', account);
  const institutionAddress = get('lastAccount.institution.address', account);
  const accountDisplayNumber = get('lastAccount.accountDisplayNumber', account);

  const cashflowHistory = getOr([], 'cashflowHistory', account);
  const isCreditCard = type === 'creditCard';
  const showGraph = !isCreditCard && cashflowHistory && cashflowHistory.length > 0;

  const latestBalance = get('latestBalance', account);
  const latestBalanceDate = get('latestBalanceDate', account);

  const subTitle = accountDisplayNumber
    ? `(${accountDisplayNumber})`
    : '';


  const FinicityAccountHeaderInfo = () => (
    <PaddedH3 bold>{formatCurrency(latestBalance)}</PaddedH3>
  );

  return (
    <ToggleWell
      title={`${name} ${subTitle}`}
      TitleInfo={FinicityAccountHeaderInfo as any}
    >
      <H4 bold>Account Balance</H4>
      <LatestBalanceHeader bold>{formatCurrency(latestBalance)}</LatestBalanceHeader>
      {showGraph && (
        <CashflowChart
          height={400}
          cashflowHistory={cashflowHistory}
        />
      )}
      <StatsHeader bold>Account Stats</StatsHeader>
      <Stats.Container>
        <Stats.Stat label='Currency'>
          {currency}
        </Stats.Stat>
        <Stats.Stat label='Bank Name'>
          {institutionName}
        </Stats.Stat>
        <Stats.Stat label='Bank Address'>
          {formatAddress(institutionAddress)}
        </Stats.Stat>
        <Stats.Stat label='Account Type'>
          {formatAccountType(type)}
        </Stats.Stat>
        <Stats.Stat label='Verified'>
          <StyledCheckIcon />
        </Stats.Stat>
      </Stats.Container>
      <Caption color='secondary'>Last Updated {formatDateToTimeAgo(latestBalanceDate)}</Caption>
    </ToggleWell>
  );
};
