import React from 'react';

import { get, getOr, noop } from 'lodash/fp';

import { IVendor } from '@/types/vendor';
import { IAttachment } from '@/types/attachment';

import { H4 } from '@/components/designSystem/Typography';
import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';
import { IDocument, DocumentRow } from '@/components/Document';

import {
  StyledFilesContainer,
  StyledDocumentsContainer,
} from '../styled';

interface IProps {
  reference: IVendor,
  refetch: () => void,
  isEditActive: boolean,
}

export const UploadedDocuments = ({
  reference,
  refetch,
  isEditActive,
}: IProps) => {
  const attachments = getOr([], 'attachments', reference);
  const documents: IDocument[] = attachments.map((file: IAttachment) => ({
    title: file.originalFilename,
    content: file.originalFilename,
    file: file
  }));
  const referenceId = get('id', reference);

  if (attachments.length) {
    return (
      <>
        <H4 bold>Uploaded documents</H4><StyledFilesContainer>
          {
            documents.map((document) => (
              <DocumentRow
                key={document.file.id}
                document={document}
                refetch={refetch}
                onDelete={() => noop}
                enableDelete={false}
              />)
            )
          }
        </StyledFilesContainer>
        {(isEditActive) && (
          <FileUploadField key={`${referenceId}-edit`} name='referenceDocuments' label='Reference Document' />
        )}
      </>
    )
  }

  if (isEditActive) {
    return (
      <>
        <H4 bold>Uploaded documents</H4>
        <StyledDocumentsContainer>
          <H4>None uploaded</H4>
        </StyledDocumentsContainer>
        <FileUploadField key={referenceId} name='referenceDocuments' label='Reference Document' />
      </>
    )
  }

  return null;
}
