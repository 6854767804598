import React from 'react';

import { BodyText, H4 } from '@/components/designSystem/Typography';

import { Callout } from './Callout';

interface IProps {
    title: string,
    children: React.ReactNode,
    color?: string,
    Icon?: React.ReactNode,
}

export const TitleCallout = ({ children, title, color, Icon }: IProps) => (
  <Callout color={color} Icon={Icon}>
    <H4 bold>{title}</H4>
    <BodyText>{children}</BodyText>
  </Callout>
);