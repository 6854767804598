import styled from 'styled-components';

import { H4 } from '@/components/designSystem/Typography';
import { FlexColumn, FlexRowItemsCenter } from '../designSystem/Layout';

interface IProps {
  visible: boolean,
}

export const DropdownContainer = styled.div<IProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.color.shadow.shadowMed};
  margin: 0 1rem;
`;

export const DropdownItemContainer = styled(H4)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.color.pastelBlue};
  }
`;

export const IconContainer = styled.div`
  margin-right: 1.125rem;
  height: 1.125rem;
`;

export const StyledMenuIconWrapper = styled.div`
  border-radius: 50%;
  padding: 1rem 0.75rem;
  cursor: pointer;
  width: fit-content;

  &:hover {
    outline: 1px solid rgb(229 231 235);
    background-color: ${({ theme }) => theme.color.white};
  }
`;

//  ITEMS

export const StyledMenuItem = styled(FlexRowItemsCenter)`
  cursor: pointer;
  padding: 0.5rem;
  flex-direction: row;
  display: flex;
  gap: 0.25rem;
  &:hover {
    background-color: ${({ theme }) => theme.color.greyLight};
  }
`;

export const StyledMenuItems = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.color.white};
  border: ${(props) => props.theme.color.border.light};
  border-radius: 6px;
`;