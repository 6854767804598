import React, { Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router';

import { IBrand, IPublicCompany } from '@/types/publicCompany';

import { BackButton } from '@/components/designSystem/buttons/BackButton';

import { ReactComponent as PoweredByLogo } from '@/assets/powered_by.svg';

import { StepHeader } from '../StepHeader';
import {
  StyledFormContainer,
  StyledLeftBlock,
  StyledMain,
  StyledRightBlock,
  StyledPoweredByContainer,
  StyledCommentContainer,
  // StyledStepCounterContainer,
} from './styled';
import { StyledStepCounterContainer } from '../styled';
import { Callout } from '@/components/Callout/Callout';
import { Flex } from '@/components/designSystem/Layout';
import { BodyText, H3, StyledLinkButtonUnderline } from '@/components/designSystem/Typography';
import { StepCounter } from '@/components/Onboard/StepCounter';
import { WarningPopupIcon } from '@/components/WarningPopupIcon';
import { WARNINGS } from '@/constants';
import { IStep } from './container';

interface IProps {
  children: React.ReactNode,
  overriddenLogo: JSX.Element | null,
  brand: IBrand | undefined
  index: number,
  prevPath: string | null,
  providerCompanyName?: string,
  providerComment?: string,
  steps: IStep[],
  fullApplication: boolean,
  setFullApplication: Dispatch<SetStateAction<boolean>>,
}

export const StepWrapper = ({
  overriddenLogo,
  brand,
  children,
  index,
  prevPath,
  providerCompanyName,
  providerComment,
  steps,
  fullApplication,
  setFullApplication
}: IProps) => {
  const { search } = useLocation();

  return (
    <>
      <StepHeader
        overriddenLogo={overriddenLogo}
        steps={(
          <Flex direction='column' gap='1rem' align='center'>
            <StyledStepCounterContainer steps={Object.values(steps)}>
              <StepCounter steps={steps.map((step) => step.label)} activeStep={index} />
            </StyledStepCounterContainer>
            { !fullApplication ? (
            <Flex align='center'>
              <StyledLinkButtonUnderline onClick={() => setFullApplication(true)}>Show full application</StyledLinkButtonUnderline>
              <WarningPopupIcon description={WARNINGS.SEE_ALL_BUTTON} />
            </Flex>
            )
            :
            <Flex align='center'>
              <StyledLinkButtonUnderline onClick={() => setFullApplication(false)}>Show Requested Steps to be Changed</StyledLinkButtonUnderline>
              <WarningPopupIcon description={WARNINGS.SEE_ALL_BUTTON} />
            </Flex>
            }
          </Flex>
        )}
      >
        {(providerCompanyName && providerComment) ? (
          <StyledCommentContainer>
            <Callout>
              <Flex direction='column' gap='0.5rem'>
                <H3 bold>
                  Requested changes from {providerCompanyName}
                </H3>
                <BodyText>{providerComment}</BodyText>
              </Flex>
            </Callout>
          </StyledCommentContainer>
        ) : false }
      </StepHeader>
      <StyledMain>
        {prevPath ? (
          <StyledLeftBlock>
            <BackButton link={`${prevPath}${search}`} />
          </StyledLeftBlock>
          ) : false }
        <StyledFormContainer>
          {children}
        </StyledFormContainer>
        <StyledRightBlock>
          {(brand) ? (
            <StyledPoweredByContainer href='https://nectarinecredit.com' target='_blank'>
              <PoweredByLogo />
            </StyledPoweredByContainer>
          ) : false }
        </StyledRightBlock>
      </StyledMain>
    </>
  )}
;
