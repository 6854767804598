import styled from 'styled-components';
import DataGrid from "devextreme-react/data-grid";

import { ReactComponent as CheckIcon } from '@/assets/check-circle.svg';
import { ReactComponent as XIcon } from '@/assets/x_circle.svg';
import { ReactComponent as WarningIcon } from '@/assets/warning.svg';

interface IProps {
  isEmpty?: boolean,
  isToolbarAfterMargin?: boolean,
  toolbarRightMargin?: string,
}

export const StyledDataGrid = styled(DataGrid)<IProps>`
  height: 64vh;

  .dx-datagrid-headers {
    border-bottom: none;
    color: ${({ theme }) => theme.color.greyDark};
    background-color: ${({ theme }) => theme.color.greyLight};
  }

  .dx-datagrid-headers + .dx-datagrid-rowsview {
    border-top: none;
  }

  .dx-datagrid-action {
    position: relative;
  }

  .dx-column-indicators {
    position: absolute;
    right: 15px;
  }

  .dx-data-row {
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.color.greyLight};
    }
  }

  .dx-toolbar-after {
    margin-right: ${({ isToolbarAfterMargin, toolbarRightMargin }) => isToolbarAfterMargin ? toolbarRightMargin : 0};
  }

  .dx-datagrid-header-panel {
    height: 3.5rem;
  }
`;
