import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { PrivateRoute } from '@/routes/Routes';

import { UserOnboard } from '@/views/onboard/UserOnboard';
import { VendorOnboard } from '@/views/onboard/VendorOnboard';
import { SeekerSignature } from '@/views/onboard/SeekerSignature';
import { CreditApplicantOnboard } from '@/views/onboard/CreditApplicantOnboard';
import { CreditProviderOnboard } from '@/views/onboard/CreditProviderOnboard';
import { ChangesRequested } from '@/views/onboard/ChangesRequested';

import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';

import { VisitorContext } from '@/providers/VisitorContext';

export const OnboardApp = () => {
  const [visitorData, setVisitorData] = useState<ExtendedGetResult | null>(null);

  const {
    isLoading,
    data,
  } = useVisitorData({ extendedResult: true }, { immediate: true });

  useEffect(() => {
    if (!isLoading && data) {
      setVisitorData(data)
    }
  }, [data, isLoading]);

  return <VisitorContext.Provider value={visitorData}>
    <Switch>
      <Redirect exact from='/signup' to='/signup/provider' />
      <Route path='/signup/provider' component={CreditProviderOnboard} />
      <Route path='/signup/seeker/:companyId' component={CreditApplicantOnboard} />
      <Route path='/signup/seeker' component={CreditApplicantOnboard} />
      <Route path='/signup/user' component={UserOnboard} />
      <Route path='/signup/vendor' component={VendorOnboard} />
      <Route path='/signup/signature/seeker' component={SeekerSignature} />
      <PrivateRoute returnToPath path='/signup/changes-requested/:changeRequestId' component={ChangesRequested} />
    </Switch>
  </VisitorContext.Provider>;
}
