import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { GENERATE_EXPERIAN_REPORT } from '@/graphql/mutations/generateExperianReport';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { H4, BodyText } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Label } from '@/components/Label/Label';
import { MonogramAvatar } from '@/components/MonogramAvatar';

import {
  SearchResultItemContainer,
  Row,
  SearchResultContent,
  ReportTypeButtons,
  StyledBureauButton,
  UpChevron,
  DownChevron,
} from './styled';

interface IProps {
  businessName: string,
  address: string,
  isFirst: boolean,
  bin: string,
  customerId?: string,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: () => void,
}

export const ExperianSearchResultItem = ({
  businessName,
  address,
  isFirst = false,
  bin,
  customerId,
  setIsOpen,
  onSuccess,
}: IProps) => {
  const [showReportType, setShowReportType] = useState(false);
  const premier = 'Premier Profile';
  const intelliscore = 'Intelliscore Plus';
  const title = bin ? `${businessName} (BIN ${bin})` : businessName;
  const reportDescription = `${title}\n${address}`;

  const [generateExperianReport, { loading: loadingReport }] = useMutation(
    GENERATE_EXPERIAN_REPORT,
    {
      onCompleted: () => {
        setIsOpen(false);
        onSuccess();
      },
      onError: () => {
        showToast({
          title: 'Credit Generation Error!',
          description: 'We can not generate this credit report at this time, please try latter.',
          type: toast.TYPE.ERROR,
        });
        setIsOpen(false);
      },
    },
  );
  const loading = loadingReport;

  const onClickShowReporyType = () => {
    if (showReportType) {
      setShowReportType(false);
    } else {
      setShowReportType(true);
    }
  };

  return (
    loading ? <LoadingSpinner />
      : (
        <SearchResultItemContainer onClick={onClickShowReporyType}>
          <Row>
            <MonogramAvatar name={businessName} />
            <SearchResultContent>
              <Row>
                <H4 bold>{businessName}</H4>
                {isFirst && <Label type='success'>Best Match ✨</Label>}
              </Row>
              <BodyText color='secondary'>{address}</BodyText>
              {showReportType
                ? (
                  <ReportTypeButtons>
                    <StyledBureauButton onClick={() => {
                      const reportType = premier; generateExperianReport({
                        variables: {
                          customerId, bin, reportType, reportDescription,
                        },
                      });
                    }}
                    >{premier}
                    </StyledBureauButton>
                    <StyledBureauButton onClick={() => {
                      const reportType = intelliscore; generateExperianReport({
                        variables: {
                          customerId, bin, reportType, reportDescription,
                        },
                      });
                    }}
                    >{intelliscore}
                    </StyledBureauButton>
                  </ReportTypeButtons>
                )
                : null}
            </SearchResultContent>
          </Row>
          {showReportType ? <UpChevron /> : <DownChevron />}
        </SearchResultItemContainer>
      )
  );
};
