import React from 'react';

import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledFormRow, StyledMultiRow } from '@/components/designSystem/Form/styled';
import { H4 } from '@/components/designSystem/Typography';
import { StyledVendorContainer } from './styled';

interface IProps {
  parentKey: string,
  required?: boolean,
  index: number,
}

export const VendorSection = ({
  parentKey,
  required,
  index,
}: IProps) => (
  <StyledVendorContainer>
    <H4 bold>Vendor #{index + 1}{required ? ' (Required)' : ' (Optional)'}</H4>
    <StyledMultiRow>
      <StyledFormRow>
        <TextField required={required} type='text' name={`${parentKey}.companyName`} label='Company Name' placeholder='Company ABC' />
        <TextField required={required} type='text' name={`${parentKey}.name`} label='Contact Full name' placeholder='John Doe' />
      </StyledFormRow>
      <StyledFormRow>
        <PhoneNumberField required={required} name={`${parentKey}.phoneNumber`} label='Phone Number' placeholder='Phone number' />
        <TextField required={required} type='text' name={`${parentKey}.email`} label='Email Address' placeholder='John@streamlink.com' />
      </StyledFormRow>
    </StyledMultiRow>
  </StyledVendorContainer>

);
