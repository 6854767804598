import React, { useEffect } from 'react';

import { FieldArray, useFormikContext } from 'formik';

import { H2, H4 } from '@/components/designSystem/Typography';
import { FlexRowEnd } from '@/components/designSystem/Layout';

import { VendorSection } from './VendorSection';
import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm,
} from '../../styled';
import { IVendorsValues } from './types';

export const VendorsStepForm = () => {
  const { values, isSubmitting, setFieldTouched } = useFormikContext<IVendorsValues>();

  // After form submition with all empty fields only the first vendors array element becomes touched.
  // Maybe the formik issue? This is the fix.
  useEffect(() => {
    if (isSubmitting) {
      for (const vendorIndex in values.requiredVendors) {
        setFieldTouched(`requiredVendors[${vendorIndex}].companyName`);
        setFieldTouched(`requiredVendors[${vendorIndex}].name`);
        setFieldTouched(`requiredVendors[${vendorIndex}].phoneNumber`);
        setFieldTouched(`requiredVendors[${vendorIndex}].email`);
      }
    }
  }, [isSubmitting]);

  return (
    <StyledForm>
      <StyledTitleContainer>
        <H2 bold>Trade and Vendor References</H2>
        <H4>Enter trade and vendor references who you are currently using with balances at least equal to the amount of credit you're applying for.</H4>
      </StyledTitleContainer>
      <FieldArray
        name='requiredVendors'
        render={() => (
          <>
            {
              values.requiredVendors.map((_vendor, index) => (
                <VendorSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={`requiredVendors[${index}]`}
                  parentKey={`requiredVendors[${index}]`}
                  index={index}
                  required
                />
              ))
            }
          </>
        )}
      />
      <FieldArray
        name='optionalVendors'
        render={() => (
          <>
            {
              values.optionalVendors.map((_vendor, index) => (
                <VendorSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={`optionalVendors[${index}]`}
                  parentKey={`optionalVendors[${index}]`}
                  index={index + values.requiredVendors.length}
                />
              ))
            }
          </>
        )}
      />
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Next</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )
};
