import React, { useState } from 'react';
import { useQuery} from '@apollo/react-hooks';
import { GET_REFERENCE_EMAIL_ACTIVITY } from '@/graphql/queries/getReferenceEmailActivity';
import { get, getOr} from 'lodash/fp';
import { H4 } from '@/components/designSystem/Typography';
import { Flex} from '@/components/designSystem/Layout';
import { BodyText } from '@/components/designSystem/Typography';
import { ICustomer } from '@/types/customer';
import { IEmailActivity } from '@/types/activity';
import { StyledEmailActivityContainer, StyledEmailActivityRowContent, StyledEmailActivityRow, StyledToggleIcon } from './styled';

interface IProps {
  customerData?: ICustomer,
  vrContactEmail: string,
  vrCompanyName: string,
}

export const EmailActivities = ({
  customerData,
  vrContactEmail,
  vrCompanyName,
}: IProps) => {
  const [showEmailActivity, setShowEmailActivity] = useState(true);
  const providerCompanyId = get('providerCompany.id', customerData);
  const seekerCompanyId = get('seekerCompany.id', customerData);

  const { data } = useQuery(GET_REFERENCE_EMAIL_ACTIVITY, { variables: { email: vrContactEmail, providerCompanyId: providerCompanyId, seekerCompanyId: seekerCompanyId }});
  const emailActivities = getOr('', 'getReferenceEmailActivity', data);

  if (emailActivities.length) {
    return (
      <>
        <StyledEmailActivityContainer>
          <Flex direction='row' justify='flex-start' onClick={() => setShowEmailActivity(!showEmailActivity)}>
            <H4 bold >Reference Email Activity Feed</H4>
            {!showEmailActivity ? <StyledToggleIcon className={''} /> : <StyledToggleIcon  className={'is-open'}/> }
          </Flex>
          {
            showEmailActivity?
              <>
                <StyledEmailActivityRow>
                  <StyledEmailActivityRowContent direction='row'>
                    <BodyText bold>Date</BodyText>
                    <BodyText bold>Sent To</BodyText>
                    <BodyText bold>Sent By</BodyText>
                    <BodyText bold>Status</BodyText>
                  </StyledEmailActivityRowContent>
                </StyledEmailActivityRow>
                {
                  emailActivities.map((emailActivity: IEmailActivity) => (
                    <StyledEmailActivityRow key={emailActivity.occuredAt}>
                      <StyledEmailActivityRowContent direction='row'>
                        <BodyText>{emailActivity.occuredAt}</BodyText>
                        <BodyText>Vendor-{vrCompanyName}</BodyText>
                        <BodyText>{emailActivity.sentBy}</BodyText>
                        <BodyText>{emailActivity.status}</BodyText>
                      </StyledEmailActivityRowContent>
                    </StyledEmailActivityRow>
                  ))
                }
              </>
              : false
          }
        </StyledEmailActivityContainer>
      </>
    )
  }
  return null;
}
