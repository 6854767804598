import React, { Dispatch, SetStateAction } from 'react';

import { Form, useFormikContext } from 'formik';
import { IProviderSettings } from '@/types/providerSettings';
import { COMPANY_TYPES, SORTED_COUNTRIES, YEARS_OPTIONS } from '@/constants';

import { Button } from '@/components/designSystem/buttons';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { GoogleAddressField } from '@/components/GoogleAddressField';

import { StyledButtonsContainer } from '../../../styled';
import { setFieldsFromGooglePlace } from '../functions';

interface IProps {
  setEditActive: Dispatch<SetStateAction<boolean>>,
  providerSettings: IProviderSettings,
}

export const EditInformationForm = ({ setEditActive, providerSettings }: IProps) => {

  const { setFieldValue } = useFormikContext();

  return (
    <Form>
      <Row>
        <TextField required name='name' type='text' label='Company Name' placeholder='Enter Company Name' />
        <SelectField required name='type' defaultValue='LLC' label='Business type' options={COMPANY_TYPES} />
      </Row>
      <Row>
        <GoogleAddressField
          required
          type='text'
          name='address.address_line_1'
          label='Address line 1'
          placeholder='123 XYZ st. '
          setFieldsFromGooglePlace={(place) => setFieldsFromGooglePlace(place, 'address', setFieldValue)}
        />
        <TextField name='address.address_line_2' type='text' label='Address line 2' placeholder='123 XYZ st.' />
      </Row>
      <Row>
        <TextField required name='address.state' type='text' label='State / Province' placeholder='New York' />
        <TextField required name='address.postal_code' type='text' label='Zip / Postal Code' placeholder='90283' />
      </Row>
      <Row>
        <TextField required name='address.city' type='text' label='City' placeholder='New York' />
        <SelectField required name='address.country' label='Country' options={SORTED_COUNTRIES} />
      </Row>
      {providerSettings.enableShippingAddress ?
        <>
          <Row>
            <GoogleAddressField
              type='text'
              name='shippingAddress.address_line_1'
              label='Shipping Address line 1'
              placeholder='123 XYZ st. '
              setFieldsFromGooglePlace={(place) => setFieldsFromGooglePlace(place, 'shippingAddress', setFieldValue)}
            />
            <TextField name='shippingAddress.address_line_2' type='text' label='Shipping Address line 2' placeholder='123 XYZ st.' />
          </Row>
          <Row>
            <TextField name='shippingAddress.state' type='text' label='Shipping State / Province' placeholder='New York' />
            <TextField name='shippingAddress.postal_code' type='text' label='Shipping Zip / Postal Code' placeholder='90283' />
          </Row>
          <Row>
            <TextField name='shippingAddress.city' type='text' label='Shipping City' placeholder='New York' />
            <SelectField name='shippingAddress.country' label='Shipping Country' options={SORTED_COUNTRIES} />
          </Row>
        </>
        : false
      }
      {providerSettings.enableShippingAddress ?
        <>
          <Row>
            <GoogleAddressField
              type='text'
              name='billingAddress.address_line_1'
              label='Billing Address line 1'
              placeholder='123 XYZ st. '
              setFieldsFromGooglePlace={(place) => setFieldsFromGooglePlace(place, 'billingAddress', setFieldValue)}
            />
            <TextField name='billingAddress.address_line_2' type='text' label='Billing Address line 2' placeholder='123 XYZ st.' />
          </Row>
          <Row>
            <TextField name='billingAddress.state' type='text' label='Billing State / Province' placeholder='New York' />
            <TextField name='billingAddress.postal_code' type='text' label='Billing Zip / Postal Code' placeholder='90283' />
          </Row>
          <Row>
            <TextField name='billingAddress.city' type='text' label='Billing City' placeholder='New York' />
            <SelectField name='billingAddress.country' label='Billing Country' options={SORTED_COUNTRIES} />
          </Row>
          <Row>
            <SelectField required name='established' label='Year established' options={YEARS_OPTIONS} />
            <TextField required name='website' type='text' label='Website' placeholder='www.company.com' />
            <PhoneNumberField required name='phoneNumber' label='Phone number' placeholder='Phone number' />
          </Row>
        </>
        : false
      }
      <Row>
        <TextField
          required
          type='text'
          name='description'
          label='Business details'
          placeholder='Are you a retailer, distributor, manufacturer and in which industry?'
        />
      </Row>
      <StyledButtonsContainer>
        <Button onClick={() => setEditActive(false)} secondary>Cancel</Button>
        <Button type='submit' primary>Save</Button>
      </StyledButtonsContainer>
    </Form>
  )};
