import React, { useState, useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { ICompany } from "@/types/company";

import { StyledModal } from '@/modals/StyledModal';
import { Button } from '@/components/designSystem/buttons';
import { H3, StyledLinkButtonUnderline, Caption, } from '@/components/designSystem/Typography';
import { CreditSafeRewardModal } from '@/modals/CreditSafeRewardModal';
import { FlexColumnItemsCenter, FlexRowSpaceEvenly } from '../components/designSystem/Layout';

const Container = styled(FlexColumnItemsCenter)`
  justify-content: space-between;
  padding: 20px;
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const StyledH4 = styled.div`
  margin-top: 25px;
  font-size: 15px;
`;

const ActionContainer = styled(FlexRowSpaceEvenly)`
  padding: 1.5rem;
  border-top: ${({ theme }) => theme.color.border.light};

  & > * {
    margin-right: 1rem;
  }

  & > :last-child{
    margin-right: 0;
  }
`;

export const StyledCaption = styled(Caption)`
 font-size: 18px;
 line-height: 28px;
 text-align: center;
 color: ${({ color }) => color};
 padding-bottom: 10px;
`;

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  CPCompanyName: string,
  CACompanyName: string,
  referralCode: string,
  vrRewardReportURL?: string,
  enableVrCreditSafeIncentive: boolean,
}

export const VrCompletedModal = ({
  isOpen, setIsOpen, CPCompanyName, CACompanyName, referralCode, vrRewardReportURL, enableVrCreditSafeIncentive
}: IProps) => {
  const [showCreditSafeSearchModal, setShowCreditSafeSearchModal] = useState(false);
  const [signedURL, setSignedURL] = useState<string | undefined>();

  useEffect(() => {
    setSignedURL(vrRewardReportURL);
  }, []);

  const ReportDownloaded = (signedUrl: string) => {
    setSignedURL(signedUrl);
  }

  return (
    <>
      <StyledModal
        title='Thank you for being a vendor reference.'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disableClose
        blockClose
      >
        <Container>
          <H3>We have processed your reference for {CACompanyName} and we've passed it on to {CPCompanyName}.</H3>
          {/* <StyledH4>If you want to view your company's vendor references and complete your company's profile, click
        <StyledLink className='bold-link' color='brand' to='/signup'> here</StyledLink>.</StyledH4> */}
        </Container>
        {
          signedURL ?
            <>
              <ActionContainer>
                <StyledButton
                  onClick={() => window.location.assign('https://www.nectarinecredit.com/schedule-demo')}
                >
                  Learn More
                </StyledButton>
                <StyledButton
                  primary
                  onClick={() => window.location.assign('https://calendly.com/alex-1257/30min')}
                >
                  Book a Demo
                </StyledButton>
              </ActionContainer>
              <StyledCaption>
            Click <StyledLinkButtonUnderline onClick={(e) => {window.open(signedURL,'_blank',);e.preventDefault();}}>here</StyledLinkButtonUnderline> to download your free credit report.
              </StyledCaption>
            </>
            :
            <>
              {enableVrCreditSafeIncentive && (
                <ActionContainer>
                  <StyledButton primary onClick={() => setShowCreditSafeSearchModal(true)}>Get Your Free Creditsafe Report</StyledButton>
                </ActionContainer>
              )}
            </>
        }
      </StyledModal>
      <CreditSafeRewardModal
        isOpen={showCreditSafeSearchModal}
        setIsOpen={setShowCreditSafeSearchModal}
        referralCode={referralCode}
        onSuccess={(signedUrl) => ReportDownloaded(signedUrl)}
      />
    </>
  );
};
