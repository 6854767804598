import React, { Dispatch, SetStateAction } from 'react';

import { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_DNB_SETTINGS } from '@/graphql/mutations/updateDnbSettings';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';
import { Callout } from '@/components/Callout/Callout';
import { BodyText } from '@/components/designSystem/Typography';

const validation = Yup.object().shape({
  dnbApiKey: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  dnbApiSecret: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

// https://github.com/apollographql/apollo-client/pull/3892 This should be removed with an updated graphql
const extractGQLErrorMessage = (error: Error) => {
  const { 1: errorMessage } = error.message.split('GraphQL error: ');
  return errorMessage;
};

interface IFields {
  dnbApiKey: string,
  dnbApiSecret: string
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess?: (response: FetchResult) => void
}

export const DnbCredentialsModal = ({
  isOpen, setIsOpen, onSuccess
}: IProps) => {
  const [updateCompanySettings, { loading }] = useMutation(UPDATE_DNB_SETTINGS);

  // eslint-disable-next-line no-shadow
  const handleSubmit = async ({
    dnbApiKey, dnbApiSecret
  }: IFields) => {
    const variables = {
      settings: {
        dnb_api_key: dnbApiKey,
        dnb_api_secret: dnbApiSecret,
      },
    };

    updateCompanySettings({ variables })
      .then((response) => {
        showToast({
          title: 'Credentials Successfully Update!',
          description: 'You are now able to request D&B Credit Reports for your customers',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        showToast({
          title: 'Credentials Update Failed!',
          description: error.message,
          type: toast.TYPE.ERROR,
        });
        setIsOpen(false);
      });
  };

  const fields = {
    dnbApiKey: '',
    dnbApiSecret: '',
  };

  return (
    <StyledModalForm
      title='Login to D&B'
      submitButtonText='Confirm'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <Callout color='pastelRed'>
        <BodyText>Please ask your D&B representative for API credentials, as these credentials are different from your typical D&B login.</BodyText>
      </Callout>
      <TextField required type='text' name='dnbApiKey' label='D&B Consumer/API Key' placeholder='D&B Consumer/API Key' />
      <TextField required type='password' name='dnbApiSecret' label='D&B Consumer/API Secret' placeholder='*****' />
    </StyledModalForm>
  );
};
