import { useEffect, useState } from 'react';
import { deleteFromStorage, writeStorage } from '@rehooks/local-storage';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { COMPANY_PERSONNEL_EMAILS } from '@/graphql/queries/getCompanyPersonnelEmails';
import { IS_COMPANY_PERSONNEL } from '@/graphql/queries/isCompanyPersonnel';

import { get } from 'lodash/fp';

export const useCompanyPersonnelData = (sanitizedCompanyId: string | null, authLoading: boolean) => {
  const [isLogoutModalActive, setLogoutModalActive] = useState<boolean | null>(null);

  const {
    loading: isCompanyPersonnelLoading,
    data: isCompanyPersonnelData,
    error: isCompanyPersonnelError
  } = useQuery(IS_COMPANY_PERSONNEL, { variables: { companyId: sanitizedCompanyId }, skip: authLoading });

  const [
    getCompanyPersonnelEmails,
    {
      data: getCompanyPersonnelEmailsData,
    },
  ] = useLazyQuery(COMPANY_PERSONNEL_EMAILS, { variables: { companyId: sanitizedCompanyId } });

  const isCompanyPersonnel = get('isCompanyPersonnel', isCompanyPersonnelData);

  useEffect(() => {
    if (isCompanyPersonnel) {
      getCompanyPersonnelEmails();
      setLogoutModalActive(true);
    }
  }, [isCompanyPersonnel]);

  useEffect(() => {
    if (getCompanyPersonnelEmailsData) {
      writeStorage('emails', getCompanyPersonnelEmailsData.companyPersonnelEmails);
    }
  }, [getCompanyPersonnelEmailsData]);

  const cleanLocalStorage = () => {
    setLogoutModalActive(false);
    deleteFromStorage('token');
    deleteFromStorage('company-id');
    deleteFromStorage('customer-id');
  };

  return {
    isCompanyPersonnelLoading,
    isCompanyPersonnelData,
    isCompanyPersonnelError,
    isCompanyPersonnel,
    isLogoutModalActive,
    cleanLocalStorage,
  };
};
