import styled from 'styled-components';
import { Form } from 'formik';
import { Flex } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';
import { H2 } from '@/components/designSystem/Typography';
import device from '@/styles/device';

import { ReactComponent as ClockIcon } from '@/assets/clock.svg';
import { ReactComponent as ClipboardIcon } from '@/assets/clipboard.svg';
import { ReactComponent as ShieldIcon } from '@/assets/shield.svg';

export const StyledContainer = styled(Flex)`
  width: 100%;
  padding: 5rem;
  border: 1px solid ${({ theme }) => theme.color.brand};
  border-radius: 16px;
  background: radial-gradient(at 100% 40%, ${({ theme }) => theme.color.brand}, ${({ theme }) => theme.color.white} 60%);
  max-width: 1280px;
  
  @media ${device.tablet} {
    padding: 2rem 1rem;
  };
  
  @media ${device.mobileXL} {
    flex-direction: column;
    gap: 2rem;
    background: linear-gradient(to bottom, ${({ theme }) => theme.color.white} 40%, ${({ theme }) => theme.color.brand} 100%);
  };
`;

export const StyledLeftContainer = styled(Flex).attrs({ direction: 'column', gap: '1rem' })`
  flex-basis: 50%;
`

export const StyledRightContainer = styled(Flex).attrs({ justify: 'flex-end' })`
 flex-basis: 50%;
`

export const StyledRightMessageWrapper = styled(Flex).attrs({ direction: 'column', gap: '1.5rem' })`
  min-width: 60%;
  max-width: 20rem;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  padding: 2rem;
  
  @media ${device.mobileXL} {
    width: 100%;
    max-width: 100%;
  };
`

export const StyledClockIcon = styled(ClockIcon)`
  fill: ${({ theme }) => theme.color.brand};
`;

export const StyledClipboardIcon = styled(ClipboardIcon).attrs({ width: '24px', height: '24px', viewBox: '2 0 14 20' })`
  min-width: 25px;
  & > path {
    stroke: ${({ theme }) => theme.color.brand};
  }
`;

export const StyledShieldIcon = styled(ShieldIcon)`
  min-width: 25px;
  & > path {
    stroke: ${({ theme }) => theme.color.brand};
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: bottom;
`;

export const StyledFieldsSectionContainer = styled(Flex).attrs({ direction: 'column' })`
  width: 100%;
`;

export const StyledLinkButton = styled.button`
  margin-top: 1rem;
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.color.brand};

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledButtonMargin = styled(Button)`
  margin-top: 1rem;
`;

export const StyledH2Margin = styled(H2)`
  margin-bottom: 1rem;
`;
