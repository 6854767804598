import React, { useReducer } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { ONBOARDING_PATH } from '@/constants';

import { basicInfoInitialValues } from './steps/BasicInfoStep/initialValues';
import { companyProfileInitialValues } from './steps/CompanyProfileStep/initialValues';
import { companyContactsInitialValues } from './steps/CompanyContactsStep/initialValues';

import { StepWrapper } from './StepWrapper';
import { OnboardValuesContext } from './context';
import { StyledContainer } from './styled';
import { DEFAULT_STEP } from './constants';
import { onboardValuesReducer } from './reducer';
import { BasicInfoStep } from './steps/BasicInfoStep';
import { CompanyProfileStep } from './steps/CompanyProfileStep';
import { CompanyContactsStep } from './steps/CompanyContactsStep';

const getInitialOnboardValues = (
  basePath: string
) => ({
  basicInfo: basicInfoInitialValues,
  companyProfile: companyProfileInitialValues,
  companyContacts: companyContactsInitialValues,

  basePath,
})

export const CreditProviderOnboard = () => {
  const stepToRedirectTo = DEFAULT_STEP;
  const startPath = `${ONBOARDING_PATH.PROVIDER}/${stepToRedirectTo}`;

  //

  const [onboardValues, dispatch] = useReducer(
    onboardValuesReducer,
    getInitialOnboardValues(
      ONBOARDING_PATH.PROVIDER
    )
  );

  const steps = [
    {
      path: 'basicinfo',
      Component: BasicInfoStep,
    },
    {
      path: 'companyprofile',
      Component: CompanyProfileStep,
    },
    {
      path: 'companycontacts',
      Component: CompanyContactsStep,
    },
  ];

  return (
    <StyledContainer>
      <Switch>
        <OnboardValuesContext.Provider value={
          onboardValues
        }>
          {steps.map(({
            path, Component, stepProps
            // Fix any
          } : any, index) => (
            <Route
              key={path}
              path={`${ONBOARDING_PATH.PROVIDER}/${path}`}
              render={() =>
                <StepWrapper
                  prevPath={index ? `${ONBOARDING_PATH.PROVIDER}/${steps[index - 1].path}` : null}
                  index={index}
                >
                  <Component dispatch={dispatch} />
                </StepWrapper>
              }
            />
          ))}
          <Redirect from={ONBOARDING_PATH.PROVIDER} to={startPath} />
        </OnboardValuesContext.Provider>
      </Switch>
    </StyledContainer>
  )
}