import React from 'react';

import { BodyText } from "@/components/designSystem/Typography";

import { StyledAgreementContainer } from './styled';

export interface IProps {
    agreementText: string,
  }

export const Agreement = ({ agreementText }: IProps) => (
  <StyledAgreementContainer>
    <BodyText>{agreementText}</BodyText>
  </StyledAgreementContainer>
);
