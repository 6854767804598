import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { DELETE_CUSTOMER } from '@/graphql/mutations/deleteCustomer';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { OldConfirmActionModal } from '@/modals/OldConfirmActionModal';
import { BodyText } from '@/components/designSystem/Typography';

import { ReactComponent as Trash } from '@/assets/trash_gray.svg';

import { DeleteIcon } from './styled';

interface IProps {
    customerId?: string,
}

export const DeleteCustomer = ({ customerId }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useHistory();
  const [deleteCustomerMutation] = useMutation(DELETE_CUSTOMER);

  const deleteCustomer = () => {
    deleteCustomerMutation({ variables: { customerId } })
      .then(() => {
        showToast({
          title: 'Customer Deleted',
          description: 'Your customer has been successfully removed',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        navigate.push('/dashboard/customers');
      });
  };

  return (
    <>
      <DeleteIcon onClick={() => setIsOpen(true)}><Trash width='24' height='24' /></DeleteIcon>
      <OldConfirmActionModal
        title='Delete Customer'
        buttonText='Delete'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        action={deleteCustomer}
      >
        <BodyText bold>Are you sure you want to delete this Customer?</BodyText>
      </OldConfirmActionModal>
    </>
  );
};
