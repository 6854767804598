import styled, { keyframes, css } from 'styled-components';
import device from '@/styles/device';

const slideInRightToLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideOutLeftToRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

const slideInLeftToRight = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideOutRightToLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

interface ISlideOverWrapperProps {
  isOpen: boolean;
  isClosing: boolean;
  width?: string;
  height?: string;
  position?: string;
  backgroundColor?: string;
}

export const SlideOverWrapper = styled.div<ISlideOverWrapperProps>`
  width: ${({ width }) => width || '30rem'};
  height: ${({ width }) => width || '100%'};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  background-color: ${({ backgroundColor }) => backgroundColor || '#ffffff'};
  position: fixed;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  top: 0px;
  ${({ position }) => (position === 'left' ? 'left: 0px;' : 'right: 0px;')}
  transition: transform 0.3s ease-in-out;
  z-index: 10;

  ${({ isClosing, position }) =>
    !isClosing
      ? position === 'left'
        ? css`
          animation: ${slideInLeftToRight} 0.3s forwards;
        `
        : css`
            animation: ${slideInRightToLeft} 0.3s forwards;
          `
      : position === 'left'
        ? css`
            animation: ${slideOutRightToLeft} 0.3s forwards;
          `
        : css`
            animation: ${slideOutLeftToRight} 0.3s forwards;
          `}
  
  @media ${device.tablet} {
    width: 20rem;
  };
`;

export const SlideOverContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledCloseButton = styled.button`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  color: ${({ theme }) => theme.color.white};
`;