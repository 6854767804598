import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { GET_CREDIT_SAFE_RESULTS_FOR_REWARD } from '@/graphql/queries/getCreditSafeResultsForReward';
import { GENERATE_CREDIT_SAFE_REPORT_FOR_REWARD } from '@/graphql/mutations/generateCreditSafeReportForReward';

import { Formik } from 'formik';

import { get, getOr } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ICompany } from "@/types/company";

import { SearchResultItem } from '@/views/CustomerPage/tabs/BureauReport/SearchResultItem';
import { SearchForm } from '@/views/CustomerPage/tabs/BureauReport/SearchForm';
import { searchValidation } from '@/views/CustomerPage/tabs/BureauReport/common';
import { ContentContainer } from '@/modals/StyledModal/styled';
import { Caption, H4 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as DownloadIcon } from '@/assets/download.svg';

import {
  StyledForm,
  StyledContainer,
  StyledNoResultsText,
} from './CreditReportModal/styled';
import { useCreditReportModal } from './CreditReportModal/useCreditReportModal';
import { ReportModalFooter } from '../views/CustomerPage/tabs/BureauReport/modals/ReportModalFooter';
import { CreditReportModalContainer } from './CreditReportModal/CreditReportModalContainer';
import { FlexRowItemsCenter } from '../components/designSystem/Layout';

const ButtonContainer = styled(FlexRowItemsCenter)`
  justify-content: center;
  margin: 1.5rem;
`;

export const StyledCaption = styled(Caption)`
 font-size: 32px;
 line-height: 48px;
 font-weight: bold;
 text-align: center;
 color: ${({ color }) => color};
`;

interface IVariables {
  creditSafeId: string,
  reportDescription: string,
  referralCode: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  referralCode: string,
  onSuccess: (signedURL: string) => void,
}

export const CreditSafeRewardModal = ({
  isOpen,
  setIsOpen,
  referralCode,
  onSuccess,
}: IProps) => {
  const [signedURL, setSignedURL] = useState<string | null>(null);

  const {
    showSearch,
    setShowSearch,
    searchInput,
    data,
    generateReport,
    loading,
    handleSubmit
  } = useCreditReportModal(
    isOpen,
    GET_CREDIT_SAFE_RESULTS_FOR_REWARD,
    GENERATE_CREDIT_SAFE_REPORT_FOR_REWARD,
  )

  const searchFields = {
    name: '',
    city: '',
    province: '',
    country: 'US',
  };

  const companies = getOr([], 'getCreditSafeResultsForReward.companies', data);

  const getRewardReport = (variables: IVariables) => {
    generateReport({variables: variables})
      .then((reportData) => {
        setSignedURL(get('data.generateCreditSafeReportForReward.signedUrl', reportData));
        //window.open(signedURL,'_blank',);
      });
  }

  const downloaded = () => {
    setIsOpen(false);
    if (signedURL) {
      onSuccess(signedURL);
    }
  }

  const renderTitle = () => {
    if (showSearch) {
      return signedURL
        ? 'Your free credit report is ready.'
        : 'Get your Creditsafe report. Select a company.'
    }
    return 'Get your Creditsafe report. Search here.';
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />
    }
    if (companies.length === 0) {
      return (
        <>
          <StyledNoResultsText>No results found for the following input:</StyledNoResultsText>
          <pre>{JSON.stringify(searchInput, null, 5).replace(/[{}]/g, '')}</pre>
          <H4>Please go back and refine your search.</H4>
        </>
      )
    }
    return companies.map((company: ICompany, index: number) => {
      const { address, name, safeNo } = company;
      const simpleAddress = get('simpleValue', address);
      const title = safeNo ? `${name} (No. ${safeNo})` : name;
      const reportDescription = `${title}\n${simpleAddress}`;
      return (
        <SearchResultItem
          key={company.id}
          isFirst={index === 0}
          onClick={() => getRewardReport({ creditSafeId: company.id, reportDescription, referralCode })}
          {...company}
        />
      );
    })
  };

  return (
    <CreditReportModalContainer
      title={renderTitle()}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      disableClose
    >
      {
        signedURL ?
          <>
            <StyledCaption>Click the download button to view it.</StyledCaption>
            <ButtonContainer>
              <Button
                small
                onClick={(e) => {
                  window.open(
                    signedURL,
                    '_blank',
                  );
                  e.preventDefault();
                  downloaded();
                }}
                LeftIcon={DownloadIcon}
              >
              Download
              </Button>
            </ButtonContainer>
          </>
          :
          <Formik
            onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
            initialValues={searchFields}
            validationSchema={searchValidation}
            validateOnChange
            enableReinitialize
          >
            <StyledForm>
              <ContentContainer maxHeight='80vh'>
                {
                  showSearch
                    ? (
                      <StyledContainer>
                        {renderContent()}
                      </StyledContainer>
                    )
                    : <SearchForm />
                }
              </ContentContainer>
              <ReportModalFooter
                showSearch={showSearch}
                setShowSearch={setShowSearch}
                loading={loading}
              />
            </StyledForm>
          </Formik>
      }
    </CreditReportModalContainer>
  );
};