import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { UPDATE_REFERRAL } from '@/graphql/mutations/updateReferral';
import { GET_REFERRAL_DATA } from '@/graphql/queries/getReferralData';
import { SEND_MANUAL_REFERRAL_FOLLOW_UP } from '@/graphql/mutations/sendManualFollowUp';

import { get, getOr } from 'lodash/fp';

import { useRedirect } from '@/providers/Redirect/context';

import { IReferral } from '@/types/referral';

import { BorderedContainer } from '@/containers/BorderedContainer';
import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { SendPendingInviteModal } from '@/modals/SendPendingInviteModal';
import { LabeledComponent } from '@/components/LabeledComponent';
import { TitleCallout } from '@/components/Callout/TitleCallout';
import { ActivitySection } from '@/views/VendorReferencePage/ActivitySection';
import { ContactInfoSection } from '@/components/_oldComponents/sections/ContactInfoSection';
import { CompanyInfoSection } from '@/components/_oldComponents/sections/CompanyInfoSection';
import { VendorReferencesSection } from '@/components/_oldComponents/sections/VendorReferencesSection';
import { CompanyContactSection } from '@/components/_oldComponents/sections/CompanyContactSection';
import { FinancialHealthSection } from '@/components/_oldComponents/sections/FinancialHealth';
import { BodyText, StyledExternalLink } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';

import { CustomerSettingsMenu } from './SettingsMenu/CustomerSettings';

interface ISettingsValues {
  autoBav: boolean,
  requirePersonalGuarantee: boolean,
}

const SendApplicationButton = styled(Button)`
  margin-top: 1rem;
`;

interface IInviteSentMessageProps {
  referral: IReferral,
}

const InviteSentMessage = ({ referral }: IInviteSentMessageProps) => {
  const toCompanyName = get('toCompanyName', referral);
  const sentDefault = get('sent', referral);

  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(sentDefault);

  return (
    sent
      ? (
        <TitleCallout title='Invite Sent' color='pastelBlue'>
          An invite to complete your credit application has been sent to {toCompanyName}. You will get an email as soon as they complete the application and it's ready for you to review.
        </TitleCallout>
      )
      : (
        <TitleCallout title='Credit Application Not Yet Sent!' color='pastelOrange'>
          An invite to complete your credit application has not yet been sent to {toCompanyName}. Do you want to invite them to fill out your Credit Application?
          <SendApplicationButton primary small onClick={() => setOpen(true)}>Send Credit Application</SendApplicationButton>
          <SendPendingInviteModal
            isOpen={open}
            setIsOpen={setOpen}
            onSuccess={() => setSent(true)}
            referral={referral}
          />
        </TitleCallout>
      )

  );
};

interface IInviteSectionProps {
  referral: IReferral,
}

const InviteSection = ({ referral }: IInviteSectionProps) => {
  const toName = get('toName', referral);
  const toEmail = get('toEmail', referral);
  const toPhoneNumber = get('toPhoneNumber', referral);
  const toCompanyName = get('toCompanyName', referral);
  const referralUrl = get('referralUrl', referral);
  return (
    <TwoColumnContainer.MainItem title='Invite Information'>
      <BorderedContainer disableDivider>
        <LabeledComponent label='Name'>
          <BodyText>{toName}</BodyText>
        </LabeledComponent>

        <LabeledComponent label='Email'>
          <BodyText>{toEmail}</BodyText>
        </LabeledComponent>

        <LabeledComponent label='Phone Number'>
          <BodyText>{toPhoneNumber}</BodyText>
        </LabeledComponent>

        <LabeledComponent label='Company Name'>
          <BodyText>{toCompanyName}</BodyText>
        </LabeledComponent>

        <LabeledComponent label='Invite Link'>
          <StyledExternalLink href={referralUrl} target='_blank'>{referralUrl}</StyledExternalLink>
        </LabeledComponent>

      </BorderedContainer>

    </TwoColumnContainer.MainItem>
  );
};

interface IParams {
  referralCode: string,
}

export const VendorReferencePage = () => {
  const { referralCode } = useParams<IParams>();
  const { toCustomerPage } = useRedirect();
  const { loading, data, refetch } = useQuery(
    GET_REFERRAL_DATA,
    {
      variables: { referralCode },

      // this is nessesary as the query returns partial data when the customer is not logged in
      errorPolicy: 'all',
    },
  );
  const referral = get('getReferralData', data);
  const referralId = get('id', referral);
  const sent = get('sent', referral);
  const isComplete = getOr(false, 'complete', referral);
  const customerId = get('customer.id', referral);
  const lastSaved = get('updatedAt', referral);
  const optOut = get('optOut', referral);
  const optOutAt = get('optOutAt', referral);
  const activities = getOr([], 'activityFeed', referral);

  const [sendFollowup, { loading: followupLoading }] = useMutation(
    SEND_MANUAL_REFERRAL_FOLLOW_UP,
    {
      variables: { referralId },
      onCompleted: () => {
        showToast({
          title: 'Follow Up Sending',
          description: `Your follow up has been sent to ${get('toCompanyName', referral)}. You will see it in your activty feed shortly.`,
          type: toast.TYPE.SUCCESS,
        });
        refetch();
      },
    },
  );

  useEffect(() => {
    if (isComplete && customerId) {
      toCustomerPage(customerId);
    }
  }, [isComplete]);

  const initialSettings = {
    autoBav: getOr(false, 'autoBav', referral),
    requirePersonalGuarantee: getOr(false, 'requirePersonalGuarantee', referral),
  };

  const [updateReferral] = useMutation(UPDATE_REFERRAL);

  const saveSettings = (settings: ISettingsValues, { setSubmitting }: { setSubmitting: (arg: boolean) => void }) => {
    const variables = {
      referralId,
      referralAttrs: {
        auto_bav: settings.autoBav,
        require_personal_guarantee: settings.requirePersonalGuarantee,
      },
    };

    return updateReferral({ variables })
      .then((response) => {
        const newUpdatedAt = get('data.updateReferral.updatedAt', response);
        setSubmitting(false);
        return newUpdatedAt;
      });
  };

  return (
    <TwoColumnContainer.Container
      title={loading ? 'Loading...' : `Invite for ${get('toCompanyName', referral)}`}
      backTitle='Customer Dashboard'
      backTo='/dashboard/customers'
    >
      <TwoColumnContainer.MainColumn>
        {!loading && <InviteSentMessage referral={referral} />}
        <InviteSection referral={referral} />
        <CompanyContactSection />
        <FinancialHealthSection refetch={refetch} />
        <VendorReferencesSection />
      </TwoColumnContainer.MainColumn>

      <TwoColumnContainer.SecondaryColumn>
        <CompanyInfoSection
          action={<CustomerSettingsMenu
            initialValues={initialSettings}
            onSubmit={saveSettings}
            lastSaved={lastSaved}
            loading={loading}
            isCustomer={false}
            referralId={referralId} />} />
        <ContactInfoSection />
        <TwoColumnContainer.SecondaryItem title='Supporting Information' />
        {sent && <ActivitySection activities={activities} handleManualSend={sendFollowup} enableManualSend={!isComplete} loading={followupLoading} />}
      </TwoColumnContainer.SecondaryColumn>
    </TwoColumnContainer.Container>
  );
};
