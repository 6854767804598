import React from 'react';

import { ErrorMessage, useField } from 'formik';
import { StyledErrorText, StyledFieldContainer, StyledTextArea } from './styled';
import { FieldLabel } from './FieldLabel';

interface IProps {
  name: string,
  label?: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  maxRows?: number,
  minRows?: number,
}

export const TextAreaField = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  maxRows = 3,
  minRows = 3,
}: IProps) => {
  const [field, meta, helpers] = useField(name);

  const { touched, error } = meta;

  const showError = !!(touched && error);

  const { setValue } = helpers;

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <StyledTextArea
        minRows={minRows}
        maxRows={maxRows}
        disabled={disabled}
        placeholder={placeholder}
        value={field.value}
        name={name}
        onChange={(e) => setValue(e.target.value)}
        error={showError}
      />
      <StyledErrorText>
        { showError ? <ErrorMessage name={name} /> : false }
      </StyledErrorText>
    </StyledFieldContainer>
  )
};
