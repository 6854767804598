import styled from 'styled-components';

import { BorderedContainer } from '@/containers/BorderedContainer';
import { BodyText } from '@/components/designSystem/Typography';
import { Button } from '@/components/designSystem/buttons';
import { FlexColumn, FlexRow } from '@/components/designSystem/Layout';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 2.5rem;
  }
`;

export const StyledCustomFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledActionContainer = styled(FlexRow)`
  flex: 0 0 auto;
  margin-right: 0;
  margin-left: auto;
`;

export const StyledFieldDetails = styled(FlexColumn)`
  border: none;
`;

export const StyledRequiredText = styled.span`
  padding: 2px 5px 2px 5px;
  margin-left: 3px;
  border-radius: 10%;
  font-weight: bold;
  background-color: #FBEAE5;
  color: #D14677;
`;

export const StyledEditButton = styled(Button)`
  flex: 0 0 auto;
  margin-right: 0;
  margin-left: auto;
  padding: 13px;
  margin-top: 5px;
  margin-right: 5px;
`;

export const StyledDeleteButton = styled(Button)`
  flex: 0 0 auto;
  margin-right: 0;
  margin-left: auto;
  padding: 13px;
  margin-top: 5px;
`;

export const StyledBodyText = styled(BodyText)`
  font-size: 12px;
`;

export const StyledBorderedContainer = styled(BorderedContainer)`
  flex: 1 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 60px;
  padding-top: 0px;
`;

export const StyledCustomFieldsContainer = styled(FlexColumn)`
  & > * {
    margin-bottom: 1rem;
  }
`;
