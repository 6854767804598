import React, { useState } from 'react';

import { CreditSafeCredentialsModal } from '@/modals/CreditSafeCredentialsModal';
import { DnbCredentialsModal } from '@/modals/DnbCredentialsModal';
import { EquifaxCredentialsModal } from '@/modals/EquifaxCredentialsModal';
import { ExperianCredentialsModal } from '@/modals/ExperianCredentialsModal';

import { Button } from '@/components/designSystem/buttons';

import { INTEGRATIONS } from './constants';

interface IProps {
  type: string,
  refetch: () => void,
  username?: string,
  subcode?: string,
}

export const EditCredsButton = ({ type, refetch, username, subcode }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderModal = () => {
    if (type === INTEGRATIONS.EXPERIAN) {
      return (
        <ExperianCredentialsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSuccess={refetch}
          username={username}
          subcode={subcode}
        />
      )
    }

    if (type === INTEGRATIONS.EQUIFAX) {
      return (
        <EquifaxCredentialsModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} />
      )
    }

    if (type === INTEGRATIONS.DNB) {
      return (
        <DnbCredentialsModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} />
      )
    }

    if (type === INTEGRATIONS.CREDIT_SAFE) {
      return (
        <CreditSafeCredentialsModal isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={refetch} username={username} />
      )
    }

    return null;
  }

  return (
    <>
      <Button primary small onClick={() => setIsOpen(true)}>Edit</Button>
      {renderModal()}
    </>
  )
}