import styled from 'styled-components';
import { Form } from 'formik';

import { BodyText } from '@/components/designSystem/Typography';
import { FlexColumn, FlexColumnItemsCenter } from '@/components/designSystem/Layout';

export const StyledContainer = styled(FlexColumnItemsCenter)`
  height: 100%;
`;

export const StyledCustomerOptionsContainer = styled(FlexColumn)`
  margin-top: 2.5rem;
  flex-grow: 1;
  gap: 1rem;
`;

export const StyledBodyText = styled(BodyText)`
  margin-top: -1.5rem;
`;

export const StyledCheckboxContainer = styled(FlexColumn)`
  width: 100%;
  padding-bottom: 1rem;
`;

export const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledFormInnerContainer = styled.div`
  overflow-y: scroll;
  padding: 1.5rem 3.5rem;
`;

export const StyledFormContainer = styled.div`
  height: calc(100% - 6rem);
  width: 100%;
`;

export const StyledDescriptionTextContainer = styled.div`
  margin-bottom: 1rem;
`