import { useState, useEffect } from 'react';

import { INavOptions } from '@/types/navOptions';

import { NAV_ITEMS, ROLES } from '@/constants';

export const useNavOptions = (roles: string[], options: INavOptions) => {
  const [navOptions, setNavOptions] = useState(options);

  useEffect(() => {
    let filteredOptions = options.map(section => [...section]);

    if (roles.length) {
      if (!roles.includes(ROLES.CP)) {
        filteredOptions = filteredOptions.map(section =>
          section.filter(option => option.title !== NAV_ITEMS.APPLICATIONS && option.title !== NAV_ITEMS.CUSTOMERS && option.title !== NAV_ITEMS.TEMPLATE)
        );
      }
      if (!roles.includes(ROLES.CS)) {
        filteredOptions[0] = filteredOptions[0].filter(option => option.title !== NAV_ITEMS.APPLICANTS);
      }
      if (!roles.includes(ROLES.VENDOR)) {
        filteredOptions[0] = filteredOptions[0].filter(option => option.title !== NAV_ITEMS.REFERENCES);
      }
    }
    
    setNavOptions(filteredOptions);
  }, [roles]);

  return navOptions;
};
