import gql from 'graphql-tag';

export const INTERNAL_REVIEW = gql`
  query InternalReview($customerId: ID!) {
    getCustomerAsProvider(customerId: $customerId) {
      id
      latestApplication {
        creditLimit
        creditTerms
      }
    }
    listCustomFields(kind: "review" ) {
      id
      type
      label
      description
      required
      allowMultipleUploads
      options
      contact {
        showName
        nameRequired
        showEmail
        emailRequired
        showPhone
        phoneRequired
        positionRequired
        showExtension
        extensionRequired
      }
      yesToggle {
        type
        allowMultipleUploads
        description
      }
      noToggle {
        type
        allowMultipleUploads
        description
      }
    }
  }
`;

export const CREATE_REVIEW = gql`
  mutation CreateReview(
    $customerId: ID!,
    $application: ApplicationInput,
    $customFieldAnswers: [CustomFieldAnswerInput]
  ) {
    createReview(
      customerId: $customerId,
      application: $application,
      customFieldAnswers: $customFieldAnswers
    ) {
      id
    }
  }
`;

export const CREATE_EXTERNAL_REVIEW = gql`
  mutation CreateExternalReview(
    $customerId: ID!,
    $changeRequestAttrs: ChangeRequestInput
  ) {
    createExternalReview(
      customerId: $customerId,
      changeRequestAttrs: $changeRequestAttrs,
    ) {
      id
    }
  }
`;
