import React from 'react';

import { IOpenCorporate } from '@/types/openCorporate';
import { IOpenCorporatesComparison, ISeekerCompany } from '@/types/seekerCompany';

import { BodyText } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';

import { StyledExternalLink } from '../../styled';
import {
  StyledSingleResultTable,
  StyledSingleResultTableIconCell,
  StyledSingleResultTableIconCellTitle,
  StyledText
} from './styled';
import { StyledSingleResultContainer } from '../styled';
import { formatBusinessVerificationMatchNumberToIcon } from '../../functions';
import { formatDateToLongDateString } from '@/utils/date';

interface IProps {
  applicationInfo: Pick<ISeekerCompany, "name" | "streetAddressCity" | "type" | "established">,
  businessVerificationResult: IOpenCorporate,
  comparison: IOpenCorporatesComparison,
}

export const BusinessVerificationSingleResultView = ({ applicationInfo, businessVerificationResult, comparison }: IProps) => (
  <StyledSingleResultContainer>
    <StyledSingleResultTable>
      <BodyText bold>Data from Credit Application</BodyText>
      <BodyText bold>Data from Business Registration</BodyText>
      <StyledSingleResultTableIconCellTitle>Match</StyledSingleResultTableIconCellTitle>
      <StyledText>{applicationInfo.name}</StyledText>
      <StyledText>{businessVerificationResult.name}</StyledText>
      <StyledSingleResultTableIconCell>{formatBusinessVerificationMatchNumberToIcon(comparison.name)}</StyledSingleResultTableIconCell>
      <StyledText>{applicationInfo.streetAddressCity}</StyledText>
      <StyledText>{businessVerificationResult.streetAddressCity}</StyledText>
      <StyledSingleResultTableIconCell>{formatBusinessVerificationMatchNumberToIcon(comparison.address)}</StyledSingleResultTableIconCell>
      <StyledText>{applicationInfo.type}</StyledText>
      <StyledText>{businessVerificationResult.companyType}</StyledText>
      <StyledSingleResultTableIconCell/>
      <StyledText>{applicationInfo.established}</StyledText>
      <StyledText>{businessVerificationResult.incorporationDate}</StyledText>
      <StyledSingleResultTableIconCell>{formatBusinessVerificationMatchNumberToIcon(comparison.established)}</StyledSingleResultTableIconCell>
    </StyledSingleResultTable>
    <Flex direction='column'>
      <StyledText>Status: {businessVerificationResult.currentStatus}</StyledText>
      <StyledText>Source: {businessVerificationResult.publisher}</StyledText>
      <Flex gap='0.2rem' align='center'><StyledText>{`URL: `}</StyledText><StyledExternalLink
        href={businessVerificationResult.publisherUrl}
        target='_blank'
      >
        {` ${businessVerificationResult.publisherUrl}`}
      </StyledExternalLink></Flex>
      <StyledText>Updated At: {formatDateToLongDateString(businessVerificationResult.lastUpdatedDatetime)}</StyledText>
    </Flex>
  </StyledSingleResultContainer>
);
