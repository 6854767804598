import React from 'react';

import { getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IContact } from '@/types/contact';

import { BorderedContainer } from '@/containers/BorderedContainer';
import TwoColumnContainer from '@/components/_oldComponents/TwoColumnContainer';
import { Contact } from '@/components/_oldComponents/Contact';
import { EmptyState } from '@/components/EmptyState';

interface IProps {
  customer?: ICustomer,
}

export const CompanyContactSection = ({ customer }: IProps) => {
  const stakeholders = getOr([], 'seekerCompany.stakeholders', customer);

  return (
    <TwoColumnContainer.MainItem title='Company Contacts'>
      {
        stakeholders.length === 0
          ? <EmptyState>No Company Contacts provided yet</EmptyState>
          : (
            <BorderedContainer>
              {
                stakeholders.map((stakeholder: IContact) => <Contact key={stakeholder.id} contact={stakeholder} />)
              }
            </BorderedContainer>
          )
      }
    </TwoColumnContainer.MainItem>
  );
};
