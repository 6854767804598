import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { flow, noop, unionBy } from 'lodash/fp';

import { Button } from '@/components/designSystem/buttons';

import { FileToUpload } from './FileToUpload';
import { FileUploadContainer } from './styled';

export interface IFile {
  fileId: string,
  name: string,
  signedUrl: string,
  path: string,
  size: number,
  type: string,
}

export interface IStoredFile {
  author: {
    email: string,
    id: string,
  },
  id: string,
  originalFilename: string,
  path: string,
  signedUrl: string,
}

interface IProps {
  allFiles: IFile[],
  prompt?: string,
  setAllFiles: (files: IFile[]) => void,
  onlyOne?: boolean | null,
  error?: boolean,
}

export const FileUpload = ({
  allFiles,
  prompt = 'Upload Document',
  setAllFiles = noop,
  onlyOne,
  error,
}: IProps) => {
  const buttonText = `${prompt}${onlyOne ? '' : '(s)'}`;
  const updatedButtonText = (allFiles && allFiles.length > 0 && !onlyOne) ? 'Upload another document' : buttonText;

  const onDrop = flow(
    unionBy('name', allFiles),
    setAllFiles,
  );

  const removeFile = ({ fileId }: { fileId?: string }) => {
    const newFiles = allFiles.filter((f: IFile) => f.fileId !== fileId);
    setAllFiles(newFiles);
  };

  const onUpload = (currentFile: IFile, storedFile: IStoredFile) => {
    // eslint-disable-next-line no-param-reassign
    currentFile.signedUrl = storedFile.signedUrl;

    // eslint-disable-next-line no-param-reassign
    currentFile.fileId = storedFile.id;

    const newFiles = onlyOne
      ? [currentFile]
      : unionBy('name', [currentFile], allFiles as any);

    setAllFiles(newFiles);
  };

  const onUploadFail = useCallback((currentFile: IFile) => {
    setAllFiles(allFiles.filter((f: IFile) => f.name !== currentFile.name));
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: !onlyOne,
    onDrop,
  } as any);

  return (
    <FileUploadContainer {...getRootProps({ className: 'dropzone' })}>
      {
        (allFiles || []).map((file) => (
          <FileToUpload
            key={file.fileId}
            file={file}
            onUpload={onUpload}
            onUploadFail={onUploadFail}
            removeFile={removeFile}
          />
        ))
      }
      <input {...getInputProps()} />
      <Button
        wide
        secondary={!error}
        onClick={open}
        danger={error}
      >
        {updatedButtonText}
      </Button>
    </FileUploadContainer>
  );
};
