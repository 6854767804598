import styled from 'styled-components';

import { Callout } from '@/components/Callout/Callout';
import { Flex } from '@/components/designSystem/Layout';
import { EditButton } from '@/components/designSystem/buttons/EditButton';
import { ToggleIcon } from '@/components/ToggleBlock/styled';

export const StyledCallout = styled(Callout)`
  margin-top: 1rem;
`;

export const StyledFilesContainer = styled.div`
  margin-top: 1rem;

  & > * {
    margin-bottom: 1rem;
  }
`;

export const StyledDocumentsContainer = styled(Flex).attrs({ justify: 'space-between' })`
  width: 100%;
  position: relative;
`;

export const StyledEmailCommunicationsWrapper = styled.div`
  margin-top: 0.6rem;
`;

export const StyledEditButton = styled(EditButton)`
  flex: 0 0 auto;
  margin-left: 20px;
  margin-top: -10px;
`;

export const StyledIconLabel = styled(Flex).attrs({ direction: 'row', align: 'center' })`
  margin-right: 0.5rem;

  & > :first-child {
    margin-right: 0.25rem;
    margin-left: 0.5rem;
  }
`;

export const StyledAddVendorWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export const StyledEmailActivityContainer = styled.div`
  padding-top: 0.5rem;
  & > :first-child {
    cursor:pointer;
  }
`;

export const StyledEmailActivityRow = styled.div`
  background: ${({ theme }) => theme.color.greyLight};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  align-items: center;
  width: 100%;
`;

export const StyledEmailActivityRowContent = styled(Flex)`
  & > * {
    width: 25%;
  }
`;

export const StyledToggleIcon = styled(ToggleIcon)`
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`;

