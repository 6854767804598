import React, { Dispatch, SetStateAction } from 'react';

import { StyledModal } from '@/modals/StyledModal';

import { EversignWidget } from './EversignWidget';

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: () => void,
  embeddedUrl?: string,
}

export const SignAgreementModal = ({
  isOpen, setIsOpen, onSuccess, embeddedUrl,
}: IProps) => (
  <StyledModal
    title='Sign Agreement'
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    blockClose
  >
    {isOpen ? (
      <EversignWidget
        setIsOpen={setIsOpen}
        onSuccess={onSuccess}
        embeddedUrl={embeddedUrl}
      />
    ) : null}
  </StyledModal>
);
