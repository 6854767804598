import React, { Dispatch, SetStateAction } from 'react';

import { useMutation, FetchResult } from '@apollo/react-hooks';
import { UPDATE_COMPANY_SETTINGS } from '@/graphql/mutations/updateCompanySettings';

import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { TextField } from '@/components/designSystem/Form/TextField';
import { StyledModalForm } from '@/modals/StyledModal/StyledModalForm';
import { Callout } from '@/components/Callout/Callout';
import { BodyText } from '@/components/designSystem/Typography';

const validation = Yup.object().shape({
  equifaxCustomerCode: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  equifaxCustomerNumber: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  equifaxSecurityCode: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  equifaxClientId: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  equifaxClientSecret: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
});

// https://github.com/apollographql/apollo-client/pull/3892 This should be removed with an updated graphql
const extractGQLErrorMessage = (error: Error) => {
  const { 1: errorMessage } = error.message.split('GraphQL error: ');
  return errorMessage;
};

interface IFields {
  equifaxCustomerCode?: string,
  equifaxCustomerNumber?: string,
  equifaxSecurityCode: string,
  equifaxClientId: string,
  equifaxClientSecret: string,
}

interface IProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess?: (response: FetchResult) => void,
  equifaxCustomerCode?: string,
  equifaxCustomerNumber?: string,
}

export const EquifaxCredentialsModal = ({
  isOpen, setIsOpen, onSuccess, equifaxCustomerCode, equifaxCustomerNumber,
}: IProps) => {
  const [updateCompanySettings, { loading }] = useMutation(UPDATE_COMPANY_SETTINGS);

  // eslint-disable-next-line no-shadow
  const handleSubmit = async ({
    equifaxCustomerCode, equifaxCustomerNumber, equifaxSecurityCode, equifaxClientId, equifaxClientSecret,
  }: IFields) => {
    const variables = {
      settings: {
        equifaxCustomerCode,
        equifaxCustomerNumber,
        equifaxSecurityCode,
        equifaxClientId,
        equifaxClientSecret,
      },
    };

    updateCompanySettings({ variables })
      .then((response) => {
        showToast({
          title: 'Credentials Successfully Update!',
          description: 'You are now able to request Equifax Credit Reports for your customers',
          type: toast.TYPE.SUCCESS,
        });
        setIsOpen(false);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        showToast({
          title: 'Unauthorized',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
        setIsOpen(false);
      });
  };

  const fields = {
    equifaxCustomerCode,
    equifaxCustomerNumber,
    equifaxSecurityCode: '',
    equifaxClientId: '',
    equifaxClientSecret: '',
  };

  return (
    <StyledModalForm
      title='Login to Equifax'
      submitButtonText='Confirm'
      onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)}
      initialValues={fields}
      validationSchema={validation}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      blockClose={true}
    >
      <Callout color='pastelRed'>
        <BodyText>Please ask your Equifax representative for API credentials, as these credentials are different from your typical Equifax login.</BodyText>
      </Callout>
      <TextField required type='text' name='equifaxCustomerCode' label='Equifax Customer Code' placeholder='Equifax Customer Code' />
      <TextField required type='text' name='equifaxCustomerNumber' label='Equifax Customer Number' placeholder='Equifax Customer Number' />
      <TextField required type='password' name='equifaxSecurityCode' label='Equifax Cecurity Code' placeholder='*****' />
      <TextField required type='text' name='equifaxClientId' label='Equifax Client ID' placeholder='Equifax Client ID' />
      <TextField required type='password' name='equifaxClientSecret' label='Equifax Client Secret' placeholder='*****' />
    </StyledModalForm>
  );
};
