import React from 'react';

import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';

import { Section } from './Section';

export const BankInfoSection = () => (
  <Section
    title='Bank Info'
    description='Banking information can be automatically captured.'
  >
    <Row>
      {/* All these TextField names are fake. We need for formik fields.
        If you need to enable these text fields at some point, you will need to fix names */}
      <TextField type='text' name='bank.balance' disabled label='Balance' placeholder='$23,456' />
      <TextField type='text' name='bank.averageBalance' disabled label='Average Balance' placeholder='$456,789' />
    </Row>
    <Row>
      <TextField type='text' name='bank.highBalance' disabled label='High Balance' placeholder='$1,456,789' />
      <TextField type='text' name='bank.lowBalance' disabled label='Low Balance' placeholder='$6,789' />
    </Row>
  </Section>
);
