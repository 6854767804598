import React from 'react';

import { H4 } from '@/components/designSystem/Typography';

interface IProps {
  name?: string,
  required?: boolean,
  children: React.ReactNode,
}

export const FieldLabel = ({ name, required, children }: IProps) => (
  <label htmlFor={name}>
    <H4 bold>{children}</H4>
    {required && <H4 color='brand'>*</H4>}
  </label>
);